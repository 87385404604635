import React from "react"
import {
  Modal,
  Button,
  Header,
  Typography,
} from "@elevate_security/elevate-component-library"
import { EditContent, ItalicText } from "./styles"

const { H2, Text } = Typography

export const EditPreview = ({ item }) => {
  return (
    <div>
      <Text>
        <EditContent style={{ marginBottom: "8px" }}>
          You are about to edit the rule <strong>{item.name}</strong>, which is
          currently {item.is_test ? "in " : ""}
          <ItalicText>{item.is_test ? "test mode" : "active"}</ItalicText>.
        </EditContent>
        <EditContent style={{ lineHeight: "24px" }}>
          Making any changes to the cohort or event criteria sections will clear
          the membership of the cohorts that corresponds to the rule configured
          actions
        </EditContent>
      </Text>
    </div>
  )
}

const EditModal = ({ item, showModal, onClose, onConfirm }) => {
  const renderButtons = [
    <Button key="no" theme="ghost" onClick={onClose}>
      No, Cancel
    </Button>,
    <Button key="yes" onClick={() => onConfirm()}>
      Continue
    </Button>,
  ]

  const getTitle = () => {
    const title = "You're about to edit"
    if (item.is_test) {
      return `${title} a test rule...`
    }

    return `${title} an active rule...`
  }

  return (
    <Modal
      onClose={onClose}
      header={
        <Header>
          <H2 color="900" style={{ marginBottom: "0px" }}>
            {getTitle()}
          </H2>
        </Header>
      }
      style={{ maxWidth: "648px" }}
      rightButtons={renderButtons}
      isOpen={showModal}
    >
      <EditPreview item={item} />
    </Modal>
  )
}

export default EditModal
