import React, { useState, useEffect } from "react"
import {
  Toggle,
  RadioButton,
  ComboBox,
  CustomSelect,
} from "@elevate_security/elevate-component-library"

import {
  ActionRow,
  AzureConditionalAccessPolicyWrapper,
  ToggleWrapper,
  ToggleWrapperText,
  AzureConditionalAccessPolicyControls,
  GrantAccessContainer,
  Label,
} from "./styles"
import {
  CAP_WARNING_MESSAGE,
  UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { addRemoveRuleAction } from "../RiskRuleOutcome/utils"
import { useDispatch } from "react-redux"
import Checkbox from "@src/components/Checkbox"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { useRequest } from "ahooks"
import { getAzureADConditionalAccessPolicies } from "@src/services/apis/microsoftTeams"
import { IndividualsWrapper } from "../styles"
import { NotificationRadioButtonGroup } from "../RiskRuleOutcome/Notifications"
import { NOTIFICATION_OPTIONS } from "../RiskRuleOutcome/constants"
import WarningModal from "../CiscoDuoConditionalAccessPolicy/WarningModal"
import AzureApplicationsCombobox from "../AzureApplicationsCombobox"

const accessOptions = [
  {
    value: "block",
    label: "Block access",
  },
  {
    value: "grant",
    label: "Grant access",
  },
]

const operatorsOptions = [
  {
    value: "and",
    label: "Require all the selected controls",
  },
  {
    value: "or",
    label: "Require one of the selected controls",
  },
]

const RenderCustomOptions = ({ label, description }) => (
  <div>
    <div>
      <b>{label}</b>
    </div>
    <div style={{ overflowWrap: "unset", overflow: "unset" }}>
      {description}
    </div>
  </div>
)

const authenticationStrengthOptions = [
  {
    label: "Multifactor authentication",
    value: "Multifactor authentication",
    render: (
      <RenderCustomOptions
        label="Multifactor authentication"
        description="Combinations of methods that satisfy strong authentication, such as Password + SMS"
      />
    ),
  },
  {
    label: "Passwordless MFA",
    value: "Passwordless MFA",
    render: (
      <RenderCustomOptions
        label="Passwordless MFA"
        description="Passwordless methods that satisfy strong authentication, such as Microsoft Authentication"
      />
    ),
  },
  {
    label: "Phishing-resistant MFA",
    value: "Phishing-resistant MFA",
    render: (
      <RenderCustomOptions
        label="Phishing-resistant MFA"
        description="Phishing-resistant Passwordless methods for the strongest authentication, such as FIDO2 Security Key"
      />
    ),
  },
]
const LIMIT = 25

export const AzureConditionalAccessPolicy = ({
  ruleActions,
  azureCap,
  servicesToggle,
  showToggle = true,
}) => {
  const dispatch = useDispatch()
  const { azureCapState, setAzureCapState } = azureCap
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalGroups, setTotalGroups] = useState(0)
  const [capNextValue, setCapNextValue] = useState([])

  const { data, loading } = useRequest(
    () =>
      getAzureADConditionalAccessPolicies({
        search: `${search}`,
        limit: LIMIT,
        offset: page * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        if (page === 0) {
          setTotalGroups(response?.data?.count || 0)
          return (
            response?.data?.results?.map((item) => ({
              label: item.display_name,
              value: item.policy_id,
            })) || []
          )
        } else {
          return [
            ...data,
            ...(response?.data?.results?.map((item) => ({
              label: item.display_name,
              value: item.policy_id,
            })) ?? []),
          ]
        }
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
    setPage(0)
  }

  const AzureCAPActionIsEnabled = ruleActions?.has(
    UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY,
  )
  const showGrantAccessOptions =
    !azureCapState?.policy_properties?.grantControls?.builtInControls?.includes(
      "block",
    )

  const onCloseModal = () => {
    setCapNextValue([])
    setIsWarningModalOpen(false)
  }

  const onConfirmModal = () => {
    setAzureCapState({
      ...azureCapState,
      policy_ids: capNextValue.map((item) => item.value),
    })
    onCloseModal()
  }

  const [selectedAppsInclude, setSelectedAppsInclude] = useState(
    azureCapState?.apps_include_meta ?? [],
  )

  const [selectedAppsExclude, setSelectedAppsExclude] = useState(
    azureCapState?.apps_exclude_meta ?? [],
  )

  useEffect(() => {
    const setApplicationCondition = (obj, apps, key) => {
      if (apps.length) {
        obj[key] = apps.map((app) => app?.value)
      }
    }

    setAzureCapState((azureCapState) => {
      const newState = {
        ...azureCapState,
        apps_include_meta: selectedAppsInclude,
        apps_exclude_meta: selectedAppsExclude,
      }

      const applications = {}
      setApplicationCondition(
        applications,
        selectedAppsInclude,
        "includeApplications",
      )
      setApplicationCondition(
        applications,
        selectedAppsExclude,
        "excludeApplications",
      )

      if (Object.keys(applications).length === 0) {
        delete newState?.policy_properties?.conditions
      } else {
        newState.policy_properties = {
          ...(newState?.policy_properties || {}),
          conditions: {
            applications,
          },
        }
      }

      return newState
    })
  }, [selectedAppsInclude, selectedAppsExclude])

  const isPolicySelected = azureCapState?.policy_ids?.length > 0
  const requireAuthenticationStrengthSelected =
    typeof azureCapState.policy_properties.grantControls
      .authenticationStrength !== "undefined"

  const isIntegrationDataSynced = servicesToggle?.find(
    (service) =>
      service.vendor_code === "azuread" &&
      service.integration_type === "conditional-access-policy",
  )?.last_received_date

  return (
    <AzureConditionalAccessPolicyWrapper>
      {showToggle && (
        <ActionRow>
          <ToggleWrapper>
            <Toggle
              checked={AzureCAPActionIsEnabled}
              onChange={({ checked }) => {
                if (checked) {
                  addRemoveRuleAction({
                    ruleActions,
                    type: UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY,
                    dispatch,
                  })
                } else {
                  addRemoveRuleAction({
                    ruleActions,
                    type: UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY,
                    dispatch,
                    isAdd: false,
                  })
                }
              }}
            />
            <ToggleWrapperText>
              AzureAD Conditional Access Policy - Update Policies
            </ToggleWrapperText>
          </ToggleWrapper>
        </ActionRow>
      )}
      {AzureCAPActionIsEnabled && (
        <>
          <WarningModal
            isOpen={isWarningModalOpen}
            title="Update Azure AD CAP"
            message={CAP_WARNING_MESSAGE}
            onConfirm={() => {
              onConfirmModal()
            }}
            onClose={onCloseModal}
          />
          {isIntegrationDataSynced ? (
            <AzureConditionalAccessPolicyControls>
              <div className="row">
                <Label>Choose Azure AD Conditional Access Policy</Label>
                <ComboBox
                  data={data}
                  value={data?.filter((policy) =>
                    azureCapState?.policy_ids?.includes(policy.value),
                  )}
                  onChange={(items) => {
                    setIsWarningModalOpen(true)
                    setCapNextValue(items)
                  }}
                  isLoading={loading}
                  clearable={false}
                  isSearchable={true}
                  isMultiOption
                  checked
                  hideSelectedOptions={true}
                  components={{}}
                  placeholder="Select Policies"
                  handleScrollBotttom={() => {
                    if (data.length < totalGroups) {
                      setPage(page + 1)
                    }
                  }}
                  handleInputChange={(value) => handleSetSearch(value)}
                />
              </div>
              {isPolicySelected && (
                <>
                  <div style={{ marginBottom: 20 }}>
                    <div className="section-title">
                      Control access enforcement to block or grant access.
                    </div>
                    <RadioButton
                      options={accessOptions}
                      name="accessType"
                      radioSelected={
                        azureCapState.policy_properties.grantControls.builtInControls.includes(
                          "block",
                        )
                          ? "block"
                          : "grant"
                      }
                      vertical
                      onChange={(val) => {
                        if (val === "block") {
                          const copy = { ...azureCapState }
                          delete copy.policy_properties.grantControls
                            .authenticationStrength
                          setAzureCapState({
                            ...copy,
                            policy_properties: {
                              ...azureCapState.policy_properties,
                              grantControls: {
                                ...azureCapState.policy_properties
                                  .grantControls,
                                builtInControls: ["block"],
                              },
                            },
                          })
                        } else {
                          setAzureCapState({
                            ...azureCapState,
                            policy_properties: {
                              ...azureCapState.policy_properties,
                              grantControls: {
                                ...azureCapState.policy_properties
                                  .grantControls,
                                builtInControls: [],
                              },
                            },
                          })
                        }
                      }}
                    />
                    {showGrantAccessOptions && (
                      <GrantAccessContainer>
                        <div className="row">
                          <Checkbox
                            id="mfa-checkbox"
                            label="Require multifactor authentication"
                            disabled={
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "block",
                              ) ||
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "passwordChange",
                              ) ||
                              typeof azureCapState.policy_properties
                                .grantControls.authenticationStrength !==
                                "undefined"
                            }
                            checked={azureCapState.policy_properties.grantControls.builtInControls.includes(
                              "mfa",
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls: [
                                        ...azureCapState.policy_properties
                                          .grantControls.builtInControls,
                                        "mfa",
                                      ],
                                    },
                                  },
                                })
                              } else {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls:
                                        azureCapState.policy_properties.grantControls.builtInControls.filter(
                                          (value) => value !== "mfa",
                                        ),
                                    },
                                  },
                                })
                              }
                            }}
                          />

                          <Tooltip
                            body="User must complete additional security requirements like phone call, text"
                            placement="top"
                            readOnly
                            size="sm"
                          >
                            <Icon
                              name="InfoCheckOutline"
                              size="xxsm"
                              fill="#959DA8"
                            />
                          </Tooltip>
                        </div>
                        <div className="row">
                          <Checkbox
                            id="ras-checkbox"
                            label="Require authentication strength (Preview)"
                            disabled={
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "block",
                              ) ||
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "mfa",
                              ) ||
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "passwordChange",
                              )
                            }
                            checked={
                              typeof azureCapState.policy_properties
                                .grantControls.authenticationStrength !==
                              "undefined"
                            }
                            onChange={(checked) => {
                              if (checked) {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      operator: "and",
                                      builtInControls:
                                        azureCapState.policy_properties.grantControls.builtInControls.filter(
                                          (item) => item !== "mfa",
                                        ),
                                      authenticationStrength: {
                                        displayName:
                                          "Multifactor authentication",
                                      },
                                    },
                                  },
                                })
                              } else {
                                const copy = { ...azureCapState }
                                delete copy.policy_properties.grantControls
                                  .authenticationStrength
                                setAzureCapState({
                                  ...copy,
                                })
                              }
                            }}
                          />

                          <Tooltip
                            body="Users must use specific authentication methods, based on the authentication strength policies applied."
                            placement="top"
                            readOnly
                            size="sm"
                          >
                            <Icon
                              name="InfoCheckOutline"
                              size="xxsm"
                              fill="#959DA8"
                            />
                          </Tooltip>
                        </div>
                        {requireAuthenticationStrengthSelected && (
                          <div className="row">
                            <CustomSelect
                              placeholder="Select option"
                              options={authenticationStrengthOptions}
                              defaultValue={{
                                label:
                                  azureCapState.policy_properties.grantControls
                                    .authenticationStrength.displayName,
                                value:
                                  azureCapState.policy_properties.grantControls
                                    .authenticationStrength.displayName,
                              }}
                              onChange={(selected) => {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      authenticationStrength: {
                                        displayName: selected.value,
                                      },
                                    },
                                  },
                                })
                              }}
                            />
                          </div>
                        )}
                        <div className="row">
                          <Checkbox
                            id="compliantDevice-checkbox"
                            label="Require device to be marked as compliant"
                            disabled={
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "block",
                              ) ||
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "passwordChange",
                              )
                            }
                            checked={azureCapState.policy_properties.grantControls.builtInControls.includes(
                              "compliantDevice",
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls: [
                                        ...azureCapState.policy_properties
                                          .grantControls.builtInControls,
                                        "compliantDevice",
                                      ],
                                    },
                                  },
                                })
                              } else {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls:
                                        azureCapState.policy_properties.grantControls.builtInControls.filter(
                                          (value) =>
                                            value !== "compliantDevice",
                                        ),
                                    },
                                  },
                                })
                              }
                            }}
                          />
                          <Tooltip
                            body="Device must be Intune compliant. If the device is non-compliant, the user will be prompted to bring the device under compliance."
                            placement="top"
                            readOnly
                            size="sm"
                          >
                            <Icon
                              name="InfoCheckOutline"
                              size="xxsm"
                              fill="#959DA8"
                            />
                          </Tooltip>
                        </div>
                        <div className="row">
                          <Checkbox
                            id="domainJoinedDevice-checkbox"
                            label="Require Hybrid Azure AD joined device"
                            disabled={
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "block",
                              ) ||
                              azureCapState.policy_properties.grantControls.builtInControls.includes(
                                "passwordChange",
                              )
                            }
                            checked={azureCapState.policy_properties.grantControls.builtInControls.includes(
                              "domainJoinedDevice",
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls: [
                                        ...azureCapState.policy_properties
                                          .grantControls.builtInControls,
                                        "domainJoinedDevice",
                                      ],
                                    },
                                  },
                                })
                              } else {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls:
                                        azureCapState.policy_properties.grantControls.builtInControls.filter(
                                          (value) =>
                                            value !== "domainJoinedDevice",
                                        ),
                                    },
                                  },
                                })
                              }
                            }}
                          />
                          <Tooltip
                            body="Devices must be Hybrid Azure AD joined."
                            placement="top"
                            readOnly
                            size="sm"
                          >
                            <Icon
                              name="InfoCheckOutline"
                              size="xxsm"
                              fill="#959DA8"
                            />
                          </Tooltip>
                        </div>
                        <div className="row">
                          <Checkbox
                            id="passwordChange-checkbox"
                            label="Require password change"
                            disabled={azureCapState.policy_properties.grantControls.builtInControls.includes(
                              "block",
                            )}
                            checked={azureCapState.policy_properties.grantControls.builtInControls.includes(
                              "passwordChange",
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                if (
                                  azureCapState.policy_properties.grantControls.builtInControls.includes(
                                    "mfa",
                                  )
                                ) {
                                  setAzureCapState({
                                    ...azureCapState,
                                    policy_properties: {
                                      ...azureCapState.policy_properties,
                                      grantControls: {
                                        ...azureCapState.policy_properties
                                          .grantControls,
                                        operator: "and",
                                        builtInControls: [
                                          "mfa",
                                          "passwordChange",
                                        ],
                                      },
                                    },
                                  })
                                } else {
                                  setAzureCapState({
                                    ...azureCapState,
                                    policy_properties: {
                                      ...azureCapState.policy_properties,
                                      grantControls: {
                                        ...azureCapState.policy_properties
                                          .grantControls,
                                        operator: "and",
                                        builtInControls: ["passwordChange"],
                                        authenticationStrength: {
                                          displayName:
                                            "Multifactor authentication",
                                        },
                                      },
                                    },
                                  })
                                }
                              } else {
                                setAzureCapState({
                                  ...azureCapState,
                                  policy_properties: {
                                    ...azureCapState.policy_properties,
                                    grantControls: {
                                      ...azureCapState.policy_properties
                                        .grantControls,
                                      builtInControls:
                                        azureCapState.policy_properties.grantControls.builtInControls.filter(
                                          (value) => value !== "passwordChange",
                                        ),
                                    },
                                  },
                                })
                              }
                            }}
                          />
                          <Tooltip
                            body="Require password change to lower user risk. This option also requires multifactor authentication. Other controls can't be used."
                            placement="top"
                            readOnly
                            size="sm"
                          >
                            <Icon
                              name="InfoCheckOutline"
                              size="xxsm"
                              fill="#959DA8"
                            />
                          </Tooltip>
                        </div>
                      </GrantAccessContainer>
                    )}
                    <div className="section-title">For multiple controls</div>
                    <RadioButton
                      options={operatorsOptions}
                      name="operator"
                      radioSelected={
                        azureCapState.policy_properties.grantControls.operator
                      }
                      vertical
                      disabled={
                        azureCapState.policy_properties.grantControls.builtInControls.includes(
                          "passwordChange",
                        ) ||
                        typeof azureCapState.policy_properties.grantControls
                          .authenticationStrength !== "undefined"
                      }
                      onChange={(val) =>
                        setAzureCapState({
                          ...azureCapState,
                          policy_properties: {
                            ...azureCapState.policy_properties,
                            grantControls: {
                              ...azureCapState.policy_properties.grantControls,
                              operator: val,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <div className="row">
                    <AzureApplicationsCombobox
                      label="Set Enterprise Applications Inclusion List (Optional)"
                      onChange={(items) => setSelectedAppsInclude(items)}
                      value={selectedAppsInclude}
                    />
                  </div>
                  <div className="row">
                    <AzureApplicationsCombobox
                      label="Set Enterprise Applications Exclusion List (Optional)"
                      onChange={(items) => setSelectedAppsExclude(items)}
                      value={selectedAppsExclude}
                    />
                  </div>
                </>
              )}
              <NotificationRadioButtonGroup
                title="Matching Method:"
                titleColor="700"
                titleFontWeight="bold"
                selected={azureCapState?.matching_method}
                onChange={(e) =>
                  setAzureCapState({
                    ...azureCapState,
                    matching_method: e?.target?.value,
                  })
                }
                options={NOTIFICATION_OPTIONS}
                name="azure-ad-conditional-access-policy-notificationMethod"
              />
            </AzureConditionalAccessPolicyControls>
          ) : (
            <IndividualsWrapper>
              <div>
                <h6>No options!</h6>
                <p>Integration process is in progress</p>
              </div>
            </IndividualsWrapper>
          )}
        </>
      )}
    </AzureConditionalAccessPolicyWrapper>
  )
}
