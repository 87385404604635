import styled from "styled-components"

export const Dot = styled.span`
  display: block;
  width: 11px;
  height: 11px;
  min-width: 11px;
  min-height: 11px;
  margin: auto 8px auto 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color || "#0c7d84"};
`
