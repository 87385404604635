import React from "react"

import { ELEVATESECURITY_GROUPS_REMOVE_INDIVIDUALS } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { ActionGroups } from "./ActionGroups"
import { EditElevateSecurityGroups } from "../actionRenderComponents/EditElevateSecurityGroups"

export default {
  label: "Remove users from Elevate Security Groups",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: ELEVATESECURITY_GROUPS_REMOVE_INDIVIDUALS,
    matching_method: "delta",
    target_groups: [],
    target_groups_meta: [],
    target_system: "elevatesecurity",
  },
  isEnabled: () => true,
  render: ({ details, updateAction }) => {
    return (
      <EditElevateSecurityGroups
        title="Remove from Group"
        updateAction={updateAction}
        action={details}
      />
    )
  },
}
