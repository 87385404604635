import styled from "styled-components"

export const CampaignTooltipWrapper = styled.div`
  display: block;
  width: 320px;
  padding: 10px;
`
export const Row = styled.div`
  display: flex;
  height: ${({ height }) => (!height ? "auto" : height)};
  align-items: ${({ alignItems }) => (!alignItems ? "center" : alignItems)};
  justify-content: ${({ justifyContent }) =>
    !justifyContent ? "initial" : justifyContent};
`
export const Column = styled.div`
  flex-direction: ${({ flexDirection }) =>
    !flexDirection ? "column" : flexDirection};
  display: flex;
  padding: ${({ padding }) => (!padding ? "0" : padding)};
  margin: ${({ margin }) => (!margin ? "0" : margin)};
  width: ${({ width }) => (!width ? "initial" : width)};
  height: ${({ height }) => (!height ? "initial" : height)};
  background-color: ${({ backgroundColor }) =>
    !backgroundColor ? "none" : backgroundColor};
  align-items: ${({ alignItems }) => (!alignItems ? "initial" : alignItems)};
  justify-content: ${({ justifyContent }) =>
    !justifyContent ? "initial" : justifyContent};
`

export const Strong = styled.strong`
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #34383c;
  padding-bottom: 3px;
`
export const Targets = styled.strong`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
`

export const Span = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: #34383c;
  text-transform: ${({ textTransform }) =>
    !textTransform ? "initial" : textTransform};
`

export const Small = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: #34383c;
  padding: 8px 0 0 0;
`

export const Status = styled.span`
  display: inline-flex;
  width: auto;
  font-size: 13px;
  line-height: 16px;
  color: #cb7d20;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  background-color: #fff3ca;
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 5px;
`
