import styled from "styled-components"

export const IconWrapper = styled.div`
  margin-right: 6px;
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-bottom: 10px;
  div {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
  svg {
    line {
      stroke: ${({ iconFill }) => iconFill};
    }
  }
`
export const TextWrapper = styled.span`
  text-decoration: none;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => (props.disabled ? "#AECCCE" : "")};
  line-height: 39px;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`
