import * as d3 from "d3"
import { getGraphLabel } from "@src/scenes/IndividualProfilePage/ProfileInfo/components/IndividualHumanRiskScoreInfoSection/utils"

export const BEHAVIOR_COLORS = [
  "#2064B5",
  "#668CE5",
  "#AC3ECF",
  "#86CC55",
  "#EDA039",
  "#DE4B6D",
  "#FAD250",
  "#1CB696",
  "#7E8691",
  "#DE6900",
  "#178574",
  "#CB7D20",
  "#035257",
  "#B8E5E3",
  "#AECCCE",
]

export const setLineMargin = (month) => {
  const initialValue = 22
  switch (month.getMonth()) {
    case 2:
    case 6:
      return initialValue + 2
    case 3:
    case 7:
      return initialValue - 1
    case 11:
    case 8:
      return initialValue - 3
    case 9:
      return initialValue - 2
    case 10:
      return initialValue - 4
    case 0:
      return initialValue - 5
    case 1:
      return initialValue - 7
    default:
      return initialValue
  }
}

export const formatLabel = ({ value, showGradeLabel, yAxisDecimal }) => {
  if (showGradeLabel) {
    return getGraphLabel(value)
  }

  if (value >= 1000) {
    const formattedValue = d3.format(",.2r")(value / 1000)
    return yAxisDecimal ? `${formattedValue}K` : `${Math.round(value / 1000)}K`
  }

  return yAxisDecimal ? value.toFixed(1) : Math.round(value)
}

export const yAxisLabel = ({
  yScale,
  showGradeLabel,
  yAxisDecimal,
  max,
  ticks = 6,
  padding = 15,
  tickValues = [],
  isRightAxis = false,
}) => {
  return d3[isRightAxis ? "axisRight" : "axisLeft"](yScale)
    .tickSize(0)
    .tickSizeOuter(0)
    .tickPadding(padding)
    .ticks(ticks)
    .tickFormat((value) =>
      formatLabel({ value, showGradeLabel, yAxisDecimal, max }),
    )
    .scale(yScale)
    .tickValues(tickValues.length ? tickValues : null)
}

export const showHorizontalLines = ({ yScale, svgWidth }) => {
  return d3
    .axisLeft(yScale)
    .tickSize(0)
    .tickSizeOuter(0)
    .tickSizeInner(-svgWidth)
    .ticks(4)
    .tickFormat("")
}

export const SCORE_SORT = "score"
export const barColor = "#AEAEAE"
