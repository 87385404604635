import {
  SET_TREND_SUMMARY_CAMPAIGNS,
  SET_TREND_SUMMARY_HISTORICAL_SCORES,
} from "./types"
import {
  getTrendSummaryCampaigns,
  getTrendSummaryHierarchicalScores,
} from "@src/services/apis/trendSummary"
import { getCompanyInfo } from "../company/selectors"

export function setTrendSummaryCampaigns({
  limit,
  offset,
  start_date,
  end_date,
  ordering,
}) {
  return async (dispatch) => {
    dispatch({
      type: SET_TREND_SUMMARY_CAMPAIGNS,
      data: {
        isLoaded: false,
      },
    })
    const { error, data } = await getTrendSummaryCampaigns({
      limit,
      offset,
      start_date,
      end_date,
      ordering,
    })
    dispatch({
      type: SET_TREND_SUMMARY_CAMPAIGNS,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setTrendSummaryHistoricalScores({ start_date, end_date }) {
  return async (dispatch) => {
    dispatch({
      type: SET_TREND_SUMMARY_HISTORICAL_SCORES,
      data: {
        isLoaded: false,
      },
    })
    const { shortname: organization_nid } = getCompanyInfo()
    const { error, data } = await getTrendSummaryHierarchicalScores({
      start_date,
      end_date,
      organization_nid,
    })
    dispatch({
      type: SET_TREND_SUMMARY_HISTORICAL_SCORES,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}
