import styled from "styled-components"

export const StatusCheckWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #c9cfd6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  padding: 40px;

  hr {
    margin: 24px 0 40px 0;
  }
`

export const StatusCheckHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #34383c;
  margin-bottom: 24px;
`

export const StateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;

  .value {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #34383c;
    cursor: default;
  }

  .state {
    color: #7b828a;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }
`
