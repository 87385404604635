import { isEmpty } from "@src/utils/string"

const validateEventOccurance = (data) => {
  for (let i = 0; i < data?.length; i++) {
    const eventOccurance = data[i]
    if (
      eventOccurance?.value?.length < 4 ||
      (typeof eventOccurance?.value[0] === "string" &&
        eventOccurance?.value[0] === "") ||
      (typeof eventOccurance?.value[1] === "number" &&
        eventOccurance?.value[1] < 0) ||
      (typeof eventOccurance?.value[2] === "number" &&
        eventOccurance?.value[2] <= 0) ||
      (typeof eventOccurance?.value[3] === "string" &&
        eventOccurance?.value[3] === "")
    ) {
      return false
    }
  }
  return true
}

const validateFieldOpValue = (condition) => {
  if (isEmpty(condition?.field)) return false

  if (isEmpty(condition?.operator)) return false

  if (isEmpty(condition?.operator)) return false

  if (typeof condition.value === "undefined") return false

  if (typeof condition.value === "string" && isEmpty(condition.value)) {
    return false
  }

  return true
}

const validateCondition = (condition) => {
  if (isEmpty(condition?.category)) return false

  if (!validateFieldOpValue(condition)) return false

  if (!validateEventOccurance(condition?.eventOccurrences)) return false

  return true
}

const validateCompoundCondition = (condition) => {
  if (isEmpty(condition?.category)) return false

  const validated_compound_conditions = condition.compound_conditions.reduce(
    (acc, cur) => acc && validateFieldOpValue(cur),
    true,
  )

  if (!validated_compound_conditions) return false

  return true
}

export const _validateCriteria = (criteria) => {
  if (criteria?.compound_conditions) {
    return validateCompoundCondition(criteria)
  }
  if (!criteria.rules?.length) {
    return validateCondition(criteria)
  }

  if (criteria.rules) {
    return criteria.rules.map(_validateCriteria).every((valid) => valid)
  }

  return false
}

export const validateCriteria = (data) => {
  return _validateCriteria(data)
}
