/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react"
import { ActionWrapper, ActionBox, Strong, Medium } from "./styles"

export function ActionBoxes({ totalCount, overallTrend }) {
  return (
    <ActionWrapper>
      <ActionBox>
        <Strong>{totalCount}</Strong>
        <Medium>Actions Taken</Medium>
      </ActionBox>
      <ActionBox>
        <Strong>{overallTrend}</Strong>
        <Medium>Points (Security Score)</Medium>
      </ActionBox>
    </ActionWrapper>
  )
}
