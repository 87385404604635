import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Modal,
  Button,
  ComboBox,
  Typography,
  Messages,
} from "@elevate_security/elevate-component-library"
import { useSelectedIndividualState } from "../../SelectedIndividualProviders"
import { showToast } from "@src/services/redux/toasts/actions"
import { getGroupsList } from "@src/services/apis/groups"
import { useRequest } from "ahooks"
import { addMembersToGroupWithFilters } from "@src/services/apis/reputation"
import {
  SpaceBetween,
  ModalContentWrapper,
} from "./AddToStaticGroupModal.styles"
import { getIndividualCount } from "../groupHelpers"
import pluralize from "pluralize"

export function AddToStaticGroupModal({ isOpen, onClose, filters }) {
  const selectedIndividuals = useSelectedIndividualState()
  const { riskTableCount } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const { advanced_filters } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const dispatch = useDispatch()
  const onAddFailure = () => {
    dispatch(showToast("Failed to add to group. Please try again.", "error"))
  }
  const onAddSuccess = (groupName) => {
    dispatch(showToast(`Successfully added to group "${groupName}".`))
  }
  const onAddToGroup = (groupId, filters, targetIds, doNotTargetIds) => {
    return addMembersToGroupWithFilters(
      filters,
      {
        group_id: groupId,
        target_ids: targetIds,
        do_not_target_ids: doNotTargetIds,
      },
      advanced_filters,
    )
  }
  const fetchStaticGroups = () => getGroupsList({ group_type: 1 })

  return (
    <AddToStaticGroupModalPresentation
      isOpen={isOpen}
      onClose={onClose}
      filters={filters}
      selectedIndividuals={selectedIndividuals}
      onAddToGroup={onAddToGroup}
      onAddFailure={onAddFailure}
      onAddSuccess={onAddSuccess}
      selectedIndividualCount={riskTableCount}
      fetchStaticGroups={fetchStaticGroups}
      useRequest={useRequest}
    />
  )
}

AddToStaticGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.string.isRequired,
}

/*
 * Presentational helper component that separates the modal visual elements/state from the global redux state
 */
export function AddToStaticGroupModalPresentation({
  isOpen,
  onClose,
  filters,
  selectedIndividuals,
  selectedIndividualCount,
  onAddToGroup,
  onAddFailure,
  onAddSuccess,
  fetchStaticGroups,
  useRequest,
}) {
  const { checked, unChecked } = selectedIndividuals

  const [addingToGroup, setAddingToGroup] = useState(false)
  const [groupId, setGroupId] = useState(null)

  const handleAddToGroup = async () => {
    setAddingToGroup(true)
    const { data, error } = await onAddToGroup(
      groupId,
      filters,
      checked,
      unChecked,
    )
    setAddingToGroup(false)
    if (error) {
      onAddFailure(error)
    } else {
      onAddSuccess(data.group.name)
      onClose()
    }
  }

  const { data: groupsData, loading: loadingGroups } = useRequest(() =>
    fetchStaticGroups(),
  )

  const individualsToBeAddedCount = getIndividualCount(
    selectedIndividualCount,
    selectedIndividuals,
  )

  const individualText = pluralize("individual", individualsToBeAddedCount)

  return (
    <Modal
      header={<Typography.H1>Add to Manual Group</Typography.H1>}
      isOpen={isOpen}
      onClose={onClose}
      rightButtons={
        <>
          <Button
            theme="ghost"
            onClick={onClose}
            disabled={addingToGroup || loadingGroups}
          >
            Cancel
          </Button>
          <Button
            disabled={addingToGroup || loadingGroups || groupsData.error}
            onClick={handleAddToGroup}
          >
            {addingToGroup ? "Adding to Group..." : "Add to Group"}
          </Button>
        </>
      }
    >
      <ModalContentWrapper>
        {groupsData?.error ? (
          <ErrorMessage />
        ) : (
          <SpaceBetween>
            <Typography.Body>
              Up to {individualsToBeAddedCount} {individualText} will be added
              to the group. Any individuals already in the group will remain
              there.
            </Typography.Body>
            <ComboBox
              maxMenuHeight={125}
              disabled={loadingGroups || addingToGroup}
              data={groupsData?.data.results.map((group) => ({
                label: group.name,
                value: group.id,
              }))}
              clearable={false}
              onChange={(selectedItem) => {
                setGroupId(selectedItem.value)
              }}
              isSearchable={true}
              placeholder={
                loadingGroups
                  ? "Loading Manual Groups..."
                  : "Select Manual Group"
              }
            />
          </SpaceBetween>
        )}
      </ModalContentWrapper>
    </Modal>
  )
}

function ErrorMessage() {
  return (
    <Messages
      messages={{
        messages: [
          {
            id: "error-message",
            type: "generic",
            level: "danger",
            content: "Failed to load groups. Please try again.",
          },
        ],
      }}
    />
  )
}

AddToStaticGroupModalPresentation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.string.isRequired,
  selectedIndividuals: PropTypes.shape({
    checkedAll: PropTypes.bool.isRequired,
    checked: PropTypes.array.isRequired,
    unChecked: PropTypes.array.isRequired,
  }),
  selectedIndividualCount: PropTypes.number,
  onAddFailure: PropTypes.func.isRequired,
  onAddSuccess: PropTypes.func.isRequired,
  onAddToGroup: PropTypes.func.isRequired,
  fetchStaticGroups: PropTypes.func.isRequired,
  useRequest: PropTypes.func.isRequired,
}
