const CHANGE_SYSTEM_GROUP = "change_system_group_membership"

export const changeSystemGroupFormat = (actionList) => {
  const change_system_group_list = {
    action_type: CHANGE_SYSTEM_GROUP,
    actions: [],
  }

  actionList
    .filter((actionItem) => actionItem.action_type === CHANGE_SYSTEM_GROUP)
    .forEach((action) => {
      change_system_group_list.actions.push({
        target_system: action.target_system,
        target_group: action.target_group,
      })
    })

  if (!change_system_group_list.actions.length) {
    return actionList
  }
  return actionList
    .filter((actionItem) => actionItem.action_type !== CHANGE_SYSTEM_GROUP)
    .concat(change_system_group_list)
}
