import {
  getPulseStatsByCampaign,
  getHackesmindStatisticsByCampaign,
  getReflexStatisticsByCampaign,
} from "@src/services/apis/scores"

function percent(x, y) {
  if (y === 0) return null
  return (x / y) * 100
}

export async function getPulseOpenStatistic(campaignId) {
  const { data } = await getPulseStatsByCampaign(campaignId)
  const {
    overview: { Sent, UniqueOpens },
  } = data || {}
  return [percent(UniqueOpens, Sent), Sent]
}
export async function getHackersmindCompletionStatistic(campaignId) {
  const { data } = await getHackesmindStatisticsByCampaign(campaignId)
  const { completion_percentage } = data || {}
  return completion_percentage
}
export async function getReflexClickStatistic(campaignId) {
  const { data } = await getReflexStatisticsByCampaign(campaignId)
  const { stats, mails_sent } = data || {}
  const { clicked } = stats || {}
  return percent(clicked, mails_sent)
}
