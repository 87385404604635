import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { EventsLog } from "@src/components/MimecastReskin"

import ExportData from "@src/components/ExportData"

import { darklyGetFlag } from "@src/utils/darkly"
import { eventsLogType, getFiltersParam, getColumns } from "./utils"
import { useEventLogs } from "./useEventLogs"
import { getGlobalConfig } from "@src/globalConfig"

export const EventLogs = (props) => {
  const {
    type,
    exportConfig,
    disableSearch,
    disableFilter,
    showIndividualColumn,
    setTotalEventLogs,
    enableAdditionalDetails,
    additionalNotDateField,
    dragosaurMsg,
  } = props
  const showDataSourceCol = darklyGetFlag(
    "show-data-sources-column-on-events-log",
  )

  // Actual data fetching api calls, search and filters logic is in this custom hook
  const {
    loading,
    data,
    orderBy,
    pagination,
    searchFilter,
    handleSearch,
    filters,
    exportActionTypeByTypeMap,
    onFilterSelection,
    onFilterRemoval,
    clearFilter,
    clearSearch,
    handleSortedChange,
    appliedFilters,
    setAdditionalLog,
  } = useEventLogs(props)

  const columns = useMemo(
    () =>
      getColumns(
        orderBy,
        loading,
        showDataSourceCol,
        showIndividualColumn,
        type,
      ),
    [orderBy, loading, showDataSourceCol, showIndividualColumn],
  )

  const handleChangeTable = async (info) => {
    setAdditionalLog(false)
    const page = info.pagination.offset / info.pagination.limit + 1
    if (
      pagination.current !== page ||
      pagination.pageSize !== info.pagination.limit
    ) {
      pagination.onChange(page, info.pagination.limit)
    }
  }

  const getExportComponent = () => (
    <ExportData
      total={pagination.total}
      type={eventsLogType[type]}
      filters={Object.keys(filters).length ? getFiltersParam(filters) : ""}
      order_by={orderBy}
      actions={exportActionTypeByTypeMap[type]}
      dataLength={data?.list?.length || 0}
    />
  )

  useEffect(() => {
    setTotalEventLogs(pagination?.total)
  }, [pagination])

  if (!Object.keys(eventsLogType)?.includes(type)) return <></>

  return (
    <EventsLog
      loading={loading}
      data={data}
      columns={columns}
      showDataSourceColumn={showDataSourceCol}
      onChangeTable={handleChangeTable}
      onSortedChange={handleSortedChange}
      disableSearch={disableSearch}
      searchValue={searchFilter}
      handleSearch={handleSearch}
      clearSearch={clearSearch}
      disableFilter={disableFilter}
      filters={filters}
      showIndividualColumn={showIndividualColumn}
      enableAdditionalDetails={enableAdditionalDetails}
      orderBy={orderBy}
      totalCount={pagination.total}
      defaultPage={pagination.current}
      defaultPageSize={pagination.pageSize}
      excludeSortColumn={["action", "data_source", "event"]}
      exportData={getExportComponent()}
      clearFilter={clearFilter}
      appliedFilters={appliedFilters}
      onFilterSelection={onFilterSelection}
      onFilterRemoval={onFilterRemoval}
      shouldDisableExport={
        !getGlobalConfig("ENABLE_EVENT_LOGS_EXPORT") || exportConfig.disable
      }
      additionalNotDateField={additionalNotDateField}
      dragosaurMsg={dragosaurMsg}
      showDragosaurImage={getGlobalConfig("ELLIE")}
    />
  )
}

EventLogs.defaultProps = {
  loading: false,
  exportConfig: {
    disable: false,
  },
  disableSearch: false,
  handleSearch: () => {
    // This is intentional
  },
  disableFilter: false,
  eventAppliedFilters: [],
  showIndividualColumn: true,
  setTotalEventLogs: () => {
    // This is intentional
  },
  defaultFilters: [],
  enableAdditionalDetails: false,
}

EventLogs.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  pagination: PropTypes.shape({}),
  exportConfig: PropTypes.shape({
    disable: PropTypes.bool,
    type: PropTypes.string,
    entity: PropTypes.string,
    filters: PropTypes.string,
  }).isRequired,
  disableSearch: PropTypes.bool,
  handleSearch: PropTypes.func,
  disableFilter: PropTypes.bool,
  eventAppliedFilters: PropTypes.array,
  filters: PropTypes.arrayOf({}),
  showIndividualColumn: PropTypes.bool,
  setTotalEventLogs: PropTypes.func,
  enableAdditionalDetails: PropTypes.bool,
  dragosaurMsg: PropTypes.string,
  additionalNotDateField: PropTypes.array,
  defaultFilters: PropTypes.array,
}
