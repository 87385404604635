import React, { useState, useEffect, useRef } from "react"
import {
  InsightDataHeading,
  InsightDetail,
  TabWrapper,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import { Tab, Tabs } from "@elevate_security/elevate-component-library"
import IndividualsInvolved from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/IndividualsInvolved"
import { useInsightData } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/InsightDataProvider"
import VcDataTrends from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/VcDemo/VcDataTrends"
import { getSpecificActionAbleInsight } from "@src/services/apis/reputation"
import { useIntersection } from "@src/scenes/Dashboard/useIntersection"
import { EventLogs } from "@src/components/EventLogs"

function InsightData({ item }) {
  const { individualTotal, eventLogTotal, dataSourceTotal, setEventLogTotal } =
    useInsightData()
  const ref = useRef()
  const inViewport = useIntersection(ref, "0px")
  const [selectedTab, setSelectedTab] = useState(0)
  const [dataTrend, setDataTrend] = useState({})
  const [visitedTab, setVisitedTab] = useState({
    individual_involve: false,
    data_trend: false,
  })
  const [showInsightText, setShowInsightText] = useState({
    event_log: true,
    individual_involve: true,
    data_trend: true,
  })
  const visitedTabs = (currentTab) => {
    if (visitedTab.data_trend && visitedTab.individual_involve) return
    if (currentTab === 1) {
      setVisitedTab({ ...visitedTab, individual_involve: true })
    } else if (currentTab === 2) {
      setVisitedTab({ ...visitedTab, data_trend: true })
    }
  }
  const getSpecificActionAbleInsightHandler = async () => {
    const response = await getSpecificActionAbleInsight(item.insight)
    if (response) {
      setDataTrend(response?.data?.data_trends || {})
    }
  }

  const showText =
    showInsightText?.event_log &&
    showInsightText?.individual_involve &&
    showInsightText?.data_trend

  useEffect(() => {
    getSpecificActionAbleInsightHandler()
  }, [])

  return (
    <div ref={ref}>
      <hr style={{ marginBottom: "40px" }} />
      <InsightDataHeading>Insight Data</InsightDataHeading>
      {showText && (
        <InsightDetail>
          This insight was generated based on{" "}
          {`${eventLogTotal?.toLocaleString()}`}{" "}
          {eventLogTotal === 1 ? "event" : "events"} gathered from
          {` ${dataSourceTotal}`} data sources and applies to
          {` ${individualTotal?.toLocaleString()}`}{" "}
          {individualTotal === 1 ? "individual" : "individuals"} across your
          organization:
        </InsightDetail>
      )}
      <TabWrapper>
        <Tabs
          onChange={(i) => {
            visitedTabs(i)
            setSelectedTab(i)
          }}
          currentTabIndex={selectedTab}
        >
          <Tab
            title={
              showInsightText?.event_log
                ? `Events Log Data (${eventLogTotal?.toLocaleString()})`
                : "Events Log Data"
            }
          >
            {inViewport && (
              <EventLogs
                type="insightEventsLog"
                exportConfig={{
                  disable: false,
                }}
                setTotalEventLogs={setEventLogTotal}
              />
            )}
          </Tab>
          <Tab
            style={{ color: "black" }}
            title={
              individualTotal > 0
                ? `Individuals Involved (${individualTotal})`
                : "Individuals Involved"
            }
          >
            <div style={{ minHeight: "calc(100vh - 360px)" }}>
              {visitedTab.individual_involve && (
                <IndividualsInvolved
                  showInsightText={showInsightText}
                  setShowInsightText={setShowInsightText}
                />
              )}
            </div>
          </Tab>
          <Tab title="Data Trends">
            {visitedTab.data_trend && (
              <VcDataTrends
                item={item}
                setSelectedTab={setSelectedTab}
                visitedTab={visitedTab}
                setVisitedTab={setVisitedTab}
                dataTrend={dataTrend}
              />
            )}
          </Tab>
        </Tabs>
      </TabWrapper>
    </div>
  )
}

export default InsightData
