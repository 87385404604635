import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"

import { Group } from "./Group"
import Criteria from "../Criteria/Criteria"
import { INITIAL_RULE, eventOccurrences } from "./constant"
import { QueryBuilderLoader } from "./Loader"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import { isEmpty } from "@src/utils/string"
import { convertToQueryString } from "./utils/convertToQueryString"
import useGetCriteriaFieldsNames from "./utils/useGetCriteriaFieldsNames"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { initializeEmptyRule } from "@src/services/redux/RiskDetectionRules/RuleInfo/RuleInfoReducer"

const { H4 } = Typography

export const QueryBuilder = (props) => {
  const {
    title,
    showInlineQuery,
    dataSource,
    queryRules: _queryRules,
    onQueryChange,
    allowedNestingLevel,
    loading,
    disableEventOccurances,
  } = props

  // Previously the prop was an array, now it's just the criteria object.
  // We fake the old array here to avoid refactoring all the query builder
  // moving logic all at once.
  const queryRules = useMemo(() => [_queryRules], [_queryRules])

  const setRule = (queryRule) => {
    if (typeof onQueryChange === "function") onQueryChange(queryRule[0])
  }

  const insertCondition = (id, data, condition = null) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === id) {
        const groupsIndex = data[i].rules?.findIndex((rule) => rule.rules)
        data[i].rules?.splice(
          groupsIndex === -1 ? data[i].rules.length : groupsIndex,
          0,
          condition ?? {
            ...INITIAL_RULE,
            uuid: uuidv4(),
          },
        )
        return true
      }
      if (data[i].rules) {
        const found = insertCondition(id, data[i].rules, condition)
        if (found) return true
      }
    }
  }

  const insertGroup = (id, data, rules = [initializeEmptyRule()]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === id) {
        const group = {
          uuid: uuidv4(),
          condition: "and",
          removable: true,
          rules,
        }
        data[i].rules.push(group)
        return group
      }
      if (data[i].rules) {
        const found = insertGroup(id, data[i].rules, rules)
        if (found) return found
      }
    }
  }

  const changeOperation = (id, condition, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === id) {
        data[i].condition = condition
        return true
      }
      if (data[i].rules) {
        const found = changeOperation(id, condition, data[i].rules)
        if (found) return true
      }
    }
  }

  const getDefaultValueForOperator = (operatorName) => {
    switch (operatorName) {
      case "rbetween":
        return [0, "weeks", 0, "weeks"]
      case "rle":
      case "rge":
        return [0, "weeks"]
      default:
        return ""
    }
  }

  const handleChange = (id, index, field, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === id) {
        let params = {
          ...data[i].rules[index],
          [field.name]: field.value,
        }
        if (field.name === "field") {
          params = {
            ...params,
            operator: "",
            value: "",
          }
        }
        if (field.name === "operator") {
          params = {
            ...params,
            value: getDefaultValueForOperator(field.value),
          }
        }
        /* Check if category has occurance then show */
        if (field.name === "category") {
          const occurrence = dataSource?.occurrences?.fields[field.value]
          delete params.eventOccurrences
          params = {
            ...params,
            field: "",
            operator: "",
            value: "",
          }
          if (occurrence !== undefined && !disableEventOccurances) {
            params = {
              ...params,
              eventOccurrences,
            }
          }
        }

        data[i].rules[index] = params
        return true
      }
      if (data[i].rules) {
        const found = handleChange(id, index, field, data[i].rules)
        if (found) return true
      }
    }
  }

  const removeCondition = (id, index, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === id) {
        if (typeof index === "number") {
          const [removed] = data[i].rules.splice(index, 1)
          return removed
        }
      }
      if (data[i].rules) {
        const found = removeCondition(id, index, data[i].rules)
        if (found) return found
      }
    }
  }

  const removeGroup = (id, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].condition) {
        const targetIndex = data[i].rules.findIndex((rule) => rule.uuid === id)
        if (targetIndex > -1) {
          data[i].rules.splice(targetIndex, 1)
          return true
        }
      }
      if (data[i].rules) {
        const found = removeGroup(id, data[i].rules)
        if (found) return true
      }
    }
  }

  const addCondition = (id, condition = null) => {
    const ruleCopy = [...queryRules]

    if (insertCondition(id, ruleCopy, condition)) {
      setRule(ruleCopy)
    }
  }

  const addGroup = (id, rules) => {
    const ruleCopy = [...queryRules]

    const group = insertGroup(id, ruleCopy, rules)

    if (group) {
      setRule(ruleCopy)
    }

    return group
  }

  const toggleOperation = (id, condition) => {
    const ruleCopy = [...queryRules]

    if (changeOperation(id, condition, ruleCopy)) {
      setRule(ruleCopy)
    }
  }

  const deleteCondition = (id, index) => {
    const ruleCopy = [...queryRules]

    const removedCondition = removeCondition(id, index, ruleCopy)

    if (removedCondition) {
      setRule(ruleCopy)
    }

    return removedCondition
  }

  const deleteGroup = (id) => {
    const ruleCopy = [...queryRules]

    if (removeGroup(id, ruleCopy)) {
      setRule(ruleCopy)
    }
  }

  const onHandleFieldChange = (id, index, field) => {
    const ruleCopy = [...queryRules]

    if (handleChange(id, index, field, ruleCopy)) {
      setRule(ruleCopy)
    }
  }

  const addCompoundCondition = (id, index, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === id) {
        const params = {
          ...data[i].rules[index],
        }

        if (params.compound_conditions?.length > 0) {
          // push new item
          params.compound_conditions.push({
            field: "",
            operator: "",
            value: [""],
          })
        } else {
          // put field, operator and value as a first item of compound_conditions
          params.compound_conditions = [
            {
              field: params.field,
              operator: params.operator,
              value: params.value,
            },
            {
              field: "",
              operator: "",
              value: "",
            },
          ]
          // remove field, operator and value from main rule
          delete params.field
          delete params.operator
          delete params.value
        }

        data[i].rules[index] = params
        return true
      }
      if (data[i].rules) {
        const found = addCompoundCondition(id, index, data[i].rules)
        if (found) return true
      }
    }
  }

  const onAddCompoundCondition = (id, index) => {
    const ruleCopy = [...queryRules]

    if (addCompoundCondition(id, index, ruleCopy)) {
      setRule(ruleCopy)
    }
  }

  const removeCompoundCondition = (
    groupId,
    conditionIndex,
    compoundConditionIndex,
    data,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === groupId) {
        if (
          typeof conditionIndex === "number" &&
          typeof compoundConditionIndex === "number"
        ) {
          data[i].rules[conditionIndex]?.compound_conditions?.splice(
            compoundConditionIndex,
            1,
          )

          if (
            data[i].rules[conditionIndex]?.compound_conditions?.length === 1
          ) {
            // Lift the solitary compound condition to the rule level, since
            // it's the only condition for the rule
            data[i].rules[conditionIndex] = {
              ...data[i].rules[conditionIndex],
              ...data[i].rules[conditionIndex]?.compound_conditions[0],
            }
            delete data[i].rules[conditionIndex].compound_conditions
          }
        }
        return true
      }
      if (data[i].rules) {
        const found = removeCompoundCondition(
          groupId,
          conditionIndex,
          compoundConditionIndex,
          data[i].rules,
        )
        if (found) return true
      }
    }
  }

  const onRemoveCompoundCondition = (
    groupId,
    conditionIndex,
    compoundConditionIndex,
  ) => {
    const ruleCopy = [...queryRules]

    if (
      removeCompoundCondition(
        groupId,
        conditionIndex,
        compoundConditionIndex,
        ruleCopy,
      )
    ) {
      setRule(ruleCopy)
    }
  }

  const handleCompoundConditionFieldChange = (
    groupId,
    conditionIndex,
    compoundConditionIndex,
    field,
    data,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === groupId) {
        let params = {
          ...data[i].rules[conditionIndex].compound_conditions[
            compoundConditionIndex
          ],
          [field.name]: field.value,
        }
        if (field.name === "field") {
          params = {
            ...params,
            operator: "",
            value: "",
          }
        }
        if (field.name === "operator") {
          params = {
            ...params,
            value: getDefaultValueForOperator(field.value),
          }
        }

        data[i].rules[conditionIndex].compound_conditions[
          compoundConditionIndex
        ] = params
        return true
      }
      if (data[i].rules) {
        const found = handleCompoundConditionFieldChange(
          groupId,
          conditionIndex,
          compoundConditionIndex,
          field,
          data[i].rules,
        )
        if (found) return true
      }
    }
  }

  const onHandleCompoundConditionFieldChange = (
    groupId,
    conditionIndex,
    compoundConditionIndex,
    field,
  ) => {
    const ruleCopy = [...queryRules]

    if (
      handleCompoundConditionFieldChange(
        groupId,
        conditionIndex,
        compoundConditionIndex,
        field,
        ruleCopy,
      )
    ) {
      setRule(ruleCopy)
    }
  }

  const { isLoadingFieldsNames, fieldsNamesMap } =
    useGetCriteriaFieldsNames(queryRules)

  const criteriaString = useMemo(() => {
    if (!showInlineQuery) {
      return ""
    }
    return convertToQueryString(queryRules[0], {
      groupsMap: fieldsNamesMap,
      annotate: true,
    })
  }, [queryRules, showInlineQuery, fieldsNamesMap])

  return (
    <SpaceBetween size="sm">
      {!isEmpty(title) && <H4 color="700">{title}</H4>}
      <SpaceBetween size="sm">
        {showInlineQuery && (
          <Criteria
            criteria={criteriaString}
            isLoading={isLoadingFieldsNames}
          />
        )}
        {loading && <QueryBuilderLoader />}
        {!loading && (
          <Group
            id={queryRules?.[0].uuid}
            group={queryRules?.[0]}
            level={1}
            allowedNestingLevel={allowedNestingLevel}
            dataSource={dataSource}
            onAddGroup={addGroup}
            onAddCondition={addCondition}
            toggleOperation={toggleOperation}
            deleteCondition={deleteCondition}
            deleteGroup={deleteGroup}
            onHandleFieldChange={onHandleFieldChange}
            onAddCompoundCondition={onAddCompoundCondition}
            onRemoveCompoundCondition={onRemoveCompoundCondition}
            onHandleCompoundConditionFieldChange={
              onHandleCompoundConditionFieldChange
            }
            fieldsNamesMap={fieldsNamesMap}
          />
        )}
      </SpaceBetween>
    </SpaceBetween>
  )
}

QueryBuilder.defaultProps = {
  showInlineQuery: false,
  dataSource: {},
  queryRules: {},
  onQueryChange: () => {},
  allowedNestingLevel: 3,
  loading: false,
  disableEventOccurances: false,
}

QueryBuilder.propTypes = {
  title: PropTypes.string,
  showInlineQuery: PropTypes.bool,
  dataSource: PropTypes.object,
  queryRules: PropTypes.object,
  onQueryChange: PropTypes.func,
  allowedNestingLevel: PropTypes.number,
  loading: PropTypes.bool,
  disableEventOccurances: PropTypes.bool,
}
