import styled from "styled-components"

export const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #c9cfd6;
  padding: 24px;
  margin-top: 18px;
  margin-bottom: 32px;
`

export const TextRow = styled.div`
  > span {
    font-size: 16px;
    line-height: 24px;
    .text__green {
      font-weight: bold;
      color: #15785f;
    }
    .text__red {
      font-weight: bold;
      color: #c73434;
    }
  }
`
