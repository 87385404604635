import fetcher from "@src/services/fetcher"
import { subtractYear, currentDate } from "@src/utils/dates"
import { getErrorStatus } from "@src/services/fetcher/utils"
import { transformScoresEmployeesApiParamsToString } from "@src/utils/scores"

import {
  BASE_SCORES_API_URL,
  BASE_EP_API_URL,
  BASES_REFLEX_API_URL,
  BASES_REPUTATIONS_API_URL,
} from "@src/constants"

/**
 * Get the hierarchical scores for the team associated to
 * the specified person within a date range
 *
 * Api documentation reference: https://github.com/ElevateSecurity/scores-service/blob/master/app/openapi.yml
 *
 * @export function
 * @param {*} {
 *   organization_nid,
 *   person_nid,
 *   start_date = subtractYear(),
 *   end_date = currentDate(),
 *   group_by = "datetime-monthly,category"
 * }
 *
 * @returns {Object}
 */
export async function getHierarchicalScores({
  organization_nid,
  start_date = subtractYear({}),
  end_date = currentDate({}),
  group_by = "datetime-monthly,category",
}) {
  try {
    const API_URL = BASE_SCORES_API_URL()
    const params = {
      start_date,
      end_date,
      group_by,
    }

    const response = await fetcher.get(
      `${API_URL}/organizations/${organization_nid}/persons/null/hierarchicalScores`, // TODO:  person_nid seems not working
      { params },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getScoreByDynamicEndpoint({
  apiUrl = "",
  limit = 25,
  offset = 0,
  ordering = "",
  score_ordering = "",
  filters = "",
}) {
  try {
    const params = transformScoresEmployeesApiParamsToString({
      limit,
      offset,
      ordering,
      score_ordering,
      filters,
    })
    const { status, data } = await fetcher.get(`${apiUrl}?${params}`)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getScoresByIndividuals({
  limit = 25,
  offset = 0,
  ordering = "",
  score_ordering = "",
  filters = "",
}) {
  const API_URL = BASE_EP_API_URL()
  try {
    const params = transformScoresEmployeesApiParamsToString({
      limit,
      offset,
      ordering,
      score_ordering,
      filters,
    })
    const { status, data } = await fetcher.get(
      `${API_URL}/scores/employeesV2?${params}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getEmployeeCount({ limit = 1, offset = 0 }) {
  try {
    const API_URL = BASE_EP_API_URL()
    const params = `limit=${limit}&offset=${offset}`
    const { status, data } = await fetcher.get(
      `${API_URL}/scores/employeesV2?${params}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function legacyDownloadScores(filter) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data, headers } = await fetcher.get(
      `${API_URL}/scores/${filter}s/report`,
    )
    return { status, data, headers }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function downloadScoresByIndividuals() {
  try {
    const API_URL = BASE_EP_API_URL()

    const { status, data, headers } = await fetcher.get(
      `${API_URL}/scores/employees-csv-v3`,
    )
    return { status, data, headers }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function downloadScoresByInsightData(actions, filters) {
  let setParams = ""
  if (filters !== "" && filters !== null) {
    setParams = `filters=${filters}&`
  }
  try {
    const { status, data, headers } = await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${actions}/events?${setParams}download=True`,
    )
    return { status, data, headers }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function downloadIndividualData(actions, filters) {
  let setParams = ""
  if (filters !== "" && filters !== null) {
    setParams = `filters=${filters}&`
  }
  try {
    const response = await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/insights/${actions}/individuals?${setParams}download=True`,
    )
    return {
      status: response.status,
      data: response.data,
      headers: response.headers,
    }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getScoresByDepartment({
  limit = 25,
  offset = 0,
  ordering = "department",
  organization_nid,
}) {
  try {
    const API_URL = BASE_SCORES_API_URL()
    const params = {
      limit,
      offset,
      ordering,
    }
    const { status, data } = await fetcher.get(
      `${API_URL}/organizations/${organization_nid}/departments`,
      {
        params,
      },
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getScoresByLocation({
  limit = 25,
  offset = 0,
  ordering = "location",
  organization_nid,
}) {
  try {
    const API_URL = BASE_SCORES_API_URL()
    const params = {
      limit,
      offset,
      ordering,
    }

    const { status, data } = await fetcher.get(
      `${API_URL}/organizations/${organization_nid}/locations`,
      {
        params,
      },
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSearchSuggestions({ search = null }) {
  try {
    const API_URL = BASE_EP_API_URL()
    const params = {
      search,
    }
    const { status, data } = await fetcher.get(
      `${API_URL}/scores/search-suggestions`,
      {
        params,
      },
    )

    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPulseStatsByCampaign(campaign_id) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.get(
      `${API_URL}/scores/pulse-stats/?campaign_id=${campaign_id}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getHackesmindStatisticsByCampaign(campaign_id) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.get(
      `${API_URL}/hackersmindcampaigns/${campaign_id}/statistics/`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getReflexStatisticsByCampaign(campaign_id) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${campaign_id}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getHistoricalScoresByGroupIds(
  organization_nid,
  group_type,
  group_nids,
) {
  try {
    const API_URL = BASE_SCORES_API_URL()
    const group_nids_array = group_nids
      .map((id) => `group_nids=${id}`)
      .join("&")
    const { status, data } = await fetcher.get(
      `${API_URL}/organizations/${organization_nid}/${group_type}/historicalScores?${group_nids_array}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getHierarchicalHistoricalActionsScore(
  organization_nid,
  person_nid,
  start_date,
  end_date,
) {
  try {
    const API_URL = BASE_SCORES_API_URL()
    const { status, data } = await fetcher.get(
      `${API_URL}/organizations/${organization_nid}/persons/${person_nid}/hierarchicalScores?start_date=${start_date}&end_date=${end_date}&group_by=datetime-monthly,category`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
