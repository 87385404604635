import styled from "styled-components"

export const GaugeGraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  & > div:nth-child(1) {
    flex: 1 0 auto;
  }

  & > div:nth-child(2) {
    flex: 2 0 700px;
  }
`

export const GaugeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const GaugeHeaderWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const GaugeHeader = styled.h5`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #34383c;
  margin-bottom: 4px;
  margin-right: 8px;
`

export const GaugeHeaderIcon = styled.div`
  svg {
    // for some reason max-width is set to 100% in the CSS reset, which causes bizarre
    // shrinking behaviour, so we manually remove it here
    max-width: unset;
  }
`

export const GaugeSubHeader = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
`

export const GaugeFooter = styled.div`
  color: #000;
  text-align: center;
  margin-top: 8px;
`
