import store from "@src/services/redux/store"

export function selectState(state = store.getState()) {
  return state.get("trendSummaryReducer") || {}
}

export function selectTrendSummaryCampaigns(state) {
  const {
    trendSummaryCampaigns: { data, isLoaded },
  } = selectState(state)
  return {
    data,
    isLoaded,
  }
}
const getOverallScore = (group) =>
  group?.groups && Array.isArray(group.groups)
    ? group.groups.find(({ key }) => key === "overall")
    : {}

export function selectTrendSummaryOverallScore(state) {
  const {
    trendSummaryHistoricalScores: { data, isLoaded },
  } = selectState(state)

  const groups =
    data && data?.hierarchicalScores?.groups
      ? data.hierarchicalScores.groups
      : []

  const lastScore =
    groups && Array.isArray(groups) && groups.length > 0
      ? groups[groups.length - 1]
      : null

  const firstScore =
    groups && Array.isArray(groups) && groups.length > 0 ? groups[0] : null

  const firstMonthOverallScore = getOverallScore(firstScore)
  const lastMonthOverallScore = getOverallScore(lastScore)

  const overallLastMonth = lastMonthOverallScore?.scores?.score
    ? lastMonthOverallScore.scores.score
    : 0

  const overallFirstMonth = firstMonthOverallScore?.scores?.score
    ? firstMonthOverallScore.scores.score
    : 0

  const overallTrend = overallLastMonth - overallFirstMonth
  const trend = `${overallTrend > 0 ? "+" : ""}${overallTrend}`

  return {
    overallLastMonth,
    overallFirstMonth,
    overallTrend: trend,
    isLoaded,
  }
}
