import fetcher from "@src/services/fetcher"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"
import { buildQueryString } from "@src/utils/buildQueryString"

export function fetchDetections({ limit, offset, filters, orderBy }) {
  const queryParams = {
    page_size: limit,
    page_number: offset / limit + 1,
    filters,
    order_by: orderBy,
  }

  const queryString = buildQueryString(queryParams)

  return fetcher.get(
    `${BASES_REPUTATIONS_API_URL}/analysis/detections?${queryString}`,
  )
}

export function fetchDetectionsFilters() {
  return fetcher.get(`${BASES_REPUTATIONS_API_URL}/analysis/detections/filters`)
}
