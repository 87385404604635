import styled from "styled-components"

export const IconContainer = styled.div`
  width: 15px;
  height: 20px;
  margin-left: 5px;
  margin-bottom: 3px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    padding-left: 4px;
    padding-right: 4px;
    width: 28px;
    height: 28px;
    &:focus {
      margin-top: 0px;
      border: 0px;
      box-shadow: none;
      svg {
        margin-top: -8px;
        margin-bottom: 0px !important;
      }
    }
    svg {
      margin-top: -15px;
      margin-bottom: 0px !important;
      width: 18px !important;
      height: 18px !important;
    }
  }
`
