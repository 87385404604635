export const ColumnNameMap = {
  overall: "Human Risk Score",
  physical: "Physical Security",
  cybersecurity_knowledge: "Security Knowledge",
  real_world_phishing: "Actual Phishing",
  phishing: "Simulated Phishing",
  access_factor: "Access Factor",
  attack_factor: "Attack Factor",
}
export const sortNameMap = {
  real_world_phishing: "actual_phishing",
  phishing: "simulated_phishing",
  cybersecurity_knowledge: "security_knowledge",
}

export const actionKeyLabelMap = {}
Object.keys(sortNameMap).forEach(
  (key) => (actionKeyLabelMap[sortNameMap[key]] = key),
)
export const dummyData = []

for (let i = 0; i < 25; i++) {
  dummyData.push({
    risks: {
      account_compromise: 0,
      availability_impact: 0,
      cybersecurity_knowledge: 0,
      data_loss: 0,
      malware: 0,
      overall: 0,
      physical: 0,
      privacy_violation: 0,
    },
  })
}

const COLUMNS = {
  email: "Email",
  organizational_role: "Organizational Role",
  employment_type: "Employment Type",
  tenure_duration: "Tenure Duration",
  tenure_status: "Tenure Status",
  attack_factor: "Attack Factor",
  action_factor: "Action Factor",
  department: "Department",
  location: "Location",
  manager_name: "Manager",
  job_title: "Job Title",
  business_title: "Business Title",
  business_unit: "Business Unit",
  is_onleave: "Is On-Leave",
  job_family_function_group: "Job Family Function Group",
  job_family_function_name: "Job Family Function Name",
  preferred_language: "Preferred Language",
  preferred_name: "Preferred Name",
  preferred_middle_name: "Preferred Middle Name",
  preferred_name_local_lang: "Preferred Name Local Lang",
  time_type: "Time Type",
  work_location_country: "Work Location Country",
  work_location_geo: "Work Location Geo",
  work_location_timezone: "Work Location Timezone",
  worker_type: "Worker Type",
  work_location_building: "Work Location Building",
  work_location_city: "Work Location City",
  work_location_name: "Work Location Name",
  work_space: "Work Space",
  length_of_service: "Length Of Service",
  hire_date: "Hire Date",
  is_active: "Is Active",
  leave_date: "Leave Date",
  alternative_user_id_1: "Alternative User Id 1",
  alternative_user_id_2: "Alternative User Id 2",
}

export const INDIVIDUALS_COLUMNS = Object.keys(COLUMNS).map((column) => {
  return {
    key: column,
    label: COLUMNS[column],
  }
})

export const LIST_FILTERS = [
  "location",
  "department",
  "action_categories",
  "organizational_role",
  "tenure_status",
  "employment_type",
  "job_title",
  "business_title",
  "business_unit",
  "es_manager_id",
  "is_onleave",
  "job_family_function_group",
  "job_family_function_name",
  "manager_nid",
  "preferred_language",
  "preferred_name",
  "preferred_middle_name",
  "preferred_name_local_lang",
  "time_type",
  "work_location_country",
  "work_location_geo",
  "work_location_timezone",
  "worker_type",
  "work_location_building",
  "work_location_city",
  "work_location_name",
  "work_space",
  "length_of_service",
  "hire_date",
  "is_active",
  "leave_date",
  "alternative_user_id_1",
  "alternative_user_id_2",
]

// These are all possible fields of hr meta data
export const ALL_HR_METADATA_FIELDS = [
  "job_title",
  "business_title",
  "business_unit",
  "es_manager_id",
  "is_onleave",
  "job_family_function_group",
  "job_family_function_name",
  "manager_nid",
  "preferred_language",
  "preferred_name",
  "preferred_middle_name",
  "preferred_name_local_lang",
  "time_type",
  "work_location_country",
  "work_location_geo",
  "work_location_timezone",
  "worker_type",
  "work_location_building",
  "work_location_city",
  "work_location_name",
  "work_space",
  "length_of_service",
  "hire_date",
  "is_active",
  "leave_date",
  "alternative_user_id_1",
  "alternative_user_id_2",
]

export const UNCHECKED_COLUMNS = [...ALL_HR_METADATA_FIELDS]

export const BOOLEAN_VALUES_MAP = {
  true: "Yes",
  false: "No",
}
