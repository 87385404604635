import {
  COMBOBOX_GROUPS,
  COMBOBOX_GROUPS_COUNTS,
  COMBOBOX_GROUPS_DATA_PAGE_NUMB,
  COMBOBOX_GROUPS_LOADER,
  EXCLUDING_INDIVIDUALS_RULE_INFO,
  RULE_DESCRIPTION_RULE_INFO,
  RULE_LOADING_COUNT_RULE_INFO,
  RULE_NAME_RULE_INFO,
  RULE_SPECIFY_GROUP_RULE_INFO,
  SET_QUERY_RULE,
  RESET_QUERY_RULE,
  SET_QUERY_BUILDER_SCHEMA,
  SET_USE_MAILTRAP,
  SET_IS_RULE_NAME_UPDATED,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/types"
import { v4 as uuidv4 } from "uuid"

export const initializeEmptyRule = (category = "") => ({
  category,
  field: "",
  operator: "",
  value: "",
  uuid: uuidv4(),
})

const INITIAL_STATE = {
  comboboxGroupsData: [],
  pageNumber: 1,
  loader: false,
  ruleName: "",
  isRuleNameUpdated: false,
  ruleDescription: "",
  specifyGroup: {
    incorporate: "exclude",
    mode: "all",
    toggle: false,
  },
  excludingIndividuals: [],
  loadingCount: false,
  count: 0,
  queryRules: {
    uuid: uuidv4(),
    condition: "and",
    rules: [initializeEmptyRule("people")],
  },
  loadingSchema: true,
  queryBuilderSchema: {
    categories: [],
    operators: {},
  },
  useMailtrap: false,
}

const ruleInfoReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case COMBOBOX_GROUPS:
      return {
        ...state,
        comboboxGroupsData: action.comboboxGroupsData,
      }
    case COMBOBOX_GROUPS_DATA_PAGE_NUMB:
      return {
        ...state,
        pageNumber: action.pageNumber,
      }
    case COMBOBOX_GROUPS_LOADER:
      return {
        ...state,
        loader: action.loader,
      }
    case RULE_NAME_RULE_INFO:
      return {
        ...state,
        ruleName: action.ruleName,
        isRuleNameUpdated: true,
      }
    case RULE_DESCRIPTION_RULE_INFO:
      return {
        ...state,
        ruleDescription: action.ruleDescription,
      }
    case RULE_SPECIFY_GROUP_RULE_INFO:
      return {
        ...state,
        specifyGroup: action.specifyGroup,
      }
    case EXCLUDING_INDIVIDUALS_RULE_INFO:
      return {
        ...state,
        excludingIndividuals: action.excludingIndividuals,
      }
    case RULE_LOADING_COUNT_RULE_INFO:
      return {
        ...state,
        loadingCount: action.loadingCount,
      }
    case COMBOBOX_GROUPS_COUNTS:
      return {
        ...state,
        count: action.count,
      }
    case SET_QUERY_RULE:
      return {
        ...state,
        queryRules: action.queryRule,
      }
    case RESET_QUERY_RULE:
      return {
        ...state,
        queryRules: {
          uuid: uuidv4(),
          condition: "and",
          rules: [initializeEmptyRule("people")],
        },
      }
    case SET_QUERY_BUILDER_SCHEMA:
      return {
        ...state,
        loadingSchema: false,
        queryBuilderSchema: action.queryBuilderSchema,
      }
    case SET_USE_MAILTRAP:
      return {
        ...state,
        useMailtrap: action.flag,
      }
    case SET_IS_RULE_NAME_UPDATED:
      return {
        ...state,
        isRuleNameUpdated: action.isRuleNameUpdated,
      }
    default:
      return state
  }
}

export default ruleInfoReducer
