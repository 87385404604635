import React, { createRef, useEffect, useState } from "react"
import { Typography } from "@elevate_security/elevate-component-library"
import { useRequest } from "ahooks"
import { getGroupsList } from "@src/services/apis/groups"
import PropTypes from "prop-types"
import {
  GROUPS_LIMIT,
  LIMIT_ERROR_MESSAGE,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { useSelector, useDispatch } from "react-redux"
import {
  setComboboxCountsRuleInfo,
  setComboboxGroupsData,
  setComboboxGroupsLoader,
  setComboboxGroupsPageNumb,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import { listGroups } from "./utils"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"
import { ComboBoxWrapper } from "./styles"

const { Text } = Typography

function ComboboxWithGroups({
  selectedGroups,
  setSelectedGroups,
  headerText,
  hasError,
  errorText,
}) {
  const { comboboxGroupsData, pageNumber, loader, count } = useSelector(
    (getReducers) => getReducers.get("ruleInfoReducer"),
  )
  const dispatch = useDispatch()
  const timeout = createRef()
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState("")
  const [lengthError, setLengthError] = useState(false)
  const [groups, setGroups] = useState(selectedGroups)

  const { run: getGroupsData } = useRequest(
    () =>
      getGroupsList({
        name__icontains: `${search}`,
        limit: 50,
        offset: 50 * (pageNum - 1),
      }),
    {
      manual: true,
      formatResult: (response) => {
        if (response?.data) {
          const groupsData = response?.data?.results || []
          dispatch(setComboboxGroupsLoader(false))
          dispatch(setComboboxCountsRuleInfo(response?.data?.count || 0))
          if (pageNum === 1) {
            dispatch(
              setComboboxGroupsData(
                listGroups(groupsData).sort((a, b) =>
                  a.label > b.label ? 1 : -1,
                ),
              ),
            )
          } else {
            dispatch(setComboboxGroupsPageNumb(pageNum))
            dispatch(
              setComboboxGroupsData([
                ...comboboxGroupsData,
                ...listGroups(groupsData),
              ]),
            )
          }
        }
      },
    },
  )

  useEffect(() => {
    if (!comboboxGroupsData.length) {
      dispatch(setComboboxGroupsLoader(true))
      getGroupsData()
    }
  }, [])

  useEffect(() => {
    if (pageNum > pageNumber) {
      dispatch(setComboboxGroupsLoader(true))
      getGroupsData()
    }
  }, [pageNum])

  const handleSetSearch = (searchValue) => {
    if (search === searchValue) return
    clearTimeout(timeout.current)
    dispatch(setComboboxGroupsLoader(true))
    timeout.current = setTimeout(() => {
      dispatch(setComboboxGroupsPageNumb(1))
      setSearch(searchValue)
      setPageNum(1)
      getGroupsData()
    }, 500)
  }

  return (
    <SpaceBetween size="sm">
      <Text color="700" fontWeight="bold">
        {headerText}{" "}
        <span style={{ marginLeft: "10px" }}>{groups.length} / 15</span>
      </Text>
      <ComboBoxWrapper>
        <ComboBoxWithOverlap
          data={comboboxGroupsData}
          defaultValue={groups.length ? groups : []}
          clearable={false}
          onChange={(selected) => {
            if (selected.length > GROUPS_LIMIT) {
              setLengthError(true)
              return
            }
            setGroups(selected.filter((item) => item.label !== "Select all"))
            setSelectedGroups(
              selected.filter((item) => item.label !== "Select all"),
            )
            setLengthError(false)
          }}
          value={groups}
          isSearchable={true}
          isMultiOption={true}
          checked={true}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{}}
          isLoading={loader}
          disabled={false}
          hasError={hasError || lengthError}
          errorMessage={lengthError ? LIMIT_ERROR_MESSAGE : errorText}
          placeholder="Choose or search group(s)"
          handleScrollBotttom={() => {
            if (comboboxGroupsData.length < count) {
              setPageNum(pageNum + 1)
            }
          }}
          handleInputChange={(value) => {
            handleSetSearch(value)
          }}
        />
      </ComboBoxWrapper>
    </SpaceBetween>
  )
}

ComboboxWithGroups.defaultProps = {
  selectedGroups: [],
  setSelectedGroups: () => null,
  showCount: false,
  showHeader: false,
  hasError: false,
  headerText: "Recipient Group(s)",
  showRightLabel: false,
  errorText: "Invalid entry. Please check & try again",
}

ComboboxWithGroups.propTypes = {
  selectedGroups: PropTypes.array,
  setSelectedGroups: PropTypes.func,
  hasError: PropTypes.bool,
  headerText: PropTypes.string,
  errorText: PropTypes.string,
}

export default React.memo(ComboboxWithGroups)
