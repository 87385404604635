import styled from "styled-components"

export const Label = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: 185px;
`

export const ComboBoxContainer = styled.div`
  [class^="ComboBoxstyles__SelectContainer"] {
    margin: 0;
    width: 317px;
  }
  input:disabled {
    background-color: #f5f6f8;
  }
`
export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px 0 0 0;
  width: 100%;
`
