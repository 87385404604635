import fetcher from "@src/services/fetcher"
import { BASE_EP_API_URL } from "@src/constants"
import { getErrorStatus } from "@src/services/fetcher/utils"

const API_URL = BASE_EP_API_URL()

export async function getGroups({ orgId }) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/groups/?organization=${orgId}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getExcludeIndividualsGroupsCount({
  params,
  limit = 25,
  offset = 0,
  target_type,
}) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/employees/?groups=[${params}]&is_active=True&targeting_type=${target_type}&limit=${limit}&offset=${
        limit * offset
      }`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getGroupsList(params) {
  try {
    return await fetcher.get(`${API_URL}/groups/`, {
      params: params,
    })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getFilterGroups(params) {
  return fetcher.get(`${API_URL}/employees/employees_with_groups/`, { params })
}

export async function getGroupsCount({ params }) {
  const { status, data } = await fetcher.get(
    `${API_URL}/employees/?groups=[${params}]&is_active=True`,
  )
  return { status, data }
}
