/*
 * Returns a list of errors given a set of validations to run against the given
 * object.
 *
 * Example usage:
 *
 * validateObject({ subject: "", message: "hello" }, [
 *   ["subject", ({ subject }) => Boolean(subject), "Subject is required"],
 *   ["message", ({ message }) => Boolean(message), "Email body is required"],
 * ])
 *
 * Returns
 *
 * [{ field: "subject", reason: "Subject is required"}]
 */
export function validateObject<T extends object>(
  object: T,
  validators: [keyof T, (object: T) => boolean, string][],
): { field: keyof T; reason: string }[] {
  return validators
    .filter(([, validate]) => !validate(object))
    .map(([field, , reason]) => ({ field, reason }))
}
