import React from "react"
import { LoaderIcon } from "@src/scenes/RiskResponseRuleCreateScreen/components/SailPointIcons/styles"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { IconAction } from "@elevate_security/elevate-component-library"
import { ICON_TYPE } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"
import {
  ShowSecreteWrapper,
  ShowToggleWrapperText,
} from "@src/scenes/RiskResponseRuleCreateScreen/components/AzureadConfirmCompromisedUser/styles"
import PropTypes from "prop-types"

function SailPointIcons({ showSecret, iconHandler, loadingCredentials }) {
  if (loadingCredentials) {
    return (
      <LoaderIcon>
        <ActivityIndicator
          position="top-left"
          size={20}
          border={2}
          active={true}
        />
      </LoaderIcon>
    )
  }
  return (
    <ShowSecreteWrapper>
      <IconAction
        name={ICON_TYPE[showSecret]}
        size="sm"
        onClick={iconHandler}
      />
      <ShowToggleWrapperText>
        {showSecret ? "Hide" : "Show"} Secrets
      </ShowToggleWrapperText>
    </ShowSecreteWrapper>
  )
}

SailPointIcons.propTypes = {
  showSecret: PropTypes.bool.isRequired,
  iconHandler: PropTypes.func.isRequired,
  loadingCredentials: PropTypes.bool,
}

export default SailPointIcons
