import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Spinner, SpinnerBackdrop } from "./style"

const ActivityIndicator = (props) => {
  const { name, active, opacity, size, border, margin } = props
  const [stringSize] = useState(`${size}px`)
  const [current, setCurrent] = useState(active || false)
  const [unloading, setUnloading] = useState(false)
  const [unloaded, setUnloaded] = useState(false)

  let timer

  useEffect(() => {
    if (current !== active && active === false && !unloading) {
      setUnloading(true)
      setCurrent(active)
      clearTimeout(timer)
      timer = setTimeout(() => setUnloaded(true), 300)
    }

    if (current !== active && active === true) {
      setUnloading(false)
      setUnloaded(false)
      setCurrent(active)
    }
  }, [active])

  if (unloaded) {
    return null
  }

  return (
    <React.Fragment>
      {active && (
        <SpinnerBackdrop
          opacity={opacity}
          className={`${unloading ? "unloading" : ""} ${name}-activity`}
          data-id="hm-activity-indicator"
        >
          <Spinner size={stringSize} border={`${border}px`} margin={margin}>
            <div />
            <div />
            <div />
            <div />
          </Spinner>
        </SpinnerBackdrop>
      )}
    </React.Fragment>
  )
}

ActivityIndicator.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  opacity: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.number,
  border: PropTypes.number,
}

ActivityIndicator.defaultProps = {
  name: "default",
  active: false,
  opacity: "50%",
  margin: "auto",
  size: 64,
  border: 8,
}

export default ActivityIndicator
