import PropTypes from "prop-types"
import React, { memo } from "react"

import Button from "@elevate_security/elevate-component-library/dist/Button"
import Link from "@elevate_security/elevate-component-library/dist/Link"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { theme } from "@elevate_security/elevate-component-library"

// Components
import ScoreHorizontalBar from "@src/components/ScoreHorizontalBar"
import BehaviorsBreakdownChart from "@src/components/BehaviorsBreakdownChart"
import ActionItems from "@src/scenes/Dashboard/components/ActionItems"

// Methods
import {
  calculateStragglingPercentage,
  extractWeakestBehaviorName,
  extractWeakestDepartmentName,
} from "@src/components/PerformanceBarChart/utils"
import { getRiskAnalysisURL } from "@src/scenes/IndividualRiskAnalysis/utils"
import { FILTER_KEY } from "@src/scenes/IndividualRiskAnalysis/components/Filters/utils"

import { Row, Column, LinkWrapper } from "./styles"
import { BoxShadow } from "../../styles"

const { H3 } = Typography

function FirstQuestion(props) {
  const {
    securityScore,
    securityScoreLstMonth,
    securityBreakDownBehavior,
    riskiestDepartment,
    riskDistributions,
    history,
  } = props

  const stragglingPercentage = calculateStragglingPercentage(riskDistributions)
  const weakestBehaviorName = extractWeakestBehaviorName(
    securityBreakDownBehavior,
  )
  const riskiestDepartmentName =
    extractWeakestDepartmentName(riskiestDepartment)

  const actionsList = [
    ...(stragglingPercentage > 0
      ? [
          {
            description: (
              <span>
                <strong>{stragglingPercentage}%</strong> of your organization is
                straggling behind with a score of 40 or lower
              </span>
            ),
            action: (
              <Button
                onClick={() =>
                  history.push(
                    getRiskAnalysisURL(
                      "individual",
                      "risk-analysis",
                      `${FILTER_KEY.SCORE_OVERALL}=0-40`,
                    ),
                  )
                }
              >
                Reduce Risk
              </Button>
            ),
          },
        ]
      : []),
    ...(weakestBehaviorName !== ""
      ? [
          {
            description: (
              <span>
                <strong>{weakestBehaviorName}</strong> is your weakest behavior
              </span>
            ),
            action: (
              <LinkWrapper>
                <Link
                  to={getRiskAnalysisURL(
                    "individual",
                    "risk-analysis",
                    `${
                      FILTER_KEY.SORT_SCORE
                    }=${weakestBehaviorName.toLowerCase()}`,
                  )}
                >
                  Improve
                  <Icon name="CaretRight" size="xxsm" />
                </Link>
              </LinkWrapper>
            ),
          },
        ]
      : []),
    ...(riskiestDepartmentName
      ? [
          {
            description: (
              <span>
                <strong>{riskiestDepartmentName}</strong> is your riskiest
                department
              </span>
            ),
            action: (
              <LinkWrapper>
                <Link
                  to={getRiskAnalysisURL(
                    "individual",
                    "risk-analysis",
                    `${FILTER_KEY.SORT_SCORE}=overall&${FILTER_KEY.DEPARTMENT}=${riskiestDepartmentName}`,
                  )}
                >
                  Improve
                  <Icon name="CaretRight" size="xxsm" />
                </Link>
              </LinkWrapper>
            ),
          },
        ]
      : []),
  ]

  return (
    <>
      <ScoreHorizontalBar
        score={securityScore}
        scoreLastMounth={securityScoreLstMonth}
        showOrgScoreMessage={true}
      />
      <BoxShadow>
        <H3 fontWeight="bold" color="900">
          Score Breakdown by Performance
        </H3>
        <br />
        <br />
        <Row>
          <Column padding="40px 20px 0 0">
            <H3
              fontWeight="bold"
              style={{ color: theme.text.color[700], fontSize: 18 }}
            >
              Score Breakdown by Behaviors
            </H3>
            <br />
            <BehaviorsBreakdownChart
              behaviorsStatusData={securityBreakDownBehavior}
            />
          </Column>
          <Column padding="40px 0 0 20px">
            <H3 fontWeight="bold" color="900">
              Action Items
            </H3>
            <ActionItems actionsList={actionsList} />
            <br />
          </Column>
        </Row>
      </BoxShadow>
    </>
  )
}
FirstQuestion.propTypes = {
  securityScore: PropTypes.number.isRequired,
  securityScoreLstMonth: PropTypes.number.isRequired,
  securityBreakDownBehavior: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  riskDistributions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  riskiestDepartment: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}
export default memo(FirstQuestion)
