export const IndividualsEmailTemplate = `
  <div>
    Hi {{people.first_name}}, <br />
    <br /> There has been a recent increase in your risk profile.
    <br />
    Please review the security guidelines and make sure you&apos;re doing what
    you can to minimize the risk of an attack.
    <br />
    <br />
    Thanks,
    <br />
    The Security Team (via Elevate Security)
  </div>
`

export const ParsedIndividualsEmailTemplate = `PHA+SGkgW0lORElWSURVQUxfRklSU1ROQU1FXSw8YnIgLz4KPGJyIC8+ClRoZXJlIGhhcyBiZ
WVuIGEgcmVjZW50IGluY3JlYXNlIGluIHlvdXIgcmlzayBwcm9maWxlLjxiciAvPgpQbGVhc2UgcmV2aWV3IHRoZ
SBzZWN1cml0eSBndWlkZWxpbmVzIGFuZCBtYWtlIHN1cmUgeW91JiMzOTtyZSBkb2luZyB3aGF0IHlvdSBjYW4gd
G8gbWluaW1pemUgdGhlIHJpc2sgb2YgYW4gYXR0YWNrLjxiciAvPgo8YnIgLz4KVGhhbmtzLDxiciAvPgpUaGUgU
2VjdXJpdHkgVGVhbSAodmlhIEVsZXZhdGUgU2VjdXJpdHkpPC9wPgo=`

export const ManagersEmailTemplate = `
  <div>
    Hi {{manager.manager_first_name}},
    <br />
    <br />
    We'd like to update you that {{manager.affected_direct_reports_count}} members of your direct reports were targeted by the risk response rule {{rule.name}}.
    <br />
    <br />
    To help keep our organization safe, we’re asking you to have a quick
    conversation with your team member about good security practices:
    <br />
    <br />
    <ul>
      <li>Installing and using a password manager to keep accounts secure</li>
      <li>
        When receiving emails, double-checking the sender to make sure the email
        address is legitimate. Report suspicious emails to the security team
      </li>
      <li>
        Checking link URLs before clicking on them to avoid malicious websites
      </li>
    </ul>
    We appreciate your help in keeping our organization safe and secure!
    <br />
    <br />
    Thanks,
    <br />
    The Security Team (via Elevate Security)
  </div>
`

export const ParsedManagersEmailTemplate = `PHA+SGkgW01BTkFHRVJfRklSU1ROQU1FXSw8YnIgLz4KPGJyIC8+ClRoaXMgZW1haWwgaX
MgdG8gbm90aWZ5IHlvdSB0aGF0IGEgbWVtYmVyIG9mIHlvdXIgdGVhbSwgW0lORElWSURVQUxfRklSU1ROQU1
FXSBbSU5ESVZJRFVBTF9TVVJOQU1FXSwgaGFzIGV4aGliaXRlZCByaXNreSBzZWN1cml0eSBhY3Rpb25zLjxi
ciAvPgo8YnIgLz4KVG8gaGVscCBrZWVwIG91ciBvcmdhbml6YXRpb24gc2FmZSwgd2UmcnNxdW87cmUgYXNra
W5nIHlvdSB0byBoYXZlIGEgcXVpY2sgY29udmVyc2F0aW9uIHdpdGggeW91ciB0ZWFtIG1lbWJlciBhYm91dC
Bnb29kIHNlY3VyaXR5IHByYWN0aWNlczo8L3A+Cgo8dWw+Cgk8bGk+SW5zdGFsbGluZyBhbmQgdXNpbmcgYSB
wYXNzd29yZCBtYW5hZ2VyIHRvIGtlZXAgYWNjb3VudHMgc2VjdXJlPC9saT4KCTxsaT5XaGVuIHJlY2Vpdmlu
ZyBlbWFpbHMsIGRvdWJsZS1jaGVja2luZyB0aGUgc2VuZGVyIHRvIG1ha2Ugc3VyZSB0aGUgZW1haWwgYWRkc
mVzcyBpcyBsZWdpdGltYXRlLiBSZXBvcnQgc3VzcGljaW91cyBlbWFpbHMgdG8gdGhlIHNlY3VyaXR5IHRlYW
08L2xpPgoJPGxpPkNoZWNraW5nIGxpbmsgVVJMcyBiZWZvcmUgY2xpY2tpbmcgb24gdGhlbSB0byBhdm9pZCB
tYWxpY2lvdXMgd2Vic2l0ZXM8L2xpPgo8L3VsPgoKPHA+V2UgYXBwcmVjaWF0ZSB5b3VyIGhlbHAgaW4ga2Vl
cGluZyBvdXIgb3JnYW5pemF0aW9uIHNhZmUgYW5kIHNlY3VyZSE8YnIgLz4KPGJyIC8+ClRoYW5rcyw8YnIgL
z4KVGhlIFNlY3VyaXR5IFRlYW0gKHZpYSBFbGV2YXRlIFNlY3VyaXR5KTwvcD4K`

export const CustomEmailTemplate = `
  <div>
    Hi there,
    <br />
    <br />
    The following individuals have triggered the {{rule.name}} risk detection
    rule in the past 24 hours:
    <br />
    <br />
    {% for p in people %}<br />
    - Name: {{p.first_name}}, {{p.last_name}} - Email: {{p.email}}<br />
    {% endfor %}<br />
    <br />
    <br />
    Cheers,
    <br />
    Security Team
  </div>
`

export const ParsedCustomsEmailTemplate = `PHA+SGkgdGhlcmUsPGJyIC8+CjxiciAvPgpUaGUgZm9sb
G93aW5nIGluZGl2aWR1YWxzIGhhdmUgdHJpZ2dlcmVkIHRoZSBbUklTS19SVUxFX05BTUVdIHJpc2sgZGV0ZWN0aW
9uIHJ1bGUgaW4gdGhlIHBhc3QgMjQgaG91cnM6PGJyIC8+CjxiciAvPgpbSU5ESVZJRFVBTFNfTElTVF08YnIgLz4
KPGJyIC8+CkNoZWVycyw8YnIgLz4KU2VjdXJpdHkgVGVhbTwvcD4K`
