import React, { useEffect, useState } from "react"
import {
  NoInsightData,
  SkeletonContainer,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import { SkeletonLoader } from "@src/utils/skeleton"
import {
  GraphsWrapper,
  ViewTimePeriodWrapperAlt,
} from "@src/scenes/IndividualProfilePage/ProfileInfo/components/IndividualHumanRiskScoreInfoSection/styles"
import { useParams } from "react-router-dom"
import moment from "moment"
import {
  TIME_PERIOD_LIST,
  TIME_PERIOD_MONTHS,
} from "@src/scenes/HumanRiskDashboard/constants"
import {
  getIndividualHistoricalRisksScore,
  getIndividualScoresHistoricalActionScores,
} from "@src/services/apis/reputation"
import {
  convertGraphData,
  treatHumanRiskScore,
} from "@src/scenes/IndividualProfilePage/ProfileInfo/components/TrendAttackFactor/utils"
import { UNDERSCORED_ACTION_NAMES } from "@src/components/ActionChart/utils"
import { CustomSelect } from "@elevate_security/elevate-component-library"
import { HRSGraph } from "@src/components/MimecastReskin"
import ReactDOM from "react-dom"
import CampaignTooltip from "@src/scenes/Dashboard/components/ThirdQuestion/components/CampaignTooltip"
import ScoreTooltip from "@src/scenes/Dashboard/components/ThirdQuestion/components/ScoreTooltip"
import { humanize } from "@src/utils/string"
import tippy from "tippy.js"
import { setTimePeriodValue } from "@src/services/redux/reputations/actions"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"

const HUMAN_RISK_SCORE_KEY = "Human_Risk_Score"

function TrendAttackGraph({
  timePeriodValue,
  hideItem,
  activeActions,
  showDropDown,
}) {
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(false)
  const [actionTrendGraphData, setActionTrendGraphData] = useState([])
  const [lineFocus, setFocus] = useState(HUMAN_RISK_SCORE_KEY)

  const { profileId } = useParams()

  const getScoresFromIndividual = async () => {
    setFetching(true)
    const startDate = `${moment()
      .subtract(TIME_PERIOD_MONTHS[timePeriodValue], "month")
      .format("YYYY-MM")}-01`

    const [actions_data, risks_data] = await Promise.all([
      getIndividualScoresHistoricalActionScores({
        profileId,
        start_date: startDate,
      }),
      getIndividualHistoricalRisksScore({
        person_nid: profileId,
        start_date: startDate,
      }),
    ])

    if (!actions_data || !risks_data) return
    if (actions_data.status !== 200 || risks_data.status !== 200) return

    const graphData = convertGraphData(actions_data, activeActions)

    if (!hideItem) {
      const hrs_data = treatHumanRiskScore(risks_data.data.overall)

      graphData[HUMAN_RISK_SCORE_KEY] = hrs_data

      delete graphData[UNDERSCORED_ACTION_NAMES["overall"]]
    }

    setActionTrendGraphData(graphData)
  }

  const getActionTrendDDotLine = () => {
    if (lineFocus) return lineFocus
    return Object.keys(actionTrendGraphData).sort((a, b) => (a > b ? 1 : -1))[0]
  }

  useEffect(() => {
    getScoresFromIndividual().finally(() => setFetching(false))
  }, [timePeriodValue])

  const handleOnMouseEnter = (info) => {
    const {
      id,
      elementId,
      score,
      previous_score,
      score_last_quarter,
      behaviourName,
      date,
      typeGraph,
      isCampaignBar,
      campaign_type,
      name,
      status,
      start_date,
      end_date,
      total_targets,
      color,
    } = info
    const snatizedId = elementId.replace(/[.]/g, "-")
    const lastScore =
      typeGraph === "monthly" ? previous_score : score_last_quarter

    const tooltipId = `${snatizedId}-tooltip-content`
    const toolTipContent = document.createElement("div")

    toolTipContent.setAttribute("id", tooltipId)
    toolTipContent.style.display = "block"
    toolTipContent.style.minWidth = "900px"

    if (isCampaignBar) {
      ReactDOM.render(
        <CampaignTooltip
          campaignId={id}
          score={score}
          startDate={start_date}
          endDate={end_date}
          type={campaign_type}
          name={name}
          status={status}
          totalTargets={total_targets}
        />,
        toolTipContent,
      )
    } else {
      const trendScore = (score - lastScore).toFixed(1)
      ReactDOM.render(
        <ScoreTooltip
          score={score?.toFixed(1)}
          month={date}
          maxValue={10}
          trend={lastScore === null ? null : trendScore}
          behaviorName={humanize(behaviourName)}
        />,
        toolTipContent,
      )
    }

    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content: toolTipContent.innerHTML,
      onHide() {
        toolTipContent.remove()
      },
    }
    const element = document.querySelector(`#${snatizedId}`)
    element.setAttribute("fill", color)
    tippy(element, tippyProps)
    element._tippy.show()
  }

  // const focuseLine = getActionTrendDDotLine()
  const actionKeys = Object.keys(actionTrendGraphData)

  if (fetching) {
    return (
      <SkeletonContainer>
        <SkeletonLoader />
        <hr />
        <SkeletonLoader />
        <hr />
        <SkeletonLoader />
        <hr />
        <SkeletonLoader />
        <hr />
        <SkeletonLoader />
      </SkeletonContainer>
    )
  }

  return (
    <GraphsWrapper>
      <ViewTimePeriodWrapperAlt>
        {showDropDown && (
          <CustomSelect
            options={TIME_PERIOD_LIST}
            defaultValue={timePeriodValue}
            onChange={(selected) =>
              dispatch(setTimePeriodValue(selected.value))
            }
          />
        )}
      </ViewTimePeriodWrapperAlt>
      {actionKeys?.length ? (
        <div style={{ marginTop: "65px" }}>
          <HRSGraph
            behaviorScores={actionTrendGraphData}
            campaignScores={[]}
            onMouseEnter={handleOnMouseEnter}
            graphTitle={null}
            yAxisLabel=""
            xAxisLabel=""
            keyToBeDashed={getActionTrendDDotLine()}
            sortAlphabetical={true}
            showHeading={false}
            showTabs={true}
            hideQuarterlyTab={timePeriodValue === "Past 3 Months"}
            changeGraphTabsPosition={true}
            minMaxValue={{
              min: 0.0,
              max: 10.0,
            }}
            yAxisDecimal={true}
            showGradeLabel={true}
            changeLineFocus={setFocus}
            lineFOcus={getActionTrendDDotLine()}
            performanceFlag={true}
            showLegend={true}
            tabsToPosition={-60}
            containerStyle={{
              marginTop: "0px",
            }}
          />
        </div>
      ) : (
        <NoInsightData>
          <h6 style={{ margin: "auto" }}>No Action Data Available</h6>
        </NoInsightData>
      )}
    </GraphsWrapper>
  )
}

export default TrendAttackGraph

TrendAttackGraph.propTypes = {
  timePeriodValue: PropTypes.string.isRequired,
  hideItem: PropTypes.bool.isRequired,
  activeActions: PropTypes.string.isRequired,
  showDropDown: PropTypes.bool.isRequired,
}
