import React from "react"
import { Score } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/columns"
import { SkeletonLoader } from "@src/utils/skeleton"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import {
  ActionsWrapper,
  IconWrapper,
  IndividualsRibbon,
} from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import { Individual } from "@src/scenes/SecEngIndividualRiskAnalysis/components/IndividualRiskAnalysisTable/columns"
import { FILTER_TYPE } from "@src/scenes/IndividualProfilePage/components/utils"
import { humanize } from "@src/utils/string"
import { actions } from "@src/scenes/IndividualProfilePage/ProfileInfo/components/EventsLog/components/EventsLogTable/more_filters_options"
import ActivityIndicator from "@src/components/ActivityIndicator"

const DEFAULT_TEXT = [
  "Indestructible",
  "Fortified",
  "Sturdy",
  "Tenuous",
  "Flimsy",
]
const WFS_TEXT = [
  "Black Belt",
  "Green Belt",
  "Yellow Belt",
  "White Belt",
  "Not Ninja",
]
export const managerScoreColors = (score) => {
  const isWFSDomain = window.location.hostname?.split(/[._-]/)?.[0] === "wfs"

  const TEXT = isWFSDomain ? WFS_TEXT : DEFAULT_TEXT // wfs_text just for wfs organization

  if (score >= 0 && score < 2) {
    return {
      color: "#15785F",
      backgroundColor: "#E3F6F2",
      text: TEXT[0],
    }
  }
  if (score >= 2 && score < 4) {
    return { color: "#15785F", backgroundColor: "#E3F6F2", text: TEXT[1] }
  }
  if (score >= 4 && score < 6) {
    return { color: "#CB7D20", backgroundColor: "#FFF3CA", text: TEXT[2] }
  }
  if (score >= 6 && score < 8) {
    return { color: "#CB7D20", backgroundColor: "#FFF3CA", text: TEXT[3] }
  }
  if (score >= 8 && score <= 10) {
    return { color: "#C73434", backgroundColor: "#F6E3E3", text: TEXT[4] }
  }

  return { color: "#8995A4", backgroundColor: "#E3F6F2", text: "Invalid Score" }
}
const IsLoading = ({ children, loading, row, item }) => {
  const renderChild = {
    individual: (
      <div className="pl-3 py-2 d-flex justify-content-between">
        <Individual
          id={row?.es_manager_id || ""}
          riskType="managerDashboard"
          name="View Manager-Dashboard"
        />
      </div>
    ),
  }

  if (!loading) {
    if (row?.redirectManager === true) {
      return renderChild[item] || <div style={{ pointerEvents: "none" }} />
    }
    return <div className="pl-3 d-flex justify-content-between">{children}</div>
  }

  return (
    <div className="p-2" style={{ width: "90%" }}>
      <SkeletonLoader height={20} />
    </div>
  )
}
export const getColumns = (loading, managerInfo, setDrawer, whoAmI) => {
  const isAdmin = !whoAmI.is_manager && whoAmI.is_admin

  return [
    {
      key: "full_name",
      title: "FULL NAME",
      dataIndex: "full_name",
      width: "25%",
      render: (value, row) => {
        const isManager = row?.is_manager
        return !isManager ? (
          <IsLoading loading={loading} row={row} item="individual">
            <div className="d-flex">
              <Individual
                id={row?.es_person_id || ""}
                riskType={row?.riskType || "individual"}
                name={value}
                width="auto"
              />
            </div>
          </IsLoading>
        ) : (
          <></>
        )
      },
    },
    ...(isAdmin
      ? [
          {
            key: "human_risk_score",
            title: `HUMAN RISK SCORE`,
            dataIndex: "human_risk_score",
            render: (_, row) => (
              <IsLoading loading={loading} row={row} item="human_risk_score">
                <Score
                  score={
                    row[
                      row?.team_human_risk_key || "human_risk_score"
                    ]?.toFixed(1) || 0
                  }
                />
              </IsLoading>
            ),
          },
        ]
      : []),
    {
      key: "actions_score",
      title: `ACTION FACTOR SCORE`,
      dataIndex: "actions_score",
      render: (_, row) => (
        <IsLoading loading={loading} row={row} item="actions_score">
          <Score
            score={
              row[row?.team_action_key || "actions_score"]?.toFixed(1) || 0
            }
          />
        </IsLoading>
      ),
    },
    {
      key: "department",
      title: "DEPARTMENT",
      dataIndex: "department",
      render: (value, row) => (
        <IsLoading loading={loading} row={row} item="department">
          <>{value?.toUpperCase()}</>
        </IsLoading>
      ),
    },
    {
      key: "manager_name",
      title: "MANAGER",
      dataIndex: "manager_name",
      render: (value, row) => {
        return (
          <IsLoading loading={loading} row={row} item="manager_name">
            <Individual
              id={row?.es_manager_id || ""}
              riskType="managerDashboard"
              name={`${value} ${
                whoAmI.id === row.es_manager_id ? "(you)" : ""
              }`}
            />
          </IsLoading>
        )
      },
    },
    {
      key: "action",
      title: "ACTION",
      render: (value, row) => {
        const profileText = row?.riskType
          ? "View Full Dashboard"
          : "View Full Profile"

        return (
          <IsLoading loading={loading} row={row} item="action">
            <ActionsWrapper
              onClick={() => {
                setDrawer({
                  isOpen: true,
                  es_person_id: row?.es_person_id || "",
                  name: row.full_name,
                })
              }}
            >
              <Individual
                id={row?.es_person_id || ""}
                riskType={row?.riskType ? row?.riskType : "individual"}
                name={profileText}
                width="auto"
              />
              <Icon name="CaretRight" size="xxsm" fill="#0C7D84" />
            </ActionsWrapper>
          </IsLoading>
        )
      },
    },
  ]
}

export const getOptionParams = (deptsLoaded, departments) => [
  {
    department: deptsLoaded ? departments : [],
  },
]

export const managerFilterList = (params) => [
  {
    type: FILTER_TYPE.SLIDER,
    title: "Human Risk Score".toUpperCase(),
    key: "overall",
    items: actions?.map((decision) => ({
      label: humanize(decision),
      value: humanize(decision),
    })),
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: "department".toUpperCase(),
    key: "department",
    showAllFilter: true,
    items: params?.department
      ?.sort((a, b) => (a > b ? 1 : -1))
      ?.map((decision) => ({
        label: humanize(decision),
        value: decision,
      })),
    ...(params?.department &&
      params?.department?.length > 4 && {
        showLess: "Show less departments",
        showMore: "Show all departments",
      }),
  },
]

export const EXPENDED_ICONS = {
  loading: ({ record, managerTeamLoader }) => (
    <IsLoading loading={managerTeamLoader} row={record} item="individual">
      <div className="d-flex">
        <Individual
          id={record?.es_person_id || ""}
          riskType={record?.riskType || "individual"}
          name={record.full_name}
          width="100%"
        />
        <ActivityIndicator active={true} size={20} border={2} />
      </div>
      <IndividualsRibbon color="#1A518E" background="#E3ECF6">
        Manager
      </IndividualsRibbon>
    </IsLoading>
  ),
  caret: ({
    record,
    onExpand,
    expanded,
    handleExpand,
    dispatch,
    managerTeamLoader,
  }) => {
    return (
      <IsLoading loading={managerTeamLoader} row={record} item="individual">
        <div className="d-flex">
          <Individual
            id={record?.es_person_id || ""}
            riskType={record?.riskType || "individual"}
            name={record.full_name}
            width="100%"
          />
          <IconWrapper
            onClick={(e) =>
              handleExpand(dispatch, record, e, onExpand, expanded)
            }
          >
            <Icon
              name={expanded ? "CaretUp" : "CaretDown"}
              fill="#959DA8"
              size="xsm"
            />
          </IconWrapper>
        </div>
        <IndividualsRibbon color="#1A518E" background="#E3ECF6">
          Manager
        </IndividualsRibbon>
      </IsLoading>
    )
  },
}

export const tableFooter = ({ from, to, total }) => {
  return (
    <span className="antd-pagination">
      {from} - {to} of {total}
    </span>
  )
}
