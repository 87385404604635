// libraries
import React from "react"
import PropTypes from "prop-types"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"

// functions
import {
  formatWithCommas,
  formatStragglingEntity,
} from "@src/utils/numbers/utils"

// styles
import { StragglerStatusBarWrapper, Row, Column } from "./styles"

const { H3, H1 } = Typography

export default function StragglerStatusBar(props) {
  const { numberOfStragglers, totalNumber, stragglingEntity } = props

  const formattedNumberOfStragglers = formatWithCommas(numberOfStragglers)
  const formattedtotalNumber = formatWithCommas(totalNumber)
  const formattedStragglingEntity = formatStragglingEntity(stragglingEntity)

  return (
    <StragglerStatusBarWrapper>
      <Row>
        <Column>
          <H1 fontWeight="bold" color="900" fontSize="lg">
            {formattedNumberOfStragglers}
          </H1>
          <H3 fontWeight="bold" color="900">
            &nbsp;/&nbsp;{formattedtotalNumber}&nbsp;{formattedStragglingEntity}{" "}
            are straggling
          </H3>
        </Column>
      </Row>
    </StragglerStatusBarWrapper>
  )
}

StragglerStatusBar.defaultProps = {
  numberOfStragglers: 0,
  totalNumber: 0,
}

StragglerStatusBar.propTypes = {
  numberOfStragglers: PropTypes.number,
  totalNumber: PropTypes.number,
  stragglingEntity: PropTypes.string.isRequired,
}
