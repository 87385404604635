import styled from "styled-components"

export const RangeWrapper = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  justify-content: space-between;
  flex-direction: column;
  row-gap: 30px;
  margin: 24px 24px ${(props) => (props.hasInfoMessage ? "0px" : "18px")} 24px;
  input {
    width: 140px;
  }

  /* Remove Arrows/Spinners */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Dash = styled.div`
  width: 8px;
  height: 1px;
  background: #59616d;
  margin-bottom: 5px;
`

export const InputCell = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
`

export const ErrorMessage = styled.p`
  color: #c73434;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0;
  margin-left: 2px;
`
