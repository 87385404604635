import styled from "styled-components"

export const TrendSummaryWrapper = styled.div`
  padding: 24px 0 0 0;
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
  }
  [class^="Tablestyles__TableRow"] {
    padding: 12px 0;
    margin-top: 0;
    [class^="Tablestyles__TableData"] {
      a {
        width: 100%;
        padding-right: 10px;
      }
      &:nth-child(1),
      &:nth-child(3) {
        span.span {
          color: #0c7d84;
          cursor: default;
          display: flex;
        }
      }
    }
  }
`
export const TableWrapper = styled.div`
  position: relative;
`
export const LabelFilter = styled.strong`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  padding-right: 8px;
`
export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
  margin-bottom: 0;
`
export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const FilterColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0 36px 0;
`
export const IconWrapper = styled.div`
  margin-right: 9px;
  margin-top: -2px;
`
export const LinkCampaign = styled.div`
  display: flex;
`
export const CampaignName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #565d66;
  font-size: 14px;
  line-height: 24px;
`
