import React, { useState, useEffect, memo } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import pluralize from "pluralize"

import ActivityIndicator from "@src/components/ActivityIndicator"
import Table from "@elevate_security/elevate-component-library/dist/Table"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import {
  SummaryLineWrapper,
  SummaryLine,
  SummaryTableWrapper,
  Caret,
  IndividualRow,
} from "./styles"

const columns = [
  {
    key: "individual",
    header: "",
    isHeadSort: false,
  },
]
const defaultPageSize = 5

const IndividualsTable = ({
  behaviorName,
  threshold,
  actionCall,
  setTotalCount,
  tableWidth,
  iconToRender,
}) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showIndividuals, setShowIndividuals] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatchAction()
  }, [])

  const dispatchAction = (offset = 0) => {
    dispatch(
      actionCall({
        limit: defaultPageSize,
        offset,
        callback: ({ data: _data }) => {
          if (_data) {
            setData(_data)
            setTotalCount(_data.count)
          }
          setLoading(false)
        },
      }),
    )
  }

  const count = (data ? data.count : 0) || 0

  const tableProps = {
    data:
      data && data.results
        ? data.results.map(({ first_name, last_name, email }) => ({
            individual: (
              <IndividualRow>
                {`• ${first_name} ${last_name} - `}
                <a title={email}>{email}</a>
              </IndividualRow>
            ),
          }))
        : [],
    columns,
    totalCount: count,
    onChangeTable: ({ pagination }) => handleOnChangeTable(pagination),
    defaultPageSize,
    pageSizeOptions: [],
  }

  const handleOnChangeTable = (pagination) => {
    if (pagination) {
      const { offset } = pagination
      dispatchAction(offset)
    }
  }

  const renderCaret = () => (
    <Caret>
      <span onClick={() => setShowIndividuals(!showIndividuals)}>
        <a>{showIndividuals ? "Hide individuals" : "Show individuals"}</a>
        <Icon
          name={showIndividuals ? "CaretUp" : "CaretDown"}
          fill="#959DA8"
          size="xxsm"
        />
      </span>
    </Caret>
  )

  return (
    <>
      <SummaryLineWrapper>
        {iconToRender}
        <SummaryLine>
          {pluralize("individual", count, true)} {pluralize("has", count)}{" "}
          <b>{behaviorName}</b> score of
          <b>{` ${threshold} `}</b>
          in the last month
        </SummaryLine>
      </SummaryLineWrapper>
      {!!count && renderCaret()}
      <SummaryTableWrapper
        visible={showIndividuals && !!count}
        width={tableWidth}
      >
        <Table {...tableProps} manual />
      </SummaryTableWrapper>
      <ActivityIndicator active={loading} />
    </>
  )
}

IndividualsTable.propTypes = {
  behaviorName: PropTypes.string.isRequired,
  threshold: PropTypes.string.isRequired,
  actionCall: PropTypes.func.isRequired,
  setTotalCount: PropTypes.func,
  tableWidth: PropTypes.string,
  iconToRender: PropTypes.object, // react component
}

IndividualsTable.defaultProps = {
  setTotalCount: () => null,
  tableWidth: null,
}

export default memo(IndividualsTable)
