import React from "react"

import { ActionGroups } from "./ActionGroups"

import { ActionConfig } from "./actionConfigTypes"
import { CONFIRM_AZUREAD_COMPROMISED_USER } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { EditAzureAdConfirmCompromisedUser } from "../actionRenderComponents/EditAzureAdConfirmCompromisedUser"

type ActionPayload = {
  action_type: string
  matching_method: "delta" | "snapshot"
}

const config: ActionConfig<ActionPayload> = {
  label: "Azure AD Risky Users - Confirm as Compromised",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: CONFIRM_AZUREAD_COMPROMISED_USER,
    matching_method: "delta",
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("azuread", "risky-users"),
  render: ({ details, updateAction }) => {
    return (
      <EditAzureAdConfirmCompromisedUser
        action={details}
        updateAction={updateAction}
      />
    )
  },
}

export default config
