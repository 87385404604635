import React from "react"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { AzureAdGroupsComboBox } from "../../../AzurePolicyGroup"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"

import { Wrapper, GroupComboBoxTitle } from "./EditAzureAdGroups.styles"

export function EditAzureAdGroups({ action, title, updateAction }) {
  return (
    <Wrapper>
      <SpaceBetween direction="row" justify="flex-start" alignItems="center">
        <GroupComboBoxTitle>{title}</GroupComboBoxTitle>
        <AzureAdGroupsComboBox
          defaultValue={action.target_groups_meta.map(
            ({ group_id, group_label }) => ({
              value: group_id,
              label: group_label,
            }),
          )}
          onChange={(selectedItems) => {
            updateAction(
              "target_groups_meta",
              selectedItems.map(({ label, value }) => ({
                group_label: label,
                group_id: value,
              })),
            )
            updateAction(
              "target_groups",
              selectedItems.map(({ value }) => value),
            )
          }}
        />
      </SpaceBetween>
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
      />
    </Wrapper>
  )
}
