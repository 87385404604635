import React, { useState } from "react"
import PropTypes from "prop-types"

import {
  Modal,
  Header,
  Typography,
} from "@elevate_security/elevate-component-library"
import { Button, Tabs, Tab } from "@src/components/MimecastReskin"
import {
  TabWrapper,
  TabContainer,
  EmailContainer,
  EmailHeaderContainer,
  AttackFrameworkWrapper,
  PolicyPreviewWrapper,
} from "./styles"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"

const { H2, Text } = Typography
import { getPolicyDetailsWithRules } from "@src/services/apis/reputation"
import ActionIcon from "@src/components/ActionIcon"
import { useRequest } from "ahooks"
import { SkeletonLoader } from "@src/utils/skeleton"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { RISK_RESPONSE_RULES } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { PolicyDetailV2 } from "../DropdownCard/PolicyDetail-V2"
import { POLICY_STATE_MAP } from "@src/scenes/RiskResponseRuleCreateScreen/constants"
import EmailPreviewContainer from "../EmailPreview/EmailPreviewContainer"

const SECURITY_FRAMEWORK_LINK_MAP = {
  nist: "https://www.nist.gov/cyberframework",
  mitre: "https://attack.mitre.org/",
}

const SECURITY_FRAMEWORK_TITLES = {
  nist: "NIST",
  mitre: "MITRE ATT&CK",
}

const ConfirmationModal = ({
  ruleName,
  showModal,
  onCancel,
  onClose,
  showEmailPreview,
  setEmailPreview,
  mainRuleData,
  setShowTabData,
  selectedTab,
  setSelectedTab,
  selectedRecommendation,
  itemSelected,
  showCreateDraftRule,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedItem, setSelectedItem] = useState({})

  const { data: response, loading } = useRequest(
    () => getPolicyDetailsWithRules(selectedRecommendation?.policy_id),
    {
      ready: selectedRecommendation?.policy_id,
      manual: !selectedRecommendation?.policy_id, // only call API when has policy_id
    },
  )

  let policy = {}
  if (response?.data) {
    policy = {
      ...response.data,
    }
  }

  const handleOnToggleReviewV2 = ({ event, chosenItem }) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedItem(chosenItem)
    setEmailPreview(true)
    setIsLoading(true)
  }

  const shouldButtonDisabled =
    selectedRecommendation && !selectedRecommendation.policy_id

  const clickHandler = (e) => {
    setIsLoading(!isLoading)
    onClose(e)
  }
  const renderButtons = () =>
    showCreateDraftRule
      ? [
          <Button
            key="create-and-edit-rule-button"
            onClick={(e) => onClose(e, true)}
            disabled={shouldButtonDisabled}
          >
            Create & Edit Rule
          </Button>,
        ]
      : [
          <Button
            key="confirm-and-activate-rule-button"
            onClick={(e) => clickHandler(e)}
            disabled={loading || shouldButtonDisabled}
          >
            Confirm & Activate Rule
          </Button>,
        ]

  let mainRule = mainRuleData

  if (policy?.rule) {
    mainRule = policy?.rule?.definition || []
  }

  const getPolicyDetails = () =>
    selectedRecommendation?.policy_id ? policy : itemSelected

  const RULE_TITLE = RISK_RESPONSE_RULES.slice(0, -1)

  const modalTitle = selectedRecommendation
    ? `Create ${RULE_TITLE} from a Recommendation`
    : `Confirm & Activate ${RULE_TITLE}`

  let Modaltext = null
  if (selectedRecommendation) {
    Modaltext = (
      <Text>
        You&apos;re about to create rule from the recommendation{" "}
        <strong>{selectedRecommendation.blurb}</strong>:
        <br />
        <br />
        <br />
        <strong>Rule Name: </strong>
        {ruleName || policy?.name}
        <br />
        <strong>Rule Description:</strong> {policy?.description}
        <br />
        <br />
        <br />
        Before activating this {RULE_TITLE.toLowerCase()} in your org, please
        review the {RULE_TITLE.toLowerCase()} and confirm that everything is
        good to go!
        <br />
        <br />
      </Text>
    )
  } else {
    Modaltext = (
      <Text>
        You&apos;re about to activate rule:
        <br />
        <br />
        <br />
        <strong>Rule Name: </strong>
        {ruleName || policy?.name}
        <br />
        <strong>Rule Description:</strong> {policy?.description}
        <br />
        <br />
        <br />
        Before activating this {RULE_TITLE.toLowerCase()} in your org, please
        review the {RULE_TITLE.toLowerCase()} and confirm that everything is
        good to go!
        <br />
        <br />
      </Text>
    )
  }

  const TABS = [
    {
      id: "rule_definition",
      title: "Rule Definition",
    },
  ]

  if (itemSelected?.security_fmw) {
    TABS.push({
      id: "security_fmw",
      title: SECURITY_FRAMEWORK_TITLES[itemSelected.security_fmw],
    })
  }

  const tabsComponent = [
    <TabWrapper key="rule_definition">
      {showEmailPreview ? (
        <EmailContainer>
          <EmailHeaderContainer>
            <Tooltip
              body="Back to Risk Response Rule"
              placement="top"
              readOnly
              size="xsm"
            >
              <ActionIcon
                name="ArrowLeft"
                onClick={() => setEmailPreview(false)}
                size="sm"
              />
            </Tooltip>
            <strong>Review Email Message</strong>
          </EmailHeaderContainer>
          <EmailPreviewContainer
            action={selectedItem}
            queryRules={mainRule?.criteria?.[0]}
            ruleName={ruleName || policy?.name}
          />
        </EmailContainer>
      ) : (
        <PolicyPreviewWrapper>
          <ActivityIndicator active={!isLoading} />
          <PolicyDetailV2
            mainRuleData={mainRule}
            policy={getPolicyDetails()}
            onClick={handleOnToggleReviewV2}
          />
        </PolicyPreviewWrapper>
      )}
    </TabWrapper>,
    <AttackFrameworkWrapper key="security_framework_details">
      <div
        dangerouslySetInnerHTML={{
          __html: itemSelected?.security_fmw_text,
        }}
        style={{ gap: "2rem" }}
      />
      <div className="provider">
        This rule applies to the
        <a
          target="_blank"
          rel="noreferrer"
          href={SECURITY_FRAMEWORK_LINK_MAP[itemSelected?.security_fmw]}
        >
          <strong>{` ${
            SECURITY_FRAMEWORK_TITLES[itemSelected.security_fmw]
          } `}</strong>
        </a>{" "}
        framework.
      </div>
    </AttackFrameworkWrapper>,
  ]

  return (
    <Modal
      onClose={onCancel}
      header={
        <Header>
          <H2 color="900">{modalTitle}</H2>
        </Header>
      }
      rightButtons={renderButtons()}
      isOpen={showModal}
      style={{ maxWidth: "1080px", maxHeight: "100vh" }}
    >
      {loading && <SkeletonLoader height={100} />}
      {!loading && Modaltext}
      {!loading && (
        <TabContainer>
          <Tabs
            onChange={(i) => {
              setShowTabData(true)
              setSelectedTab(i)
            }}
            currentTabIndex={selectedTab}
          >
            {TABS.map((tab, index) => (
              <Tab key={tab.id} title={tab.title}>
                {tabsComponent[index]}
              </Tab>
            ))}
          </Tabs>
        </TabContainer>
      )}
    </Modal>
  )
}

ConfirmationModal.defaultProps = {
  item: {},
  showCreateDraftRule: false,
}

ConfirmationModal.propTypes = {
  ruleName: PropTypes.string,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  item: PropTypes.shape({
    created_datetime: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    state: PropTypes.oneOf([
      POLICY_STATE_MAP.ACTIVE,
      POLICY_STATE_MAP.DRAFT,
      POLICY_STATE_MAP.TEST,
      POLICY_STATE_MAP.PAUSED,
      POLICY_STATE_MAP.DELETED,
      POLICY_STATE_MAP.DISABLED,
    ]),
    name: PropTypes.string,
    organization_nid: PropTypes.string,
    rules: PropTypes.array,
    updated_datetime: PropTypes.string,
  }),
  selectedRecommendation: PropTypes.object,
  showCreateDraftRule: PropTypes.bool,
}

export default ConfirmationModal
