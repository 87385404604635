import React from "react"
import { SpaceBetween, SpaceBetweenProps } from "../SpaceBetween"

export const SpacingY = (
  props: React.PropsWithChildren<Omit<SpaceBetweenProps, "direction">>,
) => {
  return (
    <SpaceBetween
      justify="flex-start"
      size="sm"
      {...props}
      direction="column"
    />
  )
}
