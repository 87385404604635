import React from "react"
import { Input, ComboBox } from "@src/components/MimecastReskin"
import { ItemWrapper } from "../ConditionsGroup/styles"
import { Container, Hint, FieldWrapper, Item } from "../styles"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { StringInput } from "../StringInput"
import FieldCombobox from "../FieldCombobox"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { CompoundConditionButton } from "../CompoundConditionButton"
import { RelativeDateInput } from "../RelativeDateInput/RelativeDateInput"
import OperatorsCombobox from "../OperatorsCombobox/OperatorsCombobox"
import { groupOperators } from "../utils/groupOperators"
import { humanize, isEmpty } from "@src/utils/string"
import { RemoveButton } from "@src/components/RemoveButton"

function CompoundCondition(props) {
  const {
    id,
    index,
    rule,
    dataSource,
    compoundConditions,
    onAddCompoundCondition,
    onRemoveCompoundCondition,
    onHandleCompoundConditionFieldChange,
    fieldsNamesMap,
  } = props

  const fieldsMap = (
    rule,
    inputOrder = 0,
    currentType,
    compoundConditionIndex,
    currentOperator,
  ) => {
    const hasCsvSupport = rule.operator === "in" || rule.operator === "not_in"
    const numbersIn = currentType === "number" && hasCsvSupport
    const isRelativeOperator = Boolean(currentOperator?.type === "relative")
    const showSecondInput =
      rule?.operator === "between" || rule?.operator === "rbetween"

    const handleChange = (selected) => {
      let [startNumber, startUnit, endNumber, endUnit] = [...rule.value]
      if (showSecondInput) {
        if (inputOrder === 0) {
          startNumber = selected.number
        } else if (inputOrder === 2) {
          endNumber = selected.number
          endUnit = selected.unit
          startUnit = selected.unit
        }
      } else {
        startNumber = selected.number
        startUnit = selected.unit
      }

      onHandleCompoundConditionFieldChange(id, index, compoundConditionIndex, {
        name: "value",
        value: showSecondInput
          ? [startNumber, startUnit, endNumber, endUnit]
          : [startNumber, startUnit],
      })
    }

    const dateInput = isRelativeOperator ? (
      <RelativeDateInput
        time_mode_ops={dataSource.values.period}
        hide_time_unit={inputOrder === 0 && showSecondInput}
        value={{
          number: rule?.value[inputOrder],
          unit: rule?.value[inputOrder + 1],
        }}
        onChange={handleChange}
      />
    ) : (
      <Container>
        <DatePicker
          placeholderText="Select date"
          style={{ width: "250px" }}
          dateFormat="yyyy-MM-dd"
          selected={
            rule?.value?.length && rule?.value[inputOrder]
              ? moment(rule.value[inputOrder]).toDate()
              : null
          }
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = moment(e).format("YYYY-MM-DD")
            onHandleCompoundConditionFieldChange(
              id,
              index,
              compoundConditionIndex,
              {
                name: "value",
                value,
              },
            )
          }}
        />
      </Container>
    )

    return {
      string: (
        <StringInput
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          compoundConditionIndex={compoundConditionIndex}
          onHandleFieldChange={onHandleCompoundConditionFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      ),
      number: numbersIn ? (
        <>
          <Input
            name="value"
            placeholder="Enter value"
            style={{ width: "250px" }}
            value={rule.value[inputOrder]}
            onChange={(e) => {
              const value = [...rule.value]
              value[inputOrder] = e
              onHandleCompoundConditionFieldChange(
                id,
                index,
                compoundConditionIndex,
                {
                  name: "value",
                  value,
                },
              )
            }}
          />
          {hasCsvSupport && <Hint>Enter comma (,) separated values</Hint>}
        </>
      ) : (
        <Input
          name="value"
          type="number"
          placeholder="Enter value"
          style={{ width: "250px" }}
          step="0.1"
          min={0}
          value={rule.value[inputOrder]}
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = e
            onHandleCompoundConditionFieldChange(
              id,
              index,
              compoundConditionIndex,
              {
                name: "value",
                value,
              },
            )
          }}
        />
      ),
      boolean: (
        <ComboBox
          styles={{
            control: (baseStyles) => ({ ...baseStyles, width: "250px" }),
          }}
          data={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
          value={
            isEmpty(rule.value[inputOrder])
              ? null
              : {
                  label: humanize(`${rule.value[inputOrder]}`),
                  value: rule.value[inputOrder],
                }
          }
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = e.value
            onHandleCompoundConditionFieldChange(
              id,
              index,
              compoundConditionIndex,
              {
                name: "value",
                value,
              },
            )
          }}
          clearable={false}
          isSearchable={false}
          isMultiOption={false}
          checked={false}
          closeMenuOnSelect
          components={{}}
          disabled={false}
          placeholder="Select value"
        />
      ),
      date: dateInput,
      datetime: dateInput,
      timestamp: (
        <Container>
          <DatePicker
            placeholderText="Select date"
            style={{ width: "250px" }}
            dateFormat="yyyy-MM-dd"
            selected={
              rule?.value?.length && rule?.value[inputOrder]
                ? moment(rule.value[inputOrder]).toDate()
                : null
            }
            onChange={(e) => {
              const value = [...rule.value]
              value[inputOrder] = moment(e).format("YYYY-MM-DD")
              onHandleCompoundConditionFieldChange(
                id,
                index,
                compoundConditionIndex,
                {
                  name: "value",
                  value,
                },
              )
            }}
          />
        </Container>
      ),
      groups: (
        <StringInput
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          compoundConditionIndex={compoundConditionIndex}
          onHandleFieldChange={onHandleCompoundConditionFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      ),
      applications: (
        <StringInput
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          compoundConditionIndex={compoundConditionIndex}
          onHandleFieldChange={onHandleCompoundConditionFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      ),
    }
  }

  const handleRemove = (i) => {
    onRemoveCompoundCondition(id, index, i)
  }

  return (
    <SpaceBetween size="sm">
      {compoundConditions?.map((condition, i) => {
        const selectedField = dataSource[rule?.category]?.find(
          (field) => field.value === condition.field,
        )
        const selectedType = selectedField?.type || "string"
        const operators = dataSource?.operators?.[selectedType] || []
        const groupedOperators = groupOperators(operators, (item) => item.type)
        const operatorOptions =
          groupedOperators?.length > 1
            ? groupedOperators
            : groupedOperators?.[0].options || []
        const isOperatorFilterable = groupedOperators?.length > 1
        const showSecondInput =
          condition?.operator === "between" ||
          condition?.operator === "rbetween"
        const selectedOperator = dataSource?.operators?.[selectedType]?.find(
          (operator) => operator.value === condition?.operator,
        )
        const isRelativeOperator = Boolean(
          selectedOperator?.type === "relative",
        )

        return (
          <ItemWrapper key={`${id}-${i}`}>
            <Item>
              <Item>
                <div>
                  <FieldCombobox
                    defaultValue={condition.field}
                    options={dataSource?.[rule.category] || []}
                    onChange={(e) =>
                      onHandleCompoundConditionFieldChange(id, index, i, {
                        name: "field",
                        value: e.value,
                      })
                    }
                  />
                </div>
                <div>
                  <OperatorsCombobox
                    isFilterable={isOperatorFilterable}
                    options={operatorOptions}
                    value={selectedOperator}
                    onChange={(e) =>
                      onHandleCompoundConditionFieldChange(id, index, i, {
                        name: "operator",
                        value: e.value,
                      })
                    }
                  />
                </div>
                <FieldWrapper>
                  {
                    fieldsMap(
                      { ...condition, category: rule.category },
                      0,
                      selectedType,
                      i,
                      selectedOperator,
                    )[selectedType]
                  }
                </FieldWrapper>
                {showSecondInput && (
                  <>
                    <div style={{ paddingTop: "10px" }}>
                      <b> AND </b>
                    </div>
                    <FieldWrapper>
                      {
                        fieldsMap(
                          { ...condition, category: rule.category },
                          showSecondInput && isRelativeOperator ? 2 : 1,
                          selectedType,
                          i,
                          selectedOperator,
                        )[selectedType]
                      }
                    </FieldWrapper>
                  </>
                )}
              </Item>
              <div style={{ paddingTop: "5px" }}>
                <Item style={{ alignItems: "center" }}>
                  <RemoveButton onClick={() => handleRemove(i)} />
                  {i === 0 && (
                    <CompoundConditionButton
                      onClick={() => onAddCompoundCondition(id, index)}
                    />
                  )}
                </Item>
              </div>
            </Item>
          </ItemWrapper>
        )
      })}
    </SpaceBetween>
  )
}
export default React.memo(CompoundCondition)
