import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { validateEmail } from "@src/utils/string"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import { getGroupsList } from "@src/services/apis/groups"

import ComboboxWithGroups from "../../../ComboboxWithGroups"
import ComponentWithIndividuals from "../../../ComboboxWithIndividuals"
import {
  setIndividualsMails,
  setReceptionGroupData,
} from "../../../RiskRuleOutcome/utils"
import { EditEmailNotification } from "../EditEmailNotification"

export function EditCustomListEmailNotification({
  details,
  updateAction,
  templateAttributes,
}) {
  const [errors, setErrors] = useState(false)
  const [hasLoadedGroups, setHasLoadedGroups] = useState(false)
  const dispatch = useDispatch()

  // TODO: stop using redux for this state, can cause problems with multiple
  // custom email actions being edited at the same time
  const { receptionGroupData, individualsMails } = useSelector((getReducers) =>
    getReducers.get("defineOutcomeReducer"),
  )

  const validateRecipientIndividuals = () => {
    if (!individualsMails.length) {
      setErrors(false)
    } else {
      const found = individualsMails.some((email) => !validateEmail(email))
      setErrors(found)
    }
  }

  const fetchSelectedActionGroups = useCallback(
    async (groupIds) => {
      const { shortname: orgId } = getCompanyInfo()
      const response = await getGroupsList({
        organization: orgId,
        limit: 500,
        offset: 0,
        id__in: groupIds.join(),
      })
      if (response?.data) {
        const groups_names = {}
        response?.data?.results?.forEach((item) => {
          if (!groups_names[item.id]) {
            groups_names[item.id] = item.name
          }
        })
        const groupsData = groupIds.map((item) => {
          return { label: groups_names[item], value: item }
        })
        setReceptionGroupData(groupsData, dispatch)
      }
    },
    [dispatch],
  )

  // Load initial email data when editing a custom email action
  useEffect(() => {
    if (!hasLoadedGroups) {
      fetchSelectedActionGroups(details.group_ids ?? []).then(() => {
        setHasLoadedGroups(true)
      })
    }
    setIndividualsMails(details.emails ?? [], dispatch)
  }, [details, fetchSelectedActionGroups, hasLoadedGroups, dispatch])

  if (!hasLoadedGroups) {
    return <div>Loading groups...</div>
  }

  return (
    <>
      <ComboboxWithGroups
        setSelectedGroups={(groups) => {
          setReceptionGroupData(groups, dispatch)
          updateAction(
            "group_ids",
            groups.map(({ value: id }) => id),
          )
          validateRecipientIndividuals()
        }}
        selectedGroups={receptionGroupData}
        showRightLabel={true}
        showCount={true}
        showHeader={true}
        // TODO don't allow save if no groups or emails have been selected
        hasError={false}
      />
      <ComponentWithIndividuals
        errors={errors}
        setErrors={setErrors}
        onChange={(value) => {
          updateAction("emails", value)
        }}
      />
      <EditEmailNotification
        allowPersonListAttachment
        templateAttributes={templateAttributes}
        action={details}
        onChange={updateAction}
      />
    </>
  )
}
