import styled from "styled-components"

export const DropdownContentWrapper = styled.div`
  padding-bottom: 24px;
  > div > div:first-child {
    margin-top: 0px;
    > div[data-toggle-wrapper="0"] {
      padding: 0;
      margin-top: 32px;
    }
  }
`
export const TabsWrapper = styled.div`
  ul:first-child {
    margin: 0;
  }
`
export const Wrapper = styled.div`
  padding: ${(props) => props.padding};
  border: 1px solid #dde1e7;
  border-radius: 4px;
  margin-top: 32px;
  #score_progress_bar_header {
    div {
      border-right: none;
    }
  }
`
export const RibbonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => props.marginTop || "10px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  span {
    text-transform: capitalize;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 12px;
    background: ${(props) => props.backgroundColor};
    border-radius: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: ${(props) => props.color};
  }
`
export const Header = styled.h4`
  font-size: 23px;
  font-weight: 700;
  color: black;
`
export const IndividualsRibbon = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border-radius: 14px;
  font-weight: 700;
  font-size: 13px;
  height: 28px;
  padding: 3px 12px;
  position: relative;
`
export const ScoreBreak = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
export const BreakDownIndividual = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 30px 16px 24px 16px;
`

export const ScoreBreakdownTableWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  strong {
    font-weight: normal;
  }
  [class^="ant-table-thead"] {
    [class^="ant-table-cell"] {
      background: transparent;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: #7b828a;
    }
  }
  [class^="ant-table-tbody"] {
    [class^="ant-table-cell ant-table-cell-with-append"] {
      position: relative;
    }
    [class^="ant-table-cell"] {
      padding: 0 8px;
      font-weight: normal;
    }
  }
  [class^="ant-pagination"] {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    align-items: baseline;
    font-weight: 700;
    [class^="ant-pagination-item"] {
      background: transparent;
      border: none;
      color: rgb(12, 125, 132);
      &:hover {
        background: transparent;
        color: rgb(3, 155, 161);
      }
    }
    .ant-pagination-item-active {
      color: rgb(86, 93, 102);
    }

    [class^="ant-pagination-total-text"] {
      font-weight: normal;
      height: 25.08px;
      font-size: 14px;
      line-height: 25.08px;
      color: rgb(86, 93, 102);
      padding-left: 24px;
      margin-left: 24px;
    }
  }
`
export const IconWrapper = styled.div`
  margin-top: 2px;
  margin-right: 5px;
  svg {
    width: 15px;
    height: 15px;
  }
  &:hover {
    cursor: pointer;
  }
`

export const ManagerSideDrawer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 990;
  height: 100%;
  width: 480px;
  background: white;
  box-shadow: 0 4px 32px rgba(42, 47, 53, 0.2);
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: ${({ show }) => (show ? "translateX(0%)" : "translateX(100%)")};
  transition: ${({ show }) =>
    show ? "all 0.3s ease-in 0.1s;" : "all 0.3s ease-in 0.1s"};
  svg {
    cursor: pointer;
  }
`
export const Score_Tiles_Wrapper = styled.div`
  width: 100%;
  font-weight: bold;
  margin: 24px 0;
  height: 70vh;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    color: #34383c;
  }
`

export const EmptyScores = styled.div`
  display: flex;
  width: 100%;
  height: 77vh;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #7b828a;
`

export const ScoreHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1d1e1f;
`
export const IndividualTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0 30px 0;
  color: #34383c;
`
export const IndividualDrawerWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 16px;
  height: auto;
`
export const Tile = styled.div`
  padding: 16px;
  background: #fdfbf8;
  margin-top: 16px;
  height: max-content;
  border: 1px solid #e5e8ed;
  background: #fdfbf8;
  border-radius: 4px;
  .tile-header {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #34383c;
    display: flex;
    gap: 8px;
  }
  .tile-score {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin: 8px 0 20px 0;
    color: #34383c;
  }
`

export const TileRibbon = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundColor};
  padding: 2px 10px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ScoreFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  &:last-child {
    div {
      padding: 0;
    }
  }
`
export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  &:hover {
    cursor: pointer;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 17px;
  right: 27%;
`

export const ClearFilters = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  color: #34383c;
  cursor: pointer;
`

export const ProgressBarHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ProgressBarHeader = styled.h2`
  color: "#000000";
  font-weight: 700;
  font-size: "3em";
`

export const ProgressBarHeaderDescription = styled.h2`
  color: "#7b828a";
  font-weight: 700;
  font-size: "14px";
`
