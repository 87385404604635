export function transformHierarchicalDataToChart(data) {
  if (!data || !Object.keys(data).length) return {}

  const getScore = (obj) => {
    if (!obj || !obj.scores) return { score: 0 }
    return { ...obj.scores }
  }

  const scores = [
    // first, get all existing behaviours
    ...data.reduce((behaviours, current) => {
      const keys = current.groups.map(({ key }) => key)
      if (!behaviours) return keys
      return new Set([...keys, ...behaviours])
    }, 0),
  ]
    // then map the date and scores by behaviour
    .map((behaviour) => ({
      [behaviour]: data.map(({ key, groups }, index) => ({
        date: key,
        ...getScore(groups.find((group) => group.key === behaviour)),
        ...(index > 0
          ? {
              score_last_month: getScore(
                data[index - 1].groups.find((group) => group.key === behaviour),
              ).score,
            }
          : {
              score_last_month: null,
            }),
      })),
    }))
    // map array of objects to object of objects
    .reduce((value, current) => {
      const key = Object.keys(current)[0]
      if (!value) return { [key]: current[key] }
      return { ...value, [key]: current[key] }
    }, 0)

  return scores
}

export function transformScoresEmployeesApiParamsToString({
  limit,
  offset,
  ordering = "",
  score_ordering = "",
  filters = "",
}) {
  let _ordering =
    filters && filters.includes("ordering") ? "" : `&ordering=${ordering}`
  _ordering = !ordering || !ordering.length ? "" : _ordering

  let _score_ordering =
    filters && filters.includes("score_ordering")
      ? ""
      : `&score_ordering=${score_ordering}`

  _score_ordering =
    !score_ordering || !score_ordering.length ? "" : _score_ordering

  const params = `limit=${limit}&offset=${offset}${_ordering}${_score_ordering}${filters}`

  return params
}
