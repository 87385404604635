import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOASTS } from "./types"

export const addToastAction = (toast) => ({
  type: ADD_TOAST,
  toast,
})

export const removeToastAction = (id) => ({
  type: REMOVE_TOAST,
  id,
})

export const removeAllToasts = () => ({
  type: REMOVE_ALL_TOASTS,
})

export const toastErrorHelper = (message) => {
  return addToastAction({
    id: Math.random(),
    level: "error",
    message,
  })
}

export const toastSuccessHelper = (message) => {
  return addToastAction({
    id: Math.random(),
    level: "success",
    message,
  })
}
