import styled from "styled-components"

// Note: span width applies to the ColumnWrapper's parent
export const BreakdownTableWrapper = styled.div`
  margin-top: 24px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #c9cfd6;
  border-radius: 5px;
  height: 100%;
  position: relative;
  .table-row {
    margin: 0;
    padding: 10px 0;
  }
  .span {
    width: 90%;
  }
  [data-column-key="individual_name"] {
    min-width: 0;
    width: 20%;
    flex: 0 0 20%;
  }
  [data-column-key="department_name"] {
    min-width: 0;
    width: 30%;
    flex: 0 0 30%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
  [data-column-key="location_name"] {
    min-width: 0;
    width: 30%;
    flex: 0 0 30%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
  [data-column-key="department"] {
    min-width: 0;
    width: 20%;
    flex: 0 0 20%;
  }
  [data-column-key="individual_score"] {
    min-width: 0;
    width: 40%;
    flex: 0 0 40%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
  [data-column-key="department_score"] {
    min-width: 0;
    width: 40%;
    flex: 0 0 40%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
  [data-column-key="location_score"] {
    min-width: 0;
    width: 40%;
    flex: 0 0 40%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
  [data-column-key="manager_name"] {
    min-width: 0;
    width: 20%;
    flex: 0 0 20%;
  }
  [data-column-key="members_count"] {
    min-width: 0;
    width: 30%;
    flex: 0 0 30%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
  [data-column-key="location_members_count"] {
    min-width: 0;
    width: 30%;
    flex: 0 0 30%;

    [class^="Tablestyles__TableHeaderColumnSortIcon-sc-15sgrue-14"] {
      cursor: not-allowed !important;
      visibility: hidden;
    }
  }
`

export const BreakdownTableHeaderWrapper = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

// Styles for progress bar in score section
export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 12px 0px 0;
  align-items: center;
`

export const ProgressBar = styled.div`
  display: block;
  width: 60%;
  min-width: 100px;
  height: 16px;
  background-color: #dde1e7;
  margin-right: 20px;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ value }) => `${value}%`};
    height: 100%;
    background-color: #0c7d84;
  }
`

export const ColumnValue = styled.strong`
  display: block;
  min-width: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
`

export const TrendIcon = styled.div`
  min-width: 24px;
  margin-right: 8px;
`
export const ScoreTrendValue = styled.strong`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  min-width: 24px;
`
export const BreakdownTableGridWrapper = styled.div`
  [class^="Tablestyles__TableContainer"] {
    [class^="Tablestyles__TableBody"] {
      max-height: 495px;
    }
  }
`
