import styled from "styled-components"

export const AddRemoveComboboxLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: 185px;
`
export const IndividualsComboboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${(props) => (props.addPadding ? "padding: 5px 0 0 55px" : "")};
  ${(props) =>
    props.width
      ? `[class^="ComboBoxstyles__SelectContainer"] {
    margin: 0;
    width: ${props.width};
  }`
      : ""}
  input:disabled {
    background-color: #f5f6f8;
  }
`
