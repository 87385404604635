import React, { useState } from "react"
import { createPortal } from "react-dom"
import IconLink from "../IconLink"
import ExportModal from "../ExportPngModal"

function ExportImage() {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState("")

  const handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const onChange = (e) => {
    if (e.value) {
      setSelected(e.value)
      setLoading(false)
    }
  }

  return (
    <>
      <IconLink
        iconName="Download"
        iconSize="lg"
        fontWeight="bold"
        text="Export"
        onClick={() => handleToggleModal()}
      />
      {showModal &&
        createPortal(
          <ExportModal
            onClose={handleToggleModal}
            onChange={onChange}
            state={selected}
            loading={loading}
            isOpen={showModal}
            setLoading={setLoading}
          />,
          document.body,
        )}
    </>
  )
}

export default ExportImage
