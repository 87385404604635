import { BASE_EP_API_URL } from "@src/constants"
// import fetcher from "../fetcher"
import fetcher from "../fetcher"
import { getErrorStatus } from "@src/services/fetcher/utils"
import { transformScoresEmployeesApiParamsToString } from "@src/utils/scores"

export async function fetchCurrentManager(person_nid) {
  try {
    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/current/`

    if (person_nid) {
      url += `?es_person_id=${person_nid}`
    }

    const { status, data } = await fetcher.get(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function fetchTeamMonthlyScores(person_nid) {
  try {
    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/team-monthly-scores/`

    if (person_nid) {
      url += `?person_nid=${person_nid}`
    }

    const { status, data } = await fetcher.get(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export const fetchOrgScores = async (person_nid) => {
  try {
    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/organization-scores/`

    if (person_nid) {
      url += `?person_nid=${person_nid}`
    }

    const { status, data } = await fetcher.get(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export const fetchTeamData = async (person_nid) => {
  try {
    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/team-data/`

    if (person_nid) {
      url += `?person_nid=${person_nid}`
    }

    const { status, data } = await fetcher.get(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export const fetchManagerOverview = async () => {
  try {
    const API_URL = BASE_EP_API_URL()
    const url = `${API_URL}/scores/manager/overview/`

    const { status, data } = await fetcher.get(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function fetchTeamScoresByIndividuals({
  person_nid,
  limit = 25,
  offset = 0,
  ordering = "",
  score_ordering = "",
  filters = "",
}) {
  try {
    const params = transformScoresEmployeesApiParamsToString({
      limit,
      offset,
      ordering,
      score_ordering,
      filters,
    })

    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/subordinates-scores/?${params}`

    if (person_nid) {
      url += `&person_nid=${person_nid}`
    }

    const { status, data } = await fetcher.get(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function rewardTopPerformers(person_nid) {
  try {
    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/reward-top-performers/`

    if (person_nid) {
      url += `?person_nid=${person_nid}`
    }

    const { status, data } = await fetcher.post(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function sendEnrollEmail(behaviorNames, person_nid) {
  try {
    const API_URL = BASE_EP_API_URL()
    let url = `${API_URL}/scores/manager/email-to-enroll-low-performers/?behavior_names=${behaviorNames}`

    if (person_nid) {
      url += `&person_nid=${person_nid}`
    }

    const { status, data } = await fetcher.post(url)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
