import React from "react"
import styled from "styled-components"
import gif from "../../images/Elevate-Security-Animated-Logo.gif"

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loading = () => (
  <LoaderContainer>
    <div className="loading-screen-view">
      <img src={gif} alt="Loading..." height="200" width="200" />
    </div>
  </LoaderContainer>
)

export default Loading
