import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 50%;
  padding: ${({ padding }) => (!padding ? 0 : padding)};
`

export const LinkWrapper = styled.div`
  font-size: 14px;
  svg {
    width: 8px;
    margin-left: 5px;
    path {
      fill: #0c7d84;
    }
  }
  &:hover svg path {
    fill: #039ba1;
  }
`
