export const getFiltersParam = ({ filters, person_nid, managerInfo }) => {
  const fields = [
    {
      key: "department",
      param: `department IN ${filters?.department?.join(",")}`,
      value: filters?.department?.length > 0 ? " " : null,
    },
    {
      key: "human_risk_score",
      param: `human_risk_score GE ${filters?.overall[0]} AND human_risk_score LE ${filters?.overall[1]}`,
      value: filters?.overall?.length > 0 ? " " : null,
    },
    {
      key: "hr_metadata.es_manager_id",
      param: `hr_metadata.es_manager_id EQ ${person_nid || managerInfo?.id}`,
      value: person_nid || managerInfo?.id,
    },
  ]

  return fields
    .filter((field) => field.value)
    .map((field) => field.param)
    .join(" AND ")
}

export const findSetNestedData = (obj, key, value, data, extra_items) => {
  return obj?.map((item) => {
    if (item?.es_person_id === value) {
      return { ...item, children: data || [], ...extra_items }
    } else if (item?.children?.length) {
      return {
        ...item,
        children: findSetNestedData(
          item?.children,
          key,
          value,
          data,
          extra_items,
        ),
      }
    } else {
      return { ...item }
    }
  })
}
export const viewType = (currentUser) => {
  if (currentUser?.is_admin) {
    return "admin"
  } else if (currentUser?.is_manager) {
    return "manager"
  }
  return ""
}
