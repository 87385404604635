import { SET_GROUPS } from "./types"

const INITIAL_STATE = {
  groups: {
    data: null,
    isLoaded: false,
  },
}

export default function groupsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        groups: action.data,
      }
    default:
      return state
  }
}
