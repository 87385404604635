import { SIDE_DRAWER_Z_INDEX } from "@src/zIndex"
import styled from "styled-components"

export const ActionsOverlay = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${SIDE_DRAWER_Z_INDEX};
  height: 100%;
  width: 1096px;
  background: white;
  box-shadow: 0px 4px 32px rgba(42, 47, 53, 0.2);
  padding: 28px 48px;
  overflow-y: auto;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  overflow-x: hidden;
  transform: ${({ show }) => (show ? "translateX(0%)" : "translateX(100%)")};
  transition: ${({ show }) =>
    show ? "all 0.3s ease-in 0.1s;" : "all 0.3s ease-in 0.1s"};
`

export const Products = styled.div`
  margin-top: 16px;

  ${({ isRecommended }) =>
    !isRecommended &&
    `
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 8px;
      justify-content: center;
      align-items: stretch;
      grid-template-rows: 192px;
    `}
`

export const ProductCard = styled.div`
  display: flex;
  width: 100%;
  background: #fdfbf8;
  border-radius: 4px;
  padding: 24px;
  margin-top: 8px;
  position: relative;
  .product-icon {
    margin-right: 18px;
    min-width: 32px;
  }
  .enroll-only {
    margin-right: 0px;
    margin-bottom: 4px;
    min-width: 32px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 16px;
`

export const FlexRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
  svg {
    margin-right: 16px;
  }
`

export const Heading = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #34383c;
  margin-bottom: 16px;
`

export const TextSpan = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${({ danger }) => (danger ? "#C73434" : "#34383c")};
  text-align: ${({ align }) => (align ? align : "left")};
`

export const OrSection = styled.div`
  display: flex;
  flex-direction: column;
  .text {
    font-size: 13px;
    line-height: 16px;
    color: #7b828a;
    margin-top: 8px;
    text-align: center;
  }
`

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  .line {
    width: 100px;
    height: 1px;
    background: #c9cfd6;
  }
  .or-text {
    font-size: 16px;
    line-height: 24px;
    color: #7b828a;
    margin: 0 24px;
  }
`

export const IndividualsListContainer = styled.div``

export const IndividualsList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 370px;
  flex-wrap: wrap;
  margin-top: 20px;
  position: relative;
  > div {
    width: 50%;
    display: flex;
    margin-bottom: 8px;
    margin-right: 10px;
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const ExpandLink = styled.div`
  margin-top: 11px;
  font-size: 13px;
  color: #0c7d84;
  cursor: pointer;
  display: inline-block;
  svg {
    width: 8px;
    height: 8px;
    margin-left: 4px;
    margin-bottom: 2px;
  }
`

export const EnrollContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SkeletonWrapper = styled.div`
  width: 215px;
  display: grid;
  gap: 8px;
`

export const SideDrawerStatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdfbf8;
  width: ${({ quantity }) => (quantity === 1 ? "355px" : "100%")};
  height: 96px;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 10px;

  h3 {
    font-weight: 800;
    font-size: 32px;
  }
  p {
    font-weight: 700;
    color: #7b828a;
    font-size: 12px;
  }
  [class^="sc-ezrnTI"] {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
`

export const SideDrawerStatBoxContainer = styled.div`
  display: ${(props) => props.loading};
  grid-template-columns: ${({ quantity }) =>
    quantity === 2 ? "50% 50%" : quantity === 1 ? "none" : "33% 33% 33%"};
  grid-column-gap: 16px;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  h3 {
    color: #34383c;
  }
`
export const TabsWrapper = styled.div`
  border: 1px solid #dde1e7;
  margin-top: 24px;
  padding: 24px;
  border-radius: 4px;
  [class^="GroupsGraphstyles__GroupCharWrapper"] {
    margin-top: 0;
    padding: 0;
  }
`

export const IconContainer = styled.span`
  margin-left: 5px;
  svg {
    width: 14px;
    height: 14px;
  }
`

export const StatsNote = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #7b828a;
  text-align: end;
  margin-top: 26px;
  &:before {
    display: inline;
    content: "*";
    color: #7b828a;
  }
`
