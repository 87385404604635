import React from "react"
import { getIndividualsRulesAuditLogs } from "@src/services/apis/reputation"
import {
  ActionLogsListWrapper,
  ActionLogsRow,
  PolicyExecutionTime,
} from "./styles"
import { useParams } from "react-router-dom"
import { useRequest } from "ahooks"
import ShowActionText from "@src/scenes/RiskDetectionRulesScreen/Table/ShowActionText"
import { ActionLogsLoader } from "./ActionLogsLoader"
import { timeWithTimeZone } from "@src/utils/dates"

export const ActionLogsList = (props) => {
  const { policy_id, policy_execution_id } = props
  const { profileId } = useParams()

  const { data, loading } = useRequest(
    ({ ...params }) =>
      getIndividualsRulesAuditLogs(profileId, {
        order_by: "created_datetime desc",
        page_number: 1,
        page_size: 25,
        filters: `policy_id eq ${policy_id} AND policy_execution_id eq ${policy_execution_id}`,
        ...params,
      }),
    {
      paginated: true,
      defaultPageSize: 25,
      formatResult: (response) => ({
        total: response.data.total_elements,
        list: response.data.results,
      }),
    },
  )

  return (
    <ActionLogsListWrapper>
      {loading && <ActionLogsLoader rows={3} />}
      {data?.list?.map((item) => (
        <ActionLogsRow key={item?.created_datetime}>
          <ShowActionText data={item} parseHTML={true} />
          <PolicyExecutionTime>
            {timeWithTimeZone({
              date: item?.created_datetime,
            })}
          </PolicyExecutionTime>
        </ActionLogsRow>
      ))}
    </ActionLogsListWrapper>
  )
}
