import React, { useContext, useState } from "react"
import InsightData from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/InsightData"

const InsightDataContext = React.createContext()

export const useInsightData = () => {
  return useContext(InsightDataContext)
}

export default function InsightDataProvider({ item }) {
  const [eventLogTotal, setEventLogTotal] = useState(0)
  const [dataSourceTotal, setDataSourceTotal] = useState(
    item?.data_sources || 0,
  )

  return (
    <InsightDataContext.Provider
      value={{
        individualTotal: item?.total_individuals || 0,
        setEventLogTotal,
        setDataSourceTotal,
        eventLogTotal,
        dataSourceTotal,
      }}
    >
      <InsightData item={item} />
    </InsightDataContext.Provider>
  )
}
