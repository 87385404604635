export const BREADCRUMB_LINKS = [
  {
    label: "Vision",
    link: "/engagement/vision2/insights",
  },
  {
    label: "All",
    link: "/engagement/vision2/insights",
  },
]

export const IMPACT_BADGE = {
  high: {
    background: "#e3f6f2",
    color: "#15785f",
    impactTooltip:
      "This recommendation is estimated to have a high impact on reducing risk at your org",
    impactText: "High Impact",
  },
  medium: {
    background: "#e3ecf6",
    color: "#1a518e",
    impactTooltip:
      "This recommendation is estimated to have a medium impact on reducing risk at your org",
    impactText: "Medium Impact",
  },
  low: {
    background: "#eef0f3",
    color: "#565d66",
    impactTooltip:
      "This recommendation is estimated to have a low impact on reducing risk at your org",
    impactText: "Low Impact",
  },
}
export const MANAGER_ROLE = "MANAGER"
export const ADMIN_ROLE = "ADMIN"
