export const byNameDesc = {
  order_by: "name desc",
}

export const byNameAsc = {
  order_by: "name asc",
}

export const newestToOldest = {
  order_by: "updated_datetime desc",
}

export const oldestToNewest = {
  order_by: "updated_datetime asc",
}

export const orderBy = {
  "Newest to Oldest": newestToOldest,
  "Oldest to Newest": oldestToNewest,
  "A-Z": byNameAsc,
  "Z-A": byNameDesc,
}
