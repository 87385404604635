import React from "react"
import { EditInstantMessageNotification } from "../EditInstantMessageNotification"
import { darklyGetFlag } from "@src/utils/darkly"

export function EditMicrosoftTeamsNotification(props) {
  const showMSTeamsAttachments = darklyGetFlag(
    "show-attachment-ms-teams-notifications",
  )
  return (
    <EditInstantMessageNotification
      {...props}
      allowPersonListAttachment={
        showMSTeamsAttachments && props.allowPersonListAttachment
      }
      title="Microsoft Teams notification content"
    />
  )
}
