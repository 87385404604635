/* eslint-disable react/prop-types */
import React from "react"
import { v4 as uuidv4 } from "uuid"

import { Button } from "@src/components/MimecastReskin"
import { useDispatch } from "react-redux"
import { setQueryRule } from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import {
  UNDERSCORED_BEHAVIORS,
  REVERSED_BEHAVIORS,
} from "@src/components/ActionChart/utils"
import { RENAME_FIELD_KEYS } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { useHistory } from "react-router-dom"

function RiskDetectionRules({ filters, enabledBehaviorTypes, advancedFilter }) {
  const history = useHistory()

  const dispatch = useDispatch()

  const scores_keys = [
    "human_risk_score",
    ...Object.values(UNDERSCORED_BEHAVIORS),
  ]

  const categoryDetails = (category, categoryList) => {
    const compoundCondition = categoryList.map((item) => {
      return { field: item.field, operator: item.operator, value: item.value }
    })
    return {
      category: category,
      compound_conditions: compoundCondition,
      uuid: uuidv4(),
    }
  }

  const queryFormat = (category, categoryResult) => {
    const categoryList = Object.values(categoryResult).filter(
      (item) => item.category === category,
    )
    if (!categoryList.length) return {}

    return categoryList.length > 1
      ? categoryDetails(category, categoryList)
      : categoryList[0]
  }

  const getBaseCriteria = ({
    key,
    operator,
    score_keys_remap,
    resultKey,
    value_to_add,
  }) => {
    if (key === "behavior_type") {
      return {
        uuid: uuidv4(),
        category: resultKey,
        field: key,
        operator: operator,
        value: value_to_add,
        eventOccurrences: [
          { occurrence_type: "count", value: ["gt", 0, 1, "years"] },
        ],
      }
    }

    if (resultKey.includes("-score")) {
      const fieldKey = score_keys_remap[key] || key
      return {
        uuid: uuidv4(),
        category: "scores",
        field: RENAME_FIELD_KEYS[fieldKey] || fieldKey,
        operator: operator,
        value: value_to_add,
      }
    }

    return {
      uuid: uuidv4(),
      category: "people",
      field: key,
      operator: operator,
      value: value_to_add,
    }
  }

  const identifyKeyType = (el, behaviorType) => {
    if (el.key === "behavior_type") {
      return behaviorType.actionCategory
    }

    if (scores_keys.includes(el.key)) {
      return `${el.key}-score`
    }
    return el.key
  }

  const addRDR = () => {
    // TODO: format filters and save in state for criteria pre-population

    // TODO: remove this mapping when renaming human_risk_score to human_risk
    const score_keys_remap = {
      human_risk_score: "human_risk",
      action_score: "action",
      ...REVERSED_BEHAVIORS,
    }

    const result = filters?.reduce(function (acc, cur) {
      const behaviorType =
        cur.key === "behavior_type" &&
        enabledBehaviorTypes.find(
          ({ behaviorType }) => behaviorType === cur.value,
        )

      const resultKey = identifyKeyType(cur, behaviorType)

      const value_to_add =
        cur.operator === "between"
          ? cur?.value?.split("-") || []
          : [
              cur.key === "behavior_type"
                ? behaviorType.behaviorTypeShortName
                : cur.value,
            ]

      if (acc[resultKey] === undefined) {
        acc[resultKey] = getBaseCriteria({
          key: cur.key,
          operator: cur.operator,
          score_keys_remap,
          value_to_add,
          resultKey,
        })
      } else {
        // this is for the `in` cases, where we have multiple filters for the same key
        acc[resultKey].value = [`${acc[resultKey].value},${value_to_add[0]}`]
      }

      return acc
    }, Object.create(null))

    if (
      typeof result === "object" &&
      !Array.isArray(result) &&
      result !== null &&
      Object.keys(result)?.length > 0
    ) {
      const peopleConditions = queryFormat("people", result)
      const scoreConditions = queryFormat("scores", result)
      const behaviorTypeCategories = Array.from(
        new Set(
          enabledBehaviorTypes.map(({ actionCategory }) => actionCategory),
        ),
      )

      const jsonQuery = {
        uuid: uuidv4(),
        condition: "and",
        rules: [
          peopleConditions,
          scoreConditions,
          ...behaviorTypeCategories.map((category) =>
            queryFormat(category, result),
          ),
        ].filter((item) => item?.category),
      }

      dispatch(setQueryRule(jsonQuery))
    }

    if (advancedFilter) {
      dispatch(setQueryRule(advancedFilter))
    }

    history.push(
      "/engagement/vision2/risk-response-engine/risk-response-rules/create-rule",
    )
  }

  return (
    <Button onClick={addRDR}>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <span style={{ fontSize: "1.75em" }}>+</span>
        <span>Risk Response Rule</span>
      </div>
    </Button>
  )
}

export const MemoizedRiskDetectionRules = React.memo(RiskDetectionRules)

export default MemoizedRiskDetectionRules
