/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import { SCORE_COLORS } from "@src/constants"
import { ScoreBar, Bullet, BarColorsWrapper } from "./styles"
import { getColorAndSizeByScore } from "./utils"

export default function BarColors({
  score,
  size = "default",
  hasIndicatorBox,
  invert = false,
  showCircle = true,
  sameColor = false,
  barColor,
  padding = true,
  isActionFactorChart = false,
  showWideBar = 0,
}) {
  function renderBars() {
    const SCORE_COLORS_INVERTED = [
      { color: "#2C9097", min: 0, max: 20 },
      { color: "#3CC1AE", min: 21, max: 40 },
      { color: "#FACC38", min: 41, max: 60 },
      { color: "#F08526", min: 61, max: 80 },
      { color: "#D23A54", min: 81, max: 100 },
    ]

    const SAME_SCORE_COLORS = [
      { color: barColor, min: 0, max: 20 },
      { color: barColor, min: 21, max: 40 },
      { color: barColor, min: 41, max: 60 },
      { color: barColor, min: 61, max: 80 },
      { color: barColor, min: 81, max: 100 },
    ]

    const getScoreColors = () => {
      if (sameColor) {
        return SAME_SCORE_COLORS
      } else if (invert) {
        return SCORE_COLORS_INVERTED
      } else {
        return SCORE_COLORS
      }
    }

    return getScoreColors().map(({ color, max, min }, i) => {
      const barInfo = getColorAndSizeByScore({
        color,
        max,
        min,
        score,
        invert,
      })
      return (
        <ScoreBar
          barInfo={barInfo}
          key={i}
          size={size}
          isActionFactorChart={isActionFactorChart}
          showWideBar={showWideBar}
        >
          {showCircle && (
            <Bullet
              barInfo={barInfo}
              size={size}
              hasIndicatorBox={hasIndicatorBox}
            />
          )}
        </ScoreBar>
      )
    })
  }
  return <BarColorsWrapper padding={padding}>{renderBars()}</BarColorsWrapper>
}

BarColors.propTypes = {
  score: PropTypes.number,
  size: PropTypes.string,
  hasIndicatorBox: PropTypes.bool,
  invert: PropTypes.bool,
  showCircle: PropTypes.bool,
  sameColor: PropTypes.bool,
  barColor: PropTypes.string,
  padding: PropTypes.bool,
  isActionFactorChart: PropTypes.bool,
  showWideBar: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}
