import React, { useState } from "react"
import PropTypes from "prop-types"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Checkbox from "@src/components/Checkbox"
import { Wrapper } from "./styles"
import { THEME_PRIMARY } from "@src/theme"

const MultiSelectFilter = ({ section, filters, handleChange }) => {
  const { showMore, showLess, items, key } = section
  const [expanded, setExpanded] = useState(false)
  const showCount = expanded ? items.length : 4

  const isChecked = (value) =>
    filters.find((filter) => filter.key === key && filter.value === value)
      ? true
      : false

  return (
    <Wrapper>
      <div className="title">{section.title}</div>
      <div className="checkbox-items">
        {items.slice(0, showCount).map((item) => {
          const { label, value } = item
          return (
            <Checkbox
              label={label}
              id={`${section.title}-${label}`}
              key={label}
              checked={isChecked(value)}
              onChange={(checked) =>
                handleChange({
                  key,
                  label,
                  value,
                  checked,
                })
              }
            />
          )
        })}
      </div>
      {showMore && (
        <div className="show-more-link" onClick={() => setExpanded(!expanded)}>
          {expanded ? showLess : showMore}
          <Icon
            name={expanded ? "CaretUp" : "CaretDown"}
            size="xxsm"
            fill={THEME_PRIMARY}
            className="expand-icon"
          />
        </div>
      )}
    </Wrapper>
  )
}

MultiSelectFilter.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    key: PropTypes.string.isRequired,
    showMore: PropTypes.string,
    showLess: PropTypes.string,
  }).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default MultiSelectFilter
