import React, { useEffect, useState } from "react"
import {
  PerformanceOverTimeWrapper,
  PerformanceHeader,
  PerformanceLegendWrapper,
  PerformanceCirclesContainer,
  PerformanceCircleBox,
  PerformanceCircleOne,
  PerformanceLegendActionText,
} from "./styles"
import HistoricalTrend from "@src/components/HistoricalTrend"
import ScoreTooltip from "@src/scenes/Dashboard/components/ThirdQuestion/components/ScoreTooltip"
import tippy from "tippy.js"
import ReactDOM from "react-dom"
import { humanize } from "@src/utils/string"
import { getActionName } from "../ActionChart/utils"
import { SkeletonLoader } from "@src/utils/skeleton"
import { GroupCharColor } from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/utils"
import { useTranslation } from "react-i18next"

function PerformanceChart({ keyFactor, setEventOverTimeGraph, data, loading }) {
  const { t } = useTranslation("dashboard")
  const [lineFocus, setFocus] = useState("")
  const getMaxValue = () => {
    const maxScoreValues = Object.keys(data || {})?.map((key) => {
      return Math.max(...data[key].map((actionData) => actionData?.score))
    })
    const maxScoreValue = Math.max(...maxScoreValues)
    const Ten_Percentage = 0.1 // To show graph y-axis properly
    const maxValue = maxScoreValue + maxScoreValue * Ten_Percentage
    return maxValue > 250 ? maxValue : 250
  }

  const handleOnMouseEnter = (info) => {
    const { elementId, score, score_last_month, date, behaviourName, color } =
      info
    const snatizedId = elementId.replace(/[.]/g, "-")
    const tooltipId = `${snatizedId}-tooltip-content`
    const toolTipContent = document.createElement("div")

    toolTipContent.setAttribute("id", tooltipId)
    toolTipContent.style.display = "block"
    toolTipContent.style.minWidth = "900px"

    ReactDOM.render(
      <ScoreTooltip
        score={score.toFixed(1)}
        performanceType={humanize(behaviourName)}
        month={date}
        trend={
          score_last_month === null
            ? 0
            : (score.toFixed(1) - score_last_month.toFixed(1)).toFixed(1)
        }
        behaviorName="Human Risk Score"
        maxValue={10}
        performanceFlag={true}
      />,
      toolTipContent,
    )

    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content: toolTipContent.innerHTML,
      onHide() {
        toolTipContent.remove()
      },
    }

    const element = document.querySelector(`#${snatizedId}`)
    element.setAttribute("fill", color)
    tippy(element, tippyProps)
    element._tippy.show()
  }

  useEffect(() => {
    setFocus("")
  }, [keyFactor])

  useEffect(() => {
    if (loading) {
      setEventOverTimeGraph({ loading: true, data: [] })
    }
  }, [loading])

  if (loading) {
    return <SkeletonLoader height={200} />
  }

  const sortedItems = Object.keys(data || {}).sort((a, b) => (a > b ? 1 : -1))

  return (
    <PerformanceOverTimeWrapper>
      <PerformanceLegendWrapper>
        <PerformanceHeader>
          {t(
            "humanRiskBehaviourBreakdown.gridItem.charts.individualPerformChart.header",
          )}
          {getActionName(keyFactor)}{" "}
        </PerformanceHeader>
        <PerformanceCirclesContainer>
          {sortedItems.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <PerformanceCircleBox key={`${item}-${index}`}>
                <PerformanceCircleOne color={GroupCharColor[index]} />
                <PerformanceLegendActionText>
                  {humanize(item)}
                </PerformanceLegendActionText>
              </PerformanceCircleBox>
            )
          })}
        </PerformanceCirclesContainer>
      </PerformanceLegendWrapper>
      <HistoricalTrend
        behaviorScores={data}
        onMouseEnter={handleOnMouseEnter}
        yAxisLabel={t(
          "humanRiskBehaviourBreakdown.gridItem.charts.individualPerformChart.yAxisLabel",
        )}
        xAxisLabel={t(
          "humanRiskBehaviourBreakdown.gridItem.charts.individualPerformChart.xAxisLabel",
        )}
        keyToBeDashed={lineFocus ? lineFocus : Object.keys(data)[0]}
        showHeading={false}
        showTabs={false}
        changeGraphTabsPosition={true}
        minMaxValue={{ min: 0.0, max: getMaxValue() }}
        yAxisDecimal={true}
        changeLineFocus={setFocus}
        lineFOcus={lineFocus}
        performanceFlag={true}
      />
    </PerformanceOverTimeWrapper>
  )
}

export default PerformanceChart
