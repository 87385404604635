import type { GlobalConfig } from "./GlobalConfig"

// Keep this object like flat JSON, with no business logic. We are using TS here
// only to get the type checking.
const elevate: GlobalConfig = {
  FEATURE_FLAG_PROVIDER: "ELEVATE_PLATFORM",
  DASHBOARD_TITLE: "dashboard:dashboardTitle",
  BEHAVIOR_DESCRIPTOR: "dashboard:dashboardbehaviourDescriptorTitle",
  BEHAVIOR_BREAKDOWN_TITLE: "dashboard:dashboardBehaviorBreakdownTitle",
  ENABLE_RECOMMENDATIONS_TO_REDUCE_RISK: true,
  ENABLE_DASHBOARD_INSIGHTS: true,
  ENABLE_BEHAVIOR_TRENDS_OVER_TIME: true,
  ENABLE_ATTACK_FACTOR_DISTRIBUTION: true,
  ENABLE_RAP_TABLE_MODE: true,
  ENABLE_R3_CREATE: true,
  ENABLE_RAP_ADVANCED_FILTERS: true,
  ENABLE_RAP_BULK_ACTIONS: true,
  ENABLE_IRP_ALL_TRENDS_GRAPH: true,
  ENABLE_IRP_ATTACK_EXPLANATION_CARDS: true,
  ENABLE_ADDITIONAL_HUMAN_RESOURCE_FIELDS: true,
  ENABLE_RAP_DATA_EXPORT: true,
  ENABLE_ACTION_LOGS_EXPORT: true,
  ENABLE_EVENT_LOGS_EXPORT: true,
  ENABLE_ORG_ATTACK_FACTOR: false,
  RAP_FIELD_EXCLUSIONS: [],
  RAP_SORT_EXCLUSIONS: [],
  RAP_FILTER_EXCLUSIONS: [],
  ACTION_LOG_CONTEXT: "ELEVATE_PLATFORM",
  ENABLE_IRP_ACTION_LOG_GROUPING: true,
  ELLIE: true,
  SHOW_LAST_SCORING_DATETIME: true,
  PROVISIONING_SCREEN_MESSAGE: null,
}

export default elevate
