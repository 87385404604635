import styled from "styled-components"

export const Container = styled.div`
  position: relative;
`

export const Dropdown = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c9cfd6;
  box-shadow: 0px 2px 8px rgba(177, 184, 193, 0.5);
  /* height: 390px; */
  width: 260px;
  position: absolute;
  top: 41px;
  z-index: 1002;
`

export const Section = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  :hover {
    background-color: rgba(177, 184, 193, 0.5);
  }
`
