export const REPUTATION_SCORE_HISTORICAL = "REPUTATION_SCORE_HISTORICAL"
export const INDIVIDUAL_DATA = "INDIVIDUAL_DATA"
export const CLEAR = "CLEAR"
export const SET_SYSTEMS = "SET_SYSTEMS"
export const SET_DECISION_AREAS = "SET_DECISION_AREAS"
export const SET_EVENT_COUNT = "SET_EVENT_COUNT"

/** EVENTS_LOG */
export const EVENTS_LOG_CLEAR_FILTERS = "EVENTS_LOG_CLEAR_FILTERS"
export const EVENTS_LOG_CLEAR_FILTER = "EVENTS_LOG_CLEAR_FILTER"
export const EVENTS_LOG_SET_RESULTS = "EVENTS_LOG_SET_RESULTS"
export const EVENTS_LOG_SET_FILTERS = "EVENTS_LOG_SET_FILTERS"
export const EVENTS_LOG_SET_FILTER_BY = "EVENTS_LOG_SET_FILTER_BY"
export const TOGGLE_AUTO_SCROLL = "TOGGLE_AUTO_SCROLL"

/** AUDIT_LOG */
export const AUDIT_LOG_CLEAR_FILTERS = "AUDIT_LOG_CLEAR_FILTERS"
export const AUDIT_LOG_CLEAR_FILTER = "AUDIT_LOG_CLEAR_FILTER"
export const AUDIT_LOG_SET_RESULTS = "AUDIT_LOG_SET_RESULTS"
export const AUDIT_LOG_SET_FILTERS = "AUDIT_LOG_SET_FILTERS"
export const AUDIT_LOG_SET_FILTER_BY = "AUDIT_LOG_SET_FILTER_BY"
export const SET_SELECTED_KEY_FACTOR = "SET_SELECTED_KEY_FACTOR"
