import React from "react"
import PropTypes from "prop-types"

import { Toggle } from "@elevate_security/elevate-component-library"
import { TogglesGroup } from "@src/scenes/RiskResponseRuleCreateScreen/components/styles"

import { Row, ToggleWrapper, ToggleWrapperText } from "./styles"

const EmailPreviewToggle = ({ checked, setChecked, children }) => {
  return (
    <TogglesGroup>
      <Row>
        <ToggleWrapper>
          <Toggle
            checked={checked}
            onChange={({ checked }) => {
              setChecked(checked)
            }}
          />
          <ToggleWrapperText>
            Preview Email with Filled Content
          </ToggleWrapperText>
        </ToggleWrapper>
      </Row>
      <div>{checked && children}</div>
    </TogglesGroup>
  )
}

EmailPreviewToggle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
}

export default EmailPreviewToggle
