import React from "react"
import {
  Typography,
  Icon,
  theme,
} from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { CaretWrapper, FiltersWrapper, Separator } from "./styles"
import { useTranslation } from "react-i18next"

export const SimpleFilters = ({ onClose, children }) => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <FiltersWrapper>
          <SpaceBetween direction="row">
            <Typography.H2 fontWeight="heavy" color="700">
              {t("filtersTitle")}
            </Typography.H2>
            <CaretWrapper onClick={onClose}>
              <Icon name="CaretRight" fill={theme.colors.gray.opaque[500]} />
            </CaretWrapper>
          </SpaceBetween>
        </FiltersWrapper>
        <Separator />
      </div>
      <FiltersWrapper>
        <SpaceBetween justify="flex-start">
          <SpaceBetween justify="flex-start" size="sm">
            {children}
          </SpaceBetween>
        </SpaceBetween>
      </FiltersWrapper>
    </>
  )
}
