import React from "react"

import { SpaceBetween } from "@src/components/SpaceBetween"
import PolicyAzureAdCombobox from "../../../PolicyAzureAdCombobox"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"
import { Typography } from "@elevate_security/elevate-component-library"

import Checkbox from "@src/components/Checkbox"
import { AzureAdGroupsComboBox } from "../../../AzurePolicyGroup"
import AzureApplicationsCombobox from "../../../AzureApplicationsCombobox"

import { CheckboxWrapper } from "./EditAzureCapIncludeExclude.styles"

export type Props = {
  type: "users" | "groups" | "applications"
  policyIds: string[]
  onChangePolicyIds: (policyIds: string[]) => void
  includeExcludeActions: {
    add_to_inclusion_list: boolean
    remove_from_inclusion_list: boolean
    add_to_exclusion_list: boolean
    remove_from_exclusion_list: boolean
  }
  onChangeIncludeExcludeAction: (
    action: keyof Props["includeExcludeActions"],
    enabled: boolean,
  ) => void
  matchingMethod: "snapshot" | "delta"
  onChangeMatchingMethod: (matchingMethod: Props["matchingMethod"]) => void
  groups?: { label: string; value: string }[]
  onChangeGroups?: (groups: Required<Props>["groups"]) => void
  applications?: { label: string; value: string }[]
  onChangeApplications?: (applications: Required<Props>["applications"]) => void
}

export function EditAzureCapIncludeExclude({
  type,
  policyIds,
  onChangePolicyIds,
  includeExcludeActions: actions,
  onChangeIncludeExcludeAction,
  matchingMethod,
  onChangeMatchingMethod,
  groups,
  onChangeGroups,
  applications,
  onChangeApplications,
}: Props) {
  const createActionChangeHandler =
    (key: keyof Props["includeExcludeActions"]) => (checked: boolean) =>
      onChangeIncludeExcludeAction(key, checked)
  return (
    <SpaceBetween>
      <div>
        <Typography.Text fontWeight="bold" color="700">
          Select policies
        </Typography.Text>
      </div>
      <PolicyAzureAdCombobox
        initialValue={policyIds}
        showLabel={false}
        addPadding={false}
        width=""
        onChange={(items: { value: string }[]) => {
          onChangePolicyIds(items.map((item) => item.value))
        }}
      />
      {type === "groups" && (
        <>
          <div>
            <Typography.Text fontWeight="bold" color="700">
              Select groups
            </Typography.Text>
          </div>
          <AzureAdGroupsComboBox
            defaultValue={groups}
            onChange={onChangeGroups}
          />
        </>
      )}
      {type === "applications" && (
        <>
          <AzureApplicationsCombobox
            value={applications!}
            onChange={onChangeApplications!}
            width=""
          />
        </>
      )}
      <div>
        <Typography.Text fontWeight="bold" color="700">
          Select actions
        </Typography.Text>
      </div>
      <CheckboxWrapper>
        <Checkbox
          label={`Add ${type} to inclusion list`}
          checked={actions.add_to_inclusion_list}
          disabled={
            actions.add_to_exclusion_list || actions.remove_from_inclusion_list
          }
          onChange={createActionChangeHandler("add_to_inclusion_list")}
        />
        <Checkbox
          label={`Add ${type} to exclusion list`}
          checked={actions.add_to_exclusion_list}
          disabled={
            actions.add_to_inclusion_list || actions.remove_from_exclusion_list
          }
          onChange={createActionChangeHandler("add_to_exclusion_list")}
        />
        <Checkbox
          label={`Remove ${type} from inclusion list`}
          checked={actions.remove_from_inclusion_list}
          disabled={
            actions.add_to_inclusion_list || actions.remove_from_exclusion_list
          }
          onChange={createActionChangeHandler("remove_from_inclusion_list")}
        />
        <Checkbox
          label={`Remove ${type} from exclusion list`}
          checked={actions.remove_from_exclusion_list}
          disabled={
            actions.add_to_exclusion_list || actions.remove_from_inclusion_list
          }
          onChange={createActionChangeHandler("remove_from_exclusion_list")}
        />
      </CheckboxWrapper>
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={matchingMethod}
        onChange={(e: any) => {
          onChangeMatchingMethod(e.target.value)
        }}
        options={NOTIFICATION_OPTIONS}
        containerPadding="0"
      />
    </SpaceBetween>
  )
}
