import React from "react"
import { convertToAnnotatedQueryString } from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/utils/convertToQueryString"
import { PrettyR3CriteriaLayout } from "@src/components/PrettyR3CriteriaLayout"
import { SkeletonLoader } from "@src/utils/skeleton"
import useGetCriteriaFieldsNames from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/utils/useGetCriteriaFieldsNames"

import { PrettyLogicalOperator } from "./PrettyPrintedR3Criteria.styles"

export function PrettyPrintedR3Criteria({ criteria }) {
  const { isLoadingFieldsNames, fieldsNamesMap } = useGetCriteriaFieldsNames([
    criteria,
  ])

  if (isLoadingFieldsNames) {
    return <SkeletonLoader height={100} />
  }

  return (
    <PrettyR3CriteriaLayout
      criteria={criteria}
      renderLogicalOperator={(logicalOperator) => (
        <PrettyLogicalOperator>{logicalOperator}</PrettyLogicalOperator>
      )}
      renderLeaf={(leaf) => convertToAnnotatedQueryString(leaf, fieldsNamesMap)}
    />
  )
}
