import styled from "styled-components"
import Input from "@elevate_security/elevate-component-library/dist/Input"

export const ActionInsightsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  min-height: 140px;
  h5 {
    margin-top: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #34383c;
    margin-bottom: 24px;
  }
  p:first-child {
    font-style: normal;
    font-size: 24px;
    line-height: 25px;
    color: #34383c;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    display: inline;
    font-weight: bold;
    svg {
      margin-bottom: 5px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #565d66;
    margin-bottom: 16px;
  }
`

export const ActionInsightsBox = styled.div`
  margin-top: 8px;
  border: 1px solid #c9cfd6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 72px;
  &:hover {
    cursor: ${(props) => (props.pointer ? "pointer" : "default")};
    box-shadow: 0px 0px 7.5px -1.3px #c9cfd6;
    transition: 0.1s;

    button {
      background-color: #039ba1;
    }
  }
  button {
    margin-top: 17px;
    margin-right: 24px;
    white-space: nowrap;
  }

  svg {
    margin: 24px 10px 24px 26px;
  }
  path {
    fill: #0c7d84;
  }
`
export const ActionInsightData = styled.div`
  width: 100%;
  margin-top: 24px;
  p:first-child {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
  }
  p:nth-of-type(2) {
    margin-top: 0;
    margin-bottom: 24px;
    width: fit-content;
    display: flex;
    flex-direction: row;
  }
`
export const SkeletonContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  hr {
    margin: 10px 0 10px 0 !important;
  }
`
export const NoInsightData = styled.div`
  background-color: #f5f6f8;
  border-radius: 4px;
  min-width: 1016px;
  min-height: 110px;
  font-size: 14px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  img {
    margin-top: 40px;
  }

  h6 {
    color: #565d66;
    font-weight: 700;
    margin-top: 20px;
  }
  p {
    color: #565d66;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
`
export const InsightPageTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const InsightPageH1Container = styled.div`
  h1 {
    font-size: 32px;
    font-style: normal;
    color: #34383c;
    font-weight: bolder;
    margin-right: 10px;
  }
`

export const StyledStats = styled.span`
  font-weight: bold;
  color: #0c7d84;
`

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
`

export const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdfbf8;
  width: 354px;
  height: 96px;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 10px;

  h3 {
    font-weight: 800;
    font-size: 32px;
    color: #34383c;
  }
  p {
    font-weight: 700;
    color: #7b828a;
    font-size: 12px;
  }
`

export const InsightDescription = styled.div`
  color: #34383c !important;
  font-size: 14px !important;
  font-weight: 400 !important;
`

export const InsightDataHeading = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #34383c;
  margin-bottom: 13px;
`
export const InsightDetail = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #565d66;
`
export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 4px;
  gap: 16px;

  [class^="rbt-input-main"] {
    min-width: 320px;
  }
  [class^="inputstyles__InputContainer"] {
    .icons-left {
      left: 16px;
      svg {
        stroke: #7e8691;
      }
    }
    .eZGrNi {
      background-color: white;
    }
  }
  [class^="sc-ibuqYl dyCFxG"] {
    width: 400px;
  }
  [class^="checkbox-items"] {
    input:hover {
      cursor: pointer;
    }
    label:hover {
      cursor: pointer;
    }
  }
  [class^="title"] {
    cursor: default;
  }
  [class^="rbt"][tabindex="-1"] {
    width: 125%;
  }
  .rc-slider {
    width: 328px;
    cursor: pointer;
  }
`
const tableWidth = (columns) => `calc(100% / ${columns}) !important`
export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
    /* Stick table header at top */
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      position: relative;
      top: 0;
      z-index: 998;
      background: white;
      overflow: visible;
      padding-right: 0;
      [class^="Tablestyles__TableRow"] {
        padding: 0;
        height: 56px;
        [class^="Tablestyles__TableData"] {
          padding-top: 12px;
          padding-bottom: 12px;
          background: white;
          span {
            color: #7b828a;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      [class^="Tablestyles__TableRow"] {
        cursor: default;
      }
      [class^="Tablestyles__TableData"][data-column-key="checkbox"] input {
        margin-left: 6px;
      }
    }
  }
  [class^="Tablestyles__TableRow"] {
    padding: 0;
    height: 40px;
    margin-top: 0;
  }
  [class^="Tablestyles__TableData"] {
    /* flex: 0 0 calc(100% / 7); */
    /* width: calc(100% / 7); */
    min-width: ${(props) => tableWidth(props.columns)};
    width: ${(props) => tableWidth(props.columns)};
    max-width: ${(props) => tableWidth(props.columns)};
    //min-width: calc(100% / 5) !important;

    &:last-child {
      padding-right: 0;
    }
    > .span {
      display: block;
      width: 100%;
    }
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #7b828a;
    }
  }
`
export const SearchInput = styled(Input)`
  background-color: #eef0f3;
  width: 320px;
  border: none;
  padding-left: 40px;
  display: flex;
  :hover {
    border: none;
  }
`
export const TabWrapper = styled.div`
  margin-top: 11px;
  ul {
    margin: 0;
  }
  li {
    a[aria-selected="true"] {
      color: #34383c;
    }
  }
`
export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 12px 0px 0;
  width: 100%;
  align-items: center;
  width: auto;
  .sc-hHfuMS {
    width: 16px !important;
    border-radius: 2px;
  }
`

export const ScoreDiv = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  font-weight: 700;
  margin-right: 13px;
  width: 25px;
`
export const ScoreBar = styled.div``

export const ViewEvent = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #7b828a;
  text-decoration-color: #7b828a;
  &:hover {
    cursor: pointer;
  }
`
export const IndividualTableWrapper = styled.div`
  position: relative;
  width: 100%;
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
    /* Stick table header at top */
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      position: relative;
      top: 0;
      z-index: 990;
      background: white;
      overflow: visible;
      padding-right: 0;
      [class^="Tablestyles__TableRow"] {
        padding: 0;
        height: 56px;
        [class^="Tablestyles__TableData"] {
          padding-top: 12px;
          padding-bottom: 12px;
          background: white;
          span {
            color: #7b828a;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      [class^="Tablestyles__TableRow"] {
        cursor: default;
      }
      [class^="Tablestyles__TableData"][data-column-key="checkbox"] input {
        margin-left: 6px;
      }
    }
  }
  [class^="Tablestyles__TableRow"] {
    padding: 0;
    height: 40px;
    margin-top: 0;
  }
  [class^="Tablestyles__TableData"] {
    /* flex: 0 0 calc(100% / 7); */
    /* width: calc(100% / 7); */
    min-width: ${(props) => tableWidth(props.columns)};
    width: ${(props) => tableWidth(props.columns)};
    max-width: ${(props) => tableWidth(props.columns)};
    padding-left: 10px;
    //min-width: calc(100% / 5) !important;

    &:nth-child(n + 2) {
      min-width: ${(hasEmail) => (hasEmail ? "240px" : "130px")} !important;
    }

    &:last-child {
      padding-right: 0;
      padding-left: 75px;
    }
    > .span {
      display: block;
      width: 100%;
      padding-left: 4px;
    }
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #7b828a;
    }
  }
`

export const PercentageGraphContainer = styled.div`
  h5 {
    margin-top: 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #34383c;
    margin-bottom: 24px;
  }
`

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 44px;
  height: 30px;
`

export const LegendSolidCircleTitle = styled.div`
  padding-left: 5px;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const SolidCircle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #b8e5e3;
  padding-top: 10px;
  border-radius: 2px;
  opacity: 0.3;
  margin-right: 8px;
`
export const ActionAbleInsightSkeleton = styled.div`
  margin-top: 8px;
  min-height: 104px;
  border: 1px solid #c9cfd6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ExportIconWrapper = styled.div`
  margin-left: auto;
`
export const DragoWrapper = styled.div`
  height: 350px;
`
export const IconExportWrapper = styled.div`
  min-width: 210px;
  svg {
    margin: 0 !important;
  }
  span {
    margin: 15px 5px 15px 0;
  }
`

export const ClearFilters = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  color: #34383c;
  cursor: pointer;
`
