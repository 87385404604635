import {
  INDIVIDUALS_MAILS,
  RECEPTION_GROUPS_DATA,
  RULE_ACTIONS,
  SEND_VIA_SPLUNK,
  SEND_VIA_WEB_HOOK,
  SERVICE_WEB_HOOK,
  SERVICES_TOGGLES_DATA,
  SET_OUTBOUND_TEMPLATE_ATTRIBUTES,
  SET_SERVICE_WEBHOOK_MATCHING_METHOD,
  SET_SPLUNK_MATCHING_METHOD,
  SET_WEBHOOK_MATCHING_METHOD,
  SET_SHOW_NOTIFICATION_TEMPLATE,
} from "@src/services/redux/RiskDetectionRules/DefineOutcome/types"

export const setIndividualsMailsDefineOutCome = (individualsMails) => {
  return {
    type: INDIVIDUALS_MAILS,
    individualsMails,
  }
}

export const setSendViaSplunkDefineOutCome = (sendViaSplunk) => {
  return {
    type: SEND_VIA_SPLUNK,
    sendViaSplunk: sendViaSplunk,
  }
}

export const setReceptionGroupDataDefineOutCome = (receptionGroupData) => {
  return {
    type: RECEPTION_GROUPS_DATA,
    receptionGroupData,
  }
}

export const setSendViaWebhookDefineOutCome = (sendViaWebhook) => {
  return {
    type: SEND_VIA_WEB_HOOK,
    sendViaWebhook,
  }
}

export const setServiceWebhookDefineOutCome = (serviceWebHook) => {
  return {
    type: SERVICE_WEB_HOOK,
    serviceWebHook,
  }
}

export const setRuleActionsDefineOutCome = (ruleActions) => {
  return {
    type: RULE_ACTIONS,
    ruleActions,
  }
}
export const setTogglesServicesDefineOutCome = (servicesToggle) => {
  return {
    type: SERVICES_TOGGLES_DATA,
    servicesToggle,
  }
}

export function setOutboundTemplateAttributes(data) {
  return {
    type: SET_OUTBOUND_TEMPLATE_ATTRIBUTES,
    data,
  }
}

export const setSplunkMatchingMethodDefineOutCome = (method) => {
  return {
    type: SET_SPLUNK_MATCHING_METHOD,
    method,
  }
}

export const setWebhookMatchingMethodDefineOutCome = (method) => {
  return {
    type: SET_WEBHOOK_MATCHING_METHOD,
    method,
  }
}

export const setServiceWebhookMatchingMethodDefineOutCome = (method) => {
  return {
    type: SET_SERVICE_WEBHOOK_MATCHING_METHOD,
    method,
  }
}

export const setShowNotificationTemplate = (showNotificationTemplate) => {
  return {
    type: SET_SHOW_NOTIFICATION_TEMPLATE,
    showNotificationTemplate,
  }
}
