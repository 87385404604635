import React from "react"
import PropTypes from "prop-types"

const SVG = ({ className, fill, size }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
    >
      <path
        d="M4 17.375C4 18.0664 4.55859 18.625 5.25 18.625H7.38672C7.86719 19.7305 8.96875 20.5 10.25 20.5C11.5312 20.5 12.6328 19.7305 13.1133 18.625H22.75C23.4414 18.625 24 18.0664 24 17.375C24 16.6836 23.4414 16.125 22.75 16.125H13.1133C12.6328 15.0195 11.5312 14.25 10.25 14.25C8.96875 14.25 7.86719 15.0195 7.38672 16.125H5.25C4.55859 16.125 4 16.6836 4 17.375ZM9 17.375C9 16.6848 9.55977 16.125 10.25 16.125C10.9402 16.125 11.5 16.6848 11.5 17.375C11.5 18.0652 10.9402 18.625 10.25 18.625C9.55977 18.625 9 18.0652 9 17.375ZM16.5 11.125C16.5 10.4348 17.0598 9.875 17.75 9.875C18.4402 9.875 19 10.4348 19 11.125C19 11.8152 18.4402 12.375 17.75 12.375C17.0598 12.375 16.5 11.8152 16.5 11.125ZM17.75 8C16.4688 8 15.3672 8.76953 14.8867 9.875H5.25C4.55859 9.875 4 10.4336 4 11.125C4 11.8164 4.55859 12.375 5.25 12.375H14.8867C15.3672 13.4805 16.4688 14.25 17.75 14.25C19.0312 14.25 20.1328 13.4805 20.6133 12.375H22.75C23.4414 12.375 24 11.8164 24 11.125C24 10.4336 23.4414 9.875 22.75 9.875H20.6133C20.1328 8.76953 19.0312 8 17.75 8ZM11.5 6.125C10.8098 6.125 10.25 5.56523 10.25 4.875C10.25 4.18477 10.8098 3.625 11.5 3.625C12.1902 3.625 12.75 4.18477 12.75 4.875C12.75 5.56523 12.1902 6.125 11.5 6.125ZM14.3633 3.625C13.8828 2.51953 12.7812 1.75 11.5 1.75C10.2188 1.75 9.11719 2.51953 8.63672 3.625H5.25C4.55859 3.625 4 4.18359 4 4.875C4 5.56641 4.55859 6.125 5.25 6.125H8.63672C9.11719 7.23047 10.2188 8 11.5 8C12.7812 8 13.8828 7.23047 14.3633 6.125H22.75C23.4414 6.125 24 5.56641 24 4.875C24 4.18359 23.4414 3.625 22.75 3.625H14.3633Z"
        fill={fill}
      />
    </svg>
  )
}

export default SVG

SVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

SVG.defaultProps = {
  fill: "#959DA8",
  className: "",
  size: "24px",
}
