import React, { useCallback, memo, useState } from "react"
import PropTypes from "prop-types"
import { useToggle } from "ahooks"

import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"

// Components
import {
  BehaviorsBreakdownChartWrapper,
  Row,
  LabelBar,
  ProgressBar,
  MultiProgressBar,
  ScoreValue,
  ScoreTrend,
  ScoreIcon,
} from "./styles"
import ImproveBehaviorModal from "../../../modals/ImproveBehavior"
import { parseScoreChange, renderScoreTooltip } from "./utils"
import { getIconAndColorByScoreDiff } from "@src/utils/colors"

function BreakdownChart(props) {
  const [showModal, { toggle: toggleShowModal }] = useToggle(false)
  const [tooltipBehavior, setTooltipBehavior] = useState(null)
  const { behaviorsStatusData: data } = props

  const renderItem = useCallback(
    ({ key, name, teamScore, teamScoreTrend, orgScore }) => {
      const { icon, color } = getIconAndColorByScoreDiff(teamScoreTrend)

      // Row for Score 1
      const BehaviorScoreRow = () => (
        <>
          <Tooltip
            body={renderScoreTooltip({
              name,
              teamScore,
              teamScoreTrend,
              onImproveBehavior: () => {
                setTooltipBehavior({
                  key,
                  name,
                  teamScore,
                  teamScoreTrend,
                })
                toggleShowModal()
              },
              setTooltipBehavior,
            })}
            placement="top"
            parentClass="score-bar-wrapper-span"
          >
            <ProgressBar value={teamScore} height="20px" />
          </Tooltip>
          <ScoreValue>{teamScore}</ScoreValue>
          <ScoreIcon>
            <Icon name={icon} fill={color} />
          </ScoreIcon>
          <ScoreTrend color={color}>
            {parseScoreChange(teamScoreTrend)}
          </ScoreTrend>
        </>
      )

      if (key === "overall") return null

      return (
        <Row key={name}>
          <LabelBar>{name}</LabelBar>
          <MultiProgressBar>
            <Row padding="0 0">
              <BehaviorScoreRow />
            </Row>
            <Row padding="0 44px 0 0">
              <ProgressBar value={orgScore} bgColor="#cee5e5" height="20px" />
              <ScoreValue>{orgScore}</ScoreValue>
            </Row>
          </MultiProgressBar>
        </Row>
      )
    },
    [],
  )

  return (
    <BehaviorsBreakdownChartWrapper>
      {data.map(renderItem)}
      <ImproveBehaviorModal
        modalTitle={tooltipBehavior?.name}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        behaviors={[tooltipBehavior]}
        showScoreBar={true}
      />
    </BehaviorsBreakdownChartWrapper>
  )
}

BreakdownChart.propTypes = {
  behaviorsStatusData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      teamScore: PropTypes.number,
      teamScoreTrend: PropTypes.number,
      orgScore: PropTypes.number,
    }),
  ).isRequired,
}

export default memo(BreakdownChart)
