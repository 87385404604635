import * as d3 from "d3"
import { replaceAllWhiteSpace } from "@src/utils/string"
import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"

const breakPoints = {
  1280: {
    paddingBar: 0.3,
  },
  1440: {
    paddingBar: 0.25,
  },
  1920: {
    paddingBar: 0.36,
  },
}

const generateBarColors = (key, xLabel) => {
  if (xLabel === "Score") {
    if (key === "very_low") return "#2C9097"
    else if (key === "low") return "#3CC1AE"
    else if (key === "medium") return "#FACC38"
    else if (key === "high") return "#F08526"
    else if (key === "very_high") return "#D23A54"
  } else {
    return THEME_PRIMARY
  }
}

export function createYAxis({
  data,
  svgHeight,
  graphArea,
  paddingLeft,
  paddingTop,
}) {
  const values = data.map(({ value }) => value)
  const max = d3.max(values)
  const min = 0
  const y = d3
    .scaleLinear()
    .rangeRound([svgHeight - paddingTop, 0])
    .domain([min, max])
  const yAxis = d3
    .axisLeft(y)
    .tickSize(0)
    .tickSizeOuter(0)
    .tickPadding(15)
    .ticks(4)
    .tickFormat((value) =>
      value >= 1000 ? `${parseFloat(value / 1000).toFixed(1)}K` : value,
    )
    .scale(y)

  const yAxisRender = graphArea
    .append("g")
    .style("font-size", "14px")
    .style("line-height", "24px")
    .style("cusor", "pointer")
    .attr("transform", `translate(${paddingLeft},${paddingTop})`)
    .call(yAxis)
    .selectAll("path")
    .attr("fill", "#C9CFD6")
    .attr("stroke", "#C9CFD6")
  return { y, yAxis, yAxisRender }
}

export function createXAxis({
  data,
  graphArea,
  svgWidth,
  svgHeight,
  paddingLeft,
  onBarMouseIn,
  onBarMouseOut,
  xAxisLabel,
  showSecondaryXAxisLabel,
}) {
  const paddingBars = () => {
    if (window.innerWidth >= 1920) return breakPoints[1920].paddingBar
    if (window.innerWidth >= 1440) return breakPoints[1440].paddingBar
    if (window.innerWidth >= 1280) return breakPoints[1280].paddingBar
    return breakPoints[1280].paddingBar
  }

  const x = d3
    .scaleBand()
    .rangeRound([0, svgWidth - paddingLeft * 2])
    .domain(data.map((d) => d.label))
    .padding(paddingBars())

  const x2 = d3
    .scaleBand()
    .rangeRound([0, svgWidth - paddingLeft * 2])
    .domain(data.map((d) => d.secondaryLabel))
    .padding(paddingBars())

  const xAxis = d3.axisBottom(x).tickSize(0).tickPadding(15).scale(x)
  const xAxis2 = d3.axisBottom(x2).tickSize(0).tickPadding(15).scale(x2)

  const xAxisRender = graphArea
    .append("g")
    .style("font-size", "14px")
    .style("line-height", "24px")
    .style("cursor", "pointer")
    .attr("transform", `translate(${paddingLeft},${svgHeight + 30})`)
    .call(xAxis)
    .attr("class", "second_axis")
    .select("path")
    .attr("class", "second_axis_path")
    .selectAll("path")
    .attr("fill", "#C9CFD6")
    .attr("stroke", "#C9CFD6")

  let xAxisRender2
  if (showSecondaryXAxisLabel) {
    xAxisRender2 = graphArea
      .append("g")
      .style("font-size", "14px")
      .style("line-height", "24px")
      .style("cursor", "pointer")
      .attr("transform", `translate(${paddingLeft},${svgHeight})`)
      .call(xAxis2)
      .selectAll("path")
      .attr("fill", "#C9CFD6")
      .attr("stroke", "#C9CFD6")
  }

  const gPath = d3.select(".second_axis_path")
  gPath.remove()

  const gLine = d3.select(".second_axis").selectAll("line")
  gLine.remove()

  // Manage displaying tooltip when mouse enters and exits x-axis ticks
  graphArea
    .selectAll(".tick")
    .on("mouseenter", function (label) {
      const obj = data.find((item) => item.label === label)
      if (
        obj != null &&
        typeof onBarMouseIn === "function" &&
        Object.keys(obj).length > 0
      ) {
        const barId = `#bar-${replaceAllWhiteSpace(
          label,
        )}-${replaceAllWhiteSpace(xAxisLabel)}`
        if (obj.value > 0) {
          d3.select(barId).style(
            "fill",
            xAxisLabel === "Score"
              ? generateBarColors(obj.key, xAxisLabel)
              : THEME_HOVER,
          )
        } // Change corresponding bar color: ;
        onBarMouseIn({ label, value: obj.value, barId }) // Show tooltip
      }
    })
    .on("mouseout", function (label) {
      const obj = data.find((item) => item.label === label)
      if (
        obj != null &&
        typeof onBarMouseOut === "function" &&
        Object.keys(obj).length > 0
      ) {
        const barId = `#bar-${replaceAllWhiteSpace(
          label,
        )}-${replaceAllWhiteSpace(xAxisLabel)}`
        if (obj.value > 0) {
          d3.select(barId).style(
            "fill",
            xAxisLabel === "Score"
              ? generateBarColors(obj.key, xAxisLabel)
              : THEME_PRIMARY,
          )
        } // Change corresponding bar color: ;
        onBarMouseOut({ label, value: obj.value, barId })
      }
    })

  return { x, xAxis, xAxisRender, x2, xAxis2, xAxisRender2 }
}

export function createYAxisLabel({ svgContainer }) {
  svgContainer
    .insert("g", ".chart-container")
    .attr("transform", `translate(10,115)`)
    .append("text")
    .text("# of Individuals")
    .style("transform", "rotate(-90deg)")
    .style("font-size", "14px")
    .style("line-height", "24rem")
    .style("font-weight", "bold")
}

export function createXAxisLabel({
  svgContainer,
  svgWidth,
  parentHeight,
  xAxisLabel,
}) {
  svgContainer
    .append("g")
    .attr("class", "label-axis--x")
    .append("text")
    .text(xAxisLabel || "Score Distributions")
    .style("font-size", "14px")
    .style("line-height", "24rem")
    .style("font-weight", "bold")
    .select(function () {
      const text = d3.select(this)
      const { width } = text.node().getBoundingClientRect()
      const g = d3.select(text.node().parentElement)
      g.attr("transform", `translate(${svgWidth - width},${parentHeight})`)
    })
}

export function createChartBar({
  element,
  data,
  onBarMouseOut,
  onBarMouseIn,
  onBarMouseClick,
  xAxisLabel,
  graphHeight,
  showSecondaryXAxisLabel,
}) {
  const parentNode = element.parentElement
  const parentWidth = parentNode.clientWidth || 500
  const parentHeight = graphHeight || 320

  const paddingLeft = 40
  const paddingTop = 40

  const svgWidth = parentWidth - paddingLeft
  const svgHeight = parentHeight - paddingTop

  const svgContainer = d3
    .select(element)
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", `0 0  ${svgWidth} ${parentHeight}`)

  // create the graph area
  const graphArea = svgContainer
    .append("g")
    .attr("class", "chart-container")
    .attr("transform", `translate(${paddingLeft},-${paddingTop - 5})`)

  // create the axisY
  const { y } = createYAxis({
    data,
    graphArea,
    svgHeight,
    svgWidth,
    paddingLeft,
    paddingTop: paddingTop + 3,
  })

  // create the axisX scale
  const { x } = createXAxis({
    data,
    graphArea,
    svgHeight,
    svgWidth,
    paddingLeft,
    paddingTop,
    onBarMouseIn,
    onBarMouseOut,
    xAxisLabel,
    showSecondaryXAxisLabel,
  })

  // render label on axisY
  createYAxisLabel({ svgContainer })

  // render label on axisX
  createXAxisLabel({
    svgContainer,
    svgWidth,
    paddingLeft,
    parentHeight,
    xAxisLabel,
  })

  // create the Bar styles and events
  const rx = 4
  const ry = 4

  graphArea
    .selectAll(".bar")
    .data(data)
    .enter()
    .append("path")
    .attr("class", "bar")
    .style("fill", ({ key }) => generateBarColors(key, xAxisLabel))
    .style("cursor", "pointer")
    .attr("transform", `translate(${paddingLeft},${paddingTop})`)
    .attr(
      "d",
      (item) => `
            M${x(item.label)},${y(item.value > 0 ? item.value : 1) + ry}
            a${rx},${ry} 0 0 1 ${rx},${-ry}
            h${x.bandwidth() - 2 * rx}
            a${rx},${ry} 0 0 1 ${rx},${ry}
            v${svgHeight - paddingTop - y(item.value > 0 ? item.value : 1) - ry}
            h${-x.bandwidth()}Z
          `,
    )
    .attr(
      "id",
      (item) =>
        `bar-${replaceAllWhiteSpace(item.label)}-${replaceAllWhiteSpace(
          xAxisLabel,
        )}`,
    )
    .on("mouseover", function ({ label, value, key }) {
      if (value > 0) {
        d3.select(this).style(
          "fill",
          xAxisLabel === "Score"
            ? generateBarColors(key, xAxisLabel)
            : THEME_HOVER,
        )
      }
      const barId = `#bar-${replaceAllWhiteSpace(label)}-${replaceAllWhiteSpace(
        xAxisLabel,
      )}`
      if (typeof onBarMouseIn === "function") {
        onBarMouseIn({ label, value, barId })
      }
    })
    .on("mouseout", function ({ label, value, key }) {
      if (value > 0) {
        d3.select(this).style(
          "fill",
          xAxisLabel === "Score"
            ? generateBarColors(key, xAxisLabel)
            : THEME_PRIMARY,
        )
      }
      const barId = `#bar-${replaceAllWhiteSpace(label)}-${replaceAllWhiteSpace(
        xAxisLabel,
      )}`
      if (typeof onBarMouseOut === "function") {
        onBarMouseOut({ label, value, barId })
      }
    })
    .on("click", function ({ label }) {
      onBarMouseClick && onBarMouseClick(label, xAxisLabel)
    })

  return { graphArea, y, x }
}
