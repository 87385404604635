import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams, useHistory } from "react-router-dom"
import PageHeader from "@src/components/PageHeader"
import { BREADCRUMB_LINKS, BREADCRUMB_LINKS_MANAGER } from "../../utils"
import Banner from "../../components/Banner"
import { setManagerInfo } from "@src/scenes/ManagerDashboard/services/redux/actions"
import {
  getManagerInfo,
  getManagerOverview,
} from "@src/scenes/ManagerDashboard/services/redux/selectors"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { ButtonLink, Link } from "@elevate_security/elevate-component-library"
import ManagerDashboardV1 from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV1"
import ManagerDashboardV2 from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2"
import { darklyGetFlag } from "@src/utils/darkly"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"

const Session = () => {
  const dispatch = useDispatch()
  const [showBanner, setShowBanner] = useState(true)
  const { pathname } = useLocation()
  const { person_nid } = useParams()
  const history = useHistory()
  const manager = useSelector(getManagerInfo)
  const { managerInfo } = useSelector(getManagerOverview)
  const whoAmI = useSelector(getWhoAMI)

  const showDataSourceCol = darklyGetFlag("manager-dashboard-v2")

  const isEngagementPlatform = pathname.includes("/engagement/vision2")

  let fullName = ""
  let greetings = ""

  if (manager && manager.first_name) {
    greetings = `Hi ${manager.first_name},`
    fullName = `${manager.first_name} ${manager.last_name}`
  }

  const breadcrumbLinks = managerInfo?.is_manager
    ? BREADCRUMB_LINKS_MANAGER(person_nid ? manager.first_name : null)
    : BREADCRUMB_LINKS

  useEffect(() => {
    dispatch(setManagerInfo(person_nid))
    setShowBanner(whoAmI?.id !== person_nid)
  }, [person_nid, whoAmI])

  const onLogout = () => {
    const UNAUTHORIZED = "manager:unauthorized"
    const UNAUTHORIZED_GLOBAL = "global:unauthorized"
    /**
     * This event is dispatched from here and listened in the EP
     */
    window.dispatchEvent(
      new Event(!isEngagementPlatform ? UNAUTHORIZED : UNAUTHORIZED_GLOBAL, {
        bubbles: true,
        cancelable: false,
      }),
    )
  }

  const renderBanner = () =>
    person_nid &&
    showBanner && (
      <div>
        <br />
        <Banner>
          You are viewing Manager Dashboard of&nbsp;<b>{fullName}</b>.&nbsp;
          {whoAmI?.is_manager && (
            <>
              <Link
                onClick={() => {
                  history.push("/vision2/manager-dashboard")
                  window.location.reload()
                }}
              >
                Click here
              </Link>
              &nbsp;to view your Manager Dashboard.
            </>
          )}
          <span style={{ marginRight: "auto" }} />
          <ButtonLink onClick={() => setShowBanner(false)}>
            OK, got it
          </ButtonLink>
        </Banner>
      </div>
    )

  if (!manager) {
    return <ActivityIndicator active />
  }

  return (
    <>
      <PageHeader
        breadcrumbLinks={breadcrumbLinks}
        title={greetings}
        subtitle="Here's your team's security score dashboard."
        contentOnTheRightOfTitle={
          !isEngagementPlatform || manager.es_role === "user" ? (
            <ButtonLink onClick={onLogout}>Logout</ButtonLink>
          ) : null
        }
        renderBanner={renderBanner}
      />
      {showDataSourceCol ? <ManagerDashboardV2 /> : <ManagerDashboardV1 />}
    </>
  )
}

export default Session
