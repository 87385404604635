import styled from "styled-components"

export const AddRemoveAppLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: 185px;
`

export const AddRemoveAppConditionalAccess = styled.div`
  [class^="ComboBoxstyles__SelectContainer"] {
    margin: 0;
    ${(props) => (props.width ? `width: ${props.width};` : "")}
  }
  input:disabled {
    background-color: #f5f6f8;
  }
`
