import styled from "styled-components"

export const RelativeDateInputWrapper = styled.div`
  .relative-date-input {
    width: 80px;
    border-radius: 6px;
  }

  #relative-date-input-operators {
    width: 120px;
    border-radius: 6px;
  }
`
