import { combineReducers } from "redux-immutable"
import { applyMiddleware, compose, createStore } from "redux"
import { thunk } from "redux-thunk"

import visionReducer from "@src/services/reducers/visionReducer"
import scoreReducer from "@src/services/redux/scores/reducer"
import groupsReducer from "@src/services/redux/groups/reducer"
import trendSummaryReducer from "@src/services/redux/trendSummary/reducer"
import managerScoresReducer from "@src/scenes/ManagerDashboard/services/redux/reducer"
import toastsReducer from "@src/services/redux/toasts/reducer"
import policyReducer from "@src/services/redux/policies/reducer"
import reputationsReducer from "@src/services/redux/reputations/reducer"
import analysisIndividualReducer from "@src/services/redux/analysisIndividuals/analysisIndividualReducer"
import individualProfileReducer from "@src/services/redux/IndividualProfile/individualProfileReducer"
import ruleInfoReducer from "@src/services/redux/RiskDetectionRules/RuleInfo/RuleInfoReducer"
import defineOutcomeReducer from "@src/services/redux/RiskDetectionRules/DefineOutcome/DefineOutcomeReducer"
import pngReducer from "@src/services/redux/exportPNG/reducer"

const rootReducer = combineReducers({
  visionReducer,
  visionScoresReducer: scoreReducer,
  groupsReducer,
  toastsReducer,
  trendSummaryReducer,
  managerScoresReducer,
  policyReducer,
  reputationsReducer,
  analysisIndividualReducer,
  individualProfileReducer,
  defineOutcomeReducer,
  ruleInfoReducer,
  pngReducer,
})

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // other store enhancers if any
)

const store = createStore(rootReducer, enhancer)

export default store
