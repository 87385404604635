import React from "react"
import { Link, Icon } from "@elevate_security/elevate-component-library"

import { formatDate } from "@src/utils/dates"
import { IconWrapper, LinkCampaign, CampaignName } from "./styles"

export const TREND_SUMMARY_TABLE_COLUMNS = [
  {
    key: "name",
    header: "ACTION TAKEN",
    isHeadSort: false,
  },
  {
    key: "status",
    header: "COMPLETION DATE",
    isHeadSort: false,
  },
  {
    key: "total_targets",
    header: "# OF INDIVIDUALS TARGETED",
    isHeadSort: false,
  },
]

export function getStatus(end_date) {
  if (!end_date) return "In Progress"
  const end = new Date(end_date).getTime()
  const current = Date.now()

  return end >= current
    ? "In Progress"
    : formatDate({ date: end_date, format: "MM/DD/YYYY" })
}

function getProperIconName(type) {
  switch (type) {
    case "pulse_campaign":
      return "ProductPulseColored"
    case "reflex_campaign":
      return "ProductReflexColored"
    case "hm_campaign":
      return "ProductHMColored"
    default:
      return type
  }
}

const getCampaignBaseLink = (type) =>
  ({
    hm_campaign: (id) =>
      `/engagement/hackersmind/campaigns/campaign/overview/${id}`,
    pulse_campaign: (id) => `/engagement/pulse/campaigns/${id}`,
    reflex_campaign: (id) => `/engagement/reflex/campaign/report/${id}`,
  })[type]

//TODO:  For now, theres no links for pulse or reflex campaign, still in progress
export function getCampaignNameAndLink(type, name, id) {
  return (
    <Link to={getCampaignBaseLink(type)(id)}>
      <LinkCampaign>
        <IconWrapper>
          <Icon name={getProperIconName(type)} size="xsm" />
        </IconWrapper>
        <CampaignName>{name}</CampaignName>
      </LinkCampaign>
    </Link>
  )
}

//TODO:  For now, theres no links for pulse or reflex campaign, still in progress
export function getTargetMembersLink(total_targets, type, id) {
  switch (type) {
    case "hm_campaign":
      return <Link to={getCampaignBaseLink(type)(id)}>{total_targets}</Link>
    default:
      return total_targets
  }
}
export function parseResults(results = []) {
  if (!Array.isArray(results)) return []
  return results.map((result) => ({
    ...result,
    name: getCampaignNameAndLink(
      result?.campaign_type,
      result?.name,
      result?.id,
    ),
    status: getStatus(result.end_date),
  }))
}
