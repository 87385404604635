import {
  fetchTeamMonthlyScores,
  fetchOrgScores,
  fetchTeamData,
  fetchCurrentManager,
  fetchTeamScoresByIndividuals,
  rewardTopPerformers,
  sendEnrollEmail,
  fetchManagerOverview,
} from "../apis"
import {
  SET_ORG_SCORE,
  SET_TEAM_DATA,
  SET_TEAM_SCORES_MONTHLY,
  SET_MANAGER_INFO,
  SET_TEAM_SCORES_BY_INDIVIDUALS,
  SET_TOP_PERFORMERS_COUNT,
  SET_MANAGER_OVERVIEW,
  SET_MANAGER_SCORES,
  SET_TEAM_TRENDS,
  SET_MANAGER_TEAM_DATA,
  SET_MANAGER_TEAM_DATA_LOADER,
  SET_CHILD_MANAGER_TEAM_DATA,
  SET_INITIAL_VALUES_MANGER,
  REMOVE_CHILD_MANAGER_TEAM_DATA,
  SET_CHILD_LOADING,
} from "./types"
import {
  getManagerScore,
  getManagerTeam,
  getTeamTrends,
} from "@src/services/apis/reputation"
import { getFiltersParam } from "../../scenes/Session/ManagerDashboardV2/utils"
import { HUMAN_RISK_DESC } from "@src/scenes/HumanRiskDashboard/constants"
import { v4 as uuidv4 } from "uuid"

export function setManagerInfo(person_nid) {
  return async (dispatch) => {
    const { error, data } = await fetchCurrentManager(person_nid)

    dispatch({
      type: SET_MANAGER_INFO,
      data: !error ? data : null,
    })
  }
}

export function setOrgScore(person_nid) {
  return async (dispatch) => {
    const { error, data } = await fetchOrgScores(person_nid)
    dispatch({
      type: SET_ORG_SCORE,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setTeamData(person_nid) {
  return async (dispatch) => {
    const { error, data } = await fetchTeamData(person_nid)
    dispatch({
      type: SET_TEAM_DATA,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setManagerOverview() {
  return async (dispatch) => {
    const { error, data } = await fetchManagerOverview()
    dispatch({
      type: SET_MANAGER_OVERVIEW,
      data: {
        data: !error ? data : null,
        isLoaded: false,
      },
    })
  }
}
export function setManagerScoresLoadingTrue() {
  return async (dispatch) => {
    dispatch({
      type: SET_MANAGER_SCORES,
      data: {
        data: null,
        isLoaded: true,
      },
    })
  }
}
export function setManagerTeam(params) {
  return async (dispatch) => {
    const { error, data } = await getManagerTeam({
      filters: getFiltersParam({
        filters: params?.filters,
        person_nid: params?.person_nid,
        managerInfo: params?.managerInfo,
      }),
      order_by: HUMAN_RISK_DESC,
      page_number: params?.page_number,
      page_size: params?.page_size,
      es_person_id: params?.person_nid || null,
    })
    const list =
      data?.results?.map((data) => {
        const result = { ...data, key: uuidv4() }
        if (data?.is_manager) {
          result.children = []
        }
        return result
      }) || []
    if (params?.isChildData) {
      const teamData = {
        ...params.rowData,
        key: uuidv4() + "-team",
        is_manager: false,
        individual: params.rowData.individual,
        team_action_key: "team_action_score",
        team_human_risk_key: "team_human_risk_score",
        riskType: "managerDashboard",
      }

      list.unshift(teamData)
      if (list?.total_elements > params.page_size) {
        list.push({ redirectManager: true, es_manager_id: params?.person_nid })
      }

      dispatch({
        type: SET_MANAGER_TEAM_DATA,
        data: {
          isChild: true,
          teamMembers: !error ? list : null,
          person_nid: params?.person_nid,
        },
      })
      dispatch({
        type: SET_CHILD_MANAGER_TEAM_DATA,
        data: {
          es_person_id: params?.es_person_id || "",
          key: params?.es_person_id,
          rowData: params?.rowData,
          remove_person_id: params?.person_nid,
        },
      })
    } else {
      dispatch({
        type: SET_MANAGER_TEAM_DATA,
        data: {
          teamMembers: !error ? list : null,
          totalTeamMembers: data?.total_elements || 0,
        },
      })
    }
  }
}

export const resetMangerTable = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_INITIAL_VALUES_MANGER,
    })
  }
}

export function setManagerTeamLoader(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_MANAGER_TEAM_DATA_LOADER,
      data: data,
    })
  }
}

export function removeChildManagerTeamData(data) {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_CHILD_MANAGER_TEAM_DATA,
      data: data,
    })
  }
}

export function setChildManagerTeamData(params) {
  return async (dispatch) => {
    await dispatch({
      type: SET_CHILD_LOADING,
      data: {
        es_person_id: params?.es_person_id || "",
      },
    })
    await dispatch(
      setManagerTeam({
        ...params,
        person_nid: params.es_person_id,
        isChildData: true,
      }),
    )
    await dispatch({
      type: SET_CHILD_MANAGER_TEAM_DATA,
      data: {
        es_person_id: params?.es_person_id || "",
        key: params?.es_person_id,
        rowData: params?.rowData,
      },
    })
  }
}

export function setManagerScores(person_nid) {
  return async (dispatch) => {
    const { error, data } = await getManagerScore({ es_person_id: person_nid })
    dispatch({
      type: SET_MANAGER_SCORES,
      data: {
        data: !error ? data : null,
        isLoaded: false,
      },
    })
  }
}

export function setTeamTrendLoading() {
  return async (dispatch) => {
    dispatch({
      type: SET_TEAM_TRENDS,
      data: {
        data: null,
        isLoaded: true,
      },
    })
  }
}
export function setTeamTrend(params) {
  return async (dispatch) => {
    const { error, data } = await getTeamTrends(params)

    let newData
    if (!error) {
      const keysData = Object.values(data[0] || {})[0] || {}
      const keys = Object.keys(keysData)
      newData = keys?.reduce((pre, nextKey) => {
        const itemData = data.map((item) => {
          const itemEnties = Object.entries(item)[0]
          return { date: itemEnties[0], score: itemEnties[1][nextKey] }
        })
        return { ...pre, [nextKey]: itemData }
      }, {})
    }

    dispatch({
      type: SET_TEAM_TRENDS,
      data: {
        data: !error ? newData : null,
        isLoaded: false,
      },
    })
  }
}

export function setTeamScoresMonthly(person_nid) {
  return async (dispatch) => {
    const { error, data } = await fetchTeamMonthlyScores(person_nid)
    let items = []

    if (!error) {
      const hasData =
        data &&
        Object.keys(data.hierarchicalScores).length &&
        data.hierarchicalScores.groups
      items = !hasData ? [] : hasData
    }

    dispatch({
      type: SET_TEAM_SCORES_MONTHLY,
      data: {
        data: items,
        isLoaded: true,
      },
    })
  }
}

export function setTeamScoresByIndividuals({
  person_nid,
  limit = 25,
  offset = 0,
  ordering = "first_name",
  ...filterProps
}) {
  return async function (dispatch) {
    dispatch({
      type: SET_TEAM_SCORES_BY_INDIVIDUALS,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await fetchTeamScoresByIndividuals({
      person_nid,
      limit,
      offset,
      ordering,
      ...filterProps,
    })

    dispatch({
      type: SET_TEAM_SCORES_BY_INDIVIDUALS,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setTopPerformersCount(person_nid) {
  return async function (dispatch) {
    dispatch(
      getTopPerformers({
        person_nid,
        limit: 1,
        callback: ({ error, data }) => {
          if (!error) {
            dispatch({
              type: SET_TOP_PERFORMERS_COUNT,
              data: data && data.count,
            })
          }
        },
      }),
    )
  }
}

export function sendThankYouEmailToTopPerformers(person_nid) {
  return async function () {
    await rewardTopPerformers(person_nid)
  }
}

export function sendEnrollEmailToSecurityTeam(behaviorNames, person_nid) {
  return async function () {
    await sendEnrollEmail(behaviorNames, person_nid)
  }
}

// individuals have an overall security score of > 90 in the last month
export function getTopPerformers({
  person_nid,
  limit = 5,
  offset = 0,
  ordering = "first_name",
  filters = "&score_filter_overall=91-100",
  callback,
}) {
  return async function () {
    const { error, data } = await fetchTeamScoresByIndividuals({
      person_nid,
      limit,
      offset,
      ordering,
      filters,
    })
    if (callback) {
      callback({ error, data })
    }
  }
}

// individuals having a {behavior_name} score of < 40 in the last month
export function getLowPerformresByBehavior({
  person_nid,
  key,
  limit = 5,
  offset = 0,
  ordering = "first_name",
  filters = `&score_filter_${key}=0-39`,
  callback,
}) {
  return async function () {
    const { error, data } = await fetchTeamScoresByIndividuals({
      person_nid,
      limit,
      offset,
      ordering,
      filters,
    })
    if (callback) {
      callback({ error, data })
    }
  }
}
