import React from "react"
import PropTypes from "prop-types"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { theme } from "@elevate_security/elevate-component-library"
import { MessageWrapper, MessageSpan } from "./styles"

export function MoreFilterInfoMessage({ message }) {
  return (
    <MessageWrapper>
      <Icon name="InfoCheckOutline" size="xsm" fill={theme.icons.color.light} />
      <MessageSpan>{message}</MessageSpan>
    </MessageWrapper>
  )
}

MoreFilterInfoMessage.propTypes = {
  message: PropTypes.string.isRequired,
}
