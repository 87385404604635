import React from "react"
import { SkeletonLoader } from "@src/utils/skeleton"
import {
  DropdownItem,
  DropdownTitleWrapper,
  DropdownWrapper,
} from "@src/scenes/RiskDetectionRulesScreen/DropdownCard/styles"

function RiskDetectionRulesScreenSkeleton() {
  const dummyCardData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 8]
  return (
    <div style={{ marginTop: "25px" }}>
      {dummyCardData.map((item, index) => (
        <DropdownWrapper key={index}>
          <DropdownItem>
            <div style={{ marginTop: "8px", marginBottom: "2px" }}>
              <DropdownTitleWrapper>
                <div style={{ width: "75%" }}>
                  <SkeletonLoader />
                </div>
                <div style={{ width: "15%" }}>
                  <SkeletonLoader />
                </div>
                <div style={{ width: "8%" }}>
                  <SkeletonLoader />
                </div>
              </DropdownTitleWrapper>
            </div>
          </DropdownItem>
        </DropdownWrapper>
      ))}
    </div>
  )
}

export default RiskDetectionRulesScreenSkeleton
