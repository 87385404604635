import React, { useState, useMemo, useCallback } from "react"

import ConditionGroup from "../ConditionsGroup"
import { AddButton } from "../AddButton"
import { ActionButton } from "../ActionButton"
import ConditionTemplate from "../ConditionTemplate"
import {
  PrettyR3CriteriaLayout,
  Separator,
} from "@src/components/PrettyR3CriteriaLayout"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { RemoveButton } from "@src/components/RemoveButton"

export const Group = (props) => {
  const {
    group = { condition: "and" },
    allowedNestingLevel = 3,
    dataSource,
    onAddGroup,
    onAddCondition,
    toggleOperation,
    deleteCondition,
    deleteGroup,
    onHandleFieldChange,
    onAddCompoundCondition,
    onRemoveCompoundCondition,
    onHandleCompoundConditionFieldChange,
    fieldsNamesMap,
  } = props

  const [movingLeaf, setMovingLeaf] = useState(null)
  const [newGroupPlaceholderId, setNewGroupPlaceholderId] = useState(null)

  const countGroups = useCallback((node) => {
    const subGroupCount = (node.rules ?? []).reduce(
      (count, child) => count + countGroups(child),
      0,
    )
    return node.condition ? 1 + subGroupCount : subGroupCount
  }, [])

  const groupCount = useMemo(() => countGroups(group), [group, countGroups])

  return (
    <PrettyR3CriteriaLayout
      focusNodes={movingLeaf ? [movingLeaf.id] : []}
      styleOverrides={{
        gutterWidth: "55px",
        fitToConditionWidth: false,
        flatten: false,
        applyBorder: true,
        animate: true,
      }}
      criteria={group}
      renderLogicalOperator={(operator, id) => (
        <ConditionGroup
          id={id}
          condition={operator}
          toggleOperation={toggleOperation}
        />
      )}
      renderLeaf={(leaf, index, parent, depth) => {
        const removeCondition = function () {
          deleteCondition(parent.uuid, index)
        }

        const leafActions = []

        if (parent?.rules?.length > 1) {
          leafActions.push(
            <RemoveButton
              onClick={removeCondition}
              key="remove-button"
              disabled={movingLeaf}
            />,
          )
        }

        if (
          (groupCount > 1 || parent?.rules?.length > 1) &&
          movingLeaf?.id !== leaf.uuid
        ) {
          leafActions.push(
            <ActionButton
              key="move-button"
              onClick={() => {
                setMovingLeaf({
                  id: leaf.uuid,
                  index: index,
                  parent,
                })
                if (parent?.rules?.length > 1 && depth < allowedNestingLevel) {
                  // Add a "placeholder" new group so the user has the option of
                  // moving the leaf into a brand new group with the same parent
                  const { uuid } = onAddGroup(parent.uuid, [])
                  setNewGroupPlaceholderId(uuid)
                }
              }}
              disabled={movingLeaf}
            >
              Move
            </ActionButton>,
          )
        }

        if (movingLeaf?.id === leaf.uuid) {
          leafActions.push(
            <ActionButton
              key="cancel-move-button"
              onClick={() => {
                setMovingLeaf(null)
                deleteGroup(newGroupPlaceholderId)
                setNewGroupPlaceholderId(null)
              }}
            >
              Cancel move
            </ActionButton>,
          )
        }

        return (
          <SpaceBetween gap="8px">
            {leafActions.length > 0 && (
              <>
                <SpaceBetween
                  size="sm"
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  {leafActions.map((element) => element)}
                </SpaceBetween>
                <Separator />
              </>
            )}
            <ConditionTemplate
              key={leaf.uuid}
              id={parent.uuid}
              index={index}
              rule={leaf}
              dataSource={dataSource}
              removeCondition={removeCondition}
              onHandleFieldChange={onHandleFieldChange}
              onAddCompoundCondition={onAddCompoundCondition}
              onRemoveCompoundCondition={onRemoveCompoundCondition}
              onHandleCompoundConditionFieldChange={
                onHandleCompoundConditionFieldChange
              }
              fieldsNamesMap={fieldsNamesMap}
            />
          </SpaceBetween>
        )
      }}
      renderGroupHeader={(group, depth, parent) => {
        const addGroup = function () {
          onAddGroup(group.uuid)
        }

        const removeGroup = function () {
          deleteGroup(group.uuid)
        }

        const addCondition = function () {
          onAddCondition(group.uuid)
        }

        return (
          <SpaceBetween
            direction="row"
            size="sm"
            justify="flex-start"
            alignItems="center"
          >
            {depth + 1 < allowedNestingLevel && (
              <AddButton onClick={addGroup} disabled={movingLeaf}>
                {depth > 0 ? "New Sub-Group" : "New Group"}
              </AddButton>
            )}
            <AddButton onClick={addCondition} disabled={movingLeaf}>
              New Condition
            </AddButton>
            {group?.removable && parent.rules?.length > 1 && (
              <RemoveButton onClick={removeGroup} disabled={movingLeaf} />
            )}
            {movingLeaf &&
              !group.rules.some((rule) => rule.uuid === movingLeaf?.id) && (
                <ActionButton
                  style={{ border: "2px solid rgb(20, 197, 210)" }}
                  pulse
                  onClick={() => {
                    // Decide whether or not to remove the parent BEFORE
                    // performing any operations on the tree
                    const removeParent = movingLeaf.parent.rules.length === 1

                    const removedCondition = deleteCondition(
                      movingLeaf.parent.uuid,
                      movingLeaf.index,
                    )
                    onAddCondition(group.uuid, removedCondition)
                    if (removeParent) {
                      deleteGroup(movingLeaf.parent.uuid)
                    }
                    setMovingLeaf(null)

                    if (newGroupPlaceholderId !== group.uuid) {
                      // If the user didn't move the node into the empty
                      // placeholder group, delete the placeholder group
                      deleteGroup(newGroupPlaceholderId)
                    }
                    setNewGroupPlaceholderId(null)
                  }}
                >
                  Move here
                </ActionButton>
              )}
          </SpaceBetween>
        )
      }}
    />
  )
}
