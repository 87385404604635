import React from "react"
import { Button } from "@src/components/MimecastReskin"

import { ActionButtonWrapper } from "./ActionButton.styles"

export function ActionButton(props) {
  const { onClick, children, pulse, ...rest } = props

  return (
    <ActionButtonWrapper pulse={pulse}>
      <Button onClick={onClick} {...rest}>
        {children}
      </Button>
    </ActionButtonWrapper>
  )
}
