// Adds commas to numbers 1000 and above
export function formatWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// E.g. "Individual" --> "individuals"
export function formatStragglingEntity(stragglingEntity) {
  switch (stragglingEntity) {
    case "Individual":
      return "individuals"
    case "Department":
      return "departments"
    case "Location":
      return "locations"
    default:
      break
  }

  return ""
}

/* This utility function used to limit number inside the given boundry
e.g: this would be used to limit percentage */
export const limitNumber = (value, min = 0, max = 100) =>
  Math.max(Math.min(value, max), min)

// Returns String due to the truncateToOneDecimalPlace function
export function prepareScoreValue(value) {
  return value > 0 && value < 10
    ? truncateToOneDecimalPlace(value)
    : String(value)
}

/*
 * Be careful with .toFixed() rounding behaviour, since it can seem to round up
 * or down unexpectedly based on floating-point precision loss. That's why we
 * strip everything after the first decimal place before calling it here. See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#using_tofixed
 * Returns: String - toFixed returns string
 */
export function truncateToOneDecimalPlace(value) {
  return (Math.floor((value ?? 0) * 10) / 10).toFixed(1)
}

export function truncateNumber(value) {
  switch (true) {
    case value < 999:
      return String(value)
    case value < 10000:
      return truncateToOneDecimalPlace(value / 1000) + "K"
    case value < 1000000:
      return (value - (value % 1000)) / 1000 + "K"
    case value < 10000000:
      return truncateToOneDecimalPlace(value / 1000000) + "M"
    case value >= 10000000:
      return (value - (value % 1000000)) / 1000000 + "M"
    default:
      return String(value)
  }
}
