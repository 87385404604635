import styled from "styled-components"

export const DragoWrapper = styled.div`
  position: absolute;
  width: 95%;
  height: 35%;
  span:hover {
    cursor: ${(props) => props.showPointer};
  }
`
export const IconWrapper = styled.div`
  svg {
    width: ${(props) => props.svgSize};
    height: ${(props) => props.svgSize};
  }
`
export const DragonPosition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(props) => props.paddingTop};
`
