import React, { memo } from "react"
import PropTypes from "prop-types"

import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import { theme } from "@elevate_security/elevate-component-library"

// styles
import {
  PageHeaderContainer,
  Row,
  Column,
  DateContainer,
  SubTitle,
} from "./styles"

const { H1 } = Typography

function PageHeader(props) {
  const {
    title,
    subtitle,
    contentOnTheRightOfTitle,
    contentOnTheRightOfBreadcrumb,
    lastUpdatedTimeLabel,
    renderBanner,
  } = props

  return (
    <PageHeaderContainer>
      <Row alignItems="center" justifyContent="space-between">
        {contentOnTheRightOfBreadcrumb && (
          <Column>{contentOnTheRightOfBreadcrumb}</Column>
        )}
        <DateContainer>{lastUpdatedTimeLabel}</DateContainer>
      </Row>
      {renderBanner ? renderBanner() : null}
      <Row alignItems="center" justifyContent="space-between">
        <Column>
          {typeof title === "string" && (
            <H1
              fontWeight="heavy"
              style={{ color: theme.colors.gray.opaque[900] }}
            >
              {title}
            </H1>
          )}
          {typeof title !== "string" && title}
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </Column>
        {contentOnTheRightOfTitle && (
          <Column>{contentOnTheRightOfTitle}</Column>
        )}
      </Row>
    </PageHeaderContainer>
  )
}

PageHeader.defaultProps = {
  subtitle: null,
  contentOnTheRightOfTitle: null,
  contentOnTheRightOfBreadcrumb: null,
  lastUpdatedTimeLabel: null,
  renderBanner: null,
}
PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  breadcrumbLinks: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
  ),
  contentOnTheRightOfTitle: PropTypes.node,
  contentOnTheRightOfBreadcrumb: PropTypes.node,
  lastUpdatedTimeLabel: PropTypes.string,
  renderBanner: PropTypes.node,
}

export default memo(PageHeader)
