import React from "react"
import { CriteriaWrapper } from "./styles"
import { SkeletonLoader } from "@src/utils/skeleton"

function Criteria({ criteria, isLoading = false }) {
  return (
    <div>
      {isLoading && <SkeletonLoader height={40} />}
      {!isLoading && (
        <CriteriaWrapper>{criteria || "Add criteria"}</CriteriaWrapper>
      )}
    </div>
  )
}

export default React.memo(Criteria)
