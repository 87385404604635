import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"
import { THEME_PRIMARY } from "@src/theme"

export const FiltersWrapper = styled("div")`
  width: 100%;
`

export const FiltersTitleSection = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid ${theme.colors.border.opaque.darkOpaque};
`

export const FiltersTitle = styled("div")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #1d1e1f;
`

export const FiltersCollapseButton = styled("div")`
  width: 18px;
  height: 18px;
  cursor: pointer;
`

export const FiltersContent = styled("div")`
  padding: 0 24px;
`

export const CategoryDropdownWrapper = styled("div")`
  width: 100%;
`

export const CategoryTitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: ${(props) => (props.collapsed ? "1px" : "0px")} solid
    ${theme.colors.border.opaque.darkOpaque};
  cursor: pointer;
`

export const CategoryTitle = styled("div")`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: ${theme.colors.gray.opaque["800"]};
`

export const CategoryToggleButton = styled("div")`
  width: 18px;
  height: 18px;
  cursor: pointer;

  svg {
    margin-top: -2px;
  }
`

export const Section = styled.div`
  margin-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${theme.colors.border.opaque.darkOpaque};
  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }
`

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const AppliedFiltersWrapper = styled("div")`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.border.opaque.darkOpaque};
`

export const AppliedFiltersTitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
`

export const AppliedFiltersTitle = styled("div")`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${theme.colors.black};
`

export const AppliedFiltersClearLink = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${THEME_PRIMARY};
  cursor: pointer;
`
