import styled from "styled-components"

export const ActionsDropdownWrapper = styled.div`
  position: relative;
  width: 34px;
`

export const ActionsDropdownMenuToggleButton = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
`

export const ActionsDropdownMenu = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  width: 124px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c9cfd6;
  box-shadow: 0px 2px 8px 0px rgba(177, 184, 193, 0.5);
  z-index: 1000;
`

export const ActionsDropdownMenuItem = styled.button`
  border: 0px;
  outline: none;
  width: 120px;
  padding: 8px 16px;
  background-color: #ffffff;
  text-align: left;
  opacity: ${(props) => (props.deactivated ? "0.5" : "1")};
  ${(props) => (props.deactivated ? "pointer-events: none" : "")}

  &:hover {
    background-color: ${(props) => (props.deactivated ? "#ffffff" : "#eef0f3")};
  }
`
