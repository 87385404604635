export const SET_MANAGER_INFO = "SET_MANAGER_INFO"
export const SET_ORG_SCORE = "SET_ORG_SCORE"
export const SET_TEAM_DATA = "SET_TEAM_DATA"
export const SET_MANAGER_OVERVIEW = "SET_MANAGER_OVERVIEW"
export const SET_MANAGER_SCORES = "SET_MANAGER_SCORES"
export const SET_TEAM_TRENDS = "SET_TEAM_TRENDS"
export const SET_TEAM_SCORES_MONTHLY = "SET_TEAM_SCORES_MONTHLY"
export const SET_TEAM_SCORES_BY_INDIVIDUALS = "SET_TEAM_SCORES_BY_INDIVIDUALS"
export const SET_TOP_PERFORMERS_COUNT = "SET_TOP_PERFORMERS_COUNT"
export const SET_MANAGER_TEAM_DATA = "SET_MANAGER_TEAM_DATA"
export const SET_MANAGER_TEAM_DATA_LOADER = "SET_MANAGER_TEAM_DATA_LOADER"
export const REMOVE_CHILD_MANAGER_TEAM_DATA = "REMOVE_CHILD_MANAGER_TEAM_DATA"
export const SET_CHILD_MANAGER_TEAM_DATA = "SET_CHILD_MANAGER_TEAM_DATA"
export const SET_CHILD_LOADING = "SET_CHILD_LOADING"
export const SET_INITIAL_VALUES_MANGER = "SET_INITIAL_VALUES_MANGER"
