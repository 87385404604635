import React from "react"
import styled from "styled-components"
import { ButtonIcon } from "@elevate_security/elevate-component-library"

// Hacks to style the icon in our ButtonIcon component. We should update this
// component in our component library to support all the icon props, to
// center the icon correctly, and to style correctly when disabled.
export const RemoveButtonWrapper = styled.span<{ disabled?: boolean }>`
  display: inline-block;
  min-width: 24px;

  ${(props) => (props.disabled ? "opacity: 0.5;" : "")}
  ${(props) => (props.disabled ? "* { cursor: not-allowed; }" : "")}

  svg path {
    fill: #959da8;
  }

  button {
    padding: 0;
    font-size: 1em;
    height: auto;
    line-height: revert;
  }

  [class^="ButtonIconstyles__IconContainer"] {
    display: inline-block;
  }
`

type Props = {
  disabled?: boolean
  onClick: (e: any) => void
}

export function RemoveButton({ disabled = false, onClick }: Props) {
  return (
    <RemoveButtonWrapper disabled={disabled}>
      <ButtonIcon
        icon="Trash"
        onClick={onClick}
        theme="ghost"
        disabled={disabled}
      />
    </RemoveButtonWrapper>
  )
}
