import React, { useRef, useState } from "react"
import { createPortal } from "react-dom"
import { ButtonIcon } from "@elevate_security/elevate-component-library"
import { InsertTemplateVariablesModal } from "../../InsertTemplateVariablesModal"
import { FieldWrapper, InsertRow, NotificationInput } from "./styles"

export const InlineR3NotificationEditor = (props) => {
  const { value, onChange, schema, config, placeholder } = props
  const inputRef = useRef()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {showModal &&
        createPortal(
          <InsertTemplateVariablesModal
            options={schema[config.customValues.actionName]}
            onInsert={(data) => {
              const { selectionStart, selectionEnd } = inputRef.current
              inputRef.current.setRangeText(
                data,
                selectionStart,
                selectionEnd,
                "end",
              )
              inputRef.current.focus()
              onChange(inputRef.current.value)
              setShowModal(false)
            }}
            onClose={() => {
              setShowModal(false)
            }}
          />,
          document.body,
        )}
      <FieldWrapper>
        <InsertRow>
          <span title="Insert criteria template variables">
            <ButtonIcon
              theme="ghost"
              icon="Plus"
              onClick={() => setShowModal(true)}
            />
          </span>
        </InsertRow>
        <NotificationInput
          ref={inputRef}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          placeholder={placeholder}
        >
          {value}
        </NotificationInput>
      </FieldWrapper>
    </>
  )
}
