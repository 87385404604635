import styled from "styled-components"

export const DrawerWrapper = styled.div`
  padding: 20px;
`

export const DetectionsWrapper = styled.div`
  .table-row {
    padding: 10px 0;
    margin-top: 0;
  }
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
  }
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const BaseCell = styled.div`
  line-height: 24px;
  font-size: 14px;
  padding-right: 10px;
`

export const DefaultCell = styled(BaseCell)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
