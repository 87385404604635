import { THEME_PRIMARY } from "@src/theme"
import styled from "styled-components"

export const NotificatonContainer = styled.div`
  padding: ${(props) => (props.padding ? props.padding : "8px 0px 0px 0px")};
`
export const RadioButtonContainer = styled.div`
  & label,
  label > input {
    cursor: pointer;
  }
  & label {
    padding: 1em;
  }
  & label > svg {
    padding-right: 0.65rem;
    color: ${THEME_PRIMARY};
  }
  & label > input:disabled + svg {
    color: #c9cfd6;
  }

  & label > input:disabled {
    background-color: #c9cfd6;
    border: 1px solid #c9cfd6;
  }
  & label > input:checked {
    background-color: ${THEME_PRIMARY};
    border: 1px solid ${THEME_PRIMARY};
    accent-color: ${THEME_PRIMARY};
  }
`

export const TooltipContent = styled.div`
  ul {
    list-style: disc;
    padding-left: 20px;
  }
`

export const IconContainer = styled.span`
  margin-left: 5px;
`
