import { SCORE_MESSAGES } from "@src/constants"

export function getColorAndSizeByScore({ min, max, color, score }) {
  const _color = score >= min ? color : null

  const unities = max - min
  const total = max - score
  const diff = unities - total
  const size = !_color ? 0 : (diff / unities) * 100

  const hasCircleIndicator = score >= min && score <= max
  return {
    color: _color,
    size,
    hasCircleIndicator,
    score,
  }
}

export function getScoreMessage({ score }) {
  const item = SCORE_MESSAGES.find(
    ({ min, max }) => score >= min && score <= max,
  )
  return item && Object.keys(item).length ? item.message : null
}
