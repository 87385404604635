import React, { useContext } from "react"
import HumanRisksIndividuals, {
  AttackedIndividuals,
} from "./HumanRisksIndividuals"
import { useSelector } from "react-redux"

const HumanRisksIndividualsContext = React.createContext()

export const useHumanRisksIndividuals = () => {
  return useContext(HumanRisksIndividualsContext)
}

export default function HumanRisksIndividualsProvider() {
  const reputations = useSelector((state) => state.get("reputationsReducer"))
  const loader = reputations.apiLoader
  const individualRiskDisplayOrder = ["individuals", "departments", "locations"]
  const individualDataArray = []
  const createLink = (data) => {
    if (data.es_person_id) {
      return `/engagement/vision2/${data.es_person_id}/profile`
    }
    if (data.department) {
      return `/engagement/vision2/insights/individual/risk-analysis?filters=${encodeURIComponent(
        `department IN ["${data.department}"]`,
      )}&order_by=human_risk_score desc`
    }
    if (data.location) {
      return `/engagement/vision2/insights/individual/risk-analysis?filters=${encodeURIComponent(
        `location IN ["${data.location}"]`,
      )}&order_by=human_risk_score desc`
    }
  }

  individualRiskDisplayOrder.forEach((riskType) => {
    const temp = {
      heading: riskType,
    }
    const riskDataArray = reputations[riskType] || []
    temp.data = riskDataArray.map((data) => {
      return {
        name: data.full_name || data.department || data.location,
        hrs: data["hrs"],
        es_person_id: data.es_person_id || null,
        link: createLink(data),
      }
    })
    individualDataArray.push(temp)
  })

  return (
    <HumanRisksIndividualsContext.Provider
      value={{
        riskyIndividuals: individualDataArray,
        riskIndividualLoading: loader,
      }}
    >
      <HumanRisksIndividuals />
    </HumanRisksIndividualsContext.Provider>
  )
}

export function AttackedIndividualsProvider({ data, loading }) {
  const createLink = (data) => {
    if (data.es_person_id) {
      return `/engagement/vision2/${data.es_person_id}/profile`
    }
  }
  const riskyIndividuals = !loading
    ? {
        heading: "individuals",
        data: data.map((data) => {
          return {
            name: data.full_name,
            score: data["attack_factor"],
            es_person_id: data.es_person_id || null,
            link: createLink(data),
          }
        }),
      }
    : []

  return (
    <AttackedIndividuals
      riskyIndividuals={riskyIndividuals}
      riskIndividualLoading={loading}
    />
  )
}
