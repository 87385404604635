import styled from "styled-components"

export const Section = styled.div`
  border: 1px solid #c9cfd6;
  border-radius: 4px;
  padding: 9px 24px 56px 24px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: ${({ margin }) => margin || 0};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 50%;
  padding: ${({ padding }) => (!padding ? 0 : padding)};
`
