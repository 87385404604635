import { useState, useEffect } from "react"
import { BASE_EP_API_URL } from "@src/constants"
import fetcher from "@src/services/fetcher"
import { buildQueryString } from "@src/utils/buildQueryString"

export const useApplications = ({
  ready,
  pagination: { limit, offset },
  filters: { criticality, source, application_type, name, es_person_id },
}) => {
  const [applications, setApplications] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const queryParams = {
    limit,
    offset,
    criticality__in: criticality,
    // Source doesn't actually do anything yet since everything is the same, but just adding for completeness
    source__in: source,
    application_type__in: application_type,
    search: name,
    es_person_id,
  }

  const queryString = buildQueryString(queryParams)

  useEffect(() => {
    if (ready) {
      fetcher
        .get(
          `${BASE_EP_API_URL()}/intsync/azuread/enterprise-applications/?${queryString}`,
        )
        .then((response) => {
          setApplications(response.data)
        })
        .catch((e) => {
          setError(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [ready, queryString])

  return {
    applications,
    loading: ready && loading,
    error,
    updateCriticality({ applicationId, criticality }) {
      setApplications((prev) => {
        const indexToUpdate = prev.results.findIndex(
          (app) => app.app_id === applicationId,
        )
        if (indexToUpdate === -1) {
          return prev
        }
        return {
          ...prev,
          results: [
            ...prev.results.slice(0, indexToUpdate),
            {
              ...prev.results[indexToUpdate],
              criticality,
            },
            ...prev.results.slice(indexToUpdate + 1),
          ],
        }
      })
    },
  }
}
