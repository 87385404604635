import React, { useState, useCallback, useMemo } from "react"
import { useRequest } from "ahooks"
import { ComboBox, Checkbox } from "@elevate_security/elevate-component-library"
import { getCrowdstrikeFalconIncidentTags } from "@src/services/apis/reputation"
import { ComboBoxTitle } from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditTagIncidentsCrowdstrike/EditTagIncidentsCrowdstrike.styles"
import { SpaceBetween } from "@src/components/SpaceBetween"

export const setCrowdStrikeFalcon = (incidentTags) => {
  if (!incidentTags?.length) return []

  return incidentTags.map((item) => ({
    label: item?.tag || "",
    value: item?.id || "",
  }))
}

const LIMIT = 25

function TagsCombobox({ action, updateAction }) {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(0)
  const [totalCrowdStrike, setTotalCrowdStrike] = useState(0)

  const { data: dropdownData, loading } = useRequest(
    () =>
      getCrowdstrikeFalconIncidentTags({
        search: `${search}`,
        limit: LIMIT,
        offset: page * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        const data = response?.data || {}
        const searchData = []
        if (search) {
          searchData.unshift({ label: `Add ${search}`, value: search })
        }
        const crowdStrikeOptions = setCrowdStrikeFalcon(data?.results || [])
        if (page === 0) {
          setTotalCrowdStrike(data?.count || 0)
          return [...searchData, ...crowdStrikeOptions]
        }
        return [...dropdownData, ...crowdStrikeOptions]
      },
    },
  )

  const handleSetSearch = useCallback(
    (value) => {
      if (search === value) return
      setSearch(value)
      setPage(0)
    },
    [search],
  )

  const selectCrowdStrike = useMemo(() => {
    return (action?.tags || []).map((crowdStrike) => ({
      label: crowdStrike,
      value: crowdStrike,
    }))
  }, [action])

  return (
    <>
      <SpaceBetween direction="row" justify="flex-start" alignItems="center">
        <ComboBoxTitle>Select Tags</ComboBoxTitle>
        <ComboBox
          data={dropdownData}
          defaultValue={selectCrowdStrike}
          isLoading={loading}
          clearable={false}
          onChange={(selectedItems) => {
            const selectedLabels = selectedItems.map(
              (item) => item?.label?.replace("Add ", "") || "",
            )
            updateAction("tags", selectedLabels)
          }}
          isSearchable={true}
          isMultiOption
          checked
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{}}
          placeholder="Select tags"
          disabled={false}
          handleScrollBotttom={() => {
            if (dropdownData.length < totalCrowdStrike) {
              setPage(page + 1)
            }
          }}
          {...{ handleInputChange: handleSetSearch }}
        />
      </SpaceBetween>
      <Checkbox
        id="crowdstrike_falcon_reopen_recently_closed_incidents"
        label="Reopen recently closed incidents"
        checked={Boolean(action?.reopen_recently_closed_incidents)}
        onChange={(checked) =>
          updateAction("reopen_recently_closed_incidents", checked)
        }
      />
    </>
  )
}

export default TagsCombobox
