import React, { useState } from "react"
import {
  GroupComboBoxTitle,
  InputWrapper,
  Wrapper,
} from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditExecuteSailPointWorkflow/EditExecuteSailPointWorkflow.styles"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { NOTIFICATION_OPTIONS } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"
import { NotificationRadioButtonGroup } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/Notifications"
import {
  CLIENT_ID,
  CLIENT_SECRET,
  SAILPOINT_INPUT_TYPE,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { Input } from "@elevate_security/elevate-component-library"
import SailPointCombobox from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditExecuteSailPointWorkflow/SailPointCombobox"

export function EditExecuteSailPointWorkflow({ action, updateAction }) {
  const [showSecret, setShowSecret] = useState(false)

  return (
    <Wrapper>
      {/* Select Workflow Wrapper */}
      <SailPointCombobox
        action={action}
        updateAction={updateAction}
        showSecret={showSecret}
        setShowSecret={setShowSecret}
      />

      {/* Client Id Wrapper */}
      <SpaceBetween direction="row" justify="flex-start" alignItems="center">
        <GroupComboBoxTitle>Client Id</GroupComboBoxTitle>
        <InputWrapper>
          <Input
            type={SAILPOINT_INPUT_TYPE[showSecret]}
            value={action[CLIENT_ID] || ""}
            onChange={(value) => updateAction(CLIENT_ID, value)}
          />
        </InputWrapper>
      </SpaceBetween>

      {/* Client Secret Wrapper */}
      <SpaceBetween direction="row" justify="flex-start" alignItems="center">
        <GroupComboBoxTitle>Client Secret</GroupComboBoxTitle>
        <InputWrapper>
          <Input
            type={SAILPOINT_INPUT_TYPE[showSecret]}
            value={action[CLIENT_SECRET] || ""}
            onChange={(value) => updateAction(CLIENT_SECRET, value)}
          />
        </InputWrapper>
      </SpaceBetween>

      {/* SailPoint Matching Method */}
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
      />
    </Wrapper>
  )
}
