import { createSelector } from "reselect"
import { matchPath } from "react-router"

export const getDash = (state) => state.get("visionReducer") || {}

export const getPage = createSelector([getDash], (data) => data.get("page"))

export const getPageLevel = createSelector([getPage], (data) => {
  return data ? data.get("level") : null
})

export const getPageLevelId = createSelector([getPage], (data) => {
  return data ? data.get("levelId") : null
})

export const getPageNumber = createSelector([getDash], (data) => {
  return data ? data.get("pageNumber") : null
})

export const getCompanyLoaded = createSelector([getDash], (data) => {
  // Number of components that load for this view
  return data.get("loadedCount") >= 7
})

export const getDepartmentLoaded = createSelector([getDash], (data) => {
  // Number of components that load for this view
  return data.get("loadedCount") >= 7
})

export const getTeamLoaded = createSelector([getDash], (data) => {
  // Number of components that load for this view
  return data.get("loadedCount") >= 5
})

export const getData = createSelector([getDash], (data) => data.get("data"))

export const getSorting = createSelector([getDash], (data) =>
  data.get("sorting").toJS(),
)

export const getWhoAMI = createSelector([getDash], (data) => data.get("me"))

// Selectors for Vision

// Helper selector: Grabs company-level data
export const getBaseCompany = createSelector([getData], (data) =>
  data.get("company"),
)

// Analyze Risks - Monthly Score - Historical Trend
export const getHierarchicalScores = createSelector(
  [getBaseCompany],
  (data) => {
    return data.get("hierarchy").toJS().hierarchicalScores &&
      data.get("hierarchy").toJS().hierarchicalScores.groups
      ? data.get("hierarchy").toJS().hierarchicalScores.groups
      : null
  },
)

// Analyze Risks - Monthly Distribution - Distribution
export const getScoresDistributions = createSelector(
  [getBaseCompany],
  (data) => {
    return data.get("scoresDistributions").toJS().groups
      ? data.get("scoresDistributions").toJS().groups
      : {}
  },
)

// Analyze Risks - Behavior Type panels
export const getBehaviorTypes = createSelector([getData], (data) => {
  const behaviorArray = []
  if (data.get("behaviors")) {
    const newData = data.get("behaviors").toJS()
    Object.keys(newData).forEach((key) => {
      behaviorArray.push(newData[key].category)
    })
  }
  return data ? behaviorArray : []
})

// Current clicked behavior tab ibndex (only used in selectors)
export const getCurrentBehaviorIndex = createSelector([getDash], (data) => {
  return data.get("tab") ? data.get("tab") : null
})

// Current clicked behavior tab name (only used in selectors)
export const getCurrentBehavior = createSelector(
  [getBehaviorTypes, getCurrentBehaviorIndex],
  (keys, keysIndex) => {
    return keys && keysIndex ? `score_${keys[keysIndex - 1]}` : "score_overall"
  },
)

// SUMMARY SECTION

// Summary - Top overall score
export const getOverallScore = createSelector(
  [getHierarchicalScores],
  (data) => {
    return data &&
      data[data.length - 1] &&
      data[data.length - 1][Object.keys(data[data.length - 1])[0]]
      ? data[data.length - 1][Object.keys(data[data.length - 1])[0]].groups
          .overall.scores.score
      : null
  },
)

// Summary - Top five departments
export const getTopDepartments = createSelector([getBaseCompany], (data) => {
  return data.get("topDepartments") ? data.get("topDepartments").toJS() : [{}]
})

// Summary - Risk distribution chart
export const getRiskDistributions = createSelector([getBaseCompany], (data) => {
  return data.get("riskDistributions")
    ? data.get("riskDistributions").toJS()
    : []
})

export const getBehaviorMap = createSelector([getBaseCompany], (data) => {
  return data.get("behaviorMap") ? data.get("behaviorMap").toJS() : [{}]
})

// Selectors to dig into company/department/team hierarchy with level and levelId

const getPropsId = (_, ownProps) => {
  const matchTeam = matchPath(ownProps.history.location.pathname, {
    path: "/engagement/vision2/insights/team/:teamId",
    isExact: true,
  })
  const matchDept = matchPath(ownProps.history.location.pathname, {
    path: "/engagement/vision2/insights/department/:deptId",
    isExact: true,
  })

  const deptId = matchDept !== null ? matchDept.params.deptId : undefined
  const teamId = matchTeam !== null ? matchTeam.params.teamId : undefined

  if (teamId) {
    return {
      level: "team",
      id: teamId,
    }
  }
  if (deptId) {
    return {
      level: "department",
      id: deptId,
    }
  }
  return {
    level: "company",
    id: null,
  }
}

export const getCurrentId = createSelector([getPropsId], (id) => {
  return id ? id.id : null
})

export const getTopPerformersId = createSelector(
  [getData, getPropsId, getCurrentBehavior],
  (data, id, behavior) => {
    if (id.level !== "company") {
      return data &&
        data.get(id.level) &&
        data.get(id.level).get("topPerformers") &&
        data.get(id.level).get("topPerformers").get(id.id) &&
        data.get(id.level).get("topPerformers").get(id.id).get(behavior) &&
        data.get(id.level).get("topPerformers").get(id.id).get(behavior).toJS()
        ? data
            .get(id.level)
            .get("topPerformers")
            .get(id.id)
            .get(behavior)
            .toJS()
        : []
    }
    return data &&
      data.get(id.level).get("topPerformers") &&
      data.get(id.level).get("topPerformers").get(behavior)
      ? data.get(id.level).get("topPerformers").get(behavior).toJS()
      : []
  },
)

export const getStragglersId = createSelector(
  [getData, getPropsId, getCurrentBehavior],
  (data, id, behavior) => {
    if (id.level !== "company") {
      return data &&
        data.get(id.level) &&
        data.get(id.level).get("stragglers") &&
        data.get(id.level).get("stragglers").get(id.id) &&
        data.get(id.level).get("stragglers").get(id.id).get(behavior) &&
        data.get(id.level).get("stragglers").get(id.id).get(behavior).toJS()
        ? data.get(id.level).get("stragglers").get(id.id).get(behavior).toJS()
        : []
    }
    return data &&
      data.get(id.level).get("stragglers") &&
      data.get(id.level).get("stragglers").get(behavior)
      ? data.get(id.level).get("stragglers").get(behavior).toJS()
      : []
  },
)

export const getBehaviorMapId = createSelector(
  [getData, getPropsId],
  (data, id) => {
    return data &&
      data.get(id.level) &&
      data.get(id.level).get("subBehaviorMap") &&
      data.get(id.level).get("subBehaviorMap").get(id.id) &&
      data.get(id.level).get("subBehaviorMap").get(id.id).toJS()
      ? data.get(id.level).get("subBehaviorMap").get(id.id).toJS()
      : [{}]
  },
)

export const getCurrentBehaviorMap = createSelector(
  [getData, getBaseCompany, getPageLevel, getPageLevelId],
  (data, base, page, id) => {
    if (page === "company") {
      return base.get("behaviorMap") ? base.get("behaviorMap").toJS() : [{}]
    }
    return data &&
      data.get(page) &&
      data.get(page).get("subBehaviorMap") &&
      data.get(page).get("subBehaviorMap").get(id) &&
      data.get(page).get("subBehaviorMap").get(id).toJS()
      ? data.get(page).get("subBehaviorMap").get(id).toJS()
      : [{}]
  },
)

export const getAllScoresId = createSelector(
  [getData, getPropsId],
  (data, id) => {
    const scoresList = []
    let scores
    if (
      data &&
      data.get(id.level) &&
      data.get(id.level).get("hierarchy") &&
      data.get(id.level).get("hierarchy").get(id.id)
    ) {
      const { groups } = data
        .get(id.level)
        .get("hierarchy")
        .get(id.id)
        .toJS().hierarchicalScores

      scores =
        groups && groups.length && groups[groups.length - 1]
          ? groups[groups.length - 1][Object.keys(groups[groups.length - 1])[0]]
              .groups
          : {}
      Object.keys(scores).forEach((key) => {
        if (key !== "overall") {
          scoresList.push({ behavior: key, score: scores[key].scores.score })
        }
      })
    }
    return scoresList
  },
)

export const getOverallScoreId = createSelector(
  [getData, getPropsId],
  (data, id) => {
    let hierarchicalById
    if (
      data &&
      data.get(id.level) &&
      data.get(id.level).get("hierarchy") &&
      data.get(id.level).get("hierarchy").get(id.id)
    ) {
      hierarchicalById = data.get(id.level).get("hierarchy").get(id.id).toJS()
        .hierarchicalScores.groups
    }
    return hierarchicalById &&
      hierarchicalById[hierarchicalById.length - 1] &&
      hierarchicalById[hierarchicalById.length - 1][
        Object.keys(hierarchicalById[hierarchicalById.length - 1])[0]
      ]
      ? hierarchicalById[hierarchicalById.length - 1][
          Object.keys(hierarchicalById[hierarchicalById.length - 1])[0]
        ].groups.overall.scores.score
      : null
  },
)

export const getRiskDistributionsId = createSelector(
  [getData, getPropsId],
  (data, id) => {
    let riskDistributionById
    if (
      data &&
      data.get(id.level) &&
      data.get(id.level).get("riskDistributions") &&
      data.get(id.level).get("riskDistributions").get(id.id)
    ) {
      riskDistributionById = data
        .get(id.level)
        .get("riskDistributions")
        .get(id.id)
        .toJS()
    }
    return riskDistributionById || []
  },
)

export const getScoresDistributionsId = createSelector(
  [getData, getPropsId],
  (data, id) => {
    let scoreDistributionById
    if (
      data &&
      data.get(id.level) &&
      data.get(id.level).get("scoresDistributions") &&
      data.get(id.level).get("scoresDistributions").get(id.id)
    ) {
      scoreDistributionById = data
        .get(id.level)
        .get("scoresDistributions")
        .get(id.id)
        .toJS().groups
    }
    return scoreDistributionById || {}
  },
)

export const getHierarchicalScoresId = createSelector(
  [getData, getPropsId],
  (data, id) => {
    let hierarchicalById
    if (
      data &&
      data.get(id.level) &&
      data.get(id.level).get("hierarchy") &&
      data.get(id.level).get("hierarchy").get(id.id)
    ) {
      hierarchicalById = data.get(id.level).get("hierarchy").get(id.id).toJS()
        .hierarchicalScores.groups
    }
    return hierarchicalById || []
  },
)

// Breadcrumbs and department head selectors
export const getBreadcrumbs = createSelector([getData], (data) =>
  data.get("breadcrumbs").toJS(),
)

export const getFullPathBreadcrumbs = createSelector(
  [getBreadcrumbs],
  (data) => {
    const paths = []
    if (data && data.length) {
      data
        .slice()
        .reverse()
        .forEach((item, i) => {
          if (i === 0) {
            paths.push({
              id: item.person_nid,
              name: item.department_name,
              level: "department",
              directHome: false,
            })
          } else {
            paths.push({
              id: item.person_nid,
              name: `${item.first_name} ${item.last_name}`,
              level: "team",
              directHome: false,
            })
          }
        })
    }
    return paths
  },
)

export const getShortPathBreadcrumbs = createSelector(
  [getBreadcrumbs],
  (data) => {
    const paths = []
    if (data && data.length) {
      paths.push({
        id: data[0].person_nid,
        name: `${data[0].first_name} ${data[0].last_name}'s Team`,
        level: "team",
        directHome: false,
      })
    }
    return paths
  },
)

export const getDepartment = createSelector([getBreadcrumbs], (data) => {
  return data && data[0] ? data[0].department_name : null
})

export const getDepartmentHead = createSelector([getBreadcrumbs], (data) => {
  return data && data[0] ? `${data[0].first_name} ${data[0].last_name}` : null
})
