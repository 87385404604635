import React, { useState } from "react"

import {
  CategoryDropdownWrapper,
  CategoryTitleWrapper,
  CategoryTitle,
  CategoryToggleButton,
} from "./styles"
import { Icon, theme } from "@elevate_security/elevate-component-library"

export const CategoryDropdown = (props) => {
  const { label, children, defaultValue = false } = props
  const [collapsed, setCollapsed] = useState(defaultValue)

  const toggle = () => setCollapsed(!collapsed)

  return (
    <CategoryDropdownWrapper>
      <CategoryTitleWrapper collapsed={collapsed} onClick={toggle}>
        <CategoryTitle>{label}</CategoryTitle>
        <CategoryToggleButton>
          <Icon
            name={!collapsed ? "CaretUp" : "CaretDown"}
            fill={theme.colors.gray.opaque[500]}
          />
        </CategoryToggleButton>
      </CategoryTitleWrapper>
      {!collapsed && <div>{children}</div>}
    </CategoryDropdownWrapper>
  )
}
