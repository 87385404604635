import React, { useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { useParams, useHistory } from "react-router-dom"
import { ColumnsChooser } from "../../../ColumnsChooser"
import useRiskAnalysisURLParam from "../../../useRiskAnalysisURLParam"
import { PerformBulkActions } from "../PerformBulkActions"
import FiltersUtils from "./FiltersUtils"
import { RISK_DATA_TYPES, RISK_ANALYSIS_INSIGHTS_URL } from "@src/constants"
import {
  LIST_FILTERS,
  sortNameMap,
} from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import { useIndividualRiskAnalysisState } from "@src/scenes/SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import { Drawer, Icon } from "@elevate_security/elevate-component-library"
import { ComboBox, Button } from "@src/components/MimecastReskin"
import { getOfferingPlan } from "@src/services/redux/company/selectors"
import AdvancedFilters from "../AdvancedFilters"
import { useDispatch, useSelector } from "react-redux"
import { convertToQueryString } from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/utils/convertToQueryString"
import Criteria from "@src/scenes/RiskResponseRuleCreateScreen/components/Criteria/Criteria"
import { setAdvancedFilters } from "@src/services/redux/analysisIndividuals/actions"
import { Filters } from "../Filters"
import { CreateStaticGroupModal } from "../CreateStaticGroupModal"
import { AddToStaticGroupModal } from "../AddToStaticGroupModal"
import { FilterWarningModal } from "../FilterWarningModal"
import { useDebounceFn } from "ahooks"
import useGetCriteriaFieldsNames from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/utils/useGetCriteriaFieldsNames"
import { SpacingX } from "@src/components/SpacingX"
import { SpacingY } from "@src/components/SpacingY"
import { SpaceBetween } from "@src/components/SpaceBetween"
import ExportData from "@src/components/ExportData"
import { SearchInputWithFilters } from "@src/components/SearchInputWithFilters"
import { IconWrapper, CriteriaStringWrapper } from "./FiltersToolbar.styles"
import { getGlobalConfig } from "@src/globalConfig"

const FiltersToolbar = ({
  riskTypeOverride,
  showTableMode = true,
  showAdvancedFilters = true,
  showColumnChooser = true,
  showBulkActions = true,
  optionsOverride,
  labelOverrides,
  columnState,
  onSimpleFilterChange = () => {},
  onToggleTruncate = () => {},
}) => {
  const { filters, clearUrl, order_by } = useRiskAnalysisURLParam({
    riskTypeOverride,
  })
  const { riskType: riskTypeParam, actionType } = useParams()
  const { riskTableCount, advanced_filters } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const riskType = riskTypeOverride || riskTypeParam
  const riskData = RISK_DATA_TYPES[riskType]
  const dispatch = useDispatch()
  const { actionsNames, userFacingBehaviorTypes } =
    useIndividualRiskAnalysisState()
  const [showFilterWarningModal, setShowFilterWarningModal] = useState(false)
  const [onUpdateFilterType, setOnUpdateFilterType] = useState(() => {})
  const [isAdvancedFiltersOpen, setAdvancedFiltersOpen] = useState(false)

  const isIndividualRiskType = riskType === "individual"

  const searchInputPlaceholder = riskData.searchInputPlaceholder || "Search"

  const {
    AND_CONSTANT,
    IN_CONSTANT,
    IN_SEPARATOR,
    RISK_PREFIX,
    ACTION_PREFIX,
    HUMAN_RISK_MAP,
    addFilter,
    removeFilter,
  } = FiltersUtils(riskData.columnKeyPrefix, {
    riskTypeOverride,
  })
  useEffect(() => {
    // Check if filters does not have search filter clear search field
    const filtersArray = filters?.split(AND_CONSTANT) || []
    const searchFilterArr =
      filtersArray.filter((filter) => filter.includes("searchbar")) || []
    const searchByValue =
      searchFilterArr.length > 0
        ? searchFilterArr[0].split(" ").slice(2).join(" ")
        : ""
    if (!searchByValue) {
      setSearchFilter("")
    } else {
      setSearchFilter(searchByValue)
    }
  }, [filters])

  const [searchFilter, setSearchFilter] = useState("")
  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = (flag) => {
    if (typeof flag === "undefined") {
      setOpenDrawer(!openDrawer)
    } else {
      setOpenDrawer(flag)
    }
  }

  const risksFilters = useMemo(() => {
    const filtersArray = filters ? filters?.split(AND_CONSTANT) : []
    const splitOnSpace = (filter) => filter.split(" ")

    const risksFiltersArr =
      filtersArray
        .filter((filter) => filter.includes("human_risk_score"))
        .map(splitOnSpace) || []

    const inFiltersArr =
      filtersArray
        .filter((filter) => filter.includes(IN_CONSTANT))
        .map((filter) => filter.split(IN_CONSTANT)) || []

    const othersFiltersArr =
      filtersArray
        .filter(
          (filter) =>
            !filter.includes(IN_CONSTANT) &&
            !filter.includes(" ILIKE ") &&
            !filter.includes("human_risk_score") &&
            !filter.includes("searchbar"),
        )
        .map(splitOnSpace) || []

    const inValues = inFiltersArr.reduce((acc, [key, values]) => {
      const isListFilter = LIST_FILTERS.includes(key)
      let valuesArray = values
      if (isListFilter) {
        try {
          valuesArray = JSON.parse(values)
        } catch (ex) {
          valuesArray = values.split(IN_SEPARATOR)
        }
      } else {
        valuesArray = values.split(IN_SEPARATOR)
      }
      return [
        ...acc,
        ...valuesArray.map((value) => ({
          key: key,
          value: value,
          operator: "in",
        })),
      ]
    }, [])

    const sortFunction = (a, b) => a[1].localeCompare(b[1])

    const groupRisks = risksFiltersArr?.reduce((acc, filter) => {
      let riskName = filter[0].replace(RISK_PREFIX, "")

      if (riskName === HUMAN_RISK_MAP["label"]) {
        riskName = HUMAN_RISK_MAP["key"]
      }

      const riskValue = acc[riskName] || []
      const greatThanAndLessThanValues = [...riskValue, filter]
      greatThanAndLessThanValues.sort(sortFunction) // this sorts array in place

      return {
        ...acc,
        [riskName]: greatThanAndLessThanValues,
      }
    }, {})

    const groupOthers = othersFiltersArr?.reduce((acc, filter) => {
      let actionName = filter[0].replace(ACTION_PREFIX, "")

      if (actionName === HUMAN_RISK_MAP["label"]) {
        actionName = HUMAN_RISK_MAP["key"]
      }

      const actionValue = acc[actionName] || []
      const greatThanAndLessThanValues = [...actionValue, filter]
      greatThanAndLessThanValues.sort(sortFunction) // this sorts array in place

      return {
        ...acc,
        [actionName]: greatThanAndLessThanValues,
      }
    }, {})

    const formatToSliderValues = ([key, greatThanAndLessThanValues]) => {
      const value = `${greatThanAndLessThanValues[0][2]}-${greatThanAndLessThanValues[1][2]}`
      return { key, value, operator: "between" }
    }

    const formatToSliderActionValues = ([key, greatThanAndLessThanValues]) => {
      const value = `${greatThanAndLessThanValues[0][2]}-${greatThanAndLessThanValues[1][2]}`
      return { key, value, operator: "between" }
    }
    const sliderValues = filters
      ? [
          ...Object.entries(groupRisks).map(formatToSliderValues),
          ...Object.entries(groupOthers).map(formatToSliderActionValues),
        ]
      : []

    return sliderValues.concat(inValues)
  }, [filters])

  useEffect(() => {
    onSimpleFilterChange(risksFilters)
  }, [risksFilters, onSimpleFilterChange])

  const clearAdvancedFilters = () => {
    dispatch(setAdvancedFilters(null))
  }

  const handleSelection = (filter) => {
    if (filter && filter.checked) {
      // Advance-Filter will remove when More-Filters applied
      if (advanced_filters) {
        clearAdvancedFilters()
      }
      addFilter(filter)
    } else if (filter && !filter.checked) {
      removeFilter(filter)
    }
  }

  const {
    run: updateSearchFilterParam,
    flush: immediatelyUpdateSearchFilterParam,
  } = useDebounceFn(
    (value) => {
      value.length > 0
        ? addFilter({
            key: "search",
            label: "Search",
            value,
          })
        : removeFilter({
            key: "search",
            label: "Search",
            value,
          })
    },
    { wait: 500 },
  )

  const handleSearch = (value) => {
    setSearchFilter(value)
    if (advanced_filters) {
      clearAdvancedFilters()
      immediatelyUpdateSearchFilterParam(value)
    } else {
      updateSearchFilterParam(value)
    }
  }

  const hideRiskDetectionRule = getOfferingPlan() === "assess"

  const [showCreateStaticGroupModal, setShowCreateStaticGroupModal] =
    useState(false)
  const [showAddToStaticGroupModal, setShowAddToStaticGroupModal] =
    useState(false)

  // To fetch groups names again selected group ids in criteria
  const { fieldsNamesMap, isLoadingFieldsNames } = useGetCriteriaFieldsNames(
    advanced_filters ? [advanced_filters] : [],
  )

  const queryString = useMemo(() => {
    if (advanced_filters) {
      return convertToQueryString(advanced_filters, {
        groupsMap: fieldsNamesMap,
        annotate: true,
      })
    } else {
      return ""
    }
  }, [advanced_filters, fieldsNamesMap])

  const confirmFilterSwitch = (predicate, action) => {
    if (predicate()) {
      setShowFilterWarningModal(true)
      setOnUpdateFilterType(() => () => {
        setShowFilterWarningModal(false)
        action()
      })
    } else {
      action()
    }
  }

  const onEditAdvancedFilters = () => {
    confirmFilterSwitch(
      () => filters,
      () => setAdvancedFiltersOpen(true),
    )
  }

  const history = useHistory()

  const tableModeOptions = useMemo(() => {
    return Object.values(RISK_DATA_TYPES)
      .filter((riskType) => riskType.name)
      .map(({ name }) => ({ label: name, value: name }))
  }, [])

  return (
    <>
      <SpacingY>
        <SearchInputWithFilters
          value={searchFilter}
          filtersCount={risksFilters?.length ?? 0}
          placeholder={searchInputPlaceholder}
          handleSearch={(value) => {
            confirmFilterSwitch(
              () => advanced_filters,
              () => handleSearch(value),
            )
          }}
          onToggleFilters={() => {
            confirmFilterSwitch(
              () => advanced_filters,
              () => toggleDrawer(),
            )
          }}
          isClearable
          clearSearch={() => {
            handleSearch("")
          }}
        />
        {showAdvancedFilters && advanced_filters && (
          <SpaceBetween gap="0px" alignItems="center" direction="row">
            <CriteriaStringWrapper onClick={onEditAdvancedFilters}>
              <Criteria
                criteria={queryString}
                isLoading={isLoadingFieldsNames}
              />
            </CriteriaStringWrapper>
            <div style={{ flexShrink: "0" }}>
              <Button theme="ghost" onClick={clearAdvancedFilters}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconWrapper>
                    <Icon name="Close" stroke="#959DA8" />
                  </IconWrapper>
                  <span>Clear Filters</span>
                </div>
              </Button>
            </div>
          </SpaceBetween>
        )}
        {riskData.showTableOptions && (
          <SpaceBetween
            direction="row"
            style={{
              backgroundColor: "rgb(245, 245, 245)",
              border: "1px solid #e5e8ed",
              borderRadius: "4px",
              padding: "7px 10px",
            }}
          >
            <SpacingX>
              {showBulkActions &&
                !hideRiskDetectionRule &&
                isIndividualRiskType && (
                  <PerformBulkActions
                    onCreateStaticGroup={() =>
                      setShowCreateStaticGroupModal(true)
                    }
                    onAddToExistingStaticGroup={() =>
                      setShowAddToStaticGroupModal(true)
                    }
                  />
                )}
            </SpacingX>
            <SpacingX>
              {showAdvancedFilters && isIndividualRiskType && (
                <AdvancedFilters
                  isOpen={isAdvancedFiltersOpen}
                  setIsOpen={setAdvancedFiltersOpen}
                  onClick={onEditAdvancedFilters}
                />
              )}
              {showTableMode && (
                <SpacingX alignItems="center" style={{ padding: "0px 25px" }}>
                  <div>Table mode</div>
                  <div>
                    <ComboBox
                      menuZIndex="1000"
                      value={tableModeOptions.find(
                        ({ value }) => value === riskData.name,
                      )}
                      data={tableModeOptions}
                      onChange={(e) => {
                        clearAdvancedFilters()
                        // Clear simple filters
                        history.push({
                          pathname:
                            RISK_ANALYSIS_INSIGHTS_URL +
                            Object.keys(RISK_DATA_TYPES).find(
                              (key) => RISK_DATA_TYPES[key].name === e.value,
                            ) +
                            "/" +
                            actionType,
                        })
                      }}
                    />
                  </div>
                </SpacingX>
              )}
              {showColumnChooser && (
                <>
                  {getGlobalConfig("ENABLE_RAP_DATA_EXPORT") && (
                    <ExportData
                      type={riskType}
                      filters={filters}
                      order_by={order_by}
                      total={riskTableCount}
                      advancedFilters={advanced_filters}
                    />
                  )}
                  <ColumnsChooser
                    {...columnState}
                    onToggleTruncate={onToggleTruncate}
                  />
                </>
              )}
            </SpacingX>
          </SpaceBetween>
        )}
      </SpacingY>
      {showCreateStaticGroupModal &&
        createPortal(
          <CreateStaticGroupModal
            isOpen={showCreateStaticGroupModal}
            onClose={() => setShowCreateStaticGroupModal(false)}
            filters={filters}
          />,
          document.body,
        )}
      {showAddToStaticGroupModal &&
        createPortal(
          <AddToStaticGroupModal
            isOpen={showAddToStaticGroupModal}
            onClose={() => setShowAddToStaticGroupModal(false)}
            filters={filters}
          />,
          document.body,
        )}
      {createPortal(
        <Drawer
          isOpen={openDrawer}
          direction="right"
          width="480px"
          toggleDrawer={toggleDrawer}
        >
          <Filters
            isOpen={openDrawer}
            filters={risksFilters}
            riskOptions={[
              HUMAN_RISK_MAP["label"],
              ...(actionsNames
                .map((type) => sortNameMap[type] || type)
                .filter((behaviour) => behaviour !== "overall") || []),
            ]}
            onSelection={handleSelection}
            enabledBehaviorTypes={userFacingBehaviorTypes || []}
            toggleDrawer={toggleDrawer}
            clearUrl={clearUrl}
            appliedFilter={filters}
            riskTypeOverride={riskTypeOverride}
            optionsOverride={optionsOverride}
            labelOverrides={labelOverrides}
          />
        </Drawer>,
        document.body,
      )}
      {showFilterWarningModal &&
        createPortal(
          <FilterWarningModal
            isOpen={showFilterWarningModal}
            onClose={() => setShowFilterWarningModal(false)}
            onUpdateFilterType={onUpdateFilterType}
          />,
          document.body,
        )}
    </>
  )
}

export default FiltersToolbar
