export const COMBOBOX_GROUPS = "COMBOBOX_GROUPS"
export const COMBOBOX_GROUPS_DATA_PAGE_NUMB = "COMBOBOX_GROUPS_DATA_PAGE_NUMB"
export const COMBOBOX_GROUPS_LOADER = "COMBOBOX_GROUPS_LOADER"
export const RULE_NAME_RULE_INFO = "RULE_NAME_RULE_INFO"
export const RULE_DESCRIPTION_RULE_INFO = "RULE_DESCRIPTION_RULE_INFO"
export const RULE_SPECIFY_GROUP_RULE_INFO = "RULE_SPECIFY_GROUP_RULE_INFO"
export const EXCLUDING_INDIVIDUALS_RULE_INFO = "EXCLUDING_INDIVIDUALS_RULE_INFO"
export const RULE_LOADING_COUNT_RULE_INFO = "RULE_LOADING_COUNT_RULE_INFO"
export const COMBOBOX_GROUPS_COUNTS = "COMBOBOX_GROUPS_COUNTS"
export const SET_QUERY_RULE = "SET_QUERY_RULE"
export const RESET_QUERY_RULE = "RESET_QUERY_RULE"
export const SET_QUERY_BUILDER_SCHEMA = "SET_QUERY_BUILDER_SCHEMA"
export const SET_USE_MAILTRAP = "SET_USE_MAILTRAP"
export const SET_IS_RULE_NAME_UPDATED = "SET_IS_RULE_NAME_UPDATED"
