import {
  AUDIT_LOG_CLEAR_FILTER,
  AUDIT_LOG_CLEAR_FILTERS,
  AUDIT_LOG_SET_FILTER_BY,
  AUDIT_LOG_SET_FILTERS,
  AUDIT_LOG_SET_RESULTS,
  CLEAR,
  EVENTS_LOG_CLEAR_FILTER,
  EVENTS_LOG_CLEAR_FILTERS,
  EVENTS_LOG_SET_FILTER_BY,
  EVENTS_LOG_SET_FILTERS,
  EVENTS_LOG_SET_RESULTS,
  INDIVIDUAL_DATA,
  REPUTATION_SCORE_HISTORICAL,
  SET_DECISION_AREAS,
  SET_EVENT_COUNT,
  SET_SYSTEMS,
  TOGGLE_AUTO_SCROLL,
  SET_SELECTED_KEY_FACTOR,
} from "@src/services/redux/IndividualProfile/types"

/** action creators */
export const clear = () => ({ type: CLEAR })

export const setSystems = (systems) => ({ type: SET_SYSTEMS, systems })

export const setEventCount = (eventCount) => ({
  type: SET_EVENT_COUNT,
  eventCount,
})

export const setDecisionAreas = (decision_areas) => ({
  type: SET_DECISION_AREAS,
  decision_areas,
})

export const setReputationScoreHistorical = (reputationScoreHistorical) => ({
  type: REPUTATION_SCORE_HISTORICAL,
  reputationScoreHistorical,
})

export const setIndividualData = (individualData) => ({
  type: INDIVIDUAL_DATA,
  individualData,
})
/** EVENTS_LOG */
export const clearFilters = () => ({ type: EVENTS_LOG_CLEAR_FILTERS })

export const clearFilter = (filter, value) => ({
  type: EVENTS_LOG_CLEAR_FILTER,
  filter,
  value,
})

export const setResults = (results) => ({
  type: EVENTS_LOG_SET_RESULTS,
  results,
})

export const setFilters = (filters) => ({
  type: EVENTS_LOG_SET_FILTERS,
  filters,
})

export const setFilterBy = (filterBy) => ({
  type: EVENTS_LOG_SET_FILTER_BY,
  filterBy,
})

export const setAutoScrollToggle = (filterBy) => ({
  type: TOGGLE_AUTO_SCROLL,
  filterBy,
})

/** AUDIT_LOG */
export const auditLogClearFilters = () => ({ type: AUDIT_LOG_CLEAR_FILTERS })

export const auditLogClearFilter = (filter, value) => ({
  type: AUDIT_LOG_CLEAR_FILTER,
  filter,
  value,
})

export const auditLogSetResults = (results) => ({
  type: AUDIT_LOG_SET_RESULTS,
  results,
})

export const auditLogSetFilters = (filters) => ({
  type: AUDIT_LOG_SET_FILTERS,
  filters,
})

export const auditLogSetFilterBy = (filterBy) => ({
  type: AUDIT_LOG_SET_FILTER_BY,
  filterBy,
})

export const checkIfShouldHide = (filters, initialFilterValues) => {
  return JSON.stringify(filters) === JSON.stringify(initialFilterValues)
}

/** helpers */
export const handleFilter = (key) => (state, action, field) => {
  if (action.filters[key] === undefined || action.filters[key] === null) {
    return {}
  }
  if (state[field].filters[key].includes(action.filters[key])) {
    return {
      [key]: state[field].filters[key].filter(
        (value) => value !== action.filters[key],
      ),
    }
  } else {
    return {
      [key]: [...state[field].filters[key], action.filters[key]],
    }
  }
}

export const setSelectedKeyFactor = (keyFactor) => ({
  type: SET_SELECTED_KEY_FACTOR,
  keyFactor,
})
