import styled from "styled-components"
import { SpaceBetween } from "../SpaceBetween"

export const SearchInputWithFiltersWrapper = styled(SpaceBetween)`
  & [class^="SearchInputstyles"] {
    max-width: 100%;
    input {
      width: 100%;
    }
  }
  .icons-right {
    & [class^="ToggleFilterButton"] {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`

export const ToggleFilterButton = styled.button`
  padding: 0px;
  outline: none;
  background: transparent;
  border: 0px;

  &::focus {
    outline: none;
  }
`

export const AppliedFiltersCount = styled(SpaceBetween)`
  height: 24px;
  padding: 4px 8px;
  border: 1px solid #dde1e7;
  border-radius: 4px;
  background: #ffffff;
  box-sizing: border-box;
`

export const CrossButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 0px;
  border: 0px;
  border-radius: 2px;
  background: #f5f6f8;
  outline: none;
  cursor: pointer;

  svg {
    width: 12px !important;
    height: 12px !important;
    margin-top: 0px !important;
  }
`

export const ClearSearchButton = styled.button`
  border: 0px;
  outline: none;
  padding: 0px;

  &::focus {
    outline: none;
  }

  svg {
    width: 15px !important;
    height: 15px !important;
    margin-top: 0px !important;
  }
`
