import React from "react"

import { ActionGroups } from "./ActionGroups"
import { APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

import { ActionConfig } from "./actionConfigTypes"
import { CiscoDuoConditionalAccessPolicy } from "../../CiscoDuoConditionalAccessPolicy"

type Payload = {
  action_type: typeof APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY
  matching_method: "delta" | "snapshot"
  policy_id: string
  application_ids: string[]
  policy: {
    new_user_policy: string
    authentication_policy: string
    require_security_patches_duo_mobile: boolean
    require_trusted_endpoints: boolean
    block_compromised_endpoints: boolean
    enable_endpoints_options_for_mobile: boolean
    require_trusted_endpoints_for_mobile: boolean
    authentication_methods: {
      duo_push: boolean
      always_require_duo_push: boolean
      duo_push_digits: number | null
      duo_mobile_passcodes: boolean
      sms_passcodes: boolean
      resend_sms_passcodes: boolean
      phone_callback: boolean
      hardware_tokens: boolean
      web_authn: boolean
      web_authn_security_keys: boolean
      web_authn_touch_id: boolean
    }
  }
}

const config: ActionConfig<Payload> = {
  label: "Cisco Duo Conditional Access Policy - Update policy",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY,
    matching_method: "delta",
    policy_id: "",
    application_ids: [],
    policy: {
      new_user_policy: "RE",
      authentication_policy: "E2",
      require_security_patches_duo_mobile: false,
      require_trusted_endpoints: false,
      block_compromised_endpoints: false,
      enable_endpoints_options_for_mobile: false,
      require_trusted_endpoints_for_mobile: false,
      authentication_methods: {
        duo_push: false,
        always_require_duo_push: false,
        duo_push_digits: null,
        duo_mobile_passcodes: false,
        sms_passcodes: false,
        resend_sms_passcodes: false,
        phone_callback: false,
        hardware_tokens: false,
        web_authn: false,
        web_authn_security_keys: false,
        web_authn_touch_id: false,
      },
    },
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("cisco_duo", "conditional-access-policy"),
  render: ({ details, outboundServices, updateAction }) => {
    return (
      <CiscoDuoConditionalAccessPolicy
        showToggle={false}
        servicesToggle={outboundServices}
        ruleActions={new Set([APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY])}
        ciscoCap={{
          ciscoCapState: details,
          setCiscoCapState: (state: Payload) => {
            const { action_type, ...rest } = state
            Object.entries(rest).forEach(([field, value]) => {
              if (details[field as keyof Payload] !== value) {
                updateAction(field as keyof Payload, value)
              }
            })
          },
        }}
      />
    )
  },
}

export default config
