import styled from "styled-components"

export const TrendingHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
`

const SharedAxesLabelStyles = styled.span`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;

  &:before {
    position: relative;
    content: "";
    display: inline;
    left: -8px;
    top: 0;
    width: 12px;
    height: 12px;
    border-radius: 5px;
  }
`

export const TrendingAxesLabelsContainer = styled.span`
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  opacity: 0.87;
  margin: 8px 0 0;
`

export const TrendingXLabelContainer = styled(SharedAxesLabelStyles)`
  padding: 15px 0 8px;
  &:before {
    background-color: ${(props) => props.color};
  }
`
export const TrendingYLabelContainer = styled(SharedAxesLabelStyles)`
  position: absolute;
  top: -180px;
  left: -90px;
  width: 200px;
  transform: rotate(-90deg);

  ${({ isClickable }) => (isClickable ? "cursor: pointer;" : "")}
  ${({ isHidingData }) => (isHidingData ? "opacity: 0.5;" : "")}

  &:before {
    background-color: ${(props) => props.color};
  }
`
export const NoDataContainer = styled.div`
  position: relative;
  display: flex;
  height: 250px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  color: #666;
`

export const NoDataTitle = styled.span`
  color: #000000;
  margin: 0 8px;
  font-style: italic;
`
