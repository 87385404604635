import React, { useEffect, useState } from "react"
import GroupedCombobox from "@src/components/GroupedCombobox/GroupedCombobox"

function OperatorsCombobox(props) {
  const {
    isFilterable = false,
    options,
    placeholder = "Select operator",
    value = { label: "", value: "" },
    onChange,
  } = props
  const [filterOptions, setFilterOptions] = useState([])
  const [filteredData, setFilteredData] = useState([...options])

  useEffect(() => {
    if (options.length) {
      setFilteredData(options)
    }
  }, [options])

  useEffect(() => {
    if (isFilterable) {
      const list = []
      options.forEach((option) => {
        if (option?.options) {
          list.push({
            label: option.label,
            value: option.value,
            checked: true,
          })
        }
      })
      setFilterOptions(list)
    }
  }, [options, isFilterable])

  useEffect(() => {
    if (!isFilterable) {
      return
    }
    const copyFilterOptions = [...filterOptions]
    let map = copyFilterOptions.reduce((compositeValue, option) => {
      compositeValue[option.value] = option.checked
      return compositeValue
    }, {})

    const anyoneActive = Object.keys(map)?.some((key) => map[key])
    if (!anyoneActive) {
      const inn = copyFilterOptions?.findIndex(
        (item) => item.value === "absolute",
      )
      if (inn > -1) {
        copyFilterOptions[inn].checked = true
        setFilterOptions(copyFilterOptions)
      }
    }

    map = copyFilterOptions.reduce((compositeValue, option) => {
      compositeValue[option.value] = option.checked
      return compositeValue
    }, {})

    const data = options.filter((option) => map[option.value])
    setFilteredData(data)
  }, [filterOptions, isFilterable])

  const handleFilterChange = (checked, key) => {
    const copyFilterOptions = [...filterOptions]
    const targetIndex = filterOptions.findIndex((item) => item.value === key)
    if (targetIndex > -1) {
      copyFilterOptions[targetIndex] = {
        ...copyFilterOptions[targetIndex],
        checked,
      }
    }

    setFilterOptions(copyFilterOptions)
  }

  return (
    <GroupedCombobox
      isSearchable={false}
      isFilterable={isFilterable}
      hasNesting={false}
      options={filteredData}
      filterOptions={filterOptions}
      onFilterChange={handleFilterChange}
      onSearch={() => {}}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  )
}

export default React.memo(OperatorsCombobox)
