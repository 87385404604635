import { SCORE_COLORS } from "@src/constants"

export function getGradeByScore(score) {
  score = isNaN(parseInt(score)) ? -1 : score
  const item = SCORE_COLORS.find(({ min, max }) => score >= min && score <= max)
  return item && Object.keys(item).length
    ? { color: item.color, name: item.name, value: score, textColor: "#fff" }
    : {
        color: "#FFF",
        name: "No score available",
        value: score,
        textColor: "#000",
      }
}

export function getColorByScore({ score }) {
  const item = SCORE_COLORS.find(({ min, max }) => score >= min && score <= max)
  return item && Object.keys(item).length ? item.color : "#34383C"
}

export function getIconAndColorByScoreDiff(diff) {
  if (diff > 0) return { color: "#D54242", icon: "TrendUp", diff: `+${diff}` }
  if (diff < 0) {
    return { color: "#15785f", icon: "TrendDown", diff: `-${diff * -1}` }
  }
  return { color: "#114E92", icon: "TrendNeutral", diff }
}

export function getBehaviorsIconAndColorByScore(diff) {
  if (diff > 0) return { color: "#15785f", icon: "TrendUp", diff: `+${diff}` }
  if (diff < 0) {
    return { color: "#D54242", icon: "TrendDown", diff: `-${diff * -1}` }
  }
  return { color: "#114E92", icon: "TrendNeutral", diff }
}

const colors = {
  error: "#c73434",
}

export default colors
