import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { setQueryRule } from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import { QueryBuilder } from ".."
import { QueryBuilderWrapper } from "./QueryBuilderWrapper.styles"
import CriteriaResults from "../CriteriaResults"
import { validateCriteria } from "../../ConfigureInputCriteriaPage/utils"

/*
Reason for Memo:

When used externally with another application (Ex: ui-elevateplatform),
it created a feedback loop that caused this render to happen twice.

In other words, we want the additional render for the external application,
but not this application.
*/
const CriteriaQueryBuilder = React.memo(
  ({ defaultQueryRule, onQueryRulesChange, title }) => {
    const dispatch = useDispatch()
    const { queryBuilderSchema, loadingSchema, queryRules } = useSelector(
      (getReducers) => getReducers.get("ruleInfoReducer"),
    )

    const onQueryChange = useCallback(
      (jsonQuery) => dispatch(setQueryRule(structuredClone(jsonQuery))),
      [dispatch],
    )

    useEffect(() => {
      if (defaultQueryRule) {
        dispatch(setQueryRule(structuredClone(defaultQueryRule)))
      }
      // Run on mount only
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
      if (onQueryRulesChange) {
        onQueryRulesChange(queryRules, validateCriteria(queryRules))
      }
    }, [onQueryRulesChange, queryRules])

    return (
      <QueryBuilderWrapper>
        <QueryBuilder
          title={title}
          showInlineQuery={true}
          dataSource={queryBuilderSchema}
          queryRules={queryRules}
          loading={loadingSchema}
          onQueryChange={onQueryChange}
        />
        <CriteriaResults queryRules={queryRules} />
      </QueryBuilderWrapper>
    )
  },
)

CriteriaQueryBuilder.propTypes = {
  defaultQueryRule: PropTypes.arrayOf(PropTypes.shape({})),
  onQueryRulesChange: PropTypes.func,
  title: PropTypes.string,
}

CriteriaQueryBuilder.defaultProps = {
  title: "Criteria",
}

export default CriteriaQueryBuilder
