import React, { useState } from "react"
import {
  Toggle,
  ComboBox,
  RadioButton,
  CustomSelect,
} from "@elevate_security/elevate-component-library"

import {
  CiscoDuoConditionalAccessPolicyWrapper,
  ActionRow,
  ToggleWrapper,
  ToggleWrapperText,
  Label,
  CiscoDuoConditionalAccessPolicyControls,
} from "./styles"
import { IndividualsWrapper } from "../styles"
import {
  APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY,
  CAP_WARNING_MESSAGE,
  CISCO_DUO,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { addRemoveRuleAction } from "../RiskRuleOutcome/utils"
import { useDispatch } from "react-redux"
import { useRequest } from "ahooks"
import {
  getCiscoDuoApplications,
  getCiscoDuoApplicationPolicies,
} from "@src/services/apis/reputation"
import { NotificationRadioButtonGroup } from "../RiskRuleOutcome/Notifications"
import WarningModal from "./WarningModal"
import { NOTIFICATION_OPTIONS } from "../RiskRuleOutcome/constants"
import Checkbox from "@src/components/Checkbox"
import { isEmpty } from "@src/utils/string"

const LIMIT = 25

const newUserPolicyOptions = [
  {
    label: "Require enrollment",
    value: "RE",
  },
  {
    label: "Allow access without 2FA",
    value: "AW",
  },
  {
    label: "Deny access",
    value: "DA",
  },
]

const authenticationPolicyOptions = [
  {
    label: "Enforce 2FA",
    value: "E2",
  },
  {
    label: "Bypass 2FA",
    value: "B2",
  },
  {
    label: "Deny access",
    value: "DA",
  },
]

const mobileSecurityPatchesOptions = [
  {
    label: "Require up-to-date security patches for Duo Mobile",
    value: true,
  },
  {
    label: "Don't require up-to-date security patches for Duo Mobile",
    value: false,
  },
]

const trustedEndpointsOptions = [
  {
    label: "Allow all endpoints",
    value: false,
  },
  {
    label: "Require endpoints to be trusted",
    value: true,
  },
]

const trustedMobileEndpointsOptions = [
  {
    label: "Allow all mobile endpoints",
    value: false,
  },
  {
    label: "Require mobile endpoints to be trusted",
    value: true,
  },
]

export const CiscoDuoConditionalAccessPolicy = ({
  servicesToggle,
  ruleActions,
  ciscoCap,
  showToggle = true,
}) => {
  const dispatch = useDispatch()
  const { ciscoCapState, setCiscoCapState } = ciscoCap
  const [applicationsPage, setApplicationsPage] = useState(0)
  const [applicationsSearch, setApplicationsSearch] = useState("")
  const [totalApplications, setTotalApplications] = useState(0)

  const [policiesPage, setPoliciesPage] = useState(0)
  const [policiesSearch, setPoliciesSearch] = useState("")
  const [totalPolicies, setTotalPolicies] = useState(0)

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [capNextValue, setCapNextValue] = useState({})

  const { data: applications, loading: loadingApplications } = useRequest(
    () =>
      getCiscoDuoApplications({
        search: `${applicationsSearch}`,
        limit: LIMIT,
        offset: applicationsPage * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [applicationsSearch, applicationsPage],
      formatResult: (response) => {
        if (applicationsPage === 0) {
          setTotalApplications(response?.data?.count || 0)
          return (
            response?.data?.results?.map((item) => ({
              label: item.name,
              value: item.application_id,
            })) || []
          )
        } else {
          return [
            ...applications,
            ...(response?.data?.results?.map((item) => ({
              label: item.name,
              value: item.application_id,
            })) || []),
          ]
        }
      },
    },
  )

  const { data: policies, loading: loadingPolicies } = useRequest(
    () =>
      getCiscoDuoApplicationPolicies({
        search: `${policiesSearch}`,
        limit: LIMIT,
        offset: policiesPage * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [policiesSearch, policiesPage],
      formatResult: (response) => {
        if (policiesPage === 0) {
          setTotalPolicies(response?.data?.count || 0)
          return (
            response?.data?.results?.map((item) => ({
              ...item,
              label: item.name,
              value: item.policy_id,
            })) || []
          )
        } else {
          return [
            ...policies,
            ...(response?.data?.results?.map((item) => ({
              ...item,
              label: item.name,
              value: item.policy_id,
            })) || []),
          ]
        }
      },
    },
  )

  const handleSetSearchApplications = (value) => {
    if (applicationsSearch === value) return
    setApplicationsSearch(value)
    setApplicationsPage(0)
  }

  const handleSetSearchPolicies = (value) => {
    if (policiesSearch === value) return
    setPoliciesSearch(value)
    setPoliciesPage(0)
  }

  const CiscoDuoCAPActionIsEnabled = ruleActions?.has(
    APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY,
  )

  const isIntegrationDataSynced = servicesToggle?.find(
    (service) => service.vendor_code === CISCO_DUO,
  )?.last_received_date

  const onChangeApplications = (items) => {
    setCiscoCapState({
      ...ciscoCapState,
      application_ids: items.map((item) => item.value),
    })
  }

  const onChangePolicies = (item) => {
    setCiscoCapState({
      ...ciscoCapState,
      policy_id: item.value,
      policy: {
        ...item,
      },
    })
  }

  const onCloseModal = () => {
    setCapNextValue({})
    setIsWarningModalOpen(false)
  }

  const onConfirmModal = () => {
    const selectedOption = Object.keys(capNextValue || {})?.[0]
    if (selectedOption === "applications") {
      onChangeApplications(capNextValue.applications)
    } else {
      onChangePolicies(capNextValue?.policy || {})
    }
    onCloseModal()
  }

  return (
    <CiscoDuoConditionalAccessPolicyWrapper>
      {showToggle && (
        <ActionRow>
          <ToggleWrapper>
            <Toggle
              checked={CiscoDuoCAPActionIsEnabled}
              onChange={({ checked }) => {
                if (checked) {
                  addRemoveRuleAction({
                    ruleActions,
                    type: APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY,
                    dispatch,
                  })
                } else {
                  addRemoveRuleAction({
                    ruleActions,
                    type: APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY,
                    dispatch,
                    isAdd: false,
                  })
                }
              }}
            />
            <ToggleWrapperText>
              Cisco Duo Conditional Access Policy - Update Policies
            </ToggleWrapperText>
          </ToggleWrapper>
        </ActionRow>
      )}
      {CiscoDuoCAPActionIsEnabled && (
        <>
          {isIntegrationDataSynced ? (
            <CiscoDuoConditionalAccessPolicyControls>
              <div className="row">
                <Label>Select policy</Label>
                <ComboBox
                  data={policies}
                  value={policies?.filter(
                    (policy) => ciscoCapState?.policy_id === policy.value,
                  )}
                  onChange={(item) => {
                    setIsWarningModalOpen(true)
                    setCapNextValue({ policy: item })
                  }}
                  isLoading={loadingPolicies}
                  clearable={false}
                  isSearchable={true}
                  isMultiOption={false}
                  hideSelectedOptions={true}
                  components={{}}
                  placeholder="Select policy"
                  handleScrollBotttom={() => {
                    if (policies.length < totalPolicies) {
                      setPoliciesPage(policiesPage + 1)
                    }
                  }}
                  handleInputChange={(value) => handleSetSearchPolicies(value)}
                />
              </div>

              <div className="row">
                <Label>Select applications</Label>
                <ComboBox
                  data={applications}
                  value={applications?.filter((application) =>
                    ciscoCapState?.application_ids?.includes(application.value),
                  )}
                  onChange={(items) => {
                    setIsWarningModalOpen(true)
                    setCapNextValue({ applications: items })
                  }}
                  isLoading={loadingApplications}
                  clearable={false}
                  isSearchable={true}
                  isMultiOption
                  checked
                  hideSelectedOptions={true}
                  components={{}}
                  placeholder="Select applications"
                  handleScrollBotttom={() => {
                    if (applications.length < totalApplications) {
                      setApplicationsPage(applicationsPage + 1)
                    }
                  }}
                  handleInputChange={(value) =>
                    handleSetSearchApplications(value)
                  }
                />
              </div>
              <WarningModal
                isOpen={isWarningModalOpen}
                title="Update Cisco Duo CAP"
                message={CAP_WARNING_MESSAGE}
                onConfirm={() => {
                  onConfirmModal()
                }}
                onClose={onCloseModal}
              />
              {!isEmpty(ciscoCapState?.policy_id) &&
                ciscoCapState?.application_ids?.length > 0 && (
                  <div>
                    <div className="row flex-start">
                      <Label>New User Policy</Label>
                      <RadioButton
                        options={newUserPolicyOptions}
                        name="new_user_policy"
                        radioSelected={
                          ciscoCapState?.policy?.new_user_policy ?? "RE"
                        }
                        onChange={(val) => {
                          setCiscoCapState({
                            ...ciscoCapState,
                            policy: {
                              ...ciscoCapState.policy,
                              new_user_policy: val,
                            },
                          })
                        }}
                      />
                    </div>

                    <div className="row flex-start">
                      <Label>Authentication policy</Label>
                      <RadioButton
                        options={authenticationPolicyOptions}
                        name="authentication_policy"
                        radioSelected={
                          ciscoCapState?.policy?.authentication_policy ?? "E2"
                        }
                        onChange={(val) => {
                          setCiscoCapState({
                            ...ciscoCapState,
                            policy: {
                              ...ciscoCapState.policy,
                              authentication_policy: val,
                            },
                          })
                        }}
                      />
                    </div>

                    <div className="row flex-start">
                      <Label>Authentication methods</Label>
                      <div>
                        <div className="row m-b">
                          <Checkbox
                            id="duo_push-checkbox"
                            label="Duo Push"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.duo_push
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.authentication_methods,
                                    duo_push: checked,
                                    always_require_duo_push: checked,
                                    duo_push_digits: null,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                        <div className="row m-l-2 m-b">
                          <Checkbox
                            id="always_require_duo_push-checkbox"
                            label="Always require a verified Duo Push with"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.always_require_duo_push
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    duo_push: checked
                                      ? true
                                      : ciscoCapState?.policy
                                          ?.authentication_methods.duo_push,
                                    always_require_duo_push: checked,
                                    duo_push_digits: null,
                                  },
                                },
                              })
                            }}
                          />
                          <div>
                            <CustomSelect
                              id="cisco-duo-customSelect"
                              placeholder="Select a option"
                              disabled={
                                !ciscoCapState?.policy?.authentication_methods
                                  ?.duo_push ||
                                !ciscoCapState?.policy?.authentication_methods
                                  ?.always_require_duo_push
                              }
                              defaultValue={{
                                value:
                                  ciscoCapState?.policy?.authentication_methods
                                    ?.duo_push_digits,
                              }}
                              onChange={(selected) => {
                                setCiscoCapState({
                                  ...ciscoCapState,
                                  policy: {
                                    ...ciscoCapState.policy,
                                    authentication_methods: {
                                      ...ciscoCapState?.policy
                                        ?.authentication_methods,
                                      duo_push_digits: selected.value,
                                    },
                                  },
                                })
                              }}
                              options={[3, 4, 5, 6]}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <Checkbox
                            id="duo_mobile_passcodes-checkbox"
                            label="Duo Mobile passcodes"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.duo_mobile_passcodes
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    duo_mobile_passcodes: checked,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                        <div className="row m-b">
                          <Checkbox
                            id="sms_passcodes-checkbox"
                            label="SMS passcodes"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.sms_passcodes
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    sms_passcodes: checked,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                        <div className="row m-l-2">
                          <Checkbox
                            id="resend_sms_passcodes-checkbox"
                            label="Automatically send a new passcode up to 3 times if delivery fails"
                            disabled={
                              !ciscoCapState?.policy?.authentication_methods
                                ?.sms_passcodes
                            }
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.resend_sms_passcodes
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    resend_sms_passcodes: checked,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                        <div className="row m-b">
                          <Checkbox
                            id="web_authn-checkbox"
                            label="WebAuthn"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.web_authn
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    web_authn: checked,
                                    web_authn_security_keys: checked,
                                    web_authn_touch_id: checked,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                        <div className="row m-l-2">
                          <div>
                            <Checkbox
                              id="web_authn_security_keys-checkbox"
                              label="Security Keys (WebAuthn)"
                              checked={
                                ciscoCapState?.policy?.authentication_methods
                                  ?.web_authn_security_keys
                              }
                              onChange={(checked) => {
                                setCiscoCapState({
                                  ...ciscoCapState,
                                  policy: {
                                    ...ciscoCapState.policy,
                                    authentication_methods: {
                                      ...ciscoCapState?.policy
                                        ?.authentication_methods,
                                      web_authn_security_keys: checked,
                                    },
                                  },
                                })
                              }}
                            />
                            <Checkbox
                              id="web_authn_touch_id-checkbox"
                              label="Touch ID"
                              checked={
                                ciscoCapState?.policy?.authentication_methods
                                  ?.web_authn_touch_id
                              }
                              onChange={(checked) => {
                                setCiscoCapState({
                                  ...ciscoCapState,
                                  policy: {
                                    ...ciscoCapState.policy,
                                    authentication_methods: {
                                      ...ciscoCapState?.policy
                                        ?.authentication_methods,
                                      web_authn_touch_id: checked,
                                    },
                                  },
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <Checkbox
                            id="phone_callback-checkbox"
                            label="Phone callback"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.phone_callback
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    phone_callback: checked,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                        <div className="row">
                          <Checkbox
                            id="hardware_tokens-checkbox"
                            label="Hardware tokens"
                            checked={
                              ciscoCapState?.policy?.authentication_methods
                                ?.hardware_tokens
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  authentication_methods: {
                                    ...ciscoCapState?.policy
                                      ?.authentication_methods,
                                    hardware_tokens: checked,
                                  },
                                },
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row flex-start">
                      <Label>Duo Mobile app</Label>
                      <RadioButton
                        options={mobileSecurityPatchesOptions}
                        name="require_security_patches_duo_mobile"
                        radioSelected={
                          ciscoCapState?.policy
                            ?.require_security_patches_duo_mobile
                        }
                        onChange={(val) => {
                          setCiscoCapState({
                            ...ciscoCapState,
                            policy: {
                              ...ciscoCapState.policy,
                              require_security_patches_duo_mobile: val,
                            },
                          })
                        }}
                      />
                    </div>

                    <div className="row flex-start">
                      <Label>Trusted endpoints</Label>
                      <div>
                        <RadioButton
                          options={trustedEndpointsOptions}
                          name="require_trusted_endpoints"
                          radioSelected={
                            ciscoCapState?.policy?.require_trusted_endpoints
                          }
                          onChange={(val) => {
                            setCiscoCapState({
                              ...ciscoCapState,
                              policy: {
                                ...ciscoCapState.policy,
                                require_trusted_endpoints: val,
                              },
                            })
                          }}
                        />
                        <div className="row m-l-2 m-b">
                          <Checkbox
                            id="passwordChange-checkbox"
                            label="Allow Cisco Secure Endpoint to block compromised endpoints"
                            checked={
                              ciscoCapState?.policy?.block_compromised_endpoints
                            }
                            onChange={(checked) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  block_compromised_endpoints: checked,
                                },
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row flex-start">
                      <Label>Advanced options for mobile endpoints</Label>
                      <div>
                        <Checkbox
                          id="enable_endpoints_options_for_mobile-checkbox"
                          label="Allow Cisco Secure Endpoint to block compromised endpoints"
                          checked={
                            ciscoCapState?.policy
                              ?.enable_endpoints_options_for_mobile
                          }
                          onChange={(checked) => {
                            setCiscoCapState({
                              ...ciscoCapState,
                              policy: {
                                ...ciscoCapState.policy,
                                enable_endpoints_options_for_mobile: checked,
                              },
                            })
                          }}
                        />

                        <div className="row flex-start m-l-2">
                          <RadioButton
                            options={trustedMobileEndpointsOptions}
                            name="require_trusted_endpoints_for_mobile"
                            disabled={
                              !ciscoCapState?.policy
                                ?.enable_endpoints_options_for_mobile
                            }
                            radioSelected={
                              ciscoCapState?.policy
                                ?.require_trusted_endpoints_for_mobile
                            }
                            onChange={(val) => {
                              setCiscoCapState({
                                ...ciscoCapState,
                                policy: {
                                  ...ciscoCapState.policy,
                                  require_trusted_endpoints_for_mobile: val,
                                },
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <NotificationRadioButtonGroup
                title="Matching Method:"
                titleColor="700"
                titleFontWeight="bold"
                selected={ciscoCapState?.matching_method}
                onChange={(e) =>
                  setCiscoCapState({
                    ...ciscoCapState,
                    matching_method: e?.target?.value,
                  })
                }
                options={NOTIFICATION_OPTIONS}
                name="cisco-duo-conditional-access-policy-notification-method"
              />
            </CiscoDuoConditionalAccessPolicyControls>
          ) : (
            <IndividualsWrapper>
              <div>
                <h6>No options!</h6>
                <p>Integration process is in progress</p>
              </div>
            </IndividualsWrapper>
          )}
        </>
      )}
    </CiscoDuoConditionalAccessPolicyWrapper>
  )
}
