import styled from "styled-components"

export const NotificationMessageContainer = styled.div`
  padding-right: ${({ paddingRight = 0 }) =>
    paddingRight > 0 ? `${paddingRight}px` : paddingRight};
  padding-left: ${({ paddingLeft = 0 }) =>
    paddingLeft > 0 ? `${paddingLeft}px` : paddingLeft};
  padding-top: ${({ paddingTop = 0 }) =>
    paddingTop > 0 ? `${paddingTop}px` : paddingTop};
  padding-bottom: ${({ paddingBottom = 0 }) =>
    paddingBottom > 0 ? `${paddingBottom}px` : paddingBottom};
`

export const NotificatonContainer = styled.div`
  display: flex;
  align-items: center;
  & span {
    margin: unset;
    padding-top: unset;
    color: #0c7d84;
    font-weight: 400;
  }
`
export const IconContainer = styled.div`
  svg {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    color: #0c7d84;
  }
`
