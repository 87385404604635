import React, { useEffect, useMemo } from "react"
import TeamHistoricalTrend from "@src/scenes/ManagerDashboard/scenes/Session/components/TeamHistoricalTrend"
import TeamScoresByIndividual from "@src/scenes/ManagerDashboard/scenes/Session/components/TeamScoresByIndividual"
import TeamSecurityScore from "@src/scenes/ManagerDashboard/scenes/Session/components/TeamSecurityScore"
import { DropdownContentWrapper } from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import DropdownContent from "@src/components/DropdownContent"
import {
  setOrgScore,
  setTeamData,
  setTeamScoresByIndividuals,
  setTeamScoresMonthly,
  setTopPerformersCount,
} from "@src/scenes/ManagerDashboard/services/redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getManagerInfo } from "@src/scenes/ManagerDashboard/services/redux/selectors"

function ManagerDashboardV1() {
  const dispatch = useDispatch()
  const manager = useSelector(getManagerInfo)
  const { person_nid } = useParams()
  const questions = useMemo(() => {
    return [
      {
        title: "How is your team doing over time?",
        content: <TeamHistoricalTrend />,
      },
      {
        title: "How is your team doing by Individual?",
        content: <TeamScoresByIndividual />,
      },
    ]
  }, [])

  useEffect(() => {
    if (manager && manager.id) {
      dispatch(setTeamScoresMonthly(person_nid))
      dispatch(setOrgScore(person_nid))
      dispatch(setTeamData(person_nid))
      dispatch(
        setTeamScoresByIndividuals({
          person_nid,
          limit: 10,
          offset: 0,
        }),
      )
      dispatch(setTopPerformersCount(person_nid))
    }
  }, [manager])

  return (
    <>
      <TeamSecurityScore />
      <DropdownContentWrapper>
        <DropdownContent items={questions} />
      </DropdownContentWrapper>
    </>
  )
}

export default ManagerDashboardV1
