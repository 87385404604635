import React from "react"

import { ActionGroups } from "./ActionGroups"
import { UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

import { ActionConfig } from "./actionConfigTypes"
import { AzureConditionalAccessPolicy } from "../../AzureConditionalAccessPolicy"

type Payload = {
  action_type: typeof UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY
  matching_method: "delta" | "snapshot"
  update_info: {
    policy_ids: any[]
    apps_include_meta: any[]
    apps_exclude_meta: any[]
    policy_properties: {
      grantControls: {
        operator: string
        builtInControls: string[]
        customAuthenticationFactors: any[]
        termsOfUse: any[]
      }
    }
  }
}

type DEPRECATED_UpdatePayload = Omit<Payload, "update_info"> &
  Payload["update_info"]

const config: ActionConfig<Payload> = {
  label: "Azure AD Conditional Access Policy - Update policies",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY,
    matching_method: "delta",
    update_info: {
      policy_ids: [],
      apps_include_meta: [],
      apps_exclude_meta: [],
      policy_properties: {
        grantControls: {
          operator: "and",
          builtInControls: ["block"],
          customAuthenticationFactors: [],
          termsOfUse: [],
        },
      },
    },
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("azuread", "conditional-access-policy"),
  render: ({ details, outboundServices, updateAction }) => {
    const { update_info, ...rest } = details
    const azureCapState: DEPRECATED_UpdatePayload = {
      ...rest,
      ...update_info,
    }
    return (
      <AzureConditionalAccessPolicy
        showToggle={false}
        servicesToggle={outboundServices}
        ruleActions={new Set([UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY])}
        azureCap={{
          azureCapState,
          setAzureCapState: (
            state: (
              oldState: DEPRECATED_UpdatePayload,
            ) => DEPRECATED_UpdatePayload | DEPRECATED_UpdatePayload,
          ) => {
            const newState =
              typeof state === "function" ? state(azureCapState) : state
            const { action_type, matching_method, ...update_info } = newState
            if (details.matching_method !== matching_method) {
              updateAction("matching_method", matching_method)
            }
            updateAction("update_info", update_info)
          },
        }}
      />
    )
  },
}

export default config
