import React from "react"

import { stringToBase64 } from "@src/utils/string"
import { EditEmailNotification } from "../actionRenderComponents/EditEmailNotification"
import { ManagersEmailTemplate } from "../../RiskRuleOutcome/EmailTemplates"
import { SEND_EMAIL_NOTIFICATION_TO_MANAGERS } from "../../RiskRuleOutcome/constants"
import { ActionGroups } from "./ActionGroups"

import { ActionConfig } from "./actionConfigTypes"
import { validateEmailActionPayload } from "./validators/validateEmailActionPayload"

type Payload = {
  action_type: typeof SEND_EMAIL_NOTIFICATION_TO_MANAGERS
  matching_method: "snapshot" | "delta"
  subject: string
  message: string
  send_persons_as_attachment: boolean
}

const config: ActionConfig<Payload> = {
  label: "Managers of individuals who meet the criteria",
  group: ActionGroups.EMAIL,
  defaultPayload: {
    action_type: SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
    matching_method: "delta",
    subject: stringToBase64(
      "{{manager.manager_first_name}}, a member of your team has taken risky security actions",
    ),
    message: stringToBase64(ManagersEmailTemplate),
    send_persons_as_attachment: true,
  },
  validate: (payload) => {
    return validateEmailActionPayload(payload)
  },
  isEnabled: () => true,
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <EditEmailNotification
        allowPersonListAttachment
        templateAttributes={templateAttributes}
        action={details}
        onChange={updateAction}
      />
    )
  },
}

export default config
