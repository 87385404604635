import React from "react"
import {
  Typography,
  Icon,
  theme,
} from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { PAGE_HEADER as modalPages } from "./components/RiskRuleOutcome/constants"
import { R3HeaderBreadcrumb, R3HeaderBreadcrumbsWrapper } from "./styles"

export const RiskResponseRulesHeader = ({
  pageNum,
  onSaveAndContinue,
  editFlag,
  isSaveAndContinueDisabled,
}) => {
  return (
    <div style={{ padding: "20px" }}>
      <SpaceBetween size="sm">
        <Typography.H1 fontWeight="heavy" color="700">
          Risk Response Rule Setup
        </Typography.H1>
        <R3HeaderBreadcrumbsWrapper>
          {modalPages.map(({ label, pNum }, index) => {
            const enableButton =
              (editFlag || index < pageNum) && !isSaveAndContinueDisabled
            return (
              <React.Fragment key={`${pNum}-${index}`}>
                <R3HeaderBreadcrumb
                  disabled={!enableButton}
                  onClick={() => {
                    onSaveAndContinue({ nextPage: pNum })
                  }}
                  current={pageNum === pNum}
                >
                  {label}
                </R3HeaderBreadcrumb>
                {index < modalPages.length - 1 && (
                  <Icon
                    name="CaretRight"
                    size="sm"
                    fill={theme.colors.gray.opaque[500]}
                  />
                )}
              </React.Fragment>
            )
          })}
        </R3HeaderBreadcrumbsWrapper>
      </SpaceBetween>
    </div>
  )
}
