import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"
import styled from "styled-components"

export const Wrapper = styled.div`
  .checkbox-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
    > div {
      width: 50%;
      margin-top: 9px;
      display: flex;
      label {
        font-size: 14px;
        line-height: 24px;
        color: #565d66;
        margin-bottom: 6px;
      }
    }
  }
  .show-more-link {
    margin-top: 11px;
    font-size: 13px;
    color: ${THEME_PRIMARY};
    &:hover {
      color: ${THEME_HOVER};
    }
    cursor: pointer;
    display: inline-block;
    svg {
      width: 8px;
      height: 8px;
      margin-left: 8px;
      margin-bottom: 2px;
    }
  }
`
