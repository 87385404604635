import { useState, useEffect } from "react"
import { darklyGetFlag, darkly } from "@src/utils/darkly"

export function useSubscribeToLaunchDarklyFlag(key, client = darkly()) {
  const [flag, setFlag] = useState(darklyGetFlag(key))

  useEffect(() => {
    const listener = (value) => {
      setFlag(value)
    }
    client.on(`change:${key}`, listener)
    return () => {
      client.off(`change:${key}`, listener)
    }
  }, [key, client])

  return flag
}
