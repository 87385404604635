import { buildQueryString } from "@src/utils/buildQueryString"
import fetcher from "@src/services/fetcher"
import { useRequest } from "ahooks"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"

type Individual = {
  first_name: string
  last_name: string
  mc_person_id: string
  email: string
}

export function useWatchlistIndividuals({
  watchlistId,
  pagination,
}: {
  watchlistId: string
  pagination: { pageSize: number; current: number }
}): {
  individuals: {
    results: Individual[]
    total_elements: number
  }
  loading: boolean
  error?: Error
} {
  const params = buildQueryString({
    page_size: pagination.pageSize,
    page_number: pagination.current,
  })
  const {
    data: individuals,
    loading,
    error,
  } = useRequest(
    () =>
      fetcher
        .get(
          `${BASES_REPUTATIONS_API_URL}/watchlists/${watchlistId}/individuals?${params}`,
        )
        .then((res) => res.data),
    {
      refreshDeps: [pagination.pageSize, pagination.current],
    },
  )

  return {
    individuals,
    loading,
    error,
  }
}
