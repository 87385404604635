import styled from "styled-components"

export const OverlayWrapper = styled.div`
  position: relative;
`

export const ChildrenWrapper = styled.div`
  pointer-events: none;
  opacity: 0.5;
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  zindex: 1;
  display: flex;
  justify-content: end;
  padding: 10px;
  cursor: not-allowed;
  opacity: ${(props) => props.opacity};
`
