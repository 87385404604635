/* eslint-disable indent */
import React from "react"
import PropTypes from "prop-types"

import Breadcrumb from "./Breadcrumb"
import MedalBronze from "./MedalBronze"
import MedalGold from "./MedalGold"
import MedalSilver from "./MedalSilver"
import SortArrows from "./SortArrows"
import PersonCircle from "./PersonCircle"
import ErrorIcon from "./Error"
import SmallError from "./SmallError"
import CaretLeft from "./CaretLeft"
import CaretRight from "./CaretRight"
import Sliders from "./Sliders"

const Icon = (props) => {
  const { name } = props
  switch (name) {
    case "breadcrumb":
      return <Breadcrumb {...props} />
    case "medalBronze":
      return <MedalBronze {...props} />
    case "medalGold":
      return <MedalGold {...props} />
    case "medalSilver":
      return <MedalSilver {...props} />
    case "sortArrows":
      return <SortArrows {...props} />
    case "personCircle":
      return <PersonCircle {...props} />
    case "error":
      return <ErrorIcon {...props} />
    case "smallError":
      return <SmallError {...props} />
    case "caretLeft":
      return <CaretLeft {...props} />
    case "caretRight":
      return <CaretRight {...props} />
    case "sliders":
      return <Sliders {...props} />
    default:
      return <div />
  }
}

export default Icon

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}
