import React, { useState } from "react"
import PropTypes from "prop-types"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import { BreakdownSelectorTitleWrapper, BreakdownLevelButton } from "./styles"

const { H2 } = Typography

function BreakdownSelectorTitle(props) {
  const { onChangeActiveBreakdownLevel } = props
  const [activeBreakdownLevel, setActiveBreakdownLevel] = useState("Individual")

  function handleNewActiveBreakdownLevel(newActiveBreakdownLevel, event) {
    event.preventDefault()
    event.stopPropagation()

    setActiveBreakdownLevel(newActiveBreakdownLevel)
    onChangeActiveBreakdownLevel(newActiveBreakdownLevel)
  }

  return (
    <BreakdownSelectorTitleWrapper>
      <span>
        <H2 fontWeight="bold" color="900" fontSize="24">
          How is my organization doing by:
        </H2>
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span>
        <BreakdownLevelButton
          onClick={(event) =>
            handleNewActiveBreakdownLevel("Department", event)
          }
          toggled={activeBreakdownLevel === "Department"}
        >
          Department
        </BreakdownLevelButton>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <BreakdownLevelButton
          onClick={(event) =>
            handleNewActiveBreakdownLevel("Individual", event)
          }
          toggled={activeBreakdownLevel === "Individual"}
        >
          Individual
        </BreakdownLevelButton>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <BreakdownLevelButton
          onClick={(event) => handleNewActiveBreakdownLevel("Location", event)}
          toggled={activeBreakdownLevel === "Location"}
        >
          Location
        </BreakdownLevelButton>
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span>
        <H2 fontWeight="bold" color="900" fontSize="24">
          ?
        </H2>
      </span>
    </BreakdownSelectorTitleWrapper>
  )
}

BreakdownSelectorTitle.propTypes = {
  onChangeActiveBreakdownLevel: PropTypes.func,
}

export default BreakdownSelectorTitle
