import { getGlobalConfigKey } from "@src/globalConfig"
import type { BackendClient } from "./BackendClient"
import { ElevateClient } from "./ElevateClient"
import { MimecastClient } from "./MimecastClient"

/**
 * There are some differences between the Elevate APIs and the Mimecast APIs, so
 * we define a common interface and hide the implementations in clients created
 * specifically for each back-end. We will gradually add more endpoints here as
 * the Mimecast APIs evolve and the Elevate endpoints remain static.
 */
function createClient(): BackendClient {
  switch (getGlobalConfigKey()) {
    case "elevate":
      return new ElevateClient()
    case "mimecast_cg":
    case "mimecast_ci":
      return new MimecastClient()
  }
}

export default createClient()
