import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useRequest, useDebounceFn } from "ahooks"
import moment from "moment"

import { getPoliciesFilters } from "@src/services/apis/policies"
import { humanize, isEmpty } from "@src/utils/string"
import { buildQueryString } from "@src/utils/buildQueryString"
import { ACTIONS_LABELS_MAPPING } from "@src/scenes/RiskResponseRuleCreateScreen/constants"
import { darklyGetFlag } from "@src/utils/darkly"

const BOOLEAN_MAP = {
  true: "Yes",
  false: "No",
  null: "-",
}

const FILTER_OPERATION_MAPPING = {
  state: "in",
  category: "in",
  action_category: "in",
  realtime: "is",
  updated_datetime: "between",
}

const FILTERS_KEY_LABEL_MAPPING = {
  state: "rule_status",
  action_category: "action_category",
  realtime: "realtime",
  updated_datetime: "last_edited",
}

function minusThreeMonths(date) {
  const newDate = new Date(date) // Create a new Date object to avoid mutating the original date

  return moment(newDate).subtract(3, "months").toDate()
}

function formatDate(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0]
}

export const useRiskResponseRuleFilters = (policyId) => {
  const history = useHistory()
  const isItdrEnabled = darklyGetFlag("itdr-behaviors")

  const [filters, setFilters] = useState({})
  const [appliedFilters, setAppliedFilters] = useState([])

  const { data } = useRequest(() => getPoliciesFilters(), {
    formatResult: ({ data }) => {
      const filterOptions = {}
      if (data?.rule_status) {
        filterOptions.rule_status = data?.rule_status?.map((item) => ({
          label: humanize(item),
          value: item,
        }))
      }
      if (data?.action_category) {
        filterOptions.action_category = data?.action_category?.map((item) => ({
          value: item,
          label: ACTIONS_LABELS_MAPPING[item],
        }))
      }
      if (data?.criteria_includes) {
        filterOptions.criteria_includes = data?.criteria_includes?.map(
          (item) => ({
            label: humanize(item),
            value: item,
          }),
        )
      }

      if (data?.behaviors) {
        filterOptions.behaviors = data?.behaviors?.map((item) => ({
          label: humanize(item),
          value: item,
        }))
      }
      /**
       * Make sure this filter is only displayed when feature flag is On
       */
      if (data?.realtime && isItdrEnabled) {
        filterOptions.realtime = data?.realtime?.map((item) => ({
          label: BOOLEAN_MAP[item],
          value: `${item}`,
        }))
      }

      return { ...filterOptions, keys: data?.keys }
    },
  })

  let queryString = buildQueryString(filters)
  let filtersString = ""
  if (!isEmpty(queryString)) {
    filtersString = queryString
      .split("&")
      ?.map((item) => {
        const [key, value] = item?.split("=")
        return `${key} ${FILTER_OPERATION_MAPPING[key]} ${value}`
      })
      ?.join(" AND ")
  }

  useEffect(() => {
    if (!isEmpty(policyId)) {
      queryString = `policy-id=${policyId}&${
        isEmpty(queryString) ? "" : queryString
      }`
    }
    history?.push({
      search: queryString,
    })
  }, [queryString])

  const updateFilter = (key, value) => {
    if (!isEmpty(filters[key]) && !isEmpty(key) && isEmpty(value)) {
      setFilters((prev) => {
        // eslint-disable-next-line
        const { [key]: removedProperty, ...rest } = prev
        return {
          ...rest,
        }
      })
    } else {
      setFilters((prev) => ({
        ...prev,
        [key]: value,
      }))
    }
  }

  const { run: updateFilterDebounced } = useDebounceFn(updateFilter, {
    wait: 300,
  })

  const removeFilters = (selection) => {
    const { key, value } = selection
    const filterList = (filters[key] || "")
      .split(",")
      .filter(Boolean)
      .filter((item) => item != value)

    if (key === "updated_datetime") {
      updateFilter(key, "")
    } else {
      updateFilter(key, filterList.join())
    }
  }
  const clearFilters = () => {
    // Clear filters in state
    setFilters({})
  }

  const handleFilterChange = ({ key, value, checked, type }) => {
    const today = new Date()
    const formattedToday = formatDate(today)

    const filterList = (filters[key] || "")
      .split(",")
      .filter(Boolean)
      .filter((item) => item != value)

    if (checked) {
      filterList.push(value)
    }
    if (type === "date") {
      filterList.push(value)

      /**
       * For date range filter we will have to pre-populate empty side of date range filter
       * If left side is filled AND right side is empty => right side will be today
       * If right side is filled AND left side is empty => left side will be selected date - 3 months
       */
      const fl = filterList[0]
      if (isEmpty(fl[1])) {
        fl[1] = formattedToday
      } else if (isEmpty(fl[0]) && !isEmpty(fl[1])) {
        fl[0] = formatDate(minusThreeMonths(fl[1]))
      }
      filterList[0] = fl
    }
    updateFilter(key, filterList.join(","))
  }

  const getLabel = (key, value) => {
    const label = FILTERS_KEY_LABEL_MAPPING[key]
    if (isEmpty(label)) {
      const behaviors = data.behaviors
      const tB = behaviors.find((item) => item.value === value)
      const criteria_includes = data.criteria_includes
      const tCriteriaIncludes = criteria_includes.find(
        (item) => item.value === value,
      )
      if (tB) {
        return "Behavior Category"
      }
      if (tCriteriaIncludes) {
        return "Criteria Includes"
      }
    }
    return label
  }

  useEffect(() => {
    const list = Object.entries(filters).flatMap(([key, list]) =>
      (list || "").split(",").map((value) => ({
        key,
        value,
        original: value,
        humanizedName: getLabel(key, value),
      })),
    )
    /* Make sure date range filters are merged into one*/

    if (list?.length) {
      const dateFiltersList = list.filter((it) => it.key === "updated_datetime")

      const nonDateFiltersList = list.filter(
        (it) => it.key !== "updated_datetime",
      )
      const combinedValue = dateFiltersList?.map((i) => i.value)?.join(",")
      if (dateFiltersList.length) {
        nonDateFiltersList.push({
          key: "updated_datetime",
          value: combinedValue,
          original: combinedValue,
          humanizedName: getLabel("updated_datetime", combinedValue),
        })
      }
      setAppliedFilters(nonDateFiltersList)
    } else {
      setAppliedFilters(list)
    }
  }, [filters])

  return {
    availableFilters: data || {},
    appliedFilters,
    filters,
    queryString,
    filtersString,
    updateFilter,
    updateFilterDebounced,
    removeFilters,
    clearFilters,
    FILTER_OPERATION_MAPPING,
    handleFilterChange,
  }
}
