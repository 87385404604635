import React, { PureComponent } from "react"
import { NavLink, Link } from "react-router-dom"
import { Icon, Typography } from "@elevate_security/elevate-component-library"
import cx from "classnames"
import PropTypes from "prop-types"
import { getTopLevelMenu, getSecondaryNavMenu } from "./utils"
import styles from "./styles"
import logo from "./images/elevate-logo-full.svg"
import { stringToBase64 } from "@src/utils/string"

const { Text } = Typography

// Styled Links
const StyledSubItemLink = styles.MainNavSubLink(NavLink)
const StyledSecondaryLink = styles.SecondaryNavLink(Link)
const StyledLink = styles.MainNavLink(Link)

const StyledNavLinkSubIcon = styles.NavLinkSubIcon(Icon)

class Sidebar extends PureComponent {
  static defaultProps = {
    location: {},
    history: {},
  }

  static propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }),
    history: PropTypes.shape({ listen: PropTypes.func }),
  }

  constructor(props) {
    super(props)
    this.state = {
      activeSubMenu: null,
      openMenu: false,
      topLevelNavItems: [],
      secondaryNavItems: [],
    }
  }

  componentDidMount = () => this.setSidebarMenu()

  componentWillUnmount = () => (this.listenRouteChangeAndActiveMenu = null)

  setTopLevelMenuItems = () => {
    const topLevelNavItems = getTopLevelMenu()
    this.setState({ topLevelNavItems })
  }

  setSidebarMenu = () => {
    const { history } = this.props
    const topLevelNavItems = getTopLevelMenu()
    const secondaryNavItems = getSecondaryNavMenu()
    this.listenRouteChangeAndActiveMenu = null
    this.setState({ topLevelNavItems, secondaryNavItems }, () => {
      this.handleRouterChange()
      this.listenRouteChangeAndActiveMenu = history.listen(
        this.handleRouterChange,
      )
    })
  }

  /**
   * The handleRouterChange it's responsible by to listen
   * the changes of routes and then to define the opened
   * menu on sidebar
   */
  handleRouterChange = () => {
    const { location } = this.props
    const { activeSubMenu, secondaryNavItems, topLevelNavItems } = this.state
    let activeNav = null
    topLevelNavItems.forEach((navLink) => {
      if (navLink.root && location.pathname.includes(navLink.root)) {
        activeNav = navLink
      }
    })

    secondaryNavItems.forEach((navLink) => {
      if (navLink.root && location.pathname.includes(navLink.root)) {
        activeNav = navLink
      }
    })

    if (activeSubMenu !== activeNav) {
      this.setState({
        activeSubMenu: activeNav,
        openMenu: !!activeNav,
      })
    }
  }

  /**
   * The toggleActiveSubMenu it's responsible
   * to toggle each sub menu of sidebar
   */
  toggleActiveSubMenu = (navLink) => {
    const { activeSubMenu, openMenu } = this.state
    if (navLink === activeSubMenu) {
      this.setState({ openMenu: !openMenu })
    } else {
      this.setState({
        activeSubMenu: navLink,
        openMenu: true,
      })
    }
  }

  /**
   * The renderTopNavItems it's responsible by to render the
   * the top menus of the sidebar
   */
  renderTopNavItems = () => {
    const { topLevelNavItems } = this.state

    return topLevelNavItems
      .filter((navItem) => !navItem.hidden)
      .map((navLink) => [
        this.renderMainNavItem(navLink),
        this.renderTopNavSubItems(navLink),
      ])
  }

  /**
   * The renderMainNavItem it's responsible by to render
   * the main item from top level menu
   */
  renderMainNavItem = (navLink) => {
    const { activeSubMenu, openMenu } = this.state
    const { MainNavItem, SubMenuToggleButton } = styles

    return (
      <MainNavItem
        key={stringToBase64(navLink.address + navLink.text)}
        className={cx({
          locked: navLink.locked,
          selected: activeSubMenu === navLink,
          active: activeSubMenu === navLink && !openMenu,
        })}
      >
        {this.renderNormalLink(navLink)}
        {navLink.subItems && !navLink.locked && (
          <SubMenuToggleButton
            onClick={() => !navLink.locked && this.toggleActiveSubMenu(navLink)}
            className={cx({ active: activeSubMenu === navLink && openMenu })}
          >
            <Icon name="CaretRight" size="xsm" fill="#A9AEB5" />
          </SubMenuToggleButton>
        )}
      </MainNavItem>
    )
  }

  /**
   * The renderTopNavSubItems it's responsible to render
   * the subitems from top menu
   */
  renderTopNavSubItems = (navLink) => {
    const { activeSubMenu, openMenu } = this.state
    const hasSubItems =
      navLink.subItems && activeSubMenu === navLink && openMenu

    return (
      hasSubItems && (
        <styles.MainNavSubItems
          key={stringToBase64(`subItems_${navLink.address}${navLink.text}`)}
        >
          {navLink.subItems.map((subItem) => {
            if (!subItem.hidden) {
              return (
                <StyledSubItemLink
                  key={stringToBase64(subItem.text + subItem.address)}
                  to={subItem.address}
                  activeClassName="active"
                >
                  {subItem.text}
                </StyledSubItemLink>
              )
            }
            return null
          })}
        </styles.MainNavSubItems>
      )
    )
  }

  /**
   * The renderNormalLink it's responsible by to render
   * link of react app
   */
  renderNormalLink = (navLink) => {
    const { MenuButtonLock } = styles

    return (
      <>
        <StyledLink
          to={navLink.address}
          onClick={() => !navLink.locked && this.toggleActiveSubMenu(navLink)}
        >
          {navLink.icon && <styles.NavLinkIcon src={navLink.icon} />}
          {navLink.text}
        </StyledLink>
        {navLink.locked && (
          <MenuButtonLock>
            <Icon name="Lock" size="lg" fill="#A9AEB5" />
          </MenuButtonLock>
        )}
      </>
    )
  }

  /**
   * The renderSecondaryItems it's responsible by to render the
   * the secondary menus of the sidebar
   */
  renderSecondaryItems = () => {
    const { secondaryNavItems } = this.state

    return secondaryNavItems
      .filter((secNavItem) => !secNavItem.hidden)
      .map((secNavItem) => [this.renderMainSecondaryNavItem(secNavItem)])
  }

  /**
   * The renderMainSecondaryNavItem it's responsible by to render
   * the main container of secondary menu
   */
  renderMainSecondaryNavItem = (secNavItem) => {
    const { activeSubMenu, openMenu } = this.state
    const { MainNavItem, MenuButtonLock } = styles
    return (
      <MainNavItem
        key={stringToBase64(secNavItem.text)}
        className={cx({
          locked: secNavItem.locked,
          selected: activeSubMenu === secNavItem,
          active: activeSubMenu === secNavItem && !openMenu,
        })}
      >
        <StyledSecondaryLink>
          <StyledNavLinkSubIcon
            name={secNavItem.icon}
            size="sm"
            stroke="#7B828A"
          />
          <Text>{secNavItem.text}</Text>
        </StyledSecondaryLink>
        <MenuButtonLock>
          <Icon name="Lock" size="lg" fill="#A9AEB5" />
        </MenuButtonLock>
      </MainNavItem>
    )
  }

  renderSideBarFooter = () => {
    return (
      <styles.LegalFooter>
        <a
          href="https://elevatesecurity.zendesk.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </a>
        <a
          href="https://elevatesecurity.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </styles.LegalFooter>
    )
  }

  render = () => (
    <styles.Sidebar>
      <styles.Logo src={logo} alt="Elevate Security" />
      <styles.Nav>{this.renderTopNavItems()}</styles.Nav>
      <styles.Nav>{this.renderSecondaryItems()}</styles.Nav>
      {this.renderSideBarFooter()}
    </styles.Sidebar>
  )
}

export default Sidebar
