import React, { useCallback, useState, useMemo } from "react"
import PropTypes from "prop-types"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Checkbox from "@src/components/Checkbox"
import { Wrapper } from "./styles"
import { MoreFilterInfoMessage } from "../MoreFilterInfoMessage"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"
import { SpacingY } from "@src/components/SpacingY"
import { THEME_PRIMARY } from "@src/theme"

const showInLowerCase = (item) => item?.toLowerCase()?.replaceAll(" ", "-")

const MultiSelectFilter = ({
  section,
  filters,
  handleChange,
  showAllFilters = false,
  isCollapsible = false,
  initialCollapseState = false,
  infoMessage = "",
  checkboxLimit = 10,
}) => {
  const { showMore, showLess, items, key } = section

  const isChecked = useCallback(
    (value) => {
      return !!filters.find(
        (filter) =>
          filter.key === key &&
          showInLowerCase(filter.value) === showInLowerCase(value),
      )
    },
    [filters, key],
  )

  const checkedItems = useMemo(
    () => items.filter(({ value }) => isChecked(value)),
    [items, isChecked],
  )

  const [expanded, setExpanded] = useState(showAllFilters)
  const [collapsed, setCollapsed] = useState(
    isCollapsible && items.some(({ value }) => isChecked(value))
      ? false
      : initialCollapseState,
  )
  const showCount = expanded ? items.length : 4

  return (
    <div>
      {isCollapsible && (
        <div className="title" onClick={() => setCollapsed(!collapsed)}>
          {section.title}
          <Icon
            name={!collapsed ? "CaretUp" : "CaretDown"}
            size="xxsm"
            fill="#959DA8"
            className="expand-icon"
          />
        </div>
      )}
      <Wrapper collapsed={collapsed}>
        <SpacingY>
          {!isCollapsible && <div className="title">{section.title}</div>}
          {items.length <= checkboxLimit && (
            <>
              <div className="checkbox-items">
                {items.slice(0, showCount).map((item) => {
                  const { label, value } = item
                  return (
                    <Checkbox
                      label={label}
                      id={`${section.title}-${label}`}
                      key={value}
                      checked={isChecked(value)}
                      onChange={(checked) =>
                        handleChange({
                          key,
                          label,
                          value,
                          checked,
                        })
                      }
                    />
                  )
                })}
              </div>
              {showMore && (
                <div
                  className="show-more-link"
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? showLess : showMore}
                  <Icon
                    name={expanded ? "CaretUp" : "CaretDown"}
                    size="xxsm"
                    fill={THEME_PRIMARY}
                    className="expand-icon"
                  />
                </div>
              )}
              {infoMessage && <MoreFilterInfoMessage message={infoMessage} />}
            </>
          )}
          {items.length > checkboxLimit && (
            <ComboBoxWithOverlap
              data={items}
              clearable={false}
              onChange={(selected) => {
                selected.forEach(({ label, value }) => {
                  // Select any items that were previously deselected, but have just been selected
                  if (!isChecked(value)) {
                    handleChange({ key, label, value, checked: true })
                  }
                })
                checkedItems
                  .filter(
                    ({ value }) =>
                      !selected.some((selected) => selected.value === value),
                  )
                  .forEach(({ label, value }) => {
                    // Deselect any items that were previously selected, but have just been deselected
                    handleChange({ key, label, value, checked: false })
                  })
              }}
              defaultValue={checkedItems}
              value={checkedItems}
              isSearchable={true}
              isMultiOption={true}
              checked={true}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              placeholder={`Type to search by ${section.title}...`}
            />
          )}
        </SpacingY>
      </Wrapper>
    </div>
  )
}

MultiSelectFilter.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    key: PropTypes.string.isRequired,
    showMore: PropTypes.string,
    showLess: PropTypes.string,
  }).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  showAllFilters: PropTypes.boolean,
  isCollapsible: PropTypes.boolean,
  initialCollapseState: PropTypes.boolean,
  infoMessage: PropTypes.string,
}

export default MultiSelectFilter
