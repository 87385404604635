import React from "react"
import PropTypes from "prop-types"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { ScoreSpan, ScoreIcon, ScoreTrend } from "./styles"
import { FlexCol, FlexRow } from "../../styles"
import { parseScoreChange } from "../../../components/TeamSecurityScore/BreakdownChart/utils"
import { getIconAndColorByScoreDiff } from "@src/utils/colors"

const CircularScoreBar = ({ teamScore, teamScoreTrend }) => {
  const { icon, color } = getIconAndColorByScoreDiff(teamScoreTrend)
  return (
    <div style={{ width: 200, minWidth: 200, height: 200 }}>
      <CircularProgressbarWithChildren
        value={teamScore}
        strokeWidth={5}
        styles={buildStyles({
          pathColor: "#FACC38",
        })}
      >
        <FlexCol>
          <ScoreSpan>{teamScore}</ScoreSpan>
          <FlexRow>
            <ScoreIcon>
              <Icon name={icon} fill={color} />
            </ScoreIcon>
            <ScoreTrend color={color} style={{ marginLeft: 4 }}>
              {parseScoreChange(teamScoreTrend)} pts
            </ScoreTrend>
          </FlexRow>
        </FlexCol>
      </CircularProgressbarWithChildren>
    </div>
  )
}

CircularScoreBar.propTypes = {
  teamScore: PropTypes.number.isRequired,
  teamScoreTrend: PropTypes.number.isRequired,
}

export default CircularScoreBar
