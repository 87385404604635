/**
 * Get the bucket that a score belongs to
 * Precondition: -1 < score < 101
 */
export const scoreToBucket = (score) => {
  if (score < 20) {
    return "0 - 19"
  } else if (score < 40) {
    return "20 - 39"
  } else if (score < 60) {
    return "40 - 59"
  } else if (score < 80) {
    return "60 - 79"
  } else {
    return "80 - 100"
  }
}

export const extractPerformanceBarChartData = (riskDistributions) => {
  if (!riskDistributions || riskDistributions.length === 0) return []
  // Retrieve the latest month distribution data
  const latestMonthData = riskDistributions.slice(-1)[0]
  const latestMonthDistribution =
    latestMonthData[Object.keys(latestMonthData)[0]].distribution

  // Populate result of buckets and their corresponding count
  const result = []
  const levels = Object.keys(latestMonthDistribution)
  levels.forEach((level) => {
    switch (level) {
      case "level_1":
        result.push({
          bucket: "80 - 100",
          numberOfIndividuals: latestMonthDistribution[level],
        })
        break
      case "level_2":
        result.push({
          bucket: "60 - 79",
          numberOfIndividuals: latestMonthDistribution[level],
        })
        break
      case "level_3":
        result.push({
          bucket: "40 - 59",
          numberOfIndividuals: latestMonthDistribution[level],
        })
        break
      case "level_4":
        result.push({
          bucket: "20 - 39",
          numberOfIndividuals: latestMonthDistribution[level],
        })
        break
      case "level_5":
        result.push({
          bucket: "0 - 19",
          numberOfIndividuals: latestMonthDistribution[level],
        })
        break
      default:
        break
    }
  })

  // Sort in increasing bucket order
  result.sort((a, b) => (a.bucket >= b.bucket ? 1 : -1))
  return result
}

/**
 * Calculates percentage of individuals with score of 40 and below
 * This corresponds to levels 4 and 5 in the input data
 */
export const calculateStragglingPercentage = (riskDistributions) => {
  if (riskDistributions === undefined || riskDistributions.length == 0) {
    return -1
  }

  // Retrieve latest month distribution
  const latestMonthData = riskDistributions.slice(-1)[0]
  const latestMonthDistribution =
    latestMonthData[Object.keys(latestMonthData)[0]].distribution

  // Retrieve percentage of stragglers in level_4 and level_5
  const numberOfStragglers =
    latestMonthDistribution.level_4 + latestMonthDistribution.level_5
  const totalNumberOfIndividuals = Object.values(
    latestMonthDistribution,
  ).reduce((a, b) => a + b)
  let stragglingPercentage =
    (numberOfStragglers / totalNumberOfIndividuals) * 100

  // Round percentage value to 1dp
  stragglingPercentage = Math.round(stragglingPercentage * 10) / 10
  return stragglingPercentage
}

/**
 * Extracts the name of the weakest behavior by comparing behavior scores
 * If no single weakest behavior, returns empty string
 */
export const extractWeakestBehaviorName = (securityBreakDownBehavior) => {
  if (
    securityBreakDownBehavior === undefined ||
    securityBreakDownBehavior.length == 0
  ) {
    return ""
  }

  // Sort behaviors by behaviorScore
  securityBreakDownBehavior.sort((a, b) => a.behaviorScore - b.behaviorScore)

  // Check if weakest behavior is unique
  const weakestBehavior = securityBreakDownBehavior[0]
  if (securityBreakDownBehavior.length > 1) {
    const secondWeakestBehavior = securityBreakDownBehavior[1]
    if (weakestBehavior.behaviorScore == secondWeakestBehavior.behaviorScore) {
      return ""
    }
  }

  // Return name of behavior with lowest behaviorScore
  return weakestBehavior.behaviorName
}

export const extractWeakestDepartmentName = ({ data, isLoaded }) => {
  return isLoaded && data ? data.name : ""
}

export const getParentNode = ({ elementRef }) => {
  if (!elementRef || !elementRef.current) return null
  return elementRef.current.parentElement
}

export const getParentDimensions = ({ chartRef }) => {
  if (!chartRef || !chartRef.current) return {}
  const { clientHeight, clientWidth } = getParentNode({ elementRef: chartRef })
  return {
    clientHeight,
    clientWidth,
  }
}

// Adds commas to numbers 1000 and above
export function formatWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// Gets tooltip width based on number of individuals
export function getTooltipWidth(numberOfIndividuals) {
  if (numberOfIndividuals < 10000) {
    return "320px"
  } else if (numberOfIndividuals < 1000000) {
    return "190px"
  } else if (numberOfIndividuals < 100000000) {
    return "210px"
  } else {
    return "225px"
  }
}
