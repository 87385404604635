import moment from "moment"
import { getCompanyInfo } from "@src/services/redux/company/selectors"

export function getLastUpdateTimeLabel(scoring_datetime) {
  const companyInfo = getCompanyInfo()

  if (companyInfo) {
    const date = new Date(scoring_datetime)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Show import stats only if a successful import has completed
    if (scoring_datetime) {
      const parseDate = moment(scoring_datetime)
      const lastUpdated = `Last updated on ${parseDate.format(
        "MMM Do, YYYY [at] h:mm A",
      )} ${timezone}`

      return date.toDateString() === new Date().toDateString()
        ? `Last update ${parseDate.format("[Today] [at] h:mm A")} ${timezone}`
        : lastUpdated
    }
  }
  return null
}

export const factorsGraphOrder = [
  "very_low",
  "low",
  "medium",
  "high",
  "very_high",
]
export const scoreTiersGraphOrder = [
  "very_low",
  "low",
  "medium",
  "high",
  "very_high",
]

function reverseScore(value) {
  if (value === null) return null
  return (100 - value).toFixed(2)
}

export const setHierarchicalFormat = (response, activeActions) => {
  const graphData = response.hierarchicalScores.groups
  const currentData = graphData[graphData.length - 1].groups
  const previousMonthData = graphData[graphData.length - 2].groups
  const lastYearData = graphData[0].groups
  const actions = currentData
    .filter(
      (action) =>
        activeActions?.includes(action.key) && action.key !== "overall",
    )
    .map((item) => item.key)
  const spikeGraphData = []
  actions.forEach((action) => {
    spikeGraphData.push({
      action: action,
      review: action,
      current_score: reverseScore(
        currentData.filter((item) => item.key === action)[0].scores.score,
      ),
      past_1_month: reverseScore(
        previousMonthData.filter((item) => item.key === action)[0].scores.score,
      ),
      past_12_month: reverseScore(
        lastYearData.filter((item) => item.key === action)[0].scores.score,
      ),
      trendData: graphData.map(({ key, groups }) => {
        const date = key
        const score = reverseScore(
          groups.filter((item) => item.key === action)[0].scores.score,
        )
        return { date, score }
      }),
    })
  })
  return spikeGraphData.sort((a, b) => a.current_score < b.current_score)
}

export const setReputationService = (response, activeActions) => {
  const graphData = response
  let currentData = []
  let previousMonthData = []
  let lastYearData = []
  const actions = Object.keys(graphData).filter(
    (action) =>
      activeActions?.includes(action.replaceAll("_", "-")) &&
      action !== "overall",
  )
  actions.forEach((action) => {
    const scoreArray = graphData[action]
    currentData = [
      ...currentData,
      { key: action, score: scoreArray[scoreArray.length - 1].score },
    ]
    previousMonthData = [
      ...previousMonthData,
      { key: action, score: scoreArray[scoreArray.length - 1].previous_score },
    ]
    lastYearData = [
      ...lastYearData,
      { key: action, score: scoreArray[0].score },
    ]
  })
  const spikeGraphData = []
  actions.forEach((action) => {
    spikeGraphData.push({
      action: action,
      review: action,
      current_score: currentData.filter((item) => item.key === action)[0].score,
      past_1_month: previousMonthData.filter((item) => item.key === action)[0]
        .score,
      past_12_month: lastYearData.filter((item) => item.key === action)[0]
        .score,
      trendData: graphData[action].map((item) => {
        return { date: item.date, score: item.score }
      }),
    })
  })
  return spikeGraphData.sort((a, b) => a.current_score < b.current_score)
}
