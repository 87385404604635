import styled from "styled-components"
import colors from "@src/utils/colors"
import { theme } from "@elevate_security/elevate-component-library"
import { THEME_PRIMARY } from "@src/theme"

export const NotFoundMessage = styled.div`
  text-align: center;
`

export const CreateRiskResponseRuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`

export const CreateRiskResponseRuleBodyWrapper = styled.div`
  scroll-behavior: smooth;
  overflow-y: auto;
  flex-grow: 1;
`

export const CreateRiskResponseRuleHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray.transparent[200]};
`

export const CreateRiskResponseRuleButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`

export const HeaderButtonsWrapper = styled.div`
  padding: 20px;
`

export const ErrorMessage = styled.div`
  font-weight: 500;
  line-height: 1.33;
  color: ${colors.error};
  font-size: 14px;
`

export const RiskResponseRuleBodyWrapper = styled.div`
  padding: 20px;
  min-width: fit-content;
  width: 100%;
`

export const R3HeaderBreadcrumbsWrapper = styled.div`
  margin-left: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: 2px 3px 0 5px;
    width: 9px;
    height: 9px;
  }
`

export const R3HeaderBreadcrumb = styled.button`
  font-weight: ${(props) => (props.current ? 900 : 400)};
  color: ${(props) =>
    props.current ? "#565D66" : (props.disabled && "#565d66") || THEME_PRIMARY};
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: ${(props) =>
    props.disabled && !props.current ? "not-allowed" : "pointer"};
`

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContent"] {
    font-size: 16px;
  }
`

export const ActionErrorList = styled.ul`
  list-style-type: disc;
  margin-left: 25px;
  margin-bottom: 0;
`
