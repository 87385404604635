import { YEAR } from "@src/scenes/HumanRiskDashboard/constants"
export const performanceDictionaryMockData = {
  //individuals === score  && events === last_month_score because need that logic of data to render points through time
  "real-world-phishing": [
    {
      type: "phishing_clicked",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
    {
      type: "phishing_reported",
      data: [
        {
          date: "2021-03-01",
          individuals: 116,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 34,
          events: 116,
        },
        {
          date: "2021-05-01",
          individuals: 89,
          events: 34,
        },
        {
          date: "2021-06-01",
          individuals: 23,
          events: 89,
        },
        {
          date: "2021-07-01",
          individuals: 45,
          events: 23,
        },
        {
          date: "2021-08-01",
          individuals: 144,
          events: 45,
        },
        {
          date: "2021-09-01",
          individuals: 230,
          events: 144,
        },
        {
          date: "2021-10-01",
          individuals: 248,
          events: 230,
        },
        {
          date: "2021-11-01",
          individuals: 179,
          events: 248,
        },
        {
          date: "2021-12-01",
          individuals: 110,
          events: 179,
        },
        {
          date: "2022-01-01",
          individuals: 126,
          events: 110,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 126,
        },
      ],
    },
  ],
  training: [
    {
      type: "training_not_completed",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
  ],
  "device-security": [
    {
      type: "not-in-compliance",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
  ],
  phishing: [
    {
      type: "phishing_clicked",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
    {
      type: "phishing_reported",
      data: [
        {
          date: "2021-03-01",
          individuals: 116,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 34,
          events: 116,
        },
        {
          date: "2021-05-01",
          individuals: 89,
          events: 34,
        },
        {
          date: "2021-06-01",
          individuals: 23,
          events: 89,
        },
        {
          date: "2021-07-01",
          individuals: 45,
          events: 23,
        },
        {
          date: "2021-08-01",
          individuals: 144,
          events: 45,
        },
        {
          date: "2021-09-01",
          individuals: 230,
          events: 144,
        },
        {
          date: "2021-10-01",
          individuals: 248,
          events: 230,
        },
        {
          date: "2021-11-01",
          individuals: 179,
          events: 248,
        },
        {
          date: "2021-12-01",
          individuals: 110,
          events: 179,
        },
        {
          date: "2022-01-01",
          individuals: 126,
          events: 110,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 126,
        },
      ],
    },
  ],
  "sensitive-data-handling": [
    {
      type: "sensitive-data-policy-violated",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
  ],
  "password-manager": [
    {
      type: "password-manager-installed",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
    {
      type: "password-manager-actively-used",
      data: [
        {
          date: "2021-03-01",
          individuals: 116,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 34,
          events: 116,
        },
        {
          date: "2021-05-01",
          individuals: 89,
          events: 34,
        },
        {
          date: "2021-06-01",
          individuals: 23,
          events: 89,
        },
        {
          date: "2021-07-01",
          individuals: 45,
          events: 23,
        },
        {
          date: "2021-08-01",
          individuals: 144,
          events: 45,
        },
        {
          date: "2021-09-01",
          individuals: 230,
          events: 144,
        },
        {
          date: "2021-10-01",
          individuals: 248,
          events: 230,
        },
        {
          date: "2021-11-01",
          individuals: 179,
          events: 248,
        },
        {
          date: "2021-12-01",
          individuals: 110,
          events: 179,
        },
        {
          date: "2022-01-01",
          individuals: 126,
          events: 110,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 126,
        },
      ],
    },
  ],
  "unauthorized-software": [
    {
      type: "unauthorized-software-found",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
  ],
  malware: [
    {
      type: "malware-download-incidents",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
    {
      type: "malware-executions-incidents",
      data: [
        {
          date: "2021-03-01",
          individuals: 116,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 34,
          events: 116,
        },
        {
          date: "2021-05-01",
          individuals: 89,
          events: 34,
        },
        {
          date: "2021-06-01",
          individuals: 23,
          events: 89,
        },
        {
          date: "2021-07-01",
          individuals: 45,
          events: 23,
        },
        {
          date: "2021-08-01",
          individuals: 144,
          events: 45,
        },
        {
          date: "2021-09-01",
          individuals: 230,
          events: 144,
        },
        {
          date: "2021-10-01",
          individuals: 248,
          events: 230,
        },
        {
          date: "2021-11-01",
          individuals: 179,
          events: 248,
        },
        {
          date: "2021-12-01",
          individuals: 110,
          events: 179,
        },
        {
          date: "2022-01-01",
          individuals: 126,
          events: 110,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 126,
        },
      ],
    },
  ],
  "secure-browsing": [
    {
      type: "accessed-dangerous-website",
      data: [
        {
          date: "2021-03-01",
          individuals: 249,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 210,
          events: 249,
        },
        {
          date: "2021-05-01",
          individuals: 111,
          events: 210,
        },
        {
          date: "2021-06-01",
          individuals: 245,
          events: 111,
        },
        {
          date: "2021-07-01",
          individuals: 83,
          events: 245,
        },
        {
          date: "2021-08-01",
          individuals: 109,
          events: 83,
        },
        {
          date: "2021-09-01",
          individuals: 124,
          events: 109,
        },
        {
          date: "2021-10-01",
          individuals: 119,
          events: 124,
        },
        {
          date: "2021-11-01",
          individuals: 234,
          events: 119,
        },
        {
          date: "2021-12-01",
          individuals: 13,
          events: 234,
        },
        {
          date: "2022-01-01",
          individuals: 190,
          events: 13,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 190,
        },
      ],
    },
    {
      type: "blocked-but-continued-browsing",
      data: [
        {
          date: "2021-03-01",
          individuals: 116,
          events: null,
        },
        {
          date: "2021-04-01",
          individuals: 34,
          events: 116,
        },
        {
          date: "2021-05-01",
          individuals: 89,
          events: 34,
        },
        {
          date: "2021-06-01",
          individuals: 23,
          events: 89,
        },
        {
          date: "2021-07-01",
          individuals: 45,
          events: 23,
        },
        {
          date: "2021-08-01",
          individuals: 144,
          events: 45,
        },
        {
          date: "2021-09-01",
          individuals: 230,
          events: 144,
        },
        {
          date: "2021-10-01",
          individuals: 248,
          events: 230,
        },
        {
          date: "2021-11-01",
          individuals: 179,
          events: 248,
        },
        {
          date: "2021-12-01",
          individuals: 110,
          events: 179,
        },
        {
          date: "2022-01-01",
          individuals: 126,
          events: 110,
        },
        {
          date: "2022-02-01",
          individuals: 55,
          events: 126,
        },
      ],
    },
  ],
}

export const generatePerformanceData = (arr) => {
  return arr?.reduce((acc, { type, data }) => {
    acc[type] = data
      .sort((a, b) => (a.date > b.date ? 1 : -1))
      .map(({ date, individuals, events }) => ({
        date,
        score: individuals,
        score_last_month: events,
      }))
    return acc
  }, {})
}

export const getPerformanceMonths = (months) => {
  return months === YEAR ? ++months : months
}
