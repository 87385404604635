import React from "react"
import { Wrapper } from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditTagIncidentsCrowdstrike/EditTagIncidentsCrowdstrike.styles"
import { NOTIFICATION_OPTIONS } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"
import { NotificationRadioButtonGroup } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/Notifications"
import TagsCombobox from "./TagsCombobox"

export function EditTagIncidentsCrowdstrike({ action, updateAction }) {
  return (
    <Wrapper>
      {/* Select Workflow Wrapper */}
      <TagsCombobox action={action} updateAction={updateAction} />

      {/* SailPoint Matching Method */}
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
      />
    </Wrapper>
  )
}
