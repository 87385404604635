import React, { useState } from "react"
import { theme, Typography } from "@elevate_security/elevate-component-library"

import { base64ToString, stringToBase64 } from "@src/utils/string"
import { SpaceBetween } from "@src/components/SpaceBetween"

import { InlineR3NotificationEditor } from "../../../NotificationEditor/Inline"
import { RichTextR3NotificationEditor } from "../../../NotificationEditor/RichText"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"
import { Scheduler } from "../../../Scheduler"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"
import { PersonListAttachmentToggle } from "../PersonListAttachmentToggle"

export function EditEmailNotification({
  templateAttributes,
  action,
  onChange,
  allowPersonListAttachment = false,
}) {
  const [isScheduleValid, setIsScheduleValid] = useState(true)

  return (
    <SpaceBetween size="sm">
      <div>
        <div>Email subject</div>
        <InlineR3NotificationEditor
          onChange={(e) => {
            onChange("subject", stringToBase64(e))
          }}
          value={base64ToString(action.subject)}
          schema={templateAttributes}
          config={{
            customValues: {
              actionName: action.action_type,
            },
          }}
        />
      </div>
      <div>
        <div>Email body</div>
        <RichTextR3NotificationEditor
          schema={templateAttributes}
          templateOptions={templateAttributes[action.action_type]}
          initData={base64ToString(action.message)}
          onChange={(data) => {
            onChange("message", stringToBase64(data))
          }}
        />
      </div>
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => {
          onChange("matching_method", e.target.value)
        }}
        options={NOTIFICATION_OPTIONS}
        containerPadding="0"
      />
      {allowPersonListAttachment && (
        <PersonListAttachmentToggle
          checked={action.send_persons_as_attachment}
          onChange={(e) => {
            onChange("send_persons_as_attachment", e.checked)
          }}
        />
      )}
      <div>
        <Typography.H3>Schedule</Typography.H3>
        <Scheduler
          scheduler={action.run_options?.schedule}
          onChange={(value) => {
            onChange("run_options", { schedule: value })
          }}
          isValid={(isValid) => setIsScheduleValid(isValid)}
        />
        {!isScheduleValid && (
          <div style={{ color: theme.text.color.error }}>
            Schedule is invalid
          </div>
        )}
      </div>
    </SpaceBetween>
  )
}
