//libraries
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"

// components
import PageHeader from "@src/components/PageHeader"
import DropdownContent from "@src/components/DropdownContent"
import Loading from "@src/scenes/AdminDashboardContainer/components/Loading/Loading"
import FirstQuestion from "./components/FirstQuestion"
import SecondQuestion from "./components/SecondQuestion"

// actions
import * as actions from "@src/services/actions"

// utilities
import {
  loadState,
  getState,
  extractBehaviorsStatusData,
  getLastUpdateTimeLabel,
} from "./utils"
import { useActions } from "@src/utils/redux"

import { BREADCRUMB_LINKS } from "./constants"

import {
  getSecurityScore,
  getSecurityScoreFromLastMonth,
  getRiskiestDepartment,
} from "@src/services/redux/scores/selectors"
import {
  setHierarchicalScoresMonthly,
  setScoresByDepartment,
} from "@src/services/redux/scores/actions"
import ThirdQuestion from "./components/ThirdQuestion"
import BreakdownSelectorTitle from "./components/SecondQuestion/BreakdownSelectorTitle"
import { getLastScoresUpdate } from "@src/services/redux/company/selectors"
import { darklyGetFlag } from "@src/utils/darkly"

function Dashboard(props) {
  const { history } = props

  const visionItemsThatIsAccessibleByTheCustomer = darklyGetFlag(
    "vision-items-that-is-accessible-by-the-customer",
  )

  const buildedActions = useActions({
    ...actions,
    setHierarchicalScoresMonthly,
    setScoresByDepartment,
  })

  const {
    riskDistributions,
    loadedCount,
    monthlyScore,
    isHierarchyScoresMonthlyLoaded,
  } = useSelector((state) => getState(state, { history }))

  const [activeBreakdownLevel, setActiveBreakdownLevel] = useState("Individual")
  const [lastScoreUpdate, setLastScoreUpdate] = useState(null)

  const onChangeActiveBreakdownLevel = (newBreakdownLevel) => {
    setActiveBreakdownLevel(newBreakdownLevel)
  }

  const getBreadCrumbLinks = useCallback(
    () =>
      BREADCRUMB_LINKS.map((link) =>
        link.label === "Vision"
          ? {
              ...link,
              link: (visionItemsThatIsAccessibleByTheCustomer || []).includes(
                "Dashboard",
              )
                ? "/engagement/vision2/dashboard"
                : link.link,
            }
          : link,
      ),
    [visionItemsThatIsAccessibleByTheCustomer],
  )

  useEffect(() => {
    loadState({ actions: buildedActions, history })
    ;(async () => {
      const lastScoreUpdateDate = await getLastScoresUpdate()
      setLastScoreUpdate(lastScoreUpdateDate)
    })()
  }, [])

  if (!loadedCount || !isHierarchyScoresMonthlyLoaded) return <Loading />

  const behaviorsStatusData = extractBehaviorsStatusData(monthlyScore)
  const securityScore = getSecurityScore()
  const securityScoreLstMonth = getSecurityScoreFromLastMonth()
  const riskiestDepartment = getRiskiestDepartment()

  const firstQuestion = {
    title: "How strong is my organization’s current security posture?",
    content: (
      <FirstQuestion
        securityScore={securityScore}
        securityScoreLstMonth={securityScoreLstMonth}
        riskDistributions={riskDistributions}
        securityBreakDownBehavior={behaviorsStatusData}
        riskiestDepartment={riskiestDepartment}
        history={history}
      />
    ),
  }

  const secondQuestion = {
    title: (
      <BreakdownSelectorTitle
        onChangeActiveBreakdownLevel={onChangeActiveBreakdownLevel}
        activeBreakdownLevel={activeBreakdownLevel}
      />
    ),
    content: <SecondQuestion breakdownLevel={activeBreakdownLevel} />,
  }

  const thirdQuestion = {
    title: "How is my organization performing over time?",
    content: <ThirdQuestion />,
  }

  const dropDownItems = [firstQuestion, secondQuestion, thirdQuestion]

  return (
    <>
      <PageHeader
        breadcrumbLinks={getBreadCrumbLinks()}
        title="Security Summary"
        lastUpdatedTimeLabel={getLastUpdateTimeLabel(lastScoreUpdate)}
      />
      <DropdownContent
        items={dropDownItems}
        activeBreakdownLevel={activeBreakdownLevel}
      />
    </>
  )
}

Dashboard.propTypes = {
  history: PropTypes.shape({}).isRequired,
}
export default Dashboard
