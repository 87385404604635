import { ActionConfig, RequiredActionPayload } from "./actionConfigTypes"

import sendEmailNotificationToIndividualsConfig from "./sendEmailNotificationToIndividuals"
import sendEmailNotificationToManagersConfig from "./sendEmailNotificationToManagers"
import sendEmailNotificationToCustomConfig from "./sendEmailNotificationToCustom"

import sendSlackNotificationToIndividualsConfig from "./sendSlackNotificationToIndividuals"
import sendSlackNotificationToManagersConfig from "./sendSlackNotificationToManagers"
import sendSlackNotificationToChannelsConfig from "./sendSlackNotificationToChannels"
import sendSlackNotificationToCustomConfig from "./sendSlackNotificationToCustom"

import sendMicrosoftTeamsNotificationToIndividualsConfig from "./sendMicrosoftTeamsNotificationToIndividuals"
import sendMicrosoftTeamsNotificationToManagersConfig from "./sendMicrosoftTeamsNotificationToManagers"
import sendMicrosoftTeamsNotificationToChannelsConfig from "./sendMicrosoftTeamsNotificationToChannels"
import sendMicrosoftTeamsNotificationToCustomConfig from "./sendMicrosoftTeamsNotificationToCustom"

import sendToOutboundWebhookConfig from "./sendToOutboundWebhook"

import addElevateSecurityUsersToGroupsConfig from "./addElevateSecurityUsersToGroups"
import removeElevateSecurityUsersFromGroupsConfig from "./removeElevateSecurityUsersFromGroups"

import addAzureAdUsersToGroupsConfig from "./addAzureAdUsersToGroups"
import removeAzureAdUsersFromGroupsConfig from "./removeAzureAdUsersFromGroups"

import addCiscoDuoUsersToGroupsConfig from "./addCiscoDuoUsersToGroups"
import removeCiscoDuoUsersFromGroups from "./removeCiscoDuoUsersFromGroups"

import updateAzureAdCapUsersConfig from "./updateAzureAdCapUsers"
import updateAzureAdCapGroupsConfig from "./updateAzureAdCapGroups"
import updateAzureAdCapApplicationsConfig from "./updateAzureAdCapApplications"
import updateAzureAdCapConfig from "./updateAzureAdCap"

import updateCiscoDuoCapConfig from "./updateCiscoDuoCap"

import updateAzureEnterpriseApplicationsUsersConfig from "./updateAzureEnterpriseApplicationsUsers"
import updateAzureEnterpriseApplicationsGroupsConfig from "./updateAzureEnterpriseApplicationsGroups"

import confirmAzureAdCompromisedUser from "./confirmAzureAdCompromisedUser"

import confirmCiscoDuoCompromisedUser from "./confirmCiscoDuoCompromisedUser"

import executeSailpointWorkflowConfig from "./executeSailpointWorkflow"

import tagIncidentsCrowdstrikeConfig from "./tagIncidentsCrowdstrike"

export const ALL_ACTION_CONFIGS: ActionConfig<any>[] = [
  sendEmailNotificationToIndividualsConfig,
  sendEmailNotificationToManagersConfig,
  sendEmailNotificationToCustomConfig,
  sendSlackNotificationToIndividualsConfig,
  sendSlackNotificationToManagersConfig,
  sendSlackNotificationToChannelsConfig,
  sendSlackNotificationToCustomConfig,
  sendMicrosoftTeamsNotificationToIndividualsConfig,
  sendMicrosoftTeamsNotificationToManagersConfig,
  sendMicrosoftTeamsNotificationToChannelsConfig,
  sendMicrosoftTeamsNotificationToCustomConfig,
  sendToOutboundWebhookConfig,
  addElevateSecurityUsersToGroupsConfig,
  removeElevateSecurityUsersFromGroupsConfig,
  addAzureAdUsersToGroupsConfig,
  removeAzureAdUsersFromGroupsConfig,
  addCiscoDuoUsersToGroupsConfig,
  removeCiscoDuoUsersFromGroups,
  updateAzureAdCapUsersConfig,
  updateAzureAdCapGroupsConfig,
  updateAzureAdCapApplicationsConfig,
  updateAzureAdCapConfig,
  updateCiscoDuoCapConfig,
  updateAzureEnterpriseApplicationsUsersConfig,
  updateAzureEnterpriseApplicationsGroupsConfig,
  confirmAzureAdCompromisedUser,
  confirmCiscoDuoCompromisedUser,
  executeSailpointWorkflowConfig,
  tagIncidentsCrowdstrikeConfig,
]

/*
 * The way we store actions on the server may not match the way we present the
 * actions to the user on the front-end when creating/editing an R3. Here we use
 * the action configs to convert from server representation of the action list
 * to its user-facing representation.
 */
export function convertServerActionsToUserFacingActions(
  serverActions: RequiredActionPayload[],
  actionConfigs = ALL_ACTION_CONFIGS,
): RequiredActionPayload[] {
  let userFacingActions = serverActions.slice()
  actionConfigs.forEach((config) => {
    if (
      config.convertServerActionsToUserFacingAction &&
      config.isRelatedServerAction
    ) {
      const relatedServerActions = serverActions.filter(
        config.isRelatedServerAction,
      )
      if (relatedServerActions.length > 0) {
        const userFacingAction =
          config.convertServerActionsToUserFacingAction(relatedServerActions)
        const insertionIndex = userFacingActions.findIndex(
          (action) => action === relatedServerActions[0],
        )
        userFacingActions.splice(insertionIndex, 0, userFacingAction)
        userFacingActions = userFacingActions.filter(
          (action) => !relatedServerActions.includes(action),
        )
      }
    }
  })
  return userFacingActions
}

/*
 * The way we preset actions to the user when creating/editing and R3 does not
 * necessarily match how we store the actions on the server.  Here we use the
 * action configs to convert from user-facing representation to server
 * representation before sending the R3 payload to the server.
 */
export function convertUserFacingActionsToServerActions(
  userFacingActions: RequiredActionPayload[],
  actionConfigs = ALL_ACTION_CONFIGS,
): RequiredActionPayload[] {
  return userFacingActions.flatMap((action) => {
    const config = actionConfigs.find(
      (config) => config.defaultPayload.action_type === action.action_type,
    )
    if (config?.convertUserFacingActionToServerActions) {
      return config.convertUserFacingActionToServerActions(action)
    } else {
      return [action]
    }
  })
}
