import React from "react"
import { SkeletonLoader } from "@src/utils/skeleton"
import { LatestEventsTableSkeletonWrapper } from "./styles"

export const LatestEventsTableSkeleton = () => (
  <LatestEventsTableSkeletonWrapper>
    <SkeletonLoader height={25} />
    <SkeletonLoader height={25} />
    <SkeletonLoader height={25} />
    <SkeletonLoader height={25} />
    <SkeletonLoader height={25} />
  </LatestEventsTableSkeletonWrapper>
)
