import React, { useState } from "react"

import {
  IconAction,
  SkeletonLoader,
} from "@elevate_security/elevate-component-library"

import {
  ManageCriticalityWrapper,
  ValueBox,
  CheckIcon,
  CrossIcon,
} from "./ManageCriticality.styles"
import { humanize } from "@src/utils/string"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { BASE_EP_API_URL } from "@src/constants"
import fetcher from "@src/services/fetcher"
import { showToast } from "@src/services/redux/toasts/actions"
import { useDispatch } from "react-redux"

const options = [
  {
    label: "Critical",
    value: "critical",
  },
  {
    label: "Not critical",
    value: "not_critical",
  },
  {
    label: "Not set",
    value: "not_set",
  },
]

export const ManageCriticality = (props) => {
  const { criticality, applicationId, onUpdate } = props
  const dispatch = useDispatch()

  const [enableEdit, setEnableEdit] = useState(false)
  const [value, setValue] = useState(criticality)
  const [isLoading, setIsLoading] = useState(false)

  const handleSave = async () => {
    const params = {
      [applicationId]: value,
    }
    setIsLoading(true)
    try {
      await fetcher.post(
        `${BASE_EP_API_URL()}/intsync/azuread/enterprise-applications/criticality/`,
        params,
      )
      setEnableEdit(false)
      setIsLoading(false)
      onUpdate({ applicationId, criticality: value })
    } catch (error) {
      setIsLoading(false)
      dispatch(
        showToast("Failed to update criticality, please try again", "error"),
      )
    }
  }

  if (isLoading) {
    return (
      <ManageCriticalityWrapper>
        <SkeletonLoader width={160} height={25} />
      </ManageCriticalityWrapper>
    )
  }

  return (
    <ManageCriticalityWrapper>
      {!enableEdit && (
        <SpaceBetween direction="row" alignItems="center" gap="16px">
          <ValueBox>{humanize(criticality)}</ValueBox>
          <div>
            <IconAction
              name="Pencil"
              onClick={(e) => {
                e.stopPropagation()
                setEnableEdit(applicationId)
              }}
              size="xsm"
              fill="#959DA8"
            />
          </div>
        </SpaceBetween>
      )}
      {enableEdit && (
        <SpaceBetween direction="row" alignItems="center" gap="16px">
          <div>
            {/* <CustomSelect
              id="criticality-custom-select"
              placeholder="Select a criticality"
              defaultValue={value}
              onChange={option => setValue(option)}
              options={options}
            /> */}
            <select
              value={value}
              onChange={(event) => setValue(event.target.value)}
            >
              {options?.map((op) => (
                <option value={op.value}>{op.label}</option>
              ))}
            </select>
          </div>
          <SpaceBetween direction="row" gap="8px">
            <CheckIcon
              name="CircleCheckSolid"
              onClick={(e) => {
                e.stopPropagation()
                handleSave()
              }}
              size="xsm"
            />
            <CrossIcon
              name="CircleXSolid"
              onClick={(e) => {
                e.stopPropagation()
                setValue(criticality)
                setEnableEdit(false)
              }}
              size="xsm"
            />
          </SpaceBetween>
        </SpaceBetween>
      )}
    </ManageCriticalityWrapper>
  )
}
