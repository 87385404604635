import React, { useCallback, memo } from "react"
import PropTypes from "prop-types"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"

// Components
import {
  BehaviorsBreakdownChartWrapper,
  Row,
  LabelBar,
  ProgressBar,
  ScoreValue,
  ScoreTrend,
  ScoreIcon,
} from "./styles"
import { parseScoreChange } from "./utils"
import { getBehaviorsIconAndColorByScore } from "@src/utils/colors"

function BehaviorsBreakdownChart(props) {
  // Data
  const { behaviorsStatusData: data } = props

  const renderItem = useCallback(
    ({ behaviorName, behaviorScore, behaviorScoreChange }) => {
      const { icon, color } =
        getBehaviorsIconAndColorByScore(behaviorScoreChange)
      return (
        <Row key={behaviorName}>
          <LabelBar>{behaviorName}</LabelBar>
          <ProgressBar value={behaviorScore} />
          <ScoreValue>{behaviorScore}</ScoreValue>
          <ScoreIcon>
            <Icon name={icon} fill={color} />
          </ScoreIcon>
          <ScoreTrend color={color}>
            {parseScoreChange(behaviorScoreChange)}
          </ScoreTrend>
        </Row>
      )
    },
    [],
  )

  return (
    <BehaviorsBreakdownChartWrapper>
      {data.map(renderItem)}
    </BehaviorsBreakdownChartWrapper>
  )
}

BehaviorsBreakdownChart.propTypes = {
  behaviorsStatusData: PropTypes.arrayOf(
    PropTypes.shape({
      behaviorName: PropTypes.string,
      behaviorScore: PropTypes.number,
      behaviorScoreChange: PropTypes.number,
    }),
  ).isRequired,
}

export default memo(BehaviorsBreakdownChart)
