import styled from "styled-components"

export const CiscoDuoConditionalAccessPolicyWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`

export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  input:disabled {
    background-color: #f5f6f8;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  [class^="Togglestyles__Content"] {
    background-color: #959da8;
  }
  span {
    margin-top: 2px;
  }
`

export const ToggleWrapperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
  margin-left: 8px;
`

export const CiscoDuoConditionalAccessPolicyControls = styled.div`
  padding: 5px 0 0 55px;
  .section-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
    color: #34383c;
  }
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 28px;
    margin: 16px 0px 28px 2px;

    [class^="ComboBoxstyles__SelectContainer"] {
      margin: 0;
      width: 317px;
    }

    [class*="CustomSelectContainer"] {
      width: 300px;
      padding: 0px;
      margin: 0px 10px 16px !important;
      background-color: #ffffff;
      input {
        cursor: pointer;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
    }
    input[type="radio"]:disabled,
    input[type="checkbox"]:disabled {
      background-color: #c9cfd6;
      cursor: not-allowed;
      border-color: #c9cfd6;
    }
    label {
      margin-bottom: 0px;
    }
    li {
      white-space: initial !important;
    }
  }
  .m-l-2 {
    margin-left: 32px;
  }
  .m-b {
    margin-bottom: 8px;
  }
  .flex-start {
    align-items: flex-start;
  }
`

export const Label = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: 185px;
  margin: 0px;
`
