import styled from "styled-components"

export const EmptyScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6f8;
`

export const CrossContainer = styled.div`
  margin-top: 30px;
  .plus-icon svg line {
    stroke: #0c7d84 !important;
  }
`

export const TextContainer = styled.div`
  margin-top: 16px;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
`

export const ParagraphContainer = styled.div`
  display: flex;
  width: 43%;
  text-align: center;
  p {
    font-size: 14px;
    font-weight: 400;
  }

  a {
    color: #0c7d84 !important;
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
`
