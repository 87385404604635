import React, { memo, useRef, useState } from "react"
import PropTypes from "prop-types"

// Components
import StragglerStatusBar from "@src/components/StragglerStatusBar"
import BreakdownTable from "./BreakdownTable/index"
import { useNumberOfStragglers } from "@src/scenes/Dashboard/useNumberOfStragglers"
import { useIntersection } from "../../useIntersection"

function SecondQuestion(props) {
  const { breakdownLevel } = props
  const ref = useRef()
  const inViewport = useIntersection(ref, "0px")
  const stragglers = useNumberOfStragglers(inViewport)
  const [total, setTotal] = useState(stragglers?.[breakdownLevel]?.[1])

  // Straggler info currently only available for individuals
  function renderStragglerStatusBar() {
    return (
      <StragglerStatusBar
        numberOfStragglers={stragglers?.[breakdownLevel]?.[0]}
        totalNumber={total}
        stragglingEntity={breakdownLevel}
      />
    )
  }

  return (
    <div ref={ref}>
      {renderStragglerStatusBar()}
      {inViewport && (
        <BreakdownTable
          breakdownLevel={breakdownLevel}
          totalCount={total}
          setTotal={setTotal}
        />
      )}
    </div>
  )
}

SecondQuestion.propTypes = {
  stragglers: PropTypes.shape({}),
  breakdownLevel: PropTypes.string.isRequired,
}

export default memo(SecondQuestion)
