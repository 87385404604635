import React, { useRef } from "react"
import { RiskResponseRuleBodyWrapper, ActionErrorList } from "./styles"
import ActivityIndicator from "@src/components/ActivityIndicator"
import ToastsManager from "@src/components/ToastsManager"
import ConfirmationPage from "./components/ConfirmationPage"
import { SpaceBetween } from "@src/components/SpaceBetween"
import RiskRulesInfo from "./components/RuleInfoPage/RiskRulesInfo"
import CriteriaQueryBuilder from "./components/QueryBuilder/CriteriaQueryBuilder"
import { Button } from "@src/components/MimecastReskin"
import { TransparentOverlay } from "@src/components/TransparentOverlay"
import { EditR3Actions } from "./components/EditR3Actions"

import { useDispatch } from "react-redux"
import { showToast } from "@src/services/redux/toasts/actions"
import { DashboardCard } from "@src/components/DashboardCard"

export const RiskResponseRuleBody = ({
  saveButtons,
  actionOperations,
  isSaveAndContinueDisabled,
  totalHeight,
  pageNum,
  isActionsStep,
  selectedPolicy,
  selectedRule,
  isLoadingCriteria,
  editFlag,
  onSaveAndContinue,
  actionsSummaryList,
  scrollToStep,
}) => {
  const criteria = selectedRule?.criteria

  const dispatch = useDispatch()

  const showErrorMessage = (errors) => {
    dispatch(
      showToast(
        "Action configuration is invalid:",
        "error",
        <ActionErrorList>
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ActionErrorList>,
      ),
    )
  }

  /* eslint-disable react/jsx-key */
  const pages = [
    <DashboardCard title="Basic Info">
      <RiskRulesInfo />
    </DashboardCard>,
    <DashboardCard title="Criteria">
      <CriteriaQueryBuilder title="" />
    </DashboardCard>,
    <EditR3Actions
      actionOperations={actionOperations}
      actions={selectedRule?.actions ?? []}
      criteria={criteria}
      isAddEnabled={isActionsStep}
      showErrorMessage={showErrorMessage}
      onEditEnd={() => {
        scrollToStep("actions")
      }}
      render={({ addActionMenu, body }) => {
        return (
          <DashboardCard
            title="Actions"
            subtitle="Send a notification or take an outbound action"
            renderControls={() => addActionMenu}
          >
            {body}
          </DashboardCard>
        )
      }}
    />,
    <DashboardCard
      title="Summary"
      subtitle="Double check your work, even the best of us make mistakes!"
    >
      <ConfirmationPage
        selectedPolicy={{
          ...selectedPolicy,
          rule: {
            definition: {
              ...selectedRule,
              ...(actionsSummaryList ? { actions: actionsSummaryList } : {}),
            },
          },
        }}
      />
    </DashboardCard>,
  ]
  /* eslint-enable react/jsx-key */

  const lastScrollspyRef = useRef(null)

  return (
    <RiskResponseRuleBodyWrapper>
      {isLoadingCriteria && <ActivityIndicator active={true} />}
      <ToastsManager id="UpdateRiskGroupModal" />
      <div>
        <SpaceBetween>
          {pages.map((page, i) => {
            return (
              <div
                id={`r3-scrollspy-${i}`}
                key={i}
                ref={i === pages.length - 1 ? lastScrollspyRef : null}
              >
                <TransparentOverlay
                  when={pageNum !== i}
                  activationElement={
                    (editFlag || i < pageNum) && !isSaveAndContinueDisabled ? (
                      <Button
                        onClick={() => {
                          onSaveAndContinue({ nextPage: i })
                        }}
                      >
                        {i === pages.length - 1 ? "Jump to Summary" : "Edit"}
                      </Button>
                    ) : null
                  }
                >
                  <SpaceBetween size="sm">
                    {page}
                    {pageNum === i && saveButtons}
                  </SpaceBetween>
                </TransparentOverlay>
              </div>
            )
          })}
        </SpaceBetween>
      </div>
      <div
        style={{
          height: `${Math.max(
            totalHeight - (lastScrollspyRef.current?.offsetHeight || 0),
            0,
          )}px`,
        }}
      />
    </RiskResponseRuleBodyWrapper>
  )
}
