import React from "react"
import { SkeletonLoader } from "@elevate_security/elevate-component-library"

import { ConditionLoader, RowLoader } from "../styles"

export const QueryBuilderLoader = () => (
  <div>
    <ConditionLoader>
      <SkeletonLoader width={100} height={40} />
      <SkeletonLoader width={32} height={32} />
    </ConditionLoader>
    <RowLoader>
      <SkeletonLoader width={240} height={32} />
      <SkeletonLoader width={240} height={32} />
      <SkeletonLoader width={240} height={32} />
      <SkeletonLoader width={240} height={32} />
    </RowLoader>
    <ConditionLoader margin="8px 0px 8px 40px">
      <SkeletonLoader width={100} height={40} />
      <SkeletonLoader width={32} height={32} />
    </ConditionLoader>
    <RowLoader margin="8px 0px 8px 40px">
      <SkeletonLoader width={240} height={32} />
      <SkeletonLoader width={240} height={32} />
      <SkeletonLoader width={240} height={32} />
      <SkeletonLoader width={240} height={32} />
    </RowLoader>
  </div>
)
