export const setLocalStorage = (key, value) => {
  if (localStorage) localStorage.setItem(key, value)
}

export const getLocalStorage = (key) =>
  localStorage ? JSON.parse(localStorage.getItem(key)) : null

export const setSessionStorage = (key, value) => {
  if (sessionStorage) sessionStorage.setItem(key, value)
}
