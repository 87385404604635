import styled from "styled-components"

export const WrapperIcon = styled.span`
  &:hover {
    svg line {
      stroke: #0c7d84;
    }
    svg path {
      fill: #0c7d84;
    }
  }
`
