import { SpacingX } from "@src/components/SpacingX"
import styled from "styled-components"

export const Label = styled.label`
  font-weight: bold;
  margin: 0;
`

export const TimePeriodSelectWrapper = styled(SpacingX).attrs({
  alignItems: "center",
})`
  [class^="inputstyles__InputContainer"] input {
    background-color: revert;
  }
`
