import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import {
  Icon,
  theme,
  IconAction,
} from "@elevate_security/elevate-component-library"
import { ButtonLink, Tabs, Tab } from "@src/components/MimecastReskin"
import {
  DropdownWrapper,
  DropdownItem,
  DropdownItemContent,
  DropdownTitleWrapper,
  DropdownButton,
  Column,
  DateDiv,
  RuleDatesInfoDiv,
  PolicyTitle,
  ItemStatus,
  StyledDiv,
  TitleStatusContainer,
  DateAndTrashContainer,
  IsTestMessage,
  StrongTitle,
  ActiveRule,
  RuleDescription,
  TestActionLogContainer,
  StrongTitleTestModeDiv,
  ExclamationIcon,
  StyledText,
  LinkWrapper,
  AuditLogWrapper,
  ActionsRow,
  PolicyDetailContainer,
} from "./styles"
import { formatDate, utcAndTzToTime } from "@src/utils/dates"
import { PolicyDetailV2 } from "./PolicyDetail-V2"
import {
  clearErrorValidationsInfo,
  deletePolicyRedux,
  updateCreatedPolicyRedux,
} from "@src/services/redux/policies/actions"
import { useDispatch } from "react-redux"
import { useRequest } from "ahooks"
import ConfirmationModal from "../ConfirmationModal"
import { showToast } from "@src/services/redux/toasts/actions"
import ToastsManager from "@src/components/ToastsManager"
import { editRule } from "@src/services/apis/policies"
import EmailReviewModal from "@src/scenes/RiskDetectionRulesScreen/EmailReviewModal"
import DeleteModal from "@src/scenes/RiskDetectionRulesScreen/DeleteModal"
import CloneModal from "@src/scenes/RiskDetectionRulesScreen/CloneModal"
import EditModal from "@src/scenes/RiskDetectionRulesScreen/EditModal"
import { SkeletonLoader } from "@src/utils/skeleton"
import { useHistory } from "react-router-dom"
import { EventLogs } from "@src/components/EventLogs"
import { humanize } from "@src/utils/string"
import { RISK_RESPONSE_RULES } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import ActivityIndicator from "@src/components/ActivityIndicator"
import ActionsDropdown from "../ActionsDropdown"
import {
  POLICY_STATE_LABELS_MAP,
  POLICY_STATE_MAP,
} from "@src/scenes/RiskResponseRuleCreateScreen/constants"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { THEME_PRIMARY } from "@src/theme"

const STATE_LABEL_STYLES = {
  [POLICY_STATE_MAP.DRAFT]: {
    backgroundColor: theme.badge.bg.blue,
  },
  [POLICY_STATE_MAP.PAUSED]: {
    backgroundColor: theme.badge.bg.gray,
  },
  [POLICY_STATE_MAP.TEST]: {
    backgroundColor: theme.badge.bg.yellow,
  },
  [POLICY_STATE_MAP.ACTIVE]: {
    backgroundColor: THEME_PRIMARY,
    color: theme.colors.white,
  },
  [POLICY_STATE_MAP.ERROR]: {
    backgroundColor: theme.colors.bg.errorInverse,
    color: theme.colors.white,
  },
}

const editPolicy = (id) => (params) => {
  return editRule(id, params)
}

export const DropdownCard = ({ item, id }) => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [showEmailPreview, setEmailPreview] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const dispatch = useDispatch()
  const [showCloneModal, setShowCloneModal] = useState(false)
  const mainRuleData = item?.rule?.definition ?? {}

  const RULE_TITLE = RISK_RESPONSE_RULES.slice(0, -1)

  const handleOnToggleReview = useCallback(({ event, chosenItem }) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedItem(chosenItem)
    setShowEmailModal(true)
  }, [])

  const handleOnToggleItem = ({ event }) => {
    event.preventDefault()
    event.stopPropagation()
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
  }

  const handleTestLinkClick = async () => {
    if (!isOpen) {
      setIsOpen(true)
    }
    setSelectedTab(1)
    setTimeout(() => {
      document
        .getElementById(id)
        .scrollIntoView({ behavior: "smooth", block: "start" })
    }, 500)
  }

  const handleOnToggleConfirmation = ({ event }) => {
    event.preventDefault()
    event.stopPropagation()
    setShowModal(!showModal)
  }

  const onCancelConfirmationModal = () => {
    setShowModal(false)
    setEmailPreview(false)
  }

  const onCancelCloneModal = () => {
    setShowCloneModal(false)
  }

  const setDateInfoLine = (dateInfo) => {
    if (!dateInfo) return "N/A"

    const formattedDateTime = formatDate({
      date: dateInfo,
      format: "MMM DD, YYYY h:mm:ss A",
    })

    return `${formattedDateTime}`
  }

  const { loading: updatingPolicy, run: updatePolicy } = useRequest(
    editPolicy(item?.id),
    {
      manual: true,
      onSuccess: (response) => {
        if (response?.error) {
          dispatch(showToast(response?.error?.response?.data?.detail, "error"))
        } else {
          const updatedPolicy = {
            ...item,
            ...response?.data,
          }
          dispatch(updateCreatedPolicyRedux(item?.id, updatedPolicy))
          dispatch(clearErrorValidationsInfo())
          // Notify on policy activation
          if (updatedPolicy.state === POLICY_STATE_MAP.ACTIVE) {
            dispatch(
              showToast(`${humanize(RULE_TITLE)} successfully activated!`),
            )
          }
          // Notify on policy deletion
          if (
            updatedPolicy.state === POLICY_STATE_MAP.DELETED ||
            // If the policy deleted was a recommendation, it will be put back
            // to DISABLED so the user has the option to enable it again; so
            // DISABLED here also indicates a successful deletion
            updatedPolicy.state === POLICY_STATE_MAP.DISABLED
          ) {
            dispatch(deletePolicyRedux(item))
            dispatch(showToast(`${humanize(RULE_TITLE)} successfully removed.`))
          }
        }
      },
    },
  )

  const onCloseConfirmationModal = async (event) => {
    event.stopPropagation()
    if (
      mainRuleData &&
      mainRuleData.criteria[0].length !== 0 &&
      mainRuleData &&
      mainRuleData.actions.length !== 0
    ) {
      // Close modal because we have activity indicator on parent page
      setShowModal(false)
      // Update policy state=active
      updatePolicy({
        state: POLICY_STATE_MAP.ACTIVE,
      })
    } else {
      setShowModal(false)
      dispatch(
        showToast(
          `Cannot activate ${humanize(RULE_TITLE)} without action or criteria`,
          "error",
        ),
      )
    }
  }

  const handleOnDelete = (item) => {
    setShowDeleteModal(true)
    setSelectedItem(item)
  }
  const handleOnClone = () => setShowCloneModal(true)
  const handleStateChange = (targetState) => {
    updatePolicy({
      state: targetState,
    })
  }

  const handleEdit = (item) => {
    setSelectedItem(item)
    // If state=active show confirmation modal
    if (item.state === POLICY_STATE_MAP.ACTIVE) {
      setShowEditModal(true)
    } else {
      navigateToEditPage(item.id)
    }
  }

  const navigateToEditPage = (id) => {
    history.push(
      `/engagement/vision2/risk-response-engine/risk-response-rules/edit-rule/${id}`,
    )
  }

  const isTest = item?.state === POLICY_STATE_MAP.TEST

  const renderTitle = (item) => (
    <StyledDiv>
      <TitleStatusContainer>
        <PolicyTitle>{item.name}</PolicyTitle>
        <ItemStatus style={STATE_LABEL_STYLES[item?.state]}>
          {POLICY_STATE_LABELS_MAP[item?.state]}
        </ItemStatus>
      </TitleStatusContainer>
      <DateAndTrashContainer>
        <DateDiv>
          Last updated <b>{setDateInfoLine(item.updated_datetime)}</b>
        </DateDiv>
        <ActionsRow>
          <div>
            <ActionsDropdown
              item={item}
              onDelete={handleOnDelete}
              onClone={handleOnClone}
              onStateChange={handleStateChange}
              onEdit={handleEdit}
            />
          </div>
          <div>
            <IconAction
              name="Pencil"
              onClick={(e) => {
                e.stopPropagation()
                handleEdit(item)
              }}
              size="xsm"
              fill="#959DA8"
              tooltipText="Edit rule"
            />
          </div>
        </ActionsRow>
        <ToastsManager id="UpdateRiskGroupModal" />
      </DateAndTrashContainer>
    </StyledDiv>
  )

  const renderItems = () => (
    <DropdownItem>
      <DropdownTitleWrapper
        onClick={(event) =>
          handleOnToggleItem({
            event,
          })
        }
      >
        {renderTitle(item)}
        <Column>
          <DropdownButton
            onClick={(event) => handleOnToggleItem({ event })}
            isOpen={isOpen}
          >
            <Icon
              name="CaretUp"
              size="xxsm"
              fill={theme.colors.gray.opaque[500]}
            />
          </DropdownButton>
        </Column>
      </DropdownTitleWrapper>
      <SpaceBetween size="sm" gap={!isOpen && "0px"}>
        <SpaceBetween direction="row">
          <SpaceBetween alignItems="flex-start" size="xs">
            {item.description && (
              <RuleDescription>{item.description}</RuleDescription>
            )}
            {isOpen && !item.description && (
              <RuleDescription>No description provided</RuleDescription>
            )}
            {isTest && (
              <IsTestMessage>
                <StrongTitle>
                  This rule is in test mode.{" "}
                  <LinkWrapper onClick={() => handleTestLinkClick()}>
                    Check out how this rule would affect your org
                  </LinkWrapper>
                </StrongTitle>
                <ActiveRule>
                  <ButtonLink
                    onClick={(event) => handleOnToggleConfirmation({ event })}
                  >
                    Confirm & Activate rule
                  </ButtonLink>
                </ActiveRule>
              </IsTestMessage>
            )}
          </SpaceBetween>
          {isOpen && item.last_evaluation && item.state !== "draft" && (
            <RuleDatesInfoDiv>
              <div>Last Action Executed</div>
              <div>
                <b>{setDateInfoLine(item.last_action_executed)}</b>
              </div>
              <div>Last Action Queued</div>
              <div>
                <b>{setDateInfoLine(item.last_action_queued)}</b>
              </div>
              <div>Last Evaluated</div>
              <div>
                <b>{setDateInfoLine(item.last_evaluation)}</b>
              </div>
            </RuleDatesInfoDiv>
          )}
        </SpaceBetween>
        <DropdownItemContent isOpen={isOpen}>
          {isOpen && (
            <Tabs
              onChange={(i) => setSelectedTab(i)}
              currentTabIndex={selectedTab}
            >
              <Tab title="Rule Definition">
                <PolicyDetailContainer>
                  {selectedTab === 0 && isLoading && (
                    <div
                      style={{
                        display: "grid",
                        gap: "8px",
                        height: "100px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <SkeletonLoader height={100} />
                    </div>
                  )}
                  {selectedTab === 0 && !isLoading && (
                    <PolicyDetailV2
                      mainRuleData={mainRuleData}
                      policy={item}
                      onClick={handleOnToggleReview}
                    />
                  )}
                </PolicyDetailContainer>
              </Tab>
              <Tab title={`${isTest ? "Test Mode Action Log" : "Action Log"}`}>
                {isTest && (
                  <TestActionLogContainer>
                    <ExclamationIcon>
                      <Icon name="CircleExclamation" size="sm" fill="#DE6900" />
                    </ExclamationIcon>
                    <StrongTitleTestModeDiv>
                      This rule is currently running in test mode.{" "}
                      <StyledText>
                        None of the following rule responses have actually been
                        performed. You’re just seeing a sneak peek of what this
                        rule would have done if this rule had actively started
                        running on{" "}
                        {utcAndTzToTime({
                          date: item.updated_datetime,
                          timezone: "America/Los_Angeles",
                        })?.format("MMM D, YYYY h:mm A [PST]")}
                        .
                      </StyledText>
                    </StrongTitleTestModeDiv>
                  </TestActionLogContainer>
                )}
                {isOpen && selectedTab === 1 && (
                  <AuditLogWrapper>
                    <EventLogs
                      type="action_log"
                      hideFilters={true}
                      policy_id={item.id}
                      order_by="created_datetime desc"
                      showIndividualColumn={true}
                      disableSearch={true}
                      disableFilter={true}
                      dragosaurMsg="There are no Action Logs available"
                      exportConfig={{
                        disable: true,
                      }}
                      enableAdditionalDetails={true}
                    />
                  </AuditLogWrapper>
                )}
              </Tab>
              <Tab title="Audit Log">
                {isOpen && selectedTab === 2 && (
                  <AuditLogWrapper>
                    <EventLogs
                      type="audit_log"
                      hideFilters={true}
                      policy_id={item.id}
                      order_by="created_datetime desc"
                      showIndividualColumn={false}
                      disableSearch={true}
                      disableFilter={true}
                      additionalNotDateField={[
                        "individuals_matched",
                        "total_individuals_matched_by_criteria",
                        "affected_individuals",
                      ]}
                      dragosaurMsg="There are no Audit Logs available"
                      exportConfig={{
                        disable: true,
                      }}
                      enableAdditionalDetails={true}
                    />
                  </AuditLogWrapper>
                )}
              </Tab>
            </Tabs>
          )}
          {!isOpen && (
            <>
              <SkeletonLoader height={20} marginTop={20}></SkeletonLoader>
              <SkeletonLoader height={100} marginTop={10} />
            </>
          )}
        </DropdownItemContent>
      </SpaceBetween>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          showEmailPreview={showEmailPreview}
          setEmailPreview={setEmailPreview}
          ruleName={item.name}
          itemSelected={item}
          mainRuleData={mainRuleData}
          queryString={true}
          onCancel={onCancelConfirmationModal}
          onClose={onCloseConfirmationModal}
        />
      )}
      {showEmailModal && (
        <EmailReviewModal
          policyId={item?.id}
          item={selectedItem}
          showModal={showEmailModal}
          queryRules={mainRuleData?.criteria}
          ruleName={item.name}
          onClose={() => {
            setShowEmailModal(false)
            setSelectedItem({})
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          item={selectedItem}
          showModal={showDeleteModal}
          onConfirm={(e) => {
            e.stopPropagation()
            setIsOpen(false)
            handleStateChange(POLICY_STATE_MAP.DELETED)
            setShowDeleteModal(false)
          }}
          onClose={() => {
            setShowDeleteModal(false)
            setSelectedItem({})
          }}
        />
      )}
      {showEditModal && (
        <EditModal
          item={selectedItem}
          showModal={showEditModal}
          onConfirm={() => {
            navigateToEditPage(item.id)
          }}
          onClose={() => {
            setShowEditModal(false)
            setSelectedItem({})
          }}
        />
      )}
      {showCloneModal && (
        <CloneModal
          showModal={showCloneModal}
          ruleName={item.name}
          item={item}
          onCancel={onCancelCloneModal}
          onClose={onCancelCloneModal}
        />
      )}
    </DropdownItem>
  )

  return (
    <DropdownWrapper id={id}>
      {updatingPolicy && <ActivityIndicator active={true} />}
      {renderItems()}
    </DropdownWrapper>
  )
}

DropdownCard.defaultProps = {
  item: {},
}

DropdownCard.propTypes = {
  item: PropTypes.shape({
    created_datetime: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    state: PropTypes.oneOf([
      POLICY_STATE_MAP.ACTIVE,
      POLICY_STATE_MAP.DRAFT,
      POLICY_STATE_MAP.TEST,
      POLICY_STATE_MAP.PAUSED,
      POLICY_STATE_MAP.DELETED,

      POLICY_STATE_MAP.DISABLED,
    ]),
    name: PropTypes.string,
    organization_nid: PropTypes.string,
    rules: PropTypes.array,
    updated_datetime: PropTypes.string,
  }),
}
