import styled from "styled-components"

export const Container = styled.div`
  opacity: 1;
  width: 100%;
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 250px;
  }
  .react-datepicker__time-container {
    width: 80px !important;
  }
  .react-datepicker__time-box {
    width: 79px !important;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    line-height: 38px;
    padding: 0 16px;
    border: 1px solid rgba(189, 197, 209, 0.5);
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    &:hover {
      border: 1px solid rgba(58, 70, 85, 0.3);
    }
    &:focus {
      border: 1px solid rgb(12, 125, 132);
      box-shadow: rgb(12, 125, 132) 0px 0px 3px;
    }
  }

  .react-datepicker__close-icon {
    ::after {
      background-color: rgb(12, 125, 132);
      padding: 1px 2px 1px 3px;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
`

export const ConditionLoader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: ${(props) => (props.margin ? props.margin : "8px 0px")};
`

export const RowLoader = styled.div`
  display: flex;
  gap: 16px;
  margin: ${(props) => (props.margin ? props.margin : "8px 0px")};
`

export const Hint = styled.span`
  position: absolute;
  top: 38px;
  font-size: 12px;
  color: #a9aeb5;
`
export const FieldWrapper = styled.div`
  position: relative;
`

export const Item = styled.div`
  display: flex;
  gap: 16px;
`

export const ComboBoxWrapper = styled.div`
  [class^="ComboBoxstyles__SelectContainer-sc-2zrjff-0"] {
    min-width: 250px;
    .css-g1d714-ValueContainer {
      padding: 2px 13px;
    }
  }
  .custom-react-select__menu {
    z-index: 1000;
  }
  [class^="Checkboxstyles__InputCheckBoxWrapper-sc-1oawj0u-4"] {
    input:checked::after {
      top: 2px;
      left: 5.5px;
      width: 4px;
      height: 9px;
    }
  }
`

export const SelectOptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 8px 0px;
`
