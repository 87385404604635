import moment from "moment"

const currentDateNumber = moment().date() // current date in number 10,11, etc
const currentMonthName = moment().format("MMMM") // current month name July etc
const currentDate = moment().format("YYYY-MM-DD") // e.g: 2023-07-21

export const OCCURRENCE = {
  ASAP: "run_now",
  ONE_TIME: "single_run",
  INTERVAL: "interval",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
}

export const humanizeCadenceMap = {
  [OCCURRENCE.ASAP]: "As soon as possible",
  [OCCURRENCE.ONE_TIME]: "Run once",
  [OCCURRENCE.INTERVAL]: "Every",
  [OCCURRENCE.DAY]: "Daily",
  [OCCURRENCE.WEEK]: "Weekly",
  [OCCURRENCE.MONTH]: "Monthly",
  [OCCURRENCE.YEAR]: "Annually",
}

export const occurrenceOptions = [
  {
    label: "One time",
    value: OCCURRENCE.ASAP,
  },
  {
    label: "Hourly intervals",
    value: OCCURRENCE.INTERVAL,
  },
  {
    label: "Daily",
    value: OCCURRENCE.DAY,
  },
  {
    label: "Weekly",
    value: OCCURRENCE.WEEK,
  },
  {
    label: "Monthly",
    value: OCCURRENCE.MONTH,
  },
  {
    label: "Annually",
    value: OCCURRENCE.YEAR,
  },
]

export const customHoursOptions = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: i,
}))

export const executionMinutesOptions = ["0", "15", "30", "45"]
export const monthDaysOptions = Array.from({ length: 31 }, (_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
}))
export const monthsOptions = [
  {
    label: "January",
    value: "January",
  },
  {
    label: "February",
    value: "February",
  },
  {
    label: "March",
    value: "March",
  },
  {
    label: "April",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "June",
  },
  {
    label: "July",
    value: "July",
  },
  {
    label: "August",
    value: "August",
  },
  {
    label: "September",
    value: "September",
  },
  {
    label: "October",
    value: "October",
  },
  {
    label: "November",
    value: "November",
  },
  {
    label: "December",
    value: "December",
  },
]

export const runningIntervalOptions = [
  {
    label: "Every hour",
    value: "1",
  },
  {
    label: "Every 2 hours",
    value: "2",
  },
  {
    label: "Every 3 hours",
    value: "3",
  },
  {
    label: "Every 4 hours",
    value: "4",
  },
  {
    label: "Every 6 hours",
    value: "6",
  },
  {
    label: "Every 8 hours",
    value: "8",
  },
  {
    label: "Every 12 hours",
    value: "12",
  },
  {
    label: "Every 24 hours",
    value: "24",
  },
  {
    label: "Custom",
    value: "custom",
  },
]

export const initialStateMap = {
  [OCCURRENCE.DAY]: {
    start_date: currentDate,
    cadence: OCCURRENCE.DAY,
    hour: "13",
    minute: 0,
  },
  [OCCURRENCE.WEEK]: {
    start_date: currentDate,
    cadence: OCCURRENCE.WEEK,
    day_of_week: "Monday,Tuesday,Wednesday,Thursday,Friday",
    hour: "13",
    minute: 0,
  },
  [OCCURRENCE.MONTH]: {
    start_date: currentDate,
    cadence: OCCURRENCE.MONTH,
    day: `${currentDateNumber}`,
    hour: "13",
    minute: 0,
  },
  [OCCURRENCE.YEAR]: {
    start_date: currentDate,
    cadence: OCCURRENCE.YEAR,
    month: currentMonthName,
    day: `${currentDateNumber}`,
    hour: "13",
    minute: 0,
  },
  [OCCURRENCE.INTERVAL]: {
    start_date: currentDate,
    cadence: OCCURRENCE.DAY,
    interval: "1",
    minute: 0,
  },
  [OCCURRENCE.ASAP]: {
    cadence: OCCURRENCE.ASAP,
  },
  [OCCURRENCE.ONE_TIME]: {
    cadence: OCCURRENCE.ONE_TIME,
    year: 2023,
    month: currentMonthName,
    day: `${currentDateNumber}`,
    hour: "13",
    minute: 0,
  },
}

export const sortedMonthMap = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}
