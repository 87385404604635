import React from "react"
import { useSelector } from "react-redux"

import BreakdownChart from "../BreakdownChart"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import { Row } from "../styles"
import { Dot } from "./styles"
import {
  getTeamScoreBreakdown,
  getHasDataLoaded,
} from "../../../../../services/redux/selectors"
const { Text } = Typography

const ScoreBreakdown = () => {
  const scoreBreakdown = useSelector(getTeamScoreBreakdown)
  const { teamDataLoaded, orgScoreLoaded } = useSelector(getHasDataLoaded)
  return (
    <>
      <Row margin="0 0 28px 0" style={{ color: "#34383C" }}>
        <Text
          style={{ marginRight: "auto", fontWeight: "bold", color: "#34383C" }}
        >
          Last 30 days
        </Text>
        <Dot color="#357e85" />
        <span>Your Team</span>
        <Dot color="#cee5e5" />
        <span>Your Organization</span>
      </Row>
      {teamDataLoaded &&
      orgScoreLoaded &&
      (!scoreBreakdown || !scoreBreakdown.length) ? (
        <Text style={{ fontSize: 16, textAlign: "center", marginTop: 50 }}>
          No scores found.
        </Text>
      ) : (
        <BreakdownChart behaviorsStatusData={scoreBreakdown} />
      )}
    </>
  )
}

export default ScoreBreakdown
