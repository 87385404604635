import React, { useState } from "react"
export const IndividualRiskAnalysisStateContext = React.createContext()
export const IndividualRiskAnalysisDispatchContext = React.createContext()
export const IndividualRiskAnalysisActionsContext = React.createContext()
import * as reputationApi from "@src/services/apis/reputation"
import { useSelector, useDispatch } from "react-redux"
import useRequest from "@ahooksjs/use-request"
import {
  setDepartments,
  setLocations,
  setRisks,
  setRisksNames,
  setBehaviorTypes,
  setJobTitle,
  setBusinessTitle,
  setBusinessUnit,
  setEsManagerId,
  setIsOnleave,
  setJobFamilyFunctionGroup,
  setJobFamilyFunctionName,
  setManagerNid,
  setPreferredLanguage,
  setPreferredName,
  setPreferredMiddleName,
  setPreferredNameLocalLang,
  setTimeType,
  setWorkLocationCountry,
  setWorkLocationGeo,
  setWorkLocationTimezone,
  setWorkerType,
  setWorkLocationBuilding,
  setWorkLocationCity,
  setWorkLocationName,
  setWorkSpace,
  setLengthOfService,
  setHireDate,
  setIsActive,
  setLeaveDate,
  setAlternativeUserId1,
  setAlternativeUserId2,
  setAvailableHRMetadataKeys,
} from "@src/services/redux/analysisIndividuals/actions"

/** actions */
export const getRisksAnalysisData = (dispatch) => async (params) => {
  const response = await reputationApi.getRisksAnalysisData(params)
  dispatch(setRisks(response?.data))
  return response
}

export const getRisksKeys = (dispatch) => async () => {
  const response = await reputationApi.getRisksKeys()
  const actionsNames = response?.data?.action_categories || []
  const sortedRiskNames =
    response?.data?.risk_categories?.sort(function (x, y) {
      if (x === "overall") {
        return -1
      } else if (y === "overall") {
        return 1
      }
      return 0
    }) || []
  const departments = response?.data?.departments || []
  const locations = response?.data?.locations || []
  const filters = sortedRiskNames.filter((item) => item !== "overall")

  dispatch(setJobTitle(response?.data?.job_title))
  dispatch(setBusinessTitle(response?.data?.business_title))
  dispatch(setBusinessUnit(response?.data?.business_unit))
  dispatch(setEsManagerId(response?.data?.es_manager_id))
  dispatch(setIsOnleave(response?.data?.is_onleave))
  dispatch(setJobFamilyFunctionGroup(response?.data?.job_family_function_group))
  dispatch(setJobFamilyFunctionName(response?.data?.job_family_function_name))
  dispatch(setManagerNid(response?.data?.manager_nid))
  dispatch(setPreferredLanguage(response?.data?.preferred_language))
  dispatch(setPreferredName(response?.data?.preferred_name))
  dispatch(setPreferredMiddleName(response?.data?.preferred_middle_name))
  dispatch(setPreferredNameLocalLang(response?.data?.preferred_name_local_lang))
  dispatch(setTimeType(response?.data?.time_type))
  dispatch(setWorkLocationCountry(response?.data?.work_location_country))
  dispatch(setWorkLocationGeo(response?.data?.work_location_geo))
  dispatch(setWorkLocationTimezone(response?.data?.work_location_timezone))
  dispatch(setWorkerType(response?.data?.worker_type))
  dispatch(setWorkLocationBuilding(response?.data?.work_location_building))
  dispatch(setWorkLocationCity(response?.data?.work_location_city))
  dispatch(setWorkLocationName(response?.data?.work_location_name))
  dispatch(setWorkSpace(response?.data?.work_space))
  dispatch(setLengthOfService(response?.data?.length_of_service))
  dispatch(setHireDate(response?.data?.hire_date))
  dispatch(setIsActive(response?.data?.is_active))
  dispatch(setLeaveDate(response?.data?.leave_date))
  dispatch(setAlternativeUserId1(response?.data?.alternative_user_id_1))
  dispatch(setAlternativeUserId2(response?.data?.alternative_user_id_2))

  dispatch(setDepartments(departments))
  dispatch(setLocations(locations))
  const allActionsName = [...actionsNames]
  dispatch(setRisksNames(sortedRiskNames, filters, allActionsName))
  dispatch(setBehaviorTypes(response?.data?.enabled_behaviors))
  dispatch(setAvailableHRMetadataKeys(response?.data?.hr_metadata_keys))
}

export default function IndividualRiskAnalysisContextProvider({ children }) {
  const state = useSelector((analysisIndividualState) =>
    analysisIndividualState.get("analysisIndividualReducer"),
  )
  const dispatch = useDispatch()
  const [actions] = useState({
    getRisksAnalysisData: getRisksAnalysisData(dispatch),
    getRisksKeys: getRisksKeys(dispatch),
  })

  useRequest(actions.getRisksKeys)
  return (
    <IndividualRiskAnalysisStateContext.Provider value={state}>
      <IndividualRiskAnalysisDispatchContext.Provider value={dispatch}>
        <IndividualRiskAnalysisActionsContext.Provider value={actions}>
          {children}
        </IndividualRiskAnalysisActionsContext.Provider>
      </IndividualRiskAnalysisDispatchContext.Provider>
    </IndividualRiskAnalysisStateContext.Provider>
  )
}

/** hooks */
function useIndividualRiskAnalysisState() {
  const context = React.useContext(IndividualRiskAnalysisStateContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualRiskAnalysisState must be used within a IndividualRiskAnalysisStateContext",
    )
  }
  return context
}

function useIndividualRiskAnalysisDispatch() {
  const context = React.useContext(IndividualRiskAnalysisDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualRiskAnalysisDispatch must be used within a IndividualRiskAnalysisDispatchContext",
    )
  }
  return context
}

function useIndividualRiskAnalysisActions() {
  const context = React.useContext(IndividualRiskAnalysisActionsContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualRiskAnalysisActions must be used within a IndividualRiskAnalysisActionsContext",
    )
  }
  return context
}

export {
  useIndividualRiskAnalysisState,
  useIndividualRiskAnalysisDispatch,
  useIndividualRiskAnalysisActions,
}
