import React from "react"

import { SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS } from "../../RiskRuleOutcome/constants"
import { EditSlackNotification } from "../actionRenderComponents/EditSlackNotification"
import { ActionGroups } from "./ActionGroups"
import { validateInstantMessageActionPayload } from "./validators/validateInstantMessageActionPayload"

import { ActionConfig } from "./actionConfigTypes"

type Payload = {
  action_type: typeof SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS
  matching_method: "snapshot" | "delta"
  message: string
}

const config: ActionConfig<Payload> = {
  label: "Individuals who meet the criteria",
  group: ActionGroups.SLACK,
  defaultPayload: {
    action_type: SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS,
    matching_method: "delta",
    message:
      "Hi {{people.first_name}} {{people.last_name}}! We'd like to update you that your risk posture has changed.",
  },
  validate: (payload) => {
    return validateInstantMessageActionPayload(payload)
  },
  isEnabled: (isServiceIntegrated) => isServiceIntegrated("slack"),
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <EditSlackNotification
        templateAttributes={templateAttributes}
        action={details}
        onChange={updateAction}
      />
    )
  },
}

export default config
