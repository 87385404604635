import {
  CUSTOM,
  INDIVIDUALS,
  MANAGERS,
  CHANNELS,
  OUTBOUND_WEBHOOK,
  SEND_SPLUNK_SYSLOG,
  SEND_VIA_WEB_HOOK,
  SERVICE_NOW,
  SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
  SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
  SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
  SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS,
  SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS,
  SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CHANNELS,
  SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM,
  SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS,
  SEND_SLACK_NOTIFICATION_TO_MANAGERS,
  SEND_SLACK_NOTIFICATION_TO_CHANNELS,
  SEND_SLACK_NOTIFICATION_TO_CUSTOM,
  CHANGE_SYSTEM_GROUP,
  SAIL_POINT_WORKFLOW,
  WORKFLOW_ID,
} from "./RiskRuleOutcome/constants"
import {
  addRemoveRuleAction,
  setSendViaSplunk,
  setSendViaWebhook,
  setServiceWebhook,
  setServiceWebhookMatchingMethod,
  setSplunkMatchingMethod,
  setWebhookMatchingMethod,
} from "./RiskRuleOutcome/utils"
import { showToast } from "@src/services/redux/toasts/actions"
import { closeToasts } from "./RuleInfoPage/utils"
import { humanize, isEmpty } from "@src/utils/string"
import {
  AZURE_REMOVE_INDIVIDUALS,
  AZURE_ADD_INDIVIDUALS,
  CISCO_DUO_ADD_INDIVIDUALS,
  CISCO_DUO_REMOVE_INDIVIDUALS,
  CONFIRM_AZUREAD_COMPROMISED_USER,
  UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY,
  REMOVE_GITHUB_REPOSITORIES_ACCESS,
  APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY,
  CONFIRM_CISCO_DUO_COMPROMISED_USERS,
  ELEVATESECURITY_GROUPS_ADD_INDIVIDUALS,
  ELEVATESECURITY_GROUPS_REMOVE_INDIVIDUALS,
  INCLUDE_USERS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
  EXCLUDE_USERS_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
  INCLUDE_GROUPS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
  EXCLUDE_GROUP_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
  TAGS,
  TAG_CROWDSTRIKE_FALCON_RISKY_USERS,
  ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_GROUPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_GROUPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_GROUPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_GROUPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION,
  REMOVE_USERS_FROM_AZUREAD_ENTERPRISE_APPLICATION,
  ADD_GROUPS_TO_AZUREAD_ENTERPRISE_APPLICATION,
  REMOVE_GROUPS_FROM_AZUREAD_ENTERPRISE_APPLICATION,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { getAzureADConditionalAccessPolicies } from "@src/services/apis/microsoftTeams"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import { getGroupsList } from "@src/services/apis/groups"
import {
  getCiscoDuoDropDownElements,
  getSailPointWorkFlowData,
} from "@src/services/apis/reputation"

export const validateMicrosoftTeamsNotification = (
  notification,
  setSelectedMicrosoftTeamsNotification,
) => {
  const totalMicrosoftTeamsMembers =
    notification?.microsoft_teams_members?.length || 0
  const totalMicrosoftTeamsChannels = notification?.channel_names?.length || 0
  let validationStatus = true
  if (
    (notification?.value === INDIVIDUALS || notification?.value === MANAGERS) &&
    isEmpty(notification?.message)
  ) {
    setSelectedMicrosoftTeamsNotification({
      ...notification,
      error: true,
      errorMessage: "Message is missing",
    })
    validationStatus = false
  } else if (
    notification?.value === CHANNELS &&
    (totalMicrosoftTeamsChannels === 0 || isEmpty(notification?.message))
  ) {
    setSelectedMicrosoftTeamsNotification({
      ...notification,
      error: true,
      errorMessage:
        "You must select at least one channel AND fill message for Microsoft Teams notification",
    })
    validationStatus = false
  } else if (
    notification?.value === CUSTOM &&
    totalMicrosoftTeamsMembers === 0 &&
    totalMicrosoftTeamsChannels === 0
  ) {
    setSelectedMicrosoftTeamsNotification({
      ...notification,
      error: true,
      errorMessage:
        "You must select at least one recipient(channel or inidividual) for Microsoft Teams notification",
    })
    validationStatus = false
  }
  return validationStatus
}

export const setTogglesData = (action, dispatch, ruleActions) => {
  if (action.action_type === OUTBOUND_WEBHOOK) {
    addRemoveRuleAction({
      ruleActions,
      type: OUTBOUND_WEBHOOK,
      dispatch,
    })
    setSendViaWebhook(true, dispatch)
  }
  if (action.action_type === SEND_VIA_WEB_HOOK) {
    addRemoveRuleAction({
      ruleActions,
      type: SEND_VIA_WEB_HOOK,
      dispatch,
    })
    setSendViaWebhook(true, dispatch)
    setWebhookMatchingMethod(action.matching_method)
  }
  if (action.action_type === SERVICE_NOW) {
    addRemoveRuleAction({
      ruleActions,
      type: SERVICE_NOW,
      dispatch,
    })
    setServiceWebhook(true, dispatch)
    setServiceWebhookMatchingMethod(action.matching_method)
  }
  if (action.action_type === SEND_SPLUNK_SYSLOG) {
    addRemoveRuleAction({
      ruleActions,
      type: SEND_SPLUNK_SYSLOG,
      dispatch,
    })
    setSendViaSplunk(true, dispatch)
    setSplunkMatchingMethod(action.matching_method)
  }
}

const toastMsgType = {
  created: (ruleName) => `Created risk response rule ${ruleName}`,
  modified: (ruleName) => `Modified risk response rule ${ruleName}`,
  new: () => `New risk response rule successfully activated!`,
}

export const toastMsg = ({
  msgType,
  dispatch,
  ruleName = "",
  onClose = () => null,
}) => {
  const msg = toastMsgType[msgType](ruleName)
  onClose()
  showToast(msg)(dispatch)
  closeToasts({ dispatch })
}

export const validateSlackNotification = (
  notification,
  setSelectedSlackNotification,
) => {
  const totalSlackUsers = notification?.slack_users?.length || 0
  const totalSlackChannels = notification?.channel_names?.length || 0
  let validationStatus = true
  if (
    (notification?.value === INDIVIDUALS || notification?.value === MANAGERS) &&
    isEmpty(notification?.message)
  ) {
    setSelectedSlackNotification({
      ...notification,
      error: true,
      errorMessage: "Message is missing",
    })
    validationStatus = false
  } else if (
    notification?.value === CHANNELS &&
    (totalSlackChannels === 0 || isEmpty(notification?.message))
  ) {
    setSelectedSlackNotification({
      ...notification,
      error: true,
      errorMessage:
        "You must select at least one channel AND fill message for Slack notification",
    })
    validationStatus = false
  } else if (
    notification?.value === CUSTOM &&
    totalSlackUsers === 0 &&
    totalSlackChannels === 0
  ) {
    setSelectedSlackNotification({
      ...notification,
      error: true,
      errorMessage:
        "You must select at least one recipient(channel or inidividual) for Slack notification",
    })
    validationStatus = false
  }
  return validationStatus
}

const parseUpdateAzureADConditionalAccessPolicy = async (label, action) => {
  if (action === null) {
    return label
  }
  let policiesString = ""
  if (action?.update_info?.policy_ids?.length) {
    const response = await getAzureADConditionalAccessPolicies({
      policy_id__in: action?.update_info?.policy_ids?.join(","),
    })
    policiesString =
      response?.data?.results
        ?.map((item) => item?.display_name || "")
        ?.join(", ") || ""
  }
  return `${label}: ${policiesString}`
}

const parseUpdateAzureADEntrpriseApplications = async (label, action) => {
  if (action === null) {
    return label
  }

  let applicationsString = ""
  let groupsString = ""

  if (action?.target_apps_meta?.length) {
    applicationsString =
      action?.target_apps_meta.map((item) => item?.label || "")?.join(", ") ||
      ""
  }

  if (action?.target_groups_meta?.length) {
    groupsString =
      action?.target_groups_meta.map((item) => item?.label || "")?.join(", ") ||
      ""
  }

  return `${label}: ${applicationsString}${
    isEmpty(groupsString) ? "" : `, groups: ${groupsString}`
  }`
}

const parseAddRemoveCiscoDuoUsersToGroup = async (label, action) => {
  if (action === null) {
    return label
  }
  let groupsString = ""
  if (action?.target_groups?.length) {
    const response = await getCiscoDuoDropDownElements({
      group_id__in: action.target_groups.join(","),
    })
    groupsString = response?.data?.results
      ?.map((item) => item?.name || "")
      .join(", ")
  }
  return `${label}: ${groupsString || ""}`
}

const parseNotification = async (label, action, action_values, map_key) => {
  if (action === null) {
    return label
  }
  let valuesString = ""
  if (action_values?.length) {
    if (map_key) {
      valuesString = `${action_values
        .map((value) => value[map_key])
        .join(", ")};`
    } else {
      valuesString = `${action_values.join(", ")};`
    }
  }
  return `${label}: ${valuesString}`
}

const parseGroupsCap = async (label, action, action_values) => {
  if (action === null) {
    return label
  }

  let groupsString = ""
  let policiesString = ""

  if (action?.update_info?.policy_ids?.length) {
    const response = await getAzureADConditionalAccessPolicies({
      policy_id__in: action?.update_info?.policy_ids?.join(","),
    })
    policiesString =
      response?.data?.results
        ?.map((item) => item?.display_name || "")
        ?.join(", ") || ""
  }

  if (action_values?.groups_meta?.length) {
    groupsString =
      action_values?.groups_meta.map((item) => item?.label || "")?.join(", ") ||
      ""
  } else if (action_values?.group_ids?.length) {
    groupsString = action_values.group_ids?.join("") || ""
  }

  return `${label}: policies: ${policiesString}, groups: ${groupsString}`
}

const parseAppsCap = async (label, action, action_values) => {
  if (action === null) {
    return label
  }

  let appsString = ""
  let policiesString = ""

  if (action?.update_info?.policy_ids?.length) {
    const response = await getAzureADConditionalAccessPolicies({
      policy_id__in: action?.update_info?.policy_ids?.join(","),
    })
    policiesString =
      response?.data?.results
        ?.map((item) => item?.display_name || "")
        ?.join(", ") || ""
  }

  if (action_values?.apps_meta?.length) {
    appsString =
      action_values?.apps_meta.map((item) => item?.label || "")?.join(", ") ||
      ""
  } else if (action_values?.application_ids?.length) {
    appsString = action_values.application_ids?.join("") || ""
  }

  return `${label}: policies: ${policiesString}, applications: ${appsString}`
}

const parseSailPointWorkflow = async (label, action) => {
  if (action === null) {
    return label
  }
  const workflowId = action?.[WORKFLOW_ID] || ""
  if (workflowId.length) {
    const response = await getSailPointWorkFlowData({
      limit: 500,
      offset: 0,
    })
    const workflowName =
      response?.data?.results?.find(
        (item) => item?.[WORKFLOW_ID] === workflowId,
      )?.name || ""
    return `${label}: ${workflowName}`
  }
}

const parseCrowdStrikeFalcon = async (label, action) => {
  if (action === null) {
    return label
  }
  const tagCrowdstrike = action?.[TAGS] || []
  let crowdstrikeName = ""
  if (tagCrowdstrike.length) {
    crowdstrikeName = tagCrowdstrike.join(", ")
  }
  return `${label}: ${crowdstrikeName}`
}

const parseEmailCustomNotification = async (label, action) => {
  if (action === null) {
    return label
  }

  const { shortname: orgId } = getCompanyInfo()

  let emailsString = ""
  let groupsString = ""

  if (action?.emails?.length) {
    emailsString = `${humanize("individuals")}: ${action.emails.join(", ")}; `
  }

  if (action?.group_ids?.length) {
    let groupsName = ""
    const response = await getGroupsList({
      organization: orgId,
      limit: 500,
      offset: 0,
      id__in: action?.group_ids.join(","),
    })

    groupsName =
      response?.data?.results?.map((item) => item?.name || "")?.join(", ") || ""
    groupsString = `${humanize("groups")}: ${groupsName || ""};`
  }
  return `${label}: ${emailsString}${groupsString}`
}

const parseMicrosoftTeamsCustomNotification = async (label, action) => {
  if (action === null) {
    return label
  }
  let channelsString = ""
  let membersString = ""
  if (action?.channel_names?.length) {
    channelsString = `${humanize("channels")}: ${action.channel_names.join(
      ", ",
    )};`
  }
  if (action?.microsoft_teams_members?.length) {
    membersString = `${humanize("members")}: ${action.microsoft_teams_members
      ?.map((member) => `${member?.given_name} ${member.surname}`)
      ?.join(", ")}; `
  }
  return `${label}: ${membersString}${channelsString}`
}

const parseSlackCustomNotification = async (label, action) => {
  if (action === null) {
    return label
  }
  let channelsString = ""
  let usersString = ""
  if (action?.channel_names?.length) {
    channelsString = `${humanize("channels")}: ${action.channel_names.join(
      ", ",
    )};`
  }
  if (action?.slack_users?.length) {
    usersString = `${humanize("users")}: ${action.slack_users
      ?.map((user) => user?.display_name)
      ?.join(", ")}; `
  }
  return `${label}: ${usersString}${channelsString}`
}

export const actionsTypesMapping = {
  [CHANGE_SYSTEM_GROUP]: () =>
    parseNotification("<b>Change System Group Membership</b>", null),
  [OUTBOUND_WEBHOOK]: () =>
    parseNotification("<b>Send Data to Outbound Webhook</b>", null),
  [SERVICE_NOW]: () =>
    parseNotification("<b>Open ticket on Service Now</b>", null),
  [SEND_SPLUNK_SYSLOG]: () =>
    parseNotification("<b>Send logs to Splunk</b>", null),
  [REMOVE_GITHUB_REPOSITORIES_ACCESS]: (action) =>
    parseNotification(
      "<b>Remove Github Repository Access</b>",
      action,
      action?.repositories,
    ),
  [UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Update Azure AD Conditional Access Policy</b>",
      action,
    ),
  [INCLUDE_USERS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Include Users in Conditional Access Policy</b>",
      action,
    ),
  [ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Add Users to Conditional Access Policy Inclusion List</b>",
      action,
    ),
  [ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Add Users to Conditional Access Policy Exclusion List</b>",
      action,
    ),

  [REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Remove Users from Conditional Access Policy Inclusion List</b>",
      action,
    ),
  [REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Remove Users from Conditional Access Policy Exclusion List</b>",
      action,
    ),
  [EXCLUDE_USERS_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Exclude Users from Conditional Access Policy</b>",
      action,
    ),
  [ADD_GROUPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseGroupsCap(
      "<b>Add Groups to Conditional Access Policy Inclusion List</b>",
      action,
      action?.update_info,
    ),
  [ADD_GROUPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseGroupsCap(
      "<b>Add Groups to Conditional Access Policy Exclusion List</b>",
      action,
      action?.update_info || {},
    ),
  [ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseAppsCap(
      "<b>Add Applications to Conditional Access Policy Inclusion List</b>",
      action,
      action?.update_info,
    ),
  [ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseAppsCap(
      "<b>Add Applications to Conditional Access Policy Exclusion List</b>",
      action,
      action?.update_info || {},
    ),
  [REMOVE_GROUPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Remove Groups from Conditional Access Policy Inclusion List</b>",
      action,
    ),
  [REMOVE_GROUPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Remove Groups from Conditional Access Policy Exclusion List</b>",
      action,
    ),
  [REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Remove Applications from Conditional Access Policy Inclusion List</b>",
      action,
    ),
  [REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Remove Applications from Conditional Access Policy Exclusion List</b>",
      action,
    ),
  [CONFIRM_AZUREAD_COMPROMISED_USER]: () =>
    parseNotification("<b>Confirm as compromised user in Azure AD</b>", null),
  [AZURE_ADD_INDIVIDUALS]: (action) =>
    parseNotification(
      "<b>Add user to Azure AD Groups</b>",
      action,
      action?.target_groups_meta,
      "group_label",
    ),
  [AZURE_REMOVE_INDIVIDUALS]: (action) =>
    parseNotification(
      "<b>Remove user from Azure AD Groups</b>",
      action,
      action?.target_groups_meta,
      "group_label",
    ),
  [ELEVATESECURITY_GROUPS_ADD_INDIVIDUALS]: (action) =>
    parseNotification(
      "<b>Add users to Elevate Security Groups</b>",
      action,
      action?.target_groups_meta,
      "group_label",
    ),
  [ELEVATESECURITY_GROUPS_REMOVE_INDIVIDUALS]: (action) =>
    parseNotification(
      "<b>Remove user from ES Manual Groups</b>",
      action,
      action?.target_groups_meta,
      "group_label",
    ),
  [CISCO_DUO_ADD_INDIVIDUALS]: (action) =>
    parseNotification(
      "<b>Add user to Cisco Duo Groups</b>",
      action,
      action?.target_groups_meta,
      "group_label",
    ),
  [CISCO_DUO_REMOVE_INDIVIDUALS]: (action) =>
    parseAddRemoveCiscoDuoUsersToGroup(
      "<b>Remove user from Cisco Duo Groups</b>",
      action,
    ),
  [APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY]: (action) =>
    parseUpdateAzureADConditionalAccessPolicy(
      "<b>Apply Cisco Duo Conditional Access Policy</b>",
      action,
    ),
  [CONFIRM_CISCO_DUO_COMPROMISED_USERS]: (action) =>
    parseNotification(
      "<b>Set Authentication Status of Cisco Duo Users</b>",
      action,
    ),
  [SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS]: () =>
    parseNotification("<b>Send Email message</b>: To individuals", null),
  [SEND_EMAIL_NOTIFICATION_TO_MANAGERS]: () =>
    parseNotification("<b>Send Email message</b>: To manager", null),
  [SEND_EMAIL_NOTIFICATION_TO_CUSTOM]: (action) =>
    parseEmailCustomNotification(
      "<b>Send Email message</b>: To customized list",
      action,
    ),

  [SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS]: () =>
    parseNotification(
      "<b>Send Microsoft Teams message</b>: To individuals",
      null,
    ),
  [SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS]: () =>
    parseNotification("<b>Send Microsoft Teams message</b>: To managers", null),
  [SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CHANNELS]: (action) =>
    parseNotification(
      "<b>Send Microsoft Teams message</b>: To channels",
      action,
      action?.channel_names,
    ),
  [SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM]: (action) =>
    parseMicrosoftTeamsCustomNotification(
      "<b>Send Microsoft Teams message</b>: To customized list",
      action,
    ),

  [SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS]: () =>
    parseNotification("<b>Send Slack message</b>: To individuals", null),
  [SEND_SLACK_NOTIFICATION_TO_MANAGERS]: () =>
    parseNotification("<b>Send Slack message</b>: To managers", null),
  [SEND_SLACK_NOTIFICATION_TO_CHANNELS]: (action) =>
    parseNotification(
      "<b>Send Slack message</b>: To channels",
      action,
      action?.channel_names,
    ),
  [SEND_SLACK_NOTIFICATION_TO_CUSTOM]: (action) =>
    parseSlackCustomNotification(
      "<b>Send Slack message</b>: To customized list",
      action,
    ),
  [SAIL_POINT_WORKFLOW]: (action) =>
    parseSailPointWorkflow("<b>Execute SailPoint workflow</b>", action),
  [TAG_CROWDSTRIKE_FALCON_RISKY_USERS]: (action) =>
    parseCrowdStrikeFalcon("<b>CrowdStrike Falcon</b>", action),
  [ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION]: (action) =>
    parseUpdateAzureADEntrpriseApplications(
      "<b>Add Users to Azure AD Enterprise Applications</b>",
      action,
    ),
  [REMOVE_USERS_FROM_AZUREAD_ENTERPRISE_APPLICATION]: (action) =>
    parseUpdateAzureADEntrpriseApplications(
      "<b>Remove Users from Azure AD Enterprise Applications</b>",
      action,
    ),
  [ADD_GROUPS_TO_AZUREAD_ENTERPRISE_APPLICATION]: (action) =>
    parseUpdateAzureADEntrpriseApplications(
      "<b>Add Groups to Azure AD Enterprise Applications</b>",
      action,
    ),
  [REMOVE_GROUPS_FROM_AZUREAD_ENTERPRISE_APPLICATION]: (action) =>
    parseUpdateAzureADEntrpriseApplications(
      "<b>Remove Groups from Azure AD Enterprise Applications</b>",
      action,
    ),
}

export const isCriteriaEmpty = (queryRules) => {
  let flag = false
  if (queryRules?.[0]?.rules?.length) {
    flag = true
  }
  return flag
}

const getGroupCapFormat = (action_type, groupData, group_ids, groups_meta) => {
  return {
    action_type,
    matching_method: groupData?.matching_method || "",
    update_info: {
      policy_ids: groupData?.policy_ids || [],
      group_ids,
      groups_meta,
    },
  }
}

export const getGroupsData = (groupAzureAdCap) => {
  const groupsCapArray = []
  if (groupAzureAdCap?.add_group?.length) {
    groupsCapArray.push(
      getGroupCapFormat(
        INCLUDE_GROUPS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
        groupAzureAdCap,
        groupAzureAdCap.add_group?.map((item) => item.group_id) || [],
        groupAzureAdCap?.add_group || [],
      ),
    )
  }

  if (groupAzureAdCap?.remove_group?.length) {
    groupsCapArray.push(
      getGroupCapFormat(
        EXCLUDE_GROUP_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
        groupAzureAdCap,
        groupAzureAdCap.remove_group?.map((item) => item.group_id) || [],
        groupAzureAdCap?.remove_group || [],
      ),
    )
  }
  return groupsCapArray
}
