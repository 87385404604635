import React from "react"
import pluralize from "pluralize"

import { ScoreBox, ScoreBoxDescription, Strong } from "./styles"
import { SkeletonLoader } from "@src/utils/skeleton"
import IconMalware from "./icon-malware.svg"

export const IdentityExplanation = ({
  successCount,
  failureCount,
  loading,
  attemptText,
}) => {
  return (
    <ScoreBox>
      {loading ? (
        <SkeletonLoader height={80} />
      ) : (
        <>
          <img src={IconMalware} alt="logo" />
          <ScoreBoxDescription>
            <span> WAS DETECTED IN </span>
            <Strong>{failureCount ?? "-"}</Strong> failed {attemptText}{" "}
            {pluralize("attempt", failureCount || 0)}
            <Strong>{successCount ?? "-"}</Strong> successful {attemptText}{" "}
            {pluralize("attempt", successCount || 0)}
          </ScoreBoxDescription>
        </>
      )}
    </ScoreBox>
  )
}
