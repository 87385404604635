import React from "react"

export const genericCopies = {
  training: "complete all security training by end of the month",
  "clean-desk":
    "set a reminder to clear your desk and secure any sensitive data",
  phishing: "look for suspicious senders and links in the emails you get",
  "password-manager": "install password manager client",
  malware: "watch for files from unknown senders or untrusted websites",
  "password-security": "enable two-step verification",
  "real-world-phishing":
    "look for suspicious senders and URLs in the emails you get",
  "unauthorized-software":
    "only install company-approved software on your work computer",
  "secure-browsing":
    "avoid websites posing a security threat to your device and data",
  "device-security": "stay compliant with all device security policies",
  "physical-security":
    "keep a close eye on your devices and badges and report them lost or stolen as soon as you can",
  "optional-activities": "sign up for and attend optional security activities",
  "sensitive-data-handling": "only share sensitive data via approved channels",
  "hr-access-termination":
    "process access and staff termination in Workday as quickly as possible",
}

export const concatBehaviorNames = (behaviors) => {
  if (!behaviors || !behaviors.length) return null
  else if (behaviors.length === 1) return <b>{behaviors[0].name}</b>
  return (
    <>
      <b>{behaviors[0].name}</b> or <b>{behaviors[1].name}</b>
    </>
  )
}
