import React from "react"
import { Input, ComboBox } from "@src/components/MimecastReskin"
import { GroupWrapper, ItemWrapper } from "../ConditionsGroup/styles"
import { Container, Hint, FieldWrapper, Item } from "../styles"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { EventOccurrenceItem } from "../EventOccurrenceItem"
import { StringInput } from "../StringInput"
import CompoundCondition from "../CompoundCondition"
import FieldCombobox from "../FieldCombobox"
import { CompoundConditionButton } from "../CompoundConditionButton"
import { RelativeDateInput } from "../RelativeDateInput/RelativeDateInput"
import OperatorsCombobox from "../OperatorsCombobox/OperatorsCombobox"
import { groupOperators } from "../utils/groupOperators"
import ManagerHierarchy from "../ManagerHierarchy/ManagerHierarchy"
import { humanize, isEmpty } from "@src/utils/string"

function ConditionTemplate(props) {
  const {
    id,
    index,
    rule,
    dataSource,
    onHandleFieldChange,
    onAddCompoundCondition,
    onRemoveCompoundCondition,
    onHandleCompoundConditionFieldChange,
    fieldsNamesMap,
  } = props

  const selectedField = dataSource[rule?.category]?.find(
    (field) => field.value === rule.field,
  )
  const selectedType = selectedField?.type || "string"
  const operators = dataSource?.operators?.[selectedType] || []
  const groupedOperators = groupOperators(operators, (item) => item.type)
  const operatorOptions =
    groupedOperators?.length > 1
      ? groupedOperators
      : groupedOperators?.[0].options || []
  const isOperatorFilterable = groupedOperators?.length > 1

  const selectedOperator = operators?.find(
    (operator) => operator.value === rule?.operator,
  )
  const isRelativeOperator = Boolean(selectedOperator?.type === "relative")

  const showOccurances =
    dataSource?.occurrences?.fields[rule.category] !== undefined
  const showSecondInput =
    rule?.operator === "between" || rule?.operator === "rbetween"

  const isManagerCategory = rule.category === "manager"

  const fieldsMap = (rule, inputOrder = 0, currentType, currentOperator) => {
    const hasCsvSupport = rule.operator === "in" || rule.operator === "not_in"
    const numbersIn = currentType === "number" && hasCsvSupport
    const isRelativeOperator = Boolean(currentOperator?.type === "relative")

    const handleChange = (selected) => {
      let [sNumber, sUnit, eNumber, eUnit] = [...rule.value]
      if (showSecondInput) {
        if (inputOrder === 0) {
          sNumber = selected.number
          // sUnit = eUnit
        } else if (inputOrder === 2) {
          eNumber = selected.number
          eUnit = selected.unit
          sUnit = selected.unit
        }
      } else {
        sNumber = selected.number
        sUnit = selected.unit
      }
      onHandleFieldChange(id, index, {
        name: "value",
        value: showSecondInput
          ? [sNumber, sUnit, eNumber, eUnit]
          : [sNumber, sUnit],
      })
    }

    const dateInput = isRelativeOperator ? (
      <RelativeDateInput
        time_mode_ops={dataSource.values.period}
        hide_time_unit={inputOrder === 0 && showSecondInput}
        value={{
          number: rule?.value[inputOrder],
          unit: rule?.value[inputOrder + 1],
        }}
        onChange={handleChange}
      />
    ) : (
      <Container>
        <DatePicker
          placeholderText="Select date"
          style={{ width: "250px" }}
          dateFormat="yyyy-MM-dd"
          selected={
            rule?.value?.length && rule?.value[inputOrder]
              ? moment(rule.value[inputOrder]).toDate()
              : null
          }
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = moment(e).format("YYYY-MM-DD")
            onHandleFieldChange(id, index, {
              name: "value",
              value,
            })
          }}
        />
      </Container>
    )

    return {
      string: (
        <StringInput
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          onHandleFieldChange={onHandleFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      ),
      number: numbersIn ? (
        <>
          <Input
            name="value"
            placeholder="Enter value"
            style={{ width: "250px" }}
            value={rule.value[inputOrder]}
            onChange={(e) => {
              const value = [...rule.value]
              value[inputOrder] = e
              onHandleFieldChange(id, index, {
                name: "value",
                value,
              })
            }}
          />
          {hasCsvSupport && <Hint>Enter comma (,) separated values</Hint>}
        </>
      ) : (
        <Input
          name="value"
          type="number"
          placeholder="Enter value"
          style={{ width: "250px" }}
          step="0.1"
          min={0}
          value={rule.value[inputOrder]}
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = e
            onHandleFieldChange(id, index, {
              name: "value",
              value,
            })
          }}
        />
      ),
      boolean: (
        <ComboBox
          styles={{
            control: (baseStyles) => ({ ...baseStyles, width: "250px" }),
          }}
          data={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
          value={
            isEmpty(rule.value[inputOrder])
              ? null
              : {
                  label: humanize(`${rule.value[inputOrder]}`),
                  value: rule.value[inputOrder],
                }
          }
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = e.value
            onHandleFieldChange(id, index, {
              name: "value",
              value,
            })
          }}
          clearable={false}
          isSearchable={false}
          isMultiOption={false}
          checked={false}
          closeMenuOnSelect
          components={{}}
          disabled={false}
          placeholder="Select value"
        />
      ),
      date: dateInput,
      datetime: dateInput,
      timestamp: (
        <Container>
          <DatePicker
            placeholderText="Select date"
            style={{ width: "250px" }}
            dateFormat="yyyy-MM-dd"
            selected={
              rule?.value?.length && rule?.value[inputOrder]
                ? moment(rule.value[inputOrder]).toDate()
                : null
            }
            onChange={(e) => {
              const value = [...rule.value]
              value[inputOrder] = moment(e).format("YYYY-MM-DD")
              onHandleFieldChange(id, index, {
                name: "value",
                value,
              })
            }}
          />
        </Container>
      ),
      groups: (
        <StringInput
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          onHandleFieldChange={onHandleFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      ),
      applications: (
        <StringInput
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          onHandleFieldChange={onHandleFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      ),
    }
  }

  return (
    <ItemWrapper>
      <Item>
        <div>
          <ComboBox
            styles={{
              control: (baseStyles) => ({ ...baseStyles, width: "200px" }),
            }}
            data={dataSource?.categories || []}
            value={dataSource?.categories?.find(
              (category) => category.value === rule.category,
            )}
            onChange={(e) =>
              onHandleFieldChange(id, index, {
                name: "category",
                value: e.value,
              })
            }
            clearable={false}
            isSearchable={false}
            isMultiOption={false}
            checked={false}
            closeMenuOnSelect
            components={{}}
            disabled={false}
            placeholder="Select category"
            inputId="select-category"
          />
        </div>
        {rule?.compound_conditions ? (
          <CompoundCondition
            compoundConditions={rule?.compound_conditions}
            onAddCompoundCondition={onAddCompoundCondition}
            onRemoveCompoundCondition={onRemoveCompoundCondition}
            onHandleCompoundConditionFieldChange={
              onHandleCompoundConditionFieldChange
            }
            fieldsNamesMap={fieldsNamesMap}
            {...props}
          />
        ) : (
          <Item>
            <div>
              <FieldCombobox
                defaultValue={rule.field}
                options={dataSource?.[rule.category] || []}
                onChange={(e) =>
                  onHandleFieldChange(id, index, {
                    name: "field",
                    value: e.value,
                  })
                }
              />
            </div>
            <div>
              <OperatorsCombobox
                isFilterable={isOperatorFilterable}
                options={operatorOptions}
                value={operators?.find((op) => op.value === rule.operator)}
                onChange={(e) =>
                  onHandleFieldChange(id, index, {
                    name: "operator",
                    value: e.value,
                  })
                }
              />
            </div>
            <FieldWrapper>
              {fieldsMap(rule, 0, selectedType, selectedOperator)[selectedType]}
            </FieldWrapper>
            {showSecondInput && (
              <>
                <div style={{ paddingTop: "10px" }}>
                  <strong> AND </strong>
                </div>
                <FieldWrapper>
                  {
                    fieldsMap(
                      rule,
                      showSecondInput && isRelativeOperator ? 2 : 1,
                      selectedType,
                      selectedOperator,
                    )[selectedType]
                  }
                </FieldWrapper>
              </>
            )}
            <div style={{ paddingTop: "5px" }}>
              <CompoundConditionButton
                onClick={() => onAddCompoundCondition(id, index)}
              />
            </div>
          </Item>
        )}
      </Item>
      {showOccurances && rule?.category && rule?.eventOccurrences && (
        <GroupWrapper className="tree occurance-tree-group">
          {rule.eventOccurrences.map((eventOccurrence, i) => (
            <EventOccurrenceItem
              key={`${id}-${i}`}
              index={i}
              ruleIndex={index}
              ruleId={id}
              eventOccurrence={eventOccurrence}
              handleChange={(data) => {
                const eventOccurrencesCopy = [...rule.eventOccurrences]
                eventOccurrencesCopy[i] = { ...data }
                onHandleFieldChange(id, index, {
                  name: "eventOccurrences",
                  value: eventOccurrencesCopy,
                })
              }}
              time_mode_ops={dataSource.values.period}
            />
          ))}
        </GroupWrapper>
      )}
      {/* Manager hierarchy support */}
      {isManagerCategory && (
        <ManagerHierarchy
          value={rule.manager_hierarchy}
          handleChange={(value) => {
            onHandleFieldChange(id, index, {
              name: "manager_hierarchy",
              value: value,
            })
          }}
        />
      )}
    </ItemWrapper>
  )
}
export default React.memo(ConditionTemplate)
