export const ActionGroups = {
  EMAIL: {
    id: "EMAIL",
    label: "Send email to",
    actionLimitPerType: Infinity,
  },
  SLACK: {
    id: "SLACK",
    label: "Send Slack message to",
    actionLimitPerType: Infinity,
  },
  MICROSOFT_TEAMS: {
    id: "MICROSOFT_TEAMS",
    label: "Send Microsoft Teams message to",
    actionLimitPerType: Infinity,
  },
  OUTBOUND: {
    id: "OUTBOUND",
    label: "Outbound actions",
    // For now allow at most one of each outbound action, since there can be
    // conflicts for some if multiple exist
    actionLimitPerType: 1,
  },
} as const
