import React from "react"
import { theme, Icon } from "@elevate_security/elevate-component-library"

import { ConditionWrapper, ConditionSelect, IconWrapper } from "./styles"

function ConditionGroup(props) {
  const { id, condition, toggleOperation } = props
  return (
    <ConditionWrapper>
      <ConditionSelect
        value={condition}
        onChange={(e) => {
          toggleOperation(id, e.target.value)
        }}
      >
        <option value="and">AND</option>
        <option value="or">OR</option>
      </ConditionSelect>
      <IconWrapper>
        <Icon name="CaretDown" fill={theme.text.color[300]} />
      </IconWrapper>
    </ConditionWrapper>
  )
}
export default React.memo(ConditionGroup)
