const { useRef, useState, useEffect } = require("react")
import { useDrawChart } from "./useDrawChart"

export const useGraph = ({
  behaviorScores,
  barData,
  onMouseEnter,
  prevDisabledLines = [],
  showExport,
  keyToBeDashed,
  minMaxValue,
  sortType = "",
  isInsight,
  yAxisDecimal = false,
  changeLineFocus,
  lineFOcus,
  performanceFlag = false,
  showGradeLabel = false,
}) => {
  const chartRef = useRef(null)
  const printChartRef = useRef(null)
  const [disabledLines, setDisabledLines] = useState(prevDisabledLines)
  const [hideActionsTaken, setHideActionsTaken] = useState(false)

  const [isReady, setIsReady] = useState(false)
  const [typeGraph, setTypeGraph] = useState("monthly")

  const drawChart = useDrawChart(
    chartRef,
    printChartRef,
    disabledLines,
    typeGraph,
    behaviorScores,
    barData,
    hideActionsTaken,
    onMouseEnter,
    setIsReady,
    showExport,
    keyToBeDashed,
    minMaxValue,
    sortType,
    isInsight,
    yAxisDecimal,
    changeLineFocus,
    lineFOcus,
    performanceFlag,
    showGradeLabel,
  )

  useEffect(
    () => drawChart(false),
    [chartRef, typeGraph, behaviorScores, barData],
  )

  useEffect(() => {
    if (!isReady) return
    drawChart(true)
  }, [disabledLines, hideActionsTaken])

  useEffect(() => {
    window.addEventListener("resize", () => drawChart(true))
    return () => window.removeEventListener("resize", drawChart)
  }, [chartRef, disabledLines, typeGraph, behaviorScores, barData])

  return {
    chartRef,
    printChartRef,
    disabledLines,
    setDisabledLines,
    hideActionsTaken,
    setHideActionsTaken,
    typeGraph,
    setTypeGraph,
    isReady,
  }
}
