// Hard-coded flag list to unblock Mimecast CG integration work. We should sweep
// through this list and whittle it down to the flags are actually used by
// Vision. Eventually Mimecast embedded Vision will stop using this and instead
// we'll use Mimecast LaunchDarkly instead.
export const INLINE_FLAGS = {
  "action-trends-over-time": false,
  "actions-that-enable-the-action-factor-drawer": [
    "malware",
    "optional_activities",
    "phishing",
    "physical_security",
    "real_world_phishing",
    "secure_browsing",
    "sensitive_data_handling",
    "training",
    "unauthorized_software",
  ],
  "assess-ui-state-control": "integration",
  "dashboard-actionable-insights": true,
  "display-assess-only-ui": false,
  "employee-legacy": false,
  "employee-redesign": true,
  "employee-s3-upload": true,
  "employee-score-list-v2": true,
  "ep-my-integrations-v2": true,
  "groups-legacy": false,
  "groups-redesign": true,
  "hide-legacy-integration-screen": true,
  "hm-wizard-legacy": true,
  "hm-wizard-rewrite": true,
  "home-page-rewrite": true,
  import_groups: true,
  init_full_story: false,
  init_pando: true,
  "integrations-legacy": true,
  "integrations-redesign": true,
  "integrations-redesign-2": true,
  "itdr-behaviors": false,
  "manager-dashboard-v2": true,
  "offering-plan": "assess",
  "onboarding-steps": true,
  "pulse-allow-html": false,
  "pulse-available-template-type": ["static", "employees", "managersV2"],
  "pulse-configurator": true,
  "pulse-languages": [
    "English",
    "French",
    "German",
    "Portuguese",
    "Spanish",
    "Italian",
    "Chinese",
    "Japanese",
  ],
  "pulse-show-actual-phishing-behavior-toggle": true,
  "pulse-show-language": true,
  "pulse-show-mfa-behavior-toggle": true,
  "rdr-non-canonical-fields": true,
  "recommendation-campaign": true,
  "reflex-2-mvp": true,
  "reflex-confidence-engine": true,
  "reflex-frame": true,
  "reflex-recommendation-campaign": false,
  "reflex-template-editor-menu-entry": true,
  "see-recommendations-on-dashboard": true,
  "see-use-mailtrap-toggle-on-rrr": true,
  "show-3rd-party-policy-groups-features-on-vision": true,
  "show-attachment-ms-teams-notifications": false,
  "show-data-sources-column-on-events-log": true,
  "show-dial-score-graphic": true,
  "show-employee-on-msteams": false,
  "show-event-based-trigger": false,
  "show-flags-ribbon": false,
  "show-manager-dashboard": true,
  "show-next-generation-rdr": true,
  "show-pulse-configuration-form": true,
  "show-rdr-enhancements": true,
  "show-reflex-camp-results-v2": true,
  "show-reflex-legacy": false,
  "show-reflex-mvp": true,
  "show-scheduler-on-r3": true,
  "show-vc-demo-features": false,
  show_es_roles: true,
  "streaming-architecture": false,
  "tags-api-available": true,
  "use-individual-scores-from-reputation-service": false,
  "vision-items-that-is-accessible-by-the-customer": [
    "Dashboard",
    "Risk Analysis",
    "Risk Detection Rules",
    "Security Summary",
    "Manager Dashboard",
  ],
  "vision-phishing-reports-dashboard": true,
  "vision-refresh": true,
  "rap-action-factor-trends-over-time-hidden-behaviours": [],
} as const
