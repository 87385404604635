import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 16px 24px;
  border: 1px solid #dde1e7;
  border-radius: 4px;
  margin-top: 32px;
`

export const TimeWrappers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0 35px 0;
  align-items: baseline;
`
export const GapWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const HeaderWrapper = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
`
