import React from "react"
import PropTypes from "prop-types"
import {
  Modal,
  Typography,
  theme,
  Icon,
} from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"
import { SpaceBetween, IconWrapper } from "./FilterWarningModal.styles"

export function FilterWarningModal({ isOpen, onClose, onUpdateFilterType }) {
  return (
    <Modal
      header={<Typography.H1>Update filter type</Typography.H1>}
      isOpen={isOpen}
      onClose={onClose}
      rightButtons={
        <>
          <Button theme="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onUpdateFilterType}>Update</Button>
        </>
      }
    >
      <SpaceBetween direction="row">
        <IconWrapper>
          <Icon name="Info" size="lg" fill={theme.colors.border.error} />
        </IconWrapper>
        <Typography.Text>
          You can only use one kind of filter type here &mdash; Simple Filters
          or Advanced Filters. If you change filter types, your current choices
          will be deleted. Update filter type?
        </Typography.Text>
      </SpaceBetween>
    </Modal>
  )
}

FilterWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateFilterType: PropTypes.func.isRequired,
}
