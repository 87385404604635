import { SET_PNG } from "./types"

export function setPNG(id, png) {
  return {
    type: SET_PNG,
    payload: {
      id,
      png,
    },
  }
}
