import { SET_PNG } from "./types"

//To add another graph to export, add to this state as well as wrap the item in a PNGWrapper Component
const initialState = {
  ScoreProgressBarPNG: null,
  ActionFactorPNG: null,
  AttacksOverTimePNG: null,
  AccessAndAttackFactorPNG: null,
  HumanRiskScoreOverTimePNG: null,
  ActionFactorTrendsPNG: null,
}

export default function pngReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PNG: {
      const { id, png } = action.payload
      return {
        ...state,
        [id]: png,
      }
    }
    default:
      return state
  }
}
