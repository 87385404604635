/* eslint-disable no-param-reassign */
import axios from "axios"
import qs from "qs"
import { getSubdomain } from "@src/services/fetcher/utils"

const axios_headers = { "X-Tenant": getSubdomain() }

const impersonate_es_person_id = localStorage.getItem(
  "impersonate_es_person_id",
)
if (impersonate_es_person_id !== null) {
  axios_headers["X-Impersonate-User"] = impersonate_es_person_id
}

const axiosOpts = {
  headers: axios_headers,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params),
}

// Create the preconfigured axiosInstance, with the base URL of our API
const axiosInstance = axios.create(axiosOpts)

const UNAUTHORIZED = "global:unauthorized"
const FORBIDDEN = "manager-dashboard:forbidden"

// Add the Token to requests made with Axios
axiosInstance.interceptors.request.use(
  (config) => {
    // Make sure the request is sent to our API to avoid leaking the token
    if (!config.headers.Authorization) {
      const Bearer = localStorage.getItem("JWT")
      const Token = localStorage.getItem("managerToken")
      if (Bearer || Token) {
        // const bearerToken = token.replace(/Token/g, "Bearer")
        config.headers.Authorization = Bearer || Token
      }
    }
    return config
  },
  (error) => Promise.reject(error),
)

// Register a handler for 401 responses from axios
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error

    // if 401 is returned, dispatch event to initiate authorization flow
    if (response && response.status === 401) {
      window.dispatchEvent(
        // eslint-disable-next-line no-undef
        new Event(UNAUTHORIZED, { bubbles: true, cancelable: false }),
      )
    } else if (response && response.status === 403) {
      window.dispatchEvent(
        // eslint-disable-next-line no-undef
        new Event(FORBIDDEN, { bubbles: true, cancelable: false }),
      )
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
