import React from "react"
import PropTypes from "prop-types"
import {
  SparkColumn,
  SparkGroupChart,
  SparkRow,
} from "@src/components/SparkLine/style"
import { useSparkLineGraph } from "@src/components/SparkLine/useSparkLineGraph"

function SparkLine({ behaviorScores, minMaxValue }) {
  if (!behaviorScores || !Object.keys(behaviorScores).length) return null
  const { chartRef } = useSparkLineGraph({
    behaviorScores,
    minMaxValue,
  })

  return (
    <React.Fragment>
      <SparkRow>
        <SparkColumn width="100%">
          <SparkGroupChart ref={chartRef} />
        </SparkColumn>
      </SparkRow>
    </React.Fragment>
  )
}

export default SparkLine

SparkLine.defaultProps = {
  minMaxValue: { min: 0, max: 100 },
}

SparkLine.propTypes = {
  behaviorScores: PropTypes.shape({}).isRequired,
  minMaxValue: PropTypes.object,
}
