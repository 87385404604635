import React from "react"

const MedalGold = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" className="MedalGold">
      <defs>
        <linearGradient id="gold-b" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="#000000" stopOpacity="0" />
          <stop offset="100%" stopColor="#000000" />
        </linearGradient>
        <linearGradient id="gold-c" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0" />
          <stop offset="43.861%" stopColor="#FFFFFF" stopOpacity=".518" />
          <stop offset="65.119%" stopColor="#FFFFFF" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
        <circle id="gold-a" cx="10" cy="13" r="6" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#D3D9E0"
          fillRule="nonzero"
          d="M10.9720476,6.06694813 L13.7390976,1 L17.7511509,3.31636007 L15.0882067,8.1926725 C14.022094,7.06466776 12.5849676,6.29101959 10.9720476,6.06694813 Z M4.75598118,8.36303943 L2,3.31636007 L6.01205334,1 L8.79862602,6.1026977 C7.19981454,6.37922938 5.78681458,7.19812831 4.75598118,8.36303943 Z"
        />
        <g fillRule="nonzero">
          <use fill="#D99F41" xlinkHref="#gold-a" />
          <use
            fill="url(#gold-b)"
            fillOpacity=".1"
            style={{ mixBlendMode: "overlay" }}
            xlinkHref="#gold-a"
          />
          <use
            fill="url(#gold-c)"
            fillOpacity=".9"
            style={{ mixBlendMode: "overlay" }}
            xlinkHref="#gold-a"
          />
        </g>
      </g>
    </svg>
  )
}

export default MedalGold
