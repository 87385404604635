import React from "react"
import {
  HeaderText,
  Row,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/styles"
import { SkeletonLoader } from "@src/utils/skeleton"
import { SpacingX } from "@src/components/SpacingX"

function IndividualRiskDetailsSkeleton() {
  return (
    <div>
      <SkeletonLoader height={17} />
      <div>
        <SpacingX>
          <HeaderText>
            <SkeletonLoader width={70} />
          </HeaderText>
          <HeaderText>
            <SkeletonLoader width={145} />
          </HeaderText>
        </SpacingX>
        {Array(4)
          .fill()
          .map((_, rowIndex) => (
            <Row key={rowIndex}>
              <div style={{ width: "100%" }}>
                <SkeletonLoader />
              </div>
            </Row>
          ))}
      </div>
    </div>
  )
}

export default IndividualRiskDetailsSkeleton
