import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  IconWrapper,
  IconWithTextWrapper,
  ExcludeIndividualWrapper,
  ExcludeIndividualChipWrapper,
  ExcludeIndividualChip,
  ActionItem,
  ActionTarget,
  PolicySectionLabel,
} from "./styles"
import { humanize } from "@src/utils/string"
import { excludeIncludeLabel } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { changeSystemGroupFormat } from "@src/scenes/RiskResponseRuleCreateScreen/components/Flowchart"
import {
  SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
  SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
  SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
} from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"
import {
  NotificationMessage,
  NotificationMessageContainer,
} from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/Notifications"
import { actionsTypesMapping } from "@src/scenes/RiskResponseRuleCreateScreen/components/utils"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { PrettyPrintedR3Criteria } from "./PrettyPrintedR3Criteria"
import { SchedulerOutput } from "@src/scenes/RiskResponseRuleCreateScreen/components/Scheduler/SchedulerOutput"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { validateCriteria } from "@src/scenes/RiskResponseRuleCreateScreen/components/ConfigureInputCriteriaPage/utils"
import { Button } from "@src/components/MimecastReskin"

export const RenderAction = ({ item, clickHandler, isR3EventTriggered }) => {
  const { matching_method, action_type, run_options } = item
  const [message, setMessage] = useState("")
  const getMessage = async () => {
    const message = await actionsTypesMapping[action_type]?.(item)
    setMessage(message)
  }

  const emailActions = [
    SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
    SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
    SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
  ]

  useEffect(() => {
    getMessage()
  }, [])

  return (
    <>
      <div>
        <ActionTarget>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </ActionTarget>
        {matching_method && (
          <NotificationMessageContainer>
            <NotificationMessage
              notificationType={matching_method}
              isDefaultColor={true}
            />
          </NotificationMessageContainer>
        )}
        <div>
          {!isR3EventTriggered && run_options?.schedule && (
            <SchedulerOutput scheduler={run_options.schedule} />
          )}
        </div>
      </div>
      <IconWrapper
        onClick={(event) => clickHandler({ event, chosenItem: item })}
      >
        {emailActions.includes(action_type) && (
          <IconWithTextWrapper>
            <Button>
              <Icon name="Eye" fill="#FFFFFF" />
              <span> Preview Email</span>
            </Button>
          </IconWithTextWrapper>
        )}
      </IconWrapper>
    </>
  )
}

export const PolicyDetailV2 = ({ mainRuleData, policy, onClick }) => {
  const mainRuleActions = mainRuleData?.actions
    ? changeSystemGroupFormat(mainRuleData.actions)
    : []
  const mainRuleCriteria = mainRuleData?.criteria

  const isRuleEmpty =
    !policy?.groups_rules && !mainRuleActions.length && !mainRuleCriteria

  const isRuleMalformed = !validateCriteria(
    policy?.rule?.definition?.criteria || {},
  )

  const renderPolicyActions = (item, i) => (
    <ActionItem key={item.id || i}>
      <RenderAction
        item={item}
        clickHandler={onClick}
        isR3EventTriggered={policy.is_event_triggered}
      />
    </ActionItem>
  )

  const showExcludeIncludeIndividual = () => {
    if (!policy?.groups_rules) return ""

    const _excludeIncludeWrapper = []

    Object.keys(policy?.groups_rules)
      .filter((key) => key !== "groups_names")
      .forEach((key) => {
        const item = policy.groups_rules[key]

        const groupsToShow = item.group_ids.map((group) => {
          return (
            <ExcludeIndividualChip key={group}>
              {policy.groups_rules.groups_names[group]}
            </ExcludeIndividualChip>
          )
        })

        _excludeIncludeWrapper.push(
          <ExcludeIndividualWrapper>
            <span style={{ fontWeight: "700" }}>
              {humanize(excludeIncludeLabel[key])}
            </span>{" "}
            individuals who belong to {item.mode} of the following group(s):
            <ExcludeIndividualChipWrapper>
              {groupsToShow}
            </ExcludeIndividualChipWrapper>
          </ExcludeIndividualWrapper>,
        )
      })
    return _excludeIncludeWrapper
  }

  if (isRuleMalformed) {
    return (
      <div>
        This rule has a <strong>malformed</strong> criteria and cannot be read.
      </div>
    )
  }
  return (
    <div>
      {isRuleEmpty && (
        <div>No logic has been added to this Risk Response Rule yet.</div>
      )}
      {!isRuleEmpty && (
        <SpaceBetween gap="35px">
          {showExcludeIncludeIndividual()}
          <SpaceBetween size="md">
            <div>
              <PolicySectionLabel>
                IF THE FOLLOWING CONDITIONS ARE MET
              </PolicySectionLabel>
            </div>
            <div>
              {mainRuleCriteria && (
                <PrettyPrintedR3Criteria criteria={mainRuleCriteria} />
              )}
            </div>
          </SpaceBetween>
          <SpaceBetween size="sm">
            <div style={{ marginBottom: "11px" }}>
              <PolicySectionLabel>
                THEN TAKE THE FOLLOWING ACTIONS
              </PolicySectionLabel>
            </div>
            {mainRuleActions?.length ? (
              <SpaceBetween>
                {mainRuleActions.map(renderPolicyActions)}
              </SpaceBetween>
            ) : (
              <div>No actions defined</div>
            )}
          </SpaceBetween>
        </SpaceBetween>
      )}
    </div>
  )
}

PolicyDetailV2.propTypes = {
  showToolTip: PropTypes.bool,
}
