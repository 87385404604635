import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"
import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0;
`
export const Navigation = styled.div`
  display: flex;
  align-items: center;
`
export const Arrow = styled.button`
  height: 32px;
  width: 32px;
  margin: 0;
  opacity: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  svg {
    width: 10px;
    height: 10px;
    path {
      fill: ${theme.colors.gray.opaque[500]};
    }
  }
  &:disabled {
    svg path {
      fill: ${theme.colors.gray.opaque[200]};
    }
  }
  &:focus {
    outline: none;
  }
`
export const PageNumbersContent = styled.div`
  display: flex;
  align-items: center;
`
export const PageNumber = styled.button`
  display: block;
  height: 32px;
  width: 32px;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: ${THEME_PRIMARY};
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  &:hover {
    color: ${THEME_HOVER};
    text-decoration: underline;
  }
  &:disabled {
    color: ${theme.text.color[500]};
  }
  &:focus {
    outline: none;
  }
`
