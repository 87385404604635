import React, { useState } from "react"

import { Button } from "@src/components/MimecastReskin"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { RemoveButton } from "@src/components/RemoveButton"
import { ALL_ACTION_CONFIGS } from "../actionConfigs"

import { ActionWrapper, ActionDescription } from "./Action.styles"

export function Action({
  details,
  updateAction,
  removeAction,
  templateAttributes,
  onEditStart,
  onEditEnd,
  isEditingEnabled,
  initialEditState,
  showErrorMessage,
  outboundServices,
  actionConfigs = ALL_ACTION_CONFIGS,
}) {
  const [isEditing, _setIsEditing] = useState(initialEditState)

  const setIsEditing = (value) => {
    _setIsEditing(value)
    if (value) {
      onEditStart()
    } else {
      onEditEnd()
    }
  }

  const [dirtyDetails, setDirtyDetails] = useState({ ...details })

  const actionConfig = actionConfigs.find(
    (config) => config.defaultPayload.action_type === dirtyDetails.action_type,
  )

  return (
    <ActionWrapper isEditing={isEditing}>
      <SpaceBetween>
        <SpaceBetween direction="row" alignItems="center">
          <ActionDescription>
            {actionConfig
              ? `${actionConfig.group.label}: ${actionConfig.label}`
              : `Unmigrated: ${dirtyDetails.action_type}`}
          </ActionDescription>
          {!isEditing && (
            <SpaceBetween direction="row" alignItems="center">
              <RemoveButton
                onClick={removeAction}
                disabled={!isEditingEnabled}
              />
              <Button
                onClick={() => {
                  setIsEditing(true)
                }}
                theme="secondary"
                disabled={!isEditingEnabled}
              >
                Edit
              </Button>
            </SpaceBetween>
          )}
        </SpaceBetween>
        {isEditing && (
          <>
            <div>
              {!actionConfig && (
                <div>Still need to migrate this action type...</div>
              )}
              {actionConfig?.render({
                templateAttributes,
                outboundServices,
                details: dirtyDetails,
                updateAction: (field, value) => {
                  setDirtyDetails((prev) => ({
                    ...prev,
                    [field]: value,
                  }))
                },
              })}
            </div>
            <SpaceBetween direction="row" size="sm" justify="flex-end">
              <Button
                onClick={() => {
                  setIsEditing(false)
                  setDirtyDetails({ ...details })
                }}
                theme="ghost"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  const errors = actionConfig.validate?.(dirtyDetails) ?? []
                  if (errors.length > 0) {
                    showErrorMessage(errors.map((error) => error.reason))
                  } else {
                    setIsEditing(false)
                    updateAction(dirtyDetails)
                  }
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          </>
        )}
      </SpaceBetween>
    </ActionWrapper>
  )
}
