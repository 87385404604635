import React from "react"
import PropTypes from "prop-types"

function PaperClip({ size, stroke }) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M10.5199 1.59188C9.98912 1.59188 9.48004 1.80275 9.10469 2.1781L2.97802 8.30477C2.35248 8.9303 2.00106 9.77872 2.00106 10.6634C2.00106 11.548 2.35248 12.3964 2.97802 13.022C3.60356 13.6475 4.45197 13.9989 5.33662 13.9989C6.22126 13.9989 7.06967 13.6475 7.69521 13.022L13.8219 6.89529C14.0822 6.63494 14.5043 6.63494 14.7647 6.89529C15.025 7.15564 15.025 7.57775 14.7647 7.8381L8.63802 13.9648C7.76243 14.8404 6.57488 15.3323 5.33662 15.3323C4.09835 15.3323 2.9108 14.8404 2.03521 13.9648C1.15962 13.0892 0.667725 11.9016 0.667725 10.6634C0.667725 9.42509 1.15962 8.23754 2.03521 7.36196L8.16188 1.23529C8.78728 0.609891 9.6355 0.258545 10.5199 0.258545C11.4044 0.258545 12.2526 0.60989 12.878 1.23529C13.5034 1.86069 13.8548 2.70891 13.8548 3.59336C13.8548 4.47781 13.5034 5.32603 12.878 5.95143L6.74469 12.0781C6.36947 12.4533 5.86058 12.6641 5.32995 12.6641C4.79932 12.6641 4.29042 12.4533 3.91521 12.0781C3.54 11.7029 3.32921 11.194 3.32921 10.6634C3.32921 10.1327 3.54 9.62384 3.91521 9.24862L9.57549 3.59501C9.83599 3.33482 10.2581 3.33506 10.5183 3.59557C10.7785 3.85607 10.7782 4.27818 10.5177 4.53838L4.85802 10.1914C4.73303 10.3166 4.66254 10.4865 4.66254 10.6634C4.66254 10.8404 4.73286 11.0101 4.85802 11.1353C4.98318 11.2605 5.15294 11.3308 5.32995 11.3308C5.50696 11.3308 5.67671 11.2605 5.80188 11.1353L11.9352 5.00862C12.3104 4.6333 12.5214 4.12407 12.5214 3.59336C12.5214 3.06253 12.3106 2.55345 11.9352 2.1781C11.5599 1.80275 11.0508 1.59188 10.5199 1.59188Z"
        fill={stroke}
      />
    </svg>
  )
}

export default PaperClip

PaperClip.propTypes = {
  size: PropTypes.number,
  stroke: PropTypes.string,
}

PaperClip.defaultProps = {
  size: 16,
  stroke: "#959DA8",
}
