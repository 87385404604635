import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Modal,
  Button,
  Input,
} from "@elevate_security/elevate-component-library"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import { createNewGroupWithFilters as createNewGroupWithFiltersReputation } from "@src/services/apis/reputation"
import { createNewGroupWithFilters } from "@src/services/apis/campaigns"
import { TARGET_TYPE } from "@src/scenes/IndividualRiskAnalysis/utils"
import { useSelectedIndividualState } from "../../SelectedIndividualProviders"
import { showToast } from "@src/services/redux/toasts/actions"
import { SpaceBetween } from "./CreateStaticGroupModal.styles"
import { getIndividualCount } from "../groupHelpers"
import pluralize from "pluralize"

export function CreateStaticGroupModal({ isOpen, onClose, filters }) {
  const selectedIndividuals = useSelectedIndividualState()
  const { riskTableCount } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const { advanced_filters } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const dispatch = useDispatch()
  const onCreateFailure = () => {
    dispatch(showToast("Failed to create group. Please try again.", "error"))
  }
  const onCreateSuccess = (groupName) => {
    dispatch(showToast(`Successfully created group "${groupName}".`))
  }
  const onCreateGroup = selectedIndividuals.checkedAll
    ? createNewGroupWithFiltersReputation
    : createNewGroupWithFilters

  return (
    <CreateStaticGroupModalPresentation
      isOpen={isOpen}
      onClose={onClose}
      filters={filters}
      selectedIndividuals={selectedIndividuals}
      onCreateGroup={onCreateGroup}
      onCreateFailure={onCreateFailure}
      onCreateSuccess={onCreateSuccess}
      selectedIndividualCount={riskTableCount}
      advancedFilters={advanced_filters}
    />
  )
}

CreateStaticGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.string.isRequired,
}

/*
 * Presentational helper component that separates the modal visual elements/state from the global redux state
 */
export function CreateStaticGroupModalPresentation({
  isOpen,
  onClose,
  filters,
  selectedIndividuals,
  selectedIndividualCount,
  onCreateGroup,
  onCreateFailure,
  onCreateSuccess,
  advancedFilters,
}) {
  const { checked, checkedAll, unChecked } = selectedIndividuals

  const [groupName, setGroupName] = useState("")
  const [creatingGroup, setCreatingGroup] = useState(false)

  const handleCreateGroup = async () => {
    const queryTargetIfFiltersPresent = filters
      ? TARGET_TYPE.QUERY
      : TARGET_TYPE.EVERYONE
    const targetingType = !checkedAll
      ? TARGET_TYPE.IDS
      : queryTargetIfFiltersPresent
    setCreatingGroup(true)
    const { data, error } = await onCreateGroup(
      checkedAll ? filters : "",
      {
        targeting_type: targetingType,
        target_ids: checked,
        group_name: groupName,
        do_not_target_ids: unChecked,
      },
      checkedAll ? advancedFilters : "",
    )
    setCreatingGroup(false)
    if (error) {
      onCreateFailure(error)
    } else {
      onCreateSuccess(data.group.name)
      onClose()
    }
  }

  const individualsToBeAddedCount = getIndividualCount(
    selectedIndividualCount,
    selectedIndividuals,
  )

  const individualText = pluralize("individual", individualsToBeAddedCount)

  return (
    <Modal
      header={<Typography.H1>Create Manual Group</Typography.H1>}
      isOpen={isOpen}
      onClose={onClose}
      rightButtons={
        <>
          <Button theme="ghost" onClick={onClose} disabled={creatingGroup}>
            Cancel
          </Button>
          <Button
            disabled={creatingGroup || !groupName.trim()}
            onClick={handleCreateGroup}
          >
            {creatingGroup ? "Creating Group..." : "Create Group"}
          </Button>
        </>
      }
    >
      <SpaceBetween>
        <div>
          <Typography.Body>Group Name</Typography.Body>
          <Input
            width="full"
            disabled={creatingGroup}
            value={groupName}
            onChange={(value) => {
              setGroupName(value)
            }}
          />
        </div>
        <Typography.Body>
          {individualsToBeAddedCount} {individualText} will be added to the
          group.
        </Typography.Body>
      </SpaceBetween>
    </Modal>
  )
}

CreateStaticGroupModalPresentation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.string.isRequired,
  selectedIndividuals: PropTypes.shape({
    checkedAll: PropTypes.bool.isRequired,
    checked: PropTypes.array.isRequired,
    unChecked: PropTypes.array.isRequired,
  }),
  selectedIndividualCount: PropTypes.number,
  onCreateFailure: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  advancedFilters: PropTypes.array,
}
