import React from "react"

import { TAG_INCIDENTS_CROWDSTRIKE } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { ActionGroups } from "./ActionGroups"
import { ActionConfig } from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionConfigs/actionConfigTypes"
import { EditTagIncidentsCrowdstrike } from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditTagIncidentsCrowdstrike"

type Payload = {
  action_type: typeof TAG_INCIDENTS_CROWDSTRIKE
  matching_method: "delta" | "snapshot"
  reopen_recently_closed_incidents: boolean
  tags: string[]
}

const config: ActionConfig<Payload> = {
  label: "Tag incidents in CrowdStrike",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: TAG_INCIDENTS_CROWDSTRIKE,
    reopen_recently_closed_incidents: false,
    matching_method: "delta",
    tags: [],
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("crowdstrike-falcon", "risky-users"),
  render: ({ details, updateAction }) => {
    return (
      <EditTagIncidentsCrowdstrike
        action={details}
        updateAction={updateAction}
      />
    )
  },
}

export default config
