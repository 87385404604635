import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 15px;
  padding-bottom: 15px;
  background-color: #f5f6f8;
  border-radius: 4px;
  padding: 0px 24px;
`

export const TabContainer = styled.div`
  [class^="Tabsstyles__TabsHeaderContainer-sc-1x30v42-1 kQcbck"] {
    margin: 0 !important;
  }
`

export const EmailContainer = styled.div`
  margin-left: 0px;
  margin-top: 20px;
  width: 100%;
`

export const EmailHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-size: 16px;
  font-weight: bold;
  color: black;
  strong {
    margin-left: 10px;
  }
`

export const AttackFrameworkWrapper = styled.div`
  margin-top: 15px;
  padding-bottom: 15px;
  background-color: ${theme.colors.gray.opaque["050"]};
  border-radius: 4px;
  padding: 24px;
  .provider {
    margin-top: 24px;
    color: ${theme.text.color.default};
    strong {
      color: ${theme.colors.primary["500"]};
    }
  }
`

export const PolicyPreviewWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`
