import React, { Children, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons"

import {
  Typography,
  IconAction,
} from "@elevate_security/elevate-component-library"

const { Text } = Typography

import {
  NotificatonContainer,
  RadioButtonContainer,
  TooltipContent,
  IconContainer,
} from "./styles"
import { generateUUID } from "@src/utils/string"

const icons = {
  snapshot: faUsers,
  delta: faUserPlus,
  default: undefined,
}

const iconSelector = (iconType) => icons[iconType] ?? icons["default"]

const TooltipBody = () => (
  <TooltipContent>
    <p>
      The matching method is how the individuals that matched the criteria will
      be processed for this action.
    </p>
    <ul>
      <li>
        <strong>Every individual matched</strong> - notifies all members of the
        current cohort each time the rule runs regardless of who was in the
        cohort previously.
      </li>
      <li>
        <strong>Only net new individuals matched</strong> - notifies only the
        net-new members that are entering the cohort on the current execution.
      </li>
    </ul>
  </TooltipContent>
)

export const NotificationRadioButtonGroup = ({
  selected,
  onChange,
  options,
  name = undefined,
  title,
  titleStyle = undefined,
  titleColor,
  titleFontWeight,
  disabled = false,
  containerPadding,
}) => {
  const radioGroupName = useMemo(() => name ?? generateUUID(), [name])
  return (
    <NotificatonContainer padding={containerPadding}>
      <Text style={titleStyle} color={titleColor} fontWeight={titleFontWeight}>
        {title}
      </Text>
      <IconContainer>
        <IconAction
          name="InfoCheckOutline"
          size="xsm"
          renderTooltipOnHover
          tooltipText={<TooltipBody />}
        />
      </IconContainer>
      <RadioButtonContainer>
        {Children.toArray(
          options.map(({ value, label }) => {
            const icon = iconSelector(value)
            return (
              <label htmlFor={`${name}-${value}`}>
                <input
                  id={`${name}-${value}`}
                  type="radio"
                  name={radioGroupName}
                  value={value}
                  onChange={onChange}
                  checked={selected === value}
                  disabled={disabled}
                />
                {icon && <FontAwesomeIcon icon={icon} />}
                {label}
              </label>
            )
          }),
        )}
      </RadioButtonContainer>
    </NotificatonContainer>
  )
}
