import React from "react"

import { Typography } from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"

import AzureApplicationsCombobox from "../../../AzureApplicationsCombobox"
import { AzureEnterpriseApplicationsRoles } from "../../../AzureEnterpriseApplicationsRoles/AzureEnterpriseApplicationsRoles"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"

import { ActionSelect } from "./ActionSelect"
import { AzureAdGroupsComboBox } from "../../../AzurePolicyGroup"

export type BaseAzureEnterpriseAppPayload = {
  matching_method: "delta" | "snapshot"
  operation: "add" | "remove"
  applications: { id: string; label: string }[]
  app_role_mapping: { [key: string]: string }
  groups?: { id: string; label: string }[]
}

type Props<ActionPayload extends BaseAzureEnterpriseAppPayload> = {
  type: "users" | "groups"
  action: ActionPayload
  updateAction: <K extends keyof ActionPayload>(
    key: K,
    value: ActionPayload[K],
  ) => void
}

export function EditAzureEnterpriseApplications<
  T extends BaseAzureEnterpriseAppPayload,
>({ type, action, updateAction }: Props<T>) {
  return (
    <SpaceBetween>
      <SpaceBetween direction="row" justify="flex-start" gap="16px">
        <AzureApplicationsCombobox
          label="Select applications"
          placeholder="Select applications"
          value={action.applications.map((app) => ({
            value: app.id,
            label: app.label,
          }))}
          onChange={(items: { label: string; value: string }[]) => {
            updateAction(
              "applications",
              items.map((item) => ({ id: item.value, label: item.label })),
            )
          }}
        />
      </SpaceBetween>
      {type === "groups" && (
        <SpaceBetween direction="row" justify="flex-start" gap="16px">
          <Typography.Text
            fontWeight="bold"
            fontSize="700"
            style={{ width: "185px" }}
          >
            Select groups
          </Typography.Text>
          <div style={{ width: "317px" }}>
            <AzureAdGroupsComboBox
              defaultValue={action.groups ?? []}
              onChange={(items: { label: string; value: string }[]) => {
                updateAction(
                  "groups",
                  items.map((item) => ({ id: item.value, label: item.label })),
                )
              }}
            />
          </div>
        </SpaceBetween>
      )}
      <ActionSelect
        type={type}
        value={action.operation}
        onChange={(value) => {
          updateAction("operation", value)
        }}
      />
      {action.operation === "add" && (
        <AzureEnterpriseApplicationsRoles
          state={{
            update_info: {
              app_ids: action.applications.map((app) => app.id),
              app_role_mapping: action.app_role_mapping,
            },
          }}
          setState={({
            update_info,
          }: {
            update_info: { app_role_mapping: { [key: string]: string } }
          }) => {
            updateAction("app_role_mapping", update_info.app_role_mapping)
          }}
        />
      )}
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e: any) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
        containerPadding="0"
      />
    </SpaceBetween>
  )
}
