import styled from "styled-components"

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    display: flex !important;
    flex-direction: column !important;
    position: fixed !important;
    width: calc(100% - 20px) !important;
    max-width: fit-content !important;
    max-height: 100vh !important;
    min-width: 800px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow:
      0 1px 3px 0 hsl(212deg 19% 28% / 10%),
      0 4px 32px 0 hsl(212deg 19% 28% / 10%);
    z-index: 10001;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 40px 40px 34px;

    [class^="Modalstyles__ModalHeader"] {
      background-color: #ffffff;
      border-radius: 8px;
    }
    [class^="Modalstyles__ModalContent"] {
      background-color: #ffffff;
      padding: 0px;
    }
    [class^="Modalstyles__ModalFooter"] {
      display: flex;
      background-color: #ffffff;
      margin-top: 10px;
      padding: 24px 24px 24px 24px;
      border-radius: 8px;
    }
    [class^="Modalstyles__ModalHeader"] {
      padding: 0px 0px 24px;
      margin-bottom: 10px;
    }
  }
`
