import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  resetQueryRule,
  setQueryBuilderSchema,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import { getQueryBuilderSchema } from "@src/services/apis/policies"
import CriteriaQueryBuilder from "../CriteriaQueryBuilder"

const ExternalCriteriaQueryBuilder = ({
  defaultQueryRule,
  onQueryRulesChange,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const getSchema = async () => {
      const response = await getQueryBuilderSchema()
      if (response.data) {
        dispatch(setQueryBuilderSchema(response.data))
      }
    }

    // If the org isn't configured with R3s enabled, the builder schema isn't
    // guaranteed to be loaded, so make sure we load it not matter what
    getSchema()

    return () => dispatch(resetQueryRule())
  }, [dispatch])

  return (
    <CriteriaQueryBuilder
      defaultQueryRule={defaultQueryRule}
      onQueryRulesChange={onQueryRulesChange}
      title=""
    />
  )
}

export default ExternalCriteriaQueryBuilder
