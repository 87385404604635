import React from "react"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { Typography } from "@elevate_security/elevate-component-library"
import { humanize } from "@src/utils/string"

export function DetectionDetails(props) {
  const IMPOSSIBLE_LOGIN_DETAILS_TO_SHOW = {
    "People details": ["job_title", "department", "expected_country"],
    "Detection details": [
      "suspicious_country",
      "ip_address",
      "associated_events",
    ],
  }
  const defaultPeopleDetails = ["job_title", "department", "location", "email"]
  const eventTypeDetailsToShow = {
    "identity-impossible-login-succeeded": [
      IMPOSSIBLE_LOGIN_DETAILS_TO_SHOW,
      props,
    ],
    "identity-impossible-login-failed": [
      IMPOSSIBLE_LOGIN_DETAILS_TO_SHOW,
      props,
    ],
    default: [
      {
        "People details": defaultPeopleDetails,
        "Detection details": Object.keys(props.details || {}).filter(
          (key) => ![...defaultPeopleDetails, "person_nid"].includes(key),
        ),
      },
      props.details || {},
    ],
  }

  const displayField = (value) => {
    let displayValue = value
    if (typeof value !== "string") {
      displayValue = JSON.stringify(value)
    }
    return displayValue || "-"
  }

  const [sections, root] =
    eventTypeDetailsToShow[props.details?.event_type || "default"] ||
    eventTypeDetailsToShow.default

  return (
    <SpaceBetween>
      {Object.entries(sections).map(([sectionTitle, sectionFields]) => {
        return (
          <SpaceBetween size="sm" key={sectionTitle}>
            <Typography.H3 fontWeight="heavy" color="700">
              {sectionTitle}
            </Typography.H3>
            {sectionFields.map((field) => {
              return (
                <div key={field}>
                  <strong>{humanize(field)}</strong>
                  <div>{displayField(root[field])}</div>
                </div>
              )
            })}
          </SpaceBetween>
        )
      })}
    </SpaceBetween>
  )
}
