import styled from "styled-components"

export const DropdownWrapper = styled.div`
  margin-bottom: 32px;
`
export const DropdownItem = styled.div`
  :first-child {
    margin-top: -40px;
  }
`
export const DropdownTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c9cfd6;
  padding: 48px 0 0 0;
  cursor: pointer;
  z-index: 0;
`
export const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: ${({ isOpen }) => (!isOpen ? 0 : 1)};
  max-height: ${({ isOpen }) => (!isOpen ? 0 : "max-content")};
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease;
`

export const Column = styled.div``

export const DropdownButton = styled.button.attrs({ type: "button" })`
  border: 0;
  background: none;
  padding: 0;
  outline: 0;
  transition: ease-out transform 350ms;
  transform: rotate(${({ isOpen }) => (!isOpen ? "180deg" : "0deg")});
  &:focus,
  &:active {
    outline: 0;
  }
`
