import styled from "styled-components"
import colors from "@src/utils/colors"

export const EmailPreviewWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const EmailPreviewContainerWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 4px;
`

export const EmailPreviewHeader = styled("div")`
  background-color: #eef0f3;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #565d66;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

export const EmailPreviewHeaderLabel = styled("div")`
  font-size: 14px;
  font-weight: 700;
  width: 60px;
  text-align: right;
`

export const EmailPreviewContent = styled("div")`
  background-color: #ffffff;
  padding: 10px 25px;
`

export const ErrorText = styled.div`
  color: ${colors.error};
  padding: 8px 0 0 0;
`
