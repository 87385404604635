import { Link } from "react-router-dom"
import styled from "styled-components"

export const TableWrapper = styled.div`
  position: relative;
  margin-top: 26px;

  [class^="Tablestyles__TableContainer"] {
    padding: 0 !important;
    /* Stick table header at top */
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      position: sticky;
      top: 0;
      background: white;
      overflow: visible;
      padding-right: 0;
      &:last-child {
      }
      [class^="Tablestyles__TableRow"] {
        padding: 0;
        [class^="Tablestyles__TableData"] {
          padding-top: 12px;
          padding-bottom: 12px;
          background: white;
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      height: calc(100vh - 250px);
      > [class^="Tablestyles__TableRow"] {
        > [class^="Tablestyles__TableData"] {
          &:last-child {
            padding: 0 5px;
          }
        }
      }
      [class^="Tablestyles__TableRow"]
        [class^="Tablestyles__TableData"][data-column-key="checkbox"]
        input {
        margin-left: 6px;
      }
    }
  }
  [class^="Tablestyles__TableRow"] {
    padding: 12px 0;
    margin-top: 0;
    height: fit-content;
    align-items: baseline;
  }
  [class^="Tablestyles__TableData"] {
    max-width: 100% !important;
    &:first-child {
      min-width: 40%;
      padding-right: 5px;
    }
    &:last-child {
      max-width: 25% !important;
      min-width: unset;
    }
    > .span {
      display: block;
      width: 100%;
    }
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      color: #7b828a;
    }
  }
  [class^="Tablestyles__TableNavigationPageNumber-sc-"] {
    width: unset;
    [class^="Tablestyles__TableNavigation"] {
      margin-left: 3px;
    }
  }
`

export const PolicyActionLogWrapper = styled.div`
  width: 100%;
  margin: 0 11px;
`

export const Row = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  ${(props) => (!props.noBorder ? "border-bottom: 1px solid #dde1e7;" : "")}
`

export const Col = styled("div")`
  width: ${(props) => (props.width ? props.width : "auto")};
  text-transform: uppercase;
  color: ${(props) => (props.header ? "#7B828A" : "#565D66")};
  font-weight: ${(props) => (props.header ? "700" : "400")};
  padding: ${(props) => (props.header ? "10px 0px" : "8px 0px")};
`

export const PolicyExecutionDropdownWrapper = styled("div")`
  border-bottom: 1px solid #dde1e7;
`

export const PolicyExecutionDropdownHeaderRow = styled("div")`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;

  svg {
    display: block;
    width: 12px;
    cursor: pointer;
  }

  & > div:first-child {
    width: 70%;
  }

  .col {
    width: 30%;
    &::first-child {
      width: 70%;
    }
  }
`

export const PolicyExecutionRowDropdownButton = styled("span")`
  width: 12px;
  height: 11px;
  border: 0;
  background: none;
  padding: 0;
  outline: 0;
  transition: ease-out transform 350ms;
  transform: rotate(${({ isOpen }) => (!isOpen ? "180deg" : "0deg")});
  &:focus,
  &:active {
    outline: 0;
  }
`

export const PolicyExecutionRowTitle = styled("div")`
  font-size: 14px;
  color: #1d1e1f;
`

export const PolicyExecutionRowTitleLink = styled(Link)`
  font-size: 14px;
  color: #15785f;
`

export const PolicyExecutionTime = styled("div")`
  color: #565d66;
  width: 30%;
  font-size: 14px;
`

export const ActionLogsListWrapper = styled("div")``

export const ActionLogsRow = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  padding: 10px 0px;
  & > div:first-child {
    width: 70%;
    padding-left: 40px;
    color: #1d1e1f;
  }
`
