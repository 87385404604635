import React, { useState } from "react"

import {
  Modal,
  Typography,
  ComboBox,
} from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { humanize } from "@src/utils/string"

import {
  ModalWrapper,
  ComboBoxWrapper,
  TemplatePreview,
} from "./InsertTemplateVariablesModal.styles"

export function InsertTemplateVariablesModal({ onInsert, options, onClose }) {
  const [category, setCategory] = useState("")
  const [preview, setPreview] = useState("")

  return (
    <ModalWrapper>
      <Modal
        isOpen={true}
        onClose={onClose}
        header={
          <Typography.H2 fontWeight="700">
            Insert criteria template variables
          </Typography.H2>
        }
        rightButtons={
          <>
            <Button theme="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onInsert(preview)
                onClose()
              }}
              disabled={!preview}
            >
              Insert
            </Button>
          </>
        }
      >
        <SpaceBetween>
          <ComboBoxWrapper>
            <SpaceBetween size="xs">
              <div>Category</div>
              <ComboBox
                styles={{
                  control: (baseStyles) => ({ ...baseStyles, width: "100%" }),
                }}
                data={Object.keys(options).map((key) => ({
                  label: humanize(key),
                  value: key,
                }))}
                placeholder="Select category"
                onChange={({ value }) => {
                  setPreview("")
                  setCategory(value)
                }}
              />
            </SpaceBetween>
            <SpaceBetween size="xs">
              <div>Field</div>
              <ComboBox
                styles={{
                  control: (baseStyles) => ({ ...baseStyles, width: "100%" }),
                }}
                data={(options[category] ?? []).map(({ label, value }) => ({
                  label,
                  value,
                }))}
                placeholder="Select value from category"
                onChange={({ value }) => {
                  setPreview(value)
                }}
              />
            </SpaceBetween>
          </ComboBoxWrapper>
          <SpaceBetween size="sm">
            <div>Preview output (read-only)</div>
            <TemplatePreview>{preview}</TemplatePreview>
          </SpaceBetween>
        </SpaceBetween>
      </Modal>
    </ModalWrapper>
  )
}
