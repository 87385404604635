import React, { useMemo } from "react"

import { Typography } from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { generateUUID } from "@src/utils/string"

type ActionSelectProps = {
  value: "add" | "remove"
  type: "users" | "groups"
  onChange: (value: ActionSelectProps["value"]) => void
}

export function ActionSelect({ value, onChange, type }: ActionSelectProps) {
  const radioName = useMemo(() => generateUUID(), [])
  const getId = (prefix = "") => `${prefix}${radioName}`
  return (
    <SpaceBetween direction="row" justify="flex-start">
      <Typography.Text color="700" fontWeight="bold" style={{ width: "185px" }}>
        Select Action
      </Typography.Text>
      <label htmlFor={getId("add_users")}>
        <input
          id={getId("add")}
          type="radio"
          name={getId()}
          onChange={(e) => {
            if (e.target.checked) {
              onChange("add")
            }
          }}
          checked={value === "add"}
        />
        Add {type} to applications
      </label>
      <label htmlFor={getId("remove")}>
        <input
          id={getId("remove_users")}
          type="radio"
          name={getId()}
          onChange={(e) => {
            if (e.target.checked) {
              onChange("remove")
            }
          }}
          checked={value === "remove"}
        />
        Remove {type} from applications
      </label>
    </SpaceBetween>
  )
}
