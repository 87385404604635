import styled from "styled-components"

export const IndividualRiskAnalysisWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  [class^="Tablestyles__TableContainer"],
  [class^="Tablestyles__TableRow"] {
    padding-left: 0;
    padding-right: 0;
  }
  [class^="Tablestyles__TableData"] {
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #7b828a;
    }
    &:first-child {
      max-width: 35px !important;
      min-width: 35px !important;
    }
    &:nth-child(2) {
      min-width: 150px !important;
      max-width: 150px !important;
      padding: 0;
    }
  }
`
