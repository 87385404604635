import styled from "styled-components"

export const LastUpdate = styled.div``

export const BoxShadow = styled.div`
  border-radius: 4px;
  border: 1px solid #c9cfd6;
  padding: 40px;
  margin-top: 18px;
  background: white;
`
