import React from "react"

const SmallError = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 352 200"
      width="352px"
      height="100px"
      className="SmallError"
    >
      <path
        d="M 0.00 0.00 L 96.13 0.00 C 95.69 2.78 95.38 5.58 94.88 8.35 C 95.08 8.66 95.27 8.96 95.46 9.27 C 95.18 10.54 94.94 11.82 94.75 13.11 C 95.13 14.77 96.44 16.27 97.31 17.70 C 98.43 16.46 99.52 15.20 100.58 13.91 C 100.14 9.20 100.86 4.66 99.66 0.00 L 352.00 0.00 L 352.00 200.00 L 0.00 200.00 L 0.00 78.70 C 0.44 80.04 0.87 81.38 1.28 82.73 C 2.20 84.00 3.14 85.27 4.07 86.54 C 5.47 83.68 7.43 80.29 7.22 77.03 C 6.92 70.67 5.60 64.72 6.97 58.37 C 6.80 57.99 6.62 57.61 6.45 57.23 C 7.07 52.48 6.58 47.81 6.09 43.08 C 4.88 41.94 3.18 41.29 1.75 40.46 C 1.23 43.51 0.93 46.55 0.00 49.51 L 0.00 0.00 Z"
        fill="#ffffff"
      />
      <path
        d="M 96.13 0.00 L 99.66 0.00 C 100.86 4.66 100.14 9.20 100.58 13.91 C 99.52 15.20 98.43 16.46 97.31 17.70 C 96.44 16.27 95.13 14.77 94.75 13.11 C 94.94 11.82 95.18 10.54 95.46 9.27 C 95.27 8.96 95.08 8.66 94.88 8.35 C 95.38 5.58 95.69 2.78 96.13 0.00 Z"
        fill="#35241d"
      />
      <path
        d="M 253.18 14.98 C 254.21 16.31 255.26 17.64 256.32 18.95 C 256.17 23.22 256.49 27.07 254.05 30.82 C 253.19 30.49 252.32 30.15 251.46 29.81 C 250.66 28.69 249.83 27.58 248.99 26.48 C 249.33 25.60 249.67 24.73 250.02 23.85 C 249.68 23.22 249.34 22.59 249.00 21.96 C 249.26 21.31 249.51 20.66 249.76 20.01 C 249.94 18.90 250.11 17.79 250.26 16.68 C 251.24 16.11 252.21 15.55 253.18 14.98 Z"
        fill="#35241d"
      />
      <path
        d="M 326.06 30.35 C 327.38 30.03 328.72 29.75 330.06 29.51 C 331.92 39.10 330.54 49.27 331.08 59.02 C 331.17 63.41 331.36 69.32 327.13 71.96 C 326.06 69.94 324.67 68.38 324.76 65.99 C 324.75 62.36 324.81 58.76 324.33 55.15 C 324.85 50.53 324.53 45.90 325.14 41.28 C 324.92 38.76 324.73 36.25 324.79 33.71 C 325.23 32.60 325.59 31.46 326.06 30.35 Z"
        fill="#35241d"
      />
      <path
        d="M 154.05 31.42 C 155.11 31.73 156.17 32.06 157.23 32.40 C 156.20 35.83 152.39 38.17 149.97 40.69 C 148.19 42.00 146.47 43.36 144.98 45.00 C 140.50 50.19 136.14 55.48 131.70 60.70 C 129.22 63.65 127.48 67.08 125.03 70.05 C 122.63 73.03 120.08 75.70 118.48 79.24 C 116.63 83.30 114.13 86.93 111.80 90.72 C 111.38 93.11 110.46 94.48 108.93 96.29 C 108.13 97.96 107.26 99.53 106.10 100.97 C 105.44 102.96 104.78 104.95 104.16 106.95 C 101.11 113.44 98.02 120.08 96.25 127.04 C 95.68 129.21 93.21 134.02 90.64 131.37 C 90.01 129.10 90.37 126.98 90.95 124.75 C 91.87 121.43 92.69 118.31 94.96 115.62 C 95.70 110.99 97.83 107.03 99.56 102.75 C 101.04 99.10 103.06 95.83 105.20 92.55 C 105.34 91.85 105.49 91.15 105.63 90.45 C 106.07 90.02 106.50 89.58 106.93 89.15 C 108.06 86.48 109.33 83.73 111.06 81.39 C 114.51 74.91 118.58 68.30 123.78 63.09 C 125.58 59.89 126.92 56.86 130.38 55.14 C 130.43 54.44 130.48 53.75 130.53 53.05 C 133.78 49.99 136.47 46.89 139.01 43.21 C 141.73 40.48 145.00 38.48 147.51 35.51 C 149.30 33.40 151.62 32.59 154.05 31.42 Z"
        fill="#35241d"
      />
      <path
        d="M 170.29 31.48 C 170.52 33.37 170.69 35.28 170.87 37.18 C 170.94 38.24 171.00 39.30 171.09 40.35 C 171.34 45.90 171.27 51.43 171.71 56.97 C 171.47 58.96 171.46 60.60 172.02 62.55 C 171.92 62.88 171.83 63.21 171.74 63.54 C 172.15 65.32 172.20 66.93 172.02 68.75 C 171.98 70.43 172.17 72.09 172.37 73.76 C 171.75 77.46 171.41 81.13 172.15 84.85 C 172.99 89.33 172.49 94.17 172.45 98.79 C 172.39 106.14 172.58 113.53 173.16 120.86 C 173.27 122.89 172.72 125.05 172.37 127.05 C 172.72 128.60 173.14 130.15 173.33 131.71 L 173.23 133.36 C 173.81 144.95 173.02 156.59 173.74 168.16 C 172.39 168.64 171.04 169.13 169.69 169.63 C 168.99 168.95 168.28 168.27 167.57 167.60 C 167.56 166.89 167.54 166.19 167.53 165.48 C 167.24 165.12 166.96 164.75 166.67 164.39 C 168.04 160.16 167.28 156.31 167.31 152.00 C 167.29 142.11 167.19 132.24 166.78 122.35 L 165.08 121.94 L 166.30 122.22 C 166.91 121.12 167.19 119.99 167.15 118.85 C 166.34 109.56 166.73 100.18 166.23 90.87 C 166.73 84.50 166.11 78.24 166.00 71.90 C 167.25 63.18 165.40 54.73 166.05 46.02 C 166.16 43.09 166.02 40.17 166.25 37.24 L 166.31 36.31 C 166.39 35.08 166.48 33.84 166.57 32.61 C 167.81 32.22 169.05 31.84 170.29 31.48 Z"
        fill="#35241d"
      />
      <path
        d="M 206.41 36.34 C 209.63 39.41 213.31 41.79 216.19 45.24 C 217.58 46.88 218.92 47.91 220.82 48.93 C 221.38 50.02 221.94 51.11 222.50 52.19 C 229.33 58.52 234.81 66.59 239.82 74.40 C 240.60 74.99 241.38 75.57 242.15 76.16 C 242.31 76.84 242.47 77.51 242.63 78.19 C 245.50 82.69 247.90 87.20 249.94 92.13 C 251.24 93.97 252.41 95.86 253.22 97.97 C 254.97 102.44 257.36 106.38 258.68 111.01 C 259.60 114.32 260.76 117.43 262.16 120.58 C 262.67 121.67 263.19 122.76 263.64 123.87 C 263.72 124.94 263.78 126.01 263.83 127.08 C 264.44 127.70 265.05 128.31 265.67 128.92 C 265.23 130.08 264.78 131.25 264.33 132.41 C 262.95 132.49 261.56 132.56 260.17 132.64 C 258.97 130.43 258.13 128.18 257.46 125.75 C 256.81 123.25 255.96 120.97 254.85 118.64 C 252.27 113.20 250.76 107.38 247.93 102.12 C 245.72 97.79 243.55 93.49 242.09 88.84 C 239.85 85.70 238.43 82.18 235.87 79.34 C 232.62 72.37 227.26 67.41 222.85 61.19 C 217.99 54.51 212.26 48.65 206.65 42.62 C 206.51 40.53 206.44 38.44 206.41 36.34 Z"
        fill="#35241d"
      />
      <path
        d="M 166.25 37.24 C 166.02 40.17 166.16 43.09 166.05 46.02 C 165.40 54.73 167.25 63.18 166.00 71.90 C 166.11 78.24 166.73 84.50 166.23 90.87 C 166.73 100.18 166.34 109.56 167.15 118.85 C 167.19 119.99 166.91 121.12 166.30 122.22 L 165.08 121.94 C 160.26 120.92 155.91 118.75 151.03 117.97 C 138.01 115.55 122.90 115.66 110.85 121.79 C 107.51 123.36 104.59 125.62 101.33 127.31 C 99.91 128.16 97.78 127.21 96.25 127.04 C 98.02 120.08 101.11 113.44 104.16 106.95 C 104.78 104.95 105.44 102.96 106.10 100.97 C 107.26 99.53 108.13 97.96 108.93 96.29 C 110.46 94.48 111.38 93.11 111.80 90.72 C 114.13 86.93 116.63 83.30 118.48 79.24 C 120.08 75.70 122.63 73.03 125.03 70.05 C 127.48 67.08 129.22 63.65 131.70 60.70 C 136.14 55.48 140.50 50.19 144.98 45.00 C 146.47 43.36 148.19 42.00 149.97 40.69 C 154.73 41.16 159.35 40.68 164.07 40.13 C 164.80 39.16 165.52 38.20 166.25 37.24 Z"
        fill="#a9ddd3"
      />
      <path
        d="M 171.09 40.35 C 183.14 39.31 194.74 41.25 206.65 42.62 C 212.26 48.65 217.99 54.51 222.85 61.19 C 227.26 67.41 232.62 72.37 235.87 79.34 C 238.43 82.18 239.85 85.70 242.09 88.84 C 243.55 93.49 245.72 97.79 247.93 102.12 C 250.76 107.38 252.27 113.20 254.85 118.64 C 255.96 120.97 256.81 123.25 257.46 125.75 C 254.98 126.74 252.50 127.72 250.02 128.71 C 243.86 125.57 237.75 122.59 231.05 120.76 C 217.69 116.66 202.94 116.65 189.52 120.47 C 184.03 121.97 178.78 124.86 173.45 127.09 C 173.42 128.63 173.42 130.17 173.33 131.71 C 173.14 130.15 172.72 128.60 172.37 127.05 C 172.72 125.05 173.27 122.89 173.16 120.86 C 172.58 113.53 172.39 106.14 172.45 98.79 C 172.49 94.17 172.99 89.33 172.15 84.85 C 171.41 81.13 171.75 77.46 172.37 73.76 C 172.17 72.09 171.98 70.43 172.02 68.75 C 172.20 66.93 172.15 65.32 171.74 63.54 C 171.83 63.21 171.92 62.88 172.02 62.55 C 171.46 60.60 171.47 58.96 171.71 56.97 C 171.27 51.43 171.34 45.90 171.09 40.35 Z"
        fill="#a9ddd3"
      />
      <path
        d="M 1.75 40.46 C 3.18 41.29 4.88 41.94 6.09 43.08 C 6.58 47.81 7.07 52.48 6.45 57.23 C 6.62 57.61 6.80 57.99 6.97 58.37 C 5.60 64.72 6.92 70.67 7.22 77.03 C 7.43 80.29 5.47 83.68 4.07 86.54 C 3.14 85.27 2.20 84.00 1.28 82.73 C 0.87 81.38 0.44 80.04 0.00 78.70 L 0.00 78.11 C 1.89 76.45 1.86 74.52 0.00 72.86 L 0.00 72.26 C 1.14 68.48 1.58 64.69 0.10 60.92 L 0.00 60.59 L 0.00 58.99 C 1.23 55.33 1.09 52.89 0.11 49.16 L 0.04 49.56 L 0.00 49.84 L 0.00 49.51 C 0.93 46.55 1.23 43.51 1.75 40.46 Z"
        fill="#35241d"
      />
      <path
        d="M 135.49 43.71 C 136.66 43.53 137.84 43.36 139.01 43.21 C 136.47 46.89 133.78 49.99 130.53 53.05 C 130.48 53.75 130.43 54.44 130.38 55.14 C 126.92 56.86 125.58 59.89 123.78 63.09 C 118.58 68.30 114.51 74.91 111.06 81.39 C 109.33 83.73 108.06 86.48 106.93 89.15 C 106.50 89.58 106.07 90.02 105.63 90.45 C 105.49 91.15 105.34 91.85 105.20 92.55 C 103.06 95.83 101.04 99.10 99.56 102.75 C 97.83 107.03 95.70 110.99 94.96 115.62 C 92.69 118.31 91.87 121.43 90.95 124.75 C 88.39 123.18 85.76 121.85 82.84 121.10 C 75.55 119.13 67.55 117.40 59.98 117.95 C 52.59 118.49 45.29 119.84 38.30 122.33 C 32.99 124.15 28.20 126.72 23.13 129.07 C 26.18 122.19 30.46 115.80 34.62 109.55 C 45.32 94.68 58.38 80.93 73.53 70.55 C 91.82 57.26 113.39 48.26 135.49 43.71 Z"
        fill="#a9ddd3"
      />
      <path
        d="M 216.19 45.24 C 217.31 45.35 218.43 45.47 219.55 45.61 C 262.33 56.21 300.73 85.53 321.43 124.54 C 322.77 126.96 323.56 129.55 324.27 132.21 C 318.70 130.30 313.48 127.72 307.75 126.23 C 293.27 122.24 278.43 119.93 263.64 123.87 C 263.19 122.76 262.67 121.67 262.16 120.58 C 260.76 117.43 259.60 114.32 258.68 111.01 C 257.36 106.38 254.97 102.44 253.22 97.97 C 252.41 95.86 251.24 93.97 249.94 92.13 C 247.90 87.20 245.50 82.69 242.63 78.19 C 242.47 77.51 242.31 76.84 242.15 76.16 C 241.38 75.57 240.60 74.99 239.82 74.40 C 234.81 66.59 229.33 58.52 222.50 52.19 C 221.94 51.11 221.38 50.02 220.82 48.93 C 218.92 47.91 217.58 46.88 216.19 45.24 Z"
        fill="#a9ddd3"
      />
      <path
        d="M 0.00 49.84 L 0.04 49.56 L 0.11 49.16 C 1.09 52.89 1.23 55.33 0.00 58.99 L 0.00 49.84 Z"
        fill="#ffffff"
      />
      <path
        d="M 108.27 57.26 C 110.85 56.97 110.79 59.42 111.91 61.13 C 106.22 66.98 98.92 69.62 92.60 74.61 C 87.56 78.64 82.09 81.94 77.46 86.49 C 72.51 90.25 67.31 93.44 63.00 98.01 C 58.77 102.35 54.00 106.07 49.90 110.54 C 47.80 109.86 45.83 110.88 45.63 107.97 C 48.31 102.99 53.76 99.66 57.48 95.38 C 63.06 91.34 67.72 86.38 73.33 82.37 C 84.50 73.25 95.73 64.46 108.27 57.26 Z"
        fill="#ffffff"
      />
      <path
        d="M 0.00 60.59 L 0.10 60.92 C 1.58 64.69 1.14 68.48 0.00 72.26 L 0.00 60.59 Z"
        fill="#ffffff"
      />
      <path
        d="M 0.00 72.86 C 1.86 74.52 1.89 76.45 0.00 78.11 L 0.00 72.86 Z"
        fill="#ffffff"
      />
    </svg>
  )
}

export default SmallError
