import React, { useState } from "react"

import {
  RoleWrapper,
  Label,
  ComboBoxContainer,
} from "./AzureEnterpriseApplicationsRoles.styles"
import { useRequest } from "ahooks"
import { ComboBox } from "@elevate_security/elevate-component-library"

import {
  getAzureADApplications,
  getAzureADApplicationsRoles,
} from "@src/services/apis/reputation"

export const AzureEnterpriseApplicationsRoles = (props) => {
  const { state, setState } = props

  const [selectedApplications, setSelectedApplications] = useState({})
  const [selectedApplicationsRoles, setSelectedApplicationsRoles] = useState({})

  const selectedApps = state?.update_info?.app_ids || []

  useRequest(
    () =>
      getAzureADApplicationsRoles({
        application__app_id__in: selectedApps.join(","),
        allowed_member_type: "User_Group",
      }),
    {
      ready: selectedApps?.length > 0,
      refreshDeps: [selectedApps],
      onSuccess: (response) => {
        const results = response?.data?.results?.map((item) => ({
          ...item,
          label: item.display_name,
          value: item.app_role_id,
        }))
        const map = {}
        results?.forEach((role) => {
          map[role.application__app_id] = !map[role.application__app_id]
            ? [role]
            : [...map[role.application__app_id], role]
        })
        setSelectedApplicationsRoles(map)
      },
    },
  )

  useRequest(() => getAzureADApplications({ id__in: selectedApps.join(",") }), {
    ready: selectedApps?.length > 0,
    refreshDeps: [selectedApps],
    onSuccess: (response) => {
      const map = {}
      response?.data?.results?.forEach((app) => {
        map[app.app_id] = app.display_name
      })
      setSelectedApplications(map)
    },
  })

  return (
    <div>
      {selectedApps?.map((id) => (
        <RoleWrapper key={id}>
          <Label>
            Select role for{" "}
            {`"${selectedApplications[id] ? selectedApplications[id] : ""}"`}
          </Label>
          <ComboBoxContainer>
            <ComboBox
              data={selectedApplicationsRoles?.[id] || []}
              value={
                selectedApplicationsRoles?.[id]?.find(
                  (item) =>
                    item.app_role_id ===
                    state?.update_info?.app_role_mapping?.[id],
                ) || {}
              }
              onChange={(item) => {
                setState({
                  ...state,
                  update_info: {
                    ...state.update_info,
                    app_role_mapping: {
                      ...state.update_info.app_role_mapping,
                      [item.application__app_id]: item.app_role_id,
                    },
                  },
                })
              }}
              isLoading={false}
              isSearchable={false}
              isMultiOption={false}
              checked={false}
              hideSelectedOptions={false}
              components={{}}
              placeholder="Select one role"
            />
          </ComboBoxContainer>
        </RoleWrapper>
      ))}
    </div>
  )
}
