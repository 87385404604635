import {
  getDashboardHistoricalEventCount,
  getDashboardHistoricalRiskScores,
  getDashboardRiskiest,
  getNewReputationRisks,
  getOrganizationReputationFactor,
  getBehaviours,
  createNewGroupWithFilters,
  getPolicyStats,
} from "@src/services/apis/reputation"
import fetcher from "@src/services/fetcher"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"
import {
  GET_ALL_API_DATA,
  GET_FACTOR_GRAPH_DATA,
  GET_TREND_OVER_TIME_CARD_DATA,
  GET_TREND_OVER_TIME_POLICY_STATS,
  GET_TRENDS_OVER_TIME,
  LATEST_EVENTS,
  SCORE_BREAKDOWN,
  SET_HIERACHICAL_DATA,
  TIME_PERIOD_VALUE,
  TREND_LOADER,
  SET_FETCHING_TREND_OVER_TIME_CARD_DATA,
  SET_FETCHING_TREND_OVER_TIME_POLICY_STATS,
  SET_ACTIVE_ACTIONS,
  SET_COMPANY_INFO,
  SET_LATEST_SCORING_DATETIME,
} from "./types"
import { getCompanyInfo as fetchCompanyInfo } from "@src/services/apis/company"
import moment from "moment"
import { getGlobalConfig } from "@src/globalConfig"

export function getOrganizationRiskLevelsNew() {
  const fieldExclusions = getGlobalConfig("RAP_FIELD_EXCLUSIONS")

  const hasDepartments = !fieldExclusions.includes("department")
  const hasLocations = !fieldExclusions.includes("location")

  return async (dispatch) => {
    const res = await Promise.all([
      getNewReputationRisks(),
      getDashboardRiskiest("individuals"),
      hasDepartments
        ? getDashboardRiskiest("departments")
        : Promise.resolve({}),
      hasLocations ? getDashboardRiskiest("locations") : Promise.resolve({}),
    ])
    const data = res.map((response) => response?.data || null)
    dispatch({
      type: GET_ALL_API_DATA,
      data: {
        data: {
          risk_scores: data[0],
          individuals: data[1],
          departments: data[2],
          locations: data[3],
        },
        apiLoader: false,
      },
    })
  }
}

export function getDashboardFactorGraphsData() {
  return async (dispatch) => {
    const response = await getOrganizationReputationFactor("attack_factor")
    const data = response.data || null
    dispatch({
      type: GET_FACTOR_GRAPH_DATA,
      data: {
        data: {
          attack_factor: data,
        },
        factor_graph_loader: false,
      },
    })
  }
}

export function setTrendLoader() {
  return (dispatch) => {
    dispatch({
      type: TREND_LOADER,
    })
  }
}

export function setLatestEvents(data, loader) {
  return async (dispatch) => {
    dispatch({
      type: LATEST_EVENTS,
      data: {
        latest_events_data: data,
        latest_events_loader: loader,
      },
    })
  }
}

export function setScoreBreakdown(data, loader) {
  return async (dispatch) => {
    dispatch({
      type: SCORE_BREAKDOWN,
      data: {
        score_breakdown: data,
        score_breakdown_loader: loader,
      },
    })
  }
}

function setFetchingTrendOverTimeCardData(isFetching) {
  return {
    type: SET_FETCHING_TREND_OVER_TIME_CARD_DATA,
    isFetching,
  }
}

export function getOrganizationTrendOverTime(months = 12) {
  return async (dispatch, getState) => {
    if (
      getState().getIn(["reputationsReducer", "fetchingTrendOverTimeCardData"])
    ) {
      // The action gets called in multiple places, so we dedupe the requests by
      // ignoring dispatches if the request is already in progress
      return
    }
    dispatch(setFetchingTrendOverTimeCardData(true))
    const res = await Promise.all([
      getDashboardHistoricalRiskScores(months),
      getDashboardHistoricalEventCount(months),
    ])
    dispatch(setFetchingTrendOverTimeCardData(false))
    const data = res.map((response) => response?.data || null)

    dispatch({
      type: GET_TREND_OVER_TIME_CARD_DATA,
      data: {
        data: {
          historical_risk_scores: data[0],
          events_count: Object.keys(data[1] || {}).map(
            (item) => data[1][item],
          )[0],
        },
        trend_loader: false,
      },
    })
  }
}

function setFetchingTrendOverTimePolicyStats(isFetching) {
  return {
    type: SET_FETCHING_TREND_OVER_TIME_POLICY_STATS,
    isFetching,
  }
}

export function getTrendOverTimePolicyStats(months = 12, es_person_id = null) {
  const startDate = months
    ? `${moment().subtract(months, "month").format("YYYY-MM")}-01`
    : ""

  return async (dispatch) => {
    dispatch(setFetchingTrendOverTimePolicyStats(true))

    const { data } = await getPolicyStats(startDate, es_person_id)

    dispatch(setFetchingTrendOverTimePolicyStats(false))
    dispatch({
      type: GET_TREND_OVER_TIME_POLICY_STATS,
      data: {
        data: {
          policy_stats: data,
        },
      },
    })
  }
}

export const setHierachicalData = (hierachicalData) => ({
  type: SET_HIERACHICAL_DATA,
  hierachicalData,
})
export const setTrendOverTime = (trendOverTime) => ({
  type: GET_TRENDS_OVER_TIME,
  trendOverTime,
})
export const setTimePeriodValue = (timePeriodValue) => ({
  type: TIME_PERIOD_VALUE,
  timePeriodValue,
})
export function createGroupWithFilters(filters, payload) {
  return async () => {
    const { error, data } = await createNewGroupWithFilters(filters, payload)
    return {
      error,
      data,
    }
  }
}

export function setActiveActions(activeActions) {
  return {
    type: SET_ACTIVE_ACTIONS,
    data: activeActions,
  }
}

export function getActiveActions() {
  return async (dispatch) => {
    const behaviors = await getBehaviours()
    dispatch(setActiveActions(behaviors))
  }
}

export function getCompanyInfo() {
  return async (dispatch) => {
    const companyInfo = await fetchCompanyInfo()

    // We do this for backwards-compatibility with ui-elevateplatform, which
    // used to provide this value. We don't want ui-vision coupled to
    // ui-elevateplatform, so we set it here ourselves for now. In the future
    // all consumers of this value should read from redux instead of
    // localStorage, and we can remove the use of localStorage
    localStorage.setItem("company", JSON.stringify(companyInfo))

    dispatch({
      type: SET_COMPANY_INFO,
      companyInfo,
    })
  }
}

export function fetchLatestScoringDatetime() {
  return async (dispatch) => {
    const response = await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/last_scoring_datetime`,
    )
    dispatch({
      type: SET_LATEST_SCORING_DATETIME,
      latestScoringDatetime: response.data?.scoring_datetime,
    })
  }
}
