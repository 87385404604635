import React, { useState, useCallback } from "react"
import { ComboBox } from "@elevate_security/elevate-component-library"
import { useRequest } from "ahooks"
import {
  getSailPointCredentials,
  getSailPointWorkFlowData,
} from "@src/services/apis/reputation"
import { WORKFLOW_ID } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"
import SailPointIcons from "@src/scenes/RiskResponseRuleCreateScreen/components/SailPointIcons/SailPointIcons"
import {
  GroupComboBoxTitle,
  SailPointIconWrapper,
} from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditExecuteSailPointWorkflow/EditExecuteSailPointWorkflow.styles"
import { SpaceBetween } from "@src/components/SpaceBetween"

export const setSailPointFormat = (sailPointData) => {
  if (!sailPointData?.length) return []

  return sailPointData.map((workflow) => ({
    label: workflow.name?.replaceAll("_", " "),
    value: workflow?.[WORKFLOW_ID],
  }))
}

const LIMIT = 25

function SailPointCombobox({
  action,
  updateAction,
  showSecret,
  setShowSecret,
}) {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(0)
  const [totalWorkflows, setTotalWorkflows] = useState(0)

  const { data: dropdownData, loading } = useRequest(
    () =>
      getSailPointWorkFlowData({
        search: `${search}`,
        limit: LIMIT,
        offset: page * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        const data = response?.data || {}
        if (page === 0) {
          setTotalWorkflows(data?.count || 0)
          return setSailPointFormat(data?.results || [])
        } else {
          const sailPointData = setSailPointFormat(data?.results || [])
          return [...dropdownData, ...sailPointData]
        }
      },
    },
  )

  const {
    run: getSailPointCredential,
    data: hasDataCredentials,
    loading: loadingCredentials,
  } = useRequest((params) => getSailPointCredentials(params), {
    manual: true,
    formatResult: (response) => {
      if (response.status === 200) {
        const { data } = response

        Object.keys(data || {}).forEach((actionName) => {
          updateAction(actionName, data[actionName] || "")
        })
      }
      return true // this will help to understand that API has been called
    },
  })

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
    setPage(0)
  }

  const iconHandler = () => {
    if (!showSecret && !hasDataCredentials) {
      // API will call once during editing the rule
      getSailPointCredential({
        workflow_id: action[WORKFLOW_ID] || "",
      }).then(() => setShowSecret(!showSecret))
    } else {
      setShowSecret(!showSecret)
    }
  }

  const initValue =
    dropdownData?.filter(
      (item) => item.value === (action[WORKFLOW_ID] || ""),
    ) || []

  // To set initial value
  const ShowCombobox = useCallback(() => {
    return (
      <ComboBox
        data={dropdownData}
        isLoading={loading}
        defaultValue={initValue}
        clearable={false}
        onChange={(selectedItem) => {
          getSailPointCredential({
            workflow_id: selectedItem?.value || "",
          })
          updateAction(WORKFLOW_ID, selectedItem?.value || "")
        }}
        isSearchable={true}
        isMultiOption={false}
        checked={false}
        hideSelectedOptions
        closeMenuOnSelect
        components={{}}
        placeholder="Select WorkFlow"
        disabled={false}
        handleScrollBotttom={() => {
          if (dropdownData.length < totalWorkflows) {
            setPage(page + 1)
          }
        }}
        handleInputChange={(value) => handleSetSearch(value)}
      />
    )
  }, [dropdownData, initValue])

  return (
    <>
      <SpaceBetween direction="row" justify="flex-start" alignItems="center">
        <GroupComboBoxTitle>Select Workflow</GroupComboBoxTitle>
        <ShowCombobox />
      </SpaceBetween>
      <SailPointIconWrapper>
        <SailPointIcons
          showSecret={showSecret}
          iconHandler={iconHandler}
          loadingCredentials={loadingCredentials}
        />
      </SailPointIconWrapper>
    </>
  )
}

export default SailPointCombobox
