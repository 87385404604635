import React from "react"
import PropTypes from "prop-types"
import {
  SummaryStatisticsValue,
  SummaryStatisticsItem,
  RiskResponseValue,
  RiskResponseFooter,
  SummaryStatisticsTitleItem,
  SummaryStatisticsBox,
  Divider,
  IRPSummaryStatisticsBox,
  IRPDivider,
  SummaryStatisticsFooterLink,
  SummaryStatisticsFooterButton,
} from "./styles"
import { truncateNumber } from "@src/utils/numbers/utils"
import { humanize } from "@src/utils/string"
import { useTranslation } from "react-i18next"

function ShowTileData({ tileData }) {
  const gridItems = tileData.map((data) => (
    <SummaryStatisticsItem key={data.name}>
      <SummaryStatisticsValue>
        {truncateNumber(data.events)}
      </SummaryStatisticsValue>{" "}
      {data.name === "training" ? (
        <SummaryStatisticsFooterButton onClick={data.onClick}>
          {humanize(data.name)}
        </SummaryStatisticsFooterButton>
      ) : (
        <SummaryStatisticsFooterLink to={data.onClick}>
          {humanize(data.name)}
        </SummaryStatisticsFooterLink>
      )}
    </SummaryStatisticsItem>
  ))

  return gridItems
}

function IRPShowTileData({ tileData }) {
  const { t } = useTranslation("dashboard")
  const updatedLabel = {
    notifications: t(
      "summaryStatistics.irpTileDataSummary.label.notifications",
    ),
    outbound_actions: t(
      "summaryStatistics.irpTileDataSummary.label.outbound_actions",
    ),
    training: t("summaryStatistics.irpTileDataSummary.label.training"),
  }
  const gridItems = tileData.map((data) => (
    <SummaryStatisticsItem key={data.name}>
      <SummaryStatisticsValue>
        {truncateNumber(data.events)}
      </SummaryStatisticsValue>{" "}
      <SummaryStatisticsFooterButton onClick={data.onClick}>
        {humanize(updatedLabel[data.name])}
      </SummaryStatisticsFooterButton>
    </SummaryStatisticsItem>
  ))

  return gridItems
}

function ShowRiskResponseData({ riskResponseData }) {
  const { t } = useTranslation("dashboard")
  const gridItems = riskResponseData.map((tile) => (
    <SummaryStatisticsTitleItem key={tile.name}>
      <RiskResponseValue>{truncateNumber(tile.events)}</RiskResponseValue>
      <RiskResponseFooter>
        {t("summaryStatistics.riskResponses")}
      </RiskResponseFooter>
    </SummaryStatisticsTitleItem>
  ))

  return gridItems
}

export default function SummaryComponent({
  riskResponseData,
  tileData,
  direction,
}) {
  return (
    <>
      {direction === "horizontal" ? (
        <SummaryStatisticsBox>
          <ShowRiskResponseData riskResponseData={riskResponseData} />
          <Divider />
          <ShowTileData tileData={tileData} />
        </SummaryStatisticsBox>
      ) : (
        <IRPSummaryStatisticsBox>
          <ShowRiskResponseData riskResponseData={riskResponseData} />
          <IRPDivider />
          <IRPShowTileData tileData={tileData} />
        </IRPSummaryStatisticsBox>
      )}
    </>
  )
}

ShowTileData.propTypes = {
  dataList: PropTypes.array.isRequired,
}
