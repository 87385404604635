import React, { useState } from "react"
import { ComboBox } from "@elevate_security/elevate-component-library"
import { useRequest } from "ahooks"
import { getAzureADConditionalAccessPolicies } from "@src/services/apis/microsoftTeams"
import {
  AddRemoveComboboxLabel,
  IndividualsComboboxWrapper,
} from "../PolicyAzureAdCombobox/styles"
import PropTypes from "prop-types"

const PolicyAzureAdCombobox = ({
  initialValue,
  onChange,
  label,
  placeHolder,
  showLabel = true,
  addPadding = true,
  width = "317px",
}) => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalGroups, setTotalGroups] = useState(0)

  const { data, loading } = useRequest(
    () =>
      getAzureADConditionalAccessPolicies({
        search: `${search}`,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        if (page === 0) {
          setTotalGroups(response?.data?.count || 0)
          return (
            response?.data?.results?.map((item) => ({
              label: item.display_name,
              value: item.policy_id,
            })) || []
          )
        } else {
          const nextData =
            response?.data?.results?.map((item) => ({
              label: item?.display_name || "",
              value: item?.policy_id || "",
            })) || []
          return [...data, ...nextData]
        }
      },
    },
  )

  const HandleSearch = (value) => {
    if (search === value) return
    setSearch(value)
    setPage(0)
  }

  return (
    <IndividualsComboboxWrapper addPadding={addPadding} width={width}>
      {showLabel && <AddRemoveComboboxLabel>{label}</AddRemoveComboboxLabel>}
      <ComboBox
        data={data}
        value={
          data?.filter((policy) => initialValue?.includes(policy.value)) || []
        }
        onChange={onChange}
        isLoading={loading}
        isSearchable={true}
        isMultiOption={true}
        checked={true}
        hideSelectedOptions={true}
        components={{}}
        placeholder={placeHolder}
        handleScrollBotttom={() => {
          if (loading.length < totalGroups) {
            setPage(page + 1)
          }
        }}
        handleInputChange={(value) => HandleSearch(value)}
      />
    </IndividualsComboboxWrapper>
  )
}

PolicyAzureAdCombobox.propTypes = {
  initialValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
}

PolicyAzureAdCombobox.defaultProps = {
  initialValue: [],
  onChange: () => null,
  label: "Select Policies",
  placeHolder: "Select Policies",
}
export default PolicyAzureAdCombobox
