import React from "react"

import {
  Modal,
  Button,
  Header,
  Typography,
  Icon,
} from "@elevate_security/elevate-component-library"
import { DeleteContent } from "@src/scenes/RiskDetectionRulesScreen/styles"
import {
  TestActionLogContainer,
  StrongTitleTestModeDiv,
  DeleteExclamationIcon,
  StyledText,
} from "../DropdownCard/styles"
import { RISK_RESPONSE_RULES } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
const { H2, Text } = Typography

export const DeletePreview = ({ item }) => {
  // This value must be replaced once we have # of individuals affected by rule
  const individuals = 0
  const RULE_TITLE = RISK_RESPONSE_RULES
  return (
    <div>
      {individuals > 0 && (
        <TestActionLogContainer
          style={{ marginTop: "0", marginBottom: "24px" }}
        >
          <DeleteExclamationIcon>
            <Icon name="CircleExclamation" size="sm" fill="#DE6900" />
          </DeleteExclamationIcon>
          <StrongTitleTestModeDiv>
            0 Individuals{" "}
            <StyledText>
              are currently being evaluated by this rule. Removing this rule
              will stop the evaluation process.
            </StyledText>
          </StrongTitleTestModeDiv>
        </TestActionLogContainer>
      )}
      <Text>
        <DeleteContent style={{ lineHeight: "24px" }}>
          You’re about to remove <strong>{item.name}</strong> from your list of{" "}
          {RULE_TITLE.toLowerCase()}. <br />
          Are you sure you want to remove this rule?
        </DeleteContent>
      </Text>
    </div>
  )
}

const DeleteModal = ({ item, showModal, onClose, onConfirm }) => {
  const RULE_TITLE = RISK_RESPONSE_RULES

  const renderButtons = [
    <Button
      key="no"
      onClick={onClose}
      style={{ fontWeight: "700", color: "#0c7d84", background: "none" }}
    >
      No, Cancel
    </Button>,
    <Button key="yes" onClick={onConfirm}>
      Yes, Remove Rule
    </Button>,
  ]

  return (
    <Modal
      onClose={onClose}
      header={
        <Header>
          <H2 color="900">Remove {RULE_TITLE}</H2>
        </Header>
      }
      rightButtons={renderButtons}
      isOpen={showModal}
    >
      <DeletePreview item={item} />
    </Modal>
  )
}

export default DeleteModal
