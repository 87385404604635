import { SpaceBetween } from "@src/components/SpaceBetween"
import styled from "styled-components"

export const GroupComboBoxTitle = styled.div`
  flex-shrink: 0;
`

export const Wrapper = styled(SpaceBetween)`
  padding: 15px 0px;
`
