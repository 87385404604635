import styled from "styled-components"

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContent"] {
    padding: 10px 0 0 0;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  [class^="Modalstyles__ModalHeader"] {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  [class^="Modalstyles__ModalOverlayer"] {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(23, 33, 44, 0.5) !important;
  }
  [class^="Modalstyles__ModalFooter"] {
    display: flex !important;
  }
`

export const BoldText = styled.div`
  font-weight: bold;
  padding-bottom: 9px;
`
export const ModalContentWrapper = styled.div`
  font-size: 14px;
`
