import fetcher from "@src/services/fetcher"
import { getErrorStatus } from "@src/services/fetcher/utils"

import { BASES_REPUTATIONS_API_URL } from "@src/constants"

export async function getLastScoresUpdateFromReputations() {
  try {
    const response = await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/organization/last_scoring_datetime`,
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getCompanyInfo() {
  try {
    const response = await fetcher.get(
      `${REACT_APP_EP_API_URL}/organizations/current/`,
    )

    const { id, shortname, default_timezone, services, plan } = response.data

    return {
      id,
      shortname,
      default_timezone,
      services,
      plan,
      error: null,
    }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
