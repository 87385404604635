import styled from "styled-components"

export const ComboBoxWrapper = styled.div`
  [class^="ComboBoxstyles__SelectContainer-sc-2zrjff-0"] {
    min-width: 500px;
    .css-g1d714-ValueContainer {
      padding: 2px 13px;
    }
  }
  .custom-react-select__menu {
    z-index: 1000;
  }
  [class^="Checkboxstyles__InputCheckBoxWrapper-sc-1oawj0u-4"] {
    input:checked::after {
      top: 2px;
      left: 5.5px;
      width: 4px;
      height: 9px;
    }
  }
`
