import moment from "moment"
import {
  getActionName,
  getUnderscoredActionName,
} from "@src/components/ActionChart/utils"
import { isEmpty } from "@src/utils/string"

export const getSecurityRatingDescription = (value) => {
  if (isEmpty(value)) return "N/A"
  if (0 <= value && value < 2) return "Very Low"
  if (2 <= value && value < 4) return "Low"
  if (4 <= value && value < 6) return "Medium"
  if (6 <= value && value < 8) return "High"
  if (8 <= value) return "Very High"
  return "N/A"
}

export const formatDate = (months) => {
  const date = moment.now()
  return moment(date).subtract(months, "months").format("YYYY-MM-DD")
}

export const mergeActionData = (arr1, arr2) => {
  const result = []
  if (arr1 && arr2) {
    arr1?.forEach((item) => {
      arr2?.forEach((item2) => {
        if (!getActionName(item.key) || ![getActionName(item2.key)]) return
        if (item.key === item2.key) {
          result.push({
            key: item2.key,
            current: item.scores.score,
            prev: item2.scores.score,
          })
        }
      })
    })
  } else {
    arr1?.forEach((item) => {
      if (!getActionName(item.key)) return
      result.push({
        key: item.key,
        current: item.scores.score,
        prev: item.scores.score,
      })
    })
  }
  return result
}

export const DATE_RANGE = {
  "30 days": 1,
  "60 days": 2,
  "90 days": 3,
  "6 months": 6,
  "1 year": 12,
}

export const DATE_RANGE_REVERSE = Object.entries(DATE_RANGE).reduce(
  (preValue, [key, value]) => {
    return { ...preValue, [value]: key }
  },
  {},
)

export const TOOLTIP_DESCRIPTION = {
  "Human Risk Score":
    "Represents the risk of an individual in an org, relative to other members of the org. This score is a weighted average of the Attacked, Access, and Action factors. Human Risk Score (HRS) is based on a 0.0 - 10.0 scale, with 0.0 indicating least risk.",
  "Action Factor":
    "Represents the action-related risk of an individual in an org. An action is a decision made by a member of an org about security that can positively or adversely affect the org’s security posture. Some examples of actions include Simulated Phishing, Actual Phishing, Secure Browsing, Clean Desk, and Password Manager.",
  "Attacked Factor":
    "The level of how attacked an individual is and/or how many opportunities to be targeted or compromised they have faced.",
  "Access Factor":
    "The level of privileged access an individual has to a system and/or data. Access is based on an individual’s place in the organizational hierarchy, their role, and access groups imported from other tools.",
}

export const generateArray = (array1) => {
  const array2 = []
  for (let index = 0; index < array1.length; index++) {
    array2.push({
      date: array1[index].date,
      score: array1[index].score,
      score_last_month: array1[index].previous_score,
    })
  }
  return { historical_risks: array2 }
}

export const getGraphLabel = (value) => {
  if (value === 0) return ""
  if (value <= 2) return "Very Low"
  if (value <= 4) return "Low"
  if (value <= 6) return "Medium"
  if (value <= 8) return "High"
  return "Very High"
}

export const convertIndividualToGraphFormat = (response, activeActions) => {
  const data = response?.data || []

  if (!Object.keys(data).length) return {}

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (!activeActions.includes(key.replaceAll("_", "-"))) return acc
    acc[getUnderscoredActionName(key)] = value
    return acc
  }, {})
}

export const convertHierarchicalToGraphFormat = (response, activeActions) => {
  const data = response.data?.hierarchicalScores?.groups || []

  if (!data.length) return {}

  const actions = data[0].groups
    .map((item) => item.key)
    .filter((action) => activeActions.includes(action))

  let graphData = actions.reduce((acc, action) => {
    acc[getUnderscoredActionName(action)] = []
    return acc
  }, {})

  graphData = data.reduce((acc, { key, groups }) => {
    groups.forEach((action) => {
      if (!activeActions.includes(action.key)) return
      const parsedAction = getUnderscoredActionName(action.key)
      const size = acc[parsedAction].length
      const previous_score = acc[parsedAction][size - 1]?.score || null
      const score = action.scores.score
      acc[parsedAction].push({
        date: key,
        score: score !== null ? 100 - score : score,
        previous_score,
      })
    })
    return acc
  }, graphData)

  const actions_to_remove = Object.entries(graphData).reduce(
    (acc, [action, action_data]) => {
      const should_remove = action_data.reduce(
        (remove_flag_acc, monthly_data) =>
          remove_flag_acc && monthly_data.score === null,
        true,
      )
      if (should_remove === true) acc.push(action)
      return acc
    },
    [],
  )

  actions_to_remove.forEach((action) => {
    delete graphData[action]
  })

  return graphData
}
