import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { setAdvancedFilters } from "@src/services/redux/analysisIndividuals/actions"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { Button } from "@src/components/MimecastReskin"

import { CriteriaResultsActionsWrapper } from "./styles"
import { THEME_PRIMARY } from "@src/theme"

const CriteriaResultsActions = ({
  setShouldPreview,
  queryRules,
  canRefresh,
}) => {
  const dispatch = useDispatch()

  const refresh = () => {
    dispatch(setAdvancedFilters(structuredClone(queryRules)))
  }

  return (
    <CriteriaResultsActionsWrapper>
      <Button theme="secondary" onClick={() => setShouldPreview(false)}>
        <Icon name="EyeClose" fill={THEME_PRIMARY} />
        &nbsp;&nbsp;
        <span>Hide Results</span>
      </Button>
      <Button disabled={!canRefresh} onClick={refresh}>
        <span>Refresh Results</span>
      </Button>
    </CriteriaResultsActionsWrapper>
  )
}

CriteriaResultsActions.propTypes = {
  setShouldPreview: PropTypes.func.isRequired,
  queryRules: PropTypes.array.isRequired,
  canRefresh: PropTypes.bool.isRequired,
}

export default CriteriaResultsActions
