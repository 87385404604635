import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

const CONNECTOR_SIZE = 2
const SPACER_SIZE = 10
const DEFAULT_BACKGROUND = "#fdfbf8" // TODO: Move to theme
const LEVEL_ONE_BACKGROUND = theme.colors.secondary[300]
const LEVEL_THREE_BACKGROUND = theme.colors.primary[100]

const backgroundColors = [
  DEFAULT_BACKGROUND,
  LEVEL_ONE_BACKGROUND,
  DEFAULT_BACKGROUND,
  LEVEL_THREE_BACKGROUND,
]

function getBackgroundColor({ depth, isLeaf }) {
  if (depth === 0 && isLeaf) {
    // Make sure criteria without multiple conditions still gets a background color
    return LEVEL_ONE_BACKGROUND
  }

  return backgroundColors[depth % backgroundColors.length]
}

function isCriteriaStyleApplied({ depth, isLeaf }) {
  // Make sure criteria without multiple conditions still gets styling
  return depth > 0 || (depth === 0 && isLeaf)
}

const CRITERIA_SIDE_PADDING = "8px"

export const PrettyCriteria = styled.div`
  ${(props) => (props.fitToConditionWidth ? "width: fit-content;" : "")}
  border-radius: 4px;
  ${(props) =>
    isCriteriaStyleApplied(props)
      ? `background-color: ${getBackgroundColor(props)};`
      : ""}
  ${(props) =>
    isCriteriaStyleApplied(props)
      ? `padding: 6px ${CRITERIA_SIDE_PADDING};`
      : ""}
  ${(props) =>
    isCriteriaStyleApplied(props)
      ? "box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09);"
      : ""}
  ${(props) =>
    props.applyBorder && isCriteriaStyleApplied(props)
      ? `border: 1px solid ${theme.colors.gray.opaque[200]}`
      : ""};
  ${(props) =>
    props.focused
      ? /* Using box-shadow to avoid layout shift from border-width change */
        `border-color: transparent; box-shadow: 0 0 0 2px rgb(20, 197, 210)`
      : ""};
`

export const Separator = styled.hr`
  margin: 0px -${CRITERIA_SIDE_PADDING};
  height: 1px;
  background-color: ${theme.colors.gray.opaque[200]};
  border: 0;
`

export const PrettyCriteriaGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.ruleCount <= 1 ? "auto" : `${props.gutterWidth} auto 1fr`};
`

export const LogicalOperatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const VerticalSpace = styled.div`
  display: flex;
  justify-content: center;
  height: ${SPACER_SIZE}px;
`

export const HorizontalSpace = styled.div`
  display: flex;
  align-items: center;
  width: ${SPACER_SIZE}px;
`

export const ConnectorWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Connector = styled.div`
  ${(props) =>
    !props.isHidden ? `background-color: ${theme.text.color[300]};` : ""}
`

export const VerticalConnector = styled(Connector)`
  height: ${(props) =>
    props.isLast || props.isFirst
      ? `calc(50% + ${CONNECTOR_SIZE / 2}px)`
      : "100%"};
  ${(props) => (props.isLast ? "align-self: start;" : "")}
  ${(props) => (props.isFirst ? "align-self: end;" : "")}
  ${(props) => (props.isLast ? "border-bottom-left-radius: 2px;" : "")}
  ${(props) => (props.isFirst ? "border-top-left-radius: 2px;" : "")}

  width: ${CONNECTOR_SIZE}px;
  min-width: ${CONNECTOR_SIZE}px;
`

export const HorizontalConnector = styled(Connector)`
  width: 100%;
  height: ${CONNECTOR_SIZE}px;
  min-height: ${CONNECTOR_SIZE}px;
`
