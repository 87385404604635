import { useEffect, useState } from "react"
import { fetchDetections } from "./detectionsApi"

export function useDetections({ pagination, filters, orderBy, when }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [error, setError] = useState(null)

  useEffect(() => {
    if (when) {
      fetchDetections({
        limit: pagination.limit,
        offset: pagination.offset,
        filters,
        orderBy,
      })
        .then((response) => {
          setData(response.data)
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [when, pagination, filters, orderBy])

  return { loading: when && loading, data, error }
}
