import React, { useState } from "react"

import {
  PolicyExecutionDropdownWrapper,
  PolicyExecutionDropdownHeaderRow,
  PolicyExecutionRowDropdownButton,
  PolicyExecutionRowTitleLink,
  PolicyExecutionRowTitle,
  PolicyExecutionTime,
  Row,
} from "./styles"
import { theme, Icon } from "@elevate_security/elevate-component-library"
import { ActionLogsList } from "./ActionLogsList"
import { timeWithTimeZone } from "@src/utils/dates"

export const PolicyExecutionDropdown = (props) => {
  const { risk_rule_name, policy_id, created_datetime, rule_deleted } = props

  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  const TitleComponent = rule_deleted ? (
    <PolicyExecutionRowTitle>
      {risk_rule_name} (Rule Deleted)
    </PolicyExecutionRowTitle>
  ) : (
    <PolicyExecutionRowTitleLink
      to={`/engagement/vision2/risk-response-engine/risk-response-rules?policy-id=${policy_id}`}
    >
      {risk_rule_name}
    </PolicyExecutionRowTitleLink>
  )

  return (
    <PolicyExecutionDropdownWrapper>
      <PolicyExecutionDropdownHeaderRow>
        <Row noBorder={true}>
          <PolicyExecutionRowDropdownButton isOpen={open} onClick={toggle}>
            <Icon
              name="CaretUp"
              size="xxsm"
              fill={theme.colors.gray.opaque[500]}
            />
          </PolicyExecutionRowDropdownButton>
          {TitleComponent}
        </Row>
        <PolicyExecutionTime>
          {timeWithTimeZone({
            date: created_datetime,
          })}
        </PolicyExecutionTime>
      </PolicyExecutionDropdownHeaderRow>
      {open && <ActionLogsList {...props} />}
    </PolicyExecutionDropdownWrapper>
  )
}
