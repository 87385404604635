import React from "react"

import { ActionGroups } from "./ActionGroups"
import {
  ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

import { ActionConfig } from "./actionConfigTypes"

import { EditAzureCapIncludeExclude } from "../actionRenderComponents/EditAzureCapIncludeExclude"

type UserFacingActionPayload = {
  action_type: "COMPOUND_update_azuread_cap_users"
  matching_method: "delta" | "snapshot"
  policy_ids: string[]
  add_to_exclusion_list: boolean
  remove_from_exclusion_list: boolean
  add_to_inclusion_list: boolean
  remove_from_inclusion_list: boolean
}

type ServerActionPayload = {
  action_type: string
  matching_method: "delta" | "snapshot"
  update_info: {
    policy_ids: string[]
  }
}

/*
 * This is a "fake" action -- i.e. it doesn't get sent to the back-end as-is,
 * but is actually composed of up to four different actions depending on what
 * the users want to do with the CAPs they select. We want to present these
 * actions as a single action in the UI, so the config specifies additional
 * methods in order to convert from the multiple-action version to the
 * single-action version, and vice-versa.
 */
const config: ActionConfig<UserFacingActionPayload, ServerActionPayload> = {
  label: "Azure AD Conditional Access Policy - Include/exclude users",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: "COMPOUND_update_azuread_cap_users",
    matching_method: "delta",
    policy_ids: [],
    add_to_exclusion_list: false,
    remove_from_exclusion_list: false,
    add_to_inclusion_list: false,
    remove_from_inclusion_list: false,
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("azuread", "conditional-access-policy"),
  render: ({ details, updateAction }) => {
    return (
      <EditAzureCapIncludeExclude
        type="users"
        policyIds={details.policy_ids}
        onChangePolicyIds={(policyIds) => updateAction("policy_ids", policyIds)}
        includeExcludeActions={details}
        onChangeIncludeExcludeAction={(action, enabled) => {
          updateAction(action, enabled)
        }}
        matchingMethod={details.matching_method}
        onChangeMatchingMethod={(matchingMethod) =>
          updateAction("matching_method", matchingMethod)
        }
      />
    )
  },
  isRelatedServerAction: (action) => {
    return [
      ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
      REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
      ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
      REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
    ].includes(action.action_type)
  },
  convertServerActionsToUserFacingAction: (actions) => {
    return {
      action_type: "COMPOUND_update_azuread_cap_users",
      matching_method: actions[0].matching_method,
      policy_ids: Array.from(
        new Set(actions.flatMap((action) => action.update_info.policy_ids)),
      ),
      add_to_exclusion_list: actions.some(
        (action) =>
          action.action_type === ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
      ),
      add_to_inclusion_list: actions.some(
        (action) =>
          action.action_type === ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
      ),
      remove_from_exclusion_list: actions.some(
        (action) =>
          action.action_type ===
          REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
      ),
      remove_from_inclusion_list: actions.some(
        (action) =>
          action.action_type ===
          REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
      ),
    }
  },
  convertUserFacingActionToServerActions: (payload) => {
    const buildRelatedAction = (action_type: string) => {
      return {
        action_type,
        matching_method: payload.matching_method,
        update_info: {
          policy_ids: payload.policy_ids,
        },
      }
    }
    const actions: ServerActionPayload[] = []
    if (payload.add_to_inclusion_list) {
      actions.push(
        buildRelatedAction(ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP),
      )
    }
    if (payload.add_to_exclusion_list) {
      actions.push(
        buildRelatedAction(ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP),
      )
    }
    if (payload.remove_from_inclusion_list) {
      actions.push(
        buildRelatedAction(REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP),
      )
    }
    if (payload.remove_from_exclusion_list) {
      actions.push(
        buildRelatedAction(REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP),
      )
    }
    return actions
  },
}

export default config
