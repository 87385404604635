import {
  setIndividualsMailsDefineOutCome,
  setReceptionGroupDataDefineOutCome,
  setRuleActionsDefineOutCome,
  setSendViaSplunkDefineOutCome,
  setSendViaWebhookDefineOutCome,
  setServiceWebhookDefineOutCome,
  setSplunkMatchingMethodDefineOutCome,
  setWebhookMatchingMethodDefineOutCome,
  setServiceWebhookMatchingMethodDefineOutCome,
} from "@src/services/redux/RiskDetectionRules/DefineOutcome/actions"
import { isEmpty } from "@src/utils/string"

export const setIndividualsMails = (value, dispatch) => {
  dispatch(setIndividualsMailsDefineOutCome(value))
}
export const setReceptionGroupData = (value, dispatch) => {
  dispatch(setReceptionGroupDataDefineOutCome(value))
}
export const setSendViaSplunk = (value, dispatch) => {
  dispatch(setSendViaSplunkDefineOutCome(value))
}
export const setSendViaWebhook = (value, dispatch) => {
  dispatch(setSendViaWebhookDefineOutCome(value))
}
export const setServiceWebhook = (value, dispatch) => {
  dispatch(setServiceWebhookDefineOutCome(value))
}
export const setRuleActions = (ruleActions, dispatch) => {
  dispatch(setRuleActionsDefineOutCome(ruleActions))
}

export const setSplunkMatchingMethod = (value, dispatch) => {
  dispatch(setSplunkMatchingMethodDefineOutCome(value))
}
export const setWebhookMatchingMethod = (value, dispatch) => {
  dispatch(setWebhookMatchingMethodDefineOutCome(value))
}
export const setServiceWebhookMatchingMethod = (value, dispatch) => {
  dispatch(setServiceWebhookMatchingMethodDefineOutCome(value))
}

export const addRemoveRuleAction = ({
  ruleActions,
  type,
  dispatch,
  isAdd = true,
}) => {
  isAdd ? ruleActions.add(type) : ruleActions.delete(type)
  setRuleActions(new Set(ruleActions), dispatch)
}
export const setDefineOutcomeInitialData = (dispatch) => {
  setReceptionGroupData([], dispatch)
  setSendViaSplunk(false, dispatch)
  setSendViaWebhook(false, dispatch)
  setServiceWebhook(false, dispatch)
  setRuleActions(new Set(), dispatch)
}

export const isIntegrated = (services, target, targetType) => {
  const found = services.find((service) => {
    if (
      (isEmpty(targetType) && service.vendor_code === target) ||
      (!isEmpty(targetType) &&
        service.vendor_code === target &&
        service.integration_type === targetType)
    ) {
      return true
    }
    return false
  })
  return found !== undefined
}
