import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const FiltersWrapper = styled.div`
  padding: 24px;
`

export const Separator = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray.opaque[200]};
  height: 1px;
`

export const CaretWrapper = styled.div`
  padding-top: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`

export const FilterTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
`
