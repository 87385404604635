import React from "react"
import styled from "styled-components"
import { TIME_PERIOD_LIST } from "@src/scenes/HumanRiskDashboard/constants"
import { useSelector, useDispatch } from "react-redux"
import { CustomSelect } from "@src/components/MimecastReskin"
import { setTimePeriodValue } from "@src/services/redux/reputations/actions"
import { SpacingX } from "@src/components/SpacingX"

const SelectLabel = styled.label`
  font-weight: bold;
  margin: 0;
`

const TimePeriodSelectWrapper = styled(SpacingX).attrs({
  alignItems: "center",
})`
  [class^="inputstyles__InputContainer"] input {
    background-color: revert;
  }
`

export function TimePeriodSelect() {
  const dispatch = useDispatch()

  const timePeriodValue =
    useSelector((state) => state.get("reputationsReducer"))
      ?.time_period_value || ""

  return (
    <TimePeriodSelectWrapper>
      <SelectLabel>Timeframe:</SelectLabel>
      <CustomSelect
        options={TIME_PERIOD_LIST}
        defaultValue={timePeriodValue}
        onChange={(selected) => dispatch(setTimePeriodValue(selected.value))}
      />
    </TimePeriodSelectWrapper>
  )
}
