import styled from "styled-components"
import { SpaceBetween } from "@src/components/SpaceBetween"

export const GroupComboBoxTitle = styled.div`
  flex-shrink: 0;
  font-weight: 700;
  width: 115px;
`

export const Wrapper = styled(SpaceBetween)`
  padding: 15px 0;
  [class^="LoaderIcon"] {
    width: 20px;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  z-index: 0;
  input {
    background: white;
    width: 100%;
  }
`

export const SailPointIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`
