/* eslint-disable react/prop-types */
import React from "react"
import { humanize } from "@src/utils/string"
import { BarColors } from "@elevate_security/elevate-component-library/"
import {
  ScoreDiv,
  ColumnValue,
  ColumnWrapper,
  ProgressBar,
  NumberOfIndividuals,
  LinkIcon,
} from "./styles"
import { NavLink } from "react-router-dom"
import Checkbox from "@src/components/Checkbox"
import { RISK_DATA_TYPES } from "@src/constants"
import {
  BOOLEAN_VALUES_MAP,
  ColumnNameMap,
  sortNameMap,
} from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import { SkeletonLoaderFill as SkeletonLoader } from "@src/utils/skeleton"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"
import { darklyGetFlag } from "@src/utils/darkly"
import { getManagerDashboardPlan } from "@src/services/redux/company/selectors"
import { TableCellSpan } from "@src/components/TableCellSpan"
import { truncateToOneDecimalPlace } from "@src/utils/numbers/utils"
import { VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH } from "@src/theme"
import { getGlobalConfig } from "@src/globalConfig"

const getColor = (score) => {
  // No score doesn't mean zero, so we use a different color if for some reason
  // this crops up
  if (score === null || score === undefined) {
    return "lightgray"
  }
  if (score >= 8) return VERY_HIGH
  else if (score >= 6 && score < 8) return HIGH
  else if (score >= 4 && score < 6) return MEDIUM
  else if (score >= 2 && score < 4) return LOW
  else return VERY_LOW
}

const EMPTY_DASH_BAR = "--"

const Score = ({ score, showWideBar, showNewDesign }) => {
  if (showNewDesign) {
    if (score === null || isNaN(score)) {
      return (
        <ColumnWrapper>
          <ScoreDiv>{EMPTY_DASH_BAR}</ScoreDiv>
        </ColumnWrapper>
      )
    }
    return (
      <ColumnWrapper>
        <BarColors
          score={score}
          showCircle={false}
          size="small"
          sameColor={true}
          barColor={getColor(score)}
          padding={false}
          showWideBar={showWideBar || false}
          showBorder
          showScore
          formatter={truncateToOneDecimalPlace}
        />
      </ColumnWrapper>
    )
  }
  //Old progress bar design
  return (
    <ColumnWrapper>
      <ColumnValue>{score === null ? EMPTY_DASH_BAR : score}</ColumnValue>
      <ProgressBar value={score * 10} />
    </ColumnWrapper>
  )
}

const RISK_LINK = `/engagement/vision2/insights/individual/risk-analysis`
const FILTER_PARAM =
  "/engagement/vision2/insights/individual/risk-analysis?filters="
const ORDER_BY_PARAMS = "&order_by=human_risk_score desc"

const REDIRECT_RISK = {
  individual: ({ id, data }) => {
    return {
      pathname: `/engagement/vision2/${id}/profile`,
      state: { data: data },
    }
  },
  departments: ({ riskType, name }) =>
    `${FILTER_PARAM}${encodeURIComponent(
      `${RISK_DATA_TYPES[riskType].filterBy} IN ${name}`,
    )}${ORDER_BY_PARAMS}`,
  locations: ({ riskType, name }) =>
    `${FILTER_PARAM}${encodeURIComponent(
      `${RISK_DATA_TYPES[riskType].filterBy} IN ["${name}"]`,
    )}${ORDER_BY_PARAMS}`,
  managerDashboard: ({ id }) => `/engagement/vision2/manager-dashboard/${id}`,
}

export const Individual = ({
  id,
  name,
  riskType,
  width = "100%",
  marginLeft = "0",
  data = {},
  showExternalLink = false,
  toolTipText = "",
  externalLinkType = "",
  externalLinkId = "",
  allowCellWrap = false,
}) => {
  return (
    <ColumnWrapper title={name} width={width} marginLeft={marginLeft}>
      <ColumnValue>
        <TableCellSpan allowCellWrap={allowCellWrap}>
          <NavLink
            to={
              REDIRECT_RISK[riskType]({ id, riskType, name, data }) || RISK_LINK
            }
          >
            {name}
          </NavLink>
        </TableCellSpan>
      </ColumnValue>
      {showExternalLink && (
        <LinkIcon>
          <Tooltip body={toolTipText} placement="top" readOnly size="xsm">
            <NavLink
              to={
                REDIRECT_RISK[externalLinkType]({
                  id: externalLinkId,
                  riskType: externalLinkType,
                  name,
                  data,
                }) || RISK_LINK
              }
            >
              <Icon name="ExclamationOutline" stroke="#fff" />
            </NavLink>
          </Tooltip>
        </LinkIcon>
      )}
    </ColumnWrapper>
  )
}

const Manager = ({ id, name }) => (
  <ColumnWrapper title={name}>
    <ColumnValue>
      <NavLink to={`/engagement/vision2/${id}/profile`}>{name}</NavLink>
    </ColumnValue>
  </ColumnWrapper>
)

export const getSortProperty = (sort, key) => {
  if (sort?.property !== key) return {}
  return {
    asc: sort?.direction === "asc" || sort?.asc ? true : false,
  }
}
const overallLabel = { overall: "human_risk_score" }
const getColumnsFromRiskScoreExample = (
  risksNames,
  sort,
  showNewDesign,
  loading,
) => {
  if (!risksNames) return []
  return risksNames
    ?.filter((riskName) => riskName === "overall")
    .map((riskName) => {
      const name = ColumnNameMap[riskName] || riskName
      return {
        key: `${overallLabel[riskName] || riskName}`,
        header: humanize(name).toUpperCase(),
        ...getSortProperty(sort, `${overallLabel[riskName] || riskName}`),
        render: (_, row) => {
          const score = row?.human_risk_score ?? row?.[riskName]
          return loading ? (
            <center style={{ width: "90%", padding: "0px", margin: "0px" }}>
              <SkeletonLoader />
            </center>
          ) : (
            <Score
              score={score === null || isNaN(score) ? null : score?.toFixed(1)}
              showNewDesign={showNewDesign}
            />
          )
        },
      }
    })
}
const getColumnsFromActionsScoreExample = (
  risksNames,
  sort,
  showNewDesign,
  loading,
) => {
  if (!risksNames) return []
  return risksNames
    ?.filter(
      (actionName) =>
        actionName !== "overall" && actionName !== "tenure_duration",
    )
    .map((actionName) => {
      const action = sortNameMap[actionName] || actionName
      const name = ColumnNameMap[action] || action
      return {
        key: `${action}`,
        header: humanize(name).toUpperCase(),
        ...getSortProperty(sort, `${action}`),
        render: (_, row) => {
          const score =
            row?.[action] === null
              ? null
              : row?.[action]?.toFixed(1) || row?.[action] || 1
          return loading ? (
            <center style={{ width: "90%", padding: "0px", margin: "0px" }}>
              <SkeletonLoader />
            </center>
          ) : (
            <div style={{ width: "115px" }}>
              <Score score={score} showNewDesign={showNewDesign} />
            </div>
          )
        },
      }
    })
}

const getGenericField = (field, sort, loading) => {
  const humanizedField = humanize(field)
  return {
    key: field,
    header: humanizedField.toUpperCase(),
    render: (field) => {
      if (loading) {
        return (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        )
      }
      return (
        <TableCellSpan title={field}>
          {BOOLEAN_VALUES_MAP[field] ?? field ?? "-"}
        </TableCellSpan>
      )
    },
    ...getSortProperty(sort, field),
  }
}

const getColumns = (
  {
    riskType,
    risksNames,
    sort,
    checked,
    toggleCheckbox,
    onCheckAllChange,
    indeterminate,
    checkedAll,
    unChecked,
    showNewDesign,
    firstColumn,
    loading,
    behaviorTypes,
    isAdmin,
    allowCellWrap,
  },
  availableHRMetadataKeys,
) => {
  const hideMdIcon = getManagerDashboardPlan()
  const managerDashboardV2 = darklyGetFlag("manager-dashboard-v2")
  const risksColumns = getColumnsFromRiskScoreExample(
    risksNames,
    sort,
    showNewDesign,
    loading,
  )

  const actionColumns = getColumnsFromActionsScoreExample(
    behaviorTypes,
    sort,
    showNewDesign,
    loading,
  )

  const hrFields = [
    getGenericField("job_title", sort, loading),
    getGenericField("business_title", sort, loading),
    getGenericField("business_unit", sort, loading),
    getGenericField("es_manager_id", sort, loading),
    getGenericField("is_onleave", sort, loading),
    getGenericField("job_family_function_group", sort, loading),
    getGenericField("job_family_function_name", sort, loading),
    getGenericField("manager_nid", sort, loading),
    getGenericField("preferred_language", sort, loading),
    getGenericField("preferred_name", sort, loading),
    getGenericField("preferred_middle_name", sort, loading),
    getGenericField("preferred_name_local_lang", sort, loading),
    getGenericField("time_type", sort, loading),
    getGenericField("work_location_country", sort, loading),
    getGenericField("work_location_geo", sort, loading),
    getGenericField("work_location_timezone", sort, loading),
    getGenericField("worker_type", sort, loading),
    getGenericField("work_location_building", sort, loading),
    getGenericField("work_location_city", sort, loading),
    getGenericField("work_location_name", sort, loading),
    getGenericField("work_space", sort, loading),
    getGenericField("length_of_service", sort, loading),
    getGenericField("hire_date", sort, loading),
    getGenericField("is_active", sort, loading),
    getGenericField("leave_date", sort, loading),
    getGenericField("alternative_user_id_1", sort, loading),
    getGenericField("alternative_user_id_2", sort, loading),
  ]

  const filteredFields = hrFields?.filter((field) =>
    availableHRMetadataKeys?.includes(field.key),
  )

  return [
    {
      name: "checkbox-col",
      key: firstColumn?.checkbox_key,
      isHeadSort: false,
      header: getGlobalConfig("ENABLE_RAP_BULK_ACTIONS") ? (
        <div style={{ marginLeft: 5 }}>
          <Checkbox
            checked={checkedAll || indeterminate}
            onChange={onCheckAllChange}
            indeterminate={indeterminate}
          />
        </div>
      ) : null,
      render: (entity_id) => {
        if (!getGlobalConfig("ENABLE_RAP_BULK_ACTIONS")) {
          return null
        }
        return (
          <Checkbox
            checked={
              (checkedAll && !unChecked.includes(entity_id)) ||
              checked.includes(entity_id)
            }
            onChange={(e) => toggleCheckbox(entity_id)(e)}
          />
        )
      },
    },
    {
      key: firstColumn?.key,
      header: humanize(firstColumn?.header)?.toUpperCase(),
      ...getSortProperty(sort, firstColumn?.key),
      render: (_, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <Individual
            id={row.es_person_id}
            name={row.locations || row.departments || `${row.full_name}`}
            riskType={riskType || "individual"}
            showExternalLink={
              managerDashboardV2 &&
              row?.organizational_role === "manager" &&
              isAdmin &&
              hideMdIcon
            }
            toolTipText="View Manager Dashboard"
            externalLinkType="managerDashboard"
            externalLinkId={row?.es_person_id}
            allowCellWrap={allowCellWrap}
          />
        ),
    },

    {
      key: `number_of_individuals`,
      header: humanize("# of Individuals").toUpperCase(),
      ...getSortProperty(sort, `number_of_individuals`),
      render: (_, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <NumberOfIndividuals>
            {row?.risks?.number_of_individuals ||
              row?.number_of_individuals ||
              0}
          </NumberOfIndividuals>
        ),
    },
    ...risksColumns,
    {
      key: "department",
      header: humanize("department").toUpperCase(),
      render: (department) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={department} allowCellWrap={allowCellWrap}>
            {department}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "department"),
    },
    {
      key: "email",
      header: humanize("email").toUpperCase(),
      render: (email) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan
            title={email}
            allowCellWrap={allowCellWrap}
            allowWordBreak={allowCellWrap}
          >
            {email}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "email"),
    },
    {
      key: "location",
      header: humanize("location").toUpperCase(),
      render: (location) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={location}>{location}</TableCellSpan>
        ),
      ...getSortProperty(sort, "location"),
    },
    {
      key: "manager_name",
      header: humanize("manager_name").toUpperCase(),
      render: (_, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <Manager id={row.es_manager_id} name={row.manager_name} />
        ),
      ...getSortProperty(sort, "manager_name"),
    },
    ...actionColumns,
    {
      key: "access_factor",
      header: humanize("access factor").toUpperCase(),
      render: (access_factor) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={humanize(access_factor)}>
            {humanize(access_factor)}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "access_factor"),
    },
    {
      key: "action_factor",
      header: humanize("action factor").toUpperCase(),
      render: (action_factor) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={humanize(action_factor)}>
            {humanize(action_factor)}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "action_factor"),
    },
    {
      key: "organizational_role",
      header: humanize("organizational role").toUpperCase(),
      render: (organizational_role) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={humanize(organizational_role)}>
            {humanize(organizational_role)}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "organizational_role"),
    },
    {
      key: "employment_type",
      header: humanize("employment type").toUpperCase(),
      render: (employment_type) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={humanize(employment_type)}>
            {employment_type === "not_set" ? "-" : humanize(employment_type)}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "employment_type"),
    },
    {
      key: "tenure_status",
      header: humanize("tenure status").toUpperCase(),
      render: (tenure_status) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={humanize(tenure_status)}>
            {humanize(tenure_status)}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "tenure_status"),
    },
    {
      key: "tenure_duration",
      header: humanize("tenure duration").toUpperCase(),
      render: (tenure_duration) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={tenure_duration}>
            {tenure_duration}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "tenure_duration"),
    },
    {
      key: "attack_factor",
      header: humanize("attack factor").toUpperCase(),
      render: (attack_factor) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan title={humanize(attack_factor)}>
            {humanize(attack_factor)}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "attack_factor"),
    },
    ...filteredFields,
  ].filter((col) => {
    return !getGlobalConfig("RAP_FIELD_EXCLUSIONS").includes(col.key)
  })
}

export default getColumns
