import React from "react"

import { Separator, FilterTitle } from "@src/components/SimpleFilters/styles"
import MultiSelectFilter from "../IndividualProfilePage/components/MoreFilter/MultiSelectFilter"

export const ApplicationsFilters = ({ onChange, appliedFilters }) => {
  return (
    <>
      <MultiSelectFilter
        showAllFilters
        section={{
          key: "source",
          title: <FilterTitle>Source</FilterTitle>,
          items: [
            {
              label: "Azure AD",
              value: "azure_ad",
            },
          ],
        }}
        filters={appliedFilters}
        handleChange={onChange}
      />
      <Separator />
      <MultiSelectFilter
        showAllFilters
        section={{
          key: "application_type",
          title: <FilterTitle>Type</FilterTitle>,
          items: [
            {
              label: "Enterprise Application",
              value: "enterprise_application",
            },
            {
              label: "Microsoft Application",
              value: "microsoft_application",
            },
            {
              label: "Managed Identity",
              value: "managed_identity",
            },
          ],
        }}
        filters={appliedFilters}
        handleChange={onChange}
      />
      <Separator />
      <MultiSelectFilter
        showAllFilters
        section={{
          key: "criticality",
          title: <FilterTitle>Ranking</FilterTitle>,
          items: [
            {
              label: "Not Set",
              value: "not_set",
            },
            {
              label: "Critical",
              value: "critical",
            },
            {
              label: "Not Critical",
              value: "not_critical",
            },
          ],
        }}
        filters={appliedFilters}
        handleChange={onChange}
      />
    </>
  )
}
