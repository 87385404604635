import React from "react"
import styled from "styled-components"
import { alphabeticOperators, operatorLookUp } from "../constant"

// Utility components here

const Condition = styled.span`
  font-weight: bold;
`

const Operator = styled.span`
  ${(props) => (props.isAlphabetic ? "font-weight: bold;" : "")}
`

const OccurrenceText = styled.span`
  font-weight: bold;
`

const LeftOperand = styled.span`
  text-decoration: underline;
  text-underline-offset: 2px;
`

const RightOperand = styled.span``

const Prefix = styled.span`
  font-weight: bold;
`

// Utility functions here

export function convertToAnnotatedQueryString(data, groupsMap = {}) {
  return convertToQueryString(data, { annotate: true, groupsMap })
}

export function convertToQueryString(
  data,
  { annotate = false, groupsMap = {} } = {},
) {
  const queryStringTokens = []

  function getCondition(condition) {
    if (condition) {
      condition = condition.toUpperCase()
    }
    if (annotate) {
      return <Condition> {condition} </Condition>
    }
    return ` ${condition} `
  }

  function getOperator(operator) {
    const isAlphabetic = Boolean(alphabeticOperators[operator])
    operator = (operatorLookUp[operator] || operator || "").toUpperCase()
    if (annotate) {
      return <Operator isAlphabetic={isAlphabetic}> {operator} </Operator>
    }
    return ` ${operator} `
  }

  function getPrefix(record) {
    const managerHierarchyLabelsMap = {
      direct: "direct",
      recursive: "direct / indirect",
    }
    let prefix = ""
    if (record.category === "manager" && record.manager_hierarchy) {
      prefix = `Employees that are ${
        managerHierarchyLabelsMap[record.manager_hierarchy]
      } reporters of `
    }
    if (annotate) {
      return <Prefix>{prefix}</Prefix>
    }
    return prefix
  }

  function getLeftOperand(operand) {
    if (annotate) {
      return <LeftOperand>{operand}</LeftOperand>
    }
    return operand
  }

  function getRightOperand(operand, operator, category, field) {
    const dynamicCategories = {
      groups: ["elevate_security_groups"],
      applications: ["name"],
    }

    const isDynamic =
      Object.keys(dynamicCategories)?.includes(category) &&
      dynamicCategories[category]?.includes(field) &&
      Object.keys(groupsMap)?.length > 0

    if (Array.isArray(operand)) {
      operand = operand.join()
    }
    if ((operator === "in" || operator === "between") && !isDynamic) {
      operand = operand.split(",").join(", ")
    } else if (
      operator === "rbetween" ||
      operator === "rge" ||
      (operator === "rle" && !isDynamic)
    ) {
      const operandArrayValue = operand.split(",")
      operand =
        operator === "rbetween"
          ? `${operandArrayValue[0]} and ${operandArrayValue[2]} ${operandArrayValue[3]} AGO`
          : `${operandArrayValue[0]} ${operandArrayValue[1]} AGO`
    } else if ((operator === "in" || operator === "between") && isDynamic) {
      operand = operand
        .split(",")
        .map((item) => groupsMap[item])
        ?.join(", ")
      operand = `"${operand}"`
    }

    if (
      operator !== "between" &&
      operator !== "rbetween" &&
      operator !== "rge" &&
      operator !== "rle" &&
      operand &&
      !isNumeric(operand) &&
      !isDynamic
    ) {
      operand = `"${operand}"`
    } else if (
      operator !== "in" &&
      operator !== "between" &&
      operand &&
      !isNumeric(operand) &&
      isDynamic
    ) {
      operand = `"${groupsMap[operand]}"`
    }

    if (annotate) {
      return <RightOperand>{operand}</RightOperand>
    }
    return operand
  }

  function getOccurrenceText(text, followingOperator) {
    text = text.toUpperCase()
    if (!operatorLookUp[followingOperator]) {
      text = text + " "
    }
    if (annotate) {
      return <OccurrenceText> {text}</OccurrenceText>
    }

    return ` ${text}`
  }

  function addTokens(...tokens) {
    queryStringTokens.push(...tokens)
  }

  const _convertToQueryString = (data, condition) => {
    if (condition) {
      condition = getCondition(condition)
    }
    data?.forEach((record, index) => {
      if (record?.rules?.length) {
        addTokens(index === 0 ? "" : condition, "(")
        _convertToQueryString(record.rules, record.condition)
        addTokens(`)`)
      } else if (record.category) {
        if (record?.compound_conditions?.length) {
          addTokens(index === 0 ? "" : condition, "(")
          {
            /* Prefix on category */
          }
          addTokens(getPrefix(record))
          record.compound_conditions?.forEach((cc, indx) => {
            addTokens(
              getLeftOperand(`${record.category}.${cc.field}`),
              getOperator(cc.operator),
              getRightOperand(cc.value, cc.operator, record.category, cc.field),
            )
            addTokens(
              record.compound_conditions.length - 1 > indx
                ? getCondition("and")
                : "",
            )
          })
          addTokens(")")
        } else {
          addTokens(index === 0 ? "" : condition)
          {
            /* Prefix on category */
          }
          addTokens(getPrefix(record))
          addTokens(
            getLeftOperand(`${record.category}.${record.field}`),
            getOperator(record.operator),
            getRightOperand(
              record.value,
              record.operator,
              record.category,
              record.field,
            ),
          )
        }
        const eventOccurrenceString =
          record?.eventOccurrences?.length > 0
            ? [
                getOccurrenceText(
                  "that occurred",
                  record.eventOccurrences[0].value[0],
                ),
                getOperator(record.eventOccurrences[0].value[0]),
                `${record.eventOccurrences[0].value[1]}${
                  record.eventOccurrences[0].value[0] === "between"
                    ? `, ${record.eventOccurrences[0].value[4]}`
                    : ""
                } times in ${record.eventOccurrences[0].value[2]} ${
                  record.eventOccurrences[0].value[3]
                }`,
              ]
            : [""]
        {
          /* Postfix on category */
        }
        addTokens(...eventOccurrenceString)
      }
    })
  }

  _convertToQueryString([data])

  if (annotate) {
    return (
      <span>
        {queryStringTokens.map((node, i) => (
          <React.Fragment key={i}>{node}</React.Fragment>
        ))}
      </span>
    )
  }
  return queryStringTokens.join("")
}

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
function isNumeric(str) {
  return !isNaN(str) && !isNaN(parseFloat(str))
}
