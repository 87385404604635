import { RISK_DATA_TYPES } from "@src/constants"
import useQuery from "@src/services/hooks/useQuery"
import { useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"

export default function useRiskAnalysisURLParam({
  riskTypeOverride = null,
} = {}) {
  const filters = useQuery().get("filters")
  const order_by = useQuery().get("order_by")
  const history = useHistory()
  const { riskType: riskTypeParam } = useParams()
  const riskType = riskTypeOverride || riskTypeParam || "individual"

  const updateUrlWithNewFilters = useCallback(
    (newFilters) => {
      history.push({
        search: `filters=${encodeURIComponent(newFilters) || ""}&order_by=${
          order_by || RISK_DATA_TYPES[riskType].defaultOrderBy
        }`,
      })
    },
    [order_by, location, riskType],
  )

  const updateUrlWithNewOrderBy = useCallback(
    (newOrderBy) => {
      history.push({
        search: `filters=${filters || ""}&order_by=${
          newOrderBy || RISK_DATA_TYPES[riskType].defaultOrderBy
        }`,
      })
    },
    [filters, location, riskType],
  )

  const clearUrl = useCallback(() => {
    history.push({
      search: RISK_DATA_TYPES[riskType].defaultOrderBy,
    })
  }, [location, riskType])

  return {
    filters, //complete Query filters= param
    order_by, //complete Query order_by= param
    clearUrl, // helper to clean the all the filters
    updateUrlWithNewFilters, // helper to update the filters
    updateUrlWithNewOrderBy,
  }
}
