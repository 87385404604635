import React from "react"

import { ActionGroups } from "./ActionGroups"
import {
  ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { uniq } from "@src/utils/uniq"

import { ActionConfig } from "./actionConfigTypes"

import { EditAzureCapIncludeExclude } from "../actionRenderComponents/EditAzureCapIncludeExclude"

type UserFacingActionPayload = {
  action_type: "COMPOUND_update_azuread_cap_applications"
  matching_method: "delta" | "snapshot"
  policy_ids: string[]
  applications: { id: string; label: string }[]
  add_to_exclusion_list: boolean
  remove_from_exclusion_list: boolean
  add_to_inclusion_list: boolean
  remove_from_inclusion_list: boolean
}

type ServerActionPayload = {
  action_type: string
  matching_method: "delta" | "snapshot"
  update_info: {
    policy_ids: string[]
    application_ids: string[]
    apps_meta: { appId: string; label: string; value: string }[]
  }
}

/*
 * This is a "fake" action -- i.e. it doesn't get sent to the back-end as-is,
 * but is actually composed of up to four different actions depending on what
 * the users want to do with the CAPs they select. We want to present these
 * actions as a single action in the UI, so the config specifies additional
 * methods in order to convert from the multiple-action version to the
 * single-action version, and vice-versa.
 */
const config: ActionConfig<UserFacingActionPayload, ServerActionPayload> = {
  label: "Azure AD Conditional Access Policy - Include/exclude applications",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: "COMPOUND_update_azuread_cap_applications",
    matching_method: "delta",
    policy_ids: [],
    applications: [],
    add_to_exclusion_list: false,
    remove_from_exclusion_list: false,
    add_to_inclusion_list: false,
    remove_from_inclusion_list: false,
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("azuread", "conditional-access-policy"),
  render: ({ details, updateAction }) => {
    return (
      <EditAzureCapIncludeExclude
        type="applications"
        policyIds={details.policy_ids}
        applications={details.applications.map((group) => ({
          value: group.id,
          label: group.label,
        }))}
        onChangeApplications={(applications) =>
          updateAction(
            "applications",
            applications.map((application) => ({
              id: application.value,
              label: application.label,
            })),
          )
        }
        onChangePolicyIds={(policyIds) => updateAction("policy_ids", policyIds)}
        includeExcludeActions={details}
        onChangeIncludeExcludeAction={(action, enabled) => {
          updateAction(action, enabled)
        }}
        matchingMethod={details.matching_method}
        onChangeMatchingMethod={(matchingMethod) =>
          updateAction("matching_method", matchingMethod)
        }
      />
    )
  },
  isRelatedServerAction: (action) => {
    return [
      ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
      REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
      ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
      REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
    ].includes(action.action_type)
  },
  convertServerActionsToUserFacingAction: (actions) => {
    return {
      action_type: "COMPOUND_update_azuread_cap_applications",
      matching_method: actions[0].matching_method,
      policy_ids: uniq(
        actions.flatMap((action) => action.update_info.policy_ids),
      ),
      applications: uniq(
        actions.flatMap((action) =>
          action.update_info.apps_meta.map(({ label, value }) => ({
            id: value,
            label,
          })),
        ),
        (application) => application.id,
      ),
      add_to_exclusion_list: actions.some(
        (action) =>
          action.action_type === ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
      ),
      add_to_inclusion_list: actions.some(
        (action) =>
          action.action_type === ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
      ),
      remove_from_exclusion_list: actions.some(
        (action) =>
          action.action_type === REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
      ),
      remove_from_inclusion_list: actions.some(
        (action) =>
          action.action_type === REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
      ),
    }
  },
  convertUserFacingActionToServerActions: (payload) => {
    const buildServerAction = (action_type: string): ServerActionPayload => {
      return {
        action_type,
        matching_method: payload.matching_method,
        update_info: {
          policy_ids: payload.policy_ids,
          application_ids: payload.applications.map(({ id }) => id),
          apps_meta: payload.applications.map(({ id, label }) => ({
            value: id,
            appId: id,
            label,
          })),
        },
      }
    }
    const actions: ServerActionPayload[] = []
    if (payload.add_to_inclusion_list) {
      actions.push(buildServerAction(ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP))
    }
    if (payload.add_to_exclusion_list) {
      actions.push(buildServerAction(ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP))
    }
    if (payload.remove_from_inclusion_list) {
      actions.push(
        buildServerAction(REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP),
      )
    }
    if (payload.remove_from_exclusion_list) {
      actions.push(
        buildServerAction(REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP),
      )
    }
    return actions
  },
}

export default config
