export const GET_POLICIES = "GET_POLICIES"
export const DELETE_POLICY = "DELETE_POLICY"
export const ADD_POLICY = "ADD_POLICY"
export const GET_POLICIES_ACTIONLOGS = "GET_POLICIES_ACTIONLOGS"
export const CLEAR_RDR_VALIDATIONS = "CLEAR_RDR_VALIDATIONS"
export const VERIFY_EXISTING_RULE_NAME = "VERIFY_EXISTING_RULE_NAME"
export const UPDATE_CREATED_POLICY = "UPDATE_CREATED_POLICY"
export const SET_LOADING = "SET_LOADING"
export const SET_IS_LOADING = "IS_LOADING"
export const SET_IS_RULE_MALFORMED = "SET_IS_RULE_MALFORMED"
