import fetcher from "@src/services/fetcher"
import { BASE_EP_API_URL } from "@src/constants"
import { getErrorStatus } from "@src/services/fetcher/utils"

const API_URL = BASE_EP_API_URL()

export async function getSlackChannels({
  search,
  limit = 25,
  offset = 0,
  app_channels = "",
}) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/intsync/slack/channels/?search=${search}&app_channels=${app_channels}&limit=${limit}&offset=${offset}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getSlackUsers({ search, limit = 25, offset = 0 }) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/intsync/slack/users/?search=${search}&limit=${limit}&offset=${offset}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
