export const SHOW_SIDE_DRAWER = "SHOW_SIDE_DRAWER"
export const CLOSE_SIDE_DRAWER = "CLOSE_SIDE_DRAWER"
export const RISK_TABLE_COUNT = "RISK_TABLE_COUNT"
/** action creators */
export const CLEAR = "CLEAR"
export const SET_RISKS = "SET_RISKS"
export const SET_RISKS_NAMES = "SET_RISKS_NAMES"
export const SET_BEHAVIOR_TYPES = "SET_BEHAVIOR_TYPES"
export const SET_RISKS_FILTER = "SET_RISKS_FILTER"
export const SET_FLAG_ACTIONS = "SET_FLAG_ACTIONS"
export const SET_ACTIONS = "SET_ACTIONS"
export const SET_DEPARTMENT = "SET_DEPARTMENT"
export const SET_WORK_TYPE = "SET_WORK_TYPE"
export const SET_LOCATION = "SET_LOCATION"
export const SET_ADVANCED_FILTERS = "ADVANCED_FILTERS"

/* HR Metadata Fields */
export const SET_JOB_TITLE = "SET_JOB_TITLE"
export const SET_BUSINESS_TITLE = "SET_BUSINESS_TITLE"
export const SET_BUSINESS_UNIT = "SET_BUSINESS_UNIT"
export const SET_ES_MANAGER_ID = "SET_ES_MANAGER_ID"
export const SET_IS_ONLEAVE = "SET_IS_ONLEAVE"
export const SET_JOB_FAMILY_FUNCTION_GROUP = "SET_JOB_FAMILY_FUNCTION_GROUP"
export const SET_JOB_FAMILY_FUNCTION_NAME = "SET_JOB_FAMILY_FUNCTION_NAME"
export const SET_MANAGER_NID = "SET_MANAGER_NID"
export const SET_PREFERRED_LANGUAGE = "SET_PREFERRED_LANGUAGE"
export const SET_PREFERRED_NAME = "SET_PREFERRED_NAME"
export const SET_PREFERRED_MIDDLE_NAME = "SET_PREFERRED_MIDDLE_NAME"
export const SET_PREFERRED_NAME_LOCAL_LANG = "SET_PREFERRED_NAME_LOCAL_LANG"
export const SET_TIME_TYPE = "SET_TIME_TYPE"
export const SET_WORK_LOCATION_COUNTRY = "SET_WORK_LOCATION_COUNTRY"
export const SET_WORK_LOCATION_GEO = "SET_WORK_LOCATION_GEO"
export const SET_WORK_LOCATION_TIMEZONE = "SET_WORK_LOCATION_TIMEZONE"
export const SET_WORKER_TYPE = "SET_WORKER_TYPE"
export const SET_WORK_LOCATION_BUILDING = "SET_WORK_LOCATION_BUILDING"
export const SET_WORK_LOCATION_CITY = "SET_WORK_LOCATION_CITY"
export const SET_WORK_LOCATION_NAME = "SET_WORK_LOCATION_NAME"
export const SET_WORK_SPACE = "SET_WORK_SPACE"
export const SET_LENGTH_OF_SERVICE = "SET_LENGTH_OF_SERVICE"
export const SET_HIRE_DATE = "SET_HIRE_DATE"
export const SET_IS_ACTIVE = "SET_IS_ACTIVE"
export const SET_LEAVE_DATE = "SET_LEAVE_DATE"
export const SET_ALTERNATIVE_USER_ID_1 = "SET_ALTERNATIVE_USER_ID_1"
export const SET_ALTERNATIVE_USER_ID_2 = "SET_ALTERNATIVE_USER_ID_2"

export const SET_AVAILABLE_HR_METADATA_KEYS = "SET_AVAILABLE_HR_METADATA_KEYS"
