import styled from "styled-components"
import { SpaceBetween } from "@src/components/SpaceBetween"

export const ComboBoxTitle = styled.div`
  flex-shrink: 0;
  font-weight: 700;
  width: 115px;
`

export const Wrapper = styled(SpaceBetween)`
  padding: 15px 0;
`
