import styled from "styled-components"
import { Link } from "react-router-dom"

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 0px;
  border-bottom: 1px solid #dde1e7;
`

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0px;
  button {
    width: 100%;
    border: 1px solid rgb(133, 166, 224);
  }
`

export const PolicyExecutionDate = styled("div")`
  color: #565d66;
  font-size: 14px;
`

export const TableWrapper = styled.div``

export const TableHeader = styled("div")`
  text-transform: uppercase;
  color: #7b828a;
  font-weight: 700;
`

export const PolicyExecutionRowTitle = styled("div")`
  font-size: 14px;
  color: #1d1e1f;
`

export const PolicyExecutionRowTitleLink = styled(Link)`
  font-size: 14px;
  color: #15785f;
`

export const PolicyActionLogWrapper = styled.div`
  width: 100%;
`

export const NoActionItemsWrapper = styled.div`
  background: #f5f6f8;
  border-radius: 5px;
  padding: 45px;
  text-align: center;
  height: 280px;
  margin-bottom: 32px;
`

export const NoActionItemImage = styled.img`
  width: 128px;
  height: 96px;
`

export const NoActionItemsMessageWrapper = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    margin-bottom: 0.3rem;
    color: #7b828a;
  }
`

export const NoDataTextHeader = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
`
