import React from "react"
import {
  StateWrapper,
  StatusCheckHeader,
  StatusCheckWrapper,
} from "@src/scenes/IndividualProfilePage/ProfileInfo/components/StatusCheck/styles"
import { useIndividualProfileState } from "@src/scenes/IndividualProfilePage/IndividualProfileContextProvider"
import { humanize } from "@src/utils/string"
import { Tooltip, Grid } from "@elevate_security/elevate-component-library"
import { timeWithTimeZone } from "@src/utils/dates"
import {
  MINUTES_IN_A_DAY,
  MINUTES_IN_AN_HOUR,
} from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/utils"
import FlagRibbon from "@src/components/FlagRibbon"

function StatusCheck() {
  const { individualData } = useIndividualProfileState()

  const stateData = {
    device_compliance_status: "Settings in compliance",
    device_encryption_status: "Device encrypted",
    device_OS_status: "OS up to date",
    password_manager_last_used: "Feb 1, 2022, 4:15 PM PST",
    password_strength: "Weak (not unique)",
    MFA_status: "MFA disabled",
    training_compliance: "Completed on time",
    "found_on_have_i_been_pwned?": "Not found on HIBP",
    clean_desk_compliance: "Compliant",
  }

  const getNameTitle = (name) => (name.endsWith("s") ? `${name}'` : `${name}'s`)

  const getRelativeTime = (relativeTime) => {
    if (relativeTime >= MINUTES_IN_A_DAY) {
      const days = Math.floor(relativeTime / MINUTES_IN_A_DAY)
      return (
        <span>
          {days} {days > 1 ? "days" : "day"} ago
        </span>
      )
    } else if (relativeTime >= MINUTES_IN_AN_HOUR) {
      const hours = Math.floor(relativeTime / MINUTES_IN_AN_HOUR)
      return (
        <span>
          {hours} {hours > 1 ? "hours" : "hour"} ago
        </span>
      )
    } else {
      return (
        <span>
          {relativeTime} {relativeTime > 1 ? "minutes" : "minute"} ago
        </span>
      )
    }
  }
  const ShowState = ({ state, value }) => {
    if (!value.length) return <StateWrapper />
    return (
      <StateWrapper>
        <span className="state">{humanize(state)}</span>
        <Tooltip
          body={
            <div
              style={{ fontSize: "13px", color: "#565D66", lineHeight: "16px" }}
            >
              Last checked <b>{getRelativeTime(60)}</b> on{" "}
              {timeWithTimeZone({ date: new Date() })}
            </div>
          }
          placement="bottom"
          readOnly
          size="xsm"
          theme="light-border"
        >
          <span className="value">{value}</span>
        </Tooltip>
      </StateWrapper>
    )
  }

  const componentList = Object.keys(stateData).map((state, i) => {
    return <ShowState key={i} state={state} value={stateData[state]} />
  })

  return (
    <StatusCheckWrapper>
      <FlagRibbon flagName="show-vc-demo-features">
        <StatusCheckHeader>
          {getNameTitle(individualData.first_name)} Status Check
        </StatusCheckHeader>
      </FlagRibbon>
      <Grid gridItemsList={componentList} showAllBorders={false} padding={0} />
    </StatusCheckWrapper>
  )
}

export default StatusCheck
