import {
  INDIVIDUALS_MAILS,
  RECEPTION_GROUPS_DATA,
  RULE_ACTIONS,
  SEND_VIA_SPLUNK,
  SEND_VIA_WEB_HOOK,
  SERVICE_WEB_HOOK,
  SERVICES_TOGGLES_DATA,
  SET_OUTBOUND_TEMPLATE_ATTRIBUTES,
  SET_SPLUNK_MATCHING_METHOD,
  SET_SERVICE_WEBHOOK_MATCHING_METHOD,
  SET_WEBHOOK_MATCHING_METHOD,
  SET_SHOW_NOTIFICATION_TEMPLATE,
} from "@src/services/redux/RiskDetectionRules/DefineOutcome/types"

const INITIAL_STATE = {
  individualsMails: [],
  sendViaSplunk: false,
  receptionGroupData: [],
  sendViaWebhook: false,
  serviceWebHook: false,
  ruleActions: new Set(),
  servicesToggle: [],
  togglesLoader: true,
  outboundTemplateAttributes: {},
  splunkMatchingMethod: "delta",
  webhookMatchingMethod: "delta",
  serviceWebhookMatchingMethod: "delta",
  showNotificationTemplate: false,
}

const defineOutcomeReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case INDIVIDUALS_MAILS:
      return {
        ...state,
        individualsMails: action.individualsMails,
      }
    case RECEPTION_GROUPS_DATA:
      return {
        ...state,
        receptionGroupData: action.receptionGroupData,
      }
    case SEND_VIA_SPLUNK:
      return {
        ...state,
        sendViaSplunk: action.sendViaSplunk,
      }
    case SEND_VIA_WEB_HOOK:
      return {
        ...state,
        sendViaWebhook: action.sendViaWebhook,
      }
    case SERVICE_WEB_HOOK:
      return {
        ...state,
        serviceWebHook: action.serviceWebHook,
      }
    case RULE_ACTIONS:
      return {
        ...state,
        ruleActions: action.ruleActions,
      }
    case SERVICES_TOGGLES_DATA:
      return {
        ...state,
        servicesToggle: action.servicesToggle,
        togglesLoader: false,
      }
    case SET_OUTBOUND_TEMPLATE_ATTRIBUTES:
      return {
        ...state,
        outboundTemplateAttributes: action.data,
      }
    case SET_SPLUNK_MATCHING_METHOD:
      return {
        ...state,
        splunkMatchingMethod: action.method,
      }
    case SET_WEBHOOK_MATCHING_METHOD:
      return {
        ...state,
        webhookMatchingMethod: action.method,
      }
    case SET_SERVICE_WEBHOOK_MATCHING_METHOD:
      return {
        ...state,
        serviceWebhookMatchingMethod: action.method,
      }
    case SET_SHOW_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        showNotificationTemplate: action.showNotificationTemplate,
      }
    default:
      return state
  }
}

export default defineOutcomeReducer
