import React from "react"
import { FlagRibbonWrapper, Wrapper } from "@src/components/FlagRibbon/styles"
import { darklyGetFlag } from "@src/utils/darkly"
import PropTypes from "prop-types"

function FlagRibbon({ children, flagName, alignItems = "baseline" }) {
  const showFlagRibbon = darklyGetFlag("show-flags-ribbon")

  if (!showFlagRibbon) return <div>{children}</div>

  return (
    <Wrapper alignItems={alignItems}>
      {children}
      <FlagRibbonWrapper>
        <b>{flagName}</b>
      </FlagRibbonWrapper>
    </Wrapper>
  )
}

export default FlagRibbon

FlagRibbon.propTypes = {
  flagName: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  alignItems: PropTypes.string,
}
