import React, { Suspense } from "react"

import Vision, { VisionProps } from "@src/scenes"
import store from "@src/services/redux/store"
import { Provider } from "react-redux"

import "./i18n"
import ActivityIndicator from "./components/ActivityIndicator"

type VisionRootProps = {
  logindata: VisionProps["logindata"]
  companyInfoOverride?: VisionProps["companyInfoOverride"]
  observeRouteChanges?: VisionProps["observeRouteChanges"]
  awsRum?: VisionProps["awsRum"]
}

export const VisionRoot = ({
  logindata,
  companyInfoOverride,
  observeRouteChanges = false,
  awsRum = null,
}: VisionRootProps) => {
  return (
    <Provider store={store}>
      <Suspense fallback={<ActivityIndicator active />}>
        <Vision
          logindata={logindata}
          companyInfoOverride={companyInfoOverride}
          observeRouteChanges={observeRouteChanges}
          awsRum={awsRum}
        />
      </Suspense>
    </Provider>
  )
}
