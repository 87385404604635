import styled from "styled-components"

export const Section = styled.div`
  border: 1px solid #c9cfd6;
  border-radius: 4px;
  padding: 23px 40px 30px 40px;
  margin-top: 24px;
  position: relative;
  .filters-row {
    position: absolute;
    right: 32px;
    top: 108px;
  }
`
