import React from "react"
import { Points } from "./styles"

export const getOverallDiffPoints = (behaviorScores) => {
  if (!behaviorScores || !Object.keys(behaviorScores).length) return 0
  const { overall } = behaviorScores
  const start = overall[0]
  const end = overall[overall.length - 1]
  return end.score - start.score
}

export const getDiffMessage = (behaviorScores) => {
  const point = getOverallDiffPoints(behaviorScores)
  if (point >= 0) {
    return (
      <Points>
        +{point} <span>points higher over the last year</span>
      </Points>
    )
  }
  return (
    <Points>
      -{point * -1} <span>points lower over the last year</span>
    </Points>
  )
}
