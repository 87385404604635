import React from "react"
import styled from "styled-components"
import { humanize } from "@src/utils/string"
import { Badge } from "@elevate_security/elevate-component-library"

const statusToTheme = {
  success: "rgb(22, 101, 192)",
  failed: "rgb(198, 40, 39)",
  suppressed: "rgb(229, 104, 0)",
} as const

const BadgeOverride = styled.span<{ override?: string }>`
  ${(props) =>
    props.override
      ? `[class^="Badgestyles"] {
          border-color: ${props.override};
          color: ${props.override};
        }`
      : ""}
`

export const StatusBadge = ({
  status,
  displayText,
}: {
  status: keyof typeof statusToTheme
  displayText?: string
}) => {
  return (
    <BadgeOverride override={statusToTheme[status]}>
      <Badge text={displayText || humanize(status)} shape="smallboldround" />
    </BadgeOverride>
  )
}
