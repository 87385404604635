import React from "react"
import { DayPickerWrapper, DayPickerItem } from "../styles"
import { isEmpty } from "@src/utils/string"

const weekDays = [
  {
    label: "M",
    value: "Monday",
  },
  {
    label: "T",
    value: "Tuesday",
  },
  {
    label: "W",
    value: "Wednesday",
  },
  {
    label: "T",
    value: "Thursday",
  },
  {
    label: "F",
    value: "Friday",
  },
  {
    label: "S",
    value: "Saturday",
  },
  {
    label: "S",
    value: "Sunday",
  },
]

const map = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
}

export const DayPicker = (props) => {
  const { value, onSelect } = props

  const onChange = (selectedDay) => {
    let values = isEmpty(value) ? [] : value?.split(",")
    if (values?.includes(selectedDay)) {
      // un-select that day
      values = values?.filter((item) => item !== selectedDay)
    } else {
      // select that day
      values?.push(selectedDay)
    }
    values?.sort((a, b) => {
      return map[a] - map[b]
    })
    onSelect(values?.join(","))
  }

  return (
    <DayPickerWrapper data-test-id="day-picker-wrapper">
      {weekDays?.map((day) => {
        const isDayActive = value?.includes(day.value)
        return (
          <DayPickerItem
            key={day.value}
            onClick={() => onChange(day.value)}
            active={isDayActive}
            data-is-active-item={isDayActive}
            data-test-id={`day-picker-item-${day.value}`}
          >
            {day.label}
          </DayPickerItem>
        )
      })}
    </DayPickerWrapper>
  )
}
