import styled from "styled-components"

export const CreateForm = styled.form`
  position: relative;
  padding-bottom: 12px;

  & > div {
    border-bottom: 0;
    padding: 0;
  }
`

export const CreateModalContainer = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    padding: 40px;
    & [class^="Modalstyles__ModalContent"] {
      overflow: initial !important;
      overflow-y: initial !important;
    }
  }
`

export const FieldContainer = styled.div`
  padding: 14px 0;
  width: 100%;
`

export const InputContainer = styled.div`
  flex: 0;
  & > * {
    width: 100;
    input {
      width: 100;
    }
  }
`
