import React from "react"
import PropTypes from "prop-types"

const MitreIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_57_1883)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 29.0683L5.2177 0H10.6835L17.516 19.6275L24.3479 0H29.8137L35.0314 29.0683H29.8137L26.3354 9.68944L19.6275 29.0683H15.4039L8.69597 9.68944L5.2177 29.0683H0ZM41.2426 29.0683V0H35.9008V29.0683H41.2426ZM42.8574 0V5.09309H49.5653V29.0683H54.907V5.09309H61.6149V0H42.8574ZM84.5961 0V29.0683H100V23.9752H89.9379V16.5214H100V11.6769H89.9379V5.09309H100V0H84.5961Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.8452 0.0403983C79.4541 0.0403983 83.1901 3.77643 83.1901 8.3853C83.1901 12.807 79.7506 15.8539 75.4011 16.0929L84.5961 28.5722V0H100V5.09309H89.9379V11.6769H100V16.5214H89.9379V23.9752H100V29.0683H78.385L69.9376 17.2673V11.723H74.8452C75.723 11.7116 76.5609 11.3548 77.1776 10.7301C77.7942 10.1053 78.14 9.26284 78.14 8.38502C78.14 7.5072 77.7942 6.66469 77.1776 6.03994C76.5609 5.41519 75.723 5.05846 74.8452 5.047H68.4474V29.0683H63.1051V0L74.8452 0.0403983Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_57_1883">
          <rect width="100" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MitreIcon

MitreIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

MitreIcon.defaultProps = {
  fill: "none",
  width: "58",
  height: "15",
}
