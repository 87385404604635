import React, { useState, useCallback } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useToggle } from "ahooks"
import PropTypes from "prop-types"

import Modal from "@elevate_security/elevate-component-library/dist/Modal"
import Button from "@elevate_security/elevate-component-library/dist/Button"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import IndividualsTable from "../components/IndividualsTable"
import CircularScoreBar from "../components/CircularScoreBar"
import {
  ModalWrapper,
  Title,
  SubTitle,
  OptionText,
  SlackMessage,
  ThankYouText,
  FlexRow,
  FlexCol,
  Summary,
} from "../styles"

import {
  getLowPerformresByBehavior,
  sendEnrollEmailToSecurityTeam,
} from "@src/scenes/ManagerDashboard/services/redux/actions"
import { genericCopies, concatBehaviorNames } from "./utils"

function ImproveBehavior({
  behaviors,
  showModal,
  toggleShowModal,
  modalTitle,
  showScoreBar,
}) {
  const [totalCount, setTotalCount] = useState({})
  const [showRequestSubmitted, { toggle: toggleRequestSubmitted }] =
    useToggle(false)
  const dispatch = useDispatch()
  const { person_nid } = useParams()

  const concatTotalCount = Object.values(totalCount).reduce(
    (accum, count) => accum + count,
    0,
  )

  const handleToggle = () => {
    setTotalCount({})
    toggleShowModal()
  }
  const handleRequestSubmittedToggle = () => toggleRequestSubmitted()

  const onSendEmail = () => {
    const behaviorNames = behaviors.length
      ? behaviors.map(({ name }) => name).join(",")
      : ""
    dispatch(sendEnrollEmailToSecurityTeam(behaviorNames, person_nid))
    handleToggle()
    handleRequestSubmittedToggle()
  }

  const renderCircularScoreBar = () => {
    if (!showScoreBar || !behaviors || !behaviors.length) return null
    const { teamScore, teamScoreTrend } = behaviors[0] || {}
    return (
      <CircularScoreBar teamScore={teamScore} teamScoreTrend={teamScoreTrend} />
    )
  }

  const SecurityScoreSummary = useCallback(
    () => (
      <Summary>
        <FlexRow>
          {renderCircularScoreBar()}
          <FlexCol style={{ marginLeft: showScoreBar ? 32 : 0 }}>
            <SubTitle>Security Score Summary</SubTitle>
            {behaviors.map(({ key, name }) => {
              return (
                <div key={key}>
                  <IndividualsTable
                    behaviorName={name}
                    threshold="< 40"
                    tableWidth={showScoreBar ? "350px" : "100%"}
                    iconToRender={
                      <Icon name="CircleExclamation" fill="#C73434" />
                    }
                    setTotalCount={(count) =>
                      setTotalCount((prevState) => ({
                        ...prevState,
                        [key]: count,
                      }))
                    }
                    actionCall={(params) =>
                      getLowPerformresByBehavior({
                        person_nid,
                        key,
                        ...params,
                      })
                    }
                  />
                </div>
              )
            })}
          </FlexCol>
        </FlexRow>
      </Summary>
    ),
    [behaviors],
  )

  const HowYourTeamCanImprove = () => (
    <>
      <SubTitle>How your team can improve:</SubTitle>
      <OptionText>
        <b>Option 1:</b> Send a message with the suggested copy to your team:
      </OptionText>
      <SlackMessage>
        Hey team, just a quick reminder for those who have not done so already,
        please
        {behaviors.length === 1
          ? ` ${genericCopies[behaviors[0].key]}. `
          : behaviors.map(({ key }, index) => (
              <div key={key}>
                {index + 1}. {genericCopies[key] || ""}
              </div>
            ))}
        This is important as it will help increase our team’s security score and
        lower security risk in the company, thank you.
      </SlackMessage>
      <OptionText style={{ marginBottom: 16 }}>
        <b>Option 2:</b> Enroll {concatTotalCount} individuals with a{" "}
        {concatBehaviorNames(behaviors)} score
        {" < "}
        40 in recommended campaigns to improve these behaviors.
      </OptionText>
      <Button width="full" onClick={onSendEmail} disabled={!concatTotalCount}>
        Enroll in a Recommended Campaign
      </Button>
    </>
  )

  const renderModal = () => (
    <ModalWrapper>
      <Modal
        onClose={handleToggle}
        header={<Title>{modalTitle}</Title>}
        isOpen={showModal}
      >
        <SecurityScoreSummary />
        <HowYourTeamCanImprove />
      </Modal>
    </ModalWrapper>
  )

  const renderRequestSubmittedModal = () => (
    <ModalWrapper>
      <Modal
        onClose={handleRequestSubmittedToggle}
        header={
          <Title style={{ marginLeft: "auto" }}>Request Submitted!</Title>
        }
        isOpen={showRequestSubmitted}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src="https://hm-assets.elevatesecurity.com/master/manager-dashboard/letter-celebrate.png"
            alt=""
            width="180"
            height="180"
          />
          <ThankYouText>
            Someone from your Security Team will reach out soon. Please reach
            out to your Security Team with any questions in the meantime.
          </ThankYouText>
        </div>
      </Modal>
    </ModalWrapper>
  )

  return (
    <>
      {renderModal()}
      {renderRequestSubmittedModal()}
    </>
  )
}

ImproveBehavior.propTypes = {
  showModal: PropTypes.bool,
  toggleShowModal: PropTypes.func,
  behaviors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      teamScore: PropTypes.number,
      teamScoreTrend: PropTypes.number,
    }),
  ),
  modalTitle: PropTypes.string,
  showScoreBar: PropTypes.bool,
}

ImproveBehavior.defaultProps = {
  modalTitle: "Improve Weakest Behaviors",
  showScoreBar: false,
}

export default ImproveBehavior
