import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Header,
  BreakDownIndividual,
  ScoreBreak,
  Wrapper,
  IndividualsRibbon,
  ClearFilters,
} from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import { useRequest } from "ahooks"
import MoreFilter from "@src/scenes/IndividualProfilePage/components/MoreFilter"
import { useDispatch, useSelector } from "react-redux"
import { getRisksKeys } from "@src/scenes/SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import ScoreBreakTable from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/ScoreBreakTable"
import {
  CHIP_LABEL,
  EXPORT_PARAMS,
  HUMAN_RISK_DESC,
  OVERALL,
} from "@src/scenes/HumanRiskDashboard/constants"
import {
  getOptionParams,
  managerFilterList,
} from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/getScoreBreakColumns"
import { humanize } from "@src/utils/string"
import { AppliedFilters } from "@elevate_security/elevate-component-library"
import ExportData from "@src/components/ExportData"
import { getManagerOverview } from "@src/scenes/ManagerDashboard/services/redux/selectors"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { useParams } from "react-router-dom"

function ScoreBreakDownIndividuals() {
  const { totalTeamMembers, managerTeamLoader, managerInfo } =
    useSelector(getManagerOverview)
  const [exportParams, setExportParams] = useState(EXPORT_PARAMS)
  const [appliedFilters, setAppliedFilters] = useState([])
  const [filters, setFilters] = useState(null)

  const dispatch = useDispatch()

  const individualAnalysis = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const { person_nid } = useParams()

  const { loading, run: fetchRisksKeys } = useRequest(getRisksKeys(dispatch), {
    manual: true,
  })

  useEffect(() => {
    // To fetch departments
    if (!individualAnalysis.departments.length) {
      fetchRisksKeys()
    }
  }, [])

  useEffect(() => {
    const rangeValue = [OVERALL]
    const sendFilters = {
      department: [],
      [OVERALL]: [],
    }
    appliedFilters.forEach((filter) => {
      if (rangeValue.includes(filter.key)) {
        sendFilters[filter.key] = filter.original.split("-")
      } else {
        sendFilters[filter.key]?.push(filter.original)
      }
    })
    setFilters(sendFilters)
  }, [appliedFilters])

  const managerFiltersOptions = useMemo(
    () => getOptionParams(loading, individualAnalysis.departments),
    [individualAnalysis.departments],
  )

  const setSliderFilter = (selection) => {
    if (!selection.remove) {
      setAppliedFilters((oldFilter) => [
        ...oldFilter.filter((fl) => fl.key !== selection.key),
        {
          key: selection.key,
          value: selection.label,
          original: selection.label,
          name: CHIP_LABEL[selection?.key] || selection.key,
          humanizedName: humanize(CHIP_LABEL[selection?.key] || selection.key),
        },
      ])
    } else {
      setAppliedFilters((oldFilter) =>
        oldFilter.filter((filter) => filter.key !== OVERALL),
      )
    }
  }
  const setManagerFilter = (selection, remove = true) => {
    const newValue = selection.value.toLowerCase().replaceAll(" ", "-")
    const existingIndex = appliedFilters.findIndex(
      (filter) => filter.value === newValue && filter.key === selection.key,
    )
    const existingSingleValueFilter = appliedFilters.findIndex(
      (filter) => filter.key === selection.key,
    )

    if (selection.key === OVERALL) {
      setSliderFilter(selection)
    } else if (existingSingleValueFilter !== -1 && existingIndex === -1) {
      setAppliedFilters((oldFilter) => {
        const filter = [...oldFilter]
        filter.splice(existingSingleValueFilter, 0, {
          key: selection.key,
          value: newValue,
          original: selection.value,
          name: CHIP_LABEL[selection?.key] || selection.key,
          humanizedName: humanize(CHIP_LABEL[selection?.key] || selection.key),
        })
        return filter
      })
    } else if (existingIndex === -1) {
      setAppliedFilters((oldFilter) => [
        ...oldFilter,
        {
          key: selection.key,
          value: newValue,
          original: selection.value,
          name: CHIP_LABEL[selection?.key] || selection.key,
          humanizedName: humanize(CHIP_LABEL[selection?.key] || selection.key),
        },
      ])
    } else {
      if (!remove) return
      setAppliedFilters((oldFilter) => {
        const filter = [...oldFilter]
        filter.splice(existingIndex, 1)
        return filter
      })
    }
  }
  const onFilterRemoval = (selection) => {
    selection.key === "overall"
      ? setManagerFilter({ ...selection, remove: true })
      : setManagerFilter(selection)
  }

  const clearFilter = () => {
    setAppliedFilters([])
    setExportParams({ ...exportParams, order_by: HUMAN_RISK_DESC })
  }

  const ExportDataButton = useCallback(() => {
    return (
      <ExportData
        showButton
        type="managerTeamData"
        order_by={exportParams.order_by}
        es_person_id={person_nid || managerInfo.id}
        filters={`hr_metadata.es_manager_id EQ ${person_nid || managerInfo.id}`}
        dataLength={totalTeamMembers}
        total={totalTeamMembers}
        showLimitationModal
      />
    )
  }, [managerTeamLoader])

  return (
    <Wrapper padding="24px 24px">
      <div className="d-flex justify-content-between">
        <Header>How is my team doing?</Header>
      </div>
      <BreakDownIndividual>
        <ScoreBreak>
          {managerTeamLoader ? (
            <IndividualsRibbon color="black" background="#f3f4f6">
              <ActivityIndicator active={true} size={20} border={2} />
            </IndividualsRibbon>
          ) : (
            <IndividualsRibbon color="black" background="#f3f4f6">
              {totalTeamMembers} Individuals
            </IndividualsRibbon>
          )}
          <div
            style={{ display: "none" }} // hiding filter using css
          >
            <MoreFilter
              filters={appliedFilters}
              _options={managerFilterList}
              optionParams={managerFiltersOptions}
              onSelection={(selectedFilter) => setManagerFilter(selectedFilter)}
              width="377px"
            />
            {!!appliedFilters?.length && (
              <ClearFilters
                onClick={() => {
                  clearFilter()
                }}
              >
                Clear filters
              </ClearFilters>
            )}
          </div>
          <ExportDataButton />
        </ScoreBreak>
      </BreakDownIndividual>
      <AppliedFilters
        appliedFilters={appliedFilters}
        handleRemoveFilter={onFilterRemoval}
      />
      {!!appliedFilters?.length && <hr />}
      <ScoreBreakTable setExportParams={setExportParams} filters={filters} />
    </Wrapper>
  )
}

export default React.memo(ScoreBreakDownIndividuals)
