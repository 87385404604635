import { getPolicies, getPoliciesActionLogs } from "@src/services/apis/policies"
import {
  GET_POLICIES,
  DELETE_POLICY,
  ADD_POLICY,
  GET_POLICIES_ACTIONLOGS,
  CLEAR_RDR_VALIDATIONS,
  VERIFY_EXISTING_RULE_NAME,
  UPDATE_CREATED_POLICY,
  SET_LOADING,
  SET_IS_LOADING,
  SET_IS_RULE_MALFORMED,
} from "./types"

export const setLoading = (policyId) => ({
  type: SET_LOADING,
  policyId,
})

export function createPolicyRedux(policy) {
  return {
    type: ADD_POLICY,
    policy,
  }
}

export function updateCreatedPolicyRedux(id, policy) {
  return {
    type: UPDATE_CREATED_POLICY,
    id: id,
    policy: policy,
  }
}

export function veirfyExistingRuleName() {
  return async (dispatch) => {
    dispatch({
      type: VERIFY_EXISTING_RULE_NAME,
    })
  }
}

export function clearErrorValidationsInfo() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_RDR_VALIDATIONS,
    })
  }
}

export function getPoliciesRedux(backendParams, shouldIgnore = () => false) {
  return async (dispatch) => {
    setIsLoading(dispatch, false)
    const { error, data } = await getPolicies(backendParams)
    if (shouldIgnore()) {
      // Indicates the request is now stale, so we can ignore the response.
      return
    }
    dispatch({
      type: GET_POLICIES,
      data: {
        data: !error ? data.results : null,
        totalPages: !error ? data.pages : null,
        pNumber: !error ? data.page_number : null,
        pSize: !error ? data.page_size : null,
        isLoaded: true,
        pTotalElements: !error ? data.total_elements : null,
      },
    })
  }
}

export function deletePolicyRedux(item) {
  return (dispatch) => {
    dispatch({
      type: DELETE_POLICY,
      data: {
        ...item,
        isLoaded: true,
      },
    })
  }
}

export function getPoliciesActionLogsRedux(
  id,
  pages,
  property,
  direction,
  currentPage,
) {
  return async (dispatch) => {
    dispatch(setLoading(id))
    const { error, data } = await getPoliciesActionLogs(
      id,
      pages,
      property,
      direction,
      currentPage,
    )
    dispatch({
      type: GET_POLICIES_ACTIONLOGS,
      data: {
        id: id,
        data: !error ? data.results : null,
        pagination: !error ? data : null,
      },
    })
  }
}

function setIsLoading(dispatch, isLoaded) {
  dispatch({
    type: SET_IS_LOADING,
    isLoaded,
  })
}
export const setIsRuleMalformed = (isRuleMalformed) => {
  return {
    type: SET_IS_RULE_MALFORMED,
    isRuleMalformed,
  }
}
