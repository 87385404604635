import React, { useEffect, useState, useMemo } from "react"

import { getOutboundTemplateAttributes as _getOutboundTemplateAttributes } from "@src/services/apis/reputation"
import { getOutboundServices as _getOutboundServices } from "@src/services/apis/integrations"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { AddActionMenu } from "./AddActionMenu"
import { Action } from "./Action"
import { isIntegrated } from "../RiskRuleOutcome/utils"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { uniq } from "@src/utils/uniq"

export function EditR3Actions({
  actions,
  criteria,
  actionOperations,
  isAddEnabled,
  showErrorMessage,
  onEditEnd,
  getOutboundTemplateAttributes = _getOutboundTemplateAttributes,
  getOutboundServices = _getOutboundServices,
  render = ({ addActionMenu, body }) => (
    <div>
      {addActionMenu}
      {body}
    </div>
  ),
}) {
  const [templateAttrs, setTemplateAttrs] = useState(null)
  const [outboundServices, setOutboundServices] = useState(null)

  // For now, only allow editing one action at a time
  const [editIndex, _setEditIndex] = useState(null)

  const setEditIndex = (index) => {
    _setEditIndex(index)
    actionOperations.setActionEditState(index !== null)
  }

  const categoriesString = useMemo(
    () =>
      collectCategories(criteria)
        .filter((category) => !["people", "scores"].includes(category))
        .sort()
        .join(","),
    [criteria],
  )

  useEffect(() => {
    setTemplateAttrs(null)
    getOutboundTemplateAttributes(categoriesString).then((response) => {
      setTemplateAttrs(response.data)
    })
  }, [categoriesString, getOutboundTemplateAttributes])

  useEffect(() => {
    setOutboundServices(null)
    getOutboundServices().then((response) => {
      setOutboundServices(response.data.results)
    })
  }, [getOutboundServices])

  const isServiceIntegrated = (target, targetType) => {
    if (!outboundServices) {
      return false
    }
    return isIntegrated(outboundServices, target, targetType)
  }

  const isLoading = !templateAttrs || !outboundServices

  return render({
    addActionMenu: isAddEnabled && (
      <AddActionMenu
        enabled={editIndex === null}
        addAction={(payload) => {
          actionOperations.addAction(payload, (addedIndex) => {
            setEditIndex(addedIndex)
          })
        }}
        isServiceIntegrated={isServiceIntegrated}
        addedActionTypes={actions.map((action) => action.action_type)}
      />
    ),
    body: (
      <SpaceBetween>
        <Loading active={isLoading}>
          {actions.map((action, i) => {
            return (
              <Action
                details={action}
                key={action.id || i}
                templateAttributes={templateAttrs}
                outboundServices={outboundServices}
                onEditStart={() => {
                  setEditIndex(i)
                }}
                onEditEnd={() => {
                  setEditIndex(null)
                  onEditEnd()
                }}
                isEditingEnabled={editIndex === null}
                initialEditState={editIndex === i}
                updateAction={(newPayload) => {
                  actionOperations.updateAction(i, newPayload)
                }}
                removeAction={() => {
                  actionOperations.removeAction(i)
                }}
                showErrorMessage={showErrorMessage}
              />
            )
          })}
        </Loading>
      </SpaceBetween>
    ),
  })
}

function collectCategories(criteria) {
  if (!criteria) {
    return []
  }

  if (!criteria.rules?.length) {
    return [criteria.category]
  }

  return uniq(criteria.rules.flatMap((rule) => collectCategories(rule)))
}

function Loading({ active, children }) {
  if (active) {
    return <ActivityIndicator active={true} />
  }
  return children
}
