import styled from "styled-components"
import colors from "@src/utils/colors"
import { TableCard } from "@src/components/TableCard"

export const LinkIcon = styled.div`
  margin-right: 4px;
  svg {
    width: 14px;
    height: 14px;
    vertical-align: baseline;
    margin-top: 5px;
    margin-right: 15px;
  }
`

export const TableWrapper = styled(TableCard)`
  // Must be relative because some elements inside the table are positioned
  // absolutely, according to this wrapper :( Needs a refactor
  position: relative;

  flex-grow: 1;
  overflow: hidden;

  [class^="Tablestyles__TableContainer"] {
    display: flex;
    flex-direction: column;
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 990;
      overflow: visible;
      padding-right: 0;
      [class^="Tablestyles__TableRow"] {
        padding-bottom: 5px;
        [class^="Tablestyles__TableData"] {
          background: white;
          color: ${colors.error};
          [class^="Tablestyles__TableHeaderColumnSortIconContent"] {
            min-width: 24px;
          }
          &:first-child {
            .span-head {
              div {
                margin-left: 0 !important;
                padding-top: 4px !important;
              }
            }
          }
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      flex-grow: 1;
      [class^="Tablestyles__TableRow"] {
        overflow: visible;
        width: auto !important;
        [class^="Tablestyles__TableData"] input[type="checkbox"] {
          vertical-align: middle;
        }
      }
    }
  }
  [class^="Tablestyles__TableRow"] {
    padding: 7px 0px;
    margin-top: 0;
    width: auto;
  }
  [class^="Tablestyles__TableData"] {
    min-width: ${({ numberOfColumns }) =>
      `calc((100% - 210px)/ ${Math.min(9, numberOfColumns - 2)}) !important`};
    max-width: ${({ numberOfColumns }) =>
      `calc((100% - 210px) / ${Math.min(9, numberOfColumns - 2)}) !important`};
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      color: #7b828a;
    }

    &:first-child {
      max-width: ${(props) =>
        props.hideFirstColumn ? "0px" : "35px"} !important;
      min-width: ${(props) =>
        props.hideFirstColumn ? "0px" : "35px"} !important;
    }
    &:nth-child(2) {
      min-width: 150px !important;
      max-width: 150px !important;
      .span-head {
        font-weight: bold !important;
      }
      strong {
        font-weight: normal;
      }
    }
    &:nth-child(n + 3) {
      max-width: ${(hasEmail) => (hasEmail ? "240px" : "160px")} !important;
      min-width: ${(hasEmail) => (hasEmail ? "240px" : "160px")} !important;
    }
    ${({ haveExtraColumns }) =>
      haveExtraColumns &&
      `
    &:nth-child(3) {
      min-width: 160px !important;
      max-width: 160px !important;
    }
    &:nth-child(4) {
      min-width: 180px !important;
      max-width: 180px !important;
    }
    &:nth-child(n + 6) {
      strong {
        font-weight: normal;
      }
    }`}
  }

  [class^="Tablestyles__TablePagination"] {
    input {
      cursor: pointer;
    }
  }
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0px 0px 0;
  width: ${(props) => props.width};
  align-items: center;
  margin-left: ${(props) => props.marginLeft};
`

export const ProgressBar = styled.div`
  display: block;
  width: 100%;
  min-width: 45px;
  height: 20px;
  background-color: #dde1e7;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ value }) => `${value}`}%;
    height: 100%;
    background-color: #0c7d84;
  }
`
export const ColumnValue = styled.strong`
  display: block;
  min-width: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: 100%;
`

export const ScoreDiv = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  font-weight: 700;
  margin-right: 13px;
  width: 20px;
  text-align: center;
`

export const BarColorsWrapper = styled.div`
  width: 20%;
`
export const NumberOfIndividuals = styled.div`
  color: #565d66;
  font-size: 14px;
`
