import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Icon } from "@elevate_security/elevate-component-library"
import { Input, Toggle } from "@src/components/MimecastReskin"

import {
  GroupedComboboxWrapper,
  InputBox,
  Menu,
  MenuHeader,
  MenuList,
  MenuListItem,
  TogglesGroup,
  ToggleWrapper,
  ToggleWrapperText,
} from "./GroupedCombobox.styles"

function GroupedCombobox(props) {
  const {
    isSearchable = false,
    isFilterable = false,
    hasNesting = false,
    options = [],
    filterOptions = [],
    onFilterChange = () => {},
    onSearch = () => {},
    placeholder = "Select field",
    value = { label: "", value: "" },
    onChange,
  } = props
  const ref = useRef()
  const menuRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [search, setSearch] = useState("")

  const toggleMenu = () => setShowMenu(!showMenu)

  const onSelectField = (selected) => {
    onChange(selected)
    // close menu on selecting item
    setShowMenu(false)
    setSearch("")
  }

  const handleSearch = (val) => {
    setSearch(val)
    onSearch(val)
  }

  // Clicking outside should close menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowMenu(false)
        setSearch("")
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
  }, [ref])

  const renderDataOptions = (options, level) => {
    return options.map((option, index) => {
      if (!option.options) {
        return (
          <MenuListItem
            onClick={() => onSelectField(option)}
            nestedLevel={hasNesting ? level + 1 : 1}
            key={`data-item-${index}-${hasNesting ? level + 1 : 1}`}
            className={option.value === value.value && "selected"}
          >
            {option.label}
          </MenuListItem>
        )
      } else {
        return (
          <React.Fragment
            key={`data-group-label-${index}-${hasNesting ? level + 1 : 1}`}
          >
            <MenuListItem
              className="header group-label"
              nestedLevel={hasNesting ? level + 1 : 1}
            >
              {option.label}
            </MenuListItem>
            {renderDataOptions(option.options, hasNesting ? level + 1 : 1)}
          </React.Fragment>
        )
      }
    })
  }

  const showFilters = isFilterable && filterOptions?.length > 0
  const showMenuHeader = isSearchable || showFilters

  return (
    <GroupedComboboxWrapper ref={ref}>
      <InputBox className={showMenu && "focus"} onClick={toggleMenu}>
        <Input placeholder={placeholder} value={value.label} />
        <Icon name="CaretDown" size="xxsm" fill="#ccc" />
      </InputBox>
      <Menu ref={menuRef} className={showMenu ? "show" : "hide"}>
        {showMenuHeader && (
          <MenuHeader>
            {isSearchable && (
              <Input
                name="field"
                placeholder="Search field..."
                style={{ width: "250px" }}
                iconLeft={<Icon name="Search" size="xsm" fill="#959DA8" />}
                value={search}
                onChange={handleSearch}
              />
            )}
            {showFilters && (
              <TogglesGroup>
                {filterOptions?.map((option) => (
                  <ToggleWrapper key={option.value}>
                    <Toggle
                      name={option.value}
                      checked={option.checked}
                      onChange={(e) => {
                        onFilterChange(e.checked, option.value)
                      }}
                    />
                    <ToggleWrapperText>{option.label}</ToggleWrapperText>
                  </ToggleWrapper>
                ))}
              </TogglesGroup>
            )}
          </MenuHeader>
        )}
        <MenuList>{renderDataOptions(options, 1)}</MenuList>
      </Menu>
    </GroupedComboboxWrapper>
  )
}

GroupedCombobox.propTypes = {
  isSearchable: PropTypes.bool,
  isFilterable: PropTypes.bool,
  hasNesting: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  filterOptions: PropTypes.array,
  onFilterChange: PropTypes.func,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
}

export default React.memo(GroupedCombobox)
