import { validateObject } from "@src/utils/validateObject"

type EmailFields = {
  subject: string
  message: string
}

export function validateEmailActionPayload<T extends EmailFields>(payload: T) {
  return validateObject(payload, [
    ["subject", ({ subject }) => Boolean(subject), "Subject is required"],
    ["message", ({ message }) => Boolean(message), "Email body is required"],
  ])
}
