import {
  COMBOBOX_GROUPS,
  COMBOBOX_GROUPS_COUNTS,
  COMBOBOX_GROUPS_DATA_PAGE_NUMB,
  COMBOBOX_GROUPS_LOADER,
  EXCLUDING_INDIVIDUALS_RULE_INFO,
  RULE_DESCRIPTION_RULE_INFO,
  RULE_LOADING_COUNT_RULE_INFO,
  RULE_NAME_RULE_INFO,
  RULE_SPECIFY_GROUP_RULE_INFO,
  SET_QUERY_RULE,
  RESET_QUERY_RULE,
  SET_QUERY_BUILDER_SCHEMA,
  SET_USE_MAILTRAP,
  SET_IS_RULE_NAME_UPDATED,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/types"

export function setComboboxGroupsData(comboboxGroupsData) {
  return {
    type: COMBOBOX_GROUPS,
    comboboxGroupsData,
  }
}

export function setComboboxGroupsPageNumb(comboboxGroupsPageNum) {
  return {
    type: COMBOBOX_GROUPS_DATA_PAGE_NUMB,
    pageNumber: comboboxGroupsPageNum,
  }
}

export function setComboboxGroupsLoader(loader) {
  return {
    type: COMBOBOX_GROUPS_LOADER,
    loader,
  }
}

export function setRuleNameRuleInfo(ruleName) {
  return {
    type: RULE_NAME_RULE_INFO,
    ruleName,
  }
}

export function setDescriptionRuleInfo(ruleDescription) {
  return {
    type: RULE_DESCRIPTION_RULE_INFO,
    ruleDescription,
  }
}

export function setSpecifyGroupRuleInfo(specifyGroup) {
  return {
    type: RULE_SPECIFY_GROUP_RULE_INFO,
    specifyGroup,
  }
}

export function setExcludingIndividualsRuleInfo(excludingIndividuals) {
  return {
    type: EXCLUDING_INDIVIDUALS_RULE_INFO,
    excludingIndividuals,
  }
}

export function setLoadingCountRuleInfo(loadingCount) {
  return {
    type: RULE_LOADING_COUNT_RULE_INFO,
    loadingCount,
  }
}
export function setComboboxCountsRuleInfo(count) {
  return {
    type: COMBOBOX_GROUPS_COUNTS,
    count,
  }
}

export function setQueryRule(queryRule) {
  return {
    type: SET_QUERY_RULE,
    queryRule,
  }
}

export function resetQueryRule() {
  return {
    type: RESET_QUERY_RULE,
  }
}

export function setQueryBuilderSchema(queryBuilderSchema) {
  return {
    type: SET_QUERY_BUILDER_SCHEMA,
    queryBuilderSchema,
  }
}

export function setUseMailtrap(flag) {
  return {
    type: SET_USE_MAILTRAP,
    flag,
  }
}

export function setIsRuleNameUpdated(flag) {
  return {
    type: SET_IS_RULE_NAME_UPDATED,
    isRuleNameUpdated: flag,
  }
}
