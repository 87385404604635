import React from "react"
import { SkeletonLoader } from "@src/utils/skeleton"

function HistoricalTrendGraphSkeleton() {
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "10px",
        border: "1px solid #c9cfd6",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <SkeletonLoader height={20} />
      </div>
      <div>
        <hr />
        <SkeletonLoader height={15} />
        <hr />
        <SkeletonLoader height={15} />
        <hr />
        <SkeletonLoader height={15} />
        <hr />
        <SkeletonLoader height={15} />
        <hr />
        <SkeletonLoader height={15} />
      </div>
    </div>
  )
}

export default HistoricalTrendGraphSkeleton
