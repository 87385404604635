import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContent"] {
    overflow: visible;
  }
  z-index: 10000;
  position: relative;
`

export const ComboBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
  > * {
    flex: 1;
  }
`

export const TemplatePreview = styled.pre`
  min-height: 100px;
  background-color: ${theme.colors.gray.opaque[100]};
  padding: 10px;
`
