export const BREADCRUMB_LINKS = [
  {
    label: "Vision",
  },
  {
    label: "Manager Dashboard",
  },
]
export const BREADCRUMB_LINKS_MANAGER = (manager_name) => {
  return [
    {
      label: "Vision",
      link: "/engagement/vision2/dashboard",
    },
    {
      label: `Manager Dashboard (${manager_name || "you"})`,
    },
  ]
}
