import React, { useMemo, useState } from "react"

import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { useDebounceFn } from "ahooks"
import { Link } from "react-router-dom"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { useWatchlists as _useWatchlists } from "./useWatchlists"
import { TableCellSpan } from "@src/components/TableCellSpan"
import { useTranslation } from "react-i18next"
import type { PredefinedWatchlistId } from "./useWatchlist"

export function WatchlistsScreen({ useWatchlists = _useWatchlists }) {
  const [search, setSearch] = useState("")
  const [searchText, setSearchText] = useState("")
  const { run: setSearchDebounced } = useDebounceFn(setSearch, { wait: 250 })
  const { t } = useTranslation("watchlists")

  const { data, loading, error } = useWatchlists()

  const columns = [
    {
      key: "name",
      header: t("tableHeaders.name"),
      render: (
        name: string,
        { watchlist_nid }: { watchlist_nid: PredefinedWatchlistId },
      ) => {
        return (
          <TableCellSpan allowCellWrap={true}>
            <Link
              to={`/engagement/vision2/risk-response-engine/watchlists/${watchlist_nid}/individuals`}
            >
              {t(`predefined.${watchlist_nid}.name`)}
            </Link>
          </TableCellSpan>
        )
      },
    },
    {
      key: "description",
      header: t("tableHeaders.description"),
      render: (
        description: string,
        { watchlist_nid }: { watchlist_nid: PredefinedWatchlistId },
      ) => {
        return (
          <TableCellSpan allowCellWrap={true}>
            {t(`predefined.${watchlist_nid}.description`)}
          </TableCellSpan>
        )
      },
    },
  ]

  // We do the filtering on the client side for ease of translation of
  // watchlist names, since there are so few watchlists for the time
  // being. If we add many more we'll have to revisit this strategy.
  const filteredResults = useMemo(() => {
    return (
      data?.results.filter(
        ({ watchlist_nid }: { watchlist_nid: PredefinedWatchlistId }) => {
          return t(`predefined.${watchlist_nid}.name`)
            .toLowerCase()
            .includes(search.toLowerCase())
        },
      ) ?? []
    )
  }, [data?.results, search, t])

  return (
    <div>
      {loading && <ActivityIndicator active />}
      <MimecastCgBreadcrumbs
        breadcrumbs={["risk_response_engine", "watchlists"]}
      />
      <TableWithFiltersScreen
        title={t("watchlistsTitleText")}
        placeholder={t("searchPlaceholder")}
        appliedFiltersCount={0}
        searchValue={searchText}
        onSearch={(val: string) => {
          setSearchDebounced(val)
          setSearchText(val)
        }}
        clearSearch={() => {
          setSearch("")
          setSearchText("")
        }}
        entries={filteredResults}
        totalCount={filteredResults.length ?? 0}
        loading={!data}
        pagination={{
          pageSize: 1,
          current: 1,
          onChange: () => {},
        }}
        enablePagination={false}
        columns={columns}
        onSortedChange={() => {}}
        excludeSortColumn={columns.map((column) => column.key)}
        errorMessage={error && t("pluralErrorMessage")}
      >
        {null}
      </TableWithFiltersScreen>
    </div>
  )
}
