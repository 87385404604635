import React, { useEffect, useRef } from "react"
import {
  FiltersWrapper,
  FiltersTitleSection,
  FiltersCollapseButton,
  FiltersTitle,
  FiltersContent,
  Section,
  AppliedFiltersWrapper,
  AppliedFiltersTitleWrapper,
  AppliedFiltersTitle,
  AppliedFiltersClearLink,
} from "./styles"
import { Icon, theme } from "@elevate_security/elevate-component-library"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { getDropdownOptions, FILTER_TYPE } from "./utils"
import { CategoryDropdown } from "./CategoryDropdown"
import SliderFilter from "@src/scenes/IndividualRiskAnalysis/components/Filters/components/MoreFilter/components/SliderFilter"
import MultiSelectFilter from "@src/scenes/IndividualProfilePage/components/MoreFilter/MultiSelectFilter"
import { AppliedFilters } from "../AppliedFilters"
import { RISK_DATA_TYPES } from "@src/constants"

// Any behavior types that don't make sense for filtering will be excluded from the component
const EXCLUDED_BEHAVIOR_TYPES = ["training-completion-percentage"]

const CATEGORIES_RAP_TYPE_MAP = {
  detections: ["people", "detections", "behavior_type"],
  individual: ["behavior_type", "score", "people"],
  departments: ["score"],
  locations: ["score"],
}

export const Filters = (props) => {
  const {
    isOpen,
    filters,
    onSelection,
    riskOptions,
    enabledBehaviorTypes,
    toggleDrawer,
    clearUrl,
    appliedFilter,
    riskTypeOverride,
    optionsOverride,
    labelOverrides = {},
  } = props
  const titleRef = useRef()
  const { riskType: riskTypeParam } = useParams()
  const riskType = riskTypeOverride || riskTypeParam
  const riskData = RISK_DATA_TYPES[riskType]

  const {
    departments,
    locations,
    jobTitle,
    businessTitle,
    businessUnit,
    esManagerId,
    isOnleave,
    jobFamilyFunctionGroup,
    jobFamilyFunctionName,
    managerNid,
    preferredLanguage,
    preferredName,
    preferredMiddleName,
    preferredNameLocalLang,
    timeType,
    workLocationCountry,
    workLocationGeo,
    workLocationTimezone,
    workerType,
    workLocationBuilding,
    workLocationCity,
    workLocationName,
    workSpace,
    lengthOfService,
    hireDate,
    isActive,
    leaveDate,
    alternativeUserId1,
    alternativeUserId2,
    availableHRMetadataKeys,
  } = useSelector((state) => state.get("analysisIndividualReducer"))

  const dropdownParams = {
    riskType: riskType,
    departments: departments || [],
    locations: locations || [],
    behaviorTypes: (riskOptions || []).slice().sort(),
    jobTitle: jobTitle || [],
    businessTitle: businessTitle || [],
    businessUnit: businessUnit || [],
    esManagerId: esManagerId || [],
    isOnleave: isOnleave || [],
    jobFamilyFunctionGroup: jobFamilyFunctionGroup || [],
    jobFamilyFunctionName: jobFamilyFunctionName || [],
    managerNid: managerNid || [],
    preferredLanguage: preferredLanguage || [],
    preferredName: preferredName || [],
    preferredMiddleName: preferredMiddleName || [],
    preferredNameLocalLang: preferredNameLocalLang || [],
    timeType: timeType || [],
    workLocationCountry: workLocationCountry || [],
    workLocationGeo: workLocationGeo || [],
    workLocationTimezone: workLocationTimezone || [],
    workerType: workerType || [],
    workLocationBuilding: workLocationBuilding || [],
    workLocationCity: workLocationCity || [],
    workLocationName: workLocationName || [],
    workSpace: workSpace || [],
    lengthOfService: lengthOfService || [],
    hireDate: hireDate || [],
    isActive: isActive || [],
    leaveDate: leaveDate || [],
    alternativeUserId1: alternativeUserId1 || [],
    alternativeUserId2: alternativeUserId2 || [],
    enabledBehaviorTypes: enabledBehaviorTypes.filter(
      ({ behaviorType }) => !EXCLUDED_BEHAVIOR_TYPES.includes(behaviorType),
    ),
  }

  const options =
    optionsOverride ||
    getDropdownOptions(dropdownParams, availableHRMetadataKeys)

  const renderFilterComponent = (section) => {
    const {
      type,
      showAllFilter,
      isCollapsible,
      initialCollapseState,
      infoMessage,
      range,
      stepIncrement,
    } = section
    switch (type) {
      case FILTER_TYPE.MULTI_SELECT:
        return (
          <MultiSelectFilter
            section={section}
            filters={filters}
            handleChange={(filter) => onSelection(filter)}
            showAllFilters={showAllFilter}
            isCollapsible={isCollapsible}
            initialCollapseState={initialCollapseState}
            infoMessage={infoMessage}
          />
        )
      case FILTER_TYPE.SLIDER:
        return (
          <SliderFilter
            section={section}
            filters={filters}
            handleChange={(filter) => onSelection(filter)}
            initialRange={range || [0, 10]}
            stepIncrement={stepIncrement}
            infoMessage={infoMessage}
          />
        )
      default:
        return <div>Unsupported filter type &quot;{type}&quot;.</div>
    }
  }

  const renderFilterCategories = () => {
    const formattedOptions = options.reduce(
      (accumulator, currentValue) => {
        if (currentValue?.category === "behavior_type") {
          accumulator.behavior_type.items.push(currentValue)
        } else if (currentValue?.category === "score") {
          accumulator.score.items.push(currentValue)
        } else if (currentValue?.category === "detections") {
          accumulator.detections.items.push(currentValue)
        } else {
          accumulator.people.items.push(currentValue)
        }
        return accumulator
      },
      {
        people: {
          label: "People",
          items: [],
        },
        behavior_type: {
          label: labelOverrides.behaviorType || "Behavior type",
          items: [],
        },
        score: {
          label: "Scores",
          items: [],
        },
        detections: {
          label: "Detections",
          items: [],
        },
      },
    )
    const filterGroupNames = Object.keys(formattedOptions).filter((el) =>
      CATEGORIES_RAP_TYPE_MAP[riskType].includes(el),
    )

    return (
      <div>
        {filterGroupNames?.map((filterGroupName) => {
          const { label, items: filterGroupsSections } =
            formattedOptions[filterGroupName]
          if (!filterGroupsSections?.length) {
            return <></>
          }

          return (
            <CategoryDropdown key={filterGroupName} label={label}>
              {filterGroupsSections.map((section) => (
                <Section
                  key={section.title}
                  data-id={`${section.key}-filter-header`}
                >
                  {renderFilterComponent(section)}
                </Section>
              ))}
            </CategoryDropdown>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    function scrollToBottom() {
      titleRef.current.scrollIntoView()
    }
    if (isOpen) {
      scrollToBottom()
    }
  }, [isOpen])

  return (
    <FiltersWrapper>
      <FiltersTitleSection ref={titleRef}>
        <FiltersTitle>Simple Filters</FiltersTitle>
        <FiltersCollapseButton onClick={() => toggleDrawer(false)}>
          <Icon name="CaretRight" fill={theme.colors.gray.opaque[500]} />
        </FiltersCollapseButton>
      </FiltersTitleSection>
      <FiltersContent>
        {/* Applied filters*/}
        {appliedFilter && (
          <AppliedFiltersWrapper>
            <AppliedFiltersTitleWrapper>
              <AppliedFiltersTitle>Applied Filters</AppliedFiltersTitle>
              <AppliedFiltersClearLink onClick={clearUrl}>
                <span>Clear All</span>
                <Icon
                  size="xxsm"
                  name="Close"
                  fill={theme.colors.gray.opaque[500]}
                />
              </AppliedFiltersClearLink>
            </AppliedFiltersTitleWrapper>

            <AppliedFilters
              riskPrefix={riskData.columnKeyPrefix}
              riskTypeOverride={riskTypeOverride}
            />
          </AppliedFiltersWrapper>
        )}

        {renderFilterCategories()}
      </FiltersContent>
    </FiltersWrapper>
  )
}
