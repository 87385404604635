import {
  SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
  SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
  SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
} from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"

import { SCORE_LEVELS as SCORE_LEVELS_ORIG } from "../SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

export const RISK_RESPONSE_RULE_PATH =
  "/engagement/vision2/risk-response-engine/risk-response-rules"

export const BREADCRUMB_LINKS = () => [
  {
    label: "Risk Response Engine",
    link: RISK_RESPONSE_RULE_PATH,
  },
  {
    label: "Risk Response Rules",
    link: RISK_RESPONSE_RULE_PATH,
  },
]

export const EMAIL_ACTION_TYPES = [
  SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
  SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
  SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
]

export const DEFAULT_RISK_LEVEL_LABEL = "Risk - "

export const SCORE_LEVELS = Object.entries(SCORE_LEVELS_ORIG).reduce(
  (acc, [key, value]) => ((acc[value] = key), acc),
  {},
)

export const dummyDataActionLog = [
  {
    rule_version: "empty",
    actions: [
      {
        action_type: "empty",
      },
    ],
    created_datetime: "empty",
    first_name: "empty",
    last_name: "empty",
  },
  {
    rule_version: "empty",
    actions: [
      {
        action_type: "empty",
      },
    ],
    created_datetime: "empty",
    first_name: "empty",
    last_name: "empty",
  },
  {
    rule_version: "empty",
    actions: [
      {
        action_type: "empty",
      },
    ],
    created_datetime: "empty",
    first_name: "empty",
    last_name: "empty",
  },
  {
    rule_version: "empty",
    actions: [
      {
        action_type: "empty",
      },
    ],
    created_datetime: "empty",
    first_name: "empty",
    last_name: "empty",
  },
  {
    rule_version: "empty",
    actions: [
      {
        action_type: "empty",
      },
    ],
    created_datetime: "empty",
    first_name: "empty",
    last_name: "empty",
  },
]
