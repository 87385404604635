import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "@elevate_security/elevate-component-library"

import { ComboBoxWrapper } from "./styles"
import { getMicrosoftTeamsMembers } from "@src/services/apis/microsoftTeams"
import { useRequest } from "ahooks"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"

const { Text } = Typography

const MicrosoftTeamsMembersComboBox = ({
  notification,
  setSelectedMicrosoftTeamsNotification,
}) => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalMembers, setTotalMembers] = useState(0)
  const microsoftTeamsMembers =
    notification?.microsoft_teams_members?.map((member) => ({
      ...member,
      label: member.email,
      value: member.azure_ad_object_id,
    })) || []

  const { data, loading } = useRequest(
    () =>
      getMicrosoftTeamsMembers({
        search: `${search}`,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        if (page === 0) {
          setTotalMembers(response?.data?.count || 0)
          return (
            response?.data?.results?.map((member) => ({
              ...member,
              label: `${member?.email}`,
              value: member?.azure_ad_object_id,
            })) || []
          )
        } else {
          return [
            ...data,
            ...response?.data?.results?.map((member) => ({
              ...member,
              label: `${member?.email}`,
              value: member?.azure_ad_object_id,
            })),
          ]
        }
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
  }

  const handleOnChange = (selected) => {
    const microsoftTeamsMembers = selected?.map((item) => ({
      ...item,
      azure_ad_object_id: item.value,
      email: item.email,
    }))
    setSelectedMicrosoftTeamsNotification({
      ...notification,
      microsoft_teams_members: microsoftTeamsMembers,
    })
  }

  return (
    <SpaceBetween size="sm">
      <Text color="700" fontWeight="bold">
        Recipient Individual(s)
      </Text>
      <ComboBoxWrapper>
        <ComboBoxWithOverlap
          data={data}
          defaultValue={microsoftTeamsMembers || []}
          value={microsoftTeamsMembers || []}
          onChange={handleOnChange}
          isLoading={loading}
          clearable={false}
          isSearchable={true}
          isMultiOption={true}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{}}
          disabled={false}
          placeholder="Choose or search Microsoft Teams members"
          handleScrollBotttom={() => {
            if (data.length < totalMembers) {
              setPage(page + 1)
            }
          }}
          handleInputChange={(value) => handleSetSearch(value)}
        />
      </ComboBoxWrapper>
    </SpaceBetween>
  )
}

MicrosoftTeamsMembersComboBox.propTypes = {
  notification: PropTypes.object,
  setSelectedMicrosoftTeamsNotification: PropTypes.func,
}

export default MicrosoftTeamsMembersComboBox
