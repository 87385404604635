import React, { useState, useEffect, useRef } from "react"
import dompurify from "dompurify"
import { base64ToString } from "@src/utils/string"

import {
  EmailPreviewWrapper,
  EmailPreviewHeader,
  EmailPreviewHeaderLabel,
  EmailPreviewContent,
} from "./styles"
import { SpaceBetween } from "@src/components/SpaceBetween"

function targetAllLinksBlank(html) {
  const div = document.createElement("div")
  div.innerHTML = html
  const links = div.querySelectorAll("a")
  Array.from(links).forEach((link) => {
    link.setAttribute("rel", "noreferrer noopener")
    link.setAttribute("target", "_blank")
  })
  return div.innerHTML
}

function EmailPreview(props) {
  const { data } = props

  const sanitizer = dompurify.sanitize
  const [htmlTemplate, setHtmlTemplate] = useState("")
  const [plainSubject, setPlainSubject] = useState("")

  useEffect(() => {
    setHtmlTemplate(base64ToString(data?.body))
    setPlainSubject(base64ToString(data?.subject))
  }, [data])

  const htmlContent = targetAllLinksBlank(sanitizer(htmlTemplate))

  const htmlPreviewHost = useRef(null)

  useEffect(() => {
    if (htmlPreviewHost.current) {
      // Using shadow dom to prevent app styles from leaking into email preview
      const shadow =
        htmlPreviewHost.current.shadowRoot ??
        htmlPreviewHost.current.attachShadow({ mode: "open" })
      shadow.innerHTML = htmlContent
    }
  }, [htmlContent])

  return (
    <EmailPreviewWrapper>
      <EmailPreviewHeader>
        <SpaceBetween direction="row" justify="flex-start">
          <EmailPreviewHeaderLabel>
            <b>From:</b>
          </EmailPreviewHeaderLabel>
          <div>{data?.sender}</div>
        </SpaceBetween>
        <SpaceBetween direction="row" justify="flex-start">
          <EmailPreviewHeaderLabel>
            <b>Subject:</b>
          </EmailPreviewHeaderLabel>
          <div>{plainSubject}</div>
        </SpaceBetween>
      </EmailPreviewHeader>
      <EmailPreviewContent>
        <div ref={htmlPreviewHost} />
      </EmailPreviewContent>
    </EmailPreviewWrapper>
  )
}

export default EmailPreview
