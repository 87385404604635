import styled from "styled-components"
import "tippy.js/themes/light-border.css"
export const Points = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #34383c;
  padding: 17px 0 8px 0px;
  margin-bottom: 0;
  span {
    font-size: 20px;
    line-height: 32px;
  }
`
