import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const SummaryStatisticsFooterLink = styled(Link)`
  color: ${THEME_PRIMARY};
  font-size: 16px;
  font-weight: 600;
`

export const SummaryStatisticsFooterButton = styled.span`
  color: ${THEME_PRIMARY};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: ${THEME_HOVER};
    svg {
      path {
        fill: ${THEME_HOVER};
      }
    }
  }
`

export const SummaryStatisticsValue = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 700;
`

export const RiskResponseValue = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 32px;
  font-weight: 800;
`

export const RiskResponseFooter = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 700;
`

export const SummaryStatisticsItem = styled.span`
  display: flex;
  flex-direction: column;
`

export const SummaryStatisticsTitleItem = styled.span`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const SummaryStatisticsBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const Divider = styled.div`
  width: 1px;
  height: 30px;
  background: #c9cfd6;
`
export const IRPSummaryStatisticsBox = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 19px;
`

export const IRPDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #c9cfd6;
`
