import React from "react"
import { Icon, Tooltip } from "@elevate_security/elevate-component-library"
import { SpacingX } from "@src/components/SpacingX"
import { SpacingY } from "@src/components/SpacingY"
import { SpaceBetween, SpaceBetweenProps } from "@src/components/SpaceBetween"
import ImageExportWrapper from "../ImageExportWrapper"
import {
  DashboardCardWrapper,
  Header,
  IconWrapper,
  SubHeader,
} from "./DashboardCard.styles"

type DashboardCardProps = {
  title?: string
  subtitle?: string
  info?: React.ReactNode
  flex?: string
  style?: { [key: string]: string }
  spacing?: SpaceBetweenProps["size"]
  variant?: "solid" | "transparent"
  imageExportId?: string
  renderControls?: () => React.ReactNode
}

export function DashboardCard({
  children,
  info,
  title,
  subtitle,
  spacing = "md",
  variant = "solid",
  imageExportId,
  renderControls = () => null,
  ...rest
}: React.PropsWithChildren<DashboardCardProps>) {
  const extraWrapperProps: { [key: string]: any } = {}
  if (title) {
    // In many cases there aren't useful CSS prefixes to select from within the
    // card, so we add an extra  ID based on the card title make it easier
    // to select sub-elements.
    extraWrapperProps["data-analytics"] = title
  }

  return (
    <DashboardCardWrapper {...rest} variant={variant} {...extraWrapperProps}>
      {renderWithImageExportWrapper(
        imageExportId,
        <SpacingY size={spacing}>
          {title && (
            <SpaceBetween direction="row" alignItems="flex-start">
              <SpacingY size="xs">
                <SpacingX alignItems="center">
                  {title && <Header>{title}</Header>}
                  {info && (
                    <IconWrapper>
                      <Tooltip body={info} placement="top" readOnly size="xsm">
                        <Icon name="InfoCheckOutline" size="xsm" />
                      </Tooltip>
                    </IconWrapper>
                  )}
                </SpacingX>
                {subtitle && <SubHeader>{subtitle}</SubHeader>}
              </SpacingY>
              <div>{renderControls()}</div>
            </SpaceBetween>
          )}
          <div>{children}</div>
        </SpacingY>,
      )}
    </DashboardCardWrapper>
  )
}

function renderWithImageExportWrapper(
  imageExportId: string | undefined,
  content: React.ReactNode,
): React.ReactNode {
  return imageExportId ? (
    <ImageExportWrapper id={imageExportId}>{content}</ImageExportWrapper>
  ) : (
    content
  )
}
