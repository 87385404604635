import React from "react"
import PropTypes from "prop-types"

// Styled Components
import {
  ActionItemsWrapper,
  NoActionItemsMessageWrapper,
  NoActionItemsWrapper,
  ActionItemCardWrapper,
} from "./styles"

export default function ActionItems(props) {
  const { actionsList, isRiskInsights } = props

  function renderNoActionItemsMessage() {
    return (
      <NoActionItemsWrapper>
        <img
          src="https://hm-assets.elevatesecurity.com/master/pulse/Fortified.png"
          alt=""
          width="30%"
        />
        <NoActionItemsMessageWrapper>
          <p>All insights reviewed!</p>
          <p>We&#8217;ll have a fresh batch for you soon. Stay tuned!</p>
        </NoActionItemsMessageWrapper>
      </NoActionItemsWrapper>
    )
  }

  return (
    <ActionItemsWrapper isRiskInsights={isRiskInsights}>
      {actionsList.length
        ? actionsList.map((action, index) => (
            <ActionItemCardWrapper key={index} isRiskInsights={isRiskInsights}>
              {action.description}
              {action.action}
            </ActionItemCardWrapper>
          ))
        : renderNoActionItemsMessage()}
    </ActionItemsWrapper>
  )
}

ActionItems.propTypes = {
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.node,
    }),
  ),
}
