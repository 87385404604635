import React, { useEffect, useRef, useState } from "react"
import { ComboBoxWrapper, FixedLabelWrapper } from "./styles"
import { Typography } from "@elevate_security/elevate-component-library"
import { useDispatch, useSelector } from "react-redux"
import { useRequest } from "ahooks"
import { getFilterGroups, getGroupsCount } from "@src/services/apis/groups"
import {
  getDropdownData,
  getReceptionGroupData,
  listIndividuals,
  loadingChip,
  setInitialData,
  ShowRightIcon,
} from "../ComboboxWithIndividuals/utils"
import PropTypes from "prop-types"
import { validateEmail } from "@src/utils/string"

import { setIndividualsMailsDefineOutCome } from "@src/services/redux/RiskDetectionRules/DefineOutcome/actions"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"
import { showToast } from "@src/services/redux/toasts/actions"

const setIndividualsMails = (value, dispatch) => {
  dispatch(setIndividualsMailsDefineOutCome(value))
}

const { Text } = Typography

const DEFAULT_ERROR_MESSAGE = "Specify at least 1 recipient"
function ComponentWithIndividuals({ errors, setErrors, onChange = () => {} }) {
  const dispatch = useDispatch()
  const { individualsMails, receptionGroupData } = useSelector((getReducers) =>
    getReducers.get("defineOutcomeReducer"),
  )
  const timeout = useRef(false)
  const [totalIndividuals, setTotalIndividuals] = useState()
  const [search, setSearch] = useState("")
  const [recipientIndividual, setRecipientIndividual] = useState([])
  const [loader, setLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE)

  const [indPageNum, setIndPageNum] = useState(0)
  const checkMemberCount = () =>
    !!receptionGroupData.filter((item) => !item?.members_count).length

  const fixedLabel = [
    {
      fixedLabel: (
        <FixedLabelWrapper>
          {receptionGroupData
            .map((item) => item.members_count)
            .reduce((a, b) => a + b, 0)
            .toLocaleString()}{" "}
          individuals <ShowRightIcon />
        </FixedLabelWrapper>
      ),
    },
  ]

  const { loading, data } = useRequest(
    () =>
      getFilterGroups({
        search: `${search}`,
        is_active: true,
        ordering: "first_name, last_name",
        groups_ids: !receptionGroupData.length
          ? `[]`
          : `[${getReceptionGroupData(receptionGroupData)}]`,
        limit: 100,
        offset: indPageNum * 100,
      }).catch((error) => {
        // ahooks v2 has some weird behaviour with error handling and rejected
        // promises, so we manually handle the error with a .catch.
        // See https://github.com/alibaba/hooks/issues/812
        dispatch(
          showToast(
            "Failed to load employee group information, please try again",
            "error",
          ),
        )
        return { error }
      }),
    {
      refreshDeps: [receptionGroupData.length, indPageNum, search],
      debounceInterval: 500,
      formatResult: (response) => {
        if (response.error) {
          return
        }
        setLoader(false)
        if (indPageNum === 0) {
          setTotalIndividuals(response.data?.count || 0)
          return listIndividuals(
            response?.data?.results,
            recipientIndividual,
            setRecipientIndividual,
          )
        } else {
          return [
            ...data,
            ...listIndividuals(
              response.data?.results,
              recipientIndividual,
              setRecipientIndividual,
            ),
          ]
        }
      },
    },
  )

  const {
    loading: countLoading,
    data: countData,
    run: getCount,
  } = useRequest(
    () =>
      getGroupsCount({
        params: receptionGroupData.map((item) => `"${item.value}"`).join(","),
      }).catch((error) => {
        // ahooks v2 has some weird behaviour with error handling and rejected
        // promises, so we manually handle the error with a .catch.
        // See https://github.com/alibaba/hooks/issues/812
        dispatch(
          showToast(
            "Failed to load total individual count for groups",
            "error",
          ),
        )
        return { error }
      }),
    {
      manual: true,
      formatResult: (res) => {
        return res.data?.count ?? "?"
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    clearTimeout(timeout.current)
    setLoader(true)
    timeout.current = setTimeout(() => {
      setSearch(value)
    }, 500)
  }

  const handleOnChange = (selected) => {
    setErrorMessage(DEFAULT_ERROR_MESSAGE)
    setErrors(false)
    selected = selected.map((item) => {
      if (item?.value) {
        const isValidEmail = validateEmail(item?.value)
        if (!isValidEmail) {
          setErrorMessage(
            isValidEmail
              ? errorMessage
              : `Incorrect email format (${item?.value}). Please re-enter email address.`,
          )
          setErrors(!isValidEmail)
        }
        return {
          ...item,
          isError: !isValidEmail,
        }
      }
      return item
    })
    setRecipientIndividual(selected)
    const mails = Array.from(
      new Set(
        selected.filter((item) => !item.fixedLabel).map((item) => item?.value),
      ),
    )
    setIndividualsMails(mails, dispatch)
    onChange(mails)
  }

  const showValueWithChip = () => {
    if (countLoading) {
      const loadingData = loadingChip()
      return getDropdownData(
        receptionGroupData,
        recipientIndividual,
        loadingData,
      )
    }

    if (checkMemberCount()) {
      const firstItem = [
        {
          fixedLabel: (
            <FixedLabelWrapper>
              {countData} individuals <ShowRightIcon />
            </FixedLabelWrapper>
          ),
        },
      ]
      return getDropdownData(receptionGroupData, recipientIndividual, firstItem)
    }

    return getDropdownData(receptionGroupData, recipientIndividual, fixedLabel)
  }

  useEffect(() => {
    setRecipientIndividual([])
    if (checkMemberCount()) {
      getCount()
    }
    setIndPageNum(0)
    // reset errors if all values cleared
    setErrors(false)
    setErrorMessage(DEFAULT_ERROR_MESSAGE)
  }, [receptionGroupData.length])

  useEffect(() => {
    if (individualsMails.length) {
      setRecipientIndividual(setInitialData(individualsMails))
    }
  }, [])

  return (
    <SpaceBetween size="sm">
      <Text color="700" fontWeight="bold">
        Recipient Individual(s)
      </Text>
      <ComboBoxWrapper>
        <ComboBoxWithOverlap
          data={data}
          clearable={false}
          onChange={handleOnChange}
          styles={{
            multiValueRemove: (base, state) => {
              return state.data.fixedLabel ? { ...base, display: "none" } : base
            },
          }}
          isSearchable={true}
          isMultiOption={true}
          isCreatable={true}
          checked={true}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{}}
          disabled={false}
          placeholder="Choose or search individual(s)"
          hasError={errors}
          errorMessage={errorMessage}
          isLoading={loading || loader}
          value={showValueWithChip()}
          handleScrollBotttom={() => {
            if (data.length < totalIndividuals) {
              setIndPageNum(indPageNum + 1)
            }
          }}
          handleInputChange={(value) => handleSetSearch(value)}
        />
      </ComboBoxWrapper>
    </SpaceBetween>
  )
}

ComponentWithIndividuals.prototype = {
  errors: PropTypes.bool,
  setErrors: PropTypes.func,
}
export default ComponentWithIndividuals
