declare const REACT_ROUTER_BASE_URL: string

import { extractRoute } from "./extractRoute"
import { useHistory } from "react-router-dom"

export function forceNavigate(
  history: ReturnType<typeof useHistory>,
  rawRoute: string,
) {
  // history.location will always provide the pathname without the base URL, while
  // the rawRoute may include it. In order to navigate properly, we need to
  // strip it from the start.
  const route = rawRoute.startsWith(REACT_ROUTER_BASE_URL)
    ? rawRoute.replace(REACT_ROUTER_BASE_URL, "")
    : rawRoute

  const currentRoute = normalizeRoute(extractRoute(history.location))

  const newRoute = normalizeRoute(route)

  if (currentRoute !== newRoute) {
    history.push(route, {})
  }
}

function normalizeRoute(route: string): string {
  if (!route.startsWith("/")) {
    return `/${route}`
  }
  return route
}
