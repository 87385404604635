import styled from "styled-components"

export const ManagerHierarchyWrapper = styled.div`
  margin-top: 8px;
  padding-left: 5px;
  color: #34383c;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`

export const ComboBoxWrapper = styled.div`
  width: 200px;
`
