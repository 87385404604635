import { humanize } from "@src/utils/string"
import {
  factorValues,
  FILTER_KEY,
  HR_DATA_FILTER_KEY,
} from "@src/scenes/IndividualRiskAnalysis/components/Filters/utils"
import { BOOLEAN_VALUES_MAP } from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import { getGlobalConfig } from "@src/globalConfig"

export const FILTER_TYPE = {
  MULTI_SELECT: "multi-select",
  SLIDER: "slider",
}

export const getGenericFilterForField = (name, values) => {
  const size = values?.length
  const humanized_name = humanize(name)
  return {
    type: FILTER_TYPE.MULTI_SELECT,
    title: humanized_name,
    key: name,
    items: values.map((el) => ({
      label: BOOLEAN_VALUES_MAP[el] ?? el ?? "-",
      value: el ?? "not_set",
    })),
    ...(size > 4 && {
      showMore: `Show all ${humanized_name}`,
      showLess: `Show less ${humanized_name}`,
    }),
  }
}

export const individualsFilters = (fields, availableHRMetadataKeys) => {
  // All HR Fields
  const hrFields = [
    getGenericFilterForField(HR_DATA_FILTER_KEY.JOB_TITLE, fields.jobTitle),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.BUSINESS_TITLE,
      fields.businessTitle,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.BUSINESS_UNIT,
      fields.businessUnit,
    ),
    getGenericFilterForField(HR_DATA_FILTER_KEY.IS_ONLEAVE, fields.isOnleave),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.JOB_FAMILY_FUNCTION_GROUP,
      fields.jobFamilyFunctionGroup,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.JOB_FAMILY_FUNCTION_NAME,
      fields.jobFamilyFunctionName,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_LANGUAGE,
      fields.preferredLanguage,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_NAME,
      fields.preferredName,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_MIDDLE_NAME,
      fields.preferredMiddleName,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_NAME_LOCAL_LANG,
      fields.preferredNameLocalLang,
    ),
    getGenericFilterForField(HR_DATA_FILTER_KEY.TIME_TYPE, fields.timeType),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_COUNTRY,
      fields.workLocationCountry,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_GEO,
      fields.workLocationGeo,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_TIMEZONE,
      fields.workLocationTimezone,
    ),
    getGenericFilterForField(HR_DATA_FILTER_KEY.WORKER_TYPE, fields.workerType),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_BUILDING,
      fields.workLocationBuilding,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_CITY,
      fields.workLocationCity,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_NAME,
      fields.workLocationName,
    ),
    getGenericFilterForField(HR_DATA_FILTER_KEY.WORK_SPACE, fields.workSpace),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.LENGTH_OF_SERVICE,
      fields.lengthOfService,
    ),
    getGenericFilterForField(HR_DATA_FILTER_KEY.HIRE_DATE, fields.hireDate),
    getGenericFilterForField(HR_DATA_FILTER_KEY.IS_ACTIVE, fields.isActive),
    getGenericFilterForField(HR_DATA_FILTER_KEY.LEAVE_DATE, fields.leaveDate),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.ALTERNATIVE_USER_ID_1,
      fields.alternativeUserId1,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.ALTERNATIVE_USER_ID_2,
      fields.alternativeUserId2,
    ),
  ]
  const filteredFields = hrFields?.filter((field) =>
    availableHRMetadataKeys?.includes(field.key),
  )
  return [
    {
      type: FILTER_TYPE.MULTI_SELECT,
      title: "Organizational Role",
      key: FILTER_KEY.ORGANIZATIONAL_ROLE,
      items: [
        { label: "Manager", value: "manager" },
        { label: "Contributor", value: "contributor" },
      ],
    },
    {
      type: FILTER_TYPE.MULTI_SELECT,
      title: "Employment Type",
      key: FILTER_KEY.EMPLOYMENT_TYPE,
      items: [
        { label: "Contractor", value: "contractor" },
        { label: "Employee", value: "employee" },
        { label: "-", value: "not_set" },
      ],
    },
    {
      type: FILTER_TYPE.MULTI_SELECT,
      title: "Tenure Status",
      key: FILTER_KEY.TENURE_STATUS,
      items: [
        { label: "New Hire", value: "new_hire" },
        { label: "Veteran", value: "veteran" },
      ],
    },
    {
      type: FILTER_TYPE.SLIDER,
      title: "Tenure Duration",
      key: FILTER_KEY.TENURE_DURATION,
      range: [0, 1000],
      stepIncrement: "1",
      infoMessage: "Tenure duration is specified in months",
    },
    ...(fields.departments?.length
      ? [
          {
            type: FILTER_TYPE.MULTI_SELECT,
            title: "Department",
            key: FILTER_KEY.DEPARTMENT,
            items: fields.departments.map((name) => ({
              label: name,
              value: name,
            })),
            ...(fields.departments.length > 4 && {
              showMore: "Show all departments",
              showLess: "Show less departments",
            }),
          },
        ]
      : []),
    ...(fields.locations?.length
      ? [
          {
            type: FILTER_TYPE.MULTI_SELECT,
            title: "Location",
            key: FILTER_KEY.LOCATION,
            items: fields.locations.map((name) => ({
              label: name,
              value: name,
            })),
            ...(fields.locations &&
              fields.locations.length > 4 && {
                showMore: "Show all locations",
                showLess: "Show less locations",
              }),
          },
        ]
      : []),
    {
      type: FILTER_TYPE.MULTI_SELECT,
      showAllFilter: true,
      title: "Attack Factor".toUpperCase(),
      key: "attack_factor",
      items: factorValues?.map((decision) => ({
        label: humanize(decision.label),
        value: decision.value,
      })),
    },
    {
      type: FILTER_TYPE.MULTI_SELECT,
      showAllFilter: true,
      title: "Action Factor".toUpperCase(),
      key: "action_factor",
      items: factorValues?.map((decision) => ({
        label: humanize(decision.label),
        value: decision.value,
      })),
    },
    ...filteredFields,
  ].filter((field) => {
    return (
      !getGlobalConfig("RAP_FIELD_EXCLUSIONS").includes(field.key) &&
      !getGlobalConfig("RAP_FILTER_EXCLUSIONS").includes(field.key)
    )
  })
}

function isCategoryPresent(behaviorTypes, category) {
  return behaviorTypes.some(({ actionCategory }) => actionCategory === category)
}

function getSliderTitle(key) {
  const suffix = !key.toLowerCase().endsWith("score") ? " Score" : ""
  return `${humanize(key)}${suffix}`
}

export const getDropdownOptions = (dropdownParams, availableHRMetadataKeys) => {
  const list = [
    ...(dropdownParams.riskType === "individual"
      ? individualsFilters(dropdownParams, availableHRMetadataKeys)
      : []),
    ...(dropdownParams.behaviorTypes
      ? dropdownParams.behaviorTypes.flatMap((key) => [
          {
            type: FILTER_TYPE.SLIDER,
            key,
            title: getSliderTitle(key),
            category: "score",
          },
          ...(isCategoryPresent(dropdownParams.enabledBehaviorTypes, key)
            ? [
                {
                  type: FILTER_TYPE.MULTI_SELECT,
                  // This is the filter key, used to send the params to the
                  // back-end. It must be "behavior_type" for all behavior type
                  // filters, so shouldn't be used as a React key for list
                  // rendering. Use the title instead.
                  key: FILTER_KEY.BEHAVIOR_TYPE,
                  title: `${humanize(key)} Behavior Types`,
                  showAllFilter: true,
                  isCollapsible: true,
                  initialCollapseState: true,
                  category: "behavior_type",
                  infoMessage:
                    "Behavior type search is limited to events from the past year",
                  items: dropdownParams.enabledBehaviorTypes
                    .filter(({ actionCategory }) => actionCategory === key)
                    .map(({ behaviorType, behaviorTypeShortName }) => ({
                      label: humanize(behaviorTypeShortName),
                      value: behaviorType,
                    })),
                },
              ]
            : []),
        ])
      : []),
  ]

  return list
}
