import React from "react"

import { CISCO_DUO_REMOVE_INDIVIDUALS } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { ActionGroups } from "./ActionGroups"
import { EditCiscoDuoGroups } from "../actionRenderComponents/EditCiscoDupGroups/EditCiscoDuoGroups"

export default {
  label: "Remove users from Cisco Duo Groups",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: CISCO_DUO_REMOVE_INDIVIDUALS,
    matching_method: "delta",
    target_groups: [],
    target_groups_meta: [],
    target_system: "cisco_duo",
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("cisco_duo", "group-membership"),
  render: ({ details, updateAction }) => {
    return (
      <EditCiscoDuoGroups
        title="Remove from Groups"
        action={details}
        updateAction={updateAction}
      />
    )
  },
}
