import styled from "styled-components"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"

const { H5, Text } = Typography

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    width: 684px;
    max-height: 80vh;
    padding-left: 40px;
    padding-right: 40px;
    [class^="Modalstyles__ModalFooter"] {
      display: none !important;
    }
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`

export const SubTitle = styled(H5)`
  font-size: 18px;
  font-weight: bold;
  color: #34383c;
`

export const OptionText = styled(Text)`
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  margin-top: 24px;
  color: #34383c;
  b {
    font-weight: bold;
  }
`

export const SlackMessage = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 16px;
  color: #565d66;
  background-color: #fafafa;
  border: 1px solid #dde1e7;
  border-radius: 4px;
  margin-top: 8px;
`

export const ThankYouText = styled(Text)`
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding: 40px;
  font-weight: normal;
`

export const FlexRow = styled.div`
  display: flex;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const Summary = styled.div`
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dde1e7;
`
