import styled from "styled-components"

export const GroupChart = styled.svg`
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => (!padding ? "initial" : padding)};
  align-items: ${({ alignItems }) => (!alignItems ? "initial" : alignItems)};
  justify-content: ${({ justifyContent }) =>
    !justifyContent ? "initial" : justifyContent};
`
export const Column = styled.div`
  display: flex;
  width: ${({ width }) => (!width ? "auto" : width)};
  min-width: ${({ minWidth }) => (!minWidth ? "auto" : minWidth)};
  padding: 8px;
  flex-direction: column;
  flex-wrap: nowrap;
`
export const AxisLabel = styled.strong`
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  white-space: nowrap;
  margin: ${({ margin }) => (!margin ? "initial" : margin)};
  padding: ${({ padding }) => (!padding ? "initial" : padding)};
  transform: ${({ transform }) => (!transform ? "initial" : transform)};
  writing-mode: ${({ writingMode }) =>
    !writingMode ? "initial" : writingMode};
  text-orientation: ${({ textOrientation }) =>
    !textOrientation ? "initial" : textOrientation};
`
export const TitleLegend = styled(AxisLabel)``

export const LegendLabel = styled.label`
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
  cursor: pointer;
  > span {
    display: flex;
  }

  &:hover {
    svg g path {
      fill: #039ba1;
    }
  }
`
export const LegendIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 20px;
  align-items: center;
`
export const LegendBullet = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin: auto 8px;
  border-radius: 50%;
  background-color: ${({ color }) => (!color ? "#0C7D84" : color)};
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
  margin-bottom: 0;
`

export const Separator = styled.div`
  display: block;
  height: 1px;
  background-color: #c9cfd6;
  margin-bottom: 10px;
  margin-top: 6px;
`

export const Strong = styled.strong`
  font-weight: bold;
`
export const Note = styled.span`
  color: #7b828a;
  font-size: 13px;
`
export const GraphTabsPosition = styled.div`
  .changeGraphTabsPosition {
    position: absolute;
    right: 0px;
    top: -80px;
  }
`
