import styled from "styled-components"

export const TableWrapper = styled.div`
  .table-row {
    padding: 10px 0;
    margin-top: 0;
  }
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
  }
`

export const Cell = styled.div`
  line-height: 24px;
  font-size: 14px;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ErrorMessage = styled.div`
  text-align: center;
  padding: 50px 0px;
`
