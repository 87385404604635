import styled from "styled-components"
import { IconAction } from "@elevate_security/elevate-component-library"

export const ManageCriticalityWrapper = styled.div`
  select {
    width: 100px;
    padding: 4px 8px;
    border-radius: 4px !important;
  }
  svg {
    width: 16px;
  }
`

export const ValueBox = styled.div`
  width: 100px;
`

export const CheckIcon = styled(IconAction)`
  svg path {
    fill: #0c7d84 !important;
  }
`

export const CrossIcon = styled(IconAction)`
  svg path {
    fill: #ef5252 !important;
  }
`
