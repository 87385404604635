import React from "react"
import { Input, ComboBox } from "@src/components/MimecastReskin"
import { EventOccurrenceItemWrapper } from "./styles"
import { EVENT_OCCURRENCE_OPERATORS } from "../constant"
import { ItemWrapper } from "../ConditionsGroup/styles"

function updatesEventsOccurrenceValues(idx, value, eventsOccurrence) {
  const data = [...eventsOccurrence.value]
  data[idx] = value

  return data
}

export const EventOccurrenceItem = (props) => {
  const { eventOccurrence, handleChange, time_mode_ops } = props
  const { value } = eventOccurrence
  const [operator] = value
  return (
    <ItemWrapper class="tree-item">
      <EventOccurrenceItemWrapper>
        <div>
          <strong>THAT OCCURRED</strong>
        </div>
        <div>
          <div>
            <ComboBox
              styles={{
                control: (baseStyles) => ({ ...baseStyles, width: "180px" }),
              }}
              data={EVENT_OCCURRENCE_OPERATORS}
              value={EVENT_OCCURRENCE_OPERATORS?.find(
                (op) => op.value === eventOccurrence.value[0],
              )}
              onChange={(e) =>
                handleChange({
                  ...eventOccurrence,
                  value: updatesEventsOccurrenceValues(
                    0,
                    e.value,
                    eventOccurrence,
                  ),
                })
              }
              clearable={false}
              isSearchable={false}
              isMultiOption={false}
              checked={false}
              hideSelectedOptions
              closeMenuOnSelect
              components={{}}
              disabled={false}
              placeholder="Select operator"
            />
          </div>
        </div>
        <div>
          <Input
            type="number"
            name="value"
            placeholder="0"
            className="event-occurance-input"
            min={0}
            value={eventOccurrence.value[1]}
            onChange={(e) =>
              handleChange({
                ...eventOccurrence,
                value: updatesEventsOccurrenceValues(
                  1,
                  parseInt(e),
                  eventOccurrence,
                ),
              })
            }
          />
        </div>
        {operator === "between" && (
          <div>
            <Input
              type="number"
              name="value"
              placeholder="0"
              className="event-occurance-input"
              min={0}
              value={eventOccurrence.value[4]}
              onChange={(e) =>
                handleChange({
                  ...eventOccurrence,
                  value: updatesEventsOccurrenceValues(
                    4,
                    parseInt(e),
                    eventOccurrence,
                  ),
                })
              }
            />
          </div>
        )}
        <div>
          <b>time(s) in the last</b>
        </div>
        <div>
          <Input
            type="number"
            name="value"
            placeholder="0"
            className="event-occurance-input"
            min={0}
            value={eventOccurrence.value[2]}
            onChange={(e) =>
              handleChange({
                ...eventOccurrence,
                value: updatesEventsOccurrenceValues(
                  2,
                  parseInt(e),
                  eventOccurrence,
                ),
              })
            }
          />
        </div>
        <div>
          <div>
            <ComboBox
              styles={{
                control: (baseStyles) => ({ ...baseStyles, width: "120px" }),
              }}
              data={time_mode_ops}
              value={time_mode_ops?.find(
                (op) => op.value === eventOccurrence.value[3],
              )}
              onChange={(e) =>
                handleChange({
                  ...eventOccurrence,
                  value: updatesEventsOccurrenceValues(
                    3,
                    e.value,
                    eventOccurrence,
                  ),
                })
              }
              clearable={false}
              isSearchable={false}
              isMultiOption={false}
              checked={false}
              closeMenuOnSelect
              components={{}}
              disabled={false}
              placeholder="Select duration"
            />
          </div>
        </div>
      </EventOccurrenceItemWrapper>
    </ItemWrapper>
  )
}
