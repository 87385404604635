import React, { useEffect, useRef, useState } from "react"
import { Icon } from "@elevate_security/elevate-component-library"
import { Input, Toggle } from "@src/components/MimecastReskin"

import {
  FieldComboboxWrapper,
  FieldValueBox,
  Menu,
  MenuHeader,
  MenuList,
  MenuListItem,
  TogglesGroup,
  ToggleWrapper,
  ToggleWrapperText,
} from "./styles"
import { isEmpty } from "@src/utils/string"
import { darklyGetFlag } from "@src/utils/darkly"
import { VENDOR_DATA_IDENTIFIER } from "../constant"

function FieldCombobox(props) {
  const {
    options,
    placeholder = "Select field",
    defaultValue,
    onChange,
  } = props
  const ref = useRef()
  const [showMenu, setShowMenu] = useState(false)
  const [showElevateDataSchema, setShowElevateDataSchema] = useState(true)
  const [showVendorDataSchema, setShowVendorDataSchema] = useState(true)
  const [elevateData, setElevateData] = useState([])
  const [vendorData, setVendorData] = useState([])
  const [filteredElevateData, setFilteredElevateData] = useState([])
  const [filteredVendorData, setFilteredVendorData] = useState([])
  const [transformedData, setTransformedData] = useState([])
  const [search, setSearch] = useState("")

  const showNonCanonicalFields = darklyGetFlag("rdr-non-canonical-fields")

  const toggleMenu = () => setShowMenu(!showMenu)

  const renderVendorDataOptions = (options, level) => {
    return options.map((option, index) => {
      if (!option.options) {
        return (
          <MenuListItem
            onClick={() => onSelectField(option)}
            nestedLevel={level - 1}
            key={`vendor-field-${index}-${level}`}
            className={option.value === defaultValue && "selected"}
            data-test-id={`fields-dropdown-nested-level-${level}-vendor-schema-option`}
          >
            {option.label}
          </MenuListItem>
        )
      } else {
        return (
          <React.Fragment key={`vendor-group-label-${index}-${level}`}>
            {level > 1 && (
              <MenuListItem
                className="group-label"
                nestedLevel={level - 1}
                data-test-id={`fields-dropdown-nested-level-${level}-vendor-schema-option-group-label`}
              >
                {option.label}
              </MenuListItem>
            )}
            {renderVendorDataOptions(option.options, level + 1)}
          </React.Fragment>
        )
      }
    })
  }

  const onSelectField = (selected) => {
    onChange(selected)
    // close menu on selecting item
    setShowMenu(false)
    setSearch("")
  }

  const handleSearch = (val) => setSearch(val)

  const resetFilteredData = () => {
    if (elevateData?.length) {
      setFilteredElevateData(elevateData)
    }
    if (vendorData?.length) {
      setFilteredVendorData(vendorData)
    }
  }

  // Group options
  useEffect(() => {
    setElevateData(
      options?.filter(
        (option) => !option?.value?.includes(`${VENDOR_DATA_IDENTIFIER}.`),
      ) || [],
    )
    setFilteredElevateData(
      options?.filter(
        (option) => !option?.value?.includes(`${VENDOR_DATA_IDENTIFIER}.`),
      ) || [],
    )
    if (showNonCanonicalFields) {
      setVendorData(
        options?.filter((option) =>
          option?.value?.includes(`${VENDOR_DATA_IDENTIFIER}.`),
        ) || [],
      )
      setFilteredVendorData(
        options?.filter((option) =>
          option?.value?.includes(`${VENDOR_DATA_IDENTIFIER}.`),
        ) || [],
      )
    }
  }, [options])

  // Format vendor schema
  useEffect(() => {
    if (!showNonCanonicalFields) return

    const transformData = (data) => {
      const transformedData = data.reduce((result, item) => {
        if (item.label.includes(".")) {
          const [prefix, ...remaining] = item.label.split(".")

          const existingOption = result.find(
            (option) => option.label === prefix,
          )
          if (existingOption) {
            if (!existingOption.options) {
              existingOption.options = []
            }
            existingOption.options.push({ ...item, label: remaining.join(".") })
          } else {
            result.push({
              label: prefix,
              options: [{ ...item, label: remaining.join(".") }],
            })
          }
        } else {
          result.push(item)
        }

        return result
      }, [])

      // Recursively transform nested options
      transformedData.forEach((option) => {
        if (option.options) {
          option.options = transformData(option.options)
        }
      })

      return transformedData
    }

    const transformedData = transformData(filteredVendorData)
    setTransformedData(transformedData)
  }, [filteredVendorData])

  // Make sure when user disables both Elvate & Vendor data schema select elevate by default
  useEffect(() => {
    if (!showElevateDataSchema && !showVendorDataSchema) {
      setShowElevateDataSchema(true)
    }
  }, [showElevateDataSchema, showVendorDataSchema])

  // Make sure when dropdown menu is opened both toggles are On
  useEffect(() => {
    if (showMenu) {
      setShowElevateDataSchema(true)
      setShowVendorDataSchema(true)
      resetFilteredData()
    }
  }, [showMenu])

  // Clicking outside should close menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowMenu(false)
        setSearch("")
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
  }, [ref])

  // Search handling here
  useEffect(() => {
    if (!isEmpty(search)) {
      const targetElevateData = elevateData?.filter((option) =>
        option.value.includes(search?.toLocaleLowerCase()),
      )
      setFilteredElevateData(targetElevateData)

      const targetVendorData = vendorData?.filter((option) =>
        option.value.includes(search?.toLocaleLowerCase()),
      )
      setFilteredVendorData(targetVendorData)
    } else {
      resetFilteredData()
    }
  }, [search])

  return (
    <FieldComboboxWrapper ref={ref}>
      <FieldValueBox
        data-test-id="fields-dropdown-toggle-button"
        className={showMenu && "focus"}
        onClick={toggleMenu}
      >
        <Input placeholder={placeholder} value={defaultValue} />
        <Icon name="CaretDown" size="xxsm" fill="#ccc" />
      </FieldValueBox>
      {showMenu && (
        <Menu>
          <MenuHeader>
            <Input
              name="field"
              data-test-id="fields-dropdown-search-box"
              placeholder="Search field..."
              style={{ width: "250px" }}
              iconLeft={<Icon name="Search" size="xsm" fill="#959DA8" />}
              value={search}
              onChange={handleSearch}
              autoFocus
            />
            {showNonCanonicalFields && filteredVendorData?.length > 0 && (
              <TogglesGroup data-test-id="fields-dropdown-schema-toggles">
                <ToggleWrapper>
                  <Toggle
                    name="elevate-data-schema-toggle"
                    data-test-id="fields-dropdown-elevate-schema-toggles"
                    checked={showElevateDataSchema}
                    onChange={(e) => {
                      setShowElevateDataSchema(e.checked)
                    }}
                  />
                  <ToggleWrapperText>Elevate Data Schema</ToggleWrapperText>
                </ToggleWrapper>
                <ToggleWrapper>
                  <Toggle
                    name="vendor-data-schema-toggle"
                    data-test-id="fields-dropdown-vendor-schema-toggles"
                    checked={showVendorDataSchema}
                    onChange={(e) => {
                      setShowVendorDataSchema(e.checked)
                    }}
                  />
                  <ToggleWrapperText>Vendor Data Schema</ToggleWrapperText>
                </ToggleWrapper>
              </TogglesGroup>
            )}
          </MenuHeader>
          <MenuList>
            {showElevateDataSchema && (
              <React.Fragment>
                {showNonCanonicalFields &&
                  filteredElevateData?.length > 0 &&
                  filteredVendorData?.length > 0 && (
                    <MenuListItem
                      className="header"
                      data-test-id="fields-dropdown-elevate-schema-header"
                    >
                      Elevate Data Schema
                    </MenuListItem>
                  )}
                {filteredElevateData?.map((option, index) => (
                  <MenuListItem
                    nestedLevel={1}
                    onClick={() => onSelectField(option)}
                    key={`elevate-${index}`}
                    className={option.value === defaultValue && "selected"}
                    data-test-id="fields-dropdown-elevate-schema-option"
                  >
                    {option.label}
                  </MenuListItem>
                ))}
              </React.Fragment>
            )}

            {showVendorDataSchema && (
              <React.Fragment>
                {showNonCanonicalFields && filteredVendorData?.length > 0 && (
                  <MenuListItem
                    className="header"
                    data-test-id="fields-dropdown-vendor-schema-header"
                  >
                    Vendor Data Schema
                  </MenuListItem>
                )}
                {renderVendorDataOptions(transformedData, 1)}
              </React.Fragment>
            )}
          </MenuList>
        </Menu>
      )}
    </FieldComboboxWrapper>
  )
}

export default React.memo(FieldCombobox)
