import React, { useState } from "react"
import IconLink from "@src/components/IconLink"
import PropTypes from "prop-types"
import { useActions } from "@src/utils/redux"
import { exportScoresBy } from "@src/services/redux/scores/actions"
import {
  BoldText,
  ModalContentWrapper,
  ModalWrapper,
} from "@src/components/ExportData/styles"
import {
  Modal,
  Header,
  Typography,
  Button,
} from "@elevate_security/elevate-component-library"
import { createPortal } from "react-dom"

const { H2 } = Typography

const DEFAULT_MAX_CSV_EXPORT_ROWS = 100000

function ExportData({
  type,
  filters = "",
  order_by = "",
  total,
  months,
  showButton,
  es_person_id,
  actions = "",
  riskAnalysisFlag,
  showLimitationModal,
  dataLength,
  text = "Export Data",
  max_rows = DEFAULT_MAX_CSV_EXPORT_ROWS,
  advancedFilters,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [showActivity, setShowActivity] = useState(false)
  const action = useActions({ exportScoresBy })

  const handleOnExport = () => {
    if (showActivity) return
    setIsOpen(false)
    action.exportScoresBy(
      type,
      filters || "",
      setShowActivity,
      riskAnalysisFlag,
      order_by,
      actions,
      { months, es_person_id },
      advancedFilters,
    )
  }

  const renderButtons = [
    <Button theme="ghost" onClick={() => setIsOpen(false)}>
      Cancel
    </Button>,
    <Button onClick={() => handleOnExport()}>Export</Button>,
  ]

  return (
    <>
      {createPortal(
        <ModalWrapper>
          <Modal
            onClose={() => {
              setIsOpen(false)
            }}
            header={
              <Header>
                <H2 color="900">Export Data</H2>
              </Header>
            }
            rightButtons={renderButtons}
            isOpen={isOpen}
          >
            <ModalContentWrapper>
              All exports are limited to <b>{max_rows.toLocaleString()}</b>{" "}
              results and the selected filters produces{" "}
              <b>{total.toLocaleString()}</b> results.
              <br />
              <br />
              You can try to apply more filters to produce a more concise export
              or get in touch with your CSM if you need a bigger export. <br />
              <br />
              <BoldText> Do you want to proceed exporting?</BoldText>
            </ModalContentWrapper>
          </Modal>
        </ModalWrapper>,
        document.body,
      )}
      {showButton ? (
        <Button
          style={{ zIndex: 2 }}
          fontWeight="bold"
          disabled={showActivity || !dataLength}
          onClick={() => {
            total > max_rows && showLimitationModal
              ? setIsOpen(true)
              : handleOnExport()
          }}
        >
          {showActivity ? "Exporting...." : "Export"}
        </Button>
      ) : (
        <IconLink
          iconName="Download"
          iconSize="xsm"
          fontWeight="bold"
          disabled={!dataLength}
          text={text}
          onClick={() => {
            total > max_rows && showLimitationModal
              ? setIsOpen(true)
              : handleOnExport()
          }}
          showActivity={showActivity}
        />
      )}
    </>
  )
}

export default ExportData

ExportData.defaultProps = {
  riskAnalysisFlag: true,
  showLimitationModal: true,
  dataLength: 1,
  months: 12,
  buttonText: "Export",
  showButton: false,
  total: 1,
  advancedFilters: "",
}
ExportData.propTypes = {
  type: PropTypes.string.isRequired,
  filters: PropTypes.string,
  order_by: PropTypes.string,
  total: PropTypes.number.isRequired,
  actions: PropTypes.string,
  riskAnalysisFlag: PropTypes.bool,
  showLimitationModal: PropTypes.bool,
  dataLength: PropTypes.number,
  months: PropTypes.number,
  showButton: PropTypes.bool,
  es_person_id: PropTypes.string,
  max_rows: PropTypes.number,
  text: PropTypes.string,
  advancedFilters: PropTypes.string,
}
