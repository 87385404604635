import styled from "styled-components"

export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    max-width: 90%;
    min-height: 65%;
    max-height: 95%;
    padding: 20px 15px;
  }

  [class^="Modalstyles__ModalHeader"] {
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    display: block;
  }

  [class^="Modalstyles__ModalContent"] {
    overflow: auto;
    flex-grow: 1;
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }

  li {
    list-style-type: none;
  }
`
