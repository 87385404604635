import { buildQueryString } from "@src/utils/buildQueryString"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"
import fetcher from "@src/services/fetcher"

export function fetchActionPerformance(
  action: string,
  { months, es_person_id }: { months?: number; es_person_id?: string } = {},
): any {
  const params = buildQueryString({
    months,
    es_person_id,
  })
  return fetcher.get(
    `${BASES_REPUTATIONS_API_URL}/organization/actions/${action}/performance?${params}`,
  )
}
