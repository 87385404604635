import styled from "styled-components"

const Shimmer = styled.div`
  background-color: #ebebeb;

  @-webkit-keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    -webkit-animation: shimmer 1s infinite;
    animation: shimmer 1s infinite;
    content: "";
  }
`

export const SkeletonWrapper = styled(Shimmer)`
  align-items: center;
  justify-content: ${({ align }) => align};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadious};
  display: inline-block;
  position: relative;
  overflow: hidden;
`

export const FillSkeletonWrapper = styled(Shimmer)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
