import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import { TIME_PERIOD_MONTHS } from "../../constants"
import { useRequest } from "ahooks"
import { getPolicyStats } from "@src/services/apis/reputation"
import SummaryComponent from "@src/components/SummaryParts"
import { SkeletonLoader } from "@src/utils/skeleton"
import { useTranslation } from "react-i18next"

export const SummaryStatisticsSection = () => {
  const { t } = useTranslation("dashboard")
  const reputations = useSelector((state) => state.get("reputationsReducer"))

  const startDate = `${moment()
    .subtract(TIME_PERIOD_MONTHS[reputations.time_period_value], "month")
    .format("YYYY-MM")}-01`

  const { data, loading } = useRequest(
    () => {
      return getPolicyStats(startDate)
    },
    { refreshDeps: [startDate] },
  )

  const riskResponseData = [
    {
      name: "total_actions",
      events: data?.data?.summary?.total_actions,
    },
  ]

  const dataList = [
    {
      name: t("summaryStatistics.notifications"),
      events: data?.data?.summary?.notifications,
    },
    {
      name: t("summaryStatistics.outboundActions"),
      events: data?.data?.summary?.outbound_actions,
    },
    {
      name: t("summaryStatistics.training"),
      events: data?.data?.summary?.trainings,
    },
  ].filter((item) => typeof item.events === "number")

  dataList.forEach((item) => {
    // Should add filtering depending on which was clicked
    item.onClick = "/engagement/vision2/risk-response-engine/action-logs"
  })

  return loading ? (
    <SkeletonLoader height={100} />
  ) : (
    <SummaryComponent
      riskResponseData={riskResponseData}
      tileData={dataList}
      direction="horizontal"
    />
  )
}
