import {
  SHOW_SIDE_DRAWER,
  CLOSE_SIDE_DRAWER,
  SET_RISKS,
  SET_RISKS_NAMES,
  CLEAR,
  SET_ACTIONS,
  SET_FLAG_ACTIONS,
  SET_DEPARTMENT,
  SET_LOCATION,
  RISK_TABLE_COUNT,
  SET_JOB_TITLE,
  SET_BUSINESS_TITLE,
  SET_BUSINESS_UNIT,
  SET_ES_MANAGER_ID,
  SET_IS_ONLEAVE,
  SET_JOB_FAMILY_FUNCTION_GROUP,
  SET_JOB_FAMILY_FUNCTION_NAME,
  SET_MANAGER_NID,
  SET_PREFERRED_LANGUAGE,
  SET_PREFERRED_NAME,
  SET_PREFERRED_MIDDLE_NAME,
  SET_PREFERRED_NAME_LOCAL_LANG,
  SET_TIME_TYPE,
  SET_WORK_LOCATION_COUNTRY,
  SET_WORK_LOCATION_GEO,
  SET_WORK_LOCATION_TIMEZONE,
  SET_WORKER_TYPE,
  SET_WORK_LOCATION_BUILDING,
  SET_WORK_LOCATION_CITY,
  SET_WORK_LOCATION_NAME,
  SET_WORK_SPACE,
  SET_LENGTH_OF_SERVICE,
  SET_HIRE_DATE,
  SET_IS_ACTIVE,
  SET_LEAVE_DATE,
  SET_ALTERNATIVE_USER_ID_1,
  SET_ALTERNATIVE_USER_ID_2,
  SET_BEHAVIOR_TYPES,
  SET_ADVANCED_FILTERS,
  SET_AVAILABLE_HR_METADATA_KEYS,
} from "./types"

const INITIAL_STATE = {
  tableInfo: "Individuals",
  showSideBar: false,
  risks: [],
  risksNames: [],
  actionsNames: [],
  riskFilters: [],
  systems: [],
  flagActions: undefined,
  actions: [],
  departments: [],
  locations: [],
  riskTableCount: 0,
  workType: [],
  timeType: [],
  businessUnit: [],
  jobFamilyFunctionName: [],
  jobFamilyFunctionGroup: [],
  advanced_filters: null,
}

const analysisIndividualReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SIDE_DRAWER:
      return {
        ...state,
        showSideBar: true,
      }
    case CLOSE_SIDE_DRAWER:
      return {
        ...state,
        showSideBar: false,
      }
    case SET_RISKS:
      return {
        ...state,
        risks: action.risks,
      }
    case RISK_TABLE_COUNT:
      return {
        ...state,
        riskTableCount: action.count,
      }
    case SET_ACTIONS:
      return {
        ...state,
        actions: action.actions,
      }
    case SET_RISKS_NAMES: {
      const equals =
        state.risksNames?.length === action.risksNames?.length &&
        state.risksNames?.every((v, i) => v === action.risksNames[i])
      if (equals) return state
      return {
        ...state,
        risksNames: action.risksNames,
        riskFilters: action.risksFilters,
        actionsNames: action.actionsNames,
      }
    }
    case SET_FLAG_ACTIONS:
      return {
        ...state,
        flagActions: action.actions_list,
      }
    case SET_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.jobTitle,
      }
    case SET_BUSINESS_TITLE:
      return {
        ...state,
        businessTitle: action.businessTitle,
      }
    case SET_BUSINESS_UNIT:
      return {
        ...state,
        businessUnit: action.businessUnit,
      }
    case SET_ES_MANAGER_ID:
      return {
        ...state,
        esManagerId: action.esManagerId,
      }
    case SET_IS_ONLEAVE:
      return {
        ...state,
        isOnleave: action.isOnleave,
      }
    case SET_JOB_FAMILY_FUNCTION_GROUP:
      return {
        ...state,
        jobFamilyFunctionGroup: action.jobFamilyFunctionGroup,
      }
    case SET_JOB_FAMILY_FUNCTION_NAME:
      return {
        ...state,
        jobFamilyFunctionName: action.jobFamilyFunctionName,
      }
    case SET_MANAGER_NID:
      return {
        ...state,
        managerNid: action.managerNid,
      }
    case SET_PREFERRED_LANGUAGE:
      return {
        ...state,
        preferredLanguage: action.preferredLanguage,
      }
    case SET_PREFERRED_NAME:
      return {
        ...state,
        preferredName: action.preferredName,
      }
    case SET_PREFERRED_MIDDLE_NAME:
      return {
        ...state,
        preferredMiddleName: action.preferredMiddleName,
      }
    case SET_PREFERRED_NAME_LOCAL_LANG:
      return {
        ...state,
        preferredNameLocalLang: action.preferredNameLocalLang,
      }
    case SET_TIME_TYPE:
      return {
        ...state,
        timeType: action.timeType,
      }
    case SET_WORK_LOCATION_COUNTRY:
      return {
        ...state,
        workLocationCountry: action.workLocationCountry,
      }
    case SET_WORK_LOCATION_GEO:
      return {
        ...state,
        workLocationGeo: action.workLocationGeo,
      }
    case SET_WORK_LOCATION_TIMEZONE:
      return {
        ...state,
        workLocationTimezone: action.workLocationTimezone,
      }
    case SET_WORKER_TYPE:
      return {
        ...state,
        workerType: action.workerType,
      }
    case SET_WORK_LOCATION_BUILDING:
      return {
        ...state,
        workLocationBuilding: action.workLocationBuilding,
      }
    case SET_WORK_LOCATION_CITY:
      return {
        ...state,
        workLocationCity: action.workLocationCity,
      }
    case SET_WORK_LOCATION_NAME:
      return {
        ...state,
        workLocationName: action.workLocationName,
      }
    case SET_WORK_SPACE:
      return {
        ...state,
        workSpace: action.workSpace,
      }
    case SET_LENGTH_OF_SERVICE:
      return {
        ...state,
        lengthOfService: action.lengthOfService,
      }
    case SET_HIRE_DATE:
      return {
        ...state,
        hireDate: action.hireDate,
      }
    case SET_IS_ACTIVE:
      return {
        ...state,
        isActive: action.isActive,
      }
    case SET_LEAVE_DATE:
      return {
        ...state,
        leaveDate: action.leaveDate,
      }
    case SET_ALTERNATIVE_USER_ID_1:
      return {
        ...state,
        alternativeUserId1: action.alternativeUserId1,
      }
    case SET_ALTERNATIVE_USER_ID_2:
      return {
        ...state,
        alternativeUserId2: action.alternativeUserId2,
      }
    case SET_DEPARTMENT:
      return {
        ...state,
        departments: action.departments,
      }
    case SET_LOCATION:
      return {
        ...state,
        locations: action.locations,
      }
    case SET_ADVANCED_FILTERS:
      return {
        ...state,
        advanced_filters: action.filters,
      }
    case CLEAR:
      return {
        ...state,
        risks: [],
        risksNames: [],
        riskFilters: [],
        systems: [],
      }
    case SET_BEHAVIOR_TYPES:
      return {
        ...state,
        behaviorTypes: action.behaviorTypes,
        userFacingBehaviorTypes: action.userFacingBehaviorTypes,
      }
    case SET_AVAILABLE_HR_METADATA_KEYS:
      return {
        ...state,
        availableHRMetadataKeys: action.availableHRMetadataKeys,
      }
    default:
      return state
  }
}

export default analysisIndividualReducer
