import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "@elevate_security/elevate-component-library"

import { ComboBoxWrapper } from "./styles"
import { getSlackUsers } from "@src/services/apis/slack"
import { useRequest } from "ahooks"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"

const { Text } = Typography
const LIMIT = 100

const SlackUsersComboBox = ({ notification, setSelectedSlackNotification }) => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalUsers, setTotalUsers] = useState(0)
  const slackUsers =
    notification?.slack_users?.map((user) => ({
      ...user,
      label: user.email, // we can alos map user.name
      value: user.slack_id,
    })) || []

  const { data, loading } = useRequest(
    () =>
      getSlackUsers({
        search: `${search}`,
        limit: LIMIT,
        offset: page * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        if (page === 0) {
          setTotalUsers(response?.data?.count || 0)
          return (
            response?.data?.results?.map((user) => ({
              ...user,
              label: `${user?.display_name} (${user?.email})`,
              value: user?.user_slack_id,
            })) || []
          )
        } else {
          return [
            ...data,
            ...response?.data?.results?.map((user) => ({
              ...user,
              label: `${user?.display_name} (${user?.email})`,
              value: user?.user_slack_id,
            })),
          ]
        }
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
  }

  const handleOnChange = (selected) => {
    const slackUsers = selected?.map((item) => ({
      ...item,
      slack_id: item.value,
      name: item.display_name,
      email: item.email,
    }))
    setSelectedSlackNotification({
      ...notification,
      slack_users: slackUsers,
    })
  }

  return (
    <SpaceBetween size="xs">
      <Text color="700" fontWeight="bold">
        Recipient Individual(s)
      </Text>
      <ComboBoxWrapper>
        <ComboBoxWithOverlap
          data={data}
          defaultValue={slackUsers || []}
          value={slackUsers || []}
          onChange={handleOnChange}
          isLoading={loading}
          clearable={false}
          isSearchable={true}
          isMultiOption={true}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{}}
          disabled={false}
          placeholder="Choose or search slack users"
          handleScrollBotttom={() => {
            if (data.length < totalUsers) {
              setPage(page + 1)
            }
          }}
          handleInputChange={(value) => handleSetSearch(value)}
        />
      </ComboBoxWrapper>
    </SpaceBetween>
  )
}

SlackUsersComboBox.propTypes = {
  notification: PropTypes.object,
  setSelectedSlackNotification: PropTypes.func,
}

export default SlackUsersComboBox
