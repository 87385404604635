/* globals __COMMIT_HASH__, APP_ENV */

import React, { useCallback } from "react"
import ReactDOM from "react-dom"
import singleSpaReact from "single-spa-react"
import { BrowserRouter } from "react-router-dom"

import { VisionRoot } from "./vision-root"
import store from "@src/services/redux/store"
import { Provider } from "react-redux"

import IndividualRiskAnalysisContextProvider from "./scenes/SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import { PrettyPrintedR3Criteria } from "./scenes/RiskDetectionRulesScreen/DropdownCard/PrettyPrintedR3Criteria"
import ExternalCriteriaQueryBuilder from "./scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/ExternalCriteriaQueryBuilder"
import { configureElevateAuth } from "./utils/configureElevateAuth"
import { datadogRum } from "@datadog/browser-rum"

if (APP_ENV !== "local") {
  datadogRum.init({
    applicationId: "9e3d9b9a-b9ca-48e9-8abf-76bcd4626d8d",
    clientToken: "pub2335d9444540ce64e585b2b4323ce2c4",
    site: "datadoghq.com",
    service: "ui-vision",
    env: APP_ENV,
    version: __COMMIT_HASH__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    startSessionReplayRecordingManually: true,
  })
}

configureElevateAuth()

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: VisionRoot,
  domElementGetter,
})

export const bootstrap = [reactLifecycles.bootstrap]

export const mount = [
  async ({ logindata }) => {
    const [ORGANIZATION_NID] = window.location.hostname.split(".")
    datadogRum.setGlobalContextProperty("organization", ORGANIZATION_NID)
    datadogRum.setGlobalContextProperty("microfrontend", "ui-vision")
    datadogRum.setGlobalContextProperty("isUiVision", true)
    if (logindata) {
      datadogRum.setUser({
        id: logindata.id,
        name: logindata.full_name,
        email: logindata.email,
      })
    }
  },
  reactLifecycles.mount,
  // eslint-disable-next-line no-console
  () => Promise.resolve(console.log("Mounted vision 2")),
]

export const unmount = [
  reactLifecycles.unmount,
  // eslint-disable-next-line no-console
  async () => Promise.resolve(console.log("Unmounting vision 2")),
  async () => {
    datadogRum.removeGlobalContextProperty("microfrontend")
    datadogRum.removeGlobalContextProperty("isUiVision")
  },
]

export const unload = [reactLifecycles.unload]

function domElementGetter() {
  const ID = "vision"
  let el = document.getElementById(ID)
  if (!el) {
    el = document.createElement("div")
    el.id = ID
    document.body.appendChild(el)
  }
  el.setAttribute("version", __COMMIT_HASH__)
  return el
}

const ExternalQueryBuilderRoot = (props) => {
  // Will be passed to memo'd CriteriaQueryBuilder
  const onQueryRulesChange = useCallback(
    (modifiedQueryRules, isQueryRulesValid) => {
      props.onQueryRulesChange(modifiedQueryRules, isQueryRulesValid)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  return (
    <BrowserRouter>
      <Provider store={store}>
        <IndividualRiskAnalysisContextProvider>
          {props.isEditing ? (
            <ExternalCriteriaQueryBuilder
              defaultQueryRule={props.criteria}
              onQueryRulesChange={onQueryRulesChange}
            />
          ) : (
            <PrettyPrintedR3Criteria criteria={props.queryRule} />
          )}
        </IndividualRiskAnalysisContextProvider>
      </Provider>
    </BrowserRouter>
  )
}

/*
This is exported so it can be used externally

Used by:
- ui-elevateplatform
*/
export const QueryBuilder = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: ExternalQueryBuilderRoot,
})
