import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import {
  ComboBox,
  Typography,
  theme,
} from "@elevate_security/elevate-component-library"
import { useRequest } from "ahooks"
import { ComboBoxWrapper } from "./styles"
import { getIndividualsMatched } from "@src/services/apis/policies"
import { SEND_EMAIL_NOTIFICATION_TO_MANAGERS } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"

const LIMIT = 20

const isActionEmailToManagers = (actionType) => {
  return actionType === SEND_EMAIL_NOTIFICATION_TO_MANAGERS
}

const MatchedIndividualsDropdown = ({
  recipient,
  setRecipient,
  action,
  queryRules,
}) => {
  const comboBoxRef = useRef(null)
  const [search, setSearch] = useState(null)
  const [page, setPage] = useState(1)

  const handleInputSearch = (option, { action }) => {
    if (action === "input-change") {
      if (search === option) return
      setSearch(option)
    }
  }

  const criteria = JSON.stringify(queryRules)

  const { data, loading } = useRequest(
    () =>
      getIndividualsMatched(
        criteria,
        page,
        isActionEmailToManagers(action?.action_type) ? { manager: true } : {},
      ),
    {
      debounceInterval: 500,
      refreshDeps: [criteria, page, action?.action_type],
      formatResult: (response) => {
        const resData = (response?.data?.results || []).map((result) => ({
          label: `${result?.full_name} (${result?.email})`,
          value: result?.es_person_id,
          ...(result?.individual_ids && {
            individual_ids: result?.individual_ids,
          }),
        }))

        if (page === 1) {
          return resData
        }

        return [...data, ...resData]
      },
    },
  )

  const { H5 } = Typography

  return (
    <div>
      <H5
        fontWeight="bold"
        fonSize="md"
        style={{ color: theme.text.color[700], marginBottom: 7 }}
      >
        Select Recipient
      </H5>
      <ComboBoxWrapper ref={comboBoxRef}>
        <ComboBox
          data={data}
          defaultValue={{}}
          value={recipient}
          closeMenuOnSelect={true}
          onChange={(selected) => {
            setRecipient(selected)
          }}
          isLoading={loading}
          clearable={false}
          isSearchable={true}
          isMultiOption={false}
          hideSelectedOptions={false}
          disabled={data?.length === 0}
          components={{}}
          placeholder={
            data?.length === 0
              ? "No matched individuals for criteria"
              : "Choose someone"
          }
          handleScrollBotttom={() => {
            if (data?.length === page * LIMIT) {
              setPage(page + 1)
            }
          }}
          onInputChange={handleInputSearch}
        />
      </ComboBoxWrapper>
    </div>
  )
}

MatchedIndividualsDropdown.propTypes = {
  recipient: PropTypes.string.isRequired,
  setRecipient: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  queryRules: PropTypes.object.isRequired,
}

export default MatchedIndividualsDropdown
