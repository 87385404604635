import styled from "styled-components"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"

const { Text } = Typography

export const SummaryLineWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 10px;
  display: flex;
  svg {
    min-width: 24px;
    margin-top: 4px;
  }
`

export const SummaryLine = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  color: #34383c;
  b {
    font-weight: bold;
  }
`

export const Caret = styled.div`
  margin-left: 39px;
  span {
    cursor: pointer;
    a {
      color: #0c7d84;
      text-decoration: underline;
    }
    svg {
      margin-left: 4px;
    }
  }
`

export const SummaryTableWrapper = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  width: ${(props) => props.width || "100%"};
  margin-left: 19px;
  margin-top: 24px;
  [class^="Tablestyles__TableHeader"] {
    display: none;
  }
  [class^="Tablestyles__TableBody"] {
    overflow: hidden;
    padding-right: 0;
    border-bottom: 1px solid #dde1e7;
    [class^="Tablestyles__TableRow"] {
      padding: 0;
      border: none;
    }
  }
  [class^="Tablestyles__TablePagination"] {
    [class^="CustomSelectstyles__CustomSelectContainer"] {
      display: none;
    }
  }
`

export const IndividualRow = styled.div`
  font-fize: 14px;
  line-height: 24px;
  margin-bottom: 8px;
  a {
    color #0C7D84;
    text-decoration: underline;
  }
`
