import React from "react"
import PropTypes from "prop-types"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { ButtonLink, Icon } from "@elevate_security/elevate-component-library"
import { IconWrapper, TextWrapper } from "@src/components/IconLink/style"
import { THEME_HOVER } from "@src/theme"

const IconLink = ({
  text,
  onClick,
  iconName,
  iconSize,
  iconFill,
  fontWeight,
  disabled,
  showActivity,
}) => {
  const fillColor = disabled ? "#AECCCE" : iconFill
  const iconProps = {
    name: iconName,
    size: iconSize,
    fill: fillColor,
  }
  return (
    <ButtonLink
      style={{
        cursor: disabled ? "not-allowed" : "",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={(e) => (onClick && !disabled ? onClick(e) : null)}
    >
      {iconName && (
        <IconWrapper iconFill={fillColor}>
          <ActivityIndicator active={showActivity} />
          {!showActivity && <Icon {...iconProps} />}
        </IconWrapper>
      )}
      <TextWrapper fontWeight={fontWeight} disabled={disabled}>
        {text}
      </TextWrapper>
    </ButtonLink>
  )
}

IconLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  iconFill: PropTypes.string,
  fontWeight: PropTypes.string,
  disabled: PropTypes.bool,
  showActivity: PropTypes.bool,
}

IconLink.defaultProps = {
  onClick: () => null,
  iconName: null,
  iconSize: "default",
  iconFill: THEME_HOVER,
  fontWeight: "normal",
  disabled: false,
  showActivity: false,
}

export default IconLink
