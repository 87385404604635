import styled from "styled-components"

export const StragglerStatusBarWrapper = styled.div`
  padding: 15px 0px 0px 0px;
  h3 {
    line-height: 24px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  h1 {
    font-size: 44px;
    margin-bottom: 0 !important;
  }
  h3 {
    margin: 0;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: initial;
  align-items: baseline;
`
