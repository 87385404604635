import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  setTimePeriodValue,
  setTrendLoader,
} from "@src/services/redux/reputations/actions"
import { TIME_PERIOD_MONTHS } from "@src/scenes/HumanRiskDashboard/constants"
import TimePeriod from "@src/scenes/HumanRiskDashboard/components/TimePeriod/TimePeriod"
import TrendingOverTimeGraphCommon from "@src/components/TrendingOverTimeGraphCommon"
import {
  getIndividualHistoricalRisksScore,
  getPolicyStats,
} from "@src/services/apis/reputation"

export function TimePeriodSelect() {
  const dispatch = useDispatch()

  const reputations = useSelector((state) => state.get("reputationsReducer"))

  return (
    <TimePeriod
      initialValue={reputations.time_period_value}
      timePeriodHandler={(selected) => {
        dispatch(setTimePeriodValue(selected.value))
        dispatch(setTrendLoader())
      }}
    />
  )
}

export function HumanRiskScoreTrendGraphIRP() {
  const [loading, setLoading] = useState(false)
  const [riskScoreData, setRiskScoreData] = useState([])
  const [policyStatsData, setPolicyStatsData] = useState([])
  const { profileId } = useParams()
  const reputations = useSelector((state) => state.get("reputationsReducer"))

  useEffect(() => {
    const getIndividualRiskProfileData = async () => {
      setLoading(true)
      const startDate = `${moment()
        .subtract(TIME_PERIOD_MONTHS[reputations.time_period_value], "month")
        .format("YYYY-MM")}-01`

      const [{ data }, risk_data] = await Promise.all([
        getPolicyStats(startDate, profileId),
        getIndividualHistoricalRisksScore({
          person_nid: profileId,
          start_date: startDate,
        }),
      ])

      const risk_data_mapped = risk_data.data.overall.map((item) => {
        item.score_last_month = item.previous_score
        delete item.previous_score
        return item
      })

      setRiskScoreData({ historical_risks: risk_data_mapped })
      setPolicyStatsData(data?.data)
    }

    getIndividualRiskProfileData().finally(() => setLoading(false))
  }, [reputations.time_period_value, profileId])

  return (
    <TrendingOverTimeGraphCommon
      lineData={riskScoreData}
      barData={policyStatsData}
      loader={loading}
      xAxisLabel="Human Risk Score"
      yAxisLabel="Risk Responses"
      xAxisColor="#2064b5"
      yAxisColor="#AEAEAE"
    />
  )
}
