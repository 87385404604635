import React from "react"
import { Badge } from "@elevate_security/elevate-component-library"

import { VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH } from "@src/theme"

const themes = [VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH]
const namedScores = ["Very low", "Low", "Medium", "High", "Very high"]

function mapScoreToIndex(score: number, buckets: number = 5): number {
  const MAX_SCORE = 10
  return Math.min(Math.floor(score / (MAX_SCORE / buckets)), buckets - 1)
}

type Props = {
  score: number
  shape: "smallboldround" | "largeboldround"
  text: string
}

export function WordScoreBadge(props: Omit<Props, "text">) {
  return (
    <ScoreBadge {...props} text={namedScores[mapScoreToIndex(props.score)]} />
  )
}

export function ScoreBadge({ score, shape = "smallboldround", text }: Props) {
  return (
    <Badge text={text} shape={shape} theme={themes[mapScoreToIndex(score)]} />
  )
}
