import { theme } from "@elevate_security/elevate-component-library"

// Set to Mimecast for now, using this file as a facade so we can easily change
// or configure as necessary across ui-vision.
export const THEME_PRIMARY = theme.button.backgroundColor.mimecastCgDefault
export const THEME_HOVER = theme.button.hover.backgroundColor.mimecastCgDefault

export const VERY_HIGH = "veryhigh"
export const HIGH = "high"
export const MEDIUM = "medium"
export const LOW = "low"
export const VERY_LOW = "verylow"
