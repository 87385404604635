import styled from "styled-components"

export const BehaviorsBreakdownChartWrapper = styled.div`
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => padding || "12px 0"};
  align-items: center;
`

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => height || "16px"};
  background-color: #eef0f3;
  border-radius: 2px;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    width: ${({ value = 0 }) => `${value}%`};
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ bgColor }) => bgColor || "#0c7d84"};
  }
`

export const LabelBar = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  text-align: right;
  min-width: 142px;
  font-size: 14px;
  color: #565d66;
`

export const ScoreValue = styled.strong`
  font-size: 14px;
  color: #34383c;
  padding: 0 16px 0 8px;
  min-width: 50px;
`
export const ScoreIcon = styled.div`
  min-width: 20px;
`
export const ScoreTrend = styled.strong`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 13px;
  min-width: 24px;
  text-align: center;
`
