import {
  addToastAction,
  removeToastAction,
  removeAllToasts,
} from "./actionsCreator"

export const showToast =
  (message, level = "success", children = null) =>
  (dispatch) => {
    const toast = {
      id: Math.random(),
      message,
      level,
      children,
    }
    dispatch(addToastAction(toast))
    setTimeout(() => {
      hideToast(toast?.id)(dispatch)
    }, 5000)
  }

export const hideToast = (id) => (dispatch) => {
  dispatch(removeToastAction(id))
}

export const hideAllToasts = () => (dispatch) => {
  dispatch(removeAllToasts())
}
