import React, { useState } from "react"

import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { Link, useParams } from "react-router-dom"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { useWatchlistIndividuals as _useWatchlistIndividuals } from "./useWatchlistIndividuals"
import { useWatchlist as _useWatchlist } from "./useWatchlist"
import { useTranslation } from "react-i18next"

export function WatchlistIndividualsScreen() {
  const { watchlistId } = useParams<{ watchlistId: string }>()
  return <WatchlistIndividualsScreenContent watchlistId={watchlistId} />
}

export function WatchlistIndividualsScreenContent({
  useWatchlist = _useWatchlist,
  useWatchlistIndividuals = _useWatchlistIndividuals,
  watchlistId,
}: {
  useWatchlist?: typeof _useWatchlist
  useWatchlistIndividuals?: typeof _useWatchlistIndividuals
  watchlistId: string
}) {
  const { t } = useTranslation("watchlists")

  const [pagination, setPagination] = useState({ pageSize: 25, current: 1 })

  const {
    watchlist,
    loading: loadingWatchlist,
    error: watchlistError,
  } = useWatchlist({ watchlistId })

  const {
    individuals,
    loading: loadingIndividuals,
    error: individualsError,
  } = useWatchlistIndividuals({
    watchlistId,
    pagination,
  })

  const columns = [
    {
      key: "name",
      header: t("tableHeaders.name"),
      render: (
        _: undefined,
        {
          first_name,
          last_name,
          mc_person_id,
        }: {
          first_name: string
          last_name: string
          mc_person_id: string
        },
      ) => {
        return (
          <Link to={`/engagement/vision2/${mc_person_id}/profile`}>
            {first_name} {last_name}
          </Link>
        )
      },
    },
    {
      key: "email",
      header: t("tableHeaders.email"),
    },
  ]

  return (
    <div>
      {(loadingWatchlist || loadingIndividuals) && <ActivityIndicator active />}
      <MimecastCgBreadcrumbs
        breadcrumbs={[
          "risk_response_engine",
          "watchlists",
          {
            label: "People",
            url: `/admin/engagement/vision2/risk-response-engine/watchlists/${watchlistId}/individuals`,
          },
        ]}
      />
      <TableWithFiltersScreen
        title={`${t("watchlistText")}: ${watchlist?.name ? `"${t(`predefined.${watchlist.watchlist_nid}.name`)}"` : t("loadingText")}`}
        subtitle={`Total people: ${individuals?.total_elements ?? t("loadingText")}`}
        appliedFiltersCount={0}
        searchValue=""
        onSearch={() => {}}
        clearSearch={() => {}}
        entries={individuals?.results ?? []}
        totalCount={individuals?.total_elements ?? 0}
        loading={!watchlist || !individuals}
        pagination={{
          ...pagination,
          onChange: (current: number, pageSize: number) => {
            setPagination({ current, pageSize })
          },
        }}
        columns={columns}
        onSortedChange={() => {}}
        showSearch={false}
        excludeSortColumn={columns.map((column) => column.key)}
        emptyMessage={t("emptyPeopleMessage")}
        errorMessage={
          (watchlistError && t("singularErrorMessage")) ||
          (individualsError && t("peopleErrorMessage"))
        }
      >
        {null}
      </TableWithFiltersScreen>
    </div>
  )
}
