import useRiskAnalysisURLParam from "../../../useRiskAnalysisURLParam"
import {
  FILTER_KEY,
  HR_DATA_FILTER_KEY,
} from "@src/scenes/IndividualRiskAnalysis/components/Filters/utils"
import { useIndividualRiskAnalysisState } from "@src/scenes/SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import {
  actionKeyLabelMap,
  LIST_FILTERS,
} from "@src/scenes/SecEngIndividualRiskAnalysis/constants"

const generateListForFilterType = (el, filter_type) => {
  return el.reduce((acc, cur) => {
    acc[cur] = filter_type
    return acc
  }, {})
}

export default function FiltersUtils(
  riskPrefix,
  { riskTypeOverride = null } = {},
) {
  const { risksNames, actionsNames } = useIndividualRiskAnalysisState()
  const { filters, updateUrlWithNewFilters } = useRiskAnalysisURLParam({
    riskTypeOverride,
  })

  const AND_CONSTANT = " AND "
  const IN_CONSTANT = " IN "
  const IN_SEPARATOR = ","
  const SEARCH_CONSTANT = "searches"
  const RISK_PREFIX = riskPrefix
  const HUMAN_RISK_MAP = { label: "human_risk_score", key: "human_risk_score" }
  const HUMAN_RISK_SCORE = { overall: "human_risk_score" }
  const FILTER_TYPES = {
    IN: "in",
    RISK: "risk",
    BOOLEAN: "boolean",
    ACTION: "actions",
    SEARCH: "search",
  }
  const FILTER_MAP = {
    [FILTER_KEY.DEPARTMENT]: FILTER_TYPES.IN,
    [FILTER_KEY.EMPLOYMENT_TYPE]: FILTER_TYPES.IN,
    [FILTER_KEY.ORGANIZATIONAL_ROLE]: FILTER_TYPES.IN,
    [FILTER_KEY.TENURE_STATUS]: FILTER_TYPES.IN,
    [FILTER_KEY.LOCATION]: FILTER_TYPES.IN,
    [FILTER_KEY.ATTACK_FACTOR]: FILTER_TYPES.IN,
    [FILTER_KEY.ACCESS_FACTOR]: FILTER_TYPES.IN,
    [FILTER_KEY.ACTION_FACTOR]: FILTER_TYPES.IN,
    [FILTER_KEY.SEARCH]: FILTER_TYPES.SEARCH,
    [FILTER_KEY.TENURE_DURATION]: FILTER_TYPES.ACTION,
    [FILTER_KEY.BEHAVIOR_TYPE]: FILTER_TYPES.IN,
    [FILTER_KEY.DETECTION_TYPE]: FILTER_TYPES.IN,
    [FILTER_KEY.APPLICATION]: FILTER_TYPES.IN,
    [FILTER_KEY.SUSPICIOUS_COUNTRY]: FILTER_TYPES.IN,
    [FILTER_KEY.EXPECTED_COUNTRY]: FILTER_TYPES.IN,
    [FILTER_KEY.STATUS]: FILTER_TYPES.IN,
    [FILTER_KEY.AUTHENTICATION_FACTOR_TYPE]: FILTER_TYPES.IN,
    [HUMAN_RISK_MAP["label"]]: FILTER_TYPES.RISK,
    [HUMAN_RISK_MAP["key"]]: FILTER_TYPES.RISK,
    ...generateListForFilterType(risksNames, FILTER_TYPES.RISK),
    ...generateListForFilterType(actionsNames, FILTER_TYPES.ACTION),
    ...generateListForFilterType(
      Object.values(HR_DATA_FILTER_KEY),
      FILTER_TYPES.IN,
    ),
  }

  const splitFilters = (filter_key) => {
    let filtersArray = []

    if (filters) {
      if (filters.length > 1) {
        filtersArray = filters.split(AND_CONSTANT)
      } else {
        filtersArray = [filters]
      }
    }

    // if I'm adding a department or location key, which uses the IN
    // I don't want to remove the original string because I need to add to it
    if (FILTER_MAP[filter_key] !== FILTER_TYPES.IN) {
      filtersArray = filtersArray.filter(
        (string) => !string.includes(filter_key),
      )
    }

    // Remove filter added by search

    filtersArray = filtersArray.filter(
      (string) => !(string.includes(filter_key) && string.includes("ILIKE")),
    )

    return filtersArray
  }

  const joinFiltersAndUpdateURL = (filtersArray) => {
    const newFilters = filtersArray.join(AND_CONSTANT)
    updateUrlWithNewFilters(newFilters)
  }

  const pushRiskFilter = (filter) => {
    if (filter.key === HUMAN_RISK_MAP["label"]) {
      filter.key = HUMAN_RISK_MAP["key"]
    }

    const filtersArray = splitFilters(filter.key)

    const [min, max] = filter.value.split("-")

    filtersArray.push(
      `${filter.key} GE ${min}${AND_CONSTANT}${filter.key} LE ${max}`,
    )

    return filtersArray
  }

  const pushActionFilter = (filter) => {
    const filtersArray = splitFilters(filter.key)

    const [min, max] = filter.value.split("-")

    filtersArray.push(
      `${filter.key} GE ${min}${AND_CONSTANT}${filter.key} LE ${max}`,
    )

    return filtersArray
  }

  const pushBooleanFilter = (filter) => {
    const filtersArray = splitFilters(filter.key)

    filtersArray.push(`${filter.key} EQ ${filter.value}`)

    return filtersArray
  }

  const addListFilter = (filters, filterValue) => {
    let [filter, value] = filters.split(IN_CONSTANT)
    value = JSON.parse(value)
    value.push(filterValue)
    return `${filter}${IN_CONSTANT}${JSON.stringify(value)}`
  }

  const pushInFilter = (filter) => {
    const filtersArray = splitFilters(filter.key)

    const foundIdx = filtersArray.findIndex((element) =>
      element.startsWith(filter.key),
    )
    let appliedFilters = filtersArray.filter((string) =>
      string.includes(filter.key),
    )

    if (appliedFilters.length !== 0) {
      appliedFilters = appliedFilters[0]
      appliedFilters = appliedFilters
        .slice(appliedFilters.indexOf("IN") + 3)
        .split(",")
      const isApplied = appliedFilters.filter((str) => str === filter.value)
      if (isApplied.length !== 0) {
        // Filter is already applied no need to apply again
        // return filtersArray
        return filtersArray
      }
    }

    const isListFilter = LIST_FILTERS.includes(filter.key)
    if (foundIdx === -1) {
      const filterValue = isListFilter
        ? JSON.stringify([filter.value])
        : filter.value
      filtersArray.push(`${filter.key}${IN_CONSTANT}${filterValue}`)
    } else {
      const filterValue = isListFilter
        ? addListFilter(filtersArray[foundIdx], filter.value)
        : `${filtersArray[foundIdx]}${IN_SEPARATOR}${filter.value}`
      filtersArray[foundIdx] = filterValue
    }

    return filtersArray
  }

  const pushSearchbarFilter = (filter) => {
    const filtersArray = splitFilters(filter.key)

    const foundIdx = filtersArray.findIndex((element) =>
      element.startsWith("searchbar"),
    )

    if (foundIdx === -1) {
      // Search is not included in filters, We need to push
      filtersArray.push(`searchbar ${SEARCH_CONSTANT} ${filter.value}`)
    } else {
      // Search is already in filters, we need to update value
      filtersArray[foundIdx] = `searchbar ${SEARCH_CONSTANT} ${filter.value}`
    }

    return filtersArray
  }

  const ADD_FUNCTION_FOR_FILTER_TYPE = {
    [FILTER_TYPES.IN]: pushInFilter,
    [FILTER_TYPES.RISK]: pushRiskFilter,
    [FILTER_TYPES.BOOLEAN]: pushBooleanFilter,
    [FILTER_TYPES.ACTION]: pushActionFilter,
    [FILTER_TYPES.SEARCH]: pushSearchbarFilter,
  }

  const addFilter = (filter) => {
    const filterKey = actionKeyLabelMap[filter.key] || filter.key
    const typeOfFilter = FILTER_MAP[filterKey]

    const fn = ADD_FUNCTION_FOR_FILTER_TYPE[typeOfFilter]

    const filtersArray = fn(filter)

    joinFiltersAndUpdateURL(filtersArray)
  }

  const removeRiskOrBooleanFilter = (filter) => {
    return splitFilters(filter.key)
  }

  const removeListFilter = (filterValues, valueToRemove) => {
    const values = JSON.parse(filterValues).filter((el) => el !== valueToRemove)
    return values.length ? JSON.stringify(values) : ""
  }
  const removeValueFromINClause = (filterClause, valueToRemove) => {
    const [filterKey, filterValues] = filterClause.split(IN_CONSTANT)

    const isListFilter = LIST_FILTERS.includes(filterKey)
    const newFilterValues = isListFilter
      ? removeListFilter(filterValues, valueToRemove)
      : filterValues
          .split(IN_SEPARATOR)
          .filter((el) => el !== valueToRemove)
          .join(IN_SEPARATOR)

    return newFilterValues ? `${filterKey}${IN_CONSTANT}${newFilterValues}` : ""
  }

  const removeInFilter = (filter) => {
    const filtersArray = splitFilters(filter.key)

    let inFilters =
      filtersArray.filter((_filter) => _filter.includes(IN_CONSTANT)) || []

    const otherFilters =
      filtersArray.filter((_filter) => !_filter.includes(IN_CONSTANT)) || []

    const desiredInFilterIdx = inFilters.findIndex((_filter) =>
      _filter.startsWith(filter.key),
    )

    if (desiredInFilterIdx !== -1) {
      inFilters[desiredInFilterIdx] = removeValueFromINClause(
        inFilters[desiredInFilterIdx],
        filter.value,
      )
    }

    inFilters = inFilters.filter((x) => x) || []

    return inFilters.concat(otherFilters)
  }

  const removeSearchbarFilter = (filter) => {
    const filtersArray = splitFilters(filter.key)

    const foundIdx = filtersArray.findIndex((element) =>
      element.startsWith("searchbar"),
    )

    if (foundIdx > -1) {
      // Search is not included in filters, We need to push
      filtersArray.splice(foundIdx, 1)
    }

    return filtersArray
  }

  const REMOVE_FUNCTION_FOR_FILTER_TYPE = {
    [FILTER_TYPES.IN]: removeInFilter,
    [FILTER_TYPES.RISK]: removeRiskOrBooleanFilter,
    [FILTER_TYPES.BOOLEAN]: removeRiskOrBooleanFilter,
    [FILTER_TYPES.ACTION]: removeRiskOrBooleanFilter,
    [FILTER_TYPES.SEARCH]: removeSearchbarFilter,
  }

  const removeFilter = (filter) => {
    //we set the boolean as default because it will use the more generic function
    const typeOfFilter = FILTER_MAP[filter.key] || FILTER_TYPES.BOOLEAN
    const fn = REMOVE_FUNCTION_FOR_FILTER_TYPE[typeOfFilter]

    const filtersArray = fn(filter)

    joinFiltersAndUpdateURL(filtersArray)
  }

  return {
    AND_CONSTANT,
    IN_CONSTANT,
    IN_SEPARATOR,
    RISK_PREFIX,
    HUMAN_RISK_MAP,
    HUMAN_RISK_SCORE,
    ACTION_FILTERS: Object.entries(FILTER_MAP)
      .filter(([, type]) => type === FILTER_TYPES.ACTION)
      .map(([key]) => key),
    addFilter,
    removeFilter,
  }
}
