import {
  SET_TREND_SUMMARY_CAMPAIGNS,
  SET_TREND_SUMMARY_HISTORICAL_SCORES,
} from "./types"

const INITIAL_STATE = {
  trendSummaryCampaigns: {
    data: null,
    isLoaded: false,
  },
  trendSummaryHistoricalScores: {
    data: null,
    isLoaded: false,
  },
}

export default function trendSummaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TREND_SUMMARY_CAMPAIGNS:
      return {
        ...state,
        trendSummaryCampaigns: {
          ...state.trendSummaryCampaigns,
          ...action.data,
        },
      }
    case SET_TREND_SUMMARY_HISTORICAL_SCORES:
      return {
        ...state,
        trendSummaryHistoricalScores: {
          ...state.trendSummaryHistoricalScores,
          ...action.data,
        },
      }
    default:
      return state
  }
}
