import { findSetNestedData } from "../../scenes/Session/ManagerDashboardV2/utils"
import {
  SET_ORG_SCORE,
  SET_TEAM_DATA,
  SET_TEAM_SCORES_MONTHLY,
  SET_MANAGER_INFO,
  SET_TEAM_SCORES_BY_INDIVIDUALS,
  SET_TOP_PERFORMERS_COUNT,
  SET_MANAGER_OVERVIEW,
  SET_MANAGER_SCORES,
  SET_TEAM_TRENDS,
  SET_MANAGER_TEAM_DATA,
  SET_MANAGER_TEAM_DATA_LOADER,
  SET_CHILD_MANAGER_TEAM_DATA,
  SET_INITIAL_VALUES_MANGER,
  REMOVE_CHILD_MANAGER_TEAM_DATA,
  SET_CHILD_LOADING,
} from "./types"

const INITIAL_STATE = {
  managerInfo: null,
  orgScores: {
    data: null,
    isLoaded: false,
  },
  teamData: {
    data: null,
    isLoaded: false,
  },
  managerScores: {
    data: {},
    isLoaded: true,
  },
  managerTeamTrends: {
    data: {},
    isLoaded: true,
  },
  managerOverview: {
    data: {
      team_human_risk_score: 0.0,
      recommendations: [],
    },
    isLoaded: true,
  },
  teamScoresMonthly: {
    data: [],
    isLoaded: false,
  },
  teamScoresByIndividuals: {
    data: null,
    isLoaded: false,
  },
  managerTeamData: [],
  totalTeamMembers: 0,
  managerTeamLoader: true,
  tablePersonInfo: [],
  topPerformersCount: 0,
}

export default function managerDashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MANAGER_INFO:
      return {
        ...state,
        managerInfo: { ...action.data },
      }
    case SET_ORG_SCORE:
      return {
        ...state,
        orgScores: {
          ...state.orgScores,
          ...action.data,
        },
      }
    case SET_TEAM_DATA:
      return {
        ...state,
        teamData: {
          ...state.teamData,
          ...action.data,
        },
      }
    case SET_MANAGER_OVERVIEW:
      return {
        ...state,
        managerOverview: {
          ...state.managerOverview,
          ...action.data,
        },
      }
    case SET_MANAGER_SCORES:
      return {
        ...state,
        managerScores: {
          ...state.managerScores,
          ...action.data,
        },
      }
    case SET_TEAM_TRENDS:
      return {
        ...state,
        managerTeamTrends: {
          ...state.managerTeamTrends,
          ...action.data,
        },
      }
    case SET_TEAM_SCORES_MONTHLY:
      return {
        ...state,
        teamScoresMonthly: {
          ...state.teamScoresMonthly,
          ...action.data,
        },
      }
    case SET_MANAGER_TEAM_DATA_LOADER:
      return {
        ...state,
        managerTeamLoader: action.data,
      }
    case SET_CHILD_LOADING: {
      let updatedList = [...state.managerTeamData]
      updatedList = findSetNestedData(
        state.managerTeamData,
        "es_person_id",
        action.data?.es_person_id,
        [],
        { isLoading: true },
      )
      return {
        ...state,
        managerTeamData: updatedList,
      }
    }
    case REMOVE_CHILD_MANAGER_TEAM_DATA: {
      let updatedList = state.managerTeamData
      if (action.data?.es_person_id) {
        updatedList = findSetNestedData(
          state.managerTeamData,
          "es_person_id",
          action.data?.es_person_id,
          [],
          { isLoading: false },
        )
      }
      return {
        ...state,
        managerTeamData: updatedList,
      }
    }
    case SET_MANAGER_TEAM_DATA: {
      let updatedList = []
      if (action.data?.isChild) {
        updatedList = findSetNestedData(
          state.managerTeamData,
          "es_person_id",
          action.data?.person_nid,
          action.data?.teamMembers,
          { isLoading: false },
        )
      }
      return {
        ...state,
        managerTeamData: action.data?.isChild
          ? [...updatedList]
          : [...(action.data?.teamMembers || [])],
        totalTeamMembers:
          action.data?.totalTeamMembers ?? state.totalTeamMembers,
        managerTeamLoader: false,
      }
    }
    case SET_CHILD_MANAGER_TEAM_DATA: {
      let list = [...state.tablePersonInfo, { ...action.data }]
      if (action.data?.remove_person_id) {
        list = list.filter(
          (item) => item.es_person_id !== action.data.remove_person_id,
        )
      }
      return {
        ...state,
        tablePersonInfo: list,
      }
    }
    case SET_TEAM_SCORES_BY_INDIVIDUALS:
      return {
        ...state,
        teamScoresByIndividuals: {
          ...state.teamScoresByIndividuals,
          ...action.data,
        },
      }
    case SET_TOP_PERFORMERS_COUNT:
      return {
        ...state,
        topPerformersCount: action.data,
      }
    case SET_INITIAL_VALUES_MANGER:
      return {
        ...state,
        managerTeamData: [],
        totalTeamMembers: 0,
        managerTeamLoader: true,
        tablePersonInfo: [],
      }
    default:
      return state
  }
}
