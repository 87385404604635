/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useCallback } from "react"
import Checkbox from "@src/components/Checkbox"
import styled from "styled-components"
import { humanize } from "@src/utils/string"
import { Icon } from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"

const ColumnList = styled.ul`
  border-radius: 4px;
  border: 1px solid #c9cfd6;
  position: absolute;
  z-index: 99999;
  background-color: white;
  overflow: auto;
  max-height: 390px;
  box-shadow: 0px 2px 8px 0px #b1b8c1;
  min-width: 230px;
  right: 0px;
`

const ColumnItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 0px 8px;
  background-color: white;
  color: ${(props) => (props.disabled ? "white" : "none")};
  input[type="checkbox"]:disabled:checked {
    background-color: #dde1e7;
    border-color: #dde1e7;
  }
  label {
    color: #565d66;
  }
`

const ColumnCheckbox = styled.div`
  display: flex;
  align-items: center;
`

const CustomizeButtonWrapper = styled.div`
  svg {
    margin-left: 4px;
  }
`

// ahooks useClickAway not working for this case, so we write our own
const useClickAway = (handler, ref) => {
  useEffect(() => {
    const onClickAway = (e) => {
      if (!ref.current?.contains(e.target)) {
        handler()
      }
    }
    document.addEventListener("click", onClickAway)
    return () => {
      document.removeEventListener("click", onClickAway)
    }
  }, [handler, ref])
}

function ColumnsChooser({
  isSelected,
  toggle,
  columns,
  onToggleTruncate = () => {},
}) {
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef()

  const closeMenu = useCallback(() => {
    if (showMenu) {
      setShowMenu(false)
    }
  }, [showMenu])

  useClickAway(closeMenu, menuRef)

  return (
    <div style={{ position: "relative" }}>
      <CustomizeButtonWrapper>
        <Button
          theme="ghost"
          onClick={() => {
            setShowMenu((prev) => !prev)
          }}
        >
          <span>Customize Columns</span>
          <Icon name="CaretDown" size="xxsm" fill="#959DA8" />
        </Button>
      </CustomizeButtonWrapper>
      {showMenu && (
        <ColumnList ref={menuRef}>
          <ColumnItem>
            <ColumnCheckbox>
              <Checkbox
                checked={!localStorage.getItem("RAP_allowCellWrap")}
                onChange={(checked) => {
                  if (checked) {
                    localStorage.removeItem("RAP_allowCellWrap")
                  } else {
                    localStorage.setItem("RAP_allowCellWrap", "yes")
                  }
                  onToggleTruncate(checked)
                }}
                label="Truncate long column values"
                id="truncate"
              />
            </ColumnCheckbox>
          </ColumnItem>
          <hr />
          {columns
            .filter((column) => !column.hidden)
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((column) => {
              return (
                <ColumnItem key={column.key} disabled={column.disabled}>
                  <ColumnCheckbox>
                    <Checkbox
                      checked={isSelected(column.key)}
                      disabled={column.disabled}
                      onChange={() => {
                        toggle(column.key)
                      }}
                      label={humanize(column.label)}
                      id={column.key}
                    />
                  </ColumnCheckbox>
                </ColumnItem>
              )
            })}
        </ColumnList>
      )}
    </div>
  )
}

export const MemoizedColumnsChooser = React.memo(ColumnsChooser)

export default MemoizedColumnsChooser
