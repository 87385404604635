// libaries
import React, { memo, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
  Typography,
  Icon,
  theme,
} from "@elevate_security/elevate-component-library"

// styles
import {
  DropdownWrapper,
  DropdownItem,
  DropdownItemContent,
  DropdownTitleWrapper,
  DropdownButton,
  Column,
} from "./styles"

// constants
const { H2 } = Typography
function DropdownContent(props) {
  const ref = useRef(null)
  const {
    items,
    activeBreakdownLevel,
    contentIndex,
    setCardIndex = () => null,
    cardsHeight = {},
    setCardsHeight = () => null,
  } = props
  const [openItems, setOpenItems] = useState([true, true, true])

  useEffect(() => {
    if (openItems[1] === false) {
      const secondTab = [...openItems]
      secondTab[1] = true
      setOpenItems(secondTab)
    }
  }, [activeBreakdownLevel])

  useEffect(() => {
    setCardsHeight({
      ...cardsHeight,
      [`index_${contentIndex}`]: ref?.current?.clientHeight,
    })
  }, [ref?.current?.clientHeight])

  function handleOnToggleItem({ index, event }) {
    event.preventDefault()
    event.stopPropagation()
    const checkOpenItems = [...openItems]
    checkOpenItems[index] = !openItems[index]
    setOpenItems(checkOpenItems)
    setCardIndex(contentIndex === 0 ? 1 : 0)
  }

  function renderTitle(title) {
    if (typeof title === "string") {
      return (
        <H2
          fontWeight="bold"
          fonSize="md"
          style={{ color: theme.text.color[700], marginBottom: 7 }}
        >
          {title}
        </H2>
      )
    }
    return title
  }

  function renderItems() {
    return items.map(({ title, content }, index) => (
      <DropdownItem key={index}>
        <DropdownTitleWrapper
          onClick={(event) => handleOnToggleItem({ index, event })}
          data-toggle-wrapper={index}
        >
          <Column>{renderTitle(title)}</Column>
          <Column>
            <DropdownButton
              onClick={(event) => handleOnToggleItem({ index, event })}
              isOpen={openItems[index]}
              data-toggle-button={index}
            >
              <Icon name="CaretUp" fill={theme.colors.gray.opaque[500]} />
            </DropdownButton>
          </Column>
        </DropdownTitleWrapper>
        <DropdownItemContent isOpen={openItems[index]}>
          {content}
        </DropdownItemContent>
      </DropdownItem>
    ))
  }
  return <DropdownWrapper ref={ref}>{renderItems()}</DropdownWrapper>
}

DropdownContent.propTypes = {
  activeBreakdownLevel: PropTypes.bool,
  contentIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  showCardIndex: PropTypes.number,
  setCardIndex: PropTypes.func,
  cardsHeight: PropTypes.object,
  setCardsHeight: PropTypes.func,
}

DropdownContent.defaultProps = {
  contentIndex: 0,
}
export default memo(DropdownContent)
