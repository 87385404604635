import styled from "styled-components"

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ""}
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  [class^="Togglestyles__Content"] {
    background-color: #959da8;
  }
  span {
    margin-top: 2px;
  }
`

export const ToggleWrapperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #565d66;
  margin-left: 8px;
`
