import React from "react"

function LightBulb() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9796 2C12.5319 2 12.9796 2.44772 12.9796 3V4.63265C12.9796 5.18494 12.5319 5.63265 11.9796 5.63265C11.4273 5.63265 10.9796 5.18494 10.9796 4.63265V3C10.9796 2.44772 11.4273 2 11.9796 2Z"
        fill="#959DA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89796 11.9796C6.89796 9.17308 9.17308 6.89796 11.9796 6.89796C14.7861 6.89796 17.0612 9.17308 17.0612 11.9796C17.0612 12.452 16.9044 12.8895 16.737 13.2465C16.5664 13.6102 16.3408 13.9796 16.1252 14.3223C16.0733 14.4047 16.0216 14.4864 15.9703 14.5674C15.5706 15.1987 15.1961 15.7901 14.9537 16.4202C14.7787 16.8753 14.6409 17.3519 14.5067 17.8388L14.4526 18.0358C14.3403 18.4462 14.2225 18.8764 14.089 19.2527C13.9475 19.6517 13.5699 19.9184 13.1465 19.9184H10.8126C10.3892 19.9184 10.0117 19.6517 9.87018 19.2527C9.7367 18.8764 9.61894 18.4462 9.50657 18.0358L9.45251 17.8388C9.31825 17.3519 9.18049 16.8753 9.00544 16.4202C8.76306 15.7901 8.38856 15.1987 7.98884 14.5674C7.93766 14.4866 7.88573 14.4046 7.83395 14.3223C7.61835 13.9796 7.39276 13.6102 7.22219 13.2465C7.05479 12.8895 6.89796 12.452 6.89796 11.9796ZM11.9796 8.89796C10.2777 8.89796 8.89796 10.2777 8.89796 11.9796C8.89796 12.0245 8.91689 12.1498 9.03297 12.3973C9.14589 12.6381 9.3115 12.915 9.52676 13.2572C9.57394 13.3322 9.62346 13.4101 9.67472 13.4907C10.0639 14.103 10.553 14.8727 10.8721 15.7022C11.0852 16.2561 11.2447 16.8145 11.3806 17.3071L11.4383 17.5172C11.477 17.6585 11.5136 17.792 11.5492 17.9184H12.4099C12.4455 17.7921 12.4821 17.6588 12.5208 17.5175L12.5786 17.3071C12.7145 16.8145 12.874 16.2561 13.0871 15.7022C13.4062 14.8727 13.895 14.1035 14.2842 13.4912C14.3355 13.4104 14.3852 13.3323 14.4324 13.2572C14.6477 12.915 14.8133 12.6381 14.9262 12.3973C15.0423 12.1498 15.0612 12.0245 15.0612 11.9796C15.0612 10.2777 13.6815 8.89796 11.9796 8.89796Z"
        fill="#959DA8"
      />
      <path
        d="M6.33585 4.92124C5.94533 4.53072 5.31216 4.53072 4.92164 4.92124C4.53112 5.31177 4.53112 5.94493 4.92164 6.33545L6.08082 7.49464C6.47135 7.88516 7.10451 7.88516 7.49504 7.49464C7.88556 7.10411 7.88556 6.47095 7.49504 6.08043L6.33585 4.92124Z"
        fill="#959DA8"
      />
      <path
        d="M16.4642 16.4646C16.8547 16.0741 17.4879 16.0741 17.8784 16.4646L19.0376 17.6238C19.4281 18.0143 19.4281 18.6475 19.0376 19.038C18.6471 19.4285 18.0139 19.4285 17.6234 19.038L16.4642 17.8788C16.0737 17.4883 16.0737 16.8551 16.4642 16.4646Z"
        fill="#959DA8"
      />
      <path
        d="M3 10.9796C2.44772 10.9796 2 11.4273 2 11.9796C2 12.5319 2.44772 12.9796 3 12.9796H4.63265C5.18494 12.9796 5.63265 12.5319 5.63265 11.9796C5.63265 11.4273 5.18494 10.9796 4.63265 10.9796H3Z"
        fill="#959DA8"
      />
      <path
        d="M18.3265 11.9796C18.3265 11.4273 18.7742 10.9796 19.3265 10.9796H20.9592C21.5115 10.9796 21.9592 11.4273 21.9592 11.9796C21.9592 12.5319 21.5115 12.9796 20.9592 12.9796H19.3265C18.7742 12.9796 18.3265 12.5319 18.3265 11.9796Z"
        fill="#959DA8"
      />
      <path
        d="M7.49504 17.8788C7.88556 17.4883 7.88556 16.8551 7.49504 16.4646C7.10451 16.0741 6.47135 16.0741 6.08082 16.4646L4.92164 17.6238C4.53112 18.0143 4.53112 18.6475 4.92164 19.038C5.31216 19.4285 5.94533 19.4285 6.33585 19.038L7.49504 17.8788Z"
        fill="#959DA8"
      />
      <path
        d="M19.0376 4.92124C19.4281 5.31177 19.4281 5.94493 19.0376 6.33545L17.8784 7.49464C17.4879 7.88516 16.8547 7.88516 16.4642 7.49464C16.0737 7.10411 16.0737 6.47095 16.4642 6.08043L17.6234 4.92124C18.0139 4.53072 18.6471 4.53072 19.0376 4.92124Z"
        fill="#959DA8"
      />
      <path
        d="M10.7551 20.3673C10.2028 20.3673 9.7551 20.8151 9.7551 21.3673C9.7551 21.9196 10.2028 22.3673 10.7551 22.3673H13.2041C13.7564 22.3673 14.2041 21.9196 14.2041 21.3673C14.2041 20.8151 13.7564 20.3673 13.2041 20.3673H10.7551Z"
        fill="#959DA8"
      />
    </svg>
  )
}

export default LightBulb
