import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NotificatonContainer, IconContainer } from "./styles"
import { NOTIFICATION_OPTIONS } from "../../constants"
import { Typography } from "@elevate_security/elevate-component-library"
const { Text } = Typography

const notifications = NOTIFICATION_OPTIONS.reduce((acc, cur) => {
  acc[cur.value] = cur
  return acc
}, {})
notifications["default"] = {}

const notificationTypes = (notificationType) =>
  notifications[notificationType] ?? notifications["default"] //This is the default in case notificationType does not exists in the notificatons object

export const NotificationMessage = ({
  notificationType,
  isDefaultColor = false,
}) => {
  const { value, label, icon } = notificationTypes(notificationType)

  if (isDefaultColor) {
    return (
      <NotificatonContainer>
        <div>
          <b>Matching Method</b>: {label}
        </div>
      </NotificatonContainer>
    )
  }

  return notificationType && notificationType.length && value && icon ? (
    <NotificatonContainer>
      <IconContainer>
        <FontAwesomeIcon icon={icon} />
      </IconContainer>
      <Text fontWeight="400">Matching Method: {label}</Text>
    </NotificatonContainer>
  ) : null
}

NotificationMessage.defaultProps = {
  notificationType: "",
}
