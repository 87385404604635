/*
 * Returns an array of unique entries from the input array, e.g.
 * [1,1,2,2,2,3,4,4] -> [1,2,3,4]. If you specify the comparator function, it
 * will be used to determine how to distinguish unique entries, e.g. [{id:
 * 'bob'}, {id: 'bob'}, {id: 'alice'}] -> [{id: 'bob'}, {id: 'alice'}] when
 * given a comparator of (item) => item.id
 */
export function uniq<T>(list: T[], comparator?: (item: T) => any): T[] {
  const collectedValues = new Set()
  return list.filter((item) => {
    const compareValue = comparator ? comparator(item) : item
    const seen = collectedValues.has(compareValue)
    collectedValues.add(compareValue)
    return !seen
  })
}
