import React from "react"

import { ComboBox } from "@elevate_security/elevate-component-library"

import {
  ManagerHierarchyWrapper,
  ComboBoxWrapper,
} from "./ManagerHierarchy.styles"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { GroupWrapper, ItemWrapper } from "../ConditionsGroup/styles"

const options = [
  {
    label: "Direct / Indirect Reports",
    value: "recursive",
  },
  {
    label: "Direct Reports",
    value: "direct",
  },
]

const ManagerHierarchy = (props) => {
  const { value, handleChange } = props
  return (
    <GroupWrapper className="tree occurance-tree-group">
      <ItemWrapper class="tree-item">
        <ManagerHierarchyWrapper>
          <SpaceBetween
            direction="row"
            justify="flex-start"
            gap="16px"
            alignItems="center"
          >
            <div>
              <strong> EMPLOYEES THAT ARE </strong>
            </div>
            <ComboBoxWrapper>
              <ComboBox
                data={options}
                value={options?.find((op) => op.value === value)}
                onChange={(e) => handleChange(e.value)}
                clearable={false}
                isSearchable={false}
                isMultiOption={false}
                checked={false}
                hideSelectedOptions
                closeMenuOnSelect
                components={{}}
                disabled={false}
                placeholder="Select category"
                inputId="select-category"
              />
            </ComboBoxWrapper>
            <div>
              <strong> OF MANAGERS SELECTED </strong>
            </div>
          </SpaceBetween>
        </ManagerHierarchyWrapper>
      </ItemWrapper>
    </GroupWrapper>
  )
}

export default React.memo(ManagerHierarchy)
