import React from "react"

import { RadioButton } from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"

const options = [
  {
    label: "Enable 2FA",
    value: "active",
  },
  {
    label: "Disable 2FA",
    value: "bypass",
  },
  {
    label: "Disabled",
    value: "disabled",
  },
]

export function EditCiscoDuoConfirmCompromisedUser({ action, updateAction }) {
  return (
    <SpaceBetween xs={{ padding: "15px 0px" }}>
      <div>
        <RadioButton
          options={options}
          name="cisco_duo_compromised_user_status"
          radioSelected={action?.user_status}
          onChange={(val) => {
            updateAction("user_status", val)
          }}
        />
      </div>
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
      />
    </SpaceBetween>
  )
}
