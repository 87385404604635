import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setPNG } from "@src/services/redux/exportPNG/actions"

type Props = React.PropsWithChildren<{
  id: string
  style?: { [key: string]: string }
}>

const ImageExportWrapper = ({ id, children, style }: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPNG(`${id}PNG`, id))
  }, [id, dispatch])

  return (
    <div id={id} style={style}>
      {children}
    </div>
  )
}

export default ImageExportWrapper
