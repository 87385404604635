import React, { useEffect } from "react"
import ChartBar from "@src/components/ChartBar"
import { formatWithCommas } from "@src/components/PerformanceBarChart/utils"
import tippy from "tippy.js"
import { SkeletonLoader } from "@src/utils/skeleton"
import { humanize } from "@src/utils/string"
import {
  scoreTiersGraphOrder,
  factorsGraphOrder,
} from "@src/scenes/HumanRiskDashboard/utils"
import { useHistory } from "react-router-dom"
import {
  AccessGraphContainer,
  AccessGraphWrapper,
  AccessHeader,
} from "@src/scenes/HumanRiskDashboard/components/AccessAndAttackFactorGraph/styles"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getActionName } from "@src/components/ActionChart/utils"
import { getScoreBreakDownActionData } from "@src/services/apis/reputation"
import { setScoreBreakdown } from "@src/services/redux/reputations/actions"

const scoresTiersToNumbersLabelsMapper = {
  very_low: "0.0 - 1.9",
  low: "2.0 - 3.9",
  medium: "4.0 - 5.9",
  high: "6.0 - 7.9",
  very_high: "8.0 - 10.0",
}

function AccessAndAttackFactorGraph({ graphType, keyFactor, showHeader }) {
  const reputations = useSelector((state) => state.get("reputationsReducer"))
  const history = useHistory()
  const dispatch = useDispatch()
  const isAttackFactor = () => graphType === "attack_factor"
  const loader = isAttackFactor()
    ? reputations.factor_graph_loader
    : !!reputations[`${graphType}_loader`]

  const data = isAttackFactor()
    ? reputations[graphType]?.sort(
        (a, b) =>
          factorsGraphOrder.indexOf(a.label) -
          factorsGraphOrder.indexOf(b.label),
      )
    : reputations[graphType] || []

  const graphLabel = {
    attack_factor: "Attack Factor Distribution",
    score_breakdown: "Score Breakdown -",
  }
  const generateGraphData = (type) => {
    if (loader) return []
    if (type === "score_breakdown") {
      return data
        ?.filter((el) => el.label !== "not_scored")
        .map(({ individual_count: value, label: label }) => ({
          value,
          label: humanize(label) || [],
          secondaryLabel: scoresTiersToNumbersLabelsMapper[label],
          key: label,
        }))
    } else {
      return data?.map(({ individual_count: value, label: label }) => ({
        value,
        label: humanize(label) || [],
      }))
    }
  }

  const getNotScoredIndividuals = (type) => {
    if (loader) return ""
    if (type === "score_breakdown") {
      if (data.length === 0) return ""
      const individuals = data?.filter((el) => el.label === "not_scored")[0]
        .individual_count
      return `Individuals not scored: ${individuals}`
    }
    return ""
  }

  const getData = async (action, type) => {
    if (type === "score_breakdown" && loader) {
      const res = await getScoreBreakDownActionData(action)
      const newData = res?.data.map((el) => ({
        individual_count: el.individuals,
        label: el.tier,
      }))
      const sortedData = newData.sort(
        (a, b) =>
          scoreTiersGraphOrder.indexOf(a.label) -
          scoreTiersGraphOrder.indexOf(b.label),
      )
      dispatch(setScoreBreakdown(sortedData, false))
    }
  }

  const getTooltipContent = ({ label, value }) => {
    const entity = value < 1 ? "individual" : "individuals"
    const tool_tip_message = `<strong>${formatWithCommas(
      value,
    )} ${entity}</strong> have a ${label} ${humanize(
      graphLabel[graphType] || graphType,
    ).toLowerCase()}`
    return `<div class="custom-tooltip">${tool_tip_message}</div>`
  }

  const scoreBreakdownTooltip = ({ label, value }) => {
    const entity = value < 1 ? "individual" : "individuals"
    const tool_tip_message = `<strong>${formatWithCommas(
      value,
    )} ${entity}</strong> are ${label} in ${humanize(keyFactor).toLowerCase()}`
    return `<div class="custom-tooltip">${tool_tip_message}</div>`
  }

  const handleAccessGraphMouseIn = ({ label, value, barId }) => {
    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content:
        graphType === "score_breakdown"
          ? scoreBreakdownTooltip({ label, value })
          : getTooltipContent({ label, value }),
    }
    const element = document.querySelector(barId)
    tippy(element, tippyProps)
    element._tippy.show()
  }

  const handleAccessGraphMouseOut = ({ barId }) => {
    const element = document.querySelector(barId)
    element._tippy.hide()
  }

  const handleAccessGraphMouseClick = (value) => {
    const filterValue = value?.toLowerCase().replaceAll(" ", "_")
    history.push(
      `/engagement/vision2/insights/individual/risk-analysis?filters=${graphType} IN ${filterValue}&order_by=human_risk_score desc`,
    )
  }

  const generateXAxisLabel = (graphType) => {
    switch (graphType) {
      case "attack_factor":
        return "Attack Factor"
      case "access_factor":
        return "Access Factor"
      case "score_breakdown":
        return "Score"
      default:
        return "Score"
    }
  }

  useEffect(() => {
    getData(keyFactor.replaceAll("-", "_"), graphType)
  }, [keyFactor])

  if (loader) {
    return (
      <AccessGraphWrapper>
        <SkeletonLoader height={20} />
        <SkeletonLoader height={350} />
      </AccessGraphWrapper>
    )
  }

  return (
    <>
      {showHeader && (
        <AccessHeader>
          {humanize(graphLabel[graphType] || graphType)}{" "}
          {graphType === "score_breakdown" && " - "}
          {graphType === "score_breakdown" && getActionName(keyFactor)}
        </AccessHeader>
      )}
      <AccessGraphContainer>
        <ChartBar
          onBarMouseIn={handleAccessGraphMouseIn}
          onBarMouseOut={handleAccessGraphMouseOut}
          onBarMouseClick={
            graphType === "score_breakdown"
              ? () => {}
              : handleAccessGraphMouseClick
          }
          data={generateGraphData(graphType)}
          xAxisLabel={generateXAxisLabel(graphType)}
          graphHeight={graphType === "score_breakdown" ? 235 : 280}
          showSecondaryXAxisLabel={graphType === "score_breakdown"}
        />
        {getNotScoredIndividuals(graphType)}
      </AccessGraphContainer>
    </>
  )
}

export default AccessAndAttackFactorGraph
AccessAndAttackFactorGraph.propTypes = {
  graphType: PropTypes.string,
  keyFactor: PropTypes.string,
  showHeader: PropTypes.bool,
}
AccessAndAttackFactorGraph.defaultProps = {
  graphType: "access_factor",
  showHeader: true,
}
