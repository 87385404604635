import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const RiskResponseRuleFiltersWrapper = styled.div``

export const FiltersWrapper = styled.div`
  padding: 24px;
`

export const Separator = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray.opaque[200]};
  height: 1px;
`

export const CaretWrapper = styled.div`
  padding-top: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`

export const FilterTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
`

export const AppliedFiltersWrapper = styled("div")`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.border.opaque.darkOpaque};
`

export const AppliedFiltersTitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px 0px;
`

export const AppliedFiltersTitle = styled("div")`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${theme.colors.black};
`

export const AppliedFiltersClearLink = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${theme.colors.primary["500"]};
  cursor: pointer;
`
