import React from "react"

import { SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS } from "../../RiskRuleOutcome/constants"
import { ActionGroups } from "./ActionGroups"
import { EditMicrosoftTeamsNotification } from "../actionRenderComponents/EditMicrosoftTeamsNotification"
import { ActionConfig } from "./actionConfigTypes"
import { validateInstantMessageActionPayload } from "./validators/validateInstantMessageActionPayload"

type Payload = {
  action_type: typeof SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS
  matching_method: "delta" | "snapshot"
  message: string
  send_persons_as_attachment: boolean
}

const config: ActionConfig<Payload> = {
  label: "Managers of individuals who meet the criteria",
  group: ActionGroups.MICROSOFT_TEAMS,
  defaultPayload: {
    action_type: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS,
    matching_method: "delta",
    message:
      "Hi {{manager.manager_first_name}} {{manager.manager_last_name}}! We'd like to update you that {{manager.affected_direct_reports_count}} members of your direct reports were targeted by the risk response rule {{rule.name}}.",
    send_persons_as_attachment: true,
  },
  validate: (payload) => {
    return validateInstantMessageActionPayload(payload)
  },
  isEnabled: (isServiceIntegrated) => isServiceIntegrated("microsoft-teams"),
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <EditMicrosoftTeamsNotification
        allowPersonListAttachment
        templateAttributes={templateAttributes}
        action={details}
        onChange={updateAction}
      />
    )
  },
}

export default config
