import React, { useCallback } from "react"
import { useRequest } from "ahooks"
import { getManagerScore } from "@src/services/apis/reputation"
import {
  EmptyScores,
  IndividualDrawerWrapper,
  IndividualTitle,
  ManagerSideDrawer,
  Score_Tiles_Wrapper,
  ScoreFooter,
  ScoreHeader,
  Tile,
  TileRibbon,
} from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import { Button, Icon, Tabs } from "@elevate_security/elevate-component-library"
import {
  INIT_MANAGER_DRAWER,
  SCORE_DETAILS_TABS,
  VIEW_MANGER_DASHBOARD,
  VIEW_PROFILE,
} from "@src/scenes/HumanRiskDashboard/constants"
import Tab from "@elevate_security/elevate-component-library/dist/Tab"
import { humanize } from "@src/utils/string"
import PropTypes from "prop-types"
import { managerScoreColors } from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/getScoreBreakColumns"
import { SkeletonLoader } from "@src/utils/skeleton"
import {
  ACTION_NAMES,
  Score_Details_Tooltips,
} from "@src/components/ActionChart/utils"
import { Individual } from "@src/scenes/SecEngIndividualRiskAnalysis/components/IndividualRiskAnalysisTable/columns"
import HelpTooltip from "@src/components/HelpTooltip"

function ScoreDetailsDrawer({ drawer, setDrawer }) {
  const { loading, data } = useRequest(
    () => getManagerScore({ es_person_id: drawer.es_person_id }),
    {
      refreshDeps: [drawer.es_person_id],
      debounceInterval: 500,
      formatResult: (response) => response?.data || {},
    },
  )

  const ScoreDetailsHeader = useCallback(() => {
    return (
      <div className="d-flex justify-content-between w-100">
        <ScoreHeader>Score Details</ScoreHeader>
        <div onClick={() => setDrawer(INIT_MANAGER_DRAWER)}>
          <Icon name="Close" stroke="#959DA8" size="sm" />
        </div>
      </div>
    )
  }, [])

  if (loading || data === undefined) {
    return (
      <ManagerSideDrawer show={true}>
        <ScoreDetailsHeader />
        <SkeletonLoader marginTop={10} marginBottom={15} height={40} />
        <SkeletonLoader height={700} />
      </ManagerSideDrawer>
    )
  }

  const hasTeams = drawer.name.includes("s Team")
  const VIEW_TYPE = hasTeams ? VIEW_PROFILE : VIEW_MANGER_DASHBOARD

  const scoreKey = hasTeams ? "team" : "individual"
  const scoreData = data?.scores[scoreKey] || {}

  let scoreDataKeys = Object.keys(scoreData)

  if (scoreKey === "team") {
    scoreDataKeys = scoreDataKeys.filter((item) => item !== "team_human_risk")
  }

  return (
    <ManagerSideDrawer show={drawer.isOpen}>
      <ScoreDetailsHeader />
      <IndividualTitle>{drawer?.name}</IndividualTitle>
      <Tabs currentTabIndex={0}>
        <Tab title={SCORE_DETAILS_TABS[0]} key={SCORE_DETAILS_TABS[0]}>
          {scoreDataKeys.length ? (
            <Score_Tiles_Wrapper>
              <IndividualDrawerWrapper>
                {scoreDataKeys?.map((tile) => {
                  const score = scoreData[tile] || 0
                  const { color, backgroundColor, text } =
                    managerScoreColors(score)
                  const actionToolTip = Score_Details_Tooltips[tile] || ""
                  return (
                    <Tile id={tile}>
                      <div className="tile-header">
                        <span>{ACTION_NAMES[tile] || humanize(tile)}</span>
                        {actionToolTip && <HelpTooltip body={actionToolTip} />}
                      </div>
                      <div className="tile-score">{score.toFixed(1)}</div>
                      <TileRibbon
                        color={color}
                        backgroundColor={backgroundColor}
                      >
                        {text}
                      </TileRibbon>
                    </Tile>
                  )
                })}
              </IndividualDrawerWrapper>
            </Score_Tiles_Wrapper>
          ) : (
            <EmptyScores>No Score Available</EmptyScores>
          )}
          <ScoreFooter>
            <Button
              onClick={() => setDrawer(INIT_MANAGER_DRAWER)}
              style={{ background: "transparent", color: "#0c7d84" }}
            >
              Cancel
            </Button>
            <Button>
              <Individual
                id={drawer.es_person_id}
                riskType={VIEW_TYPE.riskType}
                name={VIEW_TYPE.text}
              />
            </Button>
          </ScoreFooter>
        </Tab>
      </Tabs>
    </ManagerSideDrawer>
  )
}

ScoreDetailsDrawer.propTypes = {
  drawer: PropTypes.shape({
    name: PropTypes.string,
    es_person_id: PropTypes.string,
    isOpen: PropTypes.bool,
  }),
  setDrawer: PropTypes.func,
}

export default ScoreDetailsDrawer
