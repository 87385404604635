import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Typography,
  Header,
  Modal,
  Icon,
  theme,
} from "@elevate_security/elevate-component-library"
import {
  ModalContentWrapper,
  IconWrapper,
  WarningModalWrapper,
  TextWrapper,
} from "./styles"
import Checkbox from "@src/components/Checkbox"

const WarningModal = ({ isOpen, title, message, onConfirm, onClose }) => {
  const [isChecked, setIsChecked] = useState(false)

  const handlerCheckbox = (value) => {
    setIsChecked(value)
  }

  const renderModalFooter = (onConfirm) => {
    return [
      <Button key="cancel" theme="ghost" onClick={onClose} fontWeight="bold">
        Cancel
      </Button>,
      <Button
        key="confirm"
        onClick={onConfirm}
        fontWeight="bold"
        disabled={!isChecked}
      >
        Update
      </Button>,
    ]
  }

  useEffect(() => {
    if (!isOpen) setIsChecked(false)
  }, [isOpen])

  return (
    <WarningModalWrapper>
      <Modal
        isOpen={isOpen}
        key="cisco-duo-cap-warning-modal"
        onClose={onClose}
        header={
          <Header>
            <Typography.H2 fontWeight="bold" color="900" margin={15}>
              {title}
            </Typography.H2>
          </Header>
        }
        rightButtons={renderModalFooter(onConfirm)}
        style={{ maxHeight: "400px", maxWidth: "600px" }}
      >
        <ModalContentWrapper>
          <IconWrapper>
            <Icon name="Info" size="lg" fill={theme.colors.border.error} />
          </IconWrapper>
          <TextWrapper>
            <Typography.Text>{message}</Typography.Text>
            <Checkbox label="Check to confirm" onChange={handlerCheckbox} />
          </TextWrapper>
        </ModalContentWrapper>
      </Modal>
    </WarningModalWrapper>
  )
}

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default WarningModal
