import React from "react"
import { AxiosResponse } from "axios"
import { useRequest } from "ahooks"

import { Separator, FilterTitle } from "@src/components/SimpleFilters/styles"
import MultiSelectFilter from "../IndividualProfilePage/components/MoreFilter/MultiSelectFilter"
import { getPoliciesAuditLogsFilters } from "@src/services/apis/policies"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { humanize } from "@src/utils/string"
import DateRange from "../IndividualProfilePage/components/MoreFilter/DateRange"

// TODO: Will remove once we fully migrated to new filters
const CURRENT_FILTERS = [
  "action_category",
  "behavior_category",
  "behaviors",
  "criteria_includes",
  "rule_status",
  "status",
  "user_type",
]

const FILTER_MAPPINGS: { [key: string]: string } = {
  azure_ad: "Azure AD (Entra ID)",
  github: "GitHub",
  crowdstrike: "CrowdStrike",
  sailpoint: "SailPoint",
  service_now: "ServiceNow",
}

export type AppliedFilter = {
  type?: string
  key: string
  value: string[] | string
  humanizedName: string
  original?: string
}

export type Selection = {
  key: string
  label: string
  value: string
  checked: boolean
}

export const AuditLogFilters = ({
  onChange,
  appliedFilters,
}: {
  onChange: (selection: Selection) => void
  appliedFilters: AppliedFilter[]
}) => {
  const { data, loading, error } = useRequest(async () => {
    const result = (await getPoliciesAuditLogsFilters()) as AxiosResponse
    return result.data
  })

  const filters = Object.keys(data || {})

  if (loading) {
    return <ActivityIndicator active={true} />
  }

  if (error) {
    return <div>An error occurred. Please try again.</div>
  }

  return (
    <>
      {filters
        .filter((filter) => CURRENT_FILTERS.includes(filter))
        .map((filterName) => (
          <>
            <MultiSelectFilter
              showAllFilters
              section={{
                key: data?.keys?.[filterName] || filterName,
                title: <FilterTitle>{humanize(filterName)}</FilterTitle>,
                items:
                  data && data[filterName] && Array.isArray(data[filterName])
                    ? (data[filterName] as string[])
                        .filter((filter: string) => filter)
                        .map((filter: string) => {
                          const filterValue = filter.toLowerCase()
                          const label =
                            FILTER_MAPPINGS[filterValue] ||
                            humanize(filterValue)
                          return {
                            label,
                            value: filter,
                          }
                        })
                    : [],
              }}
              filters={appliedFilters}
              handleChange={onChange}
            />
            <Separator />
          </>
        ))}
      <DateRange
        section={{
          title: <FilterTitle>Date</FilterTitle>,
          type: "date",
          key: "date",
        }}
        value={
          appliedFilters?.find((filter: AppliedFilter) => filter.key === "date")
            ?.value
        }
        onChange={onChange}
      />
    </>
  )
}
