import React from "react"
import styled from "styled-components"
import { Toggle } from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"
import PaperClip from "@src/scenes/RiskResponseRuleCreateScreen/components/paperClip"

const IconWrapper = styled.span`
  margin-left: 5px;
  padding-bottom: 5px;
`

export function PersonListAttachmentToggle({ onChange, checked }) {
  return (
    <SpaceBetween size="xs" direction="row" justify="start" alignItems="center">
      <Toggle checked={checked} onChange={onChange} />
      <div>Include individuals list as CSV attachment</div>
      <IconWrapper>
        <PaperClip />
      </IconWrapper>
    </SpaceBetween>
  )
}
