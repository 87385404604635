import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "@elevate_security/elevate-component-library"

import { ComboBoxWrapper } from "./styles"
import { useRequest } from "ahooks"
import { getSlackChannels } from "@src/services/apis/slack"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"

const { Text } = Typography

const LIMIT = 100

const SlackChannelsComboBox = ({
  notification,
  setSelectedSlackNotification,
}) => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalChannels, setTotalChannels] = useState(0)

  const { data, loading } = useRequest(
    () =>
      getSlackChannels({
        search: `${search}`,
        limit: LIMIT,
        offset: page * LIMIT,
        app_channels: true,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        if (page === 0) {
          setTotalChannels(response?.data?.count || 0)
          return (
            response?.data?.results?.map((channel) => ({
              label: channel?.channel_name,
              value: channel?.channel_name,
            })) || []
          )
        } else {
          return [
            ...data,
            ...response?.data?.results?.map((channel) => ({
              label: channel?.channel_name,
              value: channel?.channel_name,
            })),
          ]
        }
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
  }

  const handleOnChange = (selected) => {
    const channelNames = selected?.map((item) => item.value)
    setSelectedSlackNotification({
      ...notification,
      channel_names: channelNames,
    })
  }

  const selectedChannels = notification?.channel_names?.map((channel) => ({
    label: channel,
    value: channel,
  }))

  return (
    <SpaceBetween size="sm">
      <Text color="700" fontWeight="bold">
        Channel(s)
      </Text>
      <ComboBoxWrapper>
        <ComboBoxWithOverlap
          data={data}
          defaultValue={selectedChannels || []}
          value={selectedChannels || []}
          onChange={handleOnChange}
          isLoading={loading}
          clearable={false}
          isSearchable={true}
          isMultiOption={true}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{}}
          disabled={false}
          placeholder="Choose or search slack channels"
          handleScrollBotttom={() => {
            if (data.length < totalChannels) {
              setPage(page + 1)
            }
          }}
          handleInputChange={(value) => handleSetSearch(value)}
        />
      </ComboBoxWrapper>
    </SpaceBetween>
  )
}

SlackChannelsComboBox.propTypes = {
  notification: PropTypes.object,
  setSelectedSlackNotification: PropTypes.func,
}

export default SlackChannelsComboBox
