import styled from "styled-components"

export const ScoreSpan = styled.span`
  font-size: 44px;
  line-height: 56px;
  color: #34383c;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
`

export const ScoreIcon = styled.div`
  min-width: 16px;
`
export const ScoreTrend = styled.strong`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 13px;
  min-width: 24px;
  text-align: center;
`
