export const SCORE_LEVELS = {
  "Very Low": "very_low",
  Low: "low",
  Medium: "medium",
  High: "high",
  "Very High": "very_high",
}

export const ORGANIZATION_ROLE_OPS = {
  manager: "manager",
  contributor: "contributor",
}

export const EMPLOYEE_ROLE_OPS = {
  contractor: "contractor",
  employee: "employee",
  not_set: "not_set",
}

export const TENURE_STATUS_OPS = {
  new_hire: "new_hire",
  veteran: "veteran",
}

export const BOOLEAN_OPS = {
  organizational_role: ORGANIZATION_ROLE_OPS,
  employment_type: EMPLOYEE_ROLE_OPS,
  tenure_status: TENURE_STATUS_OPS,
}

export const MODIFIER_OPTIONS = [
  "is",
  "is exactly",
  "is greater than",
  "is greater than or equal to",
  "is fewer than",
  "is fewer than or equal to",
  "are",
]

export const excludeIncludeLabel = {
  exclusions: "exclude",
  inclusions: "only include",
}

export const specifyGroupDefaultData = {
  incorporate: "exclude",
  mode: "all",
  toggle: false,
}

export const LIMIT_ERROR_MESSAGE = "You can only add up to 15 groups"

export const GROUPS_LIMIT = 15

export const AZURE_AD = "azuread"
export const AZURE_REMOVE_INDIVIDUALS = "remove_azuread_user_from_group"
export const AZURE_ADD_INDIVIDUALS = "add_azuread_user_to_group"
export const AZURE_SYSTEM_GROUPS = [
  AZURE_REMOVE_INDIVIDUALS,
  AZURE_ADD_INDIVIDUALS,
]

export const CISCO_DUO = "cisco_duo"
export const CISCO_DUO_REMOVE_INDIVIDUALS = "remove_cisco_duo_users_from_groups"
export const CISCO_DUO_ADD_INDIVIDUALS = "add_cisco_duo_users_to_groups"
export const CISCO_DUO_SYSTEM_GROUPS = [
  CISCO_DUO_REMOVE_INDIVIDUALS,
  CISCO_DUO_ADD_INDIVIDUALS,
]
/* Need to remove the following two depricated */
export const INCLUDE_USERS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY =
  "include_azuread_users_in_cap"
export const EXCLUDE_USERS_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY =
  "exclude_azuread_users_from_cap"

export const ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP =
  "add_azuread_users_to_inclusion_list_in_cap"
export const REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP =
  "remove_azuread_users_from_inclusion_list_in_cap"
export const ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP =
  "add_azuread_users_to_exclusion_list_in_cap"
export const REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP =
  "remove_azuread_users_from_exclusion_list_in_cap"
export const ADD_GROUPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP =
  "add_azuread_groups_to_inclusion_list_in_cap"
export const REMOVE_GROUPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP =
  "remove_azuread_groups_from_inclusion_list_in_cap"
export const ADD_GROUPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP =
  "add_azuread_groups_to_exclusion_list_in_cap"
export const REMOVE_GROUPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP =
  "remove_azuread_groups_from_exclusion_list_in_cap"

export const ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP =
  "add_azuread_apps_to_inclusion_list_in_cap"
export const REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP =
  "remove_azuread_apps_from_inclusion_list_in_cap"
export const ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP =
  "add_azuread_apps_to_exclusion_list_in_cap"
export const REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP =
  "remove_azuread_apps_from_exclusion_list_in_cap"

export const APPS_AZURE_CAP = "apps_azure_cap"

export const USERS_AZURE_AD_CAP_TYPES = [
  ADD_USERS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_USERS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_USERS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_USERS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
]

export const GROUPS_AZURE_AD_CAP_TYPES = [
  ADD_GROUPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_GROUPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_GROUPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_GROUPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
]

export const APPS_AZURE_AD_CAP_TYPES = [
  ADD_APPS_TO_INCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_APPS_FROM_INCLUSION_LIST_IN_AZUREAD_CAP,
  ADD_APPS_TO_EXCLUSION_LIST_IN_AZUREAD_CAP,
  REMOVE_APPS_FROM_EXCLUSION_LIST_IN_AZUREAD_CAP,
]

export const INCLUDE_GROUPS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY =
  "include_azuread_groups_in_cap"
export const EXCLUDE_GROUP_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY =
  "exclude_azuread_groups_from_cap"
export const GROUPS_AZURE_CAP = "groups_azure_cap"
export const APPLY_CISCO_DUO_CONDITIONAL_ACCESS_POLICY =
  "apply_cisco_duo_conditional_access_policy"

export const CONFIRM_CISCO_DUO_COMPROMISED_USERS =
  "confirm_cisco_duo_compromised_users"

export const REMOVE_GITHUB_REPOSITORIES_ACCESS =
  "remove_github_repositories_access"
export const UPDATE_AZUREAD_CONDITIONAL_ACCESS_POLICY =
  "update_azuread_conditional_access_policy"
export const USERS_AZURE_CAP = "users_azure_cap"
export const CONFIRM_AZUREAD_COMPROMISED_USER =
  "confirm_azuread_compromised_user"

export const CROWDSTRIKE_FALCON = "crowdstrike_falcon"

export const TAG_CROWDSTRIKE_FALCON_RISKY_USERS =
  "crowdstrike_falcon_tag_risky_users"

export const REOPEN_RECENTLY_CLOSED_INCIDENTS =
  "reopen_recently_closed_incidents"
export const TAGS = "tags"

export const ELEVATESECURITY = "elevatesecurity"
export const ELEVATESECURITY_GROUPS_ADD_INDIVIDUALS =
  "add_elevatesecurity_user_to_group"
export const ELEVATESECURITY_GROUPS_REMOVE_INDIVIDUALS =
  "remove_elevatesecurity_user_from_group"
export const ELEVATESECURITY_SYSTEM_GROUPS = [
  ELEVATESECURITY_GROUPS_ADD_INDIVIDUALS,
  ELEVATESECURITY_GROUPS_REMOVE_INDIVIDUALS,
]

export const RENAME_FIELD_KEYS = {
  phishing: "simulated_phishing",
  real_world_phishing: "actual_phishing",
}
export const RISK_RESPONSE_RULES = "Risk Response Rules"

export const CAP_WARNING_MESSAGE =
  "By updating the selected Conditional Access Policy, its settings will be updated to reflect what has been applied in this Risk Response Rule. This change will affect all users and / or groups that are configured to utilize the selected Conditional Access Policy. This can affect more than just the individuals within the cohort that were selected during evaluating whom matches the conditions of the input criteria."

export const USERS_AZURE_ENTERPRISE_APPLICATION =
  "users_azure_enterprise_application"
export const GROUPS_AZURE_ENTERPRISE_APPLICATION =
  "groups_azure_enterprise_application"

export const ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION =
  "add_azuread_users_to_enterprise_applications"
export const REMOVE_USERS_FROM_AZUREAD_ENTERPRISE_APPLICATION =
  "remove_azuread_users_from_enterprise_applications"
export const ADD_GROUPS_TO_AZUREAD_ENTERPRISE_APPLICATION =
  "add_azuread_groups_to_enterprise_applications"
export const REMOVE_GROUPS_FROM_AZUREAD_ENTERPRISE_APPLICATION =
  "remove_azuread_groups_from_enterprise_applications"

export const EXECUTE_SAILPOINT_WORKFLOW = "execute_sailpoint_workflow"
export const TAG_INCIDENTS_CROWDSTRIKE = "crowdstrike_falcon_tag_risky_users"
export const SAILPOINT_INPUT_TYPE = {
  false: "password",
  true: "text",
}
export const CLIENT_ID = "client_id"
export const CLIENT_SECRET = "client_secret"
