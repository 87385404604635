import { validateObject } from "@src/utils/validateObject"

type InstantMessageFields = {
  message: string
}

export function validateInstantMessageActionPayload<
  T extends InstantMessageFields,
>(payload: T) {
  return validateObject(payload, [
    ["message", ({ message }) => Boolean(message), "Message body is required"],
  ])
}
