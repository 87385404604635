import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import {
  Modal,
  Button,
  Header,
  Typography,
  Input,
} from "@elevate_security/elevate-component-library"

import { cloneRule } from "@src/services/apis/policies"
import { createPolicyRedux } from "@src/services/redux/policies/actions"
import { isEmpty } from "@src/utils/string"
import { ruleInfoValidation } from "@src/scenes/RiskResponseRuleCreateScreen/components/RuleInfoPage/utils"
import { ValidationDiv } from "./styles"
import { POLICY_STATE_MAP } from "@src/scenes/RiskResponseRuleCreateScreen/constants"

const { H2 } = Typography

const CloneModal = ({ ruleName, item, showModal, onCancel, onClose }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState(`Clone - ${ruleName}`)
  const [cloning, setCloning] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = async () => {
    // Apply form validation before api call
    const payload = {
      name,
    }
    const hasError = await ruleInfoValidation({
      editFlag: false,
      selectedPolicy: payload,
      ruleName: name,
      dispatch,
    })

    if (hasError) {
      setError(true)
      return
    }
    setCloning(true)
    const { data, error } = await cloneRule(item?.id, payload)
    setCloning(false)
    if (error) {
      return
    }
    // Save new rule on top of list
    if (data) {
      dispatch(createPolicyRedux(data))
    }
    onCancel()
  }

  const renderButtons = () => [
    <Button
      key="cancel"
      theme="ghost"
      onClick={(e) => onClose(e, true)}
      disabled={cloning || isEmpty(name)}
    >
      Cancel
    </Button>,
    <Button
      key="save"
      onClick={handleSubmit}
      disabled={cloning || isEmpty(name)}
    >
      Save
    </Button>,
  ]

  return (
    <Modal
      onClose={onCancel}
      header={
        <Header>
          <H2 color="900">Clone Risk Response Rule </H2>
        </Header>
      }
      rightButtons={renderButtons()}
      isOpen={showModal}
    >
      <Input
        value={name}
        autoFocus={true}
        placeholder="Name your risk response rule"
        width="full"
        onChange={(val) => setName(val)}
      />
      {error && (
        <ValidationDiv>
          It looks like this name is already in use. Try another?
        </ValidationDiv>
      )}
    </Modal>
  )
}

CloneModal.defaultProps = {
  item: {},
}

CloneModal.propTypes = {
  ruleName: PropTypes.string,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  item: PropTypes.shape({
    created_datetime: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    state: PropTypes.oneOf([
      POLICY_STATE_MAP.ACTIVE,
      POLICY_STATE_MAP.DRAFT,
      POLICY_STATE_MAP.TEST,
      POLICY_STATE_MAP.PAUSED,
      POLICY_STATE_MAP.DELETED,
      POLICY_STATE_MAP.DISABLED,
    ]),
    name: PropTypes.string,
    organization_nid: PropTypes.string,
    rules: PropTypes.array,
    updated_datetime: PropTypes.string,
  }),
}

export default CloneModal
