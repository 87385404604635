import {
  RISK_ANALYSIS_URL,
  RISK_ANALYSIS_URL_BUILDER,
  RISK_DETECTION_RULES_URL,
} from "@src/constants"

export const getRiskAnalysisURL = (
  url = "individual",
  actionType = "risk-analysis",
  search = "",
) => RISK_ANALYSIS_URL_BUILDER(url, actionType) + (search && `?${search}`)
export const geNewtRiskAnalysisURL = (search = "") =>
  search && search.includes("?")
    ? `${RISK_ANALYSIS_URL["individual"]}${`${search}`}`
    : `${RISK_ANALYSIS_URL["individual"]}${`?${search}`}`

export const getRiskDetectionRuleUrl = () => RISK_DETECTION_RULES_URL

export const isPaginationChanged = (prevPagination, pagination) =>
  JSON.stringify(prevPagination) !== JSON.stringify(pagination)

export const TARGET_TYPE = {
  EVERYONE: "target_everyone",
  QUERY: "target_by_query",
  IDS: "target_by_id",
}
