import React, { useContext } from "react"
import { useRequest } from "ahooks"
import { getRecommendation } from "@src/services/apis/reputation"
import HumanRisksInsights from "./HumanRisksInsights"

const HumanRisksInsightsContext = React.createContext()

export const useHumanRisksInsights = () => {
  return useContext(HumanRisksInsightsContext)
}

export default function HumanRisksInsightsProvider({ actionableDetailsFlag }) {
  const { loading, data: response } = useRequest(getRecommendation)

  const availableRecommendation =
    response?.data?.filter((item) => item.recommendations.length > 0) || []

  if (!availableRecommendation?.length) {
    return <></>
  }

  return (
    <HumanRisksInsights
      actionableDetailsFlag={actionableDetailsFlag}
      recommendations={availableRecommendation}
      loading={loading}
    />
  )
}
