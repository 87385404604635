import fetcher from "@src/services/fetcher"
import { BASE_EP_API_URL } from "@src/constants"
import { getErrorStatus } from "@src/services/fetcher/utils"

const API_URL = BASE_EP_API_URL()

export async function getOutboundServices() {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/intsync/outbound-services`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
