import React, { useMemo, useState, useEffect, useRef } from "react"
import { getIndividualsInvolvedColumns } from "./columns"
import { useRequest } from "ahooks"
import { useParams } from "react-router-dom"
import {
  Table,
  AppliedFilters,
  SearchInput,
} from "@elevate_security/elevate-component-library"
import {
  DragoWrapper,
  IndividualTableWrapper,
  Toolbar,
  ClearFilters,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import MoreFilter from "@src/scenes/IndividualProfilePage/components/MoreFilter"
import { InsightListOfPeopleFilterOptions } from "@src/scenes/IndividualProfilePage/ProfileInfo/components/EventsLog/components/EventsLogTable/more_filters_options"
import { getActionableListOfPeople } from "@src/services/apis/reputation"
import { getOptionsParams, individualInvolveDummyData } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import DragosaurWithUmbrella from "@src/components/DragosaurWithUmbrella"
import { FilterCSVWrapper } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/VcDemo/style"
import { getRisksKeys } from "@src/scenes/SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import ExportData from "@src/components/ExportData"
import { humanize } from "@src/utils/string"

function IndividualsInvolved({ showInsightText, setShowInsightText }) {
  const [orderBy, setOrderBy] = useState("human_risk_score desc")
  const [involvedAppliedFilter, setInvolvedAppliedFilter] = useState([])
  const [filters, setFilters] = useState({})
  const [searchFilter, setSearchFilter] = useState("")
  const dispatch = useDispatch()
  const timeout = useRef()

  const individualAnalysis = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const { entity } = useParams()
  // Need to populate this for fetching all departments and location
  const { loadingRiskKeys, run: FetchRisksKeys } = useRequest(
    getRisksKeys(dispatch),
    {
      manual: true,
    },
  )

  const involveOptionsParams = getOptionsParams(
    !loadingRiskKeys,
    individualAnalysis.departments,
    !loadingRiskKeys,
    individualAnalysis.locations,
  )

  useEffect(() => {
    // To fetch departments and locations
    if (
      !individualAnalysis.departments.length ||
      !individualAnalysis.locations.length
    ) {
      FetchRisksKeys()
    }
  }, [])

  const getFiltersParam = () => {
    const fields = [
      {
        key: "filterBy",
        param: `searchbar searches ${filters?.filterBy[0]}`,
        value: filters?.filterBy.length > 0 ? filters.filterBy : null,
      },
      {
        key: "full_name",
        param: `full_name ILIKE ${filters?.full_name[0]}`,
        value: filters?.full_name.length > 0 ? " " : null,
      },
      {
        key: "manager_name",
        param: `manager_name ILIKE ${filters?.manager_name[0]}`,
      },
      {
        key: "department",
        param: `department IN ${filters?.department?.join(",")}`,
        value: filters?.department?.length > 0 ? " " : null,
      },
      {
        key: "location",
        param: `location IN ${'"' + filters?.location?.join('","') + '"'}`,
        value: filters?.location?.length > 0 ? " " : null,
      },
      {
        key: "human_risk_score",
        param: `human_risk_score GE ${filters?.overall[0]} AND human_risk_score LE ${filters?.overall[1]}`,
        value: filters?.overall?.length > 0 ? " " : null,
      },
      {
        key: "action_factor",
        param: `action_factor IN ${filters?.action_factor?.join(",")}`,
        value: filters?.action_factor?.length > 0 ? " " : null,
      },
      {
        key: "attack_factor",
        param: `attack_factor IN ${filters?.attack_factor?.join(",")}`,
        value: filters?.attack_factor?.length > 0 ? " " : null,
      },
    ]

    return fields
      .filter((field) => field.value)
      .map((field) => field.param)
      .join(" AND ")
  }

  useEffect(() => {
    const rangeValue = ["overall"]
    const sendFilters = {
      department: [],
      location: [],
      action_factor: [],
      attack_factor: [],
      overall: [],
      full_name: [],
      manager_name: [],
      filterBy: filters?.filterBy ? filters?.filterBy : [],
    }
    involvedAppliedFilter.forEach((filter) => {
      if (rangeValue.includes(filter.key)) {
        sendFilters[filter.key] = filter.original.split("-")
      } else {
        sendFilters[filter.key]?.push(filter.original)
      }
    })
    setFilters(sendFilters)
  }, [involvedAppliedFilter])

  const { loading, data, pagination } = useRequest(
    ({ current, pageSize, ...params }) =>
      getActionableListOfPeople(entity, {
        filters: getFiltersParam(),
        order_by: orderBy || "human_risk_score desc",
        page_number: current,
        page_size: pageSize,
        ...params,
      }),
    {
      refreshDeps: [orderBy, involvedAppliedFilter, filters],
      debounceInterval: 500,
      paginated: true,
      defaultPageSize: 25,
      formatResult: (response) => {
        if (!response?.data && !response?.data?.results?.length) {
          setShowInsightText({ ...showInsightText, individual_involve: false })
        }
        return {
          total: response?.data?.total_elements || 0,
          list: response?.data?.results || [],
        }
      },
    },
  )

  const handleChangeTable = async (info) => {
    const page = info.pagination.offset / info.pagination.limit + 1
    if (
      pagination.current !== page ||
      pagination.pageSize !== info.pagination.limit
    ) {
      pagination.onChange(page, info.pagination.limit)
    }
  }
  const handleSortedChange = (property, direction) =>
    setOrderBy(`${property} ${direction}`)
  const columns = useMemo(
    () => getIndividualsInvolvedColumns(orderBy, loading),
    [orderBy, loading],
  )
  const setSliderFilter = (selection) => {
    if (!selection.remove) {
      setInvolvedAppliedFilter((oldFilter) => [
        ...oldFilter.filter((fl) => fl.key !== selection.key),
        {
          key: selection.key,
          value: selection.label,
          original: selection.label,
          name: chipLabel[selection?.key] || selection.key,
          humanizedName: getHumanizedName(
            chipLabel[selection?.key] || selection.key,
          ),
        },
      ])
    } else {
      setInvolvedAppliedFilter((oldFilter) =>
        oldFilter.filter((filter) => filter.key !== "overall"),
      )
    }
  }

  const getHumanizedName = (name) =>
    name === "filterBy" ? "Filter By" : humanize(name)

  const singleValueFilters = ["full_name"]
  const setInvolveFilter = (selection, remove = true) => {
    const newValue = selection.value.toLowerCase().replaceAll(" ", "-")
    const existingIndex = involvedAppliedFilter.findIndex(
      (filter) => filter.value === newValue && filter.key === selection.key,
    )
    const existingSingleValueFilter = involvedAppliedFilter.findIndex(
      (filter) => filter.key === selection.key,
    )

    if (selection.key === "overall") {
      setSliderFilter(selection)
    } else if (
      singleValueFilters.includes(selection.key) &&
      existingSingleValueFilter !== -1 &&
      existingIndex === -1
    ) {
      setInvolvedAppliedFilter((oldFilter) => {
        const filter = [...oldFilter]
        filter.splice(existingSingleValueFilter, 1, {
          key: selection.key,
          value: newValue,
          original: selection.value,
          name: chipLabel[selection?.key] || selection.key,
          humanizedName: getHumanizedName(
            chipLabel[selection?.key] || selection.key,
          ),
        })
        return filter
      })
    } else if (existingIndex === -1) {
      setInvolvedAppliedFilter((oldFilter) => [
        ...oldFilter,
        {
          key: selection.key,
          value: newValue,
          original: selection.value,
          name: chipLabel[selection?.key] || selection.key,
          humanizedName: getHumanizedName(
            chipLabel[selection?.key] || selection.key,
          ),
        },
      ])
    } else {
      if (!remove) return
      setInvolvedAppliedFilter((oldFilter) => {
        const filter = [...oldFilter]
        filter.splice(existingIndex, 1)
        return filter
      })
    }
  }
  const clearFilter = () => {
    setInvolvedAppliedFilter([])
    setOrderBy("human_risk_score desc")
  }

  const handleSearch = (value) => {
    setSearchFilter(value)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      value.length > 1
        ? setFilters({
            ...filters,
            filterBy: [value],
          })
        : setFilters({
            ...filters,
            filterBy: [],
          })
    }, 1000)
  }

  const onFilterRemoval = (selection) => {
    selection.key === "overall"
      ? setInvolveFilter({ ...selection, remove: true })
      : setInvolveFilter(selection)
  }

  if (!showInsightText?.individual_involve) {
    return (
      <DragoWrapper>
        <DragosaurWithUmbrella />
      </DragoWrapper>
    )
  }

  const chipLabel = { overall: "human_risk_score" }

  return (
    <div>
      <>
        <FilterCSVWrapper>
          <Toolbar>
            <SearchInput
              value={searchFilter}
              handleSearch={(value) => handleSearch(value)}
              isClearable={true}
              clearSearch={() => handleSearch("")}
              placeholder="Filter by event, full name, data source..."
            />
            <MoreFilter
              filters={involvedAppliedFilter || []}
              _options={InsightListOfPeopleFilterOptions}
              optionParams={involveOptionsParams}
              onSelection={(selection) => {
                setInvolveFilter(selection)
              }}
            />
            {involvedAppliedFilter?.length > 0 && (
              <ClearFilters
                onClick={() => {
                  clearFilter()
                }}
              >
                Clear filters
              </ClearFilters>
            )}
          </Toolbar>
          <ExportData
            total={pagination?.total}
            type="IndividualInvolvedData"
            filters={Object.keys(filters).length ? getFiltersParam() : ""}
            order_by={orderBy || "human_risk_score desc"}
            actions={entity}
          />
        </FilterCSVWrapper>
        <AppliedFilters
          appliedFilters={involvedAppliedFilter}
          handleRemoveFilter={onFilterRemoval}
        />
      </>
      {involvedAppliedFilter.length > 0 && <hr />}
      {loading || (data && !!data.list.length) ? (
        <IndividualTableWrapper columns={columns.length} hasEmail={true}>
          <Table
            resizable
            bodyHeight="calc(100vh - 360px)"
            data={loading ? individualInvolveDummyData : data?.list || []}
            columns={columns}
            onChangeTable={handleChangeTable}
            manual
            totalCount={pagination.total}
            defaultPage={pagination.current}
            defaultPageSize={pagination.pageSize}
            onSortedChange={handleSortedChange}
            pageSizeOptions={[10, 25, 50, 100]}
            excludeSortColumn="event"
          />
        </IndividualTableWrapper>
      ) : (
        <DragoWrapper>
          <DragosaurWithUmbrella
            message="There are no insight available"
            showTooltip={false}
          />
        </DragoWrapper>
      )}
    </div>
  )
}

export default IndividualsInvolved
