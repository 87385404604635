import React from "react"
import { SkeletonLoader } from "@src/utils/skeleton"

function IndividualProfileContextProviderSkeleton() {
  return (
    <div style={{ marginTop: "35px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SkeletonLoader height={40} width={270} />
        <SkeletonLoader width={200} marginTop={25} marginBottom={5} />
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #c9cfd6",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <SkeletonLoader height={170} marginTop={10} />
      </div>
      <div
        style={{
          marginTop: "40px",
          border: "1px solid #c9cfd6",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", height: "140px" }}>
          <div style={{ width: "25%", marginRight: "10px" }}>
            <SkeletonLoader marginRight={10} height={80} />
          </div>
          <div style={{ width: "25%", marginRight: "10px" }}>
            <SkeletonLoader marginRight={10} height={80} />
          </div>
          <div style={{ width: "25%", marginRight: "10px" }}>
            <SkeletonLoader marginRight={10} height={80} />
          </div>
          <div style={{ width: "25%" }}>
            <SkeletonLoader marginRight={10} height={80} />
          </div>
        </div>
        <div
          style={{
            padding: "0 40px",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <SkeletonLoader marginRight={10} height={25} />
          <SkeletonLoader marginRight={10} height={25} />
          <SkeletonLoader marginRight={10} height={25} />
          <SkeletonLoader marginRight={10} height={25} />
          <SkeletonLoader height={25} />
        </div>
        <hr style={{ marginBottom: "20px" }} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SkeletonLoader height={35} width={400} />
            <SkeletonLoader height={30} width={400} />
          </div>
          <div>
            <SkeletonLoader marginTop={20} height={350} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndividualProfileContextProviderSkeleton
