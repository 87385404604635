/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { memo, useRef, useEffect } from "react"
import ReactDOM from "react-dom"
import tippy from "tippy.js"
import { useSelector, useDispatch } from "react-redux"

import { BoxShadow } from "../../styles"
import HistoricalTrend from "@src/components/HistoricalTrend"
import ScoreTooltip from "./components/ScoreTooltip"
import CampaignTooltip from "./components/CampaignTooltip"
import { TrendSummary } from "./components/TrendSummary"
import { getDiffMessage } from "./utils"
import { selectHierarchyScoresMonthlyByBehaviors } from "@src/services/redux/scores/selectors"
import {
  setHierarchicalScoresMonthly,
  setHierarchicalScoresMonthlyByGroups,
  setHierarchicalCampaignsScores,
} from "@src/services/redux/scores/actions"
import { loadHierarchicalCampaignsScoresState } from "../../utils"
import { useIntersection } from "../../useIntersection"
import { useActions } from "@src/utils/redux"
// actions
import * as actions from "@src/services/actions"

function ThirdQuestion() {
  const ref = useRef()
  const inViewport = useIntersection(ref, "0px")
  const dispatch = useDispatch()

  const buildedActions = useActions({
    ...actions,
    setHierarchicalCampaignsScores,
  })

  const behaviorScores = useSelector(selectHierarchyScoresMonthlyByBehaviors)

  const handleViewBy = (viewByState, viewBySelection) => {
    if (!viewBySelection || viewBySelection.length === 0) {
      setHierarchicalScoresMonthly()(dispatch)
    } else {
      const group_type = viewByState.toLowerCase()
      setHierarchicalScoresMonthlyByGroups(
        group_type,
        viewBySelection,
      )(dispatch)
    }
  }

  const handleOnMouseEnter = (info) => {
    const {
      id,
      elementId,
      score,
      score_last_month,
      score_last_quarter,
      behaviourName,
      date,
      typeGraph,
      isCampaignBar,
      campaign_type,
      name,
      status,
      start_date,
      end_date,
      total_targets,
    } = info
    const lastScore =
      typeGraph === "monthly" ? score_last_month : score_last_quarter

    const tooltipId = `${elementId}-tooltip-content`
    const toolTipContent = document.createElement("div")

    toolTipContent.setAttribute("id", tooltipId)
    toolTipContent.style.display = "block"
    toolTipContent.style.minWidth = "900px"

    if (isCampaignBar) {
      ReactDOM.render(
        <CampaignTooltip
          campaignId={id}
          score={score}
          startDate={start_date}
          endDate={end_date}
          type={campaign_type}
          name={name}
          status={status}
          totalTargets={total_targets}
        />,
        toolTipContent,
      )
    } else {
      ReactDOM.render(
        <ScoreTooltip
          score={score}
          month={date}
          trend={lastScore === null ? null : score - lastScore}
          behaviourName={behaviourName}
        />,
        toolTipContent,
      )
    }

    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content: toolTipContent.innerHTML,
      onHide() {
        toolTipContent.remove()
      },
    }

    const element = document.querySelector(`#${elementId}`)
    tippy(element, tippyProps)
    element._tippy.show()
  }

  useEffect(() => {
    if (inViewport) {
      loadHierarchicalCampaignsScoresState({ actions: buildedActions })
    }
  }, [inViewport])

  return (
    <div ref={ref}>
      {getDiffMessage(behaviorScores)}
      {inViewport && (
        <BoxShadow>
          <HistoricalTrend
            behaviorScores={behaviorScores}
            onMouseEnter={handleOnMouseEnter}
            onViewByChange={handleViewBy}
            showViewBy={true}
            showExport={true}
            showTabs={true}
          />
          <TrendSummary />
        </BoxShadow>
      )}
    </div>
  )
}

export default memo(ThirdQuestion)
