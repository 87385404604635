export const PRODUCT_KEY = {
  PULSE: "pulse",
  HM: "hm",
  REFLEX: "reflex",
}

export const FACTOR_DESCRIPTIONS = {
  "Real World Phishing":
    "Actual Phishing refers to actions in response to phishing attempts generated by attackers outside the organization as opposed to internal simulated phishing campaigns.",
  Training:
    "Training identifies further areas for security improvement for individuals at your organization. Training is done to measure success in understanding of good security practices and to meet compliance requirements.",
  "Device Security":
    "Device Security measures who is and is not in compliance in your organization. This includes risky device configurations, VPN usage, unencrypted Wi-Fi usage, unauthorized software, and untrusted active content.",
  Phishing:
    "Simulated Phishing refers to actions in response to phishing campaigns that are generated internally as opposed to by external threat actors.",
  "Sensitive Data Handling":
    "Sensitive Data Handling refers to actions that involve data leaks by individuals at your organization who either accidentally or intentionally disclose company information to unauthorized parties.",
  "Password Manager":
    "Password Manager refers to actions that involve adoption and usage of a password manager by individuals at your organization.",
  "Unauthorized Software":
    "Unauthorized Software refers to actions that involve the download and usage of software that has not been formally approved by the Security Team at your organization.",
  Malware:
    "Malware refers to actions that involve how individuals at your organization introduce and/or respond to malicious software.",
  "Secure Browsing":
    "Secure Browsing refers to actions associated with Internet browsing activity, specifically as it relates to the safety and security risk posed by websites being accessed.",
}

export const getProducts = ({ showHackersmind, showPulse, showReflex }) => [
  ...(showPulse
    ? [
        {
          key: PRODUCT_KEY.PULSE,
          name: "Pulse",
          icon: "ProductPulseColored",
          campaignName: "Pulse Campaign",
        },
      ]
    : []),
  ...(showHackersmind
    ? [
        {
          key: PRODUCT_KEY.HM,
          name: "Hacker’s Mind",
          icon: "ProductHMColored",
          campaignName: "HM Campaign",
        },
      ]
    : []),
  ...(showReflex
    ? [
        {
          key: PRODUCT_KEY.REFLEX,
          name: "Reflex",
          icon: "ProductReflexColored",
          campaignName: "Reflex Campaign",
        },
      ]
    : []),
]

export const tilesData = [
  {
    action: "real-world-phishing",
    details: [
      {
        position: 1,
        title: "Workforce Attacked",
        type: "percentage",
        value: "49.3",
        description:
          "Percentage of your org that has received an actual phishing email within the past 12 months",
      },
      {
        position: 2,
        title: "Workforce Clicked",
        type: "percentage",
        value: "25.1",
        description:
          "Percentage of your org that has clicked an actual phishing email within the past 12 months",
      },
      {
        position: 3,
        title: "Workforce Reported",
        type: "percentage",
        value: "18.1",
        description:
          "Percentage of your org that has reported an actual phishing email within the past 12 months",
      },
      {
        position: 4,
        title: "Phishing Emails Received",
        type: "number",
        value: "17,598",
        description:
          "Number of actual phishing emails received by your org within the past 12 months",
      },
      {
        position: 5,
        title: "Phishing Emails Clicked",
        type: "number",
        value: "3,591",
        description:
          "Number of individuals at your org who have clicked on an actual phishing email within the past 12 months",
      },
      {
        position: 6,
        title: "Phishing Emails Blocked",
        type: "number",
        value: "104,303",
        description:
          "Number of actual phishing emails blocked in the past 12 months",
      },
    ],
  },
  {
    action: "training",
    details: [
      {
        position: 1,
        title: "Training Completed On Time",
        type: "percentage",
        value: "25.1",
        description:
          "Percentage of your org that has completed training on time within the past 12 months",
      },
      {
        position: 2,
        title: "Training Completed Late",
        type: "percentage",
        value: "45.1",
        description:
          "Percentage of your org that has completed training late within the past 12 months",
      },
      {
        position: 3,
        title: "Training Not Completed",
        type: "percentage",
        value: "21.1",
        description:
          "Percentage of your org that has not completed training within the past 12 months",
      },
    ],
  },
  {
    action: "device-security",
    details: [
      {
        position: 1,
        title: "Device Security Compliance",
        type: "percentage",
        value: "11.5",
        description:
          "Percentage of your org that has remained in compliance with the device security policy for the past 12 months",
      },
      {
        position: 2,
        title: "Device Security Non-Compliance",
        type: "percentage",
        value: "32.5",
        description:
          "Percentage of your org that has violated the device security policy at least once within the past 12 months",
      },
      {
        position: 3,
        title: "Device Check-in Currently Overdue",
        type: "percentage",
        value: "9.5",
        description:
          "Number of devices that haven’t checked in as of the last monthly check-in",
      },
    ],
  },
  {
    action: "phishing",
    details: [
      {
        position: 1,
        title: "Phishing Emails Sent",
        type: "number",
        value: "12,234",
        description:
          "Number of simulated phishing emails that were sent within the past 12 months",
      },
      {
        position: 2,
        title: "Workforce Clicked",
        type: "percentage",
        value: "13.5",
        description:
          "Percentage of your org that has clicked on a simulated phishing email within the past 12 months",
      },
      {
        position: 3,
        title: "Workforce Reported",
        type: "percentage",
        value: "3.4",
        description:
          "Percentage of your org that has reported a simulated phishing email within the past 12 months",
      },
      {
        position: 4,
        title: "Individuals Compromised",
        type: "number",
        value: "9,432",
        description:
          "Number of individuals at your org who have submitted data (e.g., credentials) on a page associated with a simulated phishing campaign within the past 12 months",
      },
      {
        position: 5,
        title: "Phishing Emails Clicked",
        type: "number",
        value: "1,326",
        description:
          "Number of individuals at your org who have clicked on a simulated phishing email within the past 12 months",
      },
      {
        position: 6,
        title: "Open Rate",
        type: "percentage",
        value: "19.5",
        description:
          "Percentage of your org that has opened a simulated phishing email within the past 12 months",
      },
    ],
  },
  {
    action: "sensitive-data-handling",
    details: [
      {
        position: 1,
        title: "Sensitive Data Improperly Handled",
        type: "percentage",
        value: "56.9",
        description:
          "Percentage of your org that has improperly handled sensitive data within the past 12 months",
      },
      {
        position: 2,
        title: "Events of Improper Sensitive Data Handling",
        type: "number",
        value: "13,431",
        description:
          "Number of sensitive data handling events that were marked as improperly handled within the past 12 months",
      },
    ],
  },
  {
    action: "password-manager",
    details: [
      {
        position: 1,
        title: "Password Manager Installed",
        type: "percentage",
        value: "16.2",
        description:
          "Percentage of your org that has installed a password manager",
      },
      {
        position: 2,
        title: "MFA Enabled",
        type: "percentage",
        value: "63.9",
        description: "",
      },
      {
        position: 3,
        title: "Recent Usage Rate",
        type: "percentage",
        value: "41.9",
        description:
          "Percentage of your org that has used their password manager within the last 30 days",
      },
    ],
  },
  {
    action: "unauthorized-software",
    details: [
      {
        position: 1,
        title: "Have Unauthorized Software",
        type: "percentage",
        value: "14.7",
        description:
          "Percentage of your org that has been found with unauthorized software within the past 12 months",
      },
      {
        position: 2,
        title: "Unauthorized Software Events",
        type: "number",
        value: "2,568",
        description:
          "Number of unauthorized software events that were found within the past 12 months",
      },
    ],
  },
  {
    action: "malware",
    details: [
      {
        position: 1,
        title: "Malware Downloaded",
        type: "number",
        value: "24,642",
        description:
          "Number of individuals at your org who have downloaded malware within the past 12 months",
      },
      {
        position: 2,
        title: "Malware Executed",
        type: "number",
        value: "9,453",
        description:
          "Number of malware incidents in your org that were able to execute on an individual’s device within in the past 12 months",
      },
      {
        position: 3,
        title: "Malware Blocked",
        type: "number",
        value: "7,940",
        description:
          "Number of malware incidents in your org that were blocked before being downloaded onto an individual’s device within in the past 12 months",
      },
    ],
  },
  {
    action: "secure-browsing",
    details: [
      {
        position: 1,
        title: "Accessed Bad Websites",
        type: "percentage",
        value: "23.6",
        description:
          "Percentage of your org that has violated the secure browsing policy within the past 12 months",
      },
      {
        position: 2,
        title: "Repeat Offenders",
        type: "number",
        value: "77,824",
        description:
          "Number of individuals at your org who have 2+ secure browsing incidents within the past 12 months",
      },
      {
        position: 3,
        title: "Blocked But Continued Anyway",
        type: "number",
        value: "4,532",
        description:
          "Number of individuals at your org who have at least 1 incident within the past 12 months of continuing to browse even after being blocked",
      },
    ],
  },
]

export const MINUTES_IN_A_DAY = 1440
export const MINUTES_IN_AN_HOUR = 60
export const GroupCharColor = ["#0C7D84", "#668CE5", "#AC3ECF"]
