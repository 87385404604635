import React from "react"
import { Input, ComboBox } from "@src/components/MimecastReskin"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { RelativeDateInputWrapper } from "./RelativeDateInput.styles"

export const RelativeDateInput = (props) => {
  const { time_mode_ops, hide_time_unit = false, value, onChange } = props
  const { number = 0, unit = "weeks" } = value

  return (
    <RelativeDateInputWrapper>
      <SpaceBetween direction="row" gap="16px">
        <div>
          <Input
            type="number"
            name="value"
            className="relative-date-input"
            placeholder="0"
            min={0}
            value={number}
            onChange={(e) => {
              onChange({
                unit,
                number: parseInt(e),
              })
            }}
          />
        </div>
        {!hide_time_unit && (
          <SpaceBetween direction="row">
            <ComboBox
              styles={{
                control: (baseStyles) => ({ ...baseStyles, width: "120px" }),
              }}
              data={time_mode_ops}
              value={time_mode_ops?.find((op) => op.value === unit)}
              onChange={(e) => {
                onChange({
                  number,
                  unit: e.value,
                })
              }}
              clearable={false}
              isSearchable={false}
              isMultiOption={false}
              checked={false}
              closeMenuOnSelect
              components={{}}
              disabled={false}
              placeholder="Select duration"
            />
            <div style={{ paddingTop: "10px" }}>
              <strong> AGO </strong>
            </div>
          </SpaceBetween>
        )}
      </SpaceBetween>
    </RelativeDateInputWrapper>
  )
}
