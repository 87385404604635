import styled from "styled-components"

export const Container = styled.div`
  border-left: 1px solid #c9cfd6;
  padding-left: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  hr {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    border-color: #c9cfd6;
  }
`

export const Block = styled.div`
  // height: 45%;
  span,
  h5 {
    color: #34383c;
  }
  .text__green {
    font-weight: bold;
    color: #15785f;
  }
  .text__red {
    font-weight: bold;
    color: #c73434;
  }
  b {
    font-weight: bold;
  }
`

export const TitleRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
  }
`
