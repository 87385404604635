import React from "react"
import { SkeletonLoader } from "@src/utils/skeleton"

import { Row, Col } from "./styles"

export const ActionLogsLoader = (props) => {
  const { rows = 3 } = props
  const totalRows = Array(rows)
    .fill(null)
    .map((_, i) => i)
  return (
    <div>
      {totalRows?.map((item) => (
        <Row key={`action-logs-loader-row-${item}`}>
          <Col width="70%">
            <SkeletonLoader height={20} />
          </Col>
          <Col width="30%">
            <SkeletonLoader height={20} />
          </Col>
        </Row>
      ))}
    </div>
  )
}
