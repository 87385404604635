export const listGroups = (array1) => {
  const array2 = []
  array1.forEach((element) => {
    const individuals = element.members_count > 1 ? `Individuals` : `Individual`
    array2.push({
      label: element.name,
      value: element.id,
      members_count: element.members_count,
      rightLabel: `${element.members_count} ${individuals}`,
    })
  })
  return array2
}
