export function convertTo24Hours(hour, timePeriod) {
  if (timePeriod === "PM") {
    hour = hour === 12 ? hour : hour + 12
  } else if (timePeriod === "AM" && hour === 12) {
    hour = hour + 12
  }
  hour = hour === 24 ? 0 : hour
  return hour
}

export function convertTo12Hours(hour) {
  const value = {
    hour: hour,
    timePeriod: "AM",
  }
  if (hour > 12) {
    value.hour = `${hour % 12}`
    value.timePeriod = "PM"
  }
  return value
}

export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null))
}

export function ordinal_suffix_of(i) {
  const j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return i + "st"
  }
  if (j == 2 && k != 12) {
    return i + "nd"
  }
  if (j == 3 && k != 13) {
    return i + "rd"
  }
  return i + "th"
}

export function getAvailableTimezones() {
  return Intl.supportedValuesOf("timeZone")
}

export function getLocaltimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
