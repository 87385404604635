/* eslint-disable no-shadow */
import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk"
import * as actions from "@src/services/actions"
import ErrorState from "../AdminDashboardContainer/components/ErrorState/ErrorState"

import axios from "@src/services/fetcher"

class ReportsDashboards extends Component {
  constructor() {
    super()
    this.state = {
      error: false,
    }
  }

  componentDidMount() {
    this.callDashboardData()
  }

  componentDidUpdate(prevProps) {
    const { dashboardId } = this.props
    // If Updating with new dashboard type, run callDashboardData function
    if (
      prevProps &&
      prevProps.dashboardId &&
      dashboardId !== prevProps.dashboardId
    ) {
      this.callDashboardData()
    }
  }

  onDashboardLoadError() {
    this.setState({
      error: true,
    })
  }

  callDashboardData() {
    const { dashboardId } = this.props
    const containerDiv = document.getElementById("dashboardContainer")

    if (containerDiv) {
      containerDiv.innerHTML = ""
    }

    axios
      .get(`${REACT_APP_EP_API_URL}/dashboards/${dashboardId}`)
      .then(async (res) => {
        const { data } = res

        const options = {
          url: data.dashboard_url,
          container: "#dashboardContainer",
          width: "100%",
          height: "AutoFit",
          resizeHeightOnSizeChangedEvent: true,
          onChange: (changeEvent) => {
            if (changeEvent.eventLevel === "ERROR") {
              this.onDashboardLoadError()
              return
            }
            switch (changeEvent.eventName) {
              case "FRAME_MOUNTED": {
                break
              }
              case "FRAME_LOADED": {
                break
              }
            }
          },
        }
        const embeddingContext = await createEmbeddingContext()
        const { embedDashboard } = embeddingContext
        await embedDashboard(options, { locale: "en-US" })
      })
      .catch(() => {
        this.onDashboardLoadError()
      })
  }

  render() {
    const { error } = this.state

    return (
      <>
        {!error && <div id="dashboardContainer" />}
        {error && (
          <div className="center-text">
            <ErrorState />
            Please contact support to activate this dashboard.
          </div>
        )}
      </>
    )
  }
}

ReportsDashboards.propTypes = {
  dashboardId: PropTypes.string.isRequired,
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch),
  }
}

// export Company
export default connect(mapStateToProps, mapDispatchToProps)(ReportsDashboards)
