import {
  getDepartmentsByOrganization,
  getLocationsByOrganization,
} from "@src/services/apis/trendSummary"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import { useEffect, useState } from "react"

export const useDepartmensAndLocations = () => {
  const [Departments, setDepartments] = useState([])
  const [Locations, setLocations] = useState([])
  useEffect(() => {
    const fetchDepartments = () => {
      const { id } = getCompanyInfo()
      Promise.all([
        getDepartmentsByOrganization(id).then(({ data }) => data.results),
        getLocationsByOrganization(id).then(({ data }) => data.results),
      ]).then(([departments, locations]) => {
        setDepartments(departments)
        setLocations(locations)
      })
    }
    fetchDepartments()
  }, [])

  return { Departments, Locations }
}
