import React, { useState } from "react"
import { theme, Typography } from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"

import { InlineR3NotificationEditor } from "../../../NotificationEditor/Inline"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"
import { Scheduler } from "../../../Scheduler"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"
import { PersonListAttachmentToggle } from "../PersonListAttachmentToggle"

/*
 * Microsoft teams and slack are identical for now, so we share the bulk of the
 * message-editing logic here. If they ever diverge for whatever reason, favour
 * duplication instead of trying to make this component more generic.
 */
export function EditInstantMessageNotification({
  title,
  templateAttributes,
  action,
  onChange,
  allowPersonListAttachment,
}) {
  const [isScheduleValid, setIsScheduleValid] = useState(true)

  return (
    <SpaceBetween size="sm">
      <div>{title}</div>
      <InlineR3NotificationEditor
        onChange={(e) => {
          onChange("message", e)
        }}
        value={action.message}
        schema={templateAttributes}
        config={{
          customValues: {
            actionName: action.action_type,
          },
        }}
      />
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => {
          onChange("matching_method", e.target.value)
        }}
        options={NOTIFICATION_OPTIONS}
        containerPadding="0"
      />
      {allowPersonListAttachment && (
        <PersonListAttachmentToggle
          checked={action.send_persons_as_attachment}
          onChange={(e) => {
            onChange("send_persons_as_attachment", e.checked)
          }}
        />
      )}
      <div>
        <Typography.H3>Schedule</Typography.H3>
        <Scheduler
          scheduler={action.run_options?.schedule}
          onChange={(value) => {
            onChange("run_options", { schedule: value })
          }}
          isValid={(isValid) => setIsScheduleValid(isValid)}
        />
        {!isScheduleValid && (
          <div style={{ color: theme.text.color.error }}>
            Schedule is invalid
          </div>
        )}
      </div>
    </SpaceBetween>
  )
}
