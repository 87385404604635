import React from "react"
import {
  AppliedFilters as AppliedFiltersComponenet,
  Typography,
  Icon,
  theme,
} from "@elevate_security/elevate-component-library"

import { SpaceBetween } from "@src/components/SpaceBetween"
import {
  RiskResponseRuleFiltersWrapper,
  CaretWrapper,
  FiltersWrapper,
  Separator,
  FilterTitle,
  AppliedFiltersWrapper,
  AppliedFiltersTitleWrapper,
  AppliedFiltersTitle,
  AppliedFiltersClearLink,
} from "./RiskResponseRuleFilters.styles"
import DateRange from "@src/scenes/IndividualProfilePage/components/MoreFilter/DateRange"
import MultiSelectFilter from "@src/scenes/IndividualRiskAnalysis/components/Filters/components/MoreFilter/components/MultiSelectFilter"
import { humanize } from "@src/utils/string"

const LABEL_MAPPING = {
  action_category: "ACTION CATEGORY",
  rule_status: "RULE STATUS",
  criteria_includes: "CRITERIA INCLUDES",
  behaviors: "BEHAVIOR CATEGORY",
  realtime: "REALTIME",
}
export const RiskResponseRuleFilters = ({
  onClose,
  onChange,
  removeFilters,
  clearFilters,
  appliedFilters = [],
  availableFilters = {},
}) => {
  return (
    <RiskResponseRuleFiltersWrapper>
      <div>
        <FiltersWrapper>
          <SpaceBetween direction="row">
            <Typography.H2 fontWeight="heavy" color="700">
              Filters
            </Typography.H2>
            <CaretWrapper onClick={onClose}>
              <Icon name="CaretRight" fill={theme.colors.gray.opaque[500]} />
            </CaretWrapper>
          </SpaceBetween>
        </FiltersWrapper>
        <Separator />
      </div>
      <FiltersWrapper>
        <SpaceBetween justify="flex-start">
          <SpaceBetween justify="flex-start" size="sm">
            {appliedFilters?.length > 0 && (
              <AppliedFiltersWrapper>
                <AppliedFiltersTitleWrapper>
                  <AppliedFiltersTitle>Applied Filters</AppliedFiltersTitle>
                  <AppliedFiltersClearLink onClick={clearFilters}>
                    <span>Clear All</span>
                    <Icon
                      size="xxsm"
                      name="Close"
                      fill={theme.colors.gray.opaque[500]}
                    />
                  </AppliedFiltersClearLink>
                </AppliedFiltersTitleWrapper>
                <AppliedFiltersComponenet
                  appliedFilters={appliedFilters}
                  handleRemoveFilter={removeFilters}
                  chipsFormater={{ updated_datetime: (val) => val }}
                />
              </AppliedFiltersWrapper>
            )}

            {Object.entries(availableFilters)?.map(([key, value]) => {
              if (key === "keys") {
                return <></>
              }
              const section = {
                key: availableFilters?.keys?.[key] || key,
                title: <FilterTitle>{LABEL_MAPPING[key]}</FilterTitle>,
                items: value,
              }
              if (section?.items?.length > 4) {
                section.showMore = `Show all ${humanize(key)}`
                section.showLess = `Show less ${humanize(key)}`
              }
              return (
                <React.Fragment key={key}>
                  <MultiSelectFilter
                    showAllFilters
                    section={section}
                    filters={appliedFilters}
                    handleChange={onChange}
                  />
                  <Separator />
                </React.Fragment>
              )
            })}
            <DateRange
              isClearable={false}
              section={{
                title: <FilterTitle>Last Edited</FilterTitle>,
                type: "date",
                key: "updated_datetime",
              }}
              value={appliedFilters
                ?.find((filter) => filter.key === "updated_datetime")
                ?.value?.split(",")}
              onChange={onChange}
            />
          </SpaceBetween>
        </SpaceBetween>
      </FiltersWrapper>
    </RiskResponseRuleFiltersWrapper>
  )
}
