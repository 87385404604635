// services
import {
  getHierarchicalScores,
  getScoresByIndividuals,
  getEmployeeCount,
  getScoresByDepartment,
  getScoresByLocation,
  getSearchSuggestions,
  getHistoricalScoresByGroupIds,
  getScoreByDynamicEndpoint,
  downloadScoresByInsightData,
  downloadIndividualData,
  legacyDownloadScores,
  downloadScoresByIndividuals,
} from "@src/services/apis/scores"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import {
  ACTION_LOGS_ALL_POLICIES,
  AUDIT_LOGS_ALL_POLICIES,
} from "@src/constants"

// types
import {
  SET_HIERARCHICAL_SCORES_MONTHLY,
  SET_SCORES_BY_INDIVIDUALS,
  SET_SCORES_BY_DEPARTMENT,
  SET_SCORES_BY_LOCATION,
  SET_HIERARCHICAL_CAMPAIGNS_SCORES,
  SET_RISKIEST_DEPARTMENT,
  SET_RECOMMENDED_INDIVIDUALS_FOR_HM,
  SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE,
  SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX,
  SET_TOTAL_INDIVIDUALS_COUNT,
} from "./types"
import { getTrendSummaryCampaigns } from "@src/services/apis/trendSummary"
import { subtractYear, currentDate } from "@src/utils/dates"
import {
  downloadActions,
  downloadDepartment,
  downloadEventsLogFromReputations,
  downloadIndividual,
  downloadLocation,
  getManagerTeam,
  getTeamTrends,
} from "@src/services/apis/reputation"
import { showToast } from "@src/services/redux/toasts/actions"
import {
  getPoliciesActionLogs,
  getPoliciesAuditLogs,
} from "@src/services/apis/policies"

// constants

export function setHierarchicalScoresMonthly() {
  return async (dispatch) => {
    const { shortname: organization_nid } = getCompanyInfo()
    const { error, data } = await getHierarchicalScores({
      organization_nid,
    })
    let items = []
    if (!error) {
      const hasData =
        data &&
        Object.keys(data.hierarchicalScores).length &&
        data.hierarchicalScores.groups
      items = !hasData ? [] : hasData
    }

    dispatch({
      type: SET_HIERARCHICAL_SCORES_MONTHLY,
      data: {
        data: items,
        isLoaded: true,
      },
    })
  }
}

export function setHierarchicalScoresMonthlyByGroups(group_type, group_nids) {
  return async (dispatch) => {
    if (!group_nids || group_nids.length === 0) return
    const { shortname: organization_nid } = getCompanyInfo()
    const { status, data } = await getHistoricalScoresByGroupIds(
      organization_nid,
      group_type,
      group_nids,
    )

    const mapGroups = (group) => (behavior) => ({
      key: behavior,
      scores: {
        score: group.scores.score[behavior],
      },
    })

    let items = []
    if (status === 200) {
      const reducer = (acc, group) => [
        ...acc,
        {
          key: group.key,
          groups: Object.keys(group.scores.score).map(mapGroups(group)),
        },
      ]
      items = data.reduce(reducer, [])
    }

    if (items.length > 0) {
      dispatch({
        type: SET_HIERARCHICAL_SCORES_MONTHLY,
        data: {
          data: items,
          isLoaded: true,
        },
      })
    }
  }
}

export function setScoreByDynamicEndpoint({
  apiUrl = "",
  limit = 25,
  offset = 0,
  ordering = "",
  ...filterProps
}) {
  return async function (dispatch) {
    dispatch({
      type: SET_SCORES_BY_INDIVIDUALS,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await getScoreByDynamicEndpoint({
      apiUrl,
      limit,
      offset,
      ordering,
      ...filterProps,
    })
    dispatch({
      type: SET_SCORES_BY_INDIVIDUALS,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setScoresByIndividuals({
  limit = 25,
  offset = 0,
  ordering = "",
  ...filterProps
}) {
  return async function (dispatch) {
    dispatch({
      type: SET_SCORES_BY_INDIVIDUALS,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await getScoresByIndividuals({
      limit,
      offset,
      ordering,
      ...filterProps,
    })
    dispatch({
      type: SET_SCORES_BY_INDIVIDUALS,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

function exportScores() {
  return {
    department: ({ actions }) => legacyDownloadScores(actions),
    individual: ({ actions }) => downloadScoresByIndividuals(actions),
    location: ({ actions }) => legacyDownloadScores(actions),
  }
}

function exportRiskAnalysis() {
  return {
    [ACTION_LOGS_ALL_POLICIES]: ({ filters, orderBy }) =>
      getPoliciesActionLogs({
        filters,
        order_by: orderBy,
        download: true,
      }),
    [AUDIT_LOGS_ALL_POLICIES]: ({ filters, orderBy }) =>
      getPoliciesAuditLogs({
        filters,
        order_by: orderBy,
        download: true,
      }),
    departments: ({ filters, orderBy }) => downloadDepartment(filters, orderBy),
    individual: ({ filters, orderBy, advancedFilters }) =>
      downloadIndividual(filters, orderBy, advancedFilters),
    locations: ({ filters, orderBy }) => downloadLocation(filters, orderBy),
    InsightData: ({ actions, filters }) =>
      downloadScoresByInsightData(actions, filters),
    IndividualInvolvedData: ({ actions, filters }) =>
      downloadIndividualData(actions, filters),
    actionEventLogsData: ({ filters, orderBy, actions }) =>
      downloadActions(filters, orderBy, actions),
    individualsEventLogs: ({ actions, filters, orderBy }) =>
      downloadEventsLogFromReputations(actions, filters, orderBy),
    teamTrendData: ({ es_person_id, months }) =>
      getTeamTrends({ es_person_id, months, download: true }),
    managerTeamData: ({ order_by, es_person_id, filters }) =>
      getManagerTeam({ order_by, es_person_id, filters, download: true }),
  }
}

export function exportScoresBy(
  type,
  filters = "",
  isDownloading = undefined,
  riskAnalysisFlag = false,
  orderBy = "",
  actions = "",
  trendParams = { months: 12, es_person_id: "" },
  advancedFilters = "",
) {
  return async function (dispatch) {
    isDownloading && isDownloading(true)
    const exportScoresType = riskAnalysisFlag
      ? exportRiskAnalysis()[type]
      : exportScores()[type]
    const { data, error, headers } = await exportScoresType({
      filters,
      orderBy,
      actions,
      es_person_id: trendParams.es_person_id,
      months: trendParams.months,
      advancedFilters,
    })
    if (!error) {
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
      const link = document.createElement("a")
      const fileName =
        (
          headers["content-disposition"] || headers["Content-Disposition"]
        )?.split("filename=")[1] || `${type.toLowerCase()}`
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", fileName)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      dispatch(showToast("Export succeeded!"))
    } else {
      dispatch(showToast("Export failed! Please try again", "error"))
    }
    isDownloading && isDownloading(false)
  }
}

export function setScoresByDepartment({
  limit = 25,
  offset = 0,
  ordering = "department",
}) {
  return async (dispatch) => {
    dispatch({
      type: SET_SCORES_BY_DEPARTMENT,
      data: {
        isLoaded: false,
      },
    })
    const { shortname: organization_nid } = getCompanyInfo()
    const { error, data } = await getScoresByDepartment({
      limit,
      offset,
      ordering,
      organization_nid,
    })
    dispatch({
      type: SET_SCORES_BY_DEPARTMENT,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
    // get riskiest dept from the ascending sorted dataset
    if (offset === 0 && !error && data && data.results.length) {
      dispatch({
        type: SET_RISKIEST_DEPARTMENT,
        data: {
          data: data.results[0],
          isLoaded: true,
        },
      })
    }
  }
}

export function setScoresByLocation({
  limit = 25,
  offset = 0,
  ordering = "location",
}) {
  return async (dispatch) => {
    dispatch({
      type: SET_SCORES_BY_LOCATION,
      data: {
        isLoaded: false,
      },
    })
    const { shortname: organization_nid } = getCompanyInfo()
    const { error, data } = await getScoresByLocation({
      limit,
      offset,
      ordering,
      organization_nid,
    })
    dispatch({
      type: SET_SCORES_BY_LOCATION,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function getSuggestions({ search = null }) {
  return async () => {
    const { error, data } = await getSearchSuggestions({
      search,
    })
    return {
      error,
      data,
    }
  }
}

export function setHierarchicalCampaignsScores({
  start_date = subtractYear({}),
  end_date = currentDate({}),
  limit = 1000,
} = {}) {
  return async (dispatch) => {
    dispatch({
      type: SET_HIERARCHICAL_CAMPAIGNS_SCORES,
      data: {
        isLoaded: false,
      },
    })
    const { shortname: organization_nid } = getCompanyInfo()
    const { error, data } = await getTrendSummaryCampaigns({
      start_date,
      end_date,
      organization_nid,
      limit,
    })
    dispatch({
      type: SET_HIERARCHICAL_CAMPAIGNS_SCORES,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setRecommendedIndividualsForHM({
  limit = 20,
  offset = 0,
  ordering = "",
  ...filterProps
}) {
  return async function (dispatch) {
    dispatch({
      type: SET_RECOMMENDED_INDIVIDUALS_FOR_HM,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await getScoresByIndividuals({
      limit,
      offset,
      ordering,
      ...filterProps,
    })
    dispatch({
      type: SET_RECOMMENDED_INDIVIDUALS_FOR_HM,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setRecommendedIndividualsForPulse({
  limit = 20,
  offset = 0,
  ordering = "",
  ...filterProps
}) {
  return async function (dispatch) {
    dispatch({
      type: SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await getScoresByIndividuals({
      limit,
      offset,
      ordering,
      ...filterProps,
    })
    dispatch({
      type: SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setRecommendedIndividualsForReflex({
  limit = 20,
  offset = 0,
  ordering = "",
  ...filterProps
}) {
  return async function (dispatch) {
    dispatch({
      type: SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await getScoresByIndividuals({
      limit,
      offset,
      ordering,
      ...filterProps,
    })
    dispatch({
      type: SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX,
      data: {
        data: !error ? data : null,
        isLoaded: true,
      },
    })
  }
}

export function setTotalIndividualsCount() {
  return async function (dispatch) {
    dispatch({
      type: SET_TOTAL_INDIVIDUALS_COUNT,
      data: {
        isLoaded: false,
      },
    })

    const { error, data } = await getEmployeeCount({
      limit: 1,
      offset: 0,
    })
    dispatch({
      type: SET_TOTAL_INDIVIDUALS_COUNT,
      data: {
        data: !error ? data.count : null,
        isLoaded: true,
      },
    })
  }
}
