/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { useSelector } from "react-redux"
import EmailReviewModal from "@src/scenes/RiskDetectionRulesScreen/EmailReviewModal"
import { PolicyDetailV2 } from "@src/scenes/RiskDetectionRulesScreen/DropdownCard/PolicyDetail-V2"
import { SpaceBetween } from "@src/components/SpaceBetween"

const ConfirmationPage = ({ selectedPolicy }) => {
  const [selectedItem, setSelectedItem] = useState({})
  const [showEmailModal, setShowEmailModal] = useState(false)

  const handleOnToggleReview = ({ event, chosenItem }) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedItem(chosenItem) // Current action
    setShowEmailModal(true)
  }

  const { ruleName, queryRules } = useSelector((getReducers) =>
    getReducers.get("ruleInfoReducer"),
  )

  return (
    <>
      <EmailReviewModal
        policyId={selectedPolicy?.id}
        item={selectedItem}
        showModal={showEmailModal}
        ruleName={ruleName}
        queryRules={queryRules}
        onClose={() => {
          setShowEmailModal(false)
          setSelectedItem({})
        }}
      />
      <SpaceBetween>
        <div>
          Rule name: <strong>{ruleName}</strong>
        </div>
        <PolicyDetailV2
          mainRuleData={selectedPolicy?.rule?.definition ?? {}}
          policy={selectedPolicy}
          onClick={handleOnToggleReview}
        />
      </SpaceBetween>
    </>
  )
}

export default ConfirmationPage
