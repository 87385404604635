import React, { useState } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as AwesomeIcons from "@fortawesome/free-solid-svg-icons"

// Styled Components
import {
  RecommendationItemsWrapper,
  RecommendationItemCardWrapper,
  RecommendationIconWRAPPER,
  RecommendationParagraphWRAPPER,
  RecommendationCompanyIconWRAPPER,
  RecommendationImpact,
  RecommendationActivateWRAPPER,
  RightArrow,
} from "./styles"
import { IMPACT_BADGE } from "../../constants"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"
import ProductPulseIcon from "@src/scenes/Dashboard/components/Icons/ProductPulseIcon"
import NistIcon from "@src/scenes/Dashboard/components/Icons/NistIcon"
import MitreIcon from "@src/scenes/Dashboard/components/Icons/MitreIcon"
import ConfirmationModal from "@src/scenes/RiskDetectionRulesScreen/ConfirmationModal"
import { markRecommendationActive } from "@src/services/apis/policies"
import { showToast } from "@src/services/redux/toasts/actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { getRiskDetectionRuleUrl } from "@src/scenes/IndividualRiskAnalysis/utils"
import { humanize } from "@src/utils/string"
import { RISK_RESPONSE_RULES } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

const ImpactBadge = ({ impactType, setShowHover }) => {
  const impactBadgeSchema = IMPACT_BADGE[impactType]
  return (
    <RecommendationImpact
      background={impactBadgeSchema?.background}
      color={impactBadgeSchema?.color}
      onMouseEnter={() => setShowHover(false)}
      onMouseLeave={() => setShowHover(true)}
    >
      <Tooltip
        body={impactBadgeSchema.impactTooltip}
        placement="top"
        readOnly
        size="default"
      >
        <b>{impactBadgeSchema.impactText}</b>
      </Tooltip>
    </RecommendationImpact>
  )
}

const SECURITY_FMW_MAP = {
  nist: <NistIcon height="14" />,
  mitre: <MitreIcon height="14" />,
}

const RECOMMENDATION_ICON_DEFAULT_SIZE = "2xl"
const RECOMMENDATION_ICON_DEFAULT_COLOR = "#959DA8"

const generateFontAwesomeIcon = (icon) => (
  <FontAwesomeIcon
    icon={AwesomeIcons[icon]}
    size={RECOMMENDATION_ICON_DEFAULT_SIZE}
    style={{ color: RECOMMENDATION_ICON_DEFAULT_COLOR }}
  />
)

export default function RecommendationItems(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [showEmailPreview, setShowEmailPreview] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState({
    policy: {},
    rules: {},
    recommendation_id: "",
  })
  const [selectedRecommendation, setSelectedRecommendation] = useState({
    recommendation_id: "",
    policy_id: "",
    blurb: "",
  })
  const icons = {
    eyeglass: <Icon name="Search" size="default" stroke="#959DA8" />,
    productpulseicon: <ProductPulseIcon />,
    listplus: generateFontAwesomeIcon("faListCheck"),
    "user-shield": generateFontAwesomeIcon("faUserShield"),
    medal: generateFontAwesomeIcon("faMedal"),
    "arrows-down-to-people": generateFontAwesomeIcon("faArrowsDownToPeople"),
    "users-viewfinder": generateFontAwesomeIcon("faUsersViewfinder"),
  }
  const { actionsList, isRiskInsights, subSectionColor, recommendationsList } =
    props
  const [showHover, setShowHover] = useState(true)
  const [showTabData, setShowTabData] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  const RULE_TITLE = RISK_RESPONSE_RULES.slice(0, -1)

  const getPath = (policyId) =>
    selectedRecommendation?.type === "policy"
      ? getRiskDetectionRuleUrl() + `/edit-rule/${policyId}`
      : "/engagement/vision2/dashboard"

  const renderSecurityFrameworkTooltip = (content) => {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          style={{ gap: "2rem" }}
        />
      </div>
    )
  }

  const filteredActionsList = actionsList.sort(
    (a, b) => b.is_disabled - a.is_disabled,
  )

  return (
    <RecommendationItemsWrapper isRiskInsights={isRiskInsights}>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          showEmailPreview={showEmailPreview}
          setEmailPreview={setShowEmailPreview}
          ruleName={selectedPolicy?.policy?.name}
          itemSelected={selectedPolicy}
          mainRuleData={selectedPolicy?.rules?.definition || []}
          showTabData={showTabData}
          setShowTabData={setShowTabData}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedRecommendation={selectedRecommendation}
          showCreateDraftRule={true}
          onCancel={() => {
            setShowModal(false)
            setShowEmailPreview(false)
            setSelectedTab(0)
            setShowTabData(false)
            setSelectedPolicy({
              policy: {},
              rules: {},
              policy_id: "",
              recommendation_id: "",
            })
          }}
          onClose={async (e, isDraft) => {
            e.stopPropagation()
            const params = {}
            if (isDraft) {
              params.draft = true
            }
            const { error } = await markRecommendationActive(
              selectedPolicy.recommendation_id,
              params,
            )
            if (error) {
              showToast(
                `${humanize(RULE_TITLE)} failed to be created`,
                "error",
              )(dispatch)
            } else {
              showToast(`${humanize(RULE_TITLE)} successfully created!`)(
                dispatch,
              )
              history?.push(getPath(selectedRecommendation.policy_id), {
                isRecommendation: isDraft,
              })
            }
          }}
        />
      )}
      {filteredActionsList.map((data, index) => {
        const rec_data = recommendationsList.find(
          (recommendation) => recommendation.id == data.id,
        )
        const securityFramework = SECURITY_FMW_MAP[data.security_fmw]
        const recoIcon =
          icons[data.iconType] || generateFontAwesomeIcon(data.iconType)
        return (
          <RecommendationItemCardWrapper
            key={data.id}
            disabled={data.is_disabled}
            isRiskInsights={isRiskInsights}
            blurb={rec_data.recommendation_blurb}
            showHover={showHover}
            activated={!data.is_disabled}
            onClick={() => {
              if (data.is_disabled) {
                setShowModal(true)
                setSelectedPolicy({
                  policy: data?.policy,
                  rules: data?.rules,
                  recommendation_id: data?.id,
                  security_fmw: data?.security_fmw,
                  security_fmw_tooltip: data?.security_fmw_tooltip,
                  security_fmw_text: data?.security_fmw_text,
                })
                setSelectedRecommendation({
                  policy_id: data?.policy_id,
                  recommendation_id: data?.id,
                  type: data.type,
                  blurb: rec_data.recommendation_blurb,
                })
              } else {
                // navigate to Elevate Response Engine page with current policy id
                history.push(
                  `/engagement/vision2/risk-response-engine/risk-response-rules?policy-id=${data?.policy_id}`,
                )
              }
            }}
          >
            <RightArrow
              subSectionColor={subSectionColor}
              activated={!data.is_disabled}
            />
            <RecommendationIconWRAPPER>{recoIcon}</RecommendationIconWRAPPER>
            <RecommendationParagraphWRAPPER activated={!data.is_disabled}>
              <span>{data.description}</span>
            </RecommendationParagraphWRAPPER>
            <RecommendationCompanyIconWRAPPER
              onMouseEnter={() => setShowHover(false)}
              onMouseLeave={() => setShowHover(true)}
              security_fmw={rec_data.security_fmw}
              security_fmw_tooltip={rec_data.security_fmw_tooltip}
              security_fmw_text={rec_data.security_fmw_text}
            >
              {data.security_fmw && (
                <Tooltip
                  body={renderSecurityFrameworkTooltip(
                    data.security_fmw_tooltip,
                  )}
                  placement="top"
                  readOnly
                  size="xsm"
                >
                  {securityFramework}
                </Tooltip>
              )}
            </RecommendationCompanyIconWRAPPER>
            {data?.impact && (
              <ImpactBadge
                impactType={data.impact}
                setShowHover={setShowHover}
              />
            )}
            <RecommendationActivateWRAPPER
              data-id={`review-button-${index}`}
              activated={!data.is_disabled}
            >
              {data.action}
            </RecommendationActivateWRAPPER>
          </RecommendationItemCardWrapper>
        )
      })}
    </RecommendationItemsWrapper>
  )
}

RecommendationItems.propTypes = {
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.node,
    }),
  ),
}
