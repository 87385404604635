import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"
import styled from "styled-components"

export const GroupedComboboxWrapper = styled.div`
  width: 250px;
  position: relative;
  background-color: white;
  border-radius: 5px;
  input {
    background-color: transparent;
  }

  > :nth-child(2) {
    z-index: 1000;
  }
`

export const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ccc;
  transition: border-color 125ms ease;
  color: #34383c;
  padding: 8px 16px 8px 8px;
  background-color: transparent;
  cursor: unset;

  & > div {
    width: 90%;
  }

  input {
    width: 100%;
    height: auto;
    font-size: 14px;
    border: 0px;
    padding: 0px;
    cursor: unset;
    border-right: 1px solid #ccc;
    border-radius: 0px;
    z-index: 0;
    &:hover {
      border: 0px;
      border-right: 1px solid #ccc;
    }
    &:focus {
      border: 0px;
      border-right: 1px solid #ccc;
      outline: none;
      box-shadow: none;
    }
  }

  &:focus {
    border: 1px solid ${THEME_PRIMARY} !important;
    box-shadow: 0 0 3px ${THEME_PRIMARY} !important;
  }
  &:hover {
    border: 1px solid ${THEME_HOVER};
  }
`
export const Menu = styled.div`
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #c9cfd6;
  box-shadow: 0px 2px 8px rgba(177, 184, 193, 0.5);
  border-radius: 4px;
  z-index: 1;
  margin-top: 6px;
  &.show {
    visibility: visible;
  }
  &.hide {
    visibility: hidden;
  }
`

export const MenuHeader = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #d9d9d9;
  input {
    padding-left: 32px;
  }
  svg {
    color: #959da8;
    stroke: #959da8;
  }
`

export const MenuList = styled.div`
  width: 100%;
  height: 100%;
  min-height: 160px;
  max-height: 300px;
  overflow: auto;
  cursor: pointer;
`

export const MenuListItem = styled.div`
  width: 100%;
  padding: 8px 16px;
  padding-left: ${(props) => props.nestedLevel * 16}px;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #565d66;
  pointer-events: ${(props) => (props.isHeader ? "none" : "auto")};
  &.header {
    color: #565d66;
    font-weight: 500;
    &:hover {
      background-color: transparent;
    }
  }
  &.group-label {
    color: #565d66;
    font-weight: 500;
    &:hover {
      background-color: transparent;
    }
  }
  &.selected {
    color: ${THEME_PRIMARY};
    background-color: #eef0f3;
  }
  &:hover {
    background-color: #eef0f3;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ""}
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  [class^="Togglestyles__Content"] {
    background-color: #959da8;
  }
  span {
    margin-top: 2px;
  }
`
export const ToggleWrapperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #565d66;
  margin-left: 8px;
`

export const TogglesGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin: 10px 0px;
`
