import styled from "styled-components"
import colors from "@src/utils/colors"

export const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 500px;

  & > div:last-child {
    min-height: 30px;
    position: relative;
    small {
      position: absolute;
      top: 0;
      min-width: 350px;
    }
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
  }
  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
  }
  .upload-btn-wrapper button {
    margin-left: 25px;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  z-index: 100000;
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  span {
    margin-top: 2px;
  }
`

export const TestModeText = styled.div`
  width: 65%;
  margin-top: 20px;
`
export const ValidationDiv = styled.div`
  color: ${colors.error};
`
