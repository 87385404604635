/* eslint-disable no-console */
import store from "@src/services/redux/store"
import { transformHierarchicalDataToChart } from "@src/utils/scores"

export function getState(state = store.getState()) {
  return state.get("visionScoresReducer") || {}
}

export function getHierarchyScoresMonthly() {
  const { hierarchyScoresMonthly } = getState()
  return hierarchyScoresMonthly
}

export function isHierarchyScoresMonthlyLoaded() {
  const { isLoaded } = getHierarchyScoresMonthly()
  return isLoaded
}

export function getSecurityScore() {
  try {
    const { data } = getHierarchyScoresMonthly()
    if (!data.length) return 0
    const lastItem = data[data.length - 1].groups
    const {
      scores: { score },
    } = lastItem.find(({ key }) => key === "overall")
    return score
  } catch (error) {
    console.log(error)
    return 0
  }
}

export function getSecurityScoreFromLastMonth() {
  try {
    const { data } = getHierarchyScoresMonthly()
    if (!data || !data.length || data.length < 2) return 0
    const lastMonth = data[data.length - 2].groups
    const {
      scores: { score },
    } = lastMonth.find(({ key }) => key === "overall")
    return score
  } catch (error) {
    console.log(error)
    return 0
  }
}

export function getScoresByIndividuals(state) {
  const {
    scoresByIndiviuals: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function getScoresByDepartment(state) {
  const {
    scoresByDepartment: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function getRiskiestDepartment(state) {
  const {
    riskiestDepartment: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function getScoresByLocation(state) {
  const {
    scoresByLocation: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function selectHierarchyScoresMonthlyByBehaviors() {
  const { data } = getHierarchyScoresMonthly()
  return transformHierarchicalDataToChart(data)
}

export function selectHierarchicalCampaignsScores(state) {
  const { historicalCampaignsScores } = getState(state)
  if (historicalCampaignsScores?.data?.results) {
    const results = historicalCampaignsScores.data.results
    return results
  }
  return []
}

export function getRecommendedIndividualsForHM(state) {
  const {
    recommendedIndividualsForHM: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function getRecommendedIndividualsForPulse(state) {
  const {
    recommendedIndividualsForPulse: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function getRecommendedIndividualsForReflex(state) {
  const {
    recommendedIndividualsForReflex: { data, isLoaded },
  } = getState(state)
  return {
    data,
    isLoaded,
  }
}

export function getTotalIndividualsCount(state) {
  const {
    totalIndividualsCount: { data, isLoaded },
  } = getState(state)
  return isLoaded ? data : null
}
