import React from "react"
import PropTypes from "prop-types"

const ProductPulseIcon = ({ fill, className, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.500003 18.5192C0.500003 19.0444 0.92578 19.4702 1.451 19.4702H20.549C15.725 19.4702 14.693 16.1462 11.396 10.8722C8.513 6.27318 6.392 1.01118 1.463 0.519178C1.21359 0.501676 0.968143 0.588712 0.785476 0.759429C0.602809 0.930146 0.499389 1.16916 0.500003 1.41918V18.5192Z"
        fill="#DE6900"
      />

      <path
        opacity="0.71"
        d="M19.549 16.4817C19.8033 16.4818 20.0471 16.3799 20.2258 16.199C20.4044 16.018 20.5032 15.773 20.5 15.5187V1.61373C20.4971 1.35667 20.3862 1.11267 20.1945 0.941455C20.0027 0.770236 19.7478 0.687587 19.492 0.713733C15.205 1.15173 12.757 4.44573 10.396 7.88373C7.88201 11.5437 5.42801 15.6027 1.00001 16.3587C0.751013 16.4007 0.487013 16.4337 0.226013 16.4547C0.28755 16.472 0.351099 16.4811 0.415013 16.4817H19.549Z"
        transform="translate(0 ,3)"
        fill="#FFD4B0"
      />
    </svg>
  )
}

export default ProductPulseIcon

ProductPulseIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

ProductPulseIcon.defaultProps = {
  fill: "#0C7D84",
  width: "24",
  height: "24",
  className: "ProductPulse-icon",
}
