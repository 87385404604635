import React from "react"
import Icon from "@src/components/Icon"
import { Tooltip } from "@elevate_security/elevate-component-library"
import PropTypes from "prop-types"
import { DragonPosition, DragoWrapper, IconWrapper } from "./styles"
import { getGlobalConfig } from "@src/globalConfig"

function DragosaurWithUmbrella({
  svgSize,
  paddingTop,
  message,
  tooltip,
  showTooltip,
}) {
  return (
    <DragoWrapper showPointer={showTooltip ? "pointer" : "default"}>
      <DragonPosition paddingTop={`${paddingTop}px`}>
        {getGlobalConfig("ELLIE") && (
          <IconWrapper svgSize={`${svgSize}px`}>
            <Icon name="error" />
          </IconWrapper>
        )}
        {showTooltip ? (
          <Tooltip body={tooltip} placement="bottom" readOnly>
            <span style={{ borderBottom: "1px dotted #7B828A" }}>
              {message}
            </span>
          </Tooltip>
        ) : (
          <span style={{ borderBottom: "1px dotted #7B828A" }}>{message}</span>
        )}
      </DragonPosition>
    </DragoWrapper>
  )
}

export default DragosaurWithUmbrella

DragosaurWithUmbrella.defaultProps = {
  svgSize: 150,
  paddingTop: 60,
  message: "It looks like something went wrong...",
  tooltip:
    "Sorry about that! Our team has been notified of this issue. In the meantime, please try refreshing the page or trying again in a few minutes.",
  showTooltip: true,
}

DragosaurWithUmbrella.propTypes = {
  svgSize: PropTypes.number,
  paddingTop: PropTypes.number,
  message: PropTypes.string,
  tooltip: PropTypes.string,
  showTooltip: PropTypes.bool,
}
