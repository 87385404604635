declare global {
  interface Window {
    navigation: {
      addEventListener: (
        type: "navigate",
        handler: (event: { destination: { url: string } }) => void,
      ) => void
      removeEventListener: (
        type: "navigate",
        handler: (event: { destination: { url: string } }) => void,
      ) => void
    }
  }
}

import { useState, useEffect } from "react"
import { extractRoute } from "./extractRoute"

export function useObserveRouteChanges({
  when = false,
}: { when?: boolean } = {}) {
  const [lastRoute, setLastRoute] = useState<string | null>(null)

  useEffect(() => {
    if (!when) {
      return
    }

    // Navigation API is not available in all browsers, so we check for existence before using
    if (window.navigation) {
      const pathChangeHandler = (event: any) => {
        const url = new URL(event.destination.url)
        if (url.protocol !== "mailto:") {
          setLastRoute(extractRoute(url))
        }
      }
      window.navigation.addEventListener("navigate", pathChangeHandler)
      return () => {
        window.navigation.removeEventListener("navigate", pathChangeHandler)
      }
    } else {
      // This is perverse and I hate it, but it works well for now.
      const interval = setInterval(() => {
        // Needs to be window.location since react-router's useLocation won't
        // update the location object for external route changes
        setLastRoute(extractRoute(window.location))
      }, 500)
      return () => {
        clearInterval(interval)
      }
    }
  }, [when])

  return lastRoute
}
