import { isEmpty } from "@src/utils/string"

export function filterCriteriaApplicationIds(rules, ids = []) {
  for (let i = 0; i < rules.length; i++) {
    if (rules[i]?.rules?.length > 0) {
      ids = filterCriteriaApplicationIds(rules[i].rules, ids)
    } else if (rules[i].category === "applications") {
      if (!rules[i]?.compound_conditions) {
        ids = [...ids, ...(isEmpty(rules[i].value) ? [] : [rules[i].value[0]])]
      } else if (rules[i]?.compound_conditions?.length > 0) {
        const gIds = rules[i]?.compound_conditions?.map(
          (condition) => condition.value[0],
        )
        ids = [...ids, ...gIds]
      }
    }
  }
  return ids
}
