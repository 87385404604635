declare const APP_ENV: string

import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const isInitialized = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use({
    // Much handier than http back-end since we don't have to worry about CORS
    // here -- webpack can load JS modules by inlining script tags.
    // See https://github.com/i18next/i18next/discussions/1611
    type: "backend",
    read(
      language: string,
      namespace: string,
      callback: (err: Error | null, resources?: any) => void,
    ) {
      import(`./locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources)
        })
        .catch((err) => {
          callback(err)
        })
    },
  })
  .init({
    debug: APP_ENV === "local",
    fallbackLng: "en-US",
    ns: [
      "translation",
      "dashboard",
      "riskAnalysis",
      "individualRiskProfile",
      "watchlists",
      "actionLogs",
    ],
    defaultNS: "translation",
  })

// Useful for the few cases where we are loading outside the React context
export function waitForI18n(): Promise<{ t: typeof i18next.t }> {
  return isInitialized.then(() => ({ t: i18next.t.bind(i18next) }))
}
