/* eslint-disable no-undef */
// TODO: We should declare those variables in a .env, right now are global
import { INDIVIDUALS_COLUMNS } from "@src/scenes/SecEngIndividualRiskAnalysis/constants"

export const IS_DEMO_APP = () => window.isDemoApp || false

export const BASES_REFLEX_API_URL = REACT_APP_REFLEX_API_URL
export const BASES_REPUTATIONS_API_URL = REACT_APP_REPUTATION_API_URL

export const BASE_SCORES_API_URL = () =>
  IS_DEMO_APP() ? REACT_APP_SCORES_API_URL_DEMO : REACT_APP_SCORES_API_URL

export const BASE_APP_SCORE_API_URL = () => REACT_APP_SCORE_API_URL
export const BASE_DIRECTORY_API_URL = () =>
  IS_DEMO_APP() ? REACT_APP_DIRECTORY_API_URL_DEMO : REACT_APP_DIRECTORY_API_URL

export const BASE_EP_API_URL = () => REACT_APP_EP_API_URL

export const BASE_PULSE_API_URL = () => REACT_APP_PULSE_API_URL

export const actionAnalysisDummyData = []
for (let n = 0; n < 18; n++) {
  actionAnalysisDummyData.push({
    name: "",
    department: "",
    manager_name: "",
    number_of_people: "",
    overall: "",
    training: "",
    "clean-desk": "",
    malware: "",
    phishing: "",
    "real-world-phishing": "",
    "secure-browsing": "",
    "password-manager": "",
    "optional-activities": "",
    "unauthorized-software": "",
    "device-security": "",
    "sensitive-data-handling": "",
    "physical-security": "",
  })
}

export const RISK_DATA_TYPES = {
  detections: {
    orderBy: "event_datetime desc",
    filterBy: "",
    defaultOrderBy: "event_datetime desc",
    searchInputPlaceholder: "Filter by full name or application",
    showTableOptions: false,
  },
  individual: {
    name: "Individuals",
    dropDownOption: {
      Risk: "risk-analysis",
      Action: "action-analysis",
    },
    showDropDown: true,
    option: "Action",
    columnKeyPrefix: "risks.",
    firstColumn: {
      key: "full_name",
      header: "Full Name",
      checkbox_key: "es_person_id",
    },
    actionFirstColumn: {
      key: "name",
      header: "FULL NAME",
      sortProperty: "first_name",
    },
    orderBy: "human_risk_score desc",
    filterBy: "full_name",
    showNewDesign: true,
    ApiUrl: `${BASES_REPUTATIONS_API_URL}/analysis/risks`,
    ActionApiUrl: `${BASES_REPUTATIONS_API_URL}/analysis/risks`,
    defaultOrderBy: "human_risk_score desc",
    columns: INDIVIDUALS_COLUMNS,
    actionColumns: [
      {
        key: "department",
        header: "department",
        sortProperty: "department",
      },
      {
        key: "manager_name",
        header: "MANAGER",
        sortProperty: "manager__first_name",
      },
    ],
    searchInputPlaceholder: "Search",
    showTableOptions: true,
  },
  departments: {
    name: "Departments",
    dropDownOption: {
      Risk: "risk-analysis",
      Action: "action-analysis",
    },
    showDropDown: false,
    option: "Action",
    columnKeyPrefix: "risks.",
    extraRiskNames: ["number_of_individuals"],
    firstColumn: {
      key: "departments",
      header: "Department",
      checkbox_key: "departments",
    },
    orderBy: "",
    filterBy: "department",
    showNewDesign: true,
    ApiUrl: `${BASES_REPUTATIONS_API_URL}/analysis/departments/risks`,
    ActionApiUrl: `${BASE_APP_SCORE_API_URL()}/action/department`,
    showNumberOfPeopleKey: "number_of_people",
    defaultOrderBy: "human_risk_score desc",
    columns: [],
    actionFirstColumn: {
      key: "department",
      header: "department",
      sortProperty: "department",
    },
    searchInputPlaceholder: "Filter by department name",
    showTableOptions: true,
  },
  locations: {
    name: "Locations",
    dropDownOption: {
      Risk: "risk-analysis",
      Action: "action-analysis",
    },
    showDropDown: false,
    option: "Action",
    columnKeyPrefix: "risks.",
    extraRiskNames: ["number_of_individuals"],
    firstColumn: {
      key: "locations",
      header: "Location",
      checkbox_key: "locations",
    },
    actionFirstColumn: {
      key: "location",
      header: "location",
      sortProperty: "location",
    },
    orderBy: "",
    filterBy: "location",
    showNewDesign: true,
    ApiUrl: `${BASES_REPUTATIONS_API_URL}/analysis/locations/risks`,
    ActionApiUrl: `${BASE_APP_SCORE_API_URL()}/action/location`,
    showNumberOfPeopleKey: "number_of_people",
    defaultOrderBy: "human_risk_score desc",
    columns: [],
    searchInputPlaceholder: "Filter by location name",
    showTableOptions: true,
  },
}

export const RISK_ANALYSIS_INSIGHTS_URL = "/engagement/vision2/insights/"

export const RISK_ANALYSIS_URL = {
  individual: "/engagement/vision2/insights/individual/risk-analysis",
  departments: "/engagement/vision2/insights/departments/risk-analysis",
  locations: "/engagement/vision2/insights/locations/risk-analysis",
}

export const RISK_ANALYSIS_URL_BUILDER = (riskType, actionType) =>
  `/engagement/vision2/insights/${riskType}/${actionType}`
export const RISK_DETECTION_RULES_URL =
  "/engagement/vision2/risk-response-engine/risk-response-rules"

export const SCORE_COLORS = [
  { color: "#D23A54", min: 0, max: 20, name: "Very High" }, // Flimsy
  { color: "#F08526", min: 21, max: 40, name: "High" }, // Tenuous
  { color: "#FACC38", min: 41, max: 60, name: "Medium" }, // Sturdy
  { color: "#3CC1AE", min: 61, max: 80, name: "Low" }, // Fortified
  { color: "#2C9097", min: 81, max: 100, name: "Very Low" },
]

export const SCORE_MESSAGES = [
  { min: 0, max: 20, message: "Your org is very risky 😨" },
  { min: 21, max: 39, message: "Your org is vulnerable" },
  {
    min: 40,
    max: 69,
    message: "Your org has room for improvement",
  },
  {
    min: 70,
    max: 89,
    message: "Your org is in decent shape 👍",
  },
  { min: 90, max: 99, message: "Your org is solid 👍" },
  {
    min: 100,
    max: 100,
    message: "High-five! Your org is exceptional 🎉",
  },
]

export const OFFERING_PLANS = {
  ASSESS: "assess",
  MONITOR: "monitor",
  PROTECT: "protect",
}

export const ASSESS_UI_STATES = {
  INTEGRATION: "integration",
  INGESTING: "ingesting",
  ASSESSED: "assessed",
}

export const ACTION_LOGS_ALL_POLICIES = "action_logs_all_policies"
export const ACTION_LOGS = "Action Logs"

export const AUDIT_LOGS_ALL_POLICIES = "audit_logs_all_policies"
export const AUDIT_LOGS = "Audit Logs"

export const SEARCH_PLACEHOLDERS = {
  Applications: "Search by application name",
  [ACTION_LOGS]: "Search by name, state and execution ID",
  [AUDIT_LOGS]:
    "Search by event description, event type, rule name, rule state and user email",
}

export const LOGS_PAGE_EXPORT_TYPE = {
  [ACTION_LOGS]: ACTION_LOGS_ALL_POLICIES,
  [AUDIT_LOGS]: AUDIT_LOGS_ALL_POLICIES,
}
