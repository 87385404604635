import {
  CustomEmailTemplate,
  IndividualsEmailTemplate,
  ManagersEmailTemplate,
  ParsedCustomsEmailTemplate,
  ParsedIndividualsEmailTemplate,
  ParsedManagersEmailTemplate,
} from "./EmailTemplates"
import {
  faUserPlus,
  faUsers,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons"
import {
  EXCLUDE_USERS_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
  INCLUDE_USERS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

export const PAGE_HEADER = [
  {
    label: "Basic Info",
    pNum: 0,
  },
  {
    label: "Criteria",
    pNum: 1,
  },
  {
    label: "Actions",
    pNum: 2,
  },
  {
    label: "Summary",
    pNum: 3,
  },
]

export const POLICY_GROUP = "policy_group"
export const CHANGE_SYSTEM_GROUP = "change_system_group_membership"
export const OUTBOUND_WEBHOOK = "send_outbound_webhook_json_data"
export const SERVICE_NOW = "open_service_now_ticket"
export const SEND_SPLUNK_SYSLOG = "send_splunk_system_log"
export const SEND_VIA_WEB_HOOK = "sendViaWebhook"
export const CUSTOM = "custom"
export const INDIVIDUALS = "individuals"
export const MANAGERS = "managers"
export const CHANNELS = "channels"
export const SEND_EMAIL = "send_email"
export const SEND_MICROSOFT_TEAMS = "send_microsoft_teams"
export const SEND_SLACK = "send_slack"

/* Action Types */

// Email
export const SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS =
  "send_email_notification_to_individuals"
export const SEND_EMAIL_NOTIFICATION_TO_MANAGERS =
  "send_email_notification_to_managers"
export const SEND_EMAIL_NOTIFICATION_TO_CUSTOM =
  "send_email_notification_to_custom"

// Microsoft teams
export const SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS =
  "send_microsoft_teams_notification_to_individuals"
export const SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS =
  "send_microsoft_teams_notification_to_managers"
export const SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CHANNELS =
  "send_microsoft_teams_notification_to_channels"
export const SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM =
  "send_microsoft_teams_notification_to_custom"

// Slack
export const SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS =
  "send_slack_notification_to_individuals"
export const SEND_SLACK_NOTIFICATION_TO_MANAGERS =
  "send_slack_notification_to_managers"
export const SEND_SLACK_NOTIFICATION_TO_CHANNELS =
  "send_slack_notification_to_channels"
export const SEND_SLACK_NOTIFICATION_TO_CUSTOM =
  "send_slack_notification_to_custom"

const CSV_ATTACHMENT_TEXT = "Include Individuals List as CSV attachment"

export const EMAIL_INITIAL_STATE = [
  {
    label: "Individuals who meet the criteria",
    actionName: SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
    value: INDIVIDUALS,
    isShown: false,
    title: `{{people.first_name}}, your risk profile has changed`,
    titleIn64:
      "e3twZW9wbGUuZmlyc3RfbmFtZX19LCB5b3VyIHJpc2sgcHJvZmlsZSBoYXMgY2hhbmdlZA==",
    component: IndividualsEmailTemplate,
    templateIn64: ParsedIndividualsEmailTemplate,
    templateId: undefined,
    matching_method: "delta",
  },
  {
    label: "Managers of individuals who meet the criteria",
    actionName: SEND_EMAIL_NOTIFICATION_TO_MANAGERS,
    value: MANAGERS,
    isShown: false,
    title: `{{manager.manager_first_name}}, a member of your team has taken risky security actions`,
    titleIn64:
      "e3ttYW5hZ2VyLm1hbmFnZXJfZmlyc3RfbmFtZX19LCBhIG1lbWJlciBvZiB5b3VyIHRlYW0gaGFzIHRha2VuIHJpc2t5IHNlY3VyaXR5IGFjdGlvbnM=",
    component: ManagersEmailTemplate,
    templateIn64: ParsedManagersEmailTemplate,
    templateId: undefined,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: true,
    matching_method: "delta",
  },
  {
    label: "Customized List",
    actionName: SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
    value: CUSTOM,
    isShown: false,
    title: `{{rule.name}} risk response rule has been triggered`,
    titleIn64:
      "e3tyZHIubmFtZX19IHJpc2sgZGV0ZWN0aW9uIHJ1bGUgaGFzIGJlZW4gdHJpZ2dlcmVk",
    component: CustomEmailTemplate,
    templateIn64: ParsedCustomsEmailTemplate,
    templateId: undefined,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: false,
    matching_method: "delta",
  },
]

export const MICROSOFT_TEAMS_NOTIFICATION_STATE = [
  {
    label: "Individuals who meet the criteria",
    actionName: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS,
    isShown: false,
    value: INDIVIDUALS,
    message:
      "Hi {{people.first_name}} {{people.last_name}}! We'd like to update you that your risk posture has changed.",
    placeHolder:
      "Hi {{people.first_name}} {{people.last_name}}! We'd like to update you that your risk posture has changed.",
    error: false,
    matching_method: "delta",
  },
  {
    label: "Managers of individuals who meet the criteria",
    actionName: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_MANAGERS,
    isShown: false,
    value: MANAGERS,
    message:
      "Hi {{manager.manager_first_name}} {{manager.manager_last_name}}! We'd like to update you that {{manager.affected_direct_reports_count}} members of your direct reports were targeted by the risk response rule {{rule.name}}.",
    placeHolder:
      "Hi {{manager.manager_first_name}} {{manager.manager_last_name}}! We'd like to update you that {{manager.affected_direct_reports_count}} members of your direct reports were targeted by the risk response rule {{rule.name}}.",
    error: false,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: true,
    matching_method: "delta",
  },
  {
    label: "Channels",
    actionName: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CHANNELS,
    isShown: false,
    value: CHANNELS,
    message:
      "Hi {{channel.name}}! Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    placeHolder:
      "Hi {{channel.name}}! Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    error: false,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: true,
    matching_method: "delta",
  },
  {
    label: "Customized List",
    actionName: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM,
    isShown: false,
    value: CUSTOM,
    message: "Elevate rule {{rule.name}} activated.",
    emailList: [],
    emailPlaceHolder: "Search...",
    placeHolder: "Elevate rule {{rule.name}} activated.",
    emailError: false,
    error: false,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: false,
    matching_method: "delta",
  },
]

export const EDIT_EMAIL_STATE = {
  show: false,
  index: null,
}

export const rightLabels = (groups) => {
  const MAX_GROUPS_SHOW = 2
  if (groups.length > MAX_GROUPS_SHOW) {
    return `Belongs to ${groups.slice(0, MAX_GROUPS_SHOW).join(", ")} + ${
      groups.length - MAX_GROUPS_SHOW
    } more`
  }
  if (groups.length) {
    return `Belongs to ${groups.join(", ")}`
  }
}

export const SLACK_NOTIFICATION_STATE = [
  {
    label: "Individuals who meet the criteria",
    actionName: SEND_SLACK_NOTIFICATION_TO_INDIVIDUALS,
    isShown: false,
    value: INDIVIDUALS,
    message:
      "Hi {{people.first_name}} {{people.last_name}}! We'd like to update you that your risk posture has changed.",
    placeHolder:
      "Hi {{people.first_name}} {{people.last_name}}! We'd like to update you that your risk posture has changed.",
    error: false,
    matching_method: "delta",
  },
  {
    label: "Managers of individuals who meet the criteria",
    actionName: SEND_SLACK_NOTIFICATION_TO_MANAGERS,
    isShown: false,
    value: MANAGERS,
    message:
      "Hi {{manager.manager_first_name}} {{manager.manager_last_name}}! We'd like to update you that {{manager.affected_direct_reports_count}} members of your direct reports were targeted by the risk response rule {{rule.name}}.",
    placeHolder:
      "Hi {{manager.manager_first_name}} {{manager.manager_last_name}}! We'd like to update you that {{manager.affected_direct_reports_count}} members of your direct reports were targeted by the risk response rule {{rule.name}}.",
    error: false,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: true,
    matching_method: "delta",
  },
  {
    label: "Channels",
    actionName: SEND_SLACK_NOTIFICATION_TO_CHANNELS,
    isShown: false,
    value: CHANNELS,
    message:
      "Hi {{channel.name}}! Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    placeHolder:
      "Hi {{channel.name}}! Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    error: false,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: false,
    matching_method: "delta",
  },
  {
    label: "Customized List",
    actionName: SEND_SLACK_NOTIFICATION_TO_CUSTOM,
    isShown: false,
    value: CUSTOM,
    message:
      "Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    placeHolder:
      "Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    error: false,
    CsvAttachmentText: CSV_ATTACHMENT_TEXT,
    send_persons_as_attachment: false,
    matching_method: "delta",
  },
]

//This is used for the NotificationRadioButtonGroup
export const NOTIFICATION_OPTIONS = [
  {
    value: "delta",
    label: "Only net new individuals matched",
    icon: faUserPlus,
  },
  {
    value: "snapshot",
    label: "Every individual matched",
    icon: faUsers,
  },
]

export const ADD_REMOVE_NOTIFICATION_OPTIONS = [
  {
    value: INCLUDE_USERS_IN_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
    label: "Include Users in Policies",
    icon: faUserPlus,
  },
  {
    value: EXCLUDE_USERS_FROM_AZURE_AD_CONDITIONAL_ACCESS_POLICY,
    label: "Exclude Users from Policies",
    icon: faUserMinus,
  },
]
export const DELTA = "delta"

export const SAIL_POINT_WORKFLOW = "execute_sailpoint_workflow"
export const MATCHING_METHOD = "matching_method"
export const WORKFLOW_ID = "workflow_id"
export const CLIENT_ID = "client_id"
export const CLIENT_SECRET = "client_secret"

export const ICON_TYPE = {
  false: "Eye",
  true: "EyeClose",
}

export const INPUT_TYPE = {
  false: "password",
  true: "text",
}
