import styled from "styled-components"

export const FlagRibbonWrapper = styled.div`
  width: max-content;
  padding: 4px 10px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  height: max-content;
  margin-left: 10px;
  margin-right: 32px;
  margin-bottom: 0;
  border-radius: 5px;
  cursor: default;
  background-color: #eef0f3;
  color: #565d66;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
`
