import styled from "styled-components"

export const EventOccurrenceItemWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 5px;
  align-items: center;
  color: #34383c;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  .event-occurance-input {
    width: 80px;
    border-radius: 6px;
  }
  .event-occurance-time-mode {
    width: 200px;
    border-radius: 6px;
  }
  [class*="inputstyles"] {
    z-index: 0;
  }
`
