import React, { useEffect, useMemo, useState } from "react"
import { usePrevious } from "ahooks"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { setAdvancedFilters } from "@src/services/redux/analysisIndividuals/actions"

import { IndividualRiskAnalysisTable } from "@src/scenes/SecEngIndividualRiskAnalysis/components/IndividualRiskAnalysisTable"
import CriteriaResultsActions from "../CriteriaResultsActions"
import { validateCriteria } from "../../ConfigureInputCriteriaPage/utils"
import { CriteriaResultsWrapper } from "./styles"
import { Button } from "@src/components/MimecastReskin"
import { THEME_PRIMARY } from "@src/theme"

const CriteriaResults = ({ queryRules }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isCriteriaValid = useMemo(
    () => validateCriteria(queryRules),
    [queryRules],
  )

  const previousQueryRules = usePrevious(queryRules)

  const [shouldPreview, setShouldPreview] = useState(false)

  const prevShouldPreview = usePrevious(shouldPreview)

  useEffect(() => {
    if (!prevShouldPreview && shouldPreview) {
      dispatch(setAdvancedFilters(structuredClone(queryRules)))
    }
  }, [prevShouldPreview, shouldPreview])

  useEffect(() => {
    if (!isCriteriaValid) {
      setShouldPreview(false)
    }
  }, [isCriteriaValid])

  if (!isCriteriaValid) {
    return null
  }

  return (
    <CriteriaResultsWrapper shouldPreview={shouldPreview}>
      <hr />
      {!shouldPreview && (
        <Button theme="secondary" onClick={() => setShouldPreview(true)}>
          <Icon name="Eye" fill={THEME_PRIMARY} />
          &nbsp;&nbsp;
          <span>Preview Results</span>
        </Button>
      )}
      {shouldPreview && (
        <>
          <CriteriaResultsActions
            setShouldPreview={setShouldPreview}
            queryRules={queryRules}
            canRefresh={queryRules !== previousQueryRules}
          />
          <IndividualRiskAnalysisTable
            history={history}
            showFiltersToolbar={false}
            riskType="individual"
          />
        </>
      )}
    </CriteriaResultsWrapper>
  )
}
CriteriaResults.propTypes = {
  queryRules: PropTypes.array.isRequired,
}

export default CriteriaResults
