import { useEffect, useState } from "react"
import { getNumberStragglers } from "./utils"

const initialState = {
  Individual: [0, 0],
  Location: [0, 0],
  Department: [0, 0],
}

export const useNumberOfStragglers = (shouldTrigger = false) => {
  const [stragglers, setStragglers] = useState(initialState)

  useEffect(() => {
    async function getStragglers() {
      const stragglers = await getNumberStragglers()
      setStragglers(stragglers)
    }
    if (shouldTrigger) {
      getStragglers()
    }
  }, [shouldTrigger])

  return stragglers
}
