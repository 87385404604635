import React, { useRef, useEffect, useMemo, useCallback } from "react"
import { RiskResponseRulesHeader } from "./RiskResponseRulesHeader"
import { RiskResponseRuleSaveButtons } from "./RiskResponseRuleSaveButtons"
import { RiskResponseRuleBody } from "./RiskResponseRuleBody"
import { useHistory } from "react-router-dom"
import { useRiskResponseRuleState } from "./useRiskResponseRuleState"
import Modal from "@elevate_security/elevate-component-library/dist/Modal"
import {
  CreateRiskResponseRuleWrapper,
  CreateRiskResponseRuleBodyWrapper,
  CreateRiskResponseRuleButtonsWrapper,
  CreateRiskResponseRuleHeaderWrapper,
  HeaderButtonsWrapper,
  ModalWrapper,
} from "./styles"
import { Header, Typography } from "@elevate_security/elevate-component-library"
import { setIsRuleMalformed } from "@src/services/redux/policies/actions"
import { useDispatch } from "react-redux"
import { Button } from "@src/components/MimecastReskin"

const { H2 } = Typography

export const CreateRiskResponseRule = ({
  selectedPolicy,
  editFlag,
  onFinished,
  setIsRecommendation,
  isRuleMalformed,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const bodyRef = useRef(null)

  const {
    pageNum,
    isBasicInfoStep,
    isInputCriteriaStep,
    isActionsStep,
    isSummaryStep,
    setPageNum,
    handleClickSaveAsDraft,
    ruleName,
    handleSubmitOnCriteria,
    handleSubmitOnBasicInfo,
    handleSubmitOnOutcome,
    saveAndActivateInTest,
    saveAndActivate,
    selectedRule,
    isLoading,
    isPolicyValid,
    actionOperations,
    isEditingAction,
    actionsSummaryList,
  } = useRiskResponseRuleState({
    editFlag,
    selectedPolicy,
    setIsRecommendation,
    onFinished,
  })

  const scrollToStep = useCallback((step) => {
    const stepNum = { actions: 2 }[step] ?? step
    const scrollSpy = document.getElementById(`r3-scrollspy-${stepNum}`)
    bodyRef?.current?.scrollTo({
      top: scrollSpy.offsetTop - scrollSpy.parentElement.offsetTop,
      behavior: "smooth",
    })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      scrollToStep(pageNum)
    }
  }, [pageNum, isLoading, scrollToStep])

  const isSaveAndContinueDisabled =
    (isBasicInfoStep && !ruleName) || (isActionsStep && isEditingAction)

  const onSaveAndContinue = async ({ nextPage = pageNum + 1 }) => {
    let shouldContinue = true

    // Always allow jumping to an earlier step when creating
    if (editFlag || nextPage >= pageNum) {
      if (isBasicInfoStep) {
        shouldContinue = await handleSubmitOnBasicInfo()
        setIsRecommendation(false)
      } else if (isInputCriteriaStep) {
        shouldContinue = await handleSubmitOnCriteria()
        setIsRecommendation(false)
      } else if (isActionsStep) {
        shouldContinue = await handleSubmitOnOutcome()
      }
    }

    if (shouldContinue) {
      setPageNum(nextPage)
    }
  }

  const SaveButtons = (props) => {
    return (
      <CreateRiskResponseRuleButtonsWrapper>
        <RiskResponseRuleSaveButtons
          editFlag={editFlag}
          onCancel={onFinished}
          isInputCriteriaStep={isInputCriteriaStep}
          isBasicInfoStep={isBasicInfoStep}
          isActionsStep={isActionsStep}
          isSummaryStep={isSummaryStep}
          onSaveDraft={handleClickSaveAsDraft}
          disableSaveAsDraft={!ruleName || (isActionsStep && isEditingAction)}
          onSave={onSaveAndContinue}
          onActivateInTest={saveAndActivateInTest}
          onActivate={saveAndActivate}
          isSaveAndContinueDisabled={isSaveAndContinueDisabled}
          isPolicyValid={isPolicyValid}
          {...props}
        />
      </CreateRiskResponseRuleButtonsWrapper>
    )
  }

  const navigateToRuleList = () => {
    dispatch(setIsRuleMalformed(false))
    history.push(`/engagement/vision2/risk-response-engine/risk-response-rules`)
  }

  const renderButtons = useMemo(
    () => [
      // eslint-disable-next-line react/jsx-key
      <Button theme="ghost" onClick={navigateToRuleList}>
        Cancel
      </Button>,
      // eslint-disable-next-line react/jsx-key
      <Button onClick={() => dispatch(setIsRuleMalformed(false))}>Yes</Button>,
    ],
    [],
  )

  return (
    <CreateRiskResponseRuleWrapper>
      <CreateRiskResponseRuleHeaderWrapper>
        <RiskResponseRulesHeader
          pageNum={pageNum}
          editFlag={editFlag}
          onSaveAndContinue={onSaveAndContinue}
          isSaveAndContinueDisabled={isSaveAndContinueDisabled}
        />
        <HeaderButtonsWrapper>
          <SaveButtons />
        </HeaderButtonsWrapper>
      </CreateRiskResponseRuleHeaderWrapper>
      {isRuleMalformed ? (
        <ModalWrapper>
          <Modal
            onClose={navigateToRuleList}
            header={
              <Header>
                <H2 color="900" style={{ marginBottom: "0px" }}>
                  Reset Rule Criteria
                </H2>
              </Header>
            }
            style={{ maxWidth: "648px" }}
            rightButtons={renderButtons}
            isOpen={isRuleMalformed}
          >
            This rule has a <strong>malformed</strong> criteria and cannot be
            read. Would you like to <strong>reset</strong> the criteria and
            continue?
          </Modal>
        </ModalWrapper>
      ) : (
        <CreateRiskResponseRuleBodyWrapper ref={bodyRef}>
          <RiskResponseRuleBody
            scrollToStep={scrollToStep}
            actionOperations={actionOperations}
            saveButtons={
              <SaveButtons showCancel={false} showSaveAsDraft={isSummaryStep} />
            }
            totalHeight={bodyRef.current?.offsetHeight || 0}
            pageNum={pageNum}
            isActionsStep={isActionsStep}
            selectedPolicy={selectedPolicy}
            selectedRule={selectedRule}
            isLoadingCriteria={isLoading}
            onSaveAndContinue={onSaveAndContinue}
            isSaveAndContinueDisabled={isSaveAndContinueDisabled}
            editFlag={editFlag}
            actionsSummaryList={actionsSummaryList}
          />
        </CreateRiskResponseRuleBodyWrapper>
      )}
    </CreateRiskResponseRuleWrapper>
  )
}
