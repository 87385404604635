import React from "react"

const PersonCircle = () => {
  return (
    <svg
      width="15px"
      height="17px"
      viewBox="0 2 25 25"
      className="PersonCircle"
    >
      <g
        id="icon-person-circle"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icon-/-person-circle">
          <path
            d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M15.33,16.5 L8.66333333,16.5 C7.23659773,16.5 6.08,17.6565977 6.08,19.0833333 L6.07969753,20.6730135 C7.76518994,21.825771 9.80385765,22.5 12,22.5 C14.1930757,22.5 16.2291163,21.8276526 17.9132397,20.6778398 L17.9133333,19.0833333 C17.9133333,17.6565977 16.7567356,16.5 15.33,16.5 Z M12,1.5 C6.20101013,1.5 1.5,6.20101013 1.5,12 C1.5,14.9012742 2.67669538,17.5277165 4.5788777,19.4281185 L4.58,19.0833333 C4.58,16.8281706 6.4081706,15 8.66333333,15 L15.33,15 C17.5851627,15 19.4133333,16.8281706 19.4133333,19.0833333 L19.4141196,19.4351081 C21.3204163,17.5341591 22.5,14.9048328 22.5,12 C22.5,6.20101013 17.7989899,1.5 12,1.5 Z M11.9966667,13.1666667 C9.74150394,13.1666667 7.91333333,11.3384961 7.91333333,9.08333333 C7.91333333,6.8281706 9.74150394,5 11.9966667,5 C14.2518294,5 16.08,6.8281706 16.08,9.08333333 C16.08,11.3384961 14.2518294,13.1666667 11.9966667,13.1666667 Z M11.9966667,11.6666667 C13.4234023,11.6666667 14.58,10.5100689 14.58,9.08333333 C14.58,7.65659773 13.4234023,6.5 11.9966667,6.5 C10.5699311,6.5 9.41333333,7.65659773 9.41333333,9.08333333 C9.41333333,10.5100689 10.5699311,11.6666667 11.9966667,11.6666667 Z"
            id="Mask"
            fill="#7E8691"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  )
}

export default PersonCircle
