import React, { useEffect } from "react"
import { createGlobalStyle } from "styled-components"
import { useHistory } from "react-router-dom"

import { useMimecastCgBreadcrumbs } from "./useMimecastCgBreadcrumbs"
import type { BreadcrumbItem } from "./useMimecastCgBreadcrumbs"
import { forceNavigate } from "./utils/forceNavigate"

const CgBreadcrumbStyleOverride = createGlobalStyle`
  mimecast-ui-human-risk-container mimecast-ui-breadcrumb {
    margin-left: 30px;
    margin-top: 24px;
  }
`

export function MimecastCgBreadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbItem[]
}) {
  const history = useHistory()
  const isShowingBreadcrumbs = useMimecastCgBreadcrumbs(breadcrumbs)

  // Mad hacks to avoid getting breadcrumbs "stuck" on pages because the shell
  // app doesn't know that internal navigation has happenend. This can be
  // removed once AdCon has a solution for handling all of Vision's route
  // changes.
  useEffect(() => {
    if (isShowingBreadcrumbs) {
      const breadcrumbWrapper = document.querySelector(
        "mimecast-ui-human-risk-container mimecast-ui-breadcrumb",
      )
      if (breadcrumbWrapper) {
        const observer = new MutationObserver((mutationList) => {
          for (const mutation of mutationList) {
            if (mutation.type === "childList") {
              Array.from(mutation.addedNodes).forEach((node) => {
                if ((node as HTMLElement).tagName === "A") {
                  node.addEventListener("click", (event) => {
                    const { target } = event
                    const link = target as HTMLAnchorElement
                    forceNavigate(history, link.href.replace(link.origin, ""))
                  })
                }
              })
            }
          }
        })

        observer.observe(breadcrumbWrapper, { childList: true, subtree: true })

        return () => {
          observer.disconnect()
        }
      }
    }
  }, [history, isShowingBreadcrumbs])

  return isShowingBreadcrumbs ? <CgBreadcrumbStyleOverride /> : null
}
