import React from "react"
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom"

import ReportsDashboards from "@src/scenes/ReportsDashboards/ReportsDashboards"
import Dashboard from "@src/scenes/Dashboard"
import HumanRiskDashboard from "@src/scenes/HumanRiskDashboard"
import IndividualRiskAnalysis from "@src/scenes/IndividualRiskAnalysis"
import IndividualRiskAnalysisContextProvider from "./SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import { IndividualProfilePage } from "./IndividualProfilePage"
import ManagerDashboard from "@src/scenes/ManagerDashboard"
import RiskDetectionRulesScreen from "@src/scenes/RiskDetectionRulesScreen"
import ActionableInsightsDetails from "./Dashboard/components/FirstQuestion/components/ActionableInsights/ActionableInsightDetails"
import { RestrictedRoute } from "@src/components/RestrictedRoute"

import { darklyGetFlag } from "@src/utils/darkly"
import { getOfferingPlan } from "@src/services/redux/company/selectors"
import { RiskResponseRuleCreateScreen } from "./RiskResponseRuleCreateScreen"
import { DetectionsScreen } from "./DetectionsScreen"
import { ApplicationsScreen } from "./ApplicationsScreen"
import { ActionLogsScreen } from "./ActionLogsScreen"
import { AuditLogsScreen } from "./AuditLogsScreen"
import {
  WatchlistsScreen,
  WatchlistIndividualsScreen,
} from "./WatchlistsScreen"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchLatestScoringDatetime,
  getActiveActions,
  getCompanyInfo,
} from "@src/services/redux/reputations/actions"
import { ThunkDispatch } from "redux-thunk"
import { UnknownAction } from "redux"
import { getGlobalConfig } from "@src/globalConfig"
import ActivityIndicator from "@src/components/ActivityIndicator"
import {
  selectCompanyInfo,
  selectLatestScoringDatetime,
} from "@src/services/redux/reputations/selectors"
import { useTranslation } from "react-i18next"
import { useRequest } from "ahooks"

export type VisionContainerProps = RouteComponentProps & {
  setError: (hasError: boolean) => void
  companyInfoOverride?: {
    id: string
    shortname: string
    default_timezone: string
    services: any
    plan: string
  }
}

const VisionContainer = (props: VisionContainerProps) => {
  const { t } = useTranslation()
  const dispatch: ThunkDispatch<unknown, unknown, UnknownAction> = useDispatch()

  const { history, location, setError, companyInfoOverride } = props

  history.listen(() => {
    props.setError(false)
  })

  const latestScoringDatetime = useSelector(selectLatestScoringDatetime)
  const storedCompanyInfo = useSelector(selectCompanyInfo)
  const companyInfo = companyInfoOverride ?? storedCompanyInfo

  const { loading: loadingActiveActions } = useRequest(() => {
    return dispatch(getActiveActions()).catch(() => {
      setError(true)
    })
  })

  const { loading: loadingLatestScoringDatetime } = useRequest(() => {
    return dispatch(fetchLatestScoringDatetime()).catch(() => {
      // Only require the latest scoring datetime there is a provisioning
      // message to show. Otherwise we can ignore the failure and show the
      // rest of the app, in case it's just the latest scoring datetime
      // endpoint that's failing.
      if (getGlobalConfig("PROVISIONING_SCREEN_MESSAGE")) {
        setError(true)
      }
    })
  })

  const { loading: loadingCompanyInfo } = useRequest(() => {
    return !companyInfo
      ? dispatch(getCompanyInfo()).catch(() => {
          setError(true)
        })
      : Promise.resolve()
  })

  const isBootstrapping =
    loadingActiveActions || loadingLatestScoringDatetime || loadingCompanyInfo

  const showActionableInsights = darklyGetFlag("dashboard-actionable-insights")
  const hideRiskDetectionRules = getOfferingPlan() === "assess"

  if (isBootstrapping) {
    return <ActivityIndicator active />
  }

  if (
    getGlobalConfig("PROVISIONING_SCREEN_MESSAGE") &&
    !latestScoringDatetime
  ) {
    return (
      <div
        style={{
          height: "calc(100vh - 200px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "1.5em",
        }}
      >
        {t(getGlobalConfig("PROVISIONING_SCREEN_MESSAGE")!)}
      </div>
    )
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={[
            "/vision2/:profileId/profile",
            "/engagement/vision2/:profileId/profile",
          ]}
          component={IndividualProfilePage}
        />
        <Route
          exact
          path="/engagement/vision2/dashboard"
          render={() => <HumanRiskDashboard />}
        />
        <Route
          exact
          path="/engagement/vision2/insights"
          render={() => <Dashboard history={props.history} />}
        />
        <RestrictedRoute
          exact
          path="/engagement/vision2/insights/individualInsightInfo/:entity"
          render={() => <ActionableInsightsDetails />}
          shouldRender={showActionableInsights}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <Route
          exact
          path="/engagement/vision2/insights/:riskType/:actionType"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <IndividualRiskAnalysis history={props.history} />
            </IndividualRiskAnalysisContextProvider>
          )}
        />
        <Route
          exact
          path="/engagement/vision2/insights/detections"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <DetectionsScreen />
            </IndividualRiskAnalysisContextProvider>
          )}
        />
        <Route
          path="/engagement/vision2/manager-dashboard/team-trends/:person_nid?"
          render={() => (
            <ManagerDashboard history={history} location={location} />
          )}
        />
        <Route
          path="/engagement/vision2/manager-dashboard/:person_nid?"
          render={() => (
            <ManagerDashboard history={history} location={location} />
          )}
        />
        <Route
          path="/engagement/vision2/dashboards/keyinsights"
          render={() => <ReportsDashboards dashboardId="key-insights" />}
        />
        <Route
          path="/engagement/vision2/dashboards/repeatperformers"
          render={() => (
            <ReportsDashboards dashboardId="top-bottom-repeat-performers" />
          )}
        />
        <Route
          path="/engagement/vision2/dashboards/riskiestgroups"
          render={() => <ReportsDashboards dashboardId="riskiest-groups" />}
        />
        <Route
          path="/engagement/vision2/dashboards/phishingreports"
          render={() => (
            <ReportsDashboards dashboardId="phishing-reporting-dashboard" />
          )}
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/risk-response-rules/create-rule"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <RiskResponseRuleCreateScreen />
            </IndividualRiskAnalysisContextProvider>
          )}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/risk-response-engine/risk-response-rules"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/risk-response-rules/edit-rule/:policyId"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <RiskResponseRuleCreateScreen />
            </IndividualRiskAnalysisContextProvider>
          )}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/risk-response-engine/risk-response-rules"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/risk-response-rules"
          render={() => <RiskDetectionRulesScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/action-logs"
          render={() => <ActionLogsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/audit-logs"
          render={() => <AuditLogsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/applications"
          render={() => <ApplicationsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/risk-response-engine/risk-response-rules"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/watchlists/:watchlistId/individuals"
          render={() => <WatchlistIndividualsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/watchlists"
          render={() => <WatchlistsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
      </Switch>
    </>
  )
}

export default withRouter(VisionContainer)
