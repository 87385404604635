import Toasts from "@src/components/Toasts"
import React from "react"
import { createPortal } from "react-dom"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

const ToastsManager = ({ id, fixed }) => {
  const toasts = useSelector((state) => state.get("toastsReducer"))

  if (!toasts || !toasts.length) {
    return null
  }

  if (id) {
    const filtered = toasts.filter((toast) => toast.containerId === id)
    return createPortal(
      <Toasts toasts={filtered} fixed={fixed} />,
      document.body,
    )
  }

  const filtered = toasts.filter((toast) => !toast.containerId)
  return createPortal(<Toasts toasts={filtered} fixed={fixed} />, document.body)
}

ToastsManager.propTypes = {
  id: PropTypes.string,
  fixed: PropTypes.bool,
}

ToastsManager.defaultProps = {
  id: null,
  fixed: true,
}

export default ToastsManager
