/*
This custom hook can be used to detect that whether a specific element
entered into viewport, We need to pass Ref of that element, this observer
will removed suddenly after triggering signal of in view.
*/

import { useState, useEffect } from "react"

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting)
          observer?.unobserve(element?.current)
        }
      },
      { rootMargin },
    )

    element?.current && observer.observe(element?.current)

    return () => element?.current && observer?.unobserve(element?.current)
  }, [])

  return isVisible
}
