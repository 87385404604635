import React from "react"

import { SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM } from "../../RiskRuleOutcome/constants"
import { ActionGroups } from "./ActionGroups"
import { SpaceBetween } from "@src/components/SpaceBetween"
import MicrosoftTeamsMembersComboBox from "../../RiskRuleOutcome/MicrosoftTeams/MicrosoftTeamsMembersComboBox"
import { EditMicrosoftTeamsNotification } from "../actionRenderComponents/EditMicrosoftTeamsNotification"
import MicrosoftTeamsChannelsComboBox from "../../RiskRuleOutcome/MicrosoftTeams/MicrosoftTeamsChannelsComboBox"
import { ActionConfig } from "./actionConfigTypes"
import { validateInstantMessageActionPayload } from "./validators/validateInstantMessageActionPayload"
import { validateObject } from "@src/utils/validateObject"

type Payload = {
  action_type: typeof SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM
  matching_method: "snapshot" | "delta"
  message: string
  send_persons_as_attachment: boolean
  // When converting MicrosoftTeamsMembersComboBox to TS, then let's actually
  // define the payload here with exact props and types
  microsoft_teams_members: object[]
  channel_names: string[]
}

const config: ActionConfig<Payload> = {
  label: "Custom list",
  group: ActionGroups.MICROSOFT_TEAMS,
  defaultPayload: {
    action_type: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_CUSTOM,
    matching_method: "delta",
    message:
      "Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    send_persons_as_attachment: false,
    microsoft_teams_members: [],
    channel_names: [],
  },
  validate: (payload) => {
    return [
      ...validateInstantMessageActionPayload(payload),
      ...validateObject(payload, [
        [
          "channel_names",
          ({ channel_names, microsoft_teams_members }) =>
            microsoft_teams_members.length > 0 || channel_names.length > 0,
          "At least one Microsoft Teams channel must be selected if no Microsoft Teams users are selected",
        ],
        [
          "microsoft_teams_members",
          ({ channel_names, microsoft_teams_members }) =>
            channel_names.length > 0 || microsoft_teams_members.length > 0,
          "At least one Microsoft Teams user must be selected if no Microsoft Teams channels are selected",
        ],
      ]),
    ]
  },
  isEnabled: (isServiceIntegrated) => isServiceIntegrated("microsoft-teams"),
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <SpaceBetween size="sm">
        <MicrosoftTeamsMembersComboBox
          notification={{
            microsoft_teams_members: details.microsoft_teams_members,
          }}
          setSelectedMicrosoftTeamsNotification={({
            microsoft_teams_members,
          }) => {
            updateAction("microsoft_teams_members", microsoft_teams_members)
          }}
        />
        <MicrosoftTeamsChannelsComboBox
          notification={{ channel_names: details.channel_names }}
          setSelectedMicrosoftTeamsNotification={({ channel_names }) => {
            updateAction("channel_names", channel_names)
          }}
        />
        <EditMicrosoftTeamsNotification
          allowPersonListAttachment
          templateAttributes={templateAttributes}
          action={details}
          onChange={updateAction}
        />
      </SpaceBetween>
    )
  },
}

export default config
