import { Input } from "@src/components/MimecastReskin"
import { isEmpty } from "@src/utils/string"
import React from "react"
import { StringInputCombobox } from "../StringInputCombobox"

export const StringInput = (props) => {
  const {
    id,
    index,
    rule,
    inputOrder = 0,
    onHandleFieldChange,
    compoundConditionIndex,
    fieldsNamesMap,
  } = props
  const showTextBox =
    isEmpty(rule?.operator) ||
    rule?.operator === "contains" ||
    rule?.operator === "ilike" ||
    rule?.operator === "ieq"

  return (
    <div>
      {showTextBox ? (
        <Input
          name="value"
          placeholder="Enter value"
          style={{ width: "250px", zIndex: 0 }}
          disabled={!rule?.category || !rule?.field || !rule.operator}
          value={rule.value[inputOrder]}
          onChange={(e) => {
            const value = [...rule.value]
            value[inputOrder] = e
            if (typeof compoundConditionIndex === "number") {
              onHandleFieldChange(id, index, compoundConditionIndex, {
                name: "value",
                value,
              })
            } else {
              onHandleFieldChange(id, index, {
                name: "value",
                value,
              })
            }
          }}
        />
      ) : (
        <StringInputCombobox
          id={id}
          index={index}
          rule={rule}
          inputOrder={inputOrder}
          compoundConditionIndex={compoundConditionIndex}
          onHandleFieldChange={onHandleFieldChange}
          fieldsNamesMap={fieldsNamesMap}
        />
      )}
    </div>
  )
}
