import { convertIndividualToGraphFormat } from "@src/scenes/IndividualProfilePage/ProfileInfo/components/IndividualHumanRiskScoreInfoSection/utils"

export const convertGraphData = (actions_data, activeActions) => {
  return convertIndividualToGraphFormat(actions_data, activeActions)
}

export const treatHumanRiskScore = (hrs_data) => {
  return hrs_data.map(({ date, previous_score, score, variation }) => {
    return {
      date,
      previous_score: previous_score !== null ? previous_score : null,
      score: score !== null ? score : null,
      variation: variation !== null ? variation : null,
    }
  })
}
