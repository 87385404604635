import styled from "styled-components"

export const WarningModalWrapper = styled("div")`
  [class^="Modalstyles__ModalContainer"] {
    padding: 13px 48px;
  }
  [class^="Modalstyles__ModalContent"] {
    max-width: 1080px !important;
    background-color: white;
  }
  [class^="Modalstyles__ModalHeader"] {
    background-color: white !important;
    margin-bottom: 15px;
    [class^="Buttonstyles__StyledButton"] {
      display: none;
    }
  }
  [class^="Modalstyles__ModalFooter"] {
    padding: 0;
  }
  [class^="Typography__Text"] {
    font-size: 16px;
    color: black;
    font-weight: 400;
  }
  [class^="Typography__H2"] {
    line-height: 2.33;
  }
  [class^="Modalstyles__ModalLeftButtons"] {
    margin-top: 12px;
  }
`
export const TextWrapper = styled.div`
  width: 95%;
  text-align: justify;
  flex-direction: column;
  display: flex;
  height: 212px;
  margin-top: 8px;
  justify-content: space-between;
`
export const IconWrapper = styled.div`
  svg {
    width: 50px;
    height: 50px;
    rotate: 180deg;
    padding: 0;
  }
`
export const ModalContentWrapper = styled.div`
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`
