import { transformHierarchicalDataToChart } from "@src/utils/scores"
import store from "@src/services/redux/store"
import { dashToTitleCase } from "@src/utils/string"

export function getState(state = store.getState()) {
  return state.get("managerScoresReducer") || {}
}

export function getManagerInfo() {
  const { managerInfo } = getState()
  return managerInfo
}

export function getManagerOverview() {
  const {
    managerOverview,
    managerInfo,
    managerScores,
    managerTeamTrends,
    managerTeamData,
    totalTeamMembers,
    managerTeamLoader,
    tablePersonInfo,
  } = getState()
  return {
    managerOverview,
    managerInfo,
    managerScores,
    managerTeamTrends,
    managerTeamData,
    totalTeamMembers,
    managerTeamLoader,
    tablePersonInfo,
  }
}

export function getHasDataLoaded() {
  const { teamData, orgScores, teamScoresMonthly } = getState()
  return {
    teamDataLoaded: teamData.isLoaded,
    orgScoreLoaded: orgScores.isLoaded,
    teamScoresMonthlyLoaded: teamScoresMonthly.isLoaded,
  }
}

export function getOrgScores() {
  const { orgScores } = getState()
  return orgScores.data || {}
}

export function getTeamData() {
  const { teamData } = getState()
  return teamData.data || {}
}

export function getTeamOverallScore() {
  const { scores, score_trends } = getTeamData()
  if (scores && score_trends) {
    /**
     * we are using floor function parseInt for the floating scores to make it consistent
     * with the scores returned by /api/scores/manager/team-monthly-scores/
     */
    const overallScore = parseInt(scores.overall || 0)
    const overallScoreLastMonth = parseInt(
      scores.overall - score_trends.overall,
    )
    return {
      overallScore,
      overallScoreLastMonth,
      overallScoreTrend: overallScore - overallScoreLastMonth,
    }
  }
  return {
    overallScore: 0,
    overallScoreLastMonth: 0,
    overallScoreTrend: 0,
  }
}

export function getTeamScoreBreakdown() {
  const { scores, score_trends } = getTeamData()
  const orgScores = getOrgScores()
  const behaviors = Object.keys(orgScores || {})

  if (behaviors && behaviors.length) {
    return behaviors.map((behaviorName) => {
      const orgScore = orgScores ? parseInt(orgScores[behaviorName] || 0) : 0
      const teamScore = scores ? parseInt(scores[behaviorName] || 0) : 0
      const teamScoreLastMonth =
        scores && score_trends
          ? parseInt(scores[behaviorName] - score_trends[behaviorName])
          : 0
      return {
        // score filters accepts behavior names with dashes, not underscores
        key: behaviorName.replaceAll("_", "-"),
        name: dashToTitleCase(behaviorName),
        orgScore,
        teamScore,
        teamScoreLastMonth,
        teamScoreTrend: teamScore - teamScoreLastMonth,
      }
    })
  }
  return []
}

export function getTeamStrongestBehaviors() {
  const { strongest_behaviors } = getTeamData()
  return (strongest_behaviors || []).map((key) => ({
    // score filters accepts behavior names with dashes, not underscores
    key: key.replaceAll("_", "-"),
    name: dashToTitleCase(key),
  }))
}

export function getTeamWeakestBehaviors() {
  const { weakest_behaviors } = getTeamData()
  return (weakest_behaviors || []).map((key) => ({
    // score filters accepts behavior names with dashes, not underscores
    key: key.replaceAll("_", "-"),
    name: dashToTitleCase(key),
  }))
}

export function getTeamInsights() {
  const { insights } = getTeamData()
  return insights
}

export function getTeamScoresMonthly() {
  const { teamScoresMonthly } = getState()
  return {
    loading: teamScoresMonthly.isLoaded,
    data: transformHierarchicalDataToChart(teamScoresMonthly.data),
  }
}
export function getTeamScoresByIndividual() {
  const { teamScoresByIndividuals } = getState()
  return teamScoresByIndividuals
}

export function getTopPerformersCount() {
  const { topPerformersCount } = getState()
  return topPerformersCount
}
