import React from "react"
import {
  ColumnWrapper,
  ColumnValue,
  ProgressBar,
  TrendIcon,
  ScoreTrendValue,
} from "./styles"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import {
  setScoresByIndividuals,
  setScoresByDepartment,
  setScoresByLocation,
} from "@src/services/redux/scores/actions"

import {
  getScoresByIndividuals,
  getScoresByDepartment,
  getScoresByLocation,
} from "@src/services/redux/scores/selectors"

import Link from "@elevate_security/elevate-component-library/dist/Link"

import { getRiskAnalysisURL } from "@src/scenes/IndividualRiskAnalysis/utils"
import { FILTER_KEY } from "@src/scenes/IndividualRiskAnalysis/components/Filters/utils"

export function getScoresSetter(breakdownLevel, dispatch) {
  switch (breakdownLevel) {
    case "Individual":
      return (params) => dispatch(setScoresByIndividuals(params))
    case "Department":
      return (params) => dispatch(setScoresByDepartment(params))
    case "Location":
      return (params) => dispatch(setScoresByLocation(params))
    default:
      break
  }
  return () => null
}

export function getScoresGetter(breakdownLevel) {
  switch (breakdownLevel) {
    case "Individual":
      return getScoresByIndividuals
    case "Department":
      return getScoresByDepartment
    case "Location":
      return getScoresByLocation
    default:
      break
  }
  return () => null
}

export function getTableTitle(breakdownLevel) {
  switch (breakdownLevel) {
    case "Individual":
      return "Score Breakdown by Individual"
    case "Department":
      return "Score Breakdown by Department"
    case "Location":
      return "Score Breakdown by Location"
    default:
      break
  }
  return ""
}

export function getOrdering(direction, property) {
  let ordering = ""
  switch (property) {
    case "individual_name":
      ordering = "first_name,last_name"
      break
    case "manager_name":
      ordering = "manager__first_name,manager__last_name"
      break
    case "department":
      ordering = "department"
      break
    case "members_count":
      ordering = "members_count"
      break
    default:
      break
  }

  // Handle descending order
  if (direction == "desc") {
    ordering = "-" + ordering
  }

  return ordering
}

export function parseScoreTrendValue(scoreTrendValue) {
  if (scoreTrendValue > 0) return `+${scoreTrendValue}`
  if (scoreTrendValue < 0) return `-${scoreTrendValue * -1}`
  return scoreTrendValue
}

function getTrendIconFormat(scoreTrend) {
  if (scoreTrend === 0) {
    return { iconName: "TrendNeutral", iconFill: "#114E92" }
  } else if (scoreTrend < 0) {
    return { iconName: "TrendDown", iconFill: "#D54242" }
  } else {
    return { iconName: "TrendUp", iconFill: "#15785f" }
  }
}

function createScoreEntryWithTrend(score, scoreTrend) {
  const displayedValue = score >= 0 ? score : "-"
  const progressBarValue = score >= 0 ? score : 0
  const { iconName, iconFill } = getTrendIconFormat(scoreTrend)

  return (
    <ColumnWrapper>
      <ColumnValue>{displayedValue}</ColumnValue>
      <ProgressBar value={progressBarValue} />
      <TrendIcon>
        <Icon name={iconName} fill={iconFill} />
      </TrendIcon>
      <ScoreTrendValue color={iconFill}>
        {parseScoreTrendValue(scoreTrend)}
      </ScoreTrendValue>
    </ColumnWrapper>
  )
}

// Helper method to create Link to filtered Risk Analysis page view
function getLabelWithLink(key, value, label) {
  return (
    <Link
      to={getRiskAnalysisURL("individual", "risk-analysis", `${key}=${value}`)}
    >
      {label}
    </Link>
  )
}

export function parseIndividualScoresForTable(data, renderLink = true) {
  if (data === null || data.length == 0) return []

  const parsedData = data.map((personData) => {
    return {
      individual_name: `${personData.first_name} ${personData.last_name}`,
      department: renderLink
        ? getLabelWithLink(
            FILTER_KEY.DEPARTMENT,
            personData.department,
            personData.department,
          )
        : personData.department,
      manager_name: renderLink
        ? getLabelWithLink(
            FILTER_KEY.MANAGER,
            personData.manager_name,
            personData.manager_name,
          )
        : personData.manager_name,
      individual_score: createScoreEntryWithTrend(
        (personData &&
          personData.scores &&
          personData.scores.score &&
          personData.scores.score.overall) ||
          -1,
        (personData &&
          personData.scores &&
          personData.scores["score-trend"] &&
          personData.scores["score-trend"].overall) ||
          0,
      ),
      is_manager: personData.is_manager,
      person_nid: personData.person_nid,
    }
  })
  return parsedData
}

export function parseDepartmentScoresForTable(data) {
  if (data === null || data.length == 0) return []

  const parsedData = data.map((departmentData) => {
    return {
      department_name: getLabelWithLink(
        FILTER_KEY.DEPARTMENT,
        departmentData.name,
        departmentData.name,
      ),
      members_count: getLabelWithLink(
        FILTER_KEY.DEPARTMENT,
        departmentData.name,
        departmentData.members_count,
      ),
      department_score: createScoreEntryWithTrend(
        (departmentData &&
          departmentData.scores &&
          departmentData.scores.score &&
          departmentData.scores.score.overall) ||
          -1,
        (departmentData &&
          departmentData.scores &&
          departmentData.scores["score-trend"] &&
          departmentData.scores["score-trend"].overall) ||
          0,
      ),
    }
  })
  return parsedData
}

export function parseLocationScoresForTable(data) {
  if (data === null || data.length == 0) return []

  const parsedData = data.map((locationData) => {
    return {
      location_name: getLabelWithLink(
        FILTER_KEY.LOCATION,
        locationData.name,
        locationData.name,
      ),
      location_members_count: getLabelWithLink(
        FILTER_KEY.LOCATION,
        locationData.name,
        locationData.members_count,
      ),
      location_score: createScoreEntryWithTrend(
        (locationData &&
          locationData.scores &&
          locationData.scores.score &&
          locationData.scores.score.overall) ||
          -1,
        (locationData &&
          locationData.scores &&
          locationData.scores["score-trend"] &&
          locationData.scores["score-trend"].overall) ||
          0,
      ),
    }
  })
  return parsedData
}

export function getParserForBreakdownLevel(breakdownLevel) {
  switch (breakdownLevel) {
    case "Individual":
      return parseIndividualScoresForTable
    case "Department":
      return parseDepartmentScoresForTable
    case "Location":
      return parseLocationScoresForTable
    default:
      break
  }
  return () => null
}

export function getTableColumns(breakdownLevel) {
  switch (breakdownLevel) {
    case "Individual":
      return INDIVIDUAL_TABLE_COLUMNS
    case "Department":
      return DEPARTMENT_TABLE_COLUMNS
    case "Location":
      return LOCATION_TABLE_COLUMNS
    default:
      break
  }
  return ""
}

export const INDIVIDUAL_TABLE_COLUMNS = [
  {
    key: "individual_name",
    header: "FULL NAME",
  },
  {
    key: "individual_score",
    header: "SECURITY SCORE",
  },
  {
    key: "department",
    header: "DEPARTMENT",
  },
  {
    key: "manager_name",
    header: "MANAGER",
  },
]

export const DEPARTMENT_TABLE_COLUMNS = [
  {
    key: "department_name",
    header: "DEPARTMENT",
  },
  {
    key: "department_score",
    header: "SECURITY SCORE",
  },
  {
    key: "members_count",
    header: "# OF INDIVIDUALS",
  },
]

export const LOCATION_TABLE_COLUMNS = [
  {
    key: "location_name",
    header: "LOCATION",
  },
  {
    key: "location_score",
    header: "SECURITY SCORE",
  },
  {
    key: "location_members_count",
    header: "# OF INDIVIDUALS",
  },
]
