import React from "react"
import moment from "moment"

import { Separator, FilterTitle } from "@src/components/SimpleFilters/styles"
import MultiSelectFilter from "../IndividualProfilePage/components/MoreFilter/MultiSelectFilter"
import DateRange from "../IndividualProfilePage/components/MoreFilter/DateRange"
import type { AppliedFiltersType } from "./useMimecastActionLogs"
import { MoreFilterInfoMessage } from "../IndividualProfilePage/components/MoreFilter/MoreFilterInfoMessage"
import { useTranslation } from "react-i18next"

type Props = {
  appliedFilters: AppliedFiltersType
  setAppliedFilters: (
    updater: (prev: AppliedFiltersType) => AppliedFiltersType,
  ) => void
}

export function MimecastActionLogsFilters({
  appliedFilters,
  setAppliedFilters,
}: Props) {
  const { t } = useTranslation("actionLogs")

  const FILTERS = [
    {
      key: "action_status_filter",
      label: t("filter.status"),
      options: [
        { value: "success", label: t("status.success") },
        { value: "failed", label: t("status.failed") },
        { value: "suppressed", label: t("status.suppressed") },
      ],
    },
    {
      key: "action_filter",
      label: t("filter.action"),
      options: [
        { value: "email", label: t("filter.email") },
        { value: "email_with_video", label: t("filter.emailWithVideo") },
      ],
    },
  ] as const

  return (
    <>
      {FILTERS.map(({ key, options, label }) => {
        return (
          <React.Fragment key={key}>
            <MultiSelectFilter
              showAllFilters
              section={{
                key,
                title: <FilterTitle>{label}</FilterTitle>,
                items: options.map(({ value, label }) => ({
                  label,
                  value,
                })),
              }}
              filters={
                appliedFilters[key]?.map((value: string) => ({
                  key,
                  value,
                })) ?? []
              }
              handleChange={(item: { value: string; checked: boolean }) => {
                setAppliedFilters((prev) => ({
                  ...prev,
                  [key]: item.checked
                    ? (prev[key] ?? []).concat(item.value)
                    : (prev[key] ?? []).filter((value) => value !== item.value),
                }))
              }}
            />
            <Separator />
          </React.Fragment>
        )
      })}
      <DateRange
        section={{
          title: <FilterTitle>{t("filter.executionDate")}</FilterTitle>,
          type: "date",
          key: "execution_datetime",
        }}
        value={[
          appliedFilters.executed_datetime_start,
          appliedFilters.executed_datetime_end,
        ]}
        onChange={({ value: [start, end] }: { value: [string, string] }) => {
          setAppliedFilters((prev) => ({
            ...prev,
            executed_datetime_start: start,
            executed_datetime_end: end,
          }))
        }}
      />
      <MoreFilterInfoMessage
        message={`${t("filter.currentTimezone")}: UTC${moment().format("Z")}`}
      />
    </>
  )
}
