import styled from "styled-components"

export const ScoreBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  min-width: 730px;
`

export const ScoreBox = styled.div`
  display: flex;
  padding: 27px;
  border: 1px solid #c9cfd6;
  border-radius: 4px;

  img {
    width: 67px;
    height: 80px;
  }
`

export const ScoreBoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-left: 40px;
  padding-left: 40px;
  border-left: 1px solid #b8e5e3;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: #7b828a;
  }
`

export const Strong = styled.strong`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #34383c;
`

export const AverageBox = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  padding-left: 18px;
  border-radius: 8px;
  background: #f5f6f8;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`

export const Bold = styled.strong`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #565d66;
`

export const ProgressBarWrapper = styled.div`
  margin-top: 15px;
`

export const HeaderTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const HeaderTitleIcon = styled.div`
  svg {
    // for some reason max-width is set to 100% in the CSS reset, which causes bizarre
    // shrinking behaviour, so we manually remove it here
    max-width: unset;
    margin-left: 8px;
  }
`
