import {
  excludeIncludeLabel,
  specifyGroupDefaultData,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import {
  setDescriptionRuleInfo,
  setExcludingIndividualsRuleInfo,
  setRuleNameRuleInfo,
  setSpecifyGroupRuleInfo,
  setUseMailtrap,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import {
  createPolicy,
  editRule,
  getFilteredRuleName,
} from "@src/services/apis/policies"
import { veirfyExistingRuleName } from "@src/services/redux/policies/actions"
import { hideAllToasts } from "@src/services/redux/toasts/actions"

export const setSpecifyGroupData = (selectedPolicy) => {
  const responseKeys = Object.keys(selectedPolicy?.groups_rules || [])
  if (responseKeys?.length) {
    const incorporate =
      responseKeys?.filter((key) => key !== "groups_names")[0] || ""
    return {
      incorporate: excludeIncludeLabel[incorporate],
      mode: selectedPolicy?.groups_rules[incorporate].mode,
      toggle: true,
    }
  }
  return specifyGroupDefaultData
}

export const setExcludeIncludeData = (selectedPolicy) => {
  const groups_id = Object.keys(
    selectedPolicy?.groups_rules?.groups_names || [],
  )
  if (groups_id.length) {
    return groups_id.map((key) => {
      return {
        label: selectedPolicy?.groups_rules?.groups_names?.[key],
        value: key,
      }
    })
  }
  return []
}

export const setRuleInfoInitialData = ({
  editFlag,
  selectedPolicy,
  dispatch,
}) => {
  // This will conditionally set inclusion and all for pre-selected group
  const defaultRuleInfo = specifyGroupDefaultData

  const defaultRuleGroups = []

  dispatch(setRuleNameRuleInfo(editFlag ? selectedPolicy.name : ""))
  dispatch(setDescriptionRuleInfo(editFlag ? selectedPolicy.description : ""))
  dispatch(
    setSpecifyGroupRuleInfo(
      editFlag ? setSpecifyGroupData(selectedPolicy) : defaultRuleInfo,
    ),
  )
  dispatch(
    setExcludingIndividualsRuleInfo(
      editFlag ? setExcludeIncludeData(selectedPolicy) : defaultRuleGroups,
    ),
  )
  dispatch(setUseMailtrap(editFlag ? selectedPolicy.use_mailtrap : false))
}

export const ruleInfoValidation = async ({
  editFlag,
  selectedPolicy,
  ruleName,
  dispatch,
}) => {
  const { data } = await getFilteredRuleName({
    filters: `name eq ${ruleName}`,
    page_number: 1,
    page_size: 100,
  })
  if (!editFlag && data.results?.length > 0) {
    dispatch(veirfyExistingRuleName())
    return true
  }
  if (
    editFlag &&
    data?.results?.length > 0 &&
    ruleName !== selectedPolicy.name
  ) {
    dispatch(veirfyExistingRuleName())
    return true
  }
  return false
}

export const closeToasts = ({ dispatch }) => {
  setTimeout(() => {
    hideAllToasts()(dispatch)
  }, 2000)
}

//#endregion
//#region Functions for requests
// add new policy
export const riskPolicyPost = (params) => async (dynamicParams) => {
  return createPolicy({ ...params, ...dynamicParams })
}

// add criteria/action to a rule
export const mainRiskRulePut = (id, params) => async (dynamicParams) => {
  return editRule(id, { ...params, ...dynamicParams })
}
