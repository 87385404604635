import styled from "styled-components"

export const ViewByContainer = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr 1fr 80px;
  grid-gap: 10px;
  align-items: center;
  margin-right: auto;
`
export const Strong = styled.strong``
export const OptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  > div:first-child {
    margin-top: 2px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Label = styled.span`
  line-height: 24px;
  white-space: break-spaces;
  color: #565d66;
`
