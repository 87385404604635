import React, { useEffect, useState } from "react"
import { SpaceBetween } from "@src/components/SpaceBetween"
import {
  PrettyCriteria,
  PrettyCriteriaGrid,
  VerticalConnector,
  ConnectorWrapper,
  HorizontalConnector,
  HorizontalSpace,
  VerticalSpace,
  LogicalOperatorWrapper,
  Separator,
} from "./PrettyR3CriteriaLayout.styles"

const configurableStyles = {
  /*
   * Width of the column that renders the logical operator.  We could use "auto"
   * here, but setting a fixed value is the simplest way to ensure that "AND"
   * and "OR" columns will be the same width when rendered atop one another
   */
  gutterWidth: "35px",

  /*
   * True if the layout should collapse groups with only one child into a single node
   */
  flatten: true,

  /*
   * True if the layout should shrink to fit the max width of the leaf nodes;
   * false if all groups and leaves should always render at the same width.
   */
  fitToConditionWidth: true,

  /*
   * True to apply border styling to groups and leaves
   */
  applyBorder: false,

  /*
   * True to apply animations when nodes are added/removed/moved
   */
  animate: false,
}

export function PrettyR3CriteriaLayout({
  criteria,
  depth = 0,
  renderLogicalOperator,
  renderLeaf,
  renderGroupHeader = null,
  index = 0,
  parent = null,
  styleOverrides = configurableStyles,
  focusNodes = [],
}) {
  // We could just pass all these as plain props, but they're constants needed
  // throughout the whole criteria tree; so a single prop is handy to avoid
  // accidentally forgetting to prop drill, which would result in style
  // overrides not appearing at deeper tree levels
  const { flatten, gutterWidth, fitToConditionWidth, applyBorder, animate } = {
    ...configurableStyles,
    ...styleOverrides,
  }

  if (flatten) {
    while (criteria.rules?.length === 1) {
      criteria = criteria.rules[0]
    }
  }

  // The backend labels the and/or operator as "condition" -- we rename here for clarity
  const { condition: logicalOperator } = criteria

  const isLeaf = !logicalOperator

  // Laid out as a 3-column grid, with other 3-column grids nested as needed for
  // nested conditions. Perhaps an unintuitive layout, but it is the easiest way
  // to place the horizontal connectors; we manually set the spacing between the
  // grid columns and rows instead of relying on `gap`, in order to place
  // connectors in the space as needed.
  return (
    <PrettyCriteria
      depth={depth}
      fitToConditionWidth={fitToConditionWidth}
      applyBorder={applyBorder}
      isLeaf={isLeaf}
      focused={focusNodes.includes(criteria.uuid)}
    >
      {isLeaf ? (
        renderLeaf(criteria, index, parent, depth)
      ) : (
        <SpaceBetween gap="8px">
          {renderGroupHeader?.(criteria, depth, parent)}
          {renderGroupHeader && depth > 0 && <Separator />}
          <PrettyCriteriaGrid
            gutterWidth={gutterWidth}
            ruleCount={criteria.rules.length}
          >
            {criteria.rules.map((rule, i, rules) => {
              const isFirst = i === 0
              const isLast = i === rules.length - 1
              const isOnly = isFirst && isLast
              return (
                <React.Fragment key={rule.uuid}>
                  {!isOnly && (
                    <>
                      <ConnectorWrapper>
                        <HorizontalConnector isHidden={true} />
                        <VerticalConnector isFirst={isFirst} isLast={isLast} />
                        <HorizontalConnector />
                      </ConnectorWrapper>
                      <HorizontalSpace>
                        <HorizontalConnector />
                      </HorizontalSpace>
                    </>
                  )}
                  <FadeIn enabled={animate}>
                    <PrettyR3CriteriaLayout
                      criteria={rule}
                      depth={depth + 1}
                      renderLogicalOperator={renderLogicalOperator}
                      renderLeaf={renderLeaf}
                      renderGroupHeader={renderGroupHeader}
                      index={i}
                      parent={criteria}
                      styleOverrides={styleOverrides}
                      focusNodes={focusNodes}
                    />
                  </FadeIn>
                  {!isLast && (
                    <>
                      <VerticalSpace>
                        {isFirst ? (
                          <LogicalOperatorWrapper>
                            {renderLogicalOperator(
                              logicalOperator,
                              criteria.uuid,
                            )}
                          </LogicalOperatorWrapper>
                        ) : (
                          <VerticalConnector />
                        )}
                      </VerticalSpace>
                      <VerticalSpace />
                      <VerticalSpace />
                    </>
                  )}
                </React.Fragment>
              )
            })}
          </PrettyCriteriaGrid>
        </SpaceBetween>
      )}
    </PrettyCriteria>
  )
}

// Not especially please with this component, but it does the job for now.
// Need to look more into how to handle animations with React.
function FadeIn({ children, enabled }) {
  const [opacity, setOpacity] = useState("0.1")

  useEffect(() => {
    // setTimeout needed to guarantee animation when moving nodes, not sure why
    setTimeout(() => {
      setOpacity("1")
    })
  }, [])

  if (!enabled) {
    return children
  }

  return (
    <div style={{ opacity, transition: "opacity 175ms ease-in-out" }}>
      {children}
    </div>
  )
}
