import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { useRequest } from "ahooks"
import { generateEmailPreview } from "@src/services/apis/policies"
import { SkeletonLoader } from "@src/utils/skeleton"
import { SEND_EMAIL_NOTIFICATION_TO_CUSTOM } from "@src/scenes/RiskResponseRuleCreateScreen/components/RiskRuleOutcome/constants"
import MatchedIndividualsDropdown from "../MatchedIndividualsDropdown"
import EmailPreview from "./EmailPreview"
import { EmailPreviewContainerWrapper, ErrorText } from "./styles"
import { EMAIL_ACTION_TYPES } from "../constants"
import EmailPreviewToggle from "../EmailPreviewToggle"

const EmailPreviewContainer = ({ action, queryRules, ruleName }) => {
  const [recipient, setRecipient] = useState(null)
  const [shouldPreviewFilled, setShouldPreviewFilled] = useState(false)

  const { subject, message } = action
  const params = useMemo(
    () =>
      EMAIL_ACTION_TYPES.includes(action?.action_type) &&
      ((action?.action_type === SEND_EMAIL_NOTIFICATION_TO_CUSTOM &&
        shouldPreviewFilled) ||
        recipient)
        ? {
            action_type: action?.action_type,
            ...(recipient && {
              es_person_ids: recipient?.individual_ids || [recipient?.value],
            }),
            policy_name: ruleName,
          }
        : {},
    [
      action?.action_type === SEND_EMAIL_NOTIFICATION_TO_CUSTOM &&
        shouldPreviewFilled,
      recipient,
      action?.action_type,
    ],
  )

  const { data: results, loading } = useRequest(
    () =>
      generateEmailPreview({
        subject,
        message,
        criteria: queryRules,
        ...params,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [subject, message, queryRules, params],
      formatResult: (response) => {
        if (response?.error) {
          return { ...results, error: response?.error }
        }

        return response?.data
      },
    },
  )

  useEffect(() => {
    if (!shouldPreviewFilled) {
      setRecipient(null)
    }
  }, [shouldPreviewFilled])

  return (
    <EmailPreviewContainerWrapper>
      <EmailPreviewToggle
        checked={shouldPreviewFilled}
        setChecked={setShouldPreviewFilled}
      >
        {action?.action_type !== SEND_EMAIL_NOTIFICATION_TO_CUSTOM && (
          <div style={{ paddingTop: 10 }}>
            <MatchedIndividualsDropdown
              recipient={recipient}
              setRecipient={setRecipient}
              action={action}
              queryRules={queryRules}
            />
          </div>
        )}
        {results?.error && (
          <ErrorText>{results?.error?.response?.data?.detail}</ErrorText>
        )}
      </EmailPreviewToggle>
      {loading || !results ? (
        <>
          <SkeletonLoader height={50}></SkeletonLoader>
          <SkeletonLoader height={200} marginTop={10}></SkeletonLoader>
        </>
      ) : (
        <EmailPreview data={results} />
      )}
    </EmailPreviewContainerWrapper>
  )
}

EmailPreviewContainer.propTypes = {
  action: PropTypes.object.isRequired,
  queryRules: PropTypes.object.isRequired,
  ruleName: PropTypes.object.isRequired,
}

export default EmailPreviewContainer
