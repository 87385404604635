import React from "react"
import { Tooltip } from "@elevate_security/elevate-component-library"
import Icon from "../../../../components/Icon"

const ErrorState = () => {
  return (
    <div className="error-width">
      <Icon className="ErrorState" name="smallError" />
      <div className="center-text body-2-reg dotted-underline">
        <Tooltip
          body="Sorry about that! Our team has been notified of the issue. In the meantime, please try refreshing the page or trying again in a few minutes."
          placement="bottom"
          readOnly
        >
          Drats! Something went wrong...
        </Tooltip>
      </div>
    </div>
  )
}

export default ErrorState
