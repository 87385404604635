import React, { useEffect, useState, useCallback } from "react"
import { createPortal } from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import { Drawer } from "@elevate_security/elevate-component-library"
import { ButtonsBar, ButtonsGroup } from "./styles"
import { RiskDetectionRules } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules"
import { DropdownCard } from "./DropdownCard"
import SortSelect from "./SortSelect"
import {
  getPoliciesRedux,
  setLoading,
} from "@src/services/redux/policies/actions"
import Paginator from "../IndividualRiskAnalysis/components/Paginator"
import EmptyScreen from "./EmptyScreen"
import RiskDetectionRulesScreenSkeleton from "@src/scenes/RiskDetectionRulesScreen/skeleton/RiskDetectionRulesScreenSkeleton"
import { newestToOldest } from "./SortSelect/utils"
import useQuery from "../../services/hooks/useQuery"
import { RISK_RESPONSE_RULES } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { isEmpty } from "../../utils/string"
import debounce from "lodash.debounce"
import { RiskResponseRuleFilters } from "./RiskResponseRuleFilters/RiskResponseRuleFilters"
import { useRiskResponseRuleFilters } from "./RiskResponseRuleFilters/useRiskResponseRuleFilters"
import { PageLayout } from "../../components/PageLayout"
import { SearchInputWithFilters } from "../../components/SearchInputWithFilters"
import { SpacingY } from "@src/components/SpacingY"
import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"

export const RiskDetectionRulesScreen = () => {
  const queryParams = useQuery()
  const query = queryParams.get("query")
  const filteredPolicyId = queryParams.get("policy-id")
  const policies = useSelector((state) => state.get("policyReducer"))
  const [filteredPolicies, setFilteredPolicies] = useState([])
  const [filterValue, setFilterValue] = useState("")
  const [sortBy, setSortBy] = useState(newestToOldest)
  const [showFilters, setShowFilters] = useState(false)
  const [search, setSearch] = useState()

  const policiesList = filterValue.length > 0 ? filteredPolicies : policies.list
  const dispatch = useDispatch()

  const {
    availableFilters,
    appliedFilters,
    filtersString,
    removeFilters,
    clearFilters,
    handleFilterChange,
  } = useRiskResponseRuleFilters(filteredPolicyId)

  const fetchData = useCallback(
    (params, shouldIgnore = () => false) => {
      dispatch(getPoliciesRedux(params, shouldIgnore))
    },
    [dispatch],
  )

  const handlePaginator = (pNumParam) => {
    fetchData({
      ...sortBy,
      page_number: pNumParam,
      filters: filtersString,
    })
  }

  const searchPolicies = useCallback(
    (arg) =>
      debounce((value) => {
        setSearch(value)
      }, 500)(arg),
    [],
  )

  const onChangeHandler = (value) => {
    setFilterValue(value)
    dispatch(setLoading())
    searchPolicies(value)
  }

  const clearQuickFilter = () => {
    setFilterValue("")
    setSearch("")
    let fQueryString = `name ILIKE ${""}`
    if (!isEmpty(filtersString)) {
      fQueryString = `${fQueryString} AND ${filtersString}`
    }
    fetchData({
      ...sortBy,
      filters: fQueryString,
      page_number: 1,
    })
  }

  const renderPolicies = (data, loading) => {
    if (!loading) {
      return <div />
    } else if (data?.length > 0) {
      return data.map((policy, key) => (
        <DropdownCard key={key} item={policy} id={key} />
      ))
    } else {
      return <EmptyScreen />
    }
  }

  const toggleFilters = (val) => {
    setShowFilters((prev) => val ?? !prev)
  }

  const TITLE = RISK_RESPONSE_RULES

  useEffect(() => {
    const params = { ...sortBy }
    params.page_number = 1
    if (query) {
      params.filters = `name ILIKE ${query}`
    }
    if (filteredPolicyId) {
      params.filters = isEmpty(params?.filters)
        ? `id eq ${filteredPolicyId}`
        : `${params?.filters}&id eq ${filteredPolicyId}`
    }
    fetchData(params)
  }, [query, filteredPolicyId, sortBy, fetchData])

  useEffect(() => {
    setFilteredPolicies(policies.list)
  }, [policies.list])

  useEffect(() => {
    if (query) setFilterValue(query)
  }, [query])

  useEffect(() => {
    let ignore = false
    const params = { ...sortBy, page_number: 1 }

    if (appliedFilters?.length && !isEmpty(filtersString)) {
      let fQueryString = `${filtersString}`
      if (!isEmpty(search)) {
        fQueryString = `${fQueryString} AND name ILIKE ${search}`
      }
      params.filters = fQueryString
    } else if (!appliedFilters?.length && search) {
      params.filters = `name ILIKE ${search}`
    } else if (!appliedFilters?.length && !isEmpty(filteredPolicyId)) {
      params.filters = `id eq ${filteredPolicyId}`
    }

    fetchData(params, () => ignore)
    return () => {
      // Allows the redux action to ignore old requests that take longer than
      // the debounce, preventing stale search updates in the UI.
      ignore = true
    }
  }, [
    appliedFilters?.length,
    search,
    sortBy,
    fetchData,
    filteredPolicyId,
    filtersString,
    query,
  ])

  return (
    <>
      <MimecastCgBreadcrumbs
        breadcrumbs={["risk_response_engine", "risk_response_rules"]}
      />
      <PageLayout
        title={TITLE}
        actions={
          <ButtonsBar>
            <ButtonsGroup>
              <SortSelect select={setSortBy} />
              <RiskDetectionRules />
            </ButtonsGroup>
          </ButtonsBar>
        }
      >
        <>
          <SpacingY>
            <SearchInputWithFilters
              value={filterValue}
              filtersCount={appliedFilters.length}
              handleSearch={(value) => onChangeHandler(value)}
              isClearable={true}
              clearSearch={clearQuickFilter}
              clearFilters={clearFilters}
              placeholder="Filter by name or description…"
              onToggleFilters={() => {
                toggleFilters()
              }}
            />
            {!policies.isLoaded ? (
              <RiskDetectionRulesScreenSkeleton />
            ) : (
              <div>{renderPolicies(policiesList, policies.isLoaded)}</div>
            )}
          </SpacingY>
          <Paginator
            currentPage={policies.pageNumber - 1}
            pages={policies.pages}
            onNavigate={(page) => handlePaginator(page + 1)}
          />
          {createPortal(
            <Drawer
              isOpen={showFilters}
              direction="right"
              width="480px"
              toggleDrawer={toggleFilters}
            >
              <RiskResponseRuleFilters
                availableFilters={availableFilters}
                appliedFilters={appliedFilters}
                onChange={handleFilterChange}
                removeFilters={removeFilters}
                clearFilters={clearFilters}
                onClose={() => toggleFilters(false)}
              />
            </Drawer>,
            document.body,
          )}
        </>
      </PageLayout>
    </>
  )
}
