import React from "react"
import { EditInstantMessageNotification } from "../EditInstantMessageNotification"

export function EditSlackNotification(props) {
  return (
    <EditInstantMessageNotification
      {...props}
      title="Slack notification content"
    />
  )
}
