function validateServiceByKey(service, key) {
  return Boolean(service && service[key])
}

/**
 * Checks whether this organization or user is allowed to see the service.
 * @param {object} service
 * @returns {Boolean}
 */
export function isServiceVisible(service) {
  return validateServiceByKey(service, "visibility")
}

/**
 * Checks whether this organization or user is allowed to use the service.
 * @param {object} service
 * @returns {Boolean}
 */
export function isServiceEntitled(service) {
  return validateServiceByKey(service, "entitlement")
}
