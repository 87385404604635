import React from "react"

import { SEND_SLACK_NOTIFICATION_TO_CHANNELS } from "../../RiskRuleOutcome/constants"
import { EditSlackNotification } from "../actionRenderComponents/EditSlackNotification"
import { ActionGroups } from "./ActionGroups"
import { SpaceBetween } from "@src/components/SpaceBetween"
import SlackChannelsComboBox from "../../RiskRuleOutcome/slack/SlackChannelsComboBox"

import { ActionConfig } from "./actionConfigTypes"
import { validateInstantMessageActionPayload } from "./validators/validateInstantMessageActionPayload"
import { validateObject } from "@src/utils/validateObject"

type Payload = {
  action_type: typeof SEND_SLACK_NOTIFICATION_TO_CHANNELS
  matching_method: "delta" | "snapshot"
  message: string
  send_persons_as_attachment: boolean
  channel_names: string[]
}

const config: ActionConfig<Payload> = {
  label: "Channels",
  group: ActionGroups.SLACK,
  defaultPayload: {
    action_type: SEND_SLACK_NOTIFICATION_TO_CHANNELS,
    matching_method: "delta",
    message:
      "Hi {{channel.name}}! Elevate rule {{rule.name}} activated matching with {{rule.action_cohort_count}} individuals",
    send_persons_as_attachment: false,
    channel_names: [],
  },
  validate: (payload) => {
    return [
      ...validateInstantMessageActionPayload(payload),
      ...validateObject(payload, [
        [
          "channel_names",
          ({ channel_names }) => channel_names.length > 0,
          "At least one Slack channel must be selected",
        ],
      ]),
    ]
  },
  isEnabled: (isServiceIntegrated) => isServiceIntegrated("slack"),
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <SpaceBetween size="sm">
        <SlackChannelsComboBox
          notification={{ channel_names: details.channel_names }}
          setSelectedSlackNotification={({ channel_names }) => {
            updateAction("channel_names", channel_names)
          }}
        />
        <EditSlackNotification
          allowPersonListAttachment
          templateAttributes={templateAttributes}
          action={details}
          onChange={updateAction}
        />
      </SpaceBetween>
    )
  },
}

export default config
