import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQueryBuilderSchema, editRule } from "@src/services/apis/policies"
import { useParams, useHistory, useLocation } from "react-router-dom"
import {
  getPoliciesRedux,
  clearErrorValidationsInfo,
  setIsRuleMalformed,
} from "@src/services/redux/policies/actions"
import {
  resetQueryRule,
  setQueryBuilderSchema,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import { createGlobalStyle } from "styled-components"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { CreateRiskResponseRule } from "./CreateRiskResponseRule"
import DragosaurWithUmbrella from "@src/components/DragosaurWithUmbrella"
import Link from "@elevate_security/elevate-component-library/dist/Link"
import { NotFoundMessage } from "./styles"
import { setShowNotificationTemplate } from "@src/services/redux/RiskDetectionRules/DefineOutcome/actions"
import { POLICY_STATE_MAP } from "./constants"
import { showToast } from "@src/services/redux/toasts/actions"
import { humanize } from "@src/utils/string"
import { validateCriteria } from "@src/scenes/RiskResponseRuleCreateScreen/components/ConfigureInputCriteriaPage/utils"

// It's too tricky to change vision-dashboard inline styles without breaking all
// the other vision pages, so we resort to forcibly overriding the padding from
// here 😞
const VisionStyleOverride = createGlobalStyle`
  #vision-dashboard, #vision-dashboard-cg, #vision-dashboard-ci {
    padding: 0px !important;
  }
`

export const RiskResponseRuleCreateScreen = () => {
  const { policyId } = useParams()

  const editFlag = Boolean(policyId)

  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const [isRecommendation, setIsRecommendation] = useState(
    Boolean(editFlag && state?.isRecommendation),
  )

  useEffect(() => {
    // TODO: Confirm & Improve this becasue we do not need to fetch this when editing an Risk Response Rule, this is only needed in case of recommendation
    if (policyId) {
      dispatch(
        getPoliciesRedux({
          filters: `id eq ${policyId}`,
        }),
      )
    }
  }, [policyId])

  const {
    list: policies,
    isLoaded,
    isRuleMalformed,
  } = useSelector((state) => state.get("policyReducer"))

  const getSchema = async () => {
    const response = await getQueryBuilderSchema()
    if (response.data) {
      dispatch(setQueryBuilderSchema(response.data))
    }
  }

  useEffect(() => {
    getSchema()
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearErrorValidationsInfo())
      dispatch(setShowNotificationTemplate(false))

      // If there were any pre-filled criteria (e.g. from Risk Analysis Page),
      // discard them when unmounting the R3 create/edit page
      dispatch(resetQueryRule())
    }
  }, [])

  const selectedPolicy = policies?.find((policy) => policy.id === policyId)

  useEffect(() => {
    if (editFlag) {
      const isMalformed = !validateCriteria(
        selectedPolicy?.rule?.definition?.criteria || {},
      )

      dispatch(setIsRuleMalformed(isMalformed))
    }
  }, [selectedPolicy])

  if (policyId && !isLoaded) {
    return <ActivityIndicator active={true} />
  }

  if (policyId && !selectedPolicy && isLoaded) {
    return (
      <DragosaurWithUmbrella
        message={
          <NotFoundMessage>
            <div>
              Risk Response Rule not found -- it might have been deleted.
            </div>
            <div>
              Go to the{" "}
              <Link to="/engagement/vision2/risk-response-engine/risk-response-rules">
                Elevate Response Engine
              </Link>{" "}
              to view and create Risk Response Rules.
            </div>
          </NotFoundMessage>
        }
        showTooltip={false}
      />
    )
  }

  const onFinished = async () => {
    if (isRecommendation) {
      try {
        await editRule(selectedPolicy?.id, {
          state: POLICY_STATE_MAP.DELETED,
        })
      } catch (error) {
        dispatch(
          showToast(
            `Failed to change state of ${humanize(selectedPolicy?.name)}`,
            "error",
          ),
        )
      }
    }
    history.push(
      "/engagement/vision2/risk-response-engine/risk-response-rules",
      {},
    )
  }

  return (
    <>
      <VisionStyleOverride />
      <CreateRiskResponseRule
        selectedPolicy={selectedPolicy}
        editFlag={editFlag}
        onFinished={onFinished}
        setIsRecommendation={setIsRecommendation}
        isRuleMalformed={isRuleMalformed}
      />
    </>
  )
}
