import React from "react"
import { humanize } from "@src/utils/string"
import { Badge } from "@elevate_security/elevate-component-library"

export const StatusBadge = ({ children: status }) => {
  const statusToTheme = {
    unknown: "yellow",
    success: "green",
    pending: "blue",
    failed: "red",
    failure: "red",
    suppressed: "yellow",
  }
  return (
    <Badge
      text={humanize(status)}
      theme={statusToTheme[status] || "gray"}
      shape="rectangular"
    />
  )
}
