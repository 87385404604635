import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"
import styled from "styled-components"
import { SpacingX } from "@src/components/SpacingX"

export const Row = styled(SpacingX).attrs({ justify: "space-between" })`
  padding: 7px 0px;
  border-bottom: ${({ noBorder }) => (noBorder ? "none" : "1px solid #dde1e7")};
`

export const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => padding || "0 0"};
  align-items: center;
`

export const Column = styled.div`
  width: ${({ width }) => width || "180px"};
  display: flex;
  justify-content: ${({ position }) => position};
`

export const PositionSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: #7b828a;
  margin-right: 10px;
`

export const NameSpan = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => (props.color ? props.color : "#0c7d84")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  div {
    display: inline-block;
    white-space: nowrap;
    width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ScoreSpan = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  font-weight: 700;
  margin-right: 13px;
  margin-top: 9px;
  width: 20px;
`

export const HeaderText = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #7b828a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
`

export const TotalText = styled.div`
  margin-top: 16px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid rgb(133, 166, 224);
  font-size: 14px;
  padding: 8px;
  font-weight: 600;
  color: ${THEME_PRIMARY};
  &:hover {
    text-decoration: underline;
    color: ${THEME_HOVER};
  }
`

export const NoActionItemsWrapper = styled.div`
  background: #f5f6f8;
  border-radius: 5px;
  padding: 45px;
  text-align: center;
  height: 280px;
  margin-bottom: 32px;
`

export const NoActionItemImage = styled.img`
  width: 128px;
  height: 96px;
`

export const NoActionItemsMessageWrapper = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    margin-bottom: 0.3rem;
    color: #7b828a;
  }
`

export const NoDataTextHeader = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
`

export const NoDataText = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
  span {
    text-decoration: underline;
  }
`

export const EntityComboboxWrapper = styled.div`
  width: 50%;
  max-width: 200px;
  min-width: 150px;
`
