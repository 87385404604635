import styled from "styled-components"

export const AddActionWrapper = styled.div`
  position: relative;
`

export const MenuWrapper = styled.div`
  position: absolute;
  width: 300px;
  right: 0;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  background-color: white;
  padding: 2px;
  z-index: 1000;
  max-height: 500px;
  display: flex;
  flex-direction: column;
`

export const OptionButton = styled.button`
  background-color: unset;
  width: 100%;
  text-align: left;
  line-height: 1em;
  padding: 10px;
  padding-left: 20px;
  border: none;
  ${(props) =>
    !props.disabled
      ? "&:hover { background-color: rgba(12, 123, 131, 0.2); }"
      : ""}
  ${(props) => (props.disabled ? "cursor: not-allowed;" : "")}
  ${(props) => (props.disabled ? "opacity: 0.5;" : "")}
`

export const GroupLabel = styled.div`
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid lightgray;
`

export const NoResults = styled.div`
  padding: 10px;
`

export const SearchWrapper = styled.div`
  padding: 5px;
  input {
    width: 100%;
  }
`

export const OptionsWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`
