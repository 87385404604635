import { useEffect, useState } from "react"
import { filterCriteriaGroupIds } from "./filterCriteriaGroupIds"
import { filterCriteriaApplicationIds } from "./filterCriteriaApplicationIds"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import { getGroupsList } from "@src/services/apis/groups"
import { useRequest } from "ahooks"
import { getAzureADApplications } from "@src/services/apis/reputation"

const useGetCriteriaFieldsNames = (inputData) => {
  const { shortname: orgId } = getCompanyInfo()

  const [groupIds, setGroupIds] = useState([])
  const [applicationIds, setApplicationIds] = useState([])
  const [fetchingApplications, setFetchingApplications] = useState(false)

  /*
    applicationsMap = {
      application_id: "application_name"
    }
    
  */
  const [applicationsMap, setApplicationsMap] = useState({})

  /*
    TODO: We will replace this with regular api call like we do for applications
    groupsMap = {
      group_id: "group_name"
    }
    
  */

  const { data: groupsMap, loading: isLoadingGroupsMeta } = useRequest(
    () =>
      getGroupsList({
        organization: orgId,
        limit: 500,
        offset: 0,
        id__in: groupIds.join(),
      }),
    {
      ready: Boolean(groupIds?.length),
      refreshDeps: [groupIds],
      formatResult: (response) => {
        let map = {}
        if (response?.data) {
          map = response?.data?.results?.reduce((accumulatorValue, item) => {
            if (!accumulatorValue[item.id]) {
              accumulatorValue[item.id] = item.name
            }
            return accumulatorValue
          }, {})
        }
        return map
      },
    },
  )

  useEffect(() => {
    if (!inputData?.length) return
    const ids = filterCriteriaGroupIds(inputData, [])
    if (!ids?.length) return
    if (groupIds.join(",") !== ids.join(",")) {
      setGroupIds(ids?.join(",")?.split(","))
    }
  }, [inputData])

  useEffect(() => {
    if (!inputData?.length) return
    const ids = filterCriteriaApplicationIds(inputData, [])
    if (!ids?.length) return
    if (applicationIds.join(",") !== ids.join(",")) {
      setApplicationIds(ids?.join(",")?.split(","))
    }
  }, [inputData])

  useEffect(() => {
    async function fetchApplicationsMeta() {
      setFetchingApplications(true)
      const response = await getAzureADApplications({
        id__in: applicationIds.join(),
      })
      setFetchingApplications(false)
      if (response?.data) {
        const map = response?.data?.results?.reduce(
          (accumulatorValue, item) => {
            if (!accumulatorValue[item.app_id]) {
              accumulatorValue[item.app_id] = item.display_name
            }
            return accumulatorValue
          },
          {},
        )
        setApplicationsMap(map)
      }
    }

    if (applicationIds?.length) {
      fetchApplicationsMeta()
    }
  }, [applicationIds])

  return {
    isLoadingFieldsNames: isLoadingGroupsMeta || fetchingApplications,
    fieldsNamesMap: { ...groupsMap, ...applicationsMap },
  }
}

export default useGetCriteriaFieldsNames
