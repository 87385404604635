import React from "react"
import PropTypes from "prop-types"
import { useToggle } from "ahooks"

import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import Button from "@elevate_security/elevate-component-library/dist/Button"
import ImproveBehaviorModal from "../../../modals/ImproveBehavior"
import TopPerformersModal from "../../../modals/TopPerformers"
import { Container, Block, TitleRow } from "./styles"
import { renderInsights } from "./utils"

const { H5 } = Typography

const BehaviorsLevel = ({ insights, weakestBehaviors }) => {
  const [showModal, { toggle: toggleShowModal }] = useToggle(false)

  return (
    <Container>
      <Block>
        <TitleRow>
          <Icon name="CircleCheckOutline" size="sm" fill="#15785F" />
          <H5 fontWeight="bold" style={{ fontSize: 16 }}>
            Strongest Behaviors
          </H5>
        </TitleRow>
        {renderInsights(insights?.strongest_behaviors, "strongest")}
        <TopPerformersModal />
      </Block>
      <hr />
      <Block>
        <TitleRow>
          <Icon name="CircleExclamation" size="sm" fill="#C73434" />
          <H5 fontWeight="bold" style={{ fontSize: 16 }}>
            Weakest Behaviors
          </H5>
        </TitleRow>
        {renderInsights(insights?.weakest_behaviors, "weakest")}
        <br />
        {weakestBehaviors && !!weakestBehaviors.length && (
          <Button theme="secondary" onClick={() => toggleShowModal()}>
            Improve Weakest Behaviors
          </Button>
        )}
        <ImproveBehaviorModal
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          behaviors={weakestBehaviors}
        />
      </Block>
    </Container>
  )
}

BehaviorsLevel.propTypes = {
  weakestBehaviors: PropTypes.arrayOf(PropTypes.shape({})),
  insights: PropTypes.shape({
    strongest_behaviors: PropTypes.arrayOf(PropTypes.string),
    weakest_behaviors: PropTypes.arrayOf(PropTypes.string),
  }),
}

BehaviorsLevel.defaultProps = {
  strongestBehaviors: [],
  weakestBehaviors: [],
  insights: {},
}

export default BehaviorsLevel
