import React from "react"

import { SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS } from "../../RiskRuleOutcome/constants"
import { ActionGroups } from "./ActionGroups"
import { EditMicrosoftTeamsNotification } from "../actionRenderComponents/EditMicrosoftTeamsNotification"
import { ActionConfig } from "./actionConfigTypes"
import { validateInstantMessageActionPayload } from "./validators/validateInstantMessageActionPayload"

type Payload = {
  action_type: typeof SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS
  matching_method: "delta" | "snapshot"
  message: string
}

const config: ActionConfig<Payload> = {
  label: "Individuals who meet the criteria",
  group: ActionGroups.MICROSOFT_TEAMS,
  defaultPayload: {
    action_type: SEND_MICROSOFT_TEAMS_NOTIFICATION_TO_INDIVIDUALS,
    matching_method: "delta",
    message:
      "Hi {{people.first_name}} {{people.last_name}}! We'd like to update you that your risk posture has changed.",
  },
  validate: (payload) => {
    return validateInstantMessageActionPayload(payload)
  },
  isEnabled: (isServiceIntegrated) => isServiceIntegrated("microsoft-teams"),
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <EditMicrosoftTeamsNotification
        templateAttributes={templateAttributes}
        action={details}
        onChange={updateAction}
      />
    )
  },
}

export default config
