import { useEffect, useState } from "react"
import { fetchDetectionsFilters } from "./detectionsApi"
import {
  getGenericFilterForField,
  FILTER_TYPE,
} from "../SecEngIndividualRiskAnalysis/components/IndividualRiskAnalysisTable/components/Filters/utils"
import { humanize } from "@src/utils/string"
import { FILTER_KEY } from "../IndividualRiskAnalysis/components/Filters/utils"

export function useDetectionsFilters({ when }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    if (when) {
      fetchDetectionsFilters()
        .then((response) => {
          const keys = response.data.filterable_keys
          const behaviorTypeFilters = [
            {
              type: FILTER_TYPE.MULTI_SELECT,
              key: FILTER_KEY.DETECTION_TYPE,
              title: "Identity Detection Types",
              showAllFilter: true,
              isCollapsible: true,
              initialCollapseState: false,
              category: "detections",
              items: response.data.enabled_behaviors
                .filter((b) => b.action_category === "identity")
                .map(({ behavior_type }) => ({
                  label: humanize(behavior_type.replace("identity-", "")),
                  value: behavior_type,
                })),
            },
          ]
          setData(
            keys
              .flatMap((key) => {
                // Generic field function expects singular, but detections endpoint gives plural
                const remappings = {
                  departments: "department",
                  locations: "location",
                }
                const filterField = getGenericFilterForField(
                  remappings[key] || key,
                  response.data[key],
                )
                // Explicitly mark certain filters as "detections" category for now
                if (
                  [
                    "suspicious_country",
                    "expected_country",
                    "application",
                    "status",
                    "authentication_factor_type",
                  ].includes(key)
                ) {
                  filterField.category = "detections"
                }
                // Make sure status values are capitalized
                if (key === "status") {
                  filterField.items.forEach(
                    (item) => (item.label = humanize(item.label)),
                  )
                  const successItem = filterField.items.find(
                    (item) => item.value === "success",
                  )
                  filterField.items = [
                    successItem,
                    ...filterField.items.filter((item) => item !== successItem),
                  ]
                }
                return filterField
              })
              .concat(behaviorTypeFilters)
              .sort((a, b) => (a.title > b.title ? 1 : -1)),
          )
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [when])

  return { data, error, loading: when && loading }
}
