import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

export const RestrictedRoute = ({ shouldRender, fallbackPath, ...rest }) => (
  <>{shouldRender ? <Route {...rest} /> : <Redirect to={fallbackPath} />}</>
)

RestrictedRoute.defaultProps = {
  shouldRender: true,
  fallbackPath: "/",
}

RestrictedRoute.propTypes = {
  shouldRender: PropTypes.bool,
  fallbackPath: PropTypes.string,
}
