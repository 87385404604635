import {
  GET_POLICIES,
  DELETE_POLICY,
  ADD_POLICY,
  GET_POLICIES_ACTIONLOGS,
  CLEAR_RDR_VALIDATIONS,
  VERIFY_EXISTING_RULE_NAME,
  UPDATE_CREATED_POLICY,
  SET_LOADING,
  SET_IS_LOADING,
  SET_IS_RULE_MALFORMED,
} from "./types"

const INITIAL_STATE = {
  activePoliciesList: [],
  errorDetected: false,
  existingRule: false,
  list: [],
  run_logs: [],
  selectedPolicy: [],
  pageNumber: 0,
  pageSize: 0,
  pages: 0,
  totalElement: 0,
  isLoaded: false,
  isActionLogLoaded: {},
  isRuleMalformed: false,
}

export default function policyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_POLICY:
      return {
        ...state,
        list: [action.policy, ...state.list],
      }
    case GET_POLICIES:
      return {
        ...state,
        list: action.data.data,
        pages: action.data.totalPages,
        pageNumber: action.data.pNumber,
        pageSize: action.data.pSize,
        totalElement: action.data.pTotalElements,
        isLoaded: true,
      }
    case GET_POLICIES_ACTIONLOGS:
      return {
        ...state,
        isActionLogLoaded: {
          ...state.isActionLogLoaded,
          [action.data.id]: true,
        },
        list: state.list.map((policy) => {
          if (policy.id === action.data.id) {
            return {
              ...policy,
              run_logs: action.data.data,
              pagination: {
                pageNumber: action.data.pagination?.page_number,
                pageSize: action.data.pagination?.page_size,
                pages: action.data.pagination?.pages,
                totalElement: action.data.pagination?.total_elements,
              },
            }
          }
          return policy
        }),
      }
    case DELETE_POLICY:
      return {
        ...state,
        list: state.list.filter((policy) => {
          if (policy.id !== action.data.id) return policy
        }),
      }
    case VERIFY_EXISTING_RULE_NAME:
      return {
        ...state,
        existingRule: !state.existingRule,
      }
    case CLEAR_RDR_VALIDATIONS:
      return {
        ...state,
        errorDetected: false,
        existingRule: false,
      }
    case UPDATE_CREATED_POLICY:
      return {
        ...state,
        list: state.list.map((policy) => {
          if (policy.id === action.policy.id) return action.policy
          return policy
        }),
      }
    case SET_LOADING:
      return {
        ...state,
        isActionLogLoaded: {
          ...state.isActionLogLoaded,
          [action.policyId]: false,
        },
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoaded: action.isLoaded,
      }
    case SET_IS_RULE_MALFORMED:
      return {
        ...state,
        isRuleMalformed: action.isRuleMalformed,
      }
    default:
      return state
  }
}
