import fetcher from "@src/services/fetcher"
import { getErrorStatus } from "@src/services/fetcher/utils"
import { BASE_SCORES_API_URL, BASE_EP_API_URL } from "@src/constants"

export async function getTrendSummaryCampaigns(params) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.get(`${API_URL}/scores/campaigns`, {
      params,
    })

    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getTrendSummaryHierarchicalScores({
  organization_nid,
  start_date,
  end_date,
  group_by = "datetime-monthly,category",
}) {
  try {
    const API_URL = BASE_SCORES_API_URL()
    const params = {
      start_date,
      end_date,
      group_by,
    }

    const response = await fetcher.get(
      `${API_URL}/organizations/${organization_nid}/persons/null/hierarchicalScores`,
      { params },
    )
    return response
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getDepartmentsByOrganization(organizationId) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.get(
      `${API_URL}/organizations/${organizationId}/get_departments/`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getLocationsByOrganization(locationId) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.get(
      `${API_URL}/organizations/${locationId}/get_locations/`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
