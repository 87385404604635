import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const PrettyLogicalOperator = styled.div`
  font-weight: 500;
  padding: 1px 4px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #f2f2f2;
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid ${theme.colors.gray.opaque[200]};
  text-transform: uppercase;
`
