import React, { useState, useEffect, useMemo, useRef } from "react"
import { Input, Button } from "@src/components/MimecastReskin"

import { ALL_ACTION_CONFIGS } from "../actionConfigs"
import { ActionGroups as _ActionGroups } from "../actionConfigs/ActionGroups"
import {
  AddActionWrapper,
  GroupLabel,
  OptionButton,
  MenuWrapper,
  SearchWrapper,
  NoResults,
  OptionsWrapper,
} from "./AddActionMenu.styles"

export function AddActionMenu({
  addAction,
  isServiceIntegrated,
  enabled,
  addedActionTypes,
  allActionConfigs = ALL_ACTION_CONFIGS,
  ActionGroups = _ActionGroups,
}) {
  const [showOptions, setShowOptions] = useState(false)
  const [searchText, setSearchText] = useState("")
  const menuRef = useRef(null)

  const enabledActionConfigs = useMemo(
    () =>
      allActionConfigs.filter((config) =>
        config.isEnabled(isServiceIntegrated),
      ),
    [allActionConfigs, isServiceIntegrated],
  )

  const groupedActionConfigs = useMemo(
    () =>
      enabledActionConfigs.reduce((groups, actionConfig) => {
        const search = normalize(searchText)
        if (
          !searchText ||
          normalize(actionConfig.group.label).includes(search) ||
          normalize(actionConfig.label).includes(search)
        ) {
          if (!groups[actionConfig.group.id]) {
            groups[actionConfig.group.id] = []
          }
          groups[actionConfig.group.id].push(actionConfig)
        }
        return groups
      }, {}),
    [enabledActionConfigs, searchText],
  )

  useEffect(() => {
    const closeAddActionMenu = (e) => {
      if (showOptions && !menuRef.current?.contains(e.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener("click", closeAddActionMenu)
    return () => {
      document.removeEventListener("click", closeAddActionMenu)
    }
  }, [showOptions])

  return (
    <AddActionWrapper>
      <Button
        theme="secondary"
        disabled={!enabled}
        onClick={() => {
          setShowOptions((prev) => !prev)
        }}
      >
        + Add Action
      </Button>
      {showOptions && (
        <MenuWrapper ref={menuRef}>
          <SearchWrapper>
            <Input
              value={searchText}
              autoFocus
              onChange={(value) => {
                setSearchText(value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setShowOptions(false)
                }
              }}
              placeholder="Search..."
            />
          </SearchWrapper>
          <OptionsWrapper>
            {Object.entries(groupedActionConfigs).flatMap(
              ([groupId, actionConfigs]) => {
                return [
                  <GroupLabel key={groupId}>
                    {ActionGroups[groupId].label}:
                  </GroupLabel>,
                  ...actionConfigs.map(({ defaultPayload, label, group }) => {
                    return (
                      <OptionButton
                        key={defaultPayload.action_type}
                        disabled={
                          addedActionTypes.filter(
                            (type) => type === defaultPayload.action_type,
                          ).length >= group.actionLimitPerType
                        }
                        onClick={() => {
                          addAction(defaultPayload)
                          setShowOptions(false)
                        }}
                      >
                        {label}
                      </OptionButton>
                    )
                  }),
                ]
              },
            )}
            {Object.values(groupedActionConfigs).length === 0 && (
              <NoResults>No actions found</NoResults>
            )}
          </OptionsWrapper>
        </MenuWrapper>
      )}
    </AddActionWrapper>
  )
}

function normalize(value) {
  return value?.toLowerCase() ?? ""
}
