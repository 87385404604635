import React from "react"

import { EXECUTE_SAILPOINT_WORKFLOW } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { ActionGroups } from "./ActionGroups"
import { ActionConfig } from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionConfigs/actionConfigTypes"
import { EditExecuteSailPointWorkflow } from "@src/scenes/RiskResponseRuleCreateScreen/components/EditR3Actions/actionRenderComponents/EditExecuteSailPointWorkflow"

type Payload = {
  action_type: string
  matching_method: "delta" | "snapshot"
  client_id: string
  client_secret: string
  workflow_id: string
}

const config: ActionConfig<Payload> = {
  label: "Execute SailPoint Workflow",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: EXECUTE_SAILPOINT_WORKFLOW,
    client_id: "",
    client_secret: "",
    matching_method: "delta",
    workflow_id: "",
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("sailpoint", "execute-workflow"),
  render: ({ details, updateAction }) => {
    return (
      <EditExecuteSailPointWorkflow
        action={details}
        updateAction={updateAction}
      />
    )
  },
}

export default config
