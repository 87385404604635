import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

// Use in the render method of your Table column if you need to override
// wrapping and work break behaviour.
export const TableCellSpan = styled.span<{
  allowCellWrap?: boolean
  allowWordBreak?: boolean
}>`
  ${(props) =>
    !props.allowCellWrap
      ? `display: block;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;`
      : ""}
  ${(props) => (!props.allowWordBreak ? "" : "word-break : break-word;")}
  color: ${theme.text.color.default};
  font-size: 14px;
  padding-right: 10px;
`
