import React, { useState } from "react"
import { ComboBox } from "@elevate_security/elevate-component-library"
import {
  AddRemoveWrapper,
  IndividualsWrapper,
  PolicyGroupRowWrapper,
} from "./styles"
import {
  CISCO_DUO_ADD_INDIVIDUALS,
  CISCO_DUO_REMOVE_INDIVIDUALS,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { useRequest } from "ahooks"
import { getCiscoDuoDropDownElements } from "@src/services/apis/reputation"
import { NotificationRadioButtonGroup } from "./RiskRuleOutcome/Notifications"
import { NOTIFICATION_OPTIONS } from "./RiskRuleOutcome/constants"

const LIMIT = 25

export function CiscoDuoGroupsComboBox({ onChange, defaultValue }) {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalGroups, setTotalGroups] = useState(0)

  const { data: dropdownData, loading } = useRequest(
    () =>
      getCiscoDuoDropDownElements({
        search: `${search}`,
        limit: LIMIT,
        offset: page * LIMIT,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        if (page === 0) {
          setTotalGroups(response?.data?.count || 0)
          return (
            response?.data?.results?.map((group) => ({
              label: group.name?.replaceAll("_", " "),
              value: group.id,
              groupId: group.id,
            })) || []
          )
        } else {
          return [
            ...dropdownData,
            ...(response?.data?.results?.map((group) => ({
              label: group.name?.replaceAll("_", " "),
              value: group.id,
              groupId: group.id,
            })) ?? []),
          ]
        }
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
    setPage(0)
  }

  return (
    <ComboBox
      data={dropdownData}
      defaultValue={defaultValue}
      clearable={false}
      isLoading={loading}
      onChange={onChange}
      isSearchable={true}
      isMultiOption={true}
      checked={true}
      hideSelectedOptions={true}
      components={{}}
      placeholder="Select one or more Groups"
      handleScrollBotttom={() => {
        if (dropdownData.length < totalGroups) {
          setPage(page + 1)
        }
      }}
      handleInputChange={(value) => handleSetSearch(value)}
    />
  )
}

function CiscoDuoPolicyGroup({
  policyGroupData,
  setPolicyGroupData,
  policyGroupMatchingMethod,
  setPolicyGroupMatchingMethod,
}) {
  return (
    <PolicyGroupRowWrapper>
      <IndividualsWrapper>
        <AddRemoveWrapper>Add to Groups</AddRemoveWrapper>
        <PolicyGroupRowWrapper>
          <CiscoDuoGroupsComboBox
            defaultValue={policyGroupData?.[CISCO_DUO_ADD_INDIVIDUALS] || []}
            onChange={(selectedItem) =>
              setPolicyGroupData({
                ...policyGroupData,
                [CISCO_DUO_ADD_INDIVIDUALS]: selectedItem,
              })
            }
          />
        </PolicyGroupRowWrapper>
      </IndividualsWrapper>
      <IndividualsWrapper>
        <AddRemoveWrapper>Remove from Groups</AddRemoveWrapper>
        <PolicyGroupRowWrapper>
          <CiscoDuoGroupsComboBox
            defaultValue={policyGroupData?.[CISCO_DUO_REMOVE_INDIVIDUALS] || []}
            onChange={(selectedItem) =>
              setPolicyGroupData({
                ...policyGroupData,
                [CISCO_DUO_REMOVE_INDIVIDUALS]: selectedItem,
              })
            }
          />
        </PolicyGroupRowWrapper>
      </IndividualsWrapper>
      <IndividualsWrapper>
        <NotificationRadioButtonGroup
          title="Matching Method:"
          titleColor="700"
          titleFontWeight="bold"
          selected={policyGroupMatchingMethod}
          onChange={(e) => setPolicyGroupMatchingMethod(e?.target?.value)}
          options={NOTIFICATION_OPTIONS}
          name="notificationMethodCiscoDuoGroups"
        />
      </IndividualsWrapper>
    </PolicyGroupRowWrapper>
  )
}

export default CiscoDuoPolicyGroup
