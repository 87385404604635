import styled from "styled-components"

export const CriteriaResultsWrapper = styled.div`
  max-width: calc(100vw - 390px);
  width: 100%;
  padding: ${(props) => (props.shouldPreview ? "0 0 55px 0" : "0")};
  [class^="Tablestyles__TableContainer"] {
    min-height: auto !important;
    height: auto !important;
    padding: 0px;

    [class^="Tablestyles__TableBody"].table-body {
      min-height: auto !important;
      height: auto !important;
    }
  }
`
