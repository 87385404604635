import styled from "styled-components"
import "tippy.js/dist/svg-arrow.css"

export const VcStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-column-gap: 16px;
  justify-content: center;
  font-weight: bold;
  h3 {
    color: #34383c;
  }
`
export const VcStatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fdfbf8;
  width: 100%;
  height: 96px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 10px 0px;

  h3 {
    font-weight: 800;
    font-size: 32px;
  }
  p {
    font-weight: 700;
    color: #7b828a;
    font-size: 12px;
  }
  [class^="sc-ezrnTI"] {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
`
export const VcDataTrendStatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fdfbf8;
  width: 100%;
  height: 96px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 10px 0px;

  div[color="#34383C"]:first-child {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  p {
    font-weight: 700;
    color: #7b828a;
    font-size: 12px;
  }
`
export const VcHeader = styled.div`
  .MuiGrid-root {
    span {
      width: 285px;
    }
  }
`
export const VcDashBorder = styled.div`
  width: 32px;
  border-bottom: 2px dashed #1cb696;
  padding-top: 10px;
  border-radius: 2px;
  margin-right: 8px;
`
export const VcInsightWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #34383c;
  margin-bottom: 13px;
`

export const VcDataTrendsText = styled.span`
  color: #0c7d84;
  &:hover {
    cursor: pointer;
  }
`

export const FilterCSVWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  [class^="rc-slider"] {
    width: 328px;
    cursor: pointer;
  }
`
