import moment from "moment"
import React, { useCallback } from "react"
import DatePicker from "../DatePicker"

export default function DateRange({
  onChange,
  section,
  value,
  isClearable = true,
}) {
  const handleStartChange = useCallback(
    (val, event) => {
      handleChange(
        [val ? moment(val).format("YYYY-MM-DD") : null, value?.[1]],
        event,
      )
    },
    [value],
  )

  const handleEndChange = useCallback(
    (val, event) => {
      handleChange(
        [value?.[0], val ? moment(val).format("YYYY-MM-DD") : null],
        event,
      )
    },
    [value],
  )

  const handleChange = useCallback((value, event) => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()

    onChange({
      ...section,
      value,
    })
  }, [])

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
      <div className="title">{section.title}</div>
      <div style={{ display: "flex", gap: 5 }}>
        <DatePicker
          value={value?.[0]}
          placeholder="Start Date"
          onChange={handleStartChange}
          isClearable={isClearable}
          selectsStart
          closeOnScroll
        />
        <DatePicker
          value={value?.[1]}
          placeholder="End Date"
          onChange={handleEndChange}
          selectsEnd
          isClearable={isClearable}
          closeOnScroll
        />
      </div>
    </div>
  )
}
