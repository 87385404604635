import styled from "styled-components"
import Layout from "@elevate_security/elevate-component-library/dist/Layout"
import { Link as LinkLib } from "react-router-dom"

export const Link = styled(LinkLib)``

export const Row = styled(Layout).attrs({ container: true })`
  padding: 0;
  margin: 0;
`
export const Column = styled(Layout).attrs({ item: true })`
  padding: 0;
  margin: 0;
`
export const PageHeaderContainer = styled.div`
  h1 {
    margin: 16px 0 5px 0;
    line-height: normal;
  }
  a span {
    font-size: 13px;
  }
  div[class^="Breadcrumb__BreadcrumbContainer"] {
    svg {
      margin: 2px 3px 0 5px;
      width: 9px;
      height: 9px;
    }
  }
`
export const BreadcrumbLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: 2px 3px 0 5px;
    width: 9px;
    height: 9px;
  }
`
export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const DateContainer = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: #7b828a;
  text-align: end;
`

export const SubTitle = styled.div`
  margin-top: -8px;
  margin-bottom: 24px;
`
