import React, { useState } from "react"
import {
  Typography,
  Table,
  Drawer,
  Button,
  ButtonLink,
} from "@elevate_security/elevate-component-library"
import { FiltersToolbar } from "../SecEngIndividualRiskAnalysis/components/IndividualRiskAnalysisTable/components/FiltersToolbar"
import ActivityIndicator from "@src/components/ActivityIndicator"
import _useQuery from "@src/services/hooks/useQuery"
import { NavLink } from "react-router-dom"
import { createPortal } from "react-dom"
import { SpaceBetween } from "@src/components/SpaceBetween"
import {
  DefaultCell,
  DetectionsWrapper,
  BaseCell,
  DrawerWrapper,
} from "./DetectionsScreen.styles"
import { useSubscribeToLaunchDarklyFlag as _useSubscribeToLaunchDarklyFlag } from "@src/utils/darkly/useSubscribeToLaunchDarklyFlag"
import { DetectionDetails } from "./DetectionDetails"
import { useDetections as _useDetections } from "./useDetections"
import { useDetectionsFilters as _useDetctionsFilters } from "./useDetectionsFilters"

/*
 * This screen is suspicously similar to the Risk Analysis page, and it's
 * definitely possible that it could have been part of the RAP, but for speed of
 * implementation we decided to create a new page. The data is a bit different,
 * and and the RAP has lots of ad-hoc styling that likey would have been
 * difficult to contend with.
 */
export function DetectionsScreen({
  useSubscribeToLaunchDarklyFlag = _useSubscribeToLaunchDarklyFlag,
  useDetections = _useDetections,
  useDetectionsFilters = _useDetctionsFilters,
  useQuery = _useQuery,
}) {
  // For some reason flag changes after page render here, so we explicitly
  // subscribe to updates from LD
  const isItdrEnabled = useSubscribeToLaunchDarklyFlag("itdr-behaviors")

  const [pagination, setPagination] = useState({ limit: 25, offset: 0 })
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false)
  const [currentRow, setCurrentRow] = useState({})

  const filters = useQuery().get("filters")
  const orderBy = useQuery().get("order_by") || "event_datetime desc"

  const {
    data: detections,
    loading: isLoadingDetections,
    error: detectionsError,
  } = useDetections({
    when: isItdrEnabled,
    pagination,
    filters,
    orderBy,
  })

  const {
    data: detectionsFilters,
    loading: isLoadingFilters,
    error: filtersError,
  } = useDetectionsFilters({ when: isItdrEnabled })

  if (!isItdrEnabled) {
    return <div>Not found</div>
  }

  if (detectionsError || filtersError) {
    return <div>An error occurred, please reload the page.</div>
  }

  const loading = isLoadingDetections || isLoadingFilters
  const currentPage = pagination.offset / pagination.limit + 1

  const columns = [
    {
      key: "event",
      header: "EVENT",
      render: (val, row) => {
        return (
          <BaseCell>
            <ButtonLink
              style={{ fontSize: "14px" }}
              onClick={() => {
                setCurrentRow(row)
                setShowAdditionalDetails(true)
              }}
            >
              {val}
            </ButtonLink>
          </BaseCell>
        )
      },
    },
    {
      key: "full_name",
      header: "FULL NAME",
      render: (val, row) => {
        return (
          <DefaultCell>
            <NavLink to={`/engagement/vision2/${row.es_person_id}/profile`}>
              {val}
            </NavLink>
          </DefaultCell>
        )
      },
    },
    {
      key: "event_datetime",
      header: "DATETIME",
      cell: BaseCell,
    },
    {
      key: "data_source",
      header: "AUTH DATA SOURCE",
    },
    {
      key: "application",
      header: "APPLICATION",
    },
  ].map((col) => {
    const Cell = col.cell || DefaultCell
    if (!col.render) {
      col.render = (val) => <Cell>{val}</Cell>
    }
    delete col.cell
    return col
  })

  return (
    <DetectionsWrapper>
      {createPortal(
        <Drawer
          direction="right"
          isOpen={showAdditionalDetails}
          toggleDrawer={(val) => {
            setShowAdditionalDetails((prev) => val ?? !prev)
          }}
        >
          <DrawerWrapper>
            <SpaceBetween>
              <SpaceBetween direction="row">
                <Typography.H2 fontWeight="heavy" color="700">
                  Additional Details
                </Typography.H2>
                <Button
                  onClick={() => setShowAdditionalDetails(false)}
                  theme="ghost"
                >
                  Close
                </Button>
              </SpaceBetween>
              <DetectionDetails {...currentRow} />
            </SpaceBetween>
          </DrawerWrapper>
        </Drawer>,
        document.body,
      )}
      <Typography.H1 fontWeight="heavy" color="700" style={{ margin: "0" }}>
        Detections
      </Typography.H1>
      <FiltersToolbar
        riskTypeOverride="detections"
        showAdvancedFilters={false}
        showColumnChooser={false}
        showBulkActions={false}
        showCreateRiskResponseRule={false}
        optionsOverride={detectionsFilters}
        labelOverrides={{ behaviorType: "Detection type" }}
        showTableMode={false}
      />
      <div>
        {loading && <ActivityIndicator active />}
        {!loading && (
          <Table
            manual
            totalCount={detections.total_elements}
            data={detections.results}
            defaultPage={currentPage}
            defaultPageSize={pagination.limit}
            onChangeTable={({ pagination }) => {
              setPagination(pagination)
            }}
            columns={columns}
            // Disable all sorting because I was just too lazy to implement the
            // sorting right away
            excludeSortColumn={columns.map((c) => c.key)}
          />
        )}
      </div>
    </DetectionsWrapper>
  )
}
