import styled from "styled-components"

export const HumanRiskAreasContainer = styled.div`
  .risk-areas-row {
    width: 100%;
    border-top: 1px solid #dde1e7;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;

    :first-child {
      border-top: none;
    }
    :last-child {
      border-bottom: none;
    }

    div {
      float: left;
      width: 100%;
      height: 100%;
      border-left: 1px solid #dde1e7;
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      :first-child {
        border-left: none;
      }
      :last-child {
        border-right: none;
      }
    }
  }
`

export const ActionFactorScoreChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  width: 100%;
  @media only screen and (max-width: 1620px) {
    width: 100%;
    padding-right: unset;
  }
`

export const HumanRisksIndividualsContainer = styled.div`
  margin-top: 30px;
  width: 640px;
  margin-left: auto;
  margin-right: auto;
`

export const RiskDecisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  h2 {
    color: #34383c;
    justify-self: center;
  }

  p {
    border: 1px solid #dde1e7;
    padding: 5px 7px;
    border-radius: 19px;
    color: #7b828a;
    font-weight: bold;
    font-size: 12px;
    justify-self: start;
  }
  .first {
    color: #fff;
    background-color: #000;
    width: 72px;
    height: 32px;
    text-align: center;
  }

  .risk-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 236px;
  }

  .description {
    color: #7b828a;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }
`
export const HeadingText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
  margin: 32px 0 8px 0;
`

export const HeadingTitleText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
  margin-bottom: 8px;
`

export const SubHeadingText = styled.p`
  line-height: 24px;
  margin: 0px 0 8px 0;
  color: #7b828a;
  font-size: 14px;
  font-weight: normal;
`
