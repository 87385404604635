import React, { useEffect, useState } from "react"
import {
  InsightPageTitleContainer,
  InsightPageH1Container,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import PageHeader from "@src/components/PageHeader"
import { getLastUpdateTimeLabel } from "@src/scenes/HumanRiskDashboard/utils.js"
import { BREADCRUMB_INSIGHT_LINKS } from "./utils"
import { getLastScoresUpdate } from "@src/services/redux/company/selectors"
import IndividualInsightPercentage from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/IndividualInsightPercentage"
import InsightDataProvider from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/InsightDataProvider"

function ActionableInsightsDetails() {
  const [lastScoreUpdate, setLastScoreUpdate] = useState(null)
  const actionableInsightData = JSON.parse(sessionStorage.data)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    async function getLastScoresUpdateHandler() {
      const lastScoreUpdateDate = await getLastScoresUpdate()
      setLastScoreUpdate(lastScoreUpdateDate)
    }
    getLastScoresUpdateHandler()
  }, [])

  return (
    <>
      <PageHeader
        breadcrumbLinks={BREADCRUMB_INSIGHT_LINKS}
        title={
          <InsightPageTitleContainer>
            <InsightPageH1Container>
              <h1>Actionable Insight</h1>
            </InsightPageH1Container>
          </InsightPageTitleContainer>
        }
        lastUpdatedTimeLabel={getLastUpdateTimeLabel(lastScoreUpdate)}
      />
      <IndividualInsightPercentage
        item={actionableInsightData}
        setIsVisible={setIsVisible}
      />
      {isVisible && <InsightDataProvider item={actionableInsightData} />}
    </>
  )
}

export default ActionableInsightsDetails
