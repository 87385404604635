import React from "react"

import { AZURE_ADD_INDIVIDUALS } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { ActionGroups } from "./ActionGroups"
import { EditAzureAdGroups } from "../actionRenderComponents/EditAzureAdGroups"

export default {
  label: "Add users to Azure AD Groups",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: AZURE_ADD_INDIVIDUALS,
    matching_method: "delta",
    target_groups: [],
    target_groups_meta: [],
    target_system: "azuread",
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("azuread", "group-membership"),
  render: ({ details, updateAction }) => {
    return (
      <EditAzureAdGroups
        title="Add to Groups"
        action={details}
        updateAction={updateAction}
      />
    )
  },
}
