import styled from "styled-components"

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

export const IconWrapper = styled.div`
  height: 50px;
  width: 50px;
`
