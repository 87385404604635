import styled from "styled-components"
import { TableCard } from "@src/components/TableCard"

export const EventLogWrapper = styled(TableCard)`
  [class^="EventsLogstyles__EventLogSeverityData"] {
    word-break: break-all;
  }
  [class^="EventsLogstyles__EventLogAdditional"] {
    overflow-x: hidden;
  }
  [class^="EventsLogstyles__EventLogsWrapper"] {
    margin: 0;
  }
  [class^="EventsLogstyles__FilterContainer"] {
    margin: 0;
  }
  padding: 15px 15px 0px;
`
