import React from "react"
import { useSelector } from "react-redux"
import { Input, Typography } from "@elevate_security/elevate-component-library"
import { Form as FinalForm, Field } from "react-final-form"
import PropTypes from "prop-types"
import { HiddenInput, Row, ValidationDiv } from "./style"

const { Small, Text } = Typography

const Form = ({
  handleBlur,
  formFields,
  addRow,
  horizontal,
  handleSubmit,
  showExcludeInclude,
}) => {
  const policyState = useSelector((state) => state.get("policyReducer"))
  const validateForm = () => false

  const formHandleBlur = (e, fieldName, fieldType) => {
    if (handleBlur) {
      handleBlur(e, fieldName, fieldType)
    }
  }
  const borderHandler = (error, exist, index) => {
    if (index === 0 && (error || exist)) {
      return "1px solid red"
    }
    return "1px solid hsla(215,18%,78%,0.5)"
  }
  const onSubmit = (e) => {
    e.preventDefault()
    const errors = validateForm()

    if (!errors) {
      handleSubmit(e)
    }
  }

  const renderFormElement = (fieldType, fieldName, fieldProps, index) => (
    <Field
      name={fieldName}
      required={fieldProps.required}
      value={fieldProps.value}
    >
      {() => (
        <div>
          <Input
            {...fieldProps}
            value={fieldProps.defaultValue}
            disabled={fieldProps.disabled}
            autoFocus={fieldProps.autoFocus}
            onChange={fieldProps.onChange}
            onBlur={(e) => formHandleBlur(e, fieldName, fieldType)}
            style={{
              backgroundColor: "#FFFFFF",
              border: borderHandler(
                policyState.errorDetected,
                policyState.existingRule,
                index,
              ),
            }}
          />
          {index === 0 && policyState.errorDetected && (
            <ValidationDiv>Rule name cannot be empty</ValidationDiv>
          )}
          {index === 0 && policyState.existingRule && (
            <ValidationDiv>
              It looks like this name is already in use. Try another?
            </ValidationDiv>
          )}
        </div>
      )}
    </Field>
  )

  return (
    <FinalForm
      name="rdr-modal"
      onSubmit={onSubmit}
      render={() => (
        <form onSubmit={onSubmit} id="submit-form">
          <div className={addRow ? "space-between" : null}>
            {formFields.map(
              (
                {
                  fieldName,
                  fieldType,
                  fieldProps,
                  helpText,
                  label,
                  preFieldHelpText,
                },
                index,
              ) =>
                horizontal ? (
                  <div
                    key={`${label}_${index}`}
                    className={
                      fieldProps.className !== "" ? fieldProps.className : null
                    }
                    hidden={fieldProps.hidden}
                  >
                    <Row>
                      <Text color="500" fontWeight="500" margin="0 2rem 0 0">
                        {label}
                      </Text>
                      {preFieldHelpText || ""}
                      <div className="mt-2 align-right">
                        {!preFieldHelpText &&
                          renderFormElement(
                            fieldType,
                            fieldName,
                            fieldProps,
                            index,
                          )}
                      </div>
                      {helpText && (
                        <div>
                          <Small margin="0 0 0 1rem">{helpText}</Small>
                        </div>
                      )}
                    </Row>
                  </div>
                ) : (
                  <div
                    className={
                      fieldProps.className !== "" ? fieldProps.className : null
                    }
                    key={fieldName}
                    hidden={fieldProps.hidden}
                  >
                    <div className="form-group">
                      <Text color="700" fontWeight="bold">
                        {label}
                      </Text>
                      {preFieldHelpText || ""}
                      <div className="mt-2">
                        {!preFieldHelpText &&
                          renderFormElement(
                            fieldType,
                            fieldName,
                            fieldProps,
                            index,
                          )}
                      </div>
                      {helpText && (
                        <div>
                          <Small>{helpText}</Small>
                        </div>
                      )}
                    </div>
                  </div>
                ),
            )}
          </div>
          <HiddenInput type="submit" id="hidden-input" />
          {showExcludeInclude()}
        </form>
      )}
    />
  )
}

Form.propTypes = {
  addRow: PropTypes.bool,
  horizontal: PropTypes.bool,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      helpText: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  organizations: PropTypes.array,
}

Form.defaultProps = {
  horizontal: false,
  addRow: false,
  formFields: [],
  handleChange: null,
  handleSubmit: null,
  organizations: null,
  handleBlur: null,
}

export default Form
