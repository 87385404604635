import styled from "styled-components"

export const ActionButtonWrapper = styled.div`
  @keyframes action-button-wrapper-custom-pulse {
    from {
      box-shadow: 0 0 0 0 rgba(20, 197, 210, 0.8);
    }
    to {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }

  line-height: 12px;

  button,
  button:focus {
    height: auto;
    min-height: 24px;
    padding: 2px 5px;
    line-height: inherit;
    ${(props) =>
      props.pulse
        ? "animation: 1.5s infinite ease-in-out action-button-wrapper-custom-pulse"
        : ""}
  }
`
