// A place to put z-index values that might conflict with each other, so we
// don't have to chase everywhere to update them. Ideally we would just use DOM
// hierarchy to dertermine stacking order, but alas that's much easier said than
// done when there are lots of z-index values all over the place from across the
// years, and when we're embedding in other front-ends that have their own mess
// of styles :(

const MIMECAST_CI_MENU_BAR_Z_INDEX = 1100

// Needs to be more than Mimecast CI header
export const SIDE_DRAWER_Z_INDEX = MIMECAST_CI_MENU_BAR_Z_INDEX + 100

// Needs to be more than SideDrawer
export const LATEST_EVENTS_MODAL_Z_INDEX = SIDE_DRAWER_Z_INDEX + 100
