import React from "react"
import { stringToBase64 } from "@src/utils/string"

import { EditCustomListEmailNotification } from "../actionRenderComponents/EditCustomListEmailNotification"
import { ActionGroups } from "./ActionGroups"

import { CustomEmailTemplate } from "../../RiskRuleOutcome/EmailTemplates"
import { SEND_EMAIL_NOTIFICATION_TO_CUSTOM } from "../../RiskRuleOutcome/constants"

import { ActionConfig } from "./actionConfigTypes"
import { validateEmailActionPayload } from "./validators/validateEmailActionPayload"
import { validateObject } from "@src/utils/validateObject"

type Payload = {
  action_type: typeof SEND_EMAIL_NOTIFICATION_TO_CUSTOM
  matching_method: "snapshot" | "delta"
  subject: string
  message: string
  send_persons_as_attachment: boolean
  emails: string[]
  group_ids: string[]
}

const config: ActionConfig<Payload> = {
  label: "Custom list",
  group: ActionGroups.EMAIL,
  defaultPayload: {
    action_type: SEND_EMAIL_NOTIFICATION_TO_CUSTOM,
    matching_method: "delta",
    subject: stringToBase64(
      "{{rule.name}} risk response rule has been triggered",
    ),
    message: stringToBase64(CustomEmailTemplate),
    send_persons_as_attachment: false,
    emails: [],
    group_ids: [],
  },
  validate: (payload) => {
    return [
      ...validateEmailActionPayload(payload),
      ...validateObject(payload, [
        [
          "emails",
          ({ group_ids, emails }) => group_ids.length > 0 || emails.length > 0,
          "At least one email address must be specified if no groups are selected",
        ],
        [
          "group_ids",
          ({ group_ids, emails }) => emails.length > 0 || group_ids.length > 0,
          "At least one group must be selected if no emails are specified",
        ],
      ]),
    ]
  },
  isEnabled: () => true,
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <EditCustomListEmailNotification
        details={details}
        updateAction={updateAction}
        templateAttributes={templateAttributes}
      />
    )
  },
}

export default config
