import {
  SET_HIERARCHICAL_SCORES_MONTHLY,
  SET_SCORES_BY_INDIVIDUALS,
  SET_SCORES_BY_DEPARTMENT,
  SET_SCORES_BY_LOCATION,
  SET_HIERARCHICAL_CAMPAIGNS_SCORES,
  SET_RISKIEST_DEPARTMENT,
  SET_RECOMMENDED_INDIVIDUALS_FOR_HM,
  SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE,
  SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX,
  SET_TOTAL_INDIVIDUALS_COUNT,
} from "./types"

const INITIAL_STATE = {
  hierarchyScoresMonthly: {
    data: [],
    isLoaded: false,
  },
  scoresByIndiviuals: {
    data: null,
    isLoaded: false,
  },
  scoresByDepartment: {
    data: null,
    isLoaded: false,
  },
  scoresByLocation: {
    data: null,
    isLoaded: false,
  },
  historicalCampaignsScores: {
    data: null,
    isLoaded: false,
  },
  riskiestDepartment: {
    data: null,
    isLoaded: false,
  },
  recommendedIndividualsForHM: {
    data: null,
    isLoaded: false,
  },
  recommendedIndividualsForPulse: {
    data: null,
    isLoaded: false,
  },
  recommendedIndividualsForReflex: {
    data: null,
    isLoaded: false,
  },
  totalIndividualsCount: {
    data: null,
    isLoaded: false,
  },
}

export default function scoreReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_HIERARCHICAL_SCORES_MONTHLY:
      return {
        ...state,
        hierarchyScoresMonthly: {
          ...state.hierarchyScoresMonthly,
          ...action.data,
        },
      }
    case SET_SCORES_BY_INDIVIDUALS:
      return {
        ...state,
        scoresByIndiviuals: { ...state.scoresByIndiviuals, ...action.data },
      }
    case SET_SCORES_BY_DEPARTMENT:
      return {
        ...state,
        scoresByDepartment: { ...state.scoresByDepartment, ...action.data },
      }
    case SET_SCORES_BY_LOCATION:
      return {
        ...state,
        scoresByLocation: { ...state.scoresByLocation, ...action.data },
      }
    case SET_HIERARCHICAL_CAMPAIGNS_SCORES:
      return {
        ...state,
        historicalCampaignsScores: {
          ...state.historicalCampaignsScores,
          ...action.data,
        },
      }
    case SET_RISKIEST_DEPARTMENT: {
      return {
        ...state,
        riskiestDepartment: { ...state.riskiestDepartment, ...action.data },
      }
    }
    case SET_RECOMMENDED_INDIVIDUALS_FOR_HM: {
      return {
        ...state,
        recommendedIndividualsForHM: {
          ...state.recommendedIndividualsForHM,
          ...action.data,
        },
      }
    }
    case SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE: {
      return {
        ...state,
        recommendedIndividualsForPulse: {
          ...state.recommendedIndividualsForPulse,
          ...action.data,
        },
      }
    }
    case SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX: {
      return {
        ...state,
        recommendedIndividualsForReflex: {
          ...state.recommendedIndividualsForReflex,
          ...action.data,
        },
      }
    }
    case SET_TOTAL_INDIVIDUALS_COUNT: {
      return {
        ...state,
        totalIndividualsCount: {
          ...state.totalIndividualsCount,
          ...action.data,
        },
      }
    }
    default:
      return state
  }
}
