import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useToggle } from "ahooks"
import { useParams } from "react-router-dom"

import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Modal from "@elevate_security/elevate-component-library/dist/Modal"
import Button from "@elevate_security/elevate-component-library/dist/Button"
import IndividualsTable from "../components/IndividualsTable"
import {
  ModalWrapper,
  Title,
  SubTitle,
  OptionText,
  SlackMessage,
  ThankYouText,
  Summary,
} from "../styles"

import {
  sendThankYouEmailToTopPerformers,
  getTopPerformers,
} from "@src/scenes/ManagerDashboard/services/redux/actions"
import { getTopPerformersCount } from "@src/scenes/ManagerDashboard/services/redux/selectors"

function TopPerformers() {
  const [showModal, { toggle: toggleShowModal }] = useToggle(false)
  const [showWhooHoo, { toggle: toggleWhooHoo }] = useToggle(false)
  const dispatch = useDispatch()
  const { person_nid } = useParams()
  const topPerformersCount = useSelector(getTopPerformersCount)

  const handleToggle = () => toggleShowModal()
  const handleWhooHooToggle = () => toggleWhooHoo()

  const onSendEmail = () => {
    dispatch(sendThankYouEmailToTopPerformers(person_nid))
    handleToggle()
    handleWhooHooToggle()
  }

  const SecurityScoreSummary = () => (
    <Summary>
      <SubTitle>Security Score Summary</SubTitle>
      <IndividualsTable
        behaviorName="an overall security"
        threshold="> 90"
        actionCall={(params) => getTopPerformers({ ...params, person_nid })}
        iconToRender={<Icon name="CircleCheckOutline" fill="#15785F" />}
      />
    </Summary>
  )

  const RewardTopPerformers = () => (
    <>
      <SubTitle>Reward top performers:</SubTitle>
      <OptionText>
        <b>Option 1:</b> Send a message with the suggested copy to your team:
      </OptionText>
      <SlackMessage>
        Hey team, great job on making it on this month’s top performers list!
        Thank you for taking positive security actions and lowering risk at our
        company. Keep up the great work!
      </SlackMessage>
      <OptionText style={{ marginBottom: 16 }}>
        <b>Option 2:</b> Send individuals with an overall security score {"> "}
        90 a thank you email.
      </OptionText>
      <Button width="full" onClick={onSendEmail} disabled={!topPerformersCount}>
        Send Thank You Email
      </Button>
    </>
  )

  const renderModal = () => (
    <ModalWrapper>
      <Modal
        onClose={handleToggle}
        header={<Title>Top Performers</Title>}
        isOpen={showModal}
      >
        <SecurityScoreSummary />
        <RewardTopPerformers />
      </Modal>
    </ModalWrapper>
  )

  const renderWhooHooModal = () => (
    <ModalWrapper>
      <Modal
        onClose={handleWhooHooToggle}
        header={
          <Title style={{ marginLeft: "auto" }}>
            Whoo Hoo! Thank You Email Sent!
          </Title>
        }
        isOpen={showWhooHoo}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src="https://hm-assets.elevatesecurity.com/master/manager-dashboard/letter-celebrate.png"
            alt=""
            width="180"
            height="180"
          />
          <ThankYouText>
            Nice job! You have sent a Thank You email to all individuals on your
            team with an overall security score {">"} 90.
          </ThankYouText>
        </div>
      </Modal>
    </ModalWrapper>
  )

  return (
    <>
      <br />
      <Button theme="secondary" onClick={handleToggle}>
        Reward Top Performers
      </Button>
      {renderModal()}
      {renderWhooHooModal()}
    </>
  )
}

TopPerformers.propTypes = {}

export default TopPerformers
