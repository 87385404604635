import React from "react"
import PropTypes from "prop-types"
import pluralize from "pluralize"
import dompurify from "dompurify"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"

import { Wrapper, TextRow } from "./styles"

const sanitizer = dompurify.sanitize
const { Text } = Typography

const ScoreTooltip = ({ score, scoreTrend, insights }) => {
  const { highlights } = insights

  const renderHighlights = () => (
    <div>
      {/* First highlight in somputed on the FE */}
      <TextRow>
        <Text
          fontSize="16px"
          style={{ fontWeight: "normal", color: "#34383C" }}
        >
          • Your team’s current security score of{" "}
          <span style={{ fontWeight: "bold" }}>{score}</span> went
          <span style={{ fontWeight: "bold" }}>
            {scoreTrend < 0 ? <> down </> : <> up </>}
            by {pluralize("point", Math.abs(scoreTrend), true)}
          </span>{" "}
          since last month.
        </Text>
      </TextRow>
      {/* Rest of the higlights are coming from the insights from API */}
      {highlights &&
        Array.isArray(highlights) &&
        highlights.map((_highlight, index) => (
          <TextRow key={index}>
            <Text
              fontSize="16px"
              style={{ fontWeight: "normal", color: "#34383C" }}
            >
              •{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizer(_highlight),
                }}
              ></span>
            </Text>
          </TextRow>
        ))}
    </div>
  )

  return <Wrapper>{renderHighlights()}</Wrapper>
}

ScoreTooltip.defaultProps = {
  score: 0,
  scoreTrend: 0,
  insights: {},
}

ScoreTooltip.propTypes = {
  score: PropTypes.number,
  scoreTrend: PropTypes.number,
  insights: PropTypes.shape({
    highlights: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default ScoreTooltip
