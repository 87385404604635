import { IconAction } from "@elevate_security/elevate-component-library"
import React from "react"
import { ChipLoadingWrapper } from "./styles"
import ActivityIndicator from "@src/components/ActivityIndicator"

export const rightLabels = (groups) => {
  const MAX_GROUPS_SHOW = 2
  if (groups.length > MAX_GROUPS_SHOW) {
    return `Belongs to ${groups.slice(0, MAX_GROUPS_SHOW).join(", ")} + ${
      groups.length - MAX_GROUPS_SHOW
    } more`
  }
  if (groups.length) {
    return `Belongs to ${groups.join(", ")}`
  }
}

export const listIndividuals = (
  array1,
  recipientIndividual,
  setRecipientIndividual,
) => {
  const array2 = []
  array1.forEach((element) => {
    array2.push({
      id: element.id,
      label: `${element.full_name} (${element.email})`,
      value: element.email,
      email: element.email,
      isDisabled: !!element?.groups.length,
      isSelected: !!element?.groups.length,
      rightLabel: rightLabels(element?.groups || []),
    })
  })

  const data = array2
    .filter((item) => item.isDisabled)
    .map((item) => item.value)
  setRecipientIndividual(
    recipientIndividual.filter((item) => !data.includes(item.value)),
  )
  return array2
}

export const setInitialData = (individualsMails) => {
  const array = []
  individualsMails.forEach((item) =>
    array.push({
      label: item,
      value: item,
      email: item,
      isDisabled: false,
      isSelected: false,
      rightLabel: "",
    }),
  )
  return array
}
export const getReceptionGroupData = (receptionGroupData) => {
  return receptionGroupData?.map((item) => `"${item.value}"`).join(",")
}

export const FIXED_CHIP_LABEL = `Any individuals who belong to selected Recipient Group(s) will automatically appear here`

export const loadingChip = () => {
  return [
    {
      fixedLabel: (
        <ChipLoadingWrapper>
          Loading...{" "}
          <div
            style={{
              position: "relative",
              width: "20px",
              marginLeft: "10px",
            }}
          >
            <ActivityIndicator active={true} size={12} border={1} />
          </div>
        </ChipLoadingWrapper>
      ),
    },
  ]
}

export const ShowRightIcon = () => {
  return (
    <span style={{ marginLeft: "5px" }}>
      <IconAction
        name="InfoCheckOutline"
        tooltipText={<div>{FIXED_CHIP_LABEL}</div>}
        renderTooltipOnHover
        size="xsm"
      />
    </span>
  )
}

export const getDropdownData = (
  receptionGroupData,
  recipientIndividual,
  firstItem,
) => {
  if (
    receptionGroupData.length &&
    !recipientIndividual.some((item) => item.fixedLabel)
  ) {
    return firstItem.concat(recipientIndividual)
  } else {
    return recipientIndividual
  }
}
