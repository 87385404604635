import styled from "styled-components"

export const SparkGroupChart = styled.svg`
  width: 100%;
`
export const SparkRow = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => (!padding ? "initial" : padding)};
  align-items: ${({ alignItems }) => (!alignItems ? "initial" : alignItems)};
  justify-content: ${({ justifyContent }) =>
    !justifyContent ? "initial" : justifyContent};
`
export const SparkColumn = styled.div`
  display: flex;
  width: ${({ width }) => (!width ? "auto" : width)};
  min-width: ${({ minWidth }) => (!minWidth ? "auto" : minWidth)};
  padding: 8px;
  flex-direction: column;
  flex-wrap: nowrap;
`
