// libraries
import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { theme } from "@elevate_security/elevate-component-library"

// styles
import { ScoreHorizontalBarWrapper, ScoreGained, Row, Column } from "./styles"

// utils
import { getScoreMessage } from "./utils"
import BarColors from "./BarColors"

const { Text, H3, H1 } = Typography

export default function ScoreHorizontalBar(props) {
  const { score, scoreLastMounth, scoreTitle, showOrgScoreMessage } = props

  function renderDifferenceOfScores() {
    const diff = parseInt(score) - parseInt(scoreLastMounth)
    return (
      <ScoreGained diff={diff}>
        <Text fontWeight="bold">
          {diff > 0 && (
            <Fragment>
              <Icon name="TrendUp" /> +{diff} pts higher than 30 days ago
            </Fragment>
          )}
          {diff < 0 && (
            <Fragment>
              <Icon name="TrendDown" /> {diff} pts lower than 30 days ago
            </Fragment>
          )}
          {diff === 0 && (
            <Fragment>
              <Icon name="TrendNeutral" />
              &nbsp;{diff} pts from 30 days ago
            </Fragment>
          )}
        </Text>
      </ScoreGained>
    )
  }

  return (
    <ScoreHorizontalBarWrapper>
      <Row alignItems="baseline">
        <H1 fontWeight="bold" color="900" fontSize="lg">
          {score}
        </H1>
        <H3 fontWeight="bold" color="900">
          &nbsp;&nbsp;{scoreTitle}
        </H3>
      </Row>
      <Row justifyContent="space-between" alignItems="center">
        <Column>{renderDifferenceOfScores()}</Column>
        {showOrgScoreMessage && (
          <Column>
            <H3
              fontWeight="bold"
              style={{ color: theme.text.color[700], fontSize: 18 }}
            >
              {getScoreMessage({ score })}&nbsp;
            </H3>
          </Column>
        )}
      </Row>
      <BarColors score={score} hasIndicatorBox />
    </ScoreHorizontalBarWrapper>
  )
}

ScoreHorizontalBar.defaultProps = {
  score: 0,
  scoreLastMounth: 0,
  scoreTitle: "Security Score",
  showOrgScoreMessage: false,
}

ScoreHorizontalBar.propTypes = {
  score: PropTypes.number,
  scoreLastMounth: PropTypes.number,
  scoreTitle: PropTypes.string,
  showOrgScoreMessage: PropTypes.bool,
}
