import React from "react"

import { SpaceBetween } from "@src/components/SpaceBetween"
import { NotificationRadioButtonGroup } from "../../../RiskRuleOutcome/Notifications"
import { NOTIFICATION_OPTIONS } from "../../../RiskRuleOutcome/constants"

export function EditAzureAdConfirmCompromisedUser({ action, updateAction }) {
  return (
    <SpaceBetween xs={{ padding: "15px 0px" }}>
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={action.matching_method}
        onChange={(e) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
      />
    </SpaceBetween>
  )
}
