import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { TimePicker, DatePicker as AntDatePicker } from "antd"
import dayjs from "dayjs"
import moment from "moment"
import "moment-timezone"
import {
  CustomSelect,
  ComboBox,
  TimezonePicker,
} from "@elevate_security/elevate-component-library"

import Checkbox from "@src/components/Checkbox"
import { DayPicker } from "./DayPicker/DayPicker"
import {
  OCCURRENCE,
  occurrenceOptions,
  customHoursOptions,
  executionMinutesOptions,
  monthDaysOptions,
  monthsOptions,
  runningIntervalOptions,
  initialStateMap,
  sortedMonthMap,
} from "./constants"
import { convertTo24Hours, getLocaltimezone, removeEmpty } from "./utils"
import {
  SchedulerWrapper,
  SchedulerContent,
  DatesContainer,
  Label,
  InputRow,
  OccurrenceSelectWrapper,
} from "./styles"
import { isEmpty } from "@src/utils/string"
import { getCompanyInfo } from "@src/services/redux/company/selectors"

const format = "hh:mm A" // as per antd specification
const localTimezone = getLocaltimezone() // local machine timezone

export const Scheduler = memo((props) => {
  const { scheduler, onChange, isValid } = props
  const companyInfo = getCompanyInfo()

  const [state, setState] = useState(
    scheduler ?? {
      start_date: moment.tz(localTimezone).format("YYYY-MM-DD"),
      cadence: OCCURRENCE.DAY,
      hour: "13",
      minute: 0,
      timezone: companyInfo.default_timezone ?? localTimezone,
    },
  )

  const [hasEndDate, setHasEndDate] = useState(false)
  const [interval, setSchedulerInterval] = useState(
    scheduler?.interval ??
      // If the cadence is interval, but there is no interval specified, we
      // assume custom, and initialize the state accordingly
      (scheduler?.cadence === OCCURRENCE.INTERVAL ? "custom" : "1"),
  )

  const onDaySelect = (value) => setState({ ...state, day_of_week: value })

  const handleChangeOnOccurrence = (selected) => {
    const { value } = selected
    setState({
      ...initialStateMap[value],
      cadence: value,
      timezone: state.timezone,
    })
  }

  const handleOnChangeInterval = (selected) => {
    const { value } = selected
    switch (value) {
      case "1":
      case "2":
      case "3":
      case "4":
      case "6":
      case "8":
      case "12":
      case "24": {
        setSchedulerInterval(value)
        setState({ ...state, interval: value })
        break
      }
      case "custom": {
        setSchedulerInterval(value)
        const stateCopy = { ...state, hour: "1", minute: 0 }
        delete stateCopy.interval
        setState(stateCopy)
        break
      }
      default:
        setState({ ...state, interval: "1" })
        break
    }
  }

  const handleOnChangeMinute = (selected) =>
    setState({ ...state, minute: selected.value })

  const handleOnChangeExecutionTime = (_, timeString) => {
    const [time, mode] = timeString?.split(" ") ?? [] // e.g: timeString 01:30 AM
    const [hour, minute] = time?.split(":") ?? []
    const hours24 = convertTo24Hours(parseInt(hour), mode)
    setState({ ...state, hour: `${hours24}`, minute: parseInt(minute) })
  }

  const handleOnChangeStartDate = (_, dateString) => {
    setState({
      ...state,
      start_date: moment(dateString).format("YYYY-MM-DD"),
    })
  }

  const handleOnChangeEndDate = (_, dateString) => {
    setState({
      ...state,
      end_date: moment(dateString).format("YYYY-MM-DD"),
    })
  }

  const handleOnChangeOneTimeExecutionTime = (_, dateString) => {
    const [date, time, mode] = dateString?.split(" ") ?? [] // e.g: dateString: 2023-07-22 05:50 PM
    const momentDate = moment(date)
    const [hour, minute] = time?.split(":") ?? []
    const hours24 = convertTo24Hours(parseInt(hour), mode)

    setState({
      ...state,
      year: parseInt(momentDate.format("YYYY")),
      month: momentDate.format("MMMM"),
      day: momentDate.format("DD"),
      hour: `${hours24}`,
      minute: parseInt(minute),
    })
  }

  const handleOnTimezoneChange = (e) => {
    // Convert local current date to selected timezone date current date
    const currentDateInTimezone = new Date(
      new Date().toLocaleString("en-US", { timeZone: e }),
    )

    setState((state) => ({
      ...state,
      timezone: e,
      start_date: moment(currentDateInTimezone).format("YYYY-MM-DD"),
      end_date: null,
    }))
  }

  const shouldShowExecutionTime = () => {
    return (
      state.cadence === OCCURRENCE.DAY ||
      state.cadence === OCCURRENCE.WEEK ||
      state.cadence === OCCURRENCE.MONTH ||
      state.cadence === OCCURRENCE.YEAR
    )
  }

  const shouldShowOneTimeExecutionTime = () => {
    return (
      state.cadence === OCCURRENCE.ASAP || state.cadence === OCCURRENCE.ONE_TIME
    )
  }

  const shouldShowRepeatOn = () => {
    return state.cadence === OCCURRENCE.WEEK
  }

  const shouldShowSpecificHours = () => {
    return (
      state.cadence === OCCURRENCE.INTERVAL &&
      (interval === "custom" || isEmpty(state?.interval))
    )
  }

  const shouldShowRunningInterval = () => {
    return state.cadence === OCCURRENCE.INTERVAL
  }

  const shouldShowSpecificDays = () => {
    return (
      state.cadence === OCCURRENCE.MONTH || state.cadence === OCCURRENCE.YEAR
    )
  }

  const shouldShowSpecificMonths = () => {
    return state.cadence === OCCURRENCE.YEAR
  }

  const shouldShowDate = () => {
    return (
      state.cadence !== OCCURRENCE.ONE_TIME && state.cadence !== OCCURRENCE.ASAP
    )
  }

  const shouldShowIntervalMinutes = () => {
    return interval !== "custom"
  }

  const checkValidated = () => {
    let isValid = true
    const initState = initialStateMap[state.cadence]
    if (interval === "custom") {
      initState.hour = state.hour
      initState.minute = state.minute
      delete initState.interval
    }
    const requiredKeys = Object.keys(initState)
    requiredKeys.forEach((key) => {
      if (isEmpty(state[key])) {
        isValid = false
      }
    })
    if (hasEndDate && isEmpty(state.end_date)) {
      isValid = false
    }
    return isValid
  }

  const singleOccurrenceValues = [OCCURRENCE.ASAP, OCCURRENCE.ONE_TIME]

  // Manually disable past dates and time slots

  const shouldDisableDate = (inputMoment) => {
    const inputDate = new Date(inputMoment.format("YYYY-MM-DD"))
    // Convert the input date to simple with time 0 (midnight)
    const neutralInputDate = new Date(
      inputDate.getUTCFullYear(),
      inputDate.getUTCMonth(),
      inputDate.getUTCDate(),
      0, // Midnight hour
      0, // Midnight minute
      0, // Midnight second
      0, // Midnight millisecond
    )

    // Get the current date in the given timezone
    const currentDateInTimezone = new Date(
      new Date().toLocaleString("en-US", { timeZone: state.timezone }),
    )

    currentDateInTimezone.setHours(0, 0, 0, 0)

    // Calculate the time difference in minutes
    const timeDifferenceInMinutes = Math.floor(
      (currentDateInTimezone - neutralInputDate) / (1000 * 60),
    )

    // Check if the input date is earlier than the current date (difference is positive)
    return timeDifferenceInMinutes > 0
  }

  const disabledHours = () => {
    // Get the current date in the given timezone
    const currentDateInTimezone = new Date(
      new Date().toLocaleString("en-US", { timeZone: state.timezone }),
    )
    const neutralCurrentDate = new Date(
      currentDateInTimezone.getTime(),
    ).setHours(0, 0, 0, 0)
    const selectedDate = new Date(state.start_date)
    selectedDate.setHours(0, 0, 0, 0)

    const timeDifferenceInMinutes = Math.floor(
      (selectedDate?.getTime() - neutralCurrentDate) / (1000 * 60),
    )

    if (timeDifferenceInMinutes > 0) {
      return []
    }

    return [...Array(24).keys()].filter(
      (hour) => hour < currentDateInTimezone?.getHours(),
    )
  }

  const disabledMinutes = (selectedHour) => {
    const currentDateInTimezone = new Date(
      new Date().toLocaleString("en-US", { timeZone: state.timezone }),
    )

    const neutralCurrentDate = new Date(
      currentDateInTimezone.getTime(),
    ).setHours(0, 0, 0, 0)

    const selectedDate = new Date(state.start_date)
    selectedDate.setHours(0, 0, 0, 0)

    const timeDifferenceInMinutes = Math.floor(
      (selectedDate?.getTime() - neutralCurrentDate) / (1000 * 60),
    )

    if (
      timeDifferenceInMinutes > 0 ||
      selectedHour > currentDateInTimezone.getHours()
    ) {
      return []
    }

    return [...Array(60).keys()].filter(
      (minute) => minute < currentDateInTimezone?.getMinutes(),
    )
  }

  const disabledTime = () => {
    return {
      disabledHours,
      disabledMinutes,
    }
  }

  // Send data to parent
  useEffect(() => {
    onChange(removeEmpty(state))
    isValid(checkValidated())
  }, [state])

  return (
    <SchedulerWrapper>
      <SchedulerContent>
        <InputRow data-test-id="timezone-container">
          <Label>Timezone</Label>
          <TimezonePicker
            defaultValue={state.timezone}
            onChange={handleOnTimezoneChange}
            width="420px"
          />
        </InputRow>
        <InputRow data-test-id="occurrence-container">
          <Label>Occurrence</Label>
          <OccurrenceSelectWrapper>
            <CustomSelect
              placeholder="Select value"
              style={{ width: "181px", margin: "0px" }}
              defaultValue={{
                value: singleOccurrenceValues.includes(state.cadence)
                  ? OCCURRENCE.ASAP
                  : state.cadence,
              }}
              options={occurrenceOptions}
              onChange={handleChangeOnOccurrence}
            />
          </OccurrenceSelectWrapper>
        </InputRow>
        {shouldShowDate() && (
          <InputRow data-test-id="dates-container">
            <Label>Start date</Label>
            <DatesContainer>
              <AntDatePicker
                allowClear={false}
                size="large"
                format="YYYY-MM-DD"
                showNow={false}
                showToday={false}
                placeholder="Select start date"
                disabledDate={shouldDisableDate}
                value={
                  state.start_date
                    ? moment(state.start_date).tz(localTimezone)
                    : null
                }
                onChange={handleOnChangeStartDate}
              />
              <Checkbox
                id="set-end-checkbox"
                label="Set end date"
                checked={hasEndDate}
                onChange={(checked) => {
                  setHasEndDate(checked)
                  setState({ ...state, end_date: null })
                }}
              />
              {hasEndDate && (
                <>
                  <Label width="auto">End date</Label>
                  <AntDatePicker
                    allowClear={false}
                    size="large"
                    format="YYYY-MM-DD"
                    showNow={false}
                    showToday={false}
                    placeholder="Select end date"
                    disabledDate={shouldDisableDate}
                    value={
                      state.end_date
                        ? moment(state.end_date).tz(localTimezone)
                        : null
                    }
                    onChange={handleOnChangeEndDate}
                  />
                </>
              )}
            </DatesContainer>
          </InputRow>
        )}
        {shouldShowRepeatOn() && (
          <InputRow data-test-id="day-picker-container">
            <Label>Repeat on</Label>
            <DayPicker value={state?.day_of_week} onSelect={onDaySelect} />
          </InputRow>
        )}
        {shouldShowSpecificMonths() && (
          <InputRow data-test-id="months-container">
            <Label>Select month</Label>
            <ComboBox
              data={monthsOptions}
              clearable={false}
              value={state?.month
                ?.split(",")
                ?.map((month) => ({ label: month, value: month }))}
              onChange={(selected) => {
                const sortedMonths = selected?.sort(
                  (a, b) => sortedMonthMap[a.value] - sortedMonthMap[b.value],
                )
                setState({
                  ...state,
                  month: sortedMonths?.map((month) => month.value)?.join(","),
                })
              }}
              isSearchable={false}
              isMultiOption
              checked={false}
              hideSelectedOptions
              closeMenuOnSelect={false}
              components={{}}
              disabled={false}
              placeholder="Select months"
            />
          </InputRow>
        )}
        {shouldShowSpecificDays() && (
          <InputRow data-test-id="days-container">
            <Label>Specific days</Label>
            <ComboBox
              data={monthDaysOptions}
              clearable={false}
              value={state?.day
                ?.split(",")
                ?.map((day) => ({ label: day, value: day }))}
              onChange={(selected) => {
                const sortedDays = selected?.sort((a, b) => a.value - b.value)
                setState({
                  ...state,
                  day: sortedDays?.map((day) => day.value)?.join(","),
                })
              }}
              isSearchable={false}
              isMultiOption
              checked={false}
              hideSelectedOptions={true}
              closeMenuOnSelect={false}
              components={{}}
              disabled={false}
              placeholder="Select days"
            />
          </InputRow>
        )}
        {shouldShowExecutionTime() && (
          <InputRow data-test-id="execution-time-container">
            <Label>Execution time</Label>
            <TimePicker
              use12Hours
              size="large"
              allowClear={false}
              format={format}
              showNow={false}
              value={dayjs().hour(state.hour).minute(state.minute)}
              onChange={handleOnChangeExecutionTime}
              disabledTime={disabledTime}
            />
          </InputRow>
        )}
        {shouldShowOneTimeExecutionTime() && (
          <InputRow data-test-id="custom-execution-time-container">
            <Label>Execution time</Label>
            <CustomSelect
              placeholder="Select value"
              style={{ width: "181px", margin: "0px" }}
              defaultValue={{ value: state.cadence }}
              options={[
                {
                  label: "As soon as possible",
                  value: OCCURRENCE.ASAP,
                },
                {
                  label: "Select time",
                  value: OCCURRENCE.ONE_TIME,
                },
              ]}
              onChange={handleChangeOnOccurrence}
            />
            {state.cadence === OCCURRENCE.ONE_TIME && (
              <>
                <AntDatePicker
                  allowClear={false}
                  size="large"
                  format="YYYY-MMMM-DD hh:mm A"
                  showNow={false}
                  showToday={false}
                  disabledDate={shouldDisableDate}
                  showTime={{
                    format,
                    use12Hours: true,
                    showNow: false,
                    disabledTime: disabledTime,
                  }}
                  value={moment(
                    `${state.year}-${state.month}-${state.day} ${state.hour}:${state.minute}`,
                    "YYYY-MMMM-DD hh:mm A",
                  )}
                  onChange={handleOnChangeOneTimeExecutionTime}
                />
              </>
            )}
          </InputRow>
        )}
        {shouldShowRunningInterval() && (
          <InputRow data-test-id="interval-container">
            <Label>Running Interval</Label>
            <CustomSelect
              placeholder="Select value"
              style={{ width: "181px", margin: "0px" }}
              defaultValue={{ value: interval }}
              options={runningIntervalOptions}
              onChange={handleOnChangeInterval}
            />
            {shouldShowIntervalMinutes() && (
              <>
                <Label width="auto">Select minute</Label>
                <CustomSelect
                  placeholder="Select value"
                  style={{ width: "181px", margin: "0px" }}
                  defaultValue={{ value: `${state.minute}` }}
                  options={executionMinutesOptions}
                  onChange={handleOnChangeMinute}
                />
              </>
            )}
          </InputRow>
        )}
        {shouldShowSpecificHours() && (
          <InputRow data-test-id="hours-container">
            <Label>Specific hours</Label>
            <ComboBox
              data={customHoursOptions}
              clearable={false}
              value={state?.hour
                ?.split(",")
                ?.map((hour) => ({ label: hour, value: hour }))}
              onChange={(selected) => {
                const sortedDays = selected?.sort((a, b) => a.value - b.value)
                setState({
                  ...state,
                  hour: sortedDays?.map((day) => day.value)?.join(","),
                })
              }}
              isSearchable={false}
              isMultiOption
              checked={false}
              hideSelectedOptions
              closeMenuOnSelect
              components={{}}
              disabled={false}
              placeholder="Choose..."
            />
            <Label width="auto">Select minute</Label>
            <CustomSelect
              placeholder="Select value"
              style={{ width: "181px", margin: "0px" }}
              defaultValue={{ value: `${state.minute}` }}
              options={executionMinutesOptions}
              onChange={handleOnChangeMinute}
            />
          </InputRow>
        )}
      </SchedulerContent>
    </SchedulerWrapper>
  )
})

Scheduler.defaultProps = {
  isValid: () => {},
}

Scheduler.propTypes = {
  scheduler: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.func,
}
