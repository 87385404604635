import React from "react"
import PropTypes from "prop-types"
import Button from "@elevate_security/elevate-component-library/dist/Button"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"

import { Wrapper } from "./styles"

const ActionIcon = ({ name, size, color, hoverColor, onClick }) => {
  return (
    <Wrapper color={color} hoverColor={hoverColor}>
      <Button theme="ghost" type="buton" onClick={() => onClick()}>
        <Icon name={name} stroke={color} fill={color} size={size} />
      </Button>
    </Wrapper>
  )
}

ActionIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
}

ActionIcon.defaultProps = {
  onClick: () => null,
  size: "lg",
  color: "#959da8",
  hoverColor: "#039ba1",
}

export default ActionIcon
