import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 23px;
  margin: 5px 0;
  border: solid 1px lightgray;
  border-radius: 5px;
  min-width: 480px;
`

export const DropdownItem = styled.div`
  width: 100%;
`

export const DropdownTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  width: 100%;
`

export const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (!props.isOpen ? 0 : 1)};
  max-height: ${(props) => (!props.isOpen ? "0px" : "3500px")};
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease;
  [class^="Tabsstyles__TabsHeaderContainer-sc-1x30v42-1"] {
    margin: 0 !important;
  }
`

export const Column = styled.div`
  width: auto;
`

export const DropdownButton = styled.button.attrs({ type: "button" })`
  border: 0;
  background: none;
  padding: 0;
  outline: 0;
  transition: ease-out transform 350ms;
  transform: rotate(${({ isOpen }) => (!isOpen ? "180deg" : "0deg")});
  &:focus,
  &:active {
    outline: 0;
  }
`

export const PolicyTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  text-decoration: underline;
  font-weight: 600;
  margin-right: 5px;
`

export const ItemStatus = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 40px;
  margin: 0 5px;
  padding: 5px 10px;
  width: auto;
`

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 40px;
`
export const TrashIconButton = styled.div`
  margin-left: 15%;
`

export const TitleStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`

export const DateAndTrashContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const StrongTitle = styled.span`
  margin-right: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #cb7d20;
`

export const StrongTitleTestModeDiv = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
`

export const Strong = styled.span`
  margin-right: 5px;
  font-weight: 600;
  color: #565d66;
`

export const InfoCardContainer = styled.div`
  background-color: "#FFFFFF";
`

export const TextContainer = styled.div`
  display: flex;
`

export const Circle = styled.div`
  border-radius: 50%;
  height: 3px;
  width: 3px;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 10px;
  margin-bottom: 9px;
  background-color: #000000;
`

export const PolicyDetailContainer = styled.div`
  margin-top: 20px;
`
export const FollowUp = styled.div`
  padding-left: 16px;
  padding-top: 24px;
  border-left: 1px solid #c9cfd6;
  margin-left: 15px;
`
export const PolicyRUleHeading = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #34383c;
  font-weight: 700;
  margin-bottom: 8px;
`
export const IsTestMessage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 5px;
`

export const ActiveRule = styled.div`
  width: 205px;
  height: 40px;
  border-radius: 4px;
  margin-top: 16px;
  padding: 7px;
  background-color: #e0eff0;
  text-align: center;
  vertical-align: center;
  font-weight: 700;
  font-size: 14px;
`

export const Exclamation = styled.div`
  margin-right: 10px;
`

export const ExclamationIcon = styled.span`
  margin-right: 10px;
  width: 40px;
`

export const DeleteExclamationIcon = styled.span`
  margin-right: 10px;
`

export const ButtonActivate = styled.button`
  width: 205px;
  height: 40px;
  border-radius: 4px;
  padding: 2px;
  background-color: #e0eff0;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`

export const EmailIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
`

export const EmailLinkMessage = styled.a`
  font-weight: normal;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  margin-left: 0;
  color: #0c7d84 !important;

  span {
    margin-left: -10px;
    margin-bottom: 5px;
  }
`
export const IconWrapper = styled.div`
  .mail-icon:hover {
    fill: #039ba1;
    cursor: pointer;
  }
  .mail-icon {
    fill: #0c7d84;
    width: 22px;
    height: 18px;
  }
  span {
    text-decoration: none;
    color: #0c7d84;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 0;
  }
  span:hover {
    color: #039ba1;
    cursor: pointer;
  }
  margin-left: 17px;
`
export const EmailSentWrapper = styled.ul`
  list-style: none;
  margin-bottom: 7px;
`
export const EmailSentItem = styled.li`
  display: flex;
  align-items: center;
`
export const EmailBreakPoint = styled.div`
  max-width: 75%;
  word-break: break-all;
`
export const IconWithTextWrapper = styled.div`
  display: flex;
  align-content: center;
  .mail-icon {
    fill: #ffffff;
    width: 22px;
    height: 18px;
  }
  span {
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 0;
  }
  &:hover {
    .mail-icon {
      fill: #039ba1;
      cursor: pointer;
    }
    span {
      color: #ffffff;
      cursor: pointer;
    }
  }
  svg:first-child {
    width: 18px;
    height: 18px
    margin-bottom: s4px
  }
`
export const RuleDescription = styled.a`
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  padding-top: 8px;
`

export const TestActionLogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 13px;
  background-color: #fff3ca;
  padding: 16px 16px 16px 16px;
  border: 1px solid #f5a83f;
  box-shadow: 0px 2px 8px rgba(250, 210, 80, 0.2);
  border-radius: 4px;
`

export const StyledText = styled.u`
  color: #34383c;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  span {
    margin-top: 2px;
  }
  margin-top: 16px;
`

export const DropdownToggle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #c9cfd6;
  font-weight: bold;
  span {
    margin-top: -2px;
  }
  svg {
    padding: 1px;
    margin-top: 2px;
    path {
      stroke: white !important;
    }
  }
`

export const ToggleWrapperText = styled.div`
  margin-left: 10px;
  margin-top: 20px;
  min-width: max-content;
  line-height: 24px;
  font-weight: normal;
`

export const StyledColumn = styled.div`
  width: auto;
  margin-right: 17%;
`

export const DateDiv = styled.div`
  margin-right: 20px;
`

export const RuleDatesInfoDiv = styled.div`
  background: #f5f6f8;
  padding: 10px 20px;
  align-self: flex-start;
  flex-shrink: 0;
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: inherit;
`

export const ToogleMarginWrapper = styled.div`
  margin-top: 20px;
`

export const LinkWrapper = styled.u`
  color: #8b9299;
  &:hover {
    cursor: pointer;
  }
`
export const ExcludeIndividualWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  gap: 8px;
`

export const ExcludeIndividualChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`
export const ExcludeIndividualChip = styled.div`
  align-items: center;
  padding: 4px 8px;
  background: #b8e5e3;
  border-radius: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #34383c;
`
export const AuditLogWrapper = styled.div`
  height: 375px !important;
  overflow: hidden;
  .table-body {
    height: 253px !important;
    min-height: 253px !important;
  }
  .table-row {
    min-height: 40px;
    padding: 12px 6px;
    align-items: baseline;
    height: fit-content;
  }
  [class^="EventsLogstyles__EventLogAdditional"] {
    height: 310px;
    min-height: 310px;
  }
  .table-navigation {
    bottom: inherit;
  }

  #eventLog-Toolbar {
    display: none;
  }

  #eventLog-table-container {
    padding-top: 0;
  }

  #eventLog-wrapper {
    margin: 0;
  }
`

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  gap: 10px;
  background: #f5f6f8;
  border-radius: 4px;
  align-self: stretch;
  justify-content: space-between;
`

export const ActionTarget = styled.div`
  display: flex;
  align-items: center;
`

export const ActionsRow = styled.div`
  display: flex;
  gap: 20px;
  width: 75px;
  justify-content: flex-end;
  align-items: center;
`

export const PolicySectionLabel = styled.span`
  font-weight: ${theme.text.fontWeight.default};
  padding: 1px 4px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray.opaque[200]};
  background: ${theme.colors.bg.info};
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
`
