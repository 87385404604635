import styled from "styled-components"

export const AttackInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #c9cfd6;

  :last-child {
    border-right: none;
  }
  h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    color: #34383c;
  }

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    //styleName: Core / Subhead;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    color: #7b828a;
  }
`

export const BoxShadow = styled.div`
  background: white;
  font-size: 32px !important;
  margin-top: 65px;
  padding-right: 30px;
`

export const GraphsWrapper = styled.div`
  width: 100%;
`

export const ViewTimePeriodWrapper = styled.div`
  span {
    font-weight: 900;
  }
`
export const ViewTimePeriodWrapperAlt = styled.div`
  display: flex;
  flex-direction: row;

  h3 {
    width: 100%;
    margin-right: 30px;
  }

  span {
    font-weight: 900;
  }
`
