export const SET_HIERARCHICAL_SCORES_MONTHLY = "SET_HIERARCHICAL_SCORES_MONTHLY"
export const SET_SCORES_BY_INDIVIDUALS = "SET_SCORES_BY_INDIVIDUALS"
export const SET_SCORES_BY_DEPARTMENT = "SET_SCORES_BY_DEPARTMENT"
export const SET_SCORES_BY_LOCATION = "SET_SCORES_BY_LOCATION"
export const SET_HIERARCHICAL_CAMPAIGNS_SCORES =
  "SET_HIERARCHICAL_CAMPAIGNS_SCORES"
export const SET_RISKIEST_DEPARTMENT = "SET_RISKIEST_DEPARTMENT"
export const SET_RECOMMENDED_INDIVIDUALS_FOR_HM =
  "SET_RECOMMENDED_INDIVIDUALS_FOR_HM"
export const SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE =
  "SET_RECOMMENDED_INDIVIDUALS_FOR_PULSE"
export const SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX =
  "SET_RECOMMENDED_INDIVIDUALS_FOR_REFLEX"
export const SET_TOTAL_INDIVIDUALS_COUNT = "SET_TOTAL_INDIVIDUALS_COUNT"
