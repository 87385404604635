import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOASTS } from "./types"

export const initialState = []

export default function campaignsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST:
      return [action.toast]
    case REMOVE_TOAST:
      return state.filter((toast) => toast.id !== action.id)
    case REMOVE_ALL_TOASTS:
      return initialState
    default:
      return state
  }
}
