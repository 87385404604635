import React from "react"
import { Icon, IconAction } from "@elevate_security/elevate-component-library"
import { IconContainer, ButtonWrapper } from "./CompoundConditionButton.styles"
import { Button } from "@src/components/MimecastReskin"

export function CompoundConditionButton({ onClick }) {
  return (
    <div>
      <ButtonWrapper>
        <Button onClick={onClick}>
          <Icon name="Plus" size="default" stroke="#FFFFFF" />
        </Button>
        <IconContainer>
          <IconAction
            name="InfoCheckOutline"
            size="xsm"
            renderTooltipOnHover
            tooltipText="Adds another condition to the behavior selected. All conditions will be evaluated as a combined conditional."
          />
        </IconContainer>
      </ButtonWrapper>
    </div>
  )
}
