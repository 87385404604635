import React from "react"
import {
  ActionAbleInsightSkeleton,
  ActionInsightData,
  ActionInsightsWrapper,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import { SkeletonLoader } from "@src/utils/skeleton"

function ActionAbleInsightsSkeleton() {
  return (
    <ActionInsightsWrapper>
      <hr />
      <ActionAbleInsightSkeleton>
        <SkeletonLoader
          circle={true}
          width={28}
          height={25}
          marginTop={22}
          marginLeft={20}
          marginRight={15}
        />
        <ActionInsightData>
          <div>
            <SkeletonLoader width={500} />
          </div>
          <div>
            <SkeletonLoader width={100} height={20} />
          </div>
        </ActionInsightData>
      </ActionAbleInsightSkeleton>
    </ActionInsightsWrapper>
  )
}

export default ActionAbleInsightsSkeleton
