import { humanize } from "@src/utils/string"

export const getSecurityRatingDescription = (value) => {
  if (0 <= value && value < 20) return "Very High"
  if (20 <= value && value < 40) return "High"
  if (40 <= value && value < 60) return "Medium"
  if (60 <= value && value < 80) return "Low"
  if (80 <= value && value <= 100) return "Very Low"
  return ""
}

export const yAxisHeightRange = (arr, range) => {
  const customEnum = {}
  arr.map((action, index) => {
    if (index === 0) {
      Object.assign(customEnum, (customEnum[action.key] = range))
    } else {
      Object.assign(
        customEnum,
        (customEnum[action.key] = range + range * index),
      )
    }
  })
  return customEnum
}

export const ACTION_NAMES = {
  "device-security": "Device Security",
  malware: "Malware",
  phishing: "Simulated Phishing",
  training: "Training",
  "password-manager": "Password Manager",
  "real-world-phishing": "Actual Phishing",
  "unauthorized-software": "Unauthorized Software",
  "sensitive-data-handling": "Sensitive Data Handling",
  "secure-browsing": "Secure Browsing",
  overall: "Action Score",
  "clean-desk": "Clean Desk",
  "password-security": "Password Security MFA",
  "physical-security": "Physical Security",
  "optional-activities": "Optional Activities",
  "access-termination": "Access Termination",
  device_security: "Device Security",
  password_manager: "Password Manager",
  real_world_phishing: "Actual Phishing",
  unauthorized_software: "Unauthorized Software",
  sensitive_data_handling: "Sensitive Data Handling",
  secure_browsing: "Secure Browsing",
  clean_desk: "Clean Desk",
  password_security: "Password Security MFA",
  physical_security: "Physical Security",
  optional_activities: "Optional Activities",
  access_termination: "Access Termination",
  action_factor: "Action Factor",
  access_factor: "Access Factor",
  attack_factor: "Attack Factor",
}

const PHISHING_TEXT =
  "Phishing is the fraudulent practice of sending malicious emails that try to steal your credentials or download malicious software."
const ACTION_TEXT =
  "Examples include clicking on links in phishing emails, downloading malware, and not completing training on time"
export const Score_Details_Tooltips = {
  malware:
    "This score measures how well an individual deals with malware, malicious software attackers trick users into installing in order to grant unauthorized access or steal sensitive information.",
  phishing: `${PHISHING_TEXT} The simulated phishing score measures an individual's response to simulated phishing tests conducted by their organization.`,
  actual_phishing: `${PHISHING_TEXT} The actual phishing score measures how an individual has interacted with known phishing attacks.`,
  training:
    "The training score measures whether or not an individual completes assigned training tasks and if those tasks are completed on time.",
  secure_browsing:
    "The Secure Browsing score assesses whether or not an individual is engaging in risky browsing behavior, such as accessing sites which are not approved for work use or are malicious.",
  sensitive_data_handling:
    "This score measures how an individual handles sensitive data with respect to organization policy. Sensitive data may include Social Security numbers, credit card or medical information, intellectual property, or other information as determined by the organization.",
  action: `This score represents the risk related to actions taken by an individual that can positively or negatively affect an organization's security posture. ${ACTION_TEXT}.`,
  team_action: `This score represents the risk related to actions taken by members of a team that can positively or negatively affect an organization's security posture. ${ACTION_TEXT}`,
}

export const UNDERSCORED_ACTION_NAMES = Object.entries(ACTION_NAMES).reduce(
  (acc, [key, value]) => {
    acc[key] = value.replace(/\s/g, "_")
    return acc
  },
  {},
)

export const UNDERSCORED_BEHAVIORS = Object.entries(ACTION_NAMES).reduce(
  (acc, [key, value]) => {
    acc[key] = value.replace(/\s/g, "_").toLowerCase()
    return acc
  },
  {},
)

export const REVERSED_BEHAVIORS = Object.entries(UNDERSCORED_BEHAVIORS).reduce(
  (acc, [key, value]) => {
    acc[value] = key
    return acc
  },
  {},
)

export const printCurrentPointsColors = (value) => {
  if (0 <= value && value < 20) return "#D23A54"
  if (20 <= value && value < 40) return "#F08526"
  if (40 <= value && value < 60) return "#FACC38"
  if (60 <= value && value < 80) return "#3CC1AE"
  if (80 <= value && value <= 100) return "#2C9097"
}

const arrowWidth = {
  "Very High": 1,
  High: 2,
  Medium: 3,
  Low: 4,
  "Very Low": 5,
}

export const stepHelper = (value1, value2) => {
  const parsedValue1 = getSecurityRatingDescription(value1)
  const parsedValue2 = getSecurityRatingDescription(value2)
  const difference = Math.abs(
    arrowWidth[parsedValue1] - arrowWidth[parsedValue2],
  )
  return difference
}

export const getActionName = (key) => ACTION_NAMES[key] || humanize(key)

export const getUnderscoredActionName = (key) =>
  UNDERSCORED_ACTION_NAMES[key] || humanize(key).replaceAll(" ", "_")
