import {
  GET_ALL_API_DATA,
  GET_FACTOR_GRAPH_DATA,
  GET_TREND_OVER_TIME_CARD_DATA,
  GET_TREND_OVER_TIME_POLICY_STATS,
  GET_TRENDS_OVER_TIME,
  LATEST_EVENTS,
  SCORE_BREAKDOWN,
  SET_HIERACHICAL_DATA,
  TIME_PERIOD_VALUE,
  TREND_LOADER,
  SET_ACTIVE_ACTIONS,
  SET_FETCHING_TREND_OVER_TIME_CARD_DATA,
  SET_FETCHING_TREND_OVER_TIME_POLICY_STATS,
  SET_COMPANY_INFO,
  SET_LATEST_SCORING_DATETIME,
} from "./types"
import { PAST_TWELVE_MONTHS } from "@src/scenes/HumanRiskDashboard/constants"

const INITIAL_STATE = {
  riskLevels: null,
  isLoaded: false,
  risk_scores: {},
  access_factor: [],
  attack_factor: [],
  factor_graph_loader: true,
  individuals: [],
  departments: [],
  locations: [],
  apiLoader: true,
  trend_historical_risk_scores: {},
  policy_stats: {},
  trend_events_count: 0,
  trend_loader: true,
  hierachicalData: [],
  trends_over_time: {},
  time_period_value: PAST_TWELVE_MONTHS,
  keyFactor: "",
  latest_events_data: [],
  latest_events_loader: true,
  score_breakdown_loader: true,
  score_breakdown: [],
  active_actions: null,
  fetchingTrendOverTimeCardData: false,
  fetchingTrendOverTimePolicyStats: false,
  companyInfo: null,
  latestScoringDatetime: null,
}

export default function riskReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_API_DATA:
      return {
        ...state,
        risk_scores: { ...(action?.data?.data?.risk_scores || {}) },
        individuals: [...(action?.data?.data?.individuals || [])],
        departments: [...(action?.data?.data?.departments || [])],
        locations: [...(action?.data?.data?.locations || [])],
        apiLoader: action.data.apiLoader,
      }
    case GET_FACTOR_GRAPH_DATA:
      return {
        ...state,
        access_factor: [...(action?.data?.data?.access_factor || [])],
        attack_factor: [...(action?.data?.data?.attack_factor || [])],
        factor_graph_loader: action.data.factor_graph_loader,
      }
    case SET_FETCHING_TREND_OVER_TIME_CARD_DATA:
      return {
        ...state,
        fetchingTrendOverTimeCardData: action.isFetching,
      }
    case SET_FETCHING_TREND_OVER_TIME_POLICY_STATS:
      return {
        ...state,
        fetchingTrendOverTimePolicyStats: action.isFetching,
      }
    case GET_TREND_OVER_TIME_CARD_DATA:
      return {
        ...state,
        trend_historical_risk_scores: {
          ...(action?.data?.data?.historical_risk_scores || {}),
        },
        trend_events_count: action.data.data.events_count || 0,
        trend_loader: action.data.trend_loader,
      }
    case GET_TREND_OVER_TIME_POLICY_STATS:
      return {
        ...state,
        policy_stats: {
          ...(action?.data?.data?.policy_stats || {}),
        },
      }
    case SET_HIERACHICAL_DATA:
      return {
        ...state,
        hierachicalData: action.hierachicalData,
      }
    case TIME_PERIOD_VALUE:
      return {
        ...state,
        time_period_value: action.timePeriodValue,
      }
    case GET_TRENDS_OVER_TIME:
      return {
        ...state,
        trends_over_time: action.trendOverTime,
      }
    case TREND_LOADER:
      return {
        ...state,
        trend_loader: true,
      }
    case LATEST_EVENTS:
      return {
        ...state,
        latest_events_data: action.data.latest_events_data,
        latest_events_loader: action.data.latest_events_loader,
      }
    case SCORE_BREAKDOWN:
      return {
        ...state,
        score_breakdown: action.data.score_breakdown,
        score_breakdown_loader: action.data.score_breakdown_loader,
      }
    case SET_ACTIVE_ACTIONS:
      return {
        ...state,
        active_actions: action.data,
      }
    case SET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.companyInfo,
      }
    case SET_LATEST_SCORING_DATETIME:
      return {
        ...state,
        latestScoringDatetime: action.latestScoringDatetime,
      }
    default:
      return state
  }
}
