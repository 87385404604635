import styled from "styled-components"

export const AccessGraphWrapper = styled.div`
  width: 100%;
`
export const AccessGraphContainer = styled.div`
  width: 100%;
`
export const AccessHeader = styled.div`
  color: #34383c;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
`
