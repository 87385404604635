import styled from "styled-components"

export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0px")}
  input:disabled {
    background-color: #f5f6f8;
  }
  [class^="ComboBoxstyles__SelectContainer"] {
    margin: 0;
    width: 317px;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  [class^="Togglestyles__Content"] {
    background-color: #959da8;
  }
  span {
    margin-top: 2px;
  }
`

export const ToggleWrapperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
  margin-left: 8px;
`

export const ShowToggleWrapperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
`
export const ShowSecreteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  [class^="IconActionstyles__ActionWrapper"] {
    margin-right: 8px;
  }
`
