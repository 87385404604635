import React from "react"
import PropTypes from "prop-types"

const topArrowStyle = (keyName, sorting) => {
  if (sorting.columnName === keyName && sorting.order === "asc") {
    return "#0C7D84"
  }
  return "#C9CFD6"
}

const bottomArrowStyle = (keyName, sorting) => {
  if (sorting.columnName === keyName && sorting.order === "desc") {
    return "#0C7D84"
  }
  return "#C9CFD6"
}

const SortArrows = ({ keyName, sorting }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 15 15"
      className="margin-left-5 SortArrows"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            d="M13.2,1.54433916 L23.5029437,12.8273589 C24.1656854,13.5531444 24.1656854,14.7298753 23.5029437,15.4556608 C23.1846839,15.8041954 22.753031,16 22.3029437,16 L1.69705627,16 C0.759797975,16 0,15.1679256 0,14.1415099 C0,13.6486076 0.178796487,13.1758934 0.497056275,12.8273589 L10.8,1.54433916 C11.4627417,0.818553613 12.5372583,0.818553613 13.2,1.54433916 Z"
            transform="scale(.32)"
            fill={topArrowStyle(keyName, sorting)}
          />
        </g>
      </g>
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            d="M22.3029437,0 C23.240202,0 24,0.832074381 24,1.85849015 C24,2.35139238 23.8212035,2.82410662 23.5029437,3.17264113 L13.2,14.4556608 C12.5372583,15.1814464 11.4627417,15.1814464 10.8,14.4556608 L0.497056275,3.17264113 C-0.165685425,2.44685558 -0.165685425,1.27012471 0.497056275,0.544339161 C0.815316063,0.19580465 1.24696897,0 1.69705627,0 L22.3029437,0 Z"
            transform="scale(.32)translate(0,22)"
            fill={bottomArrowStyle(keyName, sorting)}
          />
        </g>
      </g>
    </svg>
  )
}

export default SortArrows

SortArrows.propTypes = {
  keyName: PropTypes.string,
  sorting: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }),
}

SortArrows.defaultProps = {
  keyName: "name",
  sorting: {
    columnName: "name",
    order: "asc",
  },
}
