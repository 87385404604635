import React, { useState, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { Container, DropdownToggle, Dropdown, Section } from "./styles"
import { getDropdownOptions, FILTER_TYPE } from "../utils"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import MultiSelectFilter from "./MultiSelectFilter"
import DateRange from "./DateRange"
import SliderFilter from "@src/scenes/IndividualRiskAnalysis/components/Filters/components/MoreFilter/components/SliderFilter"

const MoreFilter = ({
  filters,
  onSelection,
  _options,
  optionParams,
  width = "450px",
}) => {
  const ref = useRef(null)
  const count = filters?.length

  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(getDropdownOptions(_options, ...optionParams))
  }, [_options, optionParams])

  const clickListener = useCallback(
    (e) => {
      if (
        !ref.current.contains(e.target) &&
        !e.target.classList.contains("show-more-link") &&
        !e.target.classList.contains("expand-icon")
      ) {
        setIsOpen(false)
      }
    },
    [ref.current],
  )

  useEffect(() => {
    document.addEventListener("click", clickListener)
    return () => {
      document.removeEventListener("click", clickListener)
    }
  })
  useEffect(() => {
    if (count === 0) {
      setIsOpen(false)
    }
  }, [count])

  const renderFilterComponent = (section) => {
    const { type, showAllFilter } = section
    if (type === FILTER_TYPE.MULTI_SELECT) {
      return (
        <MultiSelectFilter
          section={section}
          filters={filters}
          handleChange={onSelection}
          showAllFilters={showAllFilter}
        />
      )
    } else if (type === FILTER_TYPE.DATE_RANGE) {
      const dataRangeFilter = filters?.find(
        (filter) => filter.key === section.key,
      )
      return (
        <DateRange
          section={section}
          value={dataRangeFilter?.value}
          onChange={onSelection}
        />
      )
    } else if (type === FILTER_TYPE.SLIDER) {
      const sliderFilter = filters?.find((filter) => filter.key === section.key)
      return (
        <SliderFilter
          section={section}
          filters={sliderFilter?.origin}
          handleChange={(filter) => onSelection(filter)}
          initialRange={[0, 10]}
        />
      )
    }
    return null
  }
  return (
    <Container ref={ref}>
      <DropdownToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        More Filters
        {count > 0 ? <span>&nbsp;({count})</span> : null}
        <Icon name="CaretDown" size="xxsm" fill="#959DA8" />
      </DropdownToggle>
      {isOpen && (
        <Dropdown width={width}>
          {options.map((section) => (
            <Section key={section.key}>
              {renderFilterComponent(section)}
            </Section>
          ))}
        </Dropdown>
      )}
    </Container>
  )
}

MoreFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelection: PropTypes.func.isRequired,
}

export default MoreFilter
