import React from "react"
import { NameSpan } from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/styles"

function TextOverflow({ name, color = "", children }) {
  return (
    <NameSpan title={name} color={color}>
      {children || name}
    </NameSpan>
  )
}

export default TextOverflow
