import styled from "styled-components"

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`

export const ModalContentWrapper = styled.div`
  margin-left: 2px;
  margin-right: 2px;
  min-height: 200px;
`
