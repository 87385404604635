import styled from "styled-components"

export const Container = styled.div`
  position: relative;
`

export const DropdownToggle = styled.div`
  width: 176px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #c9cfd6;
  border-radius: 4px;
  background: #ffffff;
  padding: 8px 16px;
  color: #7b828a;
  cursor: pointer;
  ${(props) =>
    props.isOpen
      ? `
    border: 1px solid #039BA1;
    box-shadow: 0px 0px 4px #0C7D84;
  `
      : null}
  svg {
    margin-left: auto;
  }
`

export const Dropdown = styled.div`
  padding: 10px 16px 0 16px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c9cfd6;
  box-shadow: 0px 2px 8px rgba(177, 184, 193, 0.5);
  height: 390px;
  width: ${(props) => props.width};
  overflow-y: scroll;
  position: absolute;
  top: 41px;
  z-index: 1002;
`

export const Section = styled.div`
  margin-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px #dde1e7 solid;
  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }
  &:last-child {
    border-bottom: unset;
  }
`
