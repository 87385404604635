import styled from "styled-components"

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
`
export const ActionBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c9cfd6;
  border-radius: 4px;
  padding: 27px;
  min-width: calc(50% - 20px);
`
export const Strong = styled.strong`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #34383c;
`
export const Medium = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #565d66;
`
