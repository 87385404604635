/* eslint-disable react/prop-types */
import useRequest from "@ahooksjs/use-request"
import * as reputationApi from "@src/services/apis/reputation"
import React, { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { setIndividualData } from "@src/services/redux/IndividualProfile/actions"
import { useSelector, useDispatch } from "react-redux"
import IndividualProfileContextProviderSkeleton from "@src/scenes/IndividualProfilePage/skeleton/IndividualProfileContextProviderSkeleton"

export const IndividualProfileStateContext = React.createContext()
export const IndividualProfileDispatchContext = React.createContext()
export const IndividualProfileActionsContext = React.createContext()
export const IndividualProfileTableRefContext = React.createContext()

const getIndividualInformation = (dispatch) => async (es_person_id) => {
  if (!es_person_id) return

  const individualData =
    await reputationApi.getReputationRisksByPersonId(es_person_id)
  dispatch(setIndividualData(individualData?.data))
}

const patchGroupsToMembers =
  (dispatch) => async (es_person_id, group, data) => {
    await reputationApi.patchGroupsToMembers(group, data)
    const individualData =
      await reputationApi.getReputationRisksByPersonId(es_person_id)
    dispatch(setIndividualData(individualData?.data))
  }

export default function IndividualProfileContextProvider({ children }) {
  const ref = useRef(null)
  const { profileId } = useParams()
  const individualProfileState = useSelector((state) =>
    state.get("individualProfileReducer"),
  )
  const dispatch = useDispatch()

  const [actions] = useState({
    getIndividualInformation: getIndividualInformation(dispatch),
    patchGroupsToMembers: patchGroupsToMembers(dispatch),
  })

  const { loading } = useRequest(
    () => actions.getIndividualInformation(profileId),
    {
      refreshDeps: [profileId],
    },
  )

  return (
    <IndividualProfileTableRefContext.Provider value={ref}>
      <IndividualProfileStateContext.Provider value={individualProfileState}>
        <IndividualProfileDispatchContext.Provider value={dispatch}>
          <IndividualProfileActionsContext.Provider value={actions}>
            {loading && <IndividualProfileContextProviderSkeleton />}
            {!loading && children}
          </IndividualProfileActionsContext.Provider>
        </IndividualProfileDispatchContext.Provider>
      </IndividualProfileStateContext.Provider>
    </IndividualProfileTableRefContext.Provider>
  )
}

/** hooks */
function useIndividualProfileState() {
  const context = React.useContext(IndividualProfileStateContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualProfileState must be used within a IndividualProfileStateContext",
    )
  }
  return context
}

function useIndividualProfileDispatch() {
  const context = React.useContext(IndividualProfileDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualProfileDispatch must be used within a IndividualProfileDispatchContext",
    )
  }
  return context
}

function useIndividualProfileActions() {
  const context = React.useContext(IndividualProfileActionsContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualProfileActions must be used within a IndividualProfileActionsContext",
    )
  }
  return context
}

function useIndividualProfileTableRef() {
  const context = React.useContext(IndividualProfileTableRefContext)
  if (context === undefined) {
    throw new Error(
      "useIndividualProfileTableRef must be used within a IndividualProfileTableRefContext",
    )
  }
  return context
}

export {
  useIndividualProfileState,
  useIndividualProfileDispatch,
  useIndividualProfileActions,
  useIndividualProfileTableRef,
}
