import React, { useState } from "react"
import { ComboBox } from "@elevate_security/elevate-component-library"
import { useRequest } from "ahooks"
import { getAzureADApplications } from "@src/services/apis/reputation"
import {
  AddRemoveAppConditionalAccess,
  AddRemoveAppLabel,
} from "../AzureApplicationsCombobox/styles"
import PropTypes from "prop-types"
import { humanize } from "@src/utils/string"

const getFormattedData = (apps) => {
  return (
    apps?.map((app) => ({
      label:
        `${app?.display_name?.replaceAll("_", " ")} (${humanize(
          app?.application_type,
        )})` || "",
      value: app?.app_id || "",
      appId: app?.app_id || "",
    })) || []
  )
}

const LIMIT = 25

const AzureApplicationsCombobox = ({
  onChange,
  label,
  value,
  placeholder,
  width = "317px",
}) => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  const [totalApps, setTotalApps] = useState(0)

  const { data: dropdownData, loading: appCapLoading } = useRequest(
    () =>
      getAzureADApplications({
        search: `${search}`,
        page_size: LIMIT,
        page_number: page,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        const resData = response?.data || {}
        if (page === 0) {
          setTotalApps(resData?.count || 0)
          return getFormattedData(resData?.results || [])
        } else {
          const nextData = getFormattedData(resData?.results || [])
          return [...dropdownData, ...nextData]
        }
      },
    },
  )

  const HandleSearch = (value) => {
    if (search === value) return
    setSearch(value)
    setPage(0)
  }

  return (
    <>
      <AddRemoveAppLabel>{label}</AddRemoveAppLabel>
      <AddRemoveAppConditionalAccess width={width}>
        <ComboBox
          data={dropdownData}
          value={value}
          onChange={onChange}
          isLoading={appCapLoading}
          isSearchable={true}
          isMultiOption={true}
          checked={true}
          hideSelectedOptions={true}
          components={{}}
          placeholder={placeholder}
          handleScrollBotttom={() => {
            if (dropdownData.length < totalApps) {
              setPage(page + 1)
            }
          }}
          handleInputChange={(value) => HandleSearch(value)}
        />
      </AddRemoveAppConditionalAccess>
    </>
  )
}

AzureApplicationsCombobox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
}

AzureApplicationsCombobox.defaultProps = {
  label: "Applications",
  placeholder: "Select one or more Applications",
}

export default AzureApplicationsCombobox
