import React from "react"
import styled from "styled-components"

export const BannerAttention = styled.div`
  display: flex;
  padding: 16px 24px;
  background-color: #fff3ca;
  border-radius: 4px;
  color: #34383c;
  font-size: 14px;
  line-height: 24px;
  b,
  a[class^="ButtonLink__StyledButtonLink"] {
    font-weight: bold;
  }
`

// eslint-disable-next-line react/prop-types
const Banner = ({ children }) => {
  return <BannerAttention>{children}</BannerAttention>
}

export default Banner
