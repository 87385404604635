import React from "react"

function getDisplayName(Component: any) {
  return Component.displayName || Component.name || "Unknown"
}

/*
 * We use this button as a way to switch from Elevate colors to Mimecast colors
 * incrementally. Eventually we'll remove the old Elevate colors and this
 * component can just be exactly the components from the component library.
 */
export function withMimecastTheme<
  T extends { theme?: "default" | "secondary" | "ghost" },
>(Component: React.ComponentType<T>): React.ComponentType<T> {
  const ComponentWithMimecastTheme = (props: T) => {
    const theme = {
      default: "mimecastCgDefault",
      secondary: "mimecastCgSecondary",
      ghost: "mimecastCgGhost",
    }[props.theme || "default"]

    return <Component {...props} theme={theme} />
  }
  ComponentWithMimecastTheme.displayName = `withMimecastTheme(${getDisplayName(Component)})`
  return ComponentWithMimecastTheme
}
