/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
// IMPORTS
import Moment from "moment"
import axios from "@src/services/fetcher"

// Selectors
import {
  getSorting,
  getPageLevel,
  getPageLevelId,
  getCurrentBehaviorMap,
} from "./selectors/visionSelectors"

// ACTIONS
export const RESET_LOADED = "RESET_LOADED"
export const WHO_AM_I = "WHO_AM_I"
export const RESET_TAB = "RESET_TAB"
export const INCREMENT_LOADED = "INCREMENT_LOADED"
export const SET_LOADED = "SET_LOADED"
export const GET_PERFORMER_DATA = "GET_PERFORMER_DATA"
export const GET_STRAGGLER_DATA = "GET_STRAGGLER_DATA"
export const GET_HIERARCHY_DATA = "GET_HIERARCHY_DATA"
export const SET_TAB = "SET_TAB"
export const GET_BEHAVIOR_DATA = "GET_BEHAVIOR_DATA"
export const GET_SCORES_DIST = "GET_SCORES_DIST"
export const GET_TOP_DEPARTMENTS = "GET_TOP_DEPARTMENTS"
export const GET_RISK_DIST = "GET_RISK_DIST"
export const SORT_COLUMN = "SORT_COLUMN"
export const GET_BEHAVIOR_MAP = "GET_BEHAVIOR_MAP"
export const GET_SUB_BEHAVIOR_MAP = "GET_SUB_BEHAVIOR_MAP"
export const GET_BREADCRUMBS = "GET_BREADCRUMBS"
export const SET_PAGE = "SET_PAGE"
export const SET_PAGINATION = "SET_PAGINATION"

function isDemoApp() {
  return window.isDemoApp || false
}

function getBaseScoresApiURL() {
  return isDemoApp() ? REACT_APP_SCORES_API_URL_DEMO : REACT_APP_SCORES_API_URL
}

function getBaseDirectoryApiURL() {
  return isDemoApp()
    ? REACT_APP_DIRECTORY_API_URL_DEMO
    : REACT_APP_DIRECTORY_API_URL
}

function extractScoresListFromData(data) {
  return (
    (data && data.hierarchicalScores && data.hierarchicalScores.groups) || []
  )
}

export function resetLoaded() {
  return (dispatch) => {
    dispatch({
      type: RESET_LOADED,
    })
  }
}

export function incrementLoaded() {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_LOADED,
    })
  }
}

export function setLoaded() {
  return (dispatch) => {
    dispatch({
      type: SET_LOADED,
    })
  }
}

export function setPage(level, levelId) {
  return (dispatch) => {
    dispatch({
      type: SET_PAGE,
      level,
      levelId,
    })
  }
}

export function setPagination(pageNumber) {
  return (dispatch) => {
    dispatch({
      type: SET_PAGINATION,
      pageNumber,
    })
  }
}

export function setWhoAmI(whoAmI) {
  return {
    type: WHO_AM_I,
    whoAmI: whoAmI,
  }
}

export const getCompany = () => {
  const company = JSON.parse(window.localStorage.getItem("company"))
  const { shortname } = company || {}
  return isDemoApp() ? "lighthouse" : shortname
}

export function getTopBottomData({
  level = "company",
  levelId = "",
  category = "score_overall",
  orderBy = "",
  limit = 10,
  startDate = Moment().subtract(1, "month").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()

    const missingId = level !== "company" && !levelCache.get(levelId)
    const categoryName = category.replace(/score_/g, "")
    const validLevelId = levelId || ""

    const personsList = []
    const performersList = []
    let behaviorScore

    if (
      company &&
      (!levelCache.get(category) ||
        levelCache.get(category).size === 0 ||
        missingId)
    ) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        person_nid: validLevelId,
        group_by: "person_nid,category",
        order_by: `${category}${orderBy}`,
        limit,
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/individualScores`,
          { params },
        )
        .then(({ data }) => {
          const scoresList =
            data && data.individualScores && data.individualScores.groups
          if (scoresList) {
            scoresList.forEach((person) => personsList.push(person.key))
          }

          return axios
            .post(
              `${getBaseDirectoryApiURL()}/organizations/${company}/persons:batchGet`,
              {
                person_nids: personsList,
              },
            )
            .then(({ data }) => {
              scoresList.forEach((score) => {
                score.groups.forEach((item) => {
                  if (item["key"] === categoryName) {
                    behaviorScore = item.scores.score
                  }
                })
                const person = data ? data.persons[score.key] : null
                const performersData = {
                  elevate_person_id: score ? score.key : null,
                  department: person ? person.department_name : null,
                  name: person
                    ? `${person.first_name} ${person.last_name}`
                    : null,
                  riskScore:
                    behaviorScore !== null && behaviorScore >= 0
                      ? behaviorScore
                      : null,
                  is_manager: person ? person.is_manager : null,
                }
                if (performersData.riskScore !== null) {
                  performersList.push(performersData)
                }
              })
              if (label === "topPerformers") {
                dispatch({
                  type: GET_PERFORMER_DATA,
                  level,
                  levelId,
                  category,
                  label: "topPerformers",
                  data: performersList.filter((datum) => datum.riskScore !== 0),
                })
              } else {
                dispatch({
                  type: GET_STRAGGLER_DATA,
                  level,
                  levelId,
                  category,
                  label: "stragglers",
                  data: performersList.filter(
                    (datum) => datum.riskScore !== 100,
                  ),
                })
              }
            })
            .catch((err) => {
              dispatch(incrementLoaded())
              console.error(err)
            })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export function getHistoricalTrend({
  level = "company",
  levelId = "",
  startDate = Moment().subtract(1, "year").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "hierarchy",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()

    const trends = { hierarchicalScores: { groups: [] } }

    const missingId = level !== "company" && !levelCache.get(levelId)

    if (company && (levelCache.size === 0 || missingId)) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        group_by: "datetime-monthly,category",
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/persons/${levelId}/hierarchicalScores`,
          { params },
        )
        .then(({ data }) => {
          const scores =
            data && data.hierarchicalScores && data.hierarchicalScores.groups
          if (scores) {
            trends.hierarchicalScores.groups = scores.map(
              (monthlyCategoryScores) => {
                const categories = {}

                monthlyCategoryScores.groups.forEach((categoryScore) => {
                  categories[categoryScore.key] = categoryScore
                })

                return { [monthlyCategoryScores.key]: { groups: categories } }
              },
            )
          }

          dispatch({
            type: GET_HIERARCHY_DATA,
            level,
            levelId,
            label,
            data: trends,
          })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export const sortData = (data, columnName, order) => {
  // Abstract comparison function
  // Assumes scores.score is a parsable integer
  const cmpfunc = (a, b) => {
    const ascore = parseInt(
      a.scores.find((x) => x.key === columnName).scores.score,
      10,
    )
    const bscore = parseInt(
      b.scores.find((x) => x.key === columnName).scores.score,
      10,
    )
    return ascore !== bscore
      ? ascore - bscore
      : a.sortValue.localeCompare(b.sortValue)
  }

  let sortedData
  if (columnName === "name") {
    if (order === "asc") {
      sortedData = data.sort((a, b) => a.sortValue.localeCompare(b.sortValue))
    } else {
      sortedData = data.sort((a, b) => -a.sortValue.localeCompare(b.sortValue))
    }
  } else if (order === "asc") {
    sortedData = data.sort((a, b) => cmpfunc(a, b))
  } else {
    sortedData = data.sort((a, b) => -cmpfunc(a, b))
  }

  return sortedData
}

export function sortColumn(columnName, originalOrder) {
  return (dispatch, getState) => {
    const level = getPageLevel(getState())
    const levelId = getPageLevelId(getState())
    const label = level === "company" ? "behaviorMap" : "subBehaviorMap"
    const currentName = getSorting(getState()).columnName
    let order
    if (originalOrder === "asc" && columnName === currentName) {
      order = "desc"
    } else if (originalOrder === "desc" && columnName === currentName) {
      order = "asc"
    } else {
      order = "asc"
    }
    const behaviorData = getCurrentBehaviorMap(getState())

    const sortedData = sortData(behaviorData, columnName, order)
    dispatch({
      type: SORT_COLUMN,
      columnName,
      order,
      level,
      levelId,
      label,
      data: sortedData,
    })
  }
}

export function resetColumn() {
  return (dispatch) => {
    dispatch({
      type: SORT_COLUMN,
      columnName: "name",
      order: "asc",
    })
  }
}

export function setTab(tab, riskTypes, callData) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_TAB,
      tab,
    })
    if (callData) {
      this.getTopBottomData({
        levelId: getPageLevelId(getState()),
        level: getPageLevel(getState()),
        company: getCompany(),
        category: `score_${riskTypes[tab - 1]}`,
        limit: 10,
        orderBy: " desc",
        label: "topPerformers",
      })
      this.getTopBottomData({
        levelId: getPageLevelId(getState()),
        level: getPageLevel(getState()),
        company: getCompany(),
        category: `score_${riskTypes[tab - 1]}`,
        limit: 10,
        label: "stragglers",
      })
    }
  }
}

export function resetTab() {
  return (dispatch) => {
    dispatch({
      type: RESET_TAB,
    })
  }
}

// get_scores_distributions

export function getScoresDistributions({
  level = "company",
  levelId = "",
  startDate = Moment().subtract(1, "year").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "scoresDistributions",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()

    const missingId = level !== "company" && !levelCache.get(levelId)
    const validLevelId = levelId || ""
    if (company && (levelCache.size === 0 || missingId)) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        person_nid: validLevelId,
        group_by: "category",
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/scoresDistributions`,
          { params },
        )
        .then(({ data }) => {
          dispatch({
            type: GET_SCORES_DIST,
            level,
            levelId,
            label,
            data: data.scoresDistributions,
          })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export function getRiskDistributions({
  level = "company",
  levelId = "",
  startDate = Moment().subtract(1, "year").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "riskDistributions",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()

    const missingId = level !== "company" && !levelCache.get(levelId)
    const validLevelId = levelId || ""

    let scoresData

    if (company && (levelCache?.size === 0 || missingId)) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        person_nid: validLevelId,
        group_by: "category,datetime-monthly",
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/scoresDistributions`,
          { params },
        )
        .then(({ data }) => {
          if (
            data &&
            data.scoresDistributions &&
            data.scoresDistributions.groups &&
            data.scoresDistributions.groups.overall &&
            data.scoresDistributions.groups.overall.groups
          ) {
            scoresData = data.scoresDistributions.groups.overall.groups
          }
          dispatch({
            type: GET_RISK_DIST,
            level,
            levelId,
            label,
            data: scoresData,
          })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export function getTopDepartments({
  level = "company",
  levelId = "",
  category = "score_overall",
  orderBy = " ",
  groupBy = "person_nid",
  limit = 5,
  startDate = Moment().subtract(1, "year").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "topDepartments",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()

    const missingId =
      level !== "company" && levelCache && !levelCache.get(levelId)

    const personsList = []
    const departmentsList = []

    if (company && levelCache && (levelCache.size === 0 || missingId)) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        order_by: `${category}${orderBy}`,
        limit,
        group_by: groupBy,
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/hierarchicalScores`,
          { params },
        )
        .then(({ data }) => {
          const scoresList = extractScoresListFromData(data)
          scoresList.forEach((score) => {
            personsList.push(score.key)
          })

          return axios
            .post(
              `${getBaseDirectoryApiURL()}/organizations/${company}/persons:batchGet`,
              {
                person_nids: personsList,
              },
            )
            .then(({ data }) => {
              scoresList.forEach((score) => {
                const person = data ? data.persons[score.key] : null

                const performersData = {
                  elevate_person_id: score ? score.key : null,
                  department: person ? person.department_name : null,
                  riskScore: score ? score.scores.score : null,
                  employee_first_name: person ? person.first_name : null,
                  employee_last_name: person ? person.last_name : null,
                }
                departmentsList.push(performersData)
              })
              dispatch({
                type: GET_TOP_DEPARTMENTS,
                level,
                levelId,
                label,
                data: departmentsList,
              })
            })
            .catch((err) => {
              dispatch(incrementLoaded())
              console.error(err)
            })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export function getBehaviorMap({
  level = "company",
  levelId = "",
  startDate = Moment().subtract(1, "year").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "behaviorMap",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()
    const validLevelId = levelId || ""

    const personsList = []
    let departmentsList = []

    if (company && !levelCache) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        person_nid: validLevelId,
        group_by: "person_nid,category",
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/hierarchicalScores`,
          { params },
        )
        .then(({ data }) => {
          const scoresList = extractScoresListFromData(data)
          scoresList.forEach((score) => {
            personsList.push(score.key)
          })
          return axios
            .post(
              `${getBaseDirectoryApiURL()}/organizations/${company}/persons:batchGet`,
              {
                person_nids: personsList,
              },
            )
            .then(({ data }) => {
              scoresList.forEach((score) => {
                const person = data ? data.persons[score.key] : null
                const behaviorsData = {
                  id: score ? score.key : null,
                  name: person ? person.department_name : null,
                  employeeFirst: person ? person.first_name : null,
                  employeeLast: person ? person.last_name : null,
                  scores: score ? score.groups : null,
                  sortValue: person
                    ? `${person.department_name} ${person.last_name} ${person.first_name}`
                    : "",
                }
                departmentsList.push(behaviorsData)
              })
              departmentsList = departmentsList.sort((a, b) =>
                a.sortValue > b.sortValue ? 1 : -1,
              )
              dispatch({
                type: GET_BEHAVIOR_MAP,
                level,
                levelId,
                label,
                data: departmentsList,
              })
            })
            .catch((err) => {
              dispatch(incrementLoaded())
              console.error(err)
            })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export function getSubBehaviorMap({
  level = "company",
  levelId = "",
  startDate = Moment().subtract(1, "year").format("YYYY-MM-DD"),
  endDate = Moment().format("YYYY-MM-DD"),
  label = "subBehaviorMap",
}) {
  return (dispatch, getState) => {
    const levelCache = getState().getIn(["visionReducer", "data", level, label])
    const company = getCompany()

    const missingId =
      level !== "company" && levelCache && !levelCache.get(levelId)
    const validLevelId = levelId || ""

    const personsList = []
    let departmentsList = []

    if (company && levelCache && (levelCache.size === 0 || missingId)) {
      const params = {
        start_date: startDate,
        end_date: endDate,
        person_nid: validLevelId,
        group_by: "person_nid,category",
      }
      return axios
        .get(
          `${getBaseScoresApiURL()}/organizations/${company}/hierarchicalScores`,
          { params },
        )
        .then(({ data }) => {
          const scoresList =
            data && data.hierarchicalScores && data.hierarchicalScores.groups
          scoresList.forEach((score) => {
            personsList.push(score.key)
          })
          return axios
            .post(
              `${getBaseDirectoryApiURL()}/organizations/${company}/persons:batchGet`,
              {
                person_nids: personsList,
              },
            )
            .then(({ data }) => {
              scoresList.forEach((score) => {
                const person = data ? data.persons[score.key] : null

                const behaviorsData = {
                  id: score ? score.key : null,
                  name: person
                    ? `${person.first_name} ${person.last_name}`
                    : null,
                  sortValue: person
                    ? `${person.last_name} ${person.first_name}`
                    : "",
                  scores: score ? score.groups : null,
                  is_manager: person ? person.is_manager : null,
                  is_team_manager: score ? score.key === validLevelId : null,
                }
                departmentsList.push(behaviorsData)
              })
              departmentsList = departmentsList.sort((a, b) =>
                a.sortValue > b.sortValue ? 1 : -1,
              )
              dispatch({
                type: GET_SUB_BEHAVIOR_MAP,
                level,
                levelId,
                label,
                data: departmentsList,
              })
            })
            .catch((err) => {
              dispatch(incrementLoaded())
              console.error(err)
            })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}

export function getBreadcrumbs({ levelId = "", label = "breadcrumbs" }) {
  return (dispatch) => {
    const company = getCompany()
    if (company) {
      return axios
        .get(
          `${getBaseDirectoryApiURL()}/organizations/${company}/persons/${levelId}/superiors`,
        )
        .then(({ data }) => {
          dispatch({
            type: GET_BREADCRUMBS,
            label,
            data: data.superiors,
          })
        })
        .catch((err) => {
          dispatch(incrementLoaded())
          console.error(err)
        })
    }
  }
}
