import React from "react"
import PropTypes from "prop-types"

const SVG = ({ className, fill, size }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      className={className}
      width={size}
      height={size}
    >
      <g
        id="icon-caret-left"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g>
          <rect id="Transparent-Block" x="0" y="0" width={size} height={size} />
          <path
            d="M4.09705627,4.73061046 C3.15979797,3.75646318 1.64020203,3.75646318 0.702943725,4.73061046 C-0.234314575,5.70475773 -0.234314575,7.28416253 0.702943725,8.2583098 L12,20 L23.2970563,8.2583098 C24.2343146,7.28416253 24.2343146,5.70475773 23.2970563,4.73061046 C22.359798,3.75646318 20.840202,3.75646318 19.9029437,4.73061046 L12,12.9446013 L4.09705627,4.73061046 Z"
            id="Mask"
            fill={fill}
            fillRule="nonzero"
            transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SVG

SVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

SVG.defaultProps = {
  fill: "#000",
  className: "",
  size: "16px",
}
