import {
  SHOW_SIDE_DRAWER,
  CLOSE_SIDE_DRAWER,
  SET_RISKS,
  SET_RISKS_NAMES,
  SET_BEHAVIOR_TYPES,
  SET_ACTIONS,
  SET_FLAG_ACTIONS,
  SET_DEPARTMENT,
  SET_LOCATION,
  RISK_TABLE_COUNT,
  SET_JOB_TITLE,
  SET_BUSINESS_TITLE,
  SET_BUSINESS_UNIT,
  SET_ES_MANAGER_ID,
  SET_IS_ONLEAVE,
  SET_JOB_FAMILY_FUNCTION_GROUP,
  SET_JOB_FAMILY_FUNCTION_NAME,
  SET_MANAGER_NID,
  SET_PREFERRED_LANGUAGE,
  SET_PREFERRED_NAME,
  SET_PREFERRED_MIDDLE_NAME,
  SET_PREFERRED_NAME_LOCAL_LANG,
  SET_TIME_TYPE,
  SET_WORK_LOCATION_COUNTRY,
  SET_WORK_LOCATION_GEO,
  SET_WORK_LOCATION_TIMEZONE,
  SET_WORKER_TYPE,
  SET_WORK_LOCATION_BUILDING,
  SET_WORK_LOCATION_CITY,
  SET_WORK_LOCATION_NAME,
  SET_WORK_SPACE,
  SET_LENGTH_OF_SERVICE,
  SET_HIRE_DATE,
  SET_IS_ACTIVE,
  SET_LEAVE_DATE,
  SET_ALTERNATIVE_USER_ID_1,
  SET_ALTERNATIVE_USER_ID_2,
  SET_ADVANCED_FILTERS,
  SET_AVAILABLE_HR_METADATA_KEYS,
} from "./types"

export function showSideDrawer() {
  return {
    type: SHOW_SIDE_DRAWER,
  }
}

export function closeSideDrawer() {
  return {
    type: CLOSE_SIDE_DRAWER,
  }
}
export function setRiskCount(count) {
  return {
    type: RISK_TABLE_COUNT,
    count,
  }
}
export const setRisks = (risks) => ({ type: SET_RISKS, risks })
export const setDepartments = (departments) => ({
  type: SET_DEPARTMENT,
  departments,
})

export const setJobTitle = (jobTitle = []) => ({
  type: SET_JOB_TITLE,
  jobTitle,
})
export const setBusinessTitle = (businessTitle = []) => ({
  type: SET_BUSINESS_TITLE,
  businessTitle,
})
export const setBusinessUnit = (businessUnit = []) => ({
  type: SET_BUSINESS_UNIT,
  businessUnit,
})
export const setEsManagerId = (esManagerId = []) => ({
  type: SET_ES_MANAGER_ID,
  esManagerId,
})
export const setIsOnleave = (isOnleave = []) => ({
  type: SET_IS_ONLEAVE,
  isOnleave,
})
export const setJobFamilyFunctionGroup = (jobFamilyFunctionGroup = []) => ({
  type: SET_JOB_FAMILY_FUNCTION_GROUP,
  jobFamilyFunctionGroup,
})
export const setJobFamilyFunctionName = (jobFamilyFunctionName = []) => ({
  type: SET_JOB_FAMILY_FUNCTION_NAME,
  jobFamilyFunctionName,
})
export const setManagerNid = (managerNid = []) => ({
  type: SET_MANAGER_NID,
  managerNid,
})
export const setPreferredLanguage = (preferredLanguage = []) => ({
  type: SET_PREFERRED_LANGUAGE,
  preferredLanguage,
})
export const setPreferredName = (preferredName = []) => ({
  type: SET_PREFERRED_NAME,
  preferredName,
})
export const setPreferredMiddleName = (preferredMiddleName = []) => ({
  type: SET_PREFERRED_MIDDLE_NAME,
  preferredMiddleName,
})
export const setPreferredNameLocalLang = (preferredNameLocalLang = []) => ({
  type: SET_PREFERRED_NAME_LOCAL_LANG,
  preferredNameLocalLang,
})
export const setTimeType = (timeType = []) => ({
  type: SET_TIME_TYPE,
  timeType,
})
export const setWorkLocationCountry = (workLocationCountry = []) => ({
  type: SET_WORK_LOCATION_COUNTRY,
  workLocationCountry,
})
export const setWorkLocationGeo = (workLocationGeo = []) => ({
  type: SET_WORK_LOCATION_GEO,
  workLocationGeo,
})
export const setWorkLocationTimezone = (workLocationTimezone = []) => ({
  type: SET_WORK_LOCATION_TIMEZONE,
  workLocationTimezone,
})
export const setWorkerType = (workerType = []) => ({
  type: SET_WORKER_TYPE,
  workerType,
})
export const setWorkLocationBuilding = (workLocationBuilding = []) => ({
  type: SET_WORK_LOCATION_BUILDING,
  workLocationBuilding,
})
export const setWorkLocationCity = (workLocationCity = []) => ({
  type: SET_WORK_LOCATION_CITY,
  workLocationCity,
})
export const setWorkLocationName = (workLocationName = []) => ({
  type: SET_WORK_LOCATION_NAME,
  workLocationName,
})
export const setWorkSpace = (workSpace = []) => ({
  type: SET_WORK_SPACE,
  workSpace,
})
export const setLengthOfService = (lengthOfService = []) => ({
  type: SET_LENGTH_OF_SERVICE,
  lengthOfService,
})
export const setHireDate = (hireDate = []) => ({
  type: SET_HIRE_DATE,
  hireDate,
})
export const setIsActive = (isActive = []) => ({
  type: SET_IS_ACTIVE,
  isActive,
})
export const setLeaveDate = (leaveDate = []) => ({
  type: SET_LEAVE_DATE,
  leaveDate,
})
export const setAlternativeUserId1 = (alternativeUserId1 = []) => ({
  type: SET_ALTERNATIVE_USER_ID_1,
  alternativeUserId1,
})
export const setAlternativeUserId2 = (alternativeUserId2 = []) => ({
  type: SET_ALTERNATIVE_USER_ID_2,
  alternativeUserId2,
})

export const setAvailableHRMetadataKeys = (availableHRMetadataKeys = []) => ({
  type: SET_AVAILABLE_HR_METADATA_KEYS,
  availableHRMetadataKeys,
})

export const setLocations = (locations) => ({ type: SET_LOCATION, locations })

export const setFlagActions = (actions_list) => ({
  type: SET_FLAG_ACTIONS,
  actions_list,
})

export const setActions = (actions) => ({ type: SET_ACTIONS, actions })

export const setRisksNames = (risksNames, risksFilters, actionsNames) => ({
  type: SET_RISKS_NAMES,
  risksNames,
  risksFilters,
  actionsNames,
})

export const setAdvancedFilters = (filters) => ({
  type: SET_ADVANCED_FILTERS,
  filters,
})

export const setBehaviorTypes = (behaviorTypes) => {
  return {
    type: SET_BEHAVIOR_TYPES,
    behaviorTypes,
    userFacingBehaviorTypes: (behaviorTypes || []).map(
      ({ action_category, behavior_type }) => {
        const dashedActionCategory = action_category.replaceAll("_", "-")
        const actionCategoryRemapping = {
          phishing: "simulated-phishing",
          "real-world-phishing": "actual-phishing",
        }
        const remappedActionCategory =
          actionCategoryRemapping[dashedActionCategory] || dashedActionCategory
        const behaviorTypeRemapping = Object.keys(actionCategoryRemapping).find(
          (typeToRemap) => behavior_type.startsWith(typeToRemap),
        )
        const remappedBehaviorType = behaviorTypeRemapping
          ? behavior_type.replace(
              behaviorTypeRemapping,
              actionCategoryRemapping[behaviorTypeRemapping],
            )
          : behavior_type
        return {
          actionCategory: remappedActionCategory.replaceAll("-", "_"),
          behaviorType: remappedBehaviorType,
          behaviorTypeShortName: behavior_type.replace(
            dashedActionCategory + "-",
            "",
          ),
        }
      },
    ),
  }
}
