import styled from "styled-components"

export const PrivilegesToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  [class^="Togglestyles__LabelText"] {
    padding: 2px 4px 2px 10px;
  }
  [class^="Togglestyles__Content"] {
    background-color: #959da8;
  }
  span {
    margin-top: 2px;
  }
`

export const ToggleWrapperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #565d66;
  margin-left: 8px;
`

export const CsvToggleTextWrapper = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
`

export const TogglesGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TemplateContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 24px;
  height: auto;
  background-color: #ffffff;
`
export const AddConditionLink = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  a[class^="ButtonLink__StyledButtonLink"] {
    color: grey !important;
  }
`

export const AndText = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: 800;
`

export const SelectContainer = styled.div`
  [class^="icons-right"] {
    z-index: 1;
  }
  display: flex;
  width: auto;
`

export const ConditionContainer = styled.div`
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #dde1e7;
`

export const ORConditionContainer = styled.div`
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
`

export const StyledList = styled.ul`
  position: absolute;
  top: 48px;
  background-color: white;
  height: auto;
  max-height: 39vh;
  margin: 2px 0px 0px;
  padding: 0px;
  border-radius: 4px;
  transition: border-color 250ms ease 0s;
  z-index: 10001;
  border: 1px solid rgb(221, 225, 231);
  box-shadow: rgb(221 225 231) 0px 2px 8px 0px;
  min-width: 330px;
  margin-left: ${({ indexFlag }) => (indexFlag > 0 ? "40px" : "0px")};
  overflow-y: scroll;
  @media (max-height: 720px) {
    max-height: 28vh;
  }
`

export const StyledOption = styled.li`
  display: block;
  text-align: left;
  position: relative;
  width: 100%;
  min-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px 14px 8px 12px;
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  color: #565d66;
  cursor: pointer;
  outline: 0;
`

export const DownArrowContainer = styled.div`
  display: block;
  text-align: left;
  position: relative;
  width: 100%;
  min-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px 0px;
  font-size: 14px;
  line-height: 21px;
  background-color: transparent;
  color: #565d66;
  cursor: pointer;
  outline: 0;
`

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 23px;
  margin: 5px 0;
  border: solid 1px lightgray;
  border-radius: 5px;
  min-width: 480px;
`

export const DropdownItem = styled.div`
  width: 100%;
`

export const DropdownTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: red;
  align-items: center;
  cursor: pointer;
  z-index: 3000;
  width: 100%;
`

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const Strong = styled.span`
  margin-right: 5px;
  font-weight: 600;
`

export const IconWrapper = styled.div`
  .mail-icon:hover {
    fill: #039ba1;
    cursor: pointer;
  }
  .mail-icon {
    fill: #0c7d84;
    width: 22px;
    height: 18px;
  }
  span {
    text-decoration: none;
    color: #0c7d84;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 0;
  }
  span:hover {
    color: #039ba1;
    cursor: pointer;
  }
  margin-left: 17px;
`

export const TrashCriteriaIconContainer = styled.div`
  margin-left: 25px;
  position: absolute;
  right: 46px;
`

export const PolicyGroupWrapper = styled.div`
  display: ${(props) => props.isHide};
  flex-direction: column;
  gap: 16px;
`

export const PolicyGroupRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  [class^="CustomSelectstyles__CustomSelectContainer"] {
    margin: 0;
  }
  input:disabled {
    background-color: #f5f6f8;
  }
`

export const NotificationConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 5px;
`

export const EmailIconWrapper = styled.div`
  margin-left: ${(props) => props.marginLeft};
  display: ${(props) => props.showMsgIcon};
  flex-direction: row;
  align-items: center;
  p {
    padding: 0;
    margin-left: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0c7d84;
    margin-right: 5px;
    margin-bottom: 0;
  }
  p:first-of-type {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  svg {
    margin-bottom: 0px;
  }
  p.edit-notification {
    &:hover {
      cursor: pointer;
      color: #039ba1;
    }
  }
`
export const ModalWrapper = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    display: flex !important;
    flex-direction: column !important;
    position: fixed !important;
    width: calc(100% - 20px) !important;
    max-width: 800px !important;
    max-height: 520px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow:
      0 1px 3px 0 hsl(212deg 19% 28% / 10%),
      0 4px 32px 0 hsl(212deg 19% 28% / 10%);
    z-index: 10001;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 28px 48px;
    [class^="Modalstyles__ModalHeader"] {
      background-color: #ffffff;
      border-radius: 8px;
    }
    [class^="Modalstyles__ModalContent"] {
      background-color: #ffffff;
      padding-top: 20px;
    }
    [class^="Modalstyles__ModalFooter"] {
      display: flex !important;
      background-color: #ffffff;
      margin-top: 10px;
      padding: 24px 24px 24px 24px;
      border-radius: 8px;
    }
  }
`

const tableWidth = (columns) => `calc(100% / ${columns}) !important`
export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
    /* Stick table header at top */
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      position: relative;
      top: 0;
      z-index: 998;
      background: white;
      overflow: visible;
      padding-right: 0;
      [class^="Tablestyles__TableRow"] {
        padding: 0;
        height: 56px;
        [class^="Tablestyles__TableData"] {
          padding-top: 12px;
          padding-bottom: 12px;
          background: white;
          span {
            color: #7b828a;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      [class^="Tablestyles__TableRow"] {
        cursor: default;
      }
      [class^="Tablestyles__TableData"][data-column-key="checkbox"] input {
        margin-left: 6px;
      }
    }
  }
  [class^="Tablestyles__TablePagination"] {
    border-top: 1px solid #dde1e7;
    padding: 20px 0 27px 0;
  }
  [class^="Tablestyles__TableRow"] {
    padding: 0;
    height: 40px;
    margin-top: 0;
  }
  [class^="Tablestyles__TableData"] {
    /* flex: 0 0 calc(100% / 7); */
    /* width: calc(100% / 7); */
    min-width: ${(props) => tableWidth(props.columns)};
    width: ${(props) => tableWidth(props.columns)};
    max-width: ${(props) => tableWidth(props.columns)};
    //min-width: calc(100% / 5) !important;

    &:last-child {
      padding-right: 0;
    }
    > .span {
      display: block;
      width: 100%;
    }
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #7b828a;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 40px;
`

export const RiskRulesOutcomePrimaryPanel = styled.div`
  flex: 1;
  padding: 0px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #dde1e7;
`

export const RiskRulesOutcomeSecondaryPanel = styled.div`
  flex: 1;
  background-color: #fff;
  border: 1px solid #dde1e7;
`
export const ToggleRightWrapper = styled.div`
  display: ${(props) => props.show};
`

export const ActionContent = styled.div`
  padding: 0 0 0 55px;
`

export const RiskRulesCriteriaTopButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-bottom: 20px;
`

export const IndividualsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0 0 55px;
  width: 100%;
`

export const AddRemoveWrapper = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: 185px;
`

export const PolicyGroupRowWrapper = styled.div`
  [class^="ComboBoxstyles__SelectContainer"] {
    margin: 0;
    width: 317px;
  }
  input:disabled {
    background-color: #f5f6f8;
  }
`
