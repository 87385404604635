import React from "react"
import { convertTo12Hours, ordinal_suffix_of } from "../utils"
import { OCCURRENCE, humanizeCadenceMap } from "../constants"
import { isEmpty } from "@src/utils/string"
import { Bold } from "../styles"
import moment from "moment-timezone"
import { formatTimezone } from "@src/utils/dates"

export const SchedulerOutput = (props) => {
  const { scheduler = {} } = props
  const {
    cadence,
    day_of_week,
    month,
    day,
    hour,
    minute,
    start_date,
    end_date,
    interval,
  } = scheduler
  const convertedTime = convertTo12Hours(parseInt(scheduler?.hour))
  let daysStr
  let minuteStr = `${minute}`

  if (day) {
    const sortedDays = day
      ?.split(",")
      ?.map((dayStr) => parseInt(dayStr))
      .sort((a, b) => a - b)
      ?.map((item) => ordinal_suffix_of(item))
    daysStr = sortedDays.join(", ")
  }

  if (minute < 10 && minute > 0) {
    minuteStr = `0${minute}`
  } else if (minute <= 0) {
    minuteStr = "00"
  }

  const generateCustomIntervalsOutput = () => {
    const hoursList = hour?.split(",")
    const times = hoursList.map((hour, index) => {
      const convertedTime = convertTo12Hours(parseInt(hour))
      let separator = ", "
      if (index === 0) {
        separator = ""
      } else if (index + 1 === hoursList.length) {
        separator = " and "
      }
      return `${separator}${convertedTime.hour}:${minuteStr} ${convertedTime?.timePeriod}`
    })
    const timesStr = times.join("")
    return `${timesStr} ${formatTimezone(
      moment.tz(scheduler.timezone).format("z"),
      scheduler.timezone,
    )} `
  }

  return (
    <div>
      <div>
        {cadence !== OCCURRENCE.INTERVAL && (
          <Bold>{humanizeCadenceMap[cadence]} </Bold>
        )}
        {isEmpty(interval) && cadence === OCCURRENCE.INTERVAL && (
          <span>
            <Bold>{generateCustomIntervalsOutput()}</Bold>
            daily
          </span>
        )}
        {interval && (
          <span>
            <Bold>Every {interval} hours </Bold>daily
          </span>
        )}
        {month && (
          <span>
            in <Bold>{month.replaceAll(",", ", ")} </Bold>
          </span>
        )}
        {day_of_week && (
          <span>
            on <Bold>{day_of_week.replaceAll(",", ", ")} </Bold>
          </span>
        )}
        {daysStr && (
          <span>
            on the <Bold>{daysStr} </Bold>
          </span>
        )}
        {cadence !== OCCURRENCE.ASAP &&
          cadence !== OCCURRENCE.INTERVAL &&
          !isEmpty(convertedTime.hour) &&
          !isEmpty(minuteStr) && (
            <span>
              at <Bold>{generateCustomIntervalsOutput()}</Bold>
            </span>
          )}
      </div>

      {start_date && (
        <div>
          <Bold>Starts </Bold>
          {start_date}
        </div>
      )}
      {end_date && (
        <div>
          <Bold>Ends </Bold>
          {end_date}
        </div>
      )}
    </div>
  )
}
