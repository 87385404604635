import { useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

import useQuery from "@src/services/hooks/useQuery"
import { buildQueryString } from "@src/utils/buildQueryString"
import { useDebounceFn } from "ahooks"

export const useApplicationsFilters = () => {
  const searchParams = useQuery()

  const [filters, setFilters] = useState({
    name: searchParams.get("name"),
    criticality: searchParams.get("criticality"),
    application_type: searchParams.get("application_type"),
    source: searchParams.get("source"),
  })

  const history = useHistory()
  const { pathname } = useLocation()

  const queryString = buildQueryString(filters)

  useEffect(() => {
    history.push({
      pathname,
      search: queryString,
    })
  }, [queryString])

  const updateFilter = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const { run: updateFilterDebounced } = useDebounceFn(updateFilter, {
    wait: 300,
  })

  return {
    filters,
    updateFilter,
    updateFilterDebounced,
  }
}
