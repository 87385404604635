import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import "rc-slider/assets/index.css"
import Slider from "rc-slider"
import { Input } from "@src/components/MimecastReskin"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import {
  RangeWrapper,
  InputWrapper,
  Dash,
  InputCell,
  ErrorMessage,
} from "./styles"
import { MoreFilterInfoMessage } from "@src/scenes/IndividualProfilePage/components/MoreFilter/MoreFilterInfoMessage"
import { THEME_PRIMARY } from "@src/theme"

const handleStyle = {
  width: "32px",
  height: "32px",
  border: "1px solid #B1B8C1",
  boxShadow: "0px 1px 4px rgba(177, 184, 193, 0.32)",
  marginTop: "-14px",
}

const SliderFilter = ({
  section,
  filters,
  handleChange,
  initialRange = [0, 100],
  stepIncrement = "0.1",
  infoMessage = "",
}) => {
  const [range, setRange] = useState(initialRange)
  const [expanded, setExpanded] = useState(false)

  const { key } = section
  const timeout = useRef(false)

  const errorMessage = (value, compare, endpoint) => {
    const start = parseFloat(value)
    const end = parseFloat(compare)
    if (isNaN(start)) return "Can’t be empty"
    else if (endpoint === "start" && start > end) return "Invalid score"
    else if (endpoint === "end" && start < end) return "Invalid score"
    return "Invalid score"
  }

  const isValueInvalid = (value, compare, endpoint) => {
    const value1 = parseFloat(value)
    const value2 = parseFloat(compare)
    if (
      isNaN(value1) ||
      value1 < initialRange[0] ||
      value1 > initialRange[1] ||
      (endpoint === "start" && value1 > value2) ||
      (endpoint === "end" && value1 < value2)
    ) {
      return true
    }
    return false
  }
  useEffect(() => {
    const applied = filters?.find((filter) => filter.key === key)
    if (applied) {
      const values = applied.value.split("-")
      setRange(values)
      setExpanded(true)
    }
  }, [])

  const handleInputChange = (val) => {
    clearTimeout(timeout.current)
    setRange(val)
    if (
      isValueInvalid(val[0], val[1], "start") ||
      isValueInvalid(val[1], val[0], "end")
    ) {
      return
    }

    timeout.current = setTimeout(() => {
      onChange(val)
    }, 600)
  }

  const onChange = (arr) => {
    setRange(arr)
    const filter = {
      key,
      label: `${arr[0]} - ${arr[1]}`,
      value: `${arr[0]}-${arr[1]}`,
      checked: !(
        arr[0] === (initialRange?.[0] || 0) &&
        arr[1] === (initialRange?.[1] || 100)
      ), // remove score filter if (0-100 is default)
    }
    handleChange(filter)
  }

  return (
    <div>
      <div className="title" onClick={() => setExpanded(!expanded)}>
        {section.title}
        <Icon
          name={`Caret${expanded ? "Up" : "Down"}`}
          size="xxsm"
          fill="#959DA8"
          className="expand-icon"
        />
      </div>
      <RangeWrapper expanded={expanded} hasInfoMessage={Boolean(infoMessage)}>
        <InputWrapper>
          <InputCell>
            <Input
              type="number"
              step={stepIncrement}
              data-id={`${key}-first`}
              value={range[0].toString()}
              onChange={(val) => handleInputChange([val, range[1]])}
              invalid={isValueInvalid(range[0], initialRange[1], "start")}
            />
            {isValueInvalid(range[0], initialRange[1], "start") && (
              <ErrorMessage>
                {errorMessage(range[0], range[1], "start")}
              </ErrorMessage>
            )}
          </InputCell>
          <Dash />
          <InputCell>
            <Input
              name="val"
              type="number"
              step={stepIncrement}
              data-id={`${key}-last`}
              value={range[1].toString()}
              onChange={(val) => handleInputChange([range[0], val])}
              invalid={isValueInvalid(range[1], initialRange[0], "end")}
            />
            {isValueInvalid(range[1], initialRange[0], "end") && (
              <ErrorMessage>
                {errorMessage(range[1], range[0], "end")}
              </ErrorMessage>
            )}
          </InputCell>
        </InputWrapper>
        <Slider
          range
          value={range}
          step={stepIncrement}
          onChange={(val) => setRange(val)}
          onAfterChange={(val) => onChange(val)}
          allowCross={false}
          min={initialRange?.[0] || 0}
          max={initialRange?.[1] || 100}
          railStyle={{
            backgroundColor: "#DDE1E7",
          }}
          trackStyle={[
            {
              backgroundColor: THEME_PRIMARY,
            },
          ]}
          handleStyle={[handleStyle, handleStyle]}
        />
        {infoMessage && <MoreFilterInfoMessage message={infoMessage} />}
      </RangeWrapper>
    </div>
  )
}

SliderFilter.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default SliderFilter
