import moment from "moment"
import {
  getBehaviorTypes,
  getHierarchicalScores,
  getTopPerformersId,
  getStragglersId,
  getOverallScore,
  getCompanyLoaded,
  getScoresDistributions,
  getTopDepartments,
  getRiskDistributions,
  getBehaviorMap,
} from "@src/services/selectors/visionSelectors"

import { dashToTitleCase } from "@src/utils/string"
import { isHierarchyScoresMonthlyLoaded } from "@src/services/redux/scores/selectors"
import { getLevelId } from "@src/scenes/AdminDashboardContainer/components/utils"
import { getCompanyInfo } from "@src/services/redux/company/selectors"
import {
  getScoresByLocation,
  getScoresByDepartment,
} from "@src/services/apis/scores"

export function loadState({ actions, history }) {
  const { level, id } = getLevelId(history)
  actions.setHierarchicalScoresMonthly()
  actions.getBehaviorMap({
    level,
  })

  actions.getTopBottomData({
    level,
    levelId: id,
    category: "score_overall",
    limit: 10,
    label: "stragglers",
  })

  actions.getTopBottomData({
    level,
    levelId: id,
    category: "score_overall",
    orderBy: " desc",
    limit: 10,
    label: "topPerformers",
  })

  actions.getTopDepartments({
    level,
    levelId: id,
    category: "score_overall",
    groupBy: "person_nid",
    limit: 5,
  })

  actions.getHistoricalTrend({
    level,
    levelId: id,
  })

  actions.getScoresDistributions({
    level,
    levelId: id,
  })

  actions.getRiskDistributions({
    level,
    levelId: id,
  })

  actions.setScoresByDepartment({})
}

export function getState(state, props = {}) {
  return {
    behaviorTypes: getBehaviorTypes(state),
    monthlyScore: getHierarchicalScores(state),
    topPerformers: getTopPerformersId(state, props),
    stragglers: getStragglersId(state, props),
    topOverallScore: getOverallScore(state),
    scoresDistributions: getScoresDistributions(state),
    topDepartments: getTopDepartments(state),
    riskDistributions: getRiskDistributions(state),
    behaviorMapData: getBehaviorMap(state),
    loadedCount: getCompanyLoaded(state),
    isHierarchyScoresMonthlyLoaded: isHierarchyScoresMonthlyLoaded(state),
  }
}

export function behaviorMapTypes({ behaviorTypes }) {
  return ["name"].concat(behaviorTypes)
}

export function extractBehaviorsStatusData(monthlyScore) {
  if (
    monthlyScore === undefined ||
    !Array.isArray(monthlyScore) ||
    !monthlyScore.length
  ) {
    return []
  }

  // Retrieve data from last 2 months
  const latestMonthData = monthlyScore.slice(-1)[0]
  const secondLastMonthData = monthlyScore.slice(-2)[0]
  const latestMonthBehaviorScores =
    latestMonthData[Object.keys(latestMonthData)[0]].groups
  const secondLastMonthBehaviorScores =
    secondLastMonthData[Object.keys(secondLastMonthData)[0]].groups

  // Extract behavior scores from last 2 months and populate status
  const result = []
  const behaviorTypes = Object.keys(latestMonthBehaviorScores)
  behaviorTypes.forEach((behaviorType) => {
    if (
      behaviorType !== "overall" &&
      latestMonthBehaviorScores[behaviorType].scores.score
    ) {
      result.push({
        behaviorName: dashToTitleCase(behaviorType),
        behaviorScore: latestMonthBehaviorScores[behaviorType].scores.score,
        behaviorScoreChange:
          latestMonthBehaviorScores[behaviorType].scores.score -
          secondLastMonthBehaviorScores[behaviorType].scores.score,
      })
    }
  })

  return result
}

export function getLastUpdateTimeLabel(scoring_datetime) {
  const companyInfo = getCompanyInfo()

  if (companyInfo) {
    const date = new Date(scoring_datetime)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Show import stats only if a successful import has completed
    if (scoring_datetime) {
      const parseDate = moment(scoring_datetime)
      const lastUpdated = `Last updated on ${parseDate.format(
        "MMM Do, YYYY [at] h:mm A",
      )} ${timezone}`

      return date.toDateString() === new Date().toDateString()
        ? `Last update ${parseDate.format("[Today] [at] h:mm A")} ${timezone}`
        : lastUpdated
    }
  }
  return null
}

export function getStackedWidths(datasetLength) {
  return {
    curr: `1 1 ${Math.floor((1 / datasetLength) * 100) + 15}%`,
    historical: `1 1 ${
      Math.floor(((datasetLength - 1) / datasetLength) * 100) - 15
    }%`,
  }
}

export async function getNumberStragglers() {
  const { shortname: organization_nid } = getCompanyInfo()
  const limit = 10
  const offset = 0

  const params = { limit, offset, organization_nid }

  const calculateStragglersByLocationsOrDeparments = (
    locationsOrDepartments,
  ) => {
    if (locationsOrDepartments) {
      const filter = (item) => item.scores.score.overall <= 40
      const stragglerCount =
        locationsOrDepartments.data?.results?.filter(filter).length || 0
      const totalCount = locationsOrDepartments.data?.results?.length || 0
      return [stragglerCount, totalCount]
    }
  }

  const calculateStragglersByIndividuals = (individuals) => {
    if (individuals) {
      const sumOfMembersCount = (acc, item) => acc + item.members_count
      const sumOfMembersWithLevel4orLevel5 = (acc, item) => {
        const { level_4, level_5 } = item.scores["score-distribution"].overall
        return acc + level_4 + level_5
      }
      const totalCount =
        individuals.data?.results?.reduce(sumOfMembersCount, 0) || 0
      const stragglerCount =
        individuals.data?.results?.reduce(sumOfMembersWithLevel4orLevel5, 0) ||
        0
      return [stragglerCount, totalCount]
    }
  }

  return Promise.all([
    getScoresByLocation(params),
    getScoresByDepartment(params),
  ]).then(([locations, departments]) => {
    const stragglers = {
      Location: [0, 0],
      Department: [0, 0],
      Individual: [0, 0],
    }

    stragglers.Location = calculateStragglersByLocationsOrDeparments(locations)
    stragglers.Department =
      calculateStragglersByLocationsOrDeparments(departments)
    stragglers.Individual = calculateStragglersByIndividuals(
      locations || departments,
    )

    return stragglers
  })
}

export function loadHierarchicalCampaignsScoresState({ actions }) {
  actions.setHierarchicalCampaignsScores()
}
