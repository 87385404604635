export function extractRoute({
  pathname,
  search,
  hash,
}: {
  pathname: string
  search?: string
  hash?: string
}): string {
  return `${pathname}${search}${hash}`
}
