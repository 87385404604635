import React from "react"

import { ActionGroups } from "./ActionGroups"

import { ActionConfig } from "./actionConfigTypes"
import { CONFIRM_CISCO_DUO_COMPROMISED_USERS } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { EditCiscoDuoConfirmCompromisedUser } from "../actionRenderComponents/EditCiscoDuoConfirmCompromisedUser"

type ActionPayload = {
  action_type: string
  matching_method: "delta" | "snapshot"
  user_status: "active" | "bypass" | "disabled"
}

const config: ActionConfig<ActionPayload> = {
  label: "Cisco Duo Users - Set Authentication Status",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: CONFIRM_CISCO_DUO_COMPROMISED_USERS,
    matching_method: "delta",
    user_status: "active",
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("cisco_duo", "risky-users"),
  render: ({ details, updateAction }) => {
    return (
      <EditCiscoDuoConfirmCompromisedUser
        action={details}
        updateAction={updateAction}
      />
    )
  },
}

export default config
