import styled from "styled-components"

export const TableCard = styled.div`
  background-color: white;
  padding: 0px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #e5e8ed;
  box-shadow: 0px 2px 8px 0px rgba(209, 212, 218, 0.3);
`
