import React, { useEffect, useState } from "react"
import {
  VcDataTrendStatBox,
  VcDataTrendsText,
  VcStatBox,
  VcStatsContainer,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/VcDemo/style"
import { humanize } from "@src/utils/string"
import TextOverflow from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/TextOverflow"
import moment from "moment"
import { getSpecificActionAbleInsightDataTrends } from "@src/services/apis/reputation"
import { SkeletonLoader } from "@src/utils/skeleton"

function VcDataTrends({ item, setSelectedTab, visitedTab, setVisitedTab }) {
  const [dataTrend, setDataTrend] = useState({})
  const [loading, setLoading] = useState(true)

  const showFactors = (key) => {
    const individuals = dataTrend[`${key}`] || 0
    const individualsPercentage = dataTrend[`${key}_percent`] || 0
    const percentageTxt = individuals > 0 ? `(${individualsPercentage}%)` : ""
    return (
      <VcStatBox>
        {!loading ? (
          <>
            <h3>
              {individuals} {percentageTxt}
            </h3>
            <p>{humanize(trendFieldLabel[key] || key).toUpperCase()}</p>
          </>
        ) : (
          <SkeletonLoader height={80} width={270} />
        )}
      </VcStatBox>
    )
  }
  const trendFieldLabel = {
    very_weak_or_weak_action_factor: "weak_or_very_weak_action_factor",
  }
  const showDataTrendFields = {
    high_or_very_high_access_factor: (key) => showFactors(key),
    high_or_very_high_attacked_factor: (key) => showFactors(key),
    very_weak_or_weak_action_factor: (key) => showFactors(key),
    most_frequent_department: (key) => (
      <VcDataTrendStatBox>
        {!loading ? (
          <>
            <h3 style={{ width: "90%" }}>
              <TextOverflow
                name={humanize(dataTrend[key] || "")}
                color="#34383C"
              />
            </h3>
            <p>{humanize("Most represented department").toUpperCase()}</p>
          </>
        ) : (
          <SkeletonLoader width={270} height={80} />
        )}
      </VcDataTrendStatBox>
    ),
    average_human_risk_score: (key) => (
      <VcStatBox>
        {!loading ? (
          <>
            <h3>{dataTrend[key] || 0}</h3>
            <p>{humanize(key).toUpperCase()}</p>
          </>
        ) : (
          <SkeletonLoader width={270} height={80} />
        )}
      </VcStatBox>
    ),
    average_start_date: () => (
      <VcStatBox>
        {!loading ? (
          <>
            <h3>{averageTenure()}</h3>
            <p>{humanize("Average Tenure Length").toUpperCase()}</p>
          </>
        ) : (
          <SkeletonLoader width={270} height={80} />
        )}
      </VcStatBox>
    ),
  }
  const individualHandler = () => {
    setSelectedTab(1)
    setVisitedTab({ ...visitedTab, individual_involve: true })
  }
  const averageTenure = () => {
    const previousDate = new moment(
      dataTrend?.average_start_date?.toString() || 0,
    )
    const days = new moment().diff(previousDate, "days")
    switch (days) {
      case 1:
        return `1 day`
      case 31:
        return `1 month`
      case 365:
        return `1 year`
      default:
        if (days > 383) return `${(days / 365).toFixed(1)} years`
        else if (days > 365) return `${Math.round(days / 365)} year`
        else if (days > 31) return `${Math.round(days / 30)} months`
        else return `${days} days`
    }
  }

  useEffect(() => {
    async function getSpecificActionAbleInsightDataTrendsHandler() {
      const response = await getSpecificActionAbleInsightDataTrends(
        item.insight,
      )
      setLoading(false)
      setDataTrend(response?.data || {})
    }
    getSpecificActionAbleInsightDataTrendsHandler()
  }, [])

  return (
    <div style={{ height: "calc(100vh - 200px)" }}>
      <p style={{ marginTop: "24px" }}>
        Upon analysis of the{" "}
        <VcDataTrendsText onClick={individualHandler}>
          {item?.total_individuals.toLocaleString()} individuals involved
        </VcDataTrendsText>
        , we see the following trends:
      </p>
      <VcStatsContainer>
        {Object.keys(showDataTrendFields).map((key, i) => {
          return <div key={i}>{showDataTrendFields[key](key)}</div>
        })}
      </VcStatsContainer>
    </div>
  )
}

export default VcDataTrends
