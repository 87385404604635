import React from "react"
import { useSelector } from "react-redux"

import { Section, Row, Column } from "./styles"

import ScoreHorizontalBar from "@src/components/ScoreHorizontalBar"
import ScoreTooltip from "./ScoreTooltip"
import ScoreBreakdown from "./ScoreBreakdown"
import BehaviorsLevel from "./BehaviorsLevel"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import { theme } from "@elevate_security/elevate-component-library"
import {
  getTeamInsights,
  getTeamOverallScore,
  getHasDataLoaded,
  getTeamWeakestBehaviors,
} from "../../../../services/redux/selectors"
import ActivityIndicator from "@src/components/ActivityIndicator"

const { H3 } = Typography

const TeamSecurityScore = () => {
  const { overallScore, overallScoreLastMonth, overallScoreTrend } =
    useSelector(getTeamOverallScore)
  const insights = useSelector(getTeamInsights) || {}
  const weakestBehaviors = useSelector(getTeamWeakestBehaviors)
  const { teamDataLoaded } = useSelector(getHasDataLoaded)

  return (
    <Section>
      <ScoreHorizontalBar
        score={overallScore}
        scoreLastMounth={overallScoreLastMonth}
        scoreTitle="/ Your team’s security score "
      />
      <ScoreTooltip
        score={overallScore}
        scoreTrend={overallScoreTrend}
        insights={insights}
      />
      <H3
        fontWeight="bold"
        style={{ color: theme.text.color[700], fontSize: 18 }}
      >
        Security Score Breakdown
      </H3>
      <Row>
        <Column padding="24px 20px 0 0">
          <ScoreBreakdown />
        </Column>
        <Column padding="24px 0 0 20px">
          <BehaviorsLevel
            insights={insights}
            weakestBehaviors={weakestBehaviors}
          />
        </Column>
      </Row>
      <ActivityIndicator active={!teamDataLoaded} />
    </Section>
  )
}

export default TeamSecurityScore
