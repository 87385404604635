import fetcher from "@src/services/fetcher"
import { useRequest } from "ahooks"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"

export type PredefinedWatchlistId =
  | "training_overdue_ge_1_in_60_minutes"
  | "training_completed_not_overdue_ge_1_in_1_day"
  | "sim_phish_clicked_ge_1_in_60_minutes"
  | "sim_phish_compromised_ge_1_in_60_minutes"
  | "actual_phish_clicked_eq_0_in_1_week_and_reported_ge_1_in_1_day"
  | "sim_phish_clicked_or_compromised_eq_0_in_1_week_and_reported_ge_1_in_1_days"
  | "actual_phish_clicked_ge_1_in_60_minutes"
  | "actual_phish_clicked_gt_1_in_1_week"
  | "sim_phish_clicked_gt_1_in_1_week"
  | "actual_phish_clicked_gt_1_in_1_month_or_sim_phish_clicked_gt_1_in_1_month"
  | "security_champion_past_3_months"
  | "malware_executed_or_downloaded_ge_1_in_1_week"
  | "malware_executed_or_downloaded_gt_1_in_1_month"
  | "malware_executed_or_downloaded_eq_0_in_1_month"

export type Watchlist = {
  name: string
  description: string
  watchlist_nid: PredefinedWatchlistId
}

export function useWatchlist({ watchlistId }: { watchlistId: string }): {
  watchlist: Watchlist
  loading: boolean
  error?: Error
} {
  const {
    data: watchlist,
    loading,
    error,
  } = useRequest(() =>
    fetcher
      .get(`${BASES_REPUTATIONS_API_URL}/watchlists/${watchlistId}`)
      .then((res) => res.data),
  )

  return {
    watchlist,
    loading,
    error,
  }
}
