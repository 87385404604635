import {
  createNewGroupWithFilters,
  createHackersMindCampaign,
  createPulseCampaign,
  getPulseCampaignTemplates,
  createReflexCampaign,
} from "@src/services/apis/campaigns"
import { getCompanyInfo } from "@src/services/redux/company/selectors"

export function createGroupWithFilters(filters, payload) {
  return async () => {
    const { error, data } = await createNewGroupWithFilters(filters, payload)
    return {
      error,
      data,
    }
  }
}

export function createCampaignHM(name, group_id) {
  return async () => {
    const company = getCompanyInfo()
    const { error, data } = await createHackersMindCampaign({
      name,
      groups: [group_id],
      organization: company.id,
      tags: [],
      status: "D",
    })
    return {
      error,
      data,
    }
  }
}

export function createCampaignPulse(name, group_id, group_name) {
  return async () => {
    const company = getCompanyInfo()
    const { error, data: templates } = await getPulseCampaignTemplates()
    if (!error && templates && templates.length) {
      const { error: error2, data } = await createPulseCampaign({
        name,
        group_id,
        group_name,
        organization_id: company.id,
        language: "ENGLISH",
        template: templates[0].template_path,
        campaign_type: templates[0].template_type.toUpperCase(),
      })
      return {
        error: error2,
        data,
      }
    } else {
      return { error }
    }
  }
}

export function createCampaignReflex(name, group_id, group_name) {
  return async () => {
    const { error, data } = await createReflexCampaign({
      name,
      es_group_id: group_id,
      description: group_name,
    })
    return {
      error,
      data,
    }
  }
}
