import styled from "styled-components"

export const DashboardCardWrapper = styled.div<{
  flex?: string
  variant: "solid" | "transparent"
}>`
  ${(props) =>
    props.variant === "solid"
      ? `border-radius: 8px;
         border: 1px solid #e5e8ed;
         box-shadow: 0px 2px 8px 0px rgba(209, 212, 218, 0.3);`
      : ""}

  padding: ${(props) =>
    props.variant === "solid" ? "15px 20px" : "15px 5px 0px"};

  ${(props) => (props.flex ? `flex: ${props.flex};` : "")}

  ${(props) => (props.variant === "solid" ? "background-color: #fff;" : "")}
`

export const Header = styled.h5`
  font-weight: bold;
  font-size: 24px;
  color: #34383c;
  margin: 0;
`

export const IconWrapper = styled.div`
  svg {
    // for some reason max-width is set to 100% in the CSS reset, which causes bizarre
    // shrinking behaviour, so we manually remove it here
    max-width: unset;
  }
`

export const SubHeader = styled.div`
  font-size: 14px;
  color: #34383c;
`
