import { useRef, useState, useEffect } from "react"
import useRequest from "@ahooksjs/use-request"

import {
  getEventsLogAction,
  getFiltersParam,
  setAuditLogFormat,
  setActionLogFormat,
} from "./utils"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { humanize } from "@src/utils/string"
import {
  ACTION_LOGS_ALL_POLICIES,
  AUDIT_LOGS_ALL_POLICIES,
} from "@src/constants"

const DATE_KEYS = ["date", "execution_datetime"]

export const getBaseFilters = (currentFilters, search, type) => {
  const baseFilters = {
    state: [],
    status: [],
    date: [],
    filterBy: currentFilters?.filterBy
      ? currentFilters?.filterBy
      : search
        ? [search]
        : [],
  }

  if (type === AUDIT_LOGS_ALL_POLICIES) {
    return {
      action_category: [],
      category: [],
      user_type: [],
      ...baseFilters,
    }
  }

  return {
    action: [],
    data_source: [],
    execution_datetime: [],
    rule_version: [],
    action_type: [],
    ...baseFilters,
  }
}

const getNewFilters = (type, { currentFilters, appliedFilters, search }) => {
  const sendFilters = getBaseFilters(currentFilters, search, type)
  appliedFilters?.forEach((filter) => {
    const value = filter.original || filter.value

    if (DATE_KEYS.includes(filter.key)) {
      sendFilters[filter.key] = value
    } else if (!sendFilters[filter.key].includes(filter.original)) {
      sendFilters[filter.key]?.push(value)
    }
  })

  return sendFilters
}

export const useEventLogs = ({
  order_by,
  defaultFilters,
  policy_id,
  type,
  hideFilters,
  search,
}) => {
  const { entity, profileId } = useParams()
  const individualProfile = useSelector((state) =>
    state.get("individualProfileReducer"),
  )
  const keyFactor = individualProfile?.selectedKeyFactor
  const timeout = useRef()

  const [orderBy, setOrderBy] = useState(order_by || "date desc")
  const [searchFilter, setSearchFilter] = useState("")
  const [appliedFilters, _setAppliedFilters] = useState(defaultFilters ?? [])
  const [filters, setFilters] = useState(
    hideFilters
      ? {}
      : getNewFilters(type, {
          currentFilters: {},
          appliedFilters: defaultFilters,
          search,
        }),
  )
  const setAppliedFilters = (arg) => {
    // Yes this is jank, sorry. Hacks to replace misuse of useEffect without
    // refactoring the entire hook and all the components using it
    _setAppliedFilters((prev) => {
      const newAppliedFilters = typeof arg === "function" ? arg(prev) : arg
      if (!hideFilters) {
        const sendFilters = getNewFilters(type, {
          currentFilters: filters,
          appliedFilters: newAppliedFilters,
          search,
        })
        setFilters(sendFilters)
      }
      return newAppliedFilters
    })
  }
  const [resetRowSelection, setResetRowSelection] = useState(false)
  const [copyToggle, setCopyToggle] = useState(false)
  const [additionalFilter, setAdditionalFilter] = useState("")
  const [AdditionalLog, setAdditionalLog] = useState(false)
  const [additionalLogData, setAdditionalLogData] = useState({})
  const [showAnimation, setShowAnimation] = useState(AdditionalLog)

  const phishingFilter = {
    actual_phishing: "real_world_phishing",
    simulated_phishing: "phishing",
  }

  const animationHandler = (isTrue) => {
    setShowAnimation(isTrue)
    if (!isTrue) {
      setTimeout(() => setAdditionalLog(isTrue), 150)
      return
    }
    setAdditionalLog(isTrue)
  }

  const firstArgumentTypeMap = {
    actionEventsLog: keyFactor?.replaceAll("-", "_"),
    insightEventsLog: entity,
    individualEventsLog: profileId,
    audit_log: policy_id,
    action_log: policy_id,
  }

  const formatResult = (response) => {
    if (type === "audit_log" || type === AUDIT_LOGS_ALL_POLICIES) {
      return setAuditLogFormat(response)
    }

    if (type === "action_log" || type === ACTION_LOGS_ALL_POLICIES) {
      return setActionLogFormat(response)
    }

    return {
      total: response?.data?.total_elements,
      list: response?.data?.results,
      metadata: response?.data?.metadata,
    }
  }

  const { loading, data, pagination } = useRequest(
    ({ current, pageSize, ...params }) => {
      const prefix = type === AUDIT_LOGS_ALL_POLICIES ? "extra." : ""
      const args = [
        {
          filters: getFiltersParam(filters, prefix),
          order_by: orderBy || "date",
          page_number: current,
          page_size: pageSize,
          ...params,
        },
      ]

      if (firstArgumentTypeMap[type]) {
        args.unshift(firstArgumentTypeMap[type])
      }

      return getEventsLogAction(type)(...args)
    },
    {
      refreshDeps: [filters, orderBy],
      paginated: true,
      defaultPageSize: type === "action_log" ? 10 : 25,
      formatResult,
    },
  )

  const handleSearch = (value) => {
    if (hideFilters) return
    setAdditionalLog(false)
    setSearchFilter(value)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      value.length > 1
        ? setFilters({
            ...filters,
            filterBy: [value],
          })
        : setFilters({
            ...filters,
            filterBy: [],
          })
    }, 1000)
  }

  const singleValueFilters = ["filterBy"]

  const setDateFilter = (selection, remove = false) => {
    if ((selection.value[0] || selection.value[1]) && !remove) {
      setAppliedFilters((oldFilter) => [
        ...oldFilter.filter((fl) => fl.key !== selection.key),
        {
          key: selection.key,
          value: selection.value,
          original: selection.value,
          humanizedName: humanize(selection.key),
        },
      ])
    } else {
      setAppliedFilters((oldFilter) =>
        oldFilter.filter((filter) => !DATE_KEYS.includes(filter.key)),
      )
    }
  }

  // Need to find a different solution for this, can cause very weird bugs with
  // looping renders if the defaultFilters value isn't memoized
  useEffect(() => {
    setAppliedFilters(defaultFilters)
  }, [defaultFilters])

  const setEventFilter = (selection, remove = true) => {
    setAdditionalLog(false)
    if (DATE_KEYS.includes(selection.key)) {
      setDateFilter(selection)
      return
    }
    const newValue = selection.value.toLowerCase().replaceAll(" ", "_")

    const existingIndex = appliedFilters.findIndex(
      (filter) => filter.value === newValue && filter.key === selection.key,
    )

    const existingSingleValueFilter = appliedFilters.findIndex(
      (filter) => filter.key === selection.key,
    )

    if (
      singleValueFilters.includes(selection.key) &&
      existingSingleValueFilter !== -1 &&
      existingIndex === -1
    ) {
      setAppliedFilters((oldFilter) => {
        const filter = [...oldFilter]
        filter.splice(existingSingleValueFilter, 1, {
          key: selection.key,
          value: newValue,
          original: selection.original || selection.value,
          humanizedName: humanize(selection.key),
        })
        return filter
      })
    } else if (existingIndex === -1) {
      setAppliedFilters((oldFilter) => [
        ...oldFilter,
        {
          key: selection.key,
          value: newValue,
          original: selection.original || selection.value,
          humanizedName: humanize(selection.key),
        },
      ])
    } else {
      if (!remove) return
      setAppliedFilters((oldFilter) => {
        const filter = [...oldFilter]
        filter.splice(existingIndex, 1)
        return filter
      })
    }
  }

  /*
    This is a map between EventsLog component type vs export action type
   */
  const exportActionTypeByTypeMap = {
    actionEventsLog: keyFactor?.replaceAll("-", "_"),
    insightEventsLog: entity,
    individualEventsLog: profileId,
  }

  const onFilterSelection = (selection) => setEventFilter(selection)

  const onFilterRemoval = (selection) => {
    selection.key === "date"
      ? setDateFilter(selection, true)
      : setEventFilter(selection)
  }

  const handleSortedChange = (property, direction) =>
    setOrderBy(`${property} ${direction}`)

  const clearFilter = () => {
    setAppliedFilters([])
    setAdditionalLog(false)
  }

  const clearSearch = () => {
    setAdditionalLog(false)
    setSearchFilter("")
    setFilters({
      ...filters,
      filterBy: [],
    })
  }

  return {
    loading,
    data,
    orderBy,
    setOrderBy,
    pagination,
    searchFilter,
    handleSearch,
    filters,
    appliedFilters,
    setAppliedFilters,
    setEventFilter,
    exportActionTypeByTypeMap,
    onFilterSelection,
    onFilterRemoval,
    clearFilter,
    clearSearch,
    handleSortedChange,
    resetRowSelection,
    setResetRowSelection,
    copyToggle,
    setCopyToggle,
    additionalFilter,
    setAdditionalFilter,
    AdditionalLog,
    setAdditionalLog,
    additionalLogData,
    setAdditionalLogData,
    animationHandler,
    phishingFilter,
    showAnimation,
    setShowAnimation,
  }
}
