import React from "react"
import { useRequest } from "ahooks"

import { Separator, FilterTitle } from "@src/components/SimpleFilters/styles"
import MultiSelectFilter from "../IndividualProfilePage/components/MoreFilter/MultiSelectFilter"
import { getPoliciesActionLogsFilters } from "@src/services/apis/policies"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { humanize } from "@src/utils/string"
import DateRange from "../IndividualProfilePage/components/MoreFilter/DateRange"

const FILTER_NAME_MAPPING = {
  status: "action_status",
  state: "rule_state",
}

// TODO: Will remove once we fully migrated to new filters
const CURRENT_FILTERS = ["rule_version", "state", "status", "action_type"]

export const ActionLogFilters = ({ onChange, appliedFilters }) => {
  const { data, loading, error } = useRequest(getPoliciesActionLogsFilters, {
    formatResult: (response) => {
      return response?.data
    },
  })

  const filters = Object.keys(data || {})

  if (loading) {
    return <ActivityIndicator active={true} />
  }

  if (error) {
    return "An error occurred. Please try again."
  }

  return (
    <>
      {filters
        .filter((filter) => CURRENT_FILTERS.includes(filter))
        .map((filterName) => (
          <>
            <MultiSelectFilter
              showAllFilters
              section={{
                key: filterName,
                title: (
                  <FilterTitle>
                    {humanize(FILTER_NAME_MAPPING[filterName] || filterName)}
                  </FilterTitle>
                ),
                items:
                  data[filterName] && Array.isArray(data[filterName])
                    ? data[filterName]
                        .filter((filter) => filter)
                        .map((filter) => ({
                          label: humanize(filter.toString()),
                          value: filter.toString(),
                        }))
                    : [],
              }}
              filters={appliedFilters}
              handleChange={onChange}
            />
            <Separator />
          </>
        ))}
      <DateRange
        section={{
          title: <FilterTitle>Execution Date</FilterTitle>,
          type: "date",
          key: "execution_datetime",
        }}
        value={
          appliedFilters?.find((filter) => filter.key === "execution_datetime")
            ?.value
        }
        onChange={onChange}
      />
    </>
  )
}
