import styled from "styled-components"

export const EditContent = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #565d66;
  margin-bottom: 4px;
  line-height: 24px;
`

export const ItalicText = styled.span`
  font-style: italic;
`
