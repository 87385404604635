import React from "react"
import PropTypes from "prop-types"

const Breadcrumb = ({ fill, className, size }) => {
  return (
    <svg
      width="20"
      height={size === "large" ? "30" : "15"}
      viewBox={size === "large" ? "0 0 20 20" : "0 -3 20 25"}
      className={className}
    >
      <path
        fill={fill}
        d="M15.5839749,7.37596228 C15.9286214,7.14619793 16.3942734,7.23932834 16.6240377,7.58397485 C16.8538021,7.92862137 16.7606717,8.39427338 16.4160251,8.62403772 L10,12.9013878 L3.58397485,8.62403772 C3.23932834,8.39427338 3.14619793,7.92862137 3.37596228,7.58397485 C3.60572662,7.23932834 4.07137863,7.14619793 4.41602515,7.37596228 L10,11.0986122 L15.5839749,7.37596228 Z"
        transform="rotate(-90 10 10.076)"
      />
    </svg>
  )
}

export default Breadcrumb

Breadcrumb.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

Breadcrumb.defaultProps = {
  fill: "#383B3D",
}
