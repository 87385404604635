import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getGlobalConfigKey } from "@src/globalConfig"

// Doesn't work in all cases with Cloud Gateway and Cloud Integrated, need
// investigation. It's possible they use custom scrollbars, or we have some
// specific styling in ui-elevateplatform that causes this to work as expected.
export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    getScrollToElement()?.scrollTo(0, 0)
  }, [pathname])

  return null
}

function getScrollToElement(): {
  scrollTo: (x: number, y: number) => void
} | null {
  switch (getGlobalConfigKey()) {
    case "elevate":
      return document.getElementById("vision-dashboard")
    case "mimecast_cg":
      return window
    case "mimecast_ci":
      return document.getElementById("main-content")
    default:
      return null
  }
}
