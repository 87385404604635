import styled from "styled-components"

export const NoActionItemsWrapper = styled.div`
  width: 100%;
  background: #f5f6f8;
  border-radius: 5px;
  padding: 45px;
  text-align: center;
`

export const NoActionItemsMessageWrapper = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    margin-bottom: 0.3rem;
    color: #7b828a;
  }
`

export const ActionItemsWrapper = styled.div`
  ${({ isRiskInsights }) =>
    isRiskInsights &&
    `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
  margin-top: 15px;
`

export const ActionItemCardWrapper = styled.div`
  background: white;
  border: 1px solid #c9cfd6;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isRiskInsights }) =>
    isRiskInsights &&
    `
  width: 48%;
  `}

  > span {
    flex: 1;
    color: #565d66;
  }
  > button {
    margin-left: 24px;
  }
`
