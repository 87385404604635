import { useEffect, useRef, useState, useMemo } from "react"
import useRequest from "@ahooksjs/use-request"
import { hideAllToasts } from "@src/services/redux/toasts/actions"
import { useDispatch, useSelector } from "react-redux"
import { addToastAction } from "@src/services/redux/toasts/actionsCreator"
import {
  clearErrorValidationsInfo,
  createPolicyRedux,
  updateCreatedPolicyRedux,
} from "@src/services/redux/policies/actions"
import { generateUUID } from "@src/utils/string"
import {
  mainRiskRulePut,
  ruleInfoValidation,
  setRuleInfoInitialData,
  riskPolicyPost,
} from "./components/RuleInfoPage/utils"
import {
  setDefineOutcomeInitialData,
  setIndividualsMails,
} from "./components/RiskRuleOutcome/utils"
import { toastMsg } from "./components/utils"
import {
  setIsRuleNameUpdated,
  setQueryRule,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import { validateCriteria } from "./components/ConfigureInputCriteriaPage/utils"
import { darklyGetFlag } from "@src/utils/darkly"
import { POLICY_STATE_MAP } from "./constants"
import {
  convertServerActionsToUserFacingActions,
  convertUserFacingActionsToServerActions,
} from "./components/EditR3Actions/actionConfigs"

export const useRiskResponseRuleState = ({
  editFlag,
  selectedPolicy,
  setIsRecommendation,
  onFinished,
}) => {
  const showUseMailTrapToggle = darklyGetFlag("see-use-mailtrap-toggle-on-rrr")

  const {
    ruleName,
    ruleDescription,
    queryRules,
    useMailtrap,
    isRuleNameUpdated,
  } = useSelector((getReducers) => getReducers.get("ruleInfoReducer"))

  const [isSendingEmails] = useState(false)
  const [policyId, setPolicyId] = useState()

  const policyStateRef = useRef(selectedPolicy?.state ?? POLICY_STATE_MAP.DRAFT)
  const [saveAsDraftFlag, setSaveAsDraftFlag] = useState(false)
  const [selectedRule, _setSelectedRule] = useState({})
  const setSelectedRule = (definition) => {
    definition.actions = convertServerActionsToUserFacingActions(
      definition.actions ?? [],
    )
    _setSelectedRule(definition)
  }
  const [pageNum, setPageNum] = useState(0)

  const isBasicInfoStep = pageNum === 0
  const isInputCriteriaStep = pageNum === 1
  const isActionsStep = pageNum === 2
  const isSummaryStep = pageNum === 3

  const [isValidatingRuleName, setIsValidatingRuleName] = useState(false)

  const toast = {
    id: generateUUID(),
    level: "error",
    message: `Risk response rule ${ruleName} could not be created`,
    containerId: "UpdateRiskGroupModal",
  }

  const editedPolicyId = editFlag ? selectedPolicy.id : policyId

  const dispatch = useDispatch()

  const [isEditingAction, setIsEditingAction] = useState(false)

  const actionOperations = useMemo(
    () => ({
      addAction: (action, indexCallback = () => {}) => {
        _setSelectedRule((prev) => {
          const newRule = {
            ...prev,
            actions: [...(prev.actions ?? []), action],
          }
          indexCallback(newRule.actions.length - 1)
          return newRule
        })
      },
      removeAction: (index) => {
        _setSelectedRule((prev) => ({
          ...prev,
          actions: prev.actions.filter((_, i) => i !== index),
        }))
      },
      updateAction: (index, newPayload) => {
        _setSelectedRule((prev) => ({
          ...prev,
          actions: [
            ...prev.actions.slice(0, index),
            newPayload,
            ...prev.actions.slice(index + 1),
          ],
        }))
      },
      setActionEditState: (isEditing) => {
        setIsEditingAction(isEditing)
      },
    }),
    [],
  )

  const getRuleInfo = async () => {
    const definition = selectedPolicy?.rule?.definition ?? {}
    // if R3 has malformed criteria, only then criteria will reset
    if (!validateCriteria(definition?.criteria)) {
      definition.criteria = queryRules
    }
    setSelectedRule(definition)
    dispatch(setQueryRule(definition.criteria))
  }

  useEffect(() => {
    setRuleInfoInitialData({
      editFlag,
      selectedPolicy,
      dispatch,
    })
    setIndividualsMails([], dispatch)
    setDefineOutcomeInitialData(dispatch)
    dispatch(setIsRuleNameUpdated(false))
  }, [])

  useEffect(() => {
    if (editFlag) {
      getRuleInfo()
    }
  }, [editFlag])

  const showToastMessage = () => {
    if (!editFlag && isActionsStep && saveAsDraftFlag) {
      toastMsg({ msgType: "created", ruleName, onClose: onFinished, dispatch })
    }
    if (editFlag && isActionsStep && saveAsDraftFlag) {
      toastMsg({ msgType: "modified", ruleName, onClose: onFinished, dispatch })
    }
    if (isActionsStep && saveAsDraftFlag) {
      toastMsg({ msgType: "modified", ruleName, onClose: onFinished, dispatch })
    }
    if (editFlag && isSummaryStep) {
      toastMsg({ msgType: "modified", ruleName, onClose: onFinished, dispatch })
    }
    if (!editFlag && isSummaryStep) {
      toastMsg({ msgType: "new", onClose: onFinished, dispatch })
    }
  }

  const getFinalActionsList = () => {
    return convertUserFacingActionsToServerActions(selectedRule?.actions ?? [])
  }

  const { loading: isUpdatingPolicy, run: updatePolicy } = useRequest(
    mainRiskRulePut(editedPolicyId, {
      name: ruleName,
      description: ruleDescription,
      state: policyStateRef.current,
      rule: {
        definition: {
          criteria: queryRules,
          actions: getFinalActionsList(),
        },
      },
      ...(showUseMailTrapToggle && { use_mailtrap: useMailtrap }),
    }),
    {
      manual: true,
      onSuccess: (response) => {
        if (response?.error) {
          const toastCopy = {
            ...toast,
            message: response?.error?.response?.data?.detail,
          }
          dispatch(addToastAction(toastCopy))
        } else {
          dispatch(updateCreatedPolicyRedux(editedPolicyId, response.data))
          dispatch(clearErrorValidationsInfo())
          const definition = response?.data?.rule?.definition ?? {}
          setSelectedRule(definition)
          if (saveAsDraftFlag) {
            onFinished()
          } else {
            showToastMessage()
          }
        }
      },
    },
  )

  const { loading: isCreatingPolicy, run: createPolicy } = useRequest(
    riskPolicyPost({
      name: ruleName,
      description: ruleDescription,
      state: policyStateRef.current,
      rule: {
        definition: {
          criteria: queryRules,
          actions: getFinalActionsList(),
        },
      },
      ...(showUseMailTrapToggle && { use_mailtrap: useMailtrap }),
    }),
    {
      manual: true,
      onSuccess: (response) => {
        if (response.data) {
          dispatch(createPolicyRedux(response.data))
          setPolicyId(response.data.id)
        }
        if (saveAsDraftFlag) {
          onFinished()
        } else {
          showToastMessage()
        }
      },
      onError: () => {
        dispatch(addToastAction(toast))
      },
    },
  )

  const saveAndActivate = () => {
    policyStateRef.current = POLICY_STATE_MAP.ACTIVE
    const params = { state: POLICY_STATE_MAP.ACTIVE }
    if (editFlag) {
      updatePolicy(params)
    } else {
      createPolicy(params)
    }
  }

  const saveAndActivateInTest = () => {
    policyStateRef.current = POLICY_STATE_MAP.TEST
    const params = { state: POLICY_STATE_MAP.TEST }
    if (editFlag) {
      updatePolicy(params)
    } else {
      createPolicy(params)
    }
  }

  // save as draft button
  const handleClickSaveAsDraft = () => {
    setIsRecommendation(false) // This is only usable when a recommendation is being activated
    policyStateRef.current = POLICY_STATE_MAP.DRAFT
    setSaveAsDraftFlag(true)

    if (isBasicInfoStep) {
      return editFlag
        ? updatePolicy({ state: POLICY_STATE_MAP.DRAFT })
        : handleSubmitOnBasicInfo()
    }

    if (isInputCriteriaStep) {
      if (editFlag) {
        return updatePolicy({ state: POLICY_STATE_MAP.DRAFT })
      }
      if (!handleSubmitOnCriteria()) {
        return false
      }
      return createPolicy({ state: POLICY_STATE_MAP.DRAFT })
    }
    if (isActionsStep || isSummaryStep) {
      return editFlag
        ? updatePolicy({ state: POLICY_STATE_MAP.DRAFT })
        : createPolicy({ state: POLICY_STATE_MAP.DRAFT })
    }
  }

  const handleSubmitOnBasicInfo = async () => {
    if (isRuleNameUpdated) {
      setIsValidatingRuleName(true)
      const isInvalid = await ruleInfoValidation({
        editFlag,
        selectedPolicy,
        ruleName,
        dispatch,
      })
      setIsValidatingRuleName(false)
      if (!isInvalid) {
        dispatch(setIsRuleNameUpdated(false))
        dispatch(clearErrorValidationsInfo())
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const handleSubmitOnCriteria = () => {
    const res = validateCriteria(queryRules)
    if (!res) {
      const toastCopy = {
        ...toast,
        message: "All fields of a condition or criteria must be filled",
      }
      dispatch(addToastAction(toastCopy))
      return false
    }

    hideAllToasts()(dispatch)
    setSelectedRule({
      ...selectedRule,
      criteria: queryRules,
    })
    return true
  }

  const handleSubmitOnOutcome = () => {
    return true
  }

  // This flag will be used to enable/disable "Activate" button on summary page
  let isPolicyValid = false
  if (isSummaryStep) {
    isPolicyValid = validateCriteria(queryRules) && selectedRule.actions?.length
  }

  const isLoading =
    isSendingEmails ||
    isUpdatingPolicy ||
    isValidatingRuleName ||
    isCreatingPolicy

  return {
    pageNum,
    isBasicInfoStep,
    isInputCriteriaStep,
    isActionsStep,
    isSummaryStep,
    setPageNum,
    handleClickSaveAsDraft,
    ruleName,
    handleSubmitOnCriteria,
    handleSubmitOnOutcome,
    saveAndActivateInTest,
    saveAndActivate,
    selectedRule,
    isLoading,
    handleSubmitOnBasicInfo,
    isPolicyValid,
    actionOperations,
    isEditingAction,
    actionsSummaryList: getFinalActionsList(),
  }
}
