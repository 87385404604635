import fetcher from "@src/services/fetcher"
import { BASE_EP_API_URL } from "@src/constants"
import { getErrorStatus } from "@src/services/fetcher/utils"

const API_URL = BASE_EP_API_URL()

export async function getMicrosoftTeamsChannels({ search }) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/intsync/microsoft-teams/channels/?search=${search}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getMicrosoftTeamsMembers({ search }) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/intsync/microsoft-teams/members/?search=${search}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getAzureADConditionalAccessPolicies(params) {
  try {
    const { status, data } = await fetcher.get(
      `${API_URL}/intsync/azuread/conditional-access-policies/`,
      { params },
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
