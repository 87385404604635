import styled from "styled-components"

// Ensure z-index is higher than that of DropdownTitleWrapper
export const BreakdownSelectorTitleWrapper = styled.div`
  display: inline-flex;
  z-index: 1;

  h2 {
    margin-bottom: 0.5rem;
  }
`

export const BreakdownLevelButton = styled.div`
  display: inline-flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  padding-bottom: 0.5rem;
  color: ${(props) => (props.toggled ? "#34383c" : "#7b828a")};
  border-bottom: ${(props) =>
    props.toggled ? "3px solid #0c7d84" : "3px solid #c9cfd6"};

  :active,
  :hover {
    border-bottom: 3px solid #0c7d84;
    color: #34383c;
  }
`
