import React from "react"
import { ButtonIcon } from "@elevate_security/elevate-component-library"
import PropTypes from "prop-types"
import dompurify from "dompurify"
import { Container, ToastWrapper, ButtonContainer, toastTheme } from "./styles"
import * as toastActions from "../../services/redux/toasts/actions"
import useActions from "@src/services/redux/useActions"

const Toasts = ({ toasts, fixed = true, width, onRemove }) => {
  const { hideToast } = useActions(toastActions)
  const sanitizer = dompurify.sanitize

  if (!toasts || !toasts.length) {
    return null
  }

  return (
    <Container
      fixed={fixed}
      width={width}
      count={toasts.length}
      data-id="hm-toast-container"
    >
      {toasts.map((config) => {
        const { id, level, message, children } = config
        return (
          <ToastWrapper
            key={id}
            fixed={fixed}
            onClick={() => hideToast(id)}
            level={level}
            data-id={`hm-toast-${id}`}
          >
            {message && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(message),
                }}
              />
            )}
            {children && <div>{children}</div>}
            <ButtonContainer stroke={toastTheme.ToastBorder[level]}>
              {" "}
              <ButtonIcon
                icon="Close"
                size="sm"
                theme="ghost"
                onClick={() => onRemove(config)}
              />
            </ButtonContainer>
          </ToastWrapper>
        )
      })}
    </Container>
  )
}

Toasts.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.node.isRequired,
      children: PropTypes.element,
      level: PropTypes.oneOf(["success", "warn", "info", "error"]),
    }),
  ),
  width: PropTypes.string,
  fixed: PropTypes.bool,
  onRemove: PropTypes.func,
}

Toasts.defaultProps = {
  toasts: [],
  fixed: true,
  width: "660px",
  onRemove: () => null,
}

export default Toasts
