import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"

import { Table } from "@elevate_security/elevate-component-library"
import {
  BreakdownTableWrapper,
  BreakdownTableHeaderWrapper,
  BreakdownTableGridWrapper,
} from "./styles"
import ActivityIndicator from "@src/components/ActivityIndicator"
import {
  getOrdering,
  getParserForBreakdownLevel,
  getTableTitle,
  getTableColumns,
  getScoresSetter,
  getScoresGetter,
} from "./utils"
import ExportData from "@src/components/ExportData"

const { H3 } = Typography

function BreakdownTable(props) {
  const { breakdownLevel, totalCount, setTotal } = props
  const dispatch = useDispatch()
  const setScoresFunction = getScoresSetter(breakdownLevel, dispatch)
  const getScoresFunction = getScoresGetter(breakdownLevel)
  const { data, isLoaded } = useSelector(getScoresFunction)

  useEffect(() => {
    if (isLoaded && data !== null) return
    setScoresFunction({
      limit: 10,
      offset: 0,
    })
  }, [breakdownLevel])

  useEffect(() => {
    setTotal((data && data.count) || totalCount)
  }, [data])

  // 2 cases supported: pagination and sorting
  const handleOnChangeTable = (pagination, sort) => {
    if (pagination && sort) {
      // Unpack pagination and sort attributes
      const { limit, offset } = pagination
      const { direction, property } = sort

      const sortingUnsupportedAttributes = [
        "individual_score",
        "department_score",
        "location_score",
        "department_name",
        "location_name",
        "members_count",
        "location_members_count",
      ]

      // Disallow sorting for specific unsupported attributes
      if (sortingUnsupportedAttributes.includes(property)) return

      // Get the ordering
      const ordering = getOrdering(direction, property)
      setScoresFunction({ limit, offset, ordering })
    } else {
      setScoresFunction(pagination)
    }
  }

  const tableProps = {
    data:
      (data && getParserForBreakdownLevel(breakdownLevel)(data.results)) || [],
    columns: getTableColumns(breakdownLevel),
    totalCount: (data && data.count) || totalCount,
    onChangeTable: ({ pagination, sort }) =>
      handleOnChangeTable(pagination, sort),
    defaultPageSize: 10,
    pageSizeOptions: [10, 25, 50],
  }

  return (
    <BreakdownTableWrapper>
      <BreakdownTableHeaderWrapper>
        <H3 fontWeight="bold" color="900">
          {getTableTitle(breakdownLevel)}
        </H3>
        <ExportData
          total={(data && data.count) || totalCount}
          type={breakdownLevel.toLowerCase()}
          actions={breakdownLevel.toLowerCase()}
          riskAnalysisFlag={false}
          showLimitationModal={false}
        />
      </BreakdownTableHeaderWrapper>
      <BreakdownTableGridWrapper>
        <Table {...tableProps} manual />
      </BreakdownTableGridWrapper>
      <ActivityIndicator active={!isLoaded} />
    </BreakdownTableWrapper>
  )
}

BreakdownTable.propTypes = {
  breakdownLevel: PropTypes.string,
  totalCount: PropTypes.number,
}

export default BreakdownTable
