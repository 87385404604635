export type Breadcrumb = {
  label: string
  url: string
}

// If a menu item doesn't have a router link, its default behaviour is to toggle
// its children, so if there is no router link, there should be children.
export type MenuItem<T extends object = object> = {
  routerLink?: string
  label: string
  id: string
  children?: {
    options: MenuItem<T>[]
  }[]
} & T

type Subscribers = {
  menuItemClick: ((url: string | undefined, id: string) => void)[]
}

/*
 * Includes all communication between Vision and the CG shell app.
 */
export class MimecastCgShellClient {
  private subscribers: Subscribers = {
    menuItemClick: [],
  }

  updateMenuItems(menuItems: MenuItem[]) {
    // Attach an onClick to each menu item so we can notify subscribers
    // when a menu item click happens.
    const buildFinalMenuItems = (
      items: MenuItem[],
    ): MenuItem<{ onClick: () => void }>[] => {
      return items.map((menuItem) => {
        return {
          ...menuItem,
          onClick: () => {
            this.subscribers.menuItemClick.forEach((subscriber) => {
              subscriber(
                menuItem.routerLink && `/${menuItem.routerLink}`,
                menuItem.id,
              )
            })
          },
          children: menuItem.children?.map(({ options }) => ({
            options: buildFinalMenuItems(options),
          })),
        }
      })
    }

    const customMenuItemEvent = new CustomEvent("visionMenuItemsUpdate", {
      detail: buildFinalMenuItems(menuItems),
    })
    window.dispatchEvent(customMenuItemEvent)
  }

  on<K extends keyof Subscribers>(eventName: K, subscriber: Subscribers[K][0]) {
    if (!this.subscribers[eventName]) {
      throw new Error(`Invalid event name ${eventName}`)
    }
    this.subscribers[eventName].push(subscriber)
  }

  off<K extends keyof Subscribers>(
    eventName: K,
    subscriberToRemove: Subscribers[K][0],
  ) {
    if (!this.subscribers[eventName]) {
      throw new Error(`Invalid event name ${eventName}`)
    }
    this.subscribers[eventName] = this.subscribers[eventName].filter(
      (subscriber) => {
        return subscriber !== subscriberToRemove
      },
    )
  }

  clear() {
    this.subscribers = Object.keys(this.subscribers).reduce(
      (subscribers, eventName) => ({
        ...subscribers,
        [eventName]: [],
      }),
      {} as Subscribers,
    )
  }

  updateBreadcrumbs(breadcrumbs: Breadcrumb[]): boolean {
    // We only show breadcrumbs if we have more than one
    const detail = breadcrumbs.length > 1 ? breadcrumbs : []
    const customBreadcrumbEvent = new CustomEvent("visionBreadcrumbsUpdate", {
      detail,
    })
    window.dispatchEvent(customBreadcrumbEvent)
    return Boolean(detail.length)
  }

  notifyRouteChange(pathname: string) {
    // Event name is specific to pre-existing name in CG, ideally it would be
    // more generic since this event can be used for more than just menu items
    const customRouteChangeEvent = new CustomEvent("highlightMenuItem", {
      detail: {
        pathname,
      },
    })
    window.dispatchEvent(customRouteChangeEvent)
  }

  notifySessionExpired() {
    // This matches how CG destroys a session for certain status codes.
    // CG has a postMessage handler that will destroy session and redirect
    // to login.
    window.postMessage({ action: "sessionExpired" }, window.location.origin)
  }
}
