export const groupBy = (list, keyGetter) => {
  var map = {}
  list.forEach((item) => {
    var key = keyGetter(item)
    var collection = map[key]
    if (collection) {
      map[key] = [...collection, item]
    } else {
      map[key] = [item]
    }
  })
  return map
}
