import React from "react"
import {
  HumanRiskBehaviorsItemDetailsGridItem,
  HumanRiskBehaviorsItemDetailsGridItemIcon,
  HumanRiskBehaviorsItemDetailsGridItemScore,
  HumanRiskBehaviorsItemDetailsGridItemText,
} from "./styles"

import PropTypes from "prop-types"

import { Icon, Tooltip } from "@elevate_security/elevate-component-library"
import { limitNumber, truncateNumber } from "@src/utils/numbers/utils"
import { humanize } from "@src/utils/string"

export const HumanRiskBehaviorsItemDetailsGridItemMap = ({
  passOnClick,
  data,
}) => {
  return (
    <HumanRiskBehaviorsItemDetailsGridItem
      key={`${data.title}-${data.value}-${data.position}`}
    >
      <HumanRiskBehaviorsItemDetailsGridItemScore>
        {data.type === "percentage"
          ? `${limitNumber(data.value, 0, 100)}%`
          : truncateNumber(data.value)}
      </HumanRiskBehaviorsItemDetailsGridItemScore>
      <HumanRiskBehaviorsItemDetailsGridItemText onClick={passOnClick}>
        {humanize(data.title)}
      </HumanRiskBehaviorsItemDetailsGridItemText>
      <HumanRiskBehaviorsItemDetailsGridItemIcon>
        <Tooltip body={data.description} placement="top" readOnly size="xsm">
          <Icon name="InfoCheckOutline" size="xsm" />
        </Tooltip>
      </HumanRiskBehaviorsItemDetailsGridItemIcon>
    </HumanRiskBehaviorsItemDetailsGridItem>
  )
}
HumanRiskBehaviorsItemDetailsGridItemMap.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  passOnClick: PropTypes.func.isRequired,
}
