import React from "react"

import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import HistoricalTrend from "@src/components/HistoricalTrend"
import { getDiffMessage } from "@src/scenes/Dashboard/components/ThirdQuestion/utils"
import { Section } from "./styles"
import { useSelector } from "react-redux"
import { getTeamScoresMonthly } from "../../../../services/redux/selectors"

const { Text } = Typography

const TeamHistoricalTrend = () => {
  const { data } = useSelector(getTeamScoresMonthly)

  return (
    <>
      <Section>
        {getDiffMessage(data)}
        <br />
        {Object.keys(data).length === 0 ? (
          <Text style={{ fontSize: 16, display: "block", textAlign: "center" }}>
            No historical scores found.
          </Text>
        ) : (
          <HistoricalTrend behaviorScores={data} />
        )}
      </Section>
    </>
  )
}

export default TeamHistoricalTrend
