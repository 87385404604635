import { IS_DEMO_APP, OFFERING_PLANS } from "@src/constants"
import { getLastScoresUpdateFromReputations } from "@src/services/apis/company"

const isDemoApp = IS_DEMO_APP()

export function getCompanyInfo() {
  // This localStorage item gets set by ui-elevateplatform. This isn't a greate
  // way to share information between microfrontends, we should use single-spa's
  // mechanism or even just request the data here directly
  const info = JSON.parse(window.localStorage.getItem("company"))
  if (!info) return {}
  const { shortname } = info
  return {
    ...info,
    shortname: !isDemoApp ? shortname : "lighthouse",
  }
}

export function getCompanyServices() {
  const info = getCompanyInfo()
  const { services = [] } = info
  const servicesObject = {}

  services.forEach((service) => {
    const result = {
      entitlement: service.entitlement,
      visibility: service.visibility,
      name: service.service.name,
      description: service.service.description,
      licenses: service.licenses,
      usedLicenses: service.used_licenses,
      active: service.service.active,
    }

    servicesObject[result.name] = result
  })
  return servicesObject
}

export async function getLastScoresUpdate() {
  const { error, data } = await getLastScoresUpdateFromReputations()
  if (error) return null
  return data?.scoring_datetime
}

export const getOfferingPlan = () => {
  const { plan } = getCompanyInfo()
  return plan
}

export const getManagerDashboardPlan = () => {
  const { plan } = getCompanyInfo()
  const hideMDIcon = [OFFERING_PLANS.ASSESS, OFFERING_PLANS.MONITOR]
  return !hideMDIcon.includes(plan)
}
