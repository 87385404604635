import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Icon, theme } from "@elevate_security/elevate-component-library"
import { POLICY_STATE_MAP } from "@src/scenes/RiskResponseRuleCreateScreen/constants"

import {
  ActionsDropdownWrapper,
  ActionsDropdownMenuToggleButton,
  ActionsDropdownMenu,
  ActionsDropdownMenuItem,
} from "./styles"

const actions = [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Activate",
    value: POLICY_STATE_MAP.ACTIVE,
  },
  {
    label: "Draft",
    value: POLICY_STATE_MAP.DRAFT,
  },
  {
    label: "Test mode",
    value: POLICY_STATE_MAP.TEST,
  },
  {
    label: "Pause",
    value: POLICY_STATE_MAP.PAUSED,
  },
  {
    label: "Duplicate",
    value: "duplicate",
  },
  {
    label: "Delete",
    value: POLICY_STATE_MAP.DELETED,
  },
]

const ActionsDropdown = ({
  item,
  onDelete,
  onClone,
  onStateChange,
  onEdit,
}) => {
  const dropdownRef = useRef(null)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsMenuOpen(!isMenuOpen)
  }

  const handleClickOnActionItem = (action) => {
    setIsMenuOpen(false) // close menu
    switch (action) {
      case "edit":
        onEdit(item)
        return
      case POLICY_STATE_MAP.ACTIVE:
        onStateChange(POLICY_STATE_MAP.ACTIVE)
        return
      case POLICY_STATE_MAP.DRAFT:
        onStateChange(POLICY_STATE_MAP.DRAFT)
        return
      case POLICY_STATE_MAP.TEST:
        onStateChange(POLICY_STATE_MAP.TEST)
        return
      case POLICY_STATE_MAP.PAUSED:
        onStateChange(POLICY_STATE_MAP.PAUSED)
        return
      case "duplicate":
        onClone()
        return
      case POLICY_STATE_MAP.DELETED:
        onDelete(item)
        return
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isMenuOpen])

  return (
    <ActionsDropdownWrapper ref={dropdownRef}>
      <ActionsDropdownMenuToggleButton onClick={toggleMenu}>
        <Icon
          name="MoreHorizontal"
          size="default"
          stroke={theme?.colors?.gray?.opaque["600"]}
        />
      </ActionsDropdownMenuToggleButton>
      {isMenuOpen && (
        <ActionsDropdownMenu>
          {actions?.map((action) => {
            return (
              <ActionsDropdownMenuItem
                key={action.value}
                deactivated={action.value === item.state}
                disabled={action.value === item.state}
                onClick={(e) => {
                  e.stopPropagation()
                  handleClickOnActionItem(action.value)
                }}
              >
                {action.label}
              </ActionsDropdownMenuItem>
            )
          })}
        </ActionsDropdownMenu>
      )}
    </ActionsDropdownWrapper>
  )
}

ActionsDropdown.propTypes = {
  select: PropTypes.func.isRequired,
}

export default ActionsDropdown
