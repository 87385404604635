import { humanize } from "@src/utils/string"
import {
  ColumnNameMap,
  sortNameMap,
  UNCHECKED_COLUMNS,
} from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import { getGlobalConfig } from "@src/globalConfig"

const getColumnsFromRiskScoreExample = (risksNames) => {
  const disabledFields = ["human_risk_score", "number_of_individuals"]
  if (!risksNames) return []
  return risksNames?.map((riskName) => {
    const name = ColumnNameMap[riskName] || riskName
    return {
      key: `${sortNameMap[riskName] || riskName}`,
      label: humanize(name),
      selected: !UNCHECKED_COLUMNS.includes(riskName),
      disabled: disabledFields.includes(riskName),
    }
  })
}

export const getColumnsChooserDefinition = ({
  risksNames,
  extraRiskFields,
  firstColumn,
  columnsList,
  behaviorTypes,
  riskType,
}) => {
  const setColumnsKey = {
    attack_factor: "attack_factor",
    access_factor: "access_factor",
    overall: "human_risk_score",
    action_factor: "action_factor",
  }
  const risksColumns = getColumnsFromRiskScoreExample(
    [setColumnsKey[risksNames]] || risksNames,
  )
  const actionColumns = getColumnsFromRiskScoreExample(behaviorTypes)
  const extraColumns = getColumnsFromRiskScoreExample(extraRiskFields) || []

  const listColumns =
    getColumnsFromRiskScoreExample(
      columnsList?.map((col) => setColumnsKey[col?.key] || col.key),
    ) || []

  return [
    ...extraColumns,
    {
      key: "es_person_id",
      hidden: true,
      selected: true,
    },
    {
      key: firstColumn?.key,
      label: firstColumn?.header,
      selected: true,
      disabled: true,
    },
    ...risksColumns,
    ...(riskType === "individual" ? listColumns : []),
    ...actionColumns,
  ].filter((col) => {
    return !getGlobalConfig("RAP_FIELD_EXCLUSIONS").includes(col.key)
  })
}
