import PropTypes from "prop-types"
import React, { memo } from "react"
import {
  CustomSelect,
  ButtonLink,
} from "@elevate_security/elevate-component-library"
import {
  ViewByContainer,
  OptionContainer,
  TextContainer,
  Label,
  Strong,
} from "./styles"
import Checkbox from "@src/components/Checkbox"
import { viewByOptions, viewByKeys } from "./constans"
import { useDepartmensAndLocations } from "./useDepartamentsAndLocations"

function ViewBy({
  viewByState,
  onViewByStateChange,
  selection,
  onViewBySelectionChange,
}) {
  const departmentsAndLocations = useDepartmensAndLocations()
  const inputText = !selection.length
    ? `All ${viewByState}`
    : `${viewByState} (${selection.length})`

  const handleClearFilterClick = () => {
    onViewByStateChange(viewByKeys[0])
    onViewBySelectionChange([])
  }

  const handleChangeViewBy = (option) => {
    onViewByStateChange(option.value)
    onViewBySelectionChange([])
  }

  const handleChangeSelection = (option) => {
    const newSelection = [...selection]

    if (selection.includes(option.value)) {
      newSelection.splice(selection.indexOf(option.value), 1)
    } else {
      newSelection.push(option.value)
    }

    onViewBySelectionChange(newSelection)
  }

  const selectOption = (label, checked) => (
    <OptionContainer>
      <Checkbox checked={checked} />
      <TextContainer>
        <Label>{label}</Label>
      </TextContainer>
    </OptionContainer>
  )

  return (
    <ViewByContainer>
      <Strong>View by:</Strong>
      <CustomSelect
        options={viewByOptions}
        defaultValue={{ label: viewByState, value: viewByState }}
        onChange={handleChangeViewBy}
      />
      <div style={{ marginBottom: 0, marginTop: "auto" }}>
        <CustomSelect
          onChange={handleChangeSelection}
          maxHeightDropdown="180px"
          options={(departmentsAndLocations[viewByState] || []).map((item) => ({
            label: item,
            value: item,
            render: selectOption(item, selection.includes(item)),
          }))}
          closeOnSelection={false}
          highlightSelection={false}
          inputText={inputText}
        />
      </div>
      <ButtonLink onClick={handleClearFilterClick}>Clear filters</ButtonLink>
    </ViewByContainer>
  )
}

ViewBy.propTypes = {
  viewByState: PropTypes.string,
  onViewByStateChange: PropTypes.func,
  selection: PropTypes.arrayOf(PropTypes.string),
  onViewBySelectionChange: PropTypes.func,
}

ViewBy.defaultProps = {
  viewByState: "Departments",
  selection: [],
}

export default memo(ViewBy)
