import fetcher from "@src/services/fetcher"
import { BASES_REPUTATIONS_API_URL, BASE_EP_API_URL } from "@src/constants"
import { getErrorStatus } from "@src/services/fetcher/utils"

const EP_API_URL = BASE_EP_API_URL()

export async function getPolicies(backendParams) {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/policies/`, {
      params: {
        page_size: 20,
        ...backendParams,
      },
    })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getFilteredRuleName(backendParams) {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/policies/`, {
      params: {
        page_size: 100,
        ...backendParams,
      },
    })
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function createPolicy(params) {
  try {
    return await fetcher.post(`${BASES_REPUTATIONS_API_URL}/policies/`, params)
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function markRecommendationActive(id, params = {}) {
  try {
    return await fetcher.post(
      `${BASES_REPUTATIONS_API_URL}/policies/recommendations/activate/${id}`,
      params,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function editRule(id, params) {
  try {
    return await fetcher.put(
      `${BASES_REPUTATIONS_API_URL}/policies/${id}`,
      params,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * @param paramsSerializer {(params: any) => string | undefined}
 * @param personId {string | undefined}
 */
export async function getPoliciesActionLogs(
  params,
  personId = undefined,
  paramsSerializer = undefined,
) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/action_logs${personId ? `/${personId}` : ""}`,
      { params, paramsSerializer },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPoliciesAuditLogs(params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/audit_logs`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPoliciesActionLogsFilters() {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/action_logs/filters`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPoliciesAuditLogsFilters() {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/audit_logs/filters`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPolicyActionLogs(policyId, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/${policyId}/action_logs`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getAuditLogData(policyId, params) {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/${policyId}/audit_logs`,
      { params },
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getQueryBuilderSchema() {
  try {
    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/query-builder-schema`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getQueryStringOptions({
  category,
  field,
  search,
  page_size = 25,
  page_number = 0,
}) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/policies/query-options/${category}/${field}?search=${search}&page_size=${page_size}&page_number=${page_number}`,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getGroupsOptions({
  search,
  page_size = 25,
  field,
  page_number = 0,
}) {
  const url =
    field === "elevate_security_groups"
      ? `${EP_API_URL}/groups/?`
      : `${EP_API_URL}/intsync/groups/?vendor=${field.split("_")[0]}&`

  const nameFilter =
    field === "elevate_security_groups" ? `name__icontains` : `search`

  try {
    const { status, data } = await fetcher.get(
      `${url}${nameFilter}=${search}&limit=${page_size}&offset=${
        page_number * page_size
      }`,
    )

    // Reformat the data
    const formattedData =
      data?.results?.map((group) => ({
        label: group.name,
        value: group.id,
      })) || []
    const updatedData = {
      results: formattedData,
      page_number: page_number,
      page_size: page_size,
    }
    return { status, data: updatedData }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function cloneRule(policyId, params) {
  try {
    return await fetcher.post(
      `${BASES_REPUTATIONS_API_URL}/policies/clone/${policyId}`,
      params,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function generateEmailPreview(params) {
  try {
    return await fetcher.post(
      `${BASES_REPUTATIONS_API_URL}/policies/preview`,
      params,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getIndividualsMatched(criteria, page, params = {}) {
  try {
    const queryString =
      Object.keys(params).length == 0
        ? ""
        : `&${new URLSearchParams(params).toString()}`

    return await fetcher.get(
      `${BASES_REPUTATIONS_API_URL}/analysis/individuals/matches?criteria=${criteria}&page_number=${page}${queryString}`,
    )
  } catch (error) {
    return getErrorStatus({ error })
  }
}

// Get all available keys to filter on policies

export async function getPoliciesFilters() {
  try {
    return await fetcher.get(`${BASES_REPUTATIONS_API_URL}/policies/filters`)
  } catch (error) {
    return getErrorStatus({ error })
  }
}
