declare const APP_ENV: string

import { INLINE_FLAGS } from "./inlineFlags"
import { getGlobalConfig } from "@src/globalConfig"

type LdClient = {
  allFlags: () => typeof INLINE_FLAGS
  on: () => void
  off: () => void
}

// This should never return undefined in practice, but there may be
// race conditions involved with how ui-elevateplatform instantiates the LD
// client and how it loads the vision front-end, so leaving for now pending
// deeper investigation.
export const darkly = (): LdClient | undefined => {
  switch (getGlobalConfig("FEATURE_FLAG_PROVIDER")) {
    case "ELEVATE_PLATFORM":
      return window && (window as any).ldClient
    case "INLINE":
      return {
        allFlags: () => INLINE_FLAGS,
        on: () => {
          console.warn(
            "Feature flag subscription not enabled for INLINE provider. Ignoring.",
          )
        },
        off: () => {
          console.warn(
            "Feature flag subscription not enabled for INLINE provider. Ignoring.",
          )
        },
      }
    default:
      throw new Error(
        `Unknown feature flag provider ${getGlobalConfig("FEATURE_FLAG_PROVIDER")}`,
      )
  }
}

type FlagMapping = typeof INLINE_FLAGS

type Flag = keyof FlagMapping

export const darklyGetFlag = <T extends Flag>(
  flag: T,
): FlagMapping[T] | false => {
  const ld = darkly()

  if (
    APP_ENV === "local" &&
    !Object.prototype.hasOwnProperty.call(INLINE_FLAGS, flag)
  ) {
    // Ensure that we always add a default value when using the INLINE provider,
    // since when using LD directly it gives defaults automatically
    throw new Error(
      `Default flag value missing for ${flag} in INLINE provider, please add one`,
    )
  }

  if (!ld) {
    return false
  }

  const allFlags = ld.allFlags()

  return allFlags && allFlags[flag]
}
