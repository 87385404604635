import IconHome from "./images/Home.svg"
import IconVision from "./images/ProductVision.svg"
import IconPulse from "./images/ProductPulse.svg"
import IconReflex from "./images/ProductReflex.svg"
import IconHM from "./images/ProductHM.svg"

export function getTopLevelMenu() {
  return [
    {
      icon: IconHome,
      text: "Home",
      locked: true,
      hidden: false,
    },
    {
      icon: IconVision,
      text: "Vision",
      description: "Organizational insights",
      type: "insights",
      locked: true,
      hidden: false,
      root: "/vision2/manager-dashboard",
      address: "/vision2/manager-dashboard",
      subItems: [
        {
          text: "Manager Dashboard",
          address: "/vision2/manager-dashboard",
        },
      ],
    },
    {
      icon: IconPulse,
      text: "Pulse",
      type: "insights",
      locked: true,
      hidden: false,
    },
    {
      icon: IconReflex,
      text: "Reflex",
      description: "Phishing Simulator",
      type: "insights",
      locked: true,
      hidden: false,
    },
    {
      icon: IconHM,
      text: "Hacker's Mind",
      description: "Gamified targeted training",
      type: "influence",
      locked: true,
      hidden: false,
    },
  ]
}

export function getSecondaryNavMenu() {
  return []
}
