import React from "react"
import { Button } from "@src/components/MimecastReskin"

export const RiskResponseRuleSaveButtons = ({
  isBasicInfoStep,
  isInputCriteriaStep,
  isActionsStep,
  isSummaryStep,
  onCancel,
  onSaveDraft,
  disableSaveAsDraft,
  onSave,
  onActivateInTest,
  onActivate,
  isSaveAndContinueDisabled,
  editFlag,
  isPolicyValid,
  showCancel = true,
  showSaveAsDraft = true,
}) => {
  const buttons = []

  if (showCancel) {
    buttons.push(
      <Button key="cancel" theme="ghost" onClick={onCancel}>
        Cancel
      </Button>,
    )
  }

  if (showSaveAsDraft && (editFlag || !isBasicInfoStep)) {
    buttons.push(
      <Button
        key="saveDraft"
        theme="secondary"
        disabled={disableSaveAsDraft}
        onClick={onSaveDraft}
      >
        Save as Draft
      </Button>,
    )
  }

  if (isBasicInfoStep || isInputCriteriaStep || isActionsStep) {
    buttons.push(
      <Button
        key="saveContinue"
        disabled={isSaveAndContinueDisabled}
        onClick={onSave}
      >
        Continue
      </Button>,
    )
  }

  if (isSummaryStep) {
    buttons.push(
      <Button
        key="saveRunTest"
        theme="secondary"
        disabled={!isPolicyValid}
        onClick={onActivateInTest}
      >
        Run Rule in Test Mode
      </Button>,
    )
    buttons.push(
      <Button key="saveActivate" onClick={onActivate} disabled={!isPolicyValid}>
        Activate Rule
      </Button>,
    )
  }

  return buttons
}
