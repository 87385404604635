import React, { PropsWithChildren } from "react"

import { Typography } from "@elevate_security/elevate-component-library"
import { SpaceBetween } from "../SpaceBetween"

export interface PageLayoutProps {
  title: string
  actions?: React.ReactNode
  subtitle?: string
}

export function PageLayout(props: PropsWithChildren<PageLayoutProps>) {
  const { title, actions, children, subtitle } = props
  return (
    <SpaceBetween gap="24px" data-test-id="page-layout">
      <SpaceBetween direction="row" alignItems="center">
        {title && (
          <Typography.H1
            data-test-id="page-title"
            fontWeight="heavy"
            color="700"
            margin="0px"
          >
            {title}
          </Typography.H1>
        )}
        {actions && <div data-test-id="page-actions-container">{actions}</div>}
      </SpaceBetween>
      {subtitle && <div>{subtitle}</div>}
      <div data-test-id="page-content">{children}</div>
    </SpaceBetween>
  )
}
