import { createSparkLineChartBar } from "@src/components/SparkLine/sparkLineGraph"

const { useCallback } = require("react")

export const useDrawSparkLineGraph = (
  chartRef,
  typeGraph,
  behaviorScores,
  hideActionsTaken,
  setIsReady,
  minMaxValue,
) => {
  return useCallback(() => {
    if (!chartRef || !chartRef.current) return
    chartRef.current.innerHTML = ""
    createSparkLineChartBar({
      element: chartRef.current,
      behaviorScores,
      typeGraph,
      hideActionsTaken,
      minMaxValue,
    })

    setIsReady(true)
  }, [typeGraph, behaviorScores, hideActionsTaken])
}
