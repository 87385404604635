export const INDIVIDUALS_MAILS = "INDIVIDUALS_MAILS"
export const RECEPTION_GROUPS_DATA = "RECEPTION_GROUPS_DATA"
export const SEND_VIA_SPLUNK = "SEND_VIA_SPLUNK"
export const SEND_VIA_WEB_HOOK = "SEND_VIA_WEB_HOOK"
export const SERVICE_WEB_HOOK = "SERVICE_WEB_HOOK"
export const RULE_ACTIONS = "RULE_ACTIONS"
export const SERVICES_TOGGLES_DATA = "SERVICES_TOGGLES_DATA"
export const SET_OUTBOUND_TEMPLATE_ATTRIBUTES =
  "SET_OUTBOUND_TEMPLATE_ATTRIBUTES"

export const SET_SPLUNK_MATCHING_METHOD = "SET_SPLUNK_MATCHING_METHOD"
export const SET_WEBHOOK_MATCHING_METHOD = "SET_WEBHOOK_MATCHING_METHOD"
export const SET_SERVICE_WEBHOOK_MATCHING_METHOD =
  "SET_SERVICE_WEBHOOK_MATCHING_METHOD"
export const SET_SHOW_NOTIFICATION_TEMPLATE = "SET_SHOW_NOTIFICATION_TEMPLATE"
