/* eslint-disable react/prop-types */
import React, { useState } from "react"
import useRequest from "@ahooksjs/use-request"
import { useParams } from "react-router-dom"
import { getIndividualsGroupedRulesAuditLogs } from "@src/services/apis/reputation"
import { PolicyActionLogWrapper, TableWrapper, Row, Col } from "./styles"
import DragosaurWithUmbrella from "@src/components/DragosaurWithUmbrella"
import { DragoWrapper } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import { PolicyExecutionDropdown } from "./PolicyExecutionDropdown"
import { ActionLogsLoader } from "./ActionLogsLoader"
import Paginator from "@src/scenes/IndividualRiskAnalysis/components/Paginator"
import { Icon, theme } from "@elevate_security/elevate-component-library"

const PAGE_SIZE = 25

function PolicyActionLog() {
  const { profileId } = useParams()
  const [orderBy, setOrderBy] = useState("created_datetime desc")
  const [pageNumber, setPageNumber] = useState(1)

  const { loading, data } = useRequest(
    () =>
      getIndividualsGroupedRulesAuditLogs(profileId, {
        order_by: orderBy || "created_datetime desc",
        page_number: pageNumber,
        page_size: PAGE_SIZE,
      }),
    {
      refreshDeps: [orderBy, pageNumber],
      paginated: true,
      defaultPageSize: PAGE_SIZE,
      formatResult: (response) => ({
        total: response.data.total_elements,
        list: response.data.results,
        pages: response.data.pages,
        page_number: response.data.page_number,
        page_size: response.data.page_size,
      }),
    },
  )

  const handlePaginator = (pNum) => {
    setPageNumber(pNum)
  }

  const toggleOrderBy = (orderByField) => {
    setPageNumber(1)
    if (orderBy.includes("desc")) {
      setOrderBy(`${orderByField} asc`)
    } else {
      setOrderBy(`${orderByField} desc`)
    }
  }

  return (
    <PolicyActionLogWrapper>
      {loading || (data && !!data.list.length) ? (
        <TableWrapper>
          <Row>
            <Col header={true} width="70%">
              <Row noBorder={true}>
                <Col header={true}>Risk Rule Name</Col>
                <Col>
                  <div onClick={() => toggleOrderBy("risk_rule_name")}>
                    <Icon
                      name="Sort"
                      size="xxsm"
                      fill={theme.colors.gray.opaque[500]}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col header={true} width="30%">
              <Row noBorder={true}>
                <Col header={true}>Execution Time</Col>
                <Col>
                  <div onClick={() => toggleOrderBy("created_datetime")}>
                    <Icon
                      name="Sort"
                      size="xxsm"
                      fill={theme.colors.gray.opaque[500]}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {loading && <ActionLogsLoader rows={6} />}
          {!loading &&
            data?.list?.map((item) => (
              <PolicyExecutionDropdown
                key={item.policy_execution_id}
                {...item}
              />
            ))}
          {data && (
            <Paginator
              currentPage={data.page_number - 1}
              pages={data.pages}
              onNavigate={(page) => handlePaginator(page + 1)}
            />
          )}
        </TableWrapper>
      ) : (
        <DragoWrapper>
          <DragosaurWithUmbrella
            message="There are no logs available"
            showTooltip={false}
          />
        </DragoWrapper>
      )}
    </PolicyActionLogWrapper>
  )
}

export default PolicyActionLog
