import React from "react"

import {
  OUTBOUND_WEBHOOK,
  NOTIFICATION_OPTIONS,
} from "../../RiskRuleOutcome/constants"
import { ActionGroups } from "./ActionGroups"
import { NotificationRadioButtonGroup } from "../../RiskRuleOutcome/Notifications"

export default {
  label: "Send data via Webhook",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: OUTBOUND_WEBHOOK,
    matching_method: "delta",
  },
  isEnabled: (isServiceIntegrated) => isServiceIntegrated("outbound-webhook"),
  render: ({ details, updateAction }) => {
    return (
      <NotificationRadioButtonGroup
        title="Matching Method:"
        titleColor="700"
        titleFontWeight="bold"
        selected={details.matching_method}
        onChange={(e) => updateAction("matching_method", e.target.value)}
        options={NOTIFICATION_OPTIONS}
      />
    )
  },
}
