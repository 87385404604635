import React, { useEffect, useState } from "react"
import tippy from "tippy.js"
import { CustomSelect } from "@elevate_security/elevate-component-library"
import {
  GapWrapper,
  HeaderWrapper,
  TimeWrappers,
  Wrapper,
} from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/TeamTrends/styles"
import HRSGraph from "@elevate_security/elevate-component-library/dist/HRSGraph"
import ReactDOM from "react-dom"
import CampaignTooltip from "@src/scenes/Dashboard/components/ThirdQuestion/components/CampaignTooltip"
import ScoreTooltip from "@src/scenes/Dashboard/components/ThirdQuestion/components/ScoreTooltip"
import { humanize } from "@src/utils/string"
import {
  DATE_RANGE,
  DATE_RANGE_REVERSE,
} from "@src/scenes/IndividualProfilePage/ProfileInfo/components/IndividualHumanRiskScoreInfoSection/utils"
import { useDispatch, useSelector } from "react-redux"
import { getManagerOverview } from "@src/scenes/ManagerDashboard/services/redux/selectors"
import {
  setTeamTrend,
  setTeamTrendLoading,
} from "@src/scenes/ManagerDashboard/services/redux/actions"
import { SkeletonLoader } from "@src/utils/skeleton"
import { useParams } from "react-router-dom"
import ExportData from "@src/components/ExportData"

const TeamTrends = () => {
  const dispatch = useDispatch()
  const { person_nid } = useParams()
  const { managerTeamTrends } = useSelector(getManagerOverview)
  const [startDate, setStartDate] = useState(12)
  const [lineFocus, setFocus] = useState()

  const handleOnMouseEnter = (info) => {
    const {
      id,
      elementId,
      score,
      previous_score,
      score_last_quarter,
      behaviourName,
      date,
      typeGraph,
      isCampaignBar,
      campaign_type,
      name,
      status,
      start_date,
      end_date,
      total_targets,
      color,
    } = info
    const snatizedId = elementId.replace(/[.]/g, "-")
    const lastScore =
      typeGraph === "monthly" ? previous_score : score_last_quarter

    const tooltipId = `${snatizedId}-tooltip-content`
    const toolTipContent = document.createElement("div")

    toolTipContent.setAttribute("id", tooltipId)
    toolTipContent.style.display = "block"
    toolTipContent.style.minWidth = "900px"

    if (isCampaignBar) {
      ReactDOM.render(
        <CampaignTooltip
          campaignId={id}
          score={score}
          startDate={start_date}
          endDate={end_date}
          type={campaign_type}
          name={name}
          status={status}
          totalTargets={total_targets}
        />,
        toolTipContent,
      )
    } else {
      const trendScore = (score - lastScore).toFixed(1)
      ReactDOM.render(
        <ScoreTooltip
          score={score?.toFixed(1)}
          month={date}
          maxValue={10}
          trend={lastScore === null ? null : trendScore}
          behaviorName={humanize(behaviourName)}
        />,
        toolTipContent,
      )
    }

    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content: toolTipContent.innerHTML,
      onHide() {
        toolTipContent.remove()
      },
    }
    const element = document.querySelector(`#${snatizedId}`)
    element.setAttribute("fill", color)
    tippy(element, tippyProps)
    element._tippy.show()
  }

  const getActionTrendDDotLine = () => {
    if (lineFocus) return lineFocus
    return Object.keys(managerTeamTrends?.data || {}).sort((a, b) =>
      a > b ? 1 : -1,
    )[0]
  }

  useEffect(() => {
    if (managerTeamTrends.isLoaded) {
      dispatch(
        setTeamTrend({ es_person_id: person_nid || null, months: startDate }),
      )
    }
  }, [managerTeamTrends.isLoaded])

  useEffect(() => {
    dispatch(setTeamTrendLoading())
  }, [person_nid, startDate])

  if (managerTeamTrends.isLoaded) {
    return (
      <Wrapper>
        <SkeletonLoader height={350} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <TimeWrappers>
        <HeaderWrapper>Team Trends</HeaderWrapper>
        <GapWrapper>
          <CustomSelect
            options={["30 days", "60 days", "90 days", "6 months", "1 year"]}
            placeholder="Select Time Period"
            onChange={(e) => {
              setStartDate(DATE_RANGE[e.value])
            }}
            defaultValue={DATE_RANGE_REVERSE[startDate]}
          />
          <ExportData
            type="teamTrendData"
            es_person_id={person_nid}
            showButton={true}
            months={startDate}
            riskAnalysisFlag
          />
        </GapWrapper>
      </TimeWrappers>
      <HRSGraph
        behaviorScores={managerTeamTrends.data}
        campaignScores={[]}
        onMouseEnter={handleOnMouseEnter}
        yAxisLabel={<div>Grade</div>}
        xAxisLabel="Time"
        keyToBeDashed={getActionTrendDDotLine()}
        sortAlphabetical={false}
        showHeading={false}
        changeGraphTabsPosition={true}
        minMaxValue={{
          min: 0.0,
          max: 10.0,
        }}
        yAxisDecimal={false}
        changeLineFocus={setFocus}
        lineFOcus={getActionTrendDDotLine()}
        performanceFlag={true}
        showLegend={true}
        tabsToPosition={-75}
        containerStyle={{
          marginTop: "0px",
        }}
      />
    </Wrapper>
  )
}

export default React.memo(TeamTrends)
