/* eslint-disable react/prop-types */
import React from "react"
import dompurify from "dompurify"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"

const { Text } = Typography
const sanitizer = dompurify.sanitize

export const renderInsights = (insights = [], levelType) => {
  if (!insights || insights.length === 0) {
    return (
      <div>
        <Text
          fontWeight="normal"
          color="#34383C"
          style={{ fontSize: "16px", lineHeight: "24px" }}
        >{`There is no ${levelType} behavior in your team.`}</Text>
      </div>
    )
  }
  return (
    <div>
      {insights &&
        Array.isArray(insights) &&
        insights.map((_insight, index) => (
          <Text
            fontWeight="normal"
            color="#34383C"
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              display: "block",
              marginBottom: 16,
            }}
            key={index}
          >
            {insights.length > 1 && <b>{index + 1}. </b>}
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(_insight),
              }}
            ></span>
          </Text>
        ))}
    </div>
  )
}
