import React, { useEffect } from "react"
import {
  ProgressBarHeader,
  ProgressBarHeaderContainer,
  Wrapper,
} from "@src/scenes/ManagerDashboard/scenes/Session/styles"
import { ScoreProgressBar } from "@elevate_security/elevate-component-library"
import { useDispatch, useSelector } from "react-redux"
import { getManagerOverview } from "@src/scenes/ManagerDashboard/services/redux/selectors"
import {
  setManagerOverview,
  setManagerScores,
  setManagerScoresLoadingTrue,
} from "@src/scenes/ManagerDashboard/services/redux/actions"
import ActivityIndicator from "@src/components/ActivityIndicator"
import ScoreBreakDownIndividuals from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/ScoreBreakDownIndividuals"
import { useParams } from "react-router-dom"

function OverviewTab() {
  const dispatch = useDispatch()
  const { managerOverview, managerScores } = useSelector(getManagerOverview)
  const { person_nid } = useParams()

  useEffect(() => {
    if (managerOverview.isLoaded) {
      dispatch(setManagerOverview())
    }
    if (managerScores.isLoaded) {
      dispatch(setManagerScores(person_nid))
    }
  }, [managerScores.isLoaded, managerOverview.isLoaded])

  useEffect(() => {
    dispatch(setManagerScoresLoadingTrue())
  }, [person_nid])

  if (managerOverview.isLoaded || managerScores.isLoaded) {
    return <ActivityIndicator active />
  }

  const headerDescription = "YOUR TEAM'S ACTION FACTOR SCORE"
  const score = managerScores?.data?.scores?.team?.team_action || 0

  return (
    <>
      <Wrapper padding="36px 24px">
        <ProgressBarHeaderContainer>
          <ProgressBarHeader>{score}</ProgressBarHeader>
          <span style={{ color: "#7b828a", fontWeight: 700, fontSize: "14px" }}>
            {headerDescription.toUpperCase()}
          </span>
        </ProgressBarHeaderContainer>

        <ScoreProgressBar
          bodyPadding="60px 20px 10px"
          score={score}
          invert={false}
          size="medium"
          showCircle
        />
      </Wrapper>
      <ScoreBreakDownIndividuals />
    </>
  )
}

export default OverviewTab
