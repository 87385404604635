import styled from "styled-components"

export const SchedulerWrapper = styled("div")`
  width: 100%;
  padding: 24px 0px;

  [class^="inputstyles__InputContainer"] input {
    background-color: revert;
  }

  [class^="ComboBoxstyles__SelectContainer"] {
    .custom-react-select__menu {
      z-index: 15;
    }

    .custom-react-select__multi-value {
      background-color: #b8e5e3;
    }
  }

  .ant-picker {
    width: 252px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c9cfd6;

    .ant-picker-input {
      flex-direction: row-reverse;
    }
    input {
      font-size: 14px;
    }

    &:hover {
      border: 1px solid rgba(58, 70, 85, 0.3);
    }
  }

  .ant-picker-focused {
    border: 1px solid #0c7d84 !important;
    box-shadow: 0 0 3px #0c7d84 !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #0c7d84;
  }

  .ant-picker-suffix {
    margin-left: 0px;
    margin-right: 8px;

    svg {
      fill: #959da8;
      width: 20px;
      height: 20px;
    }
  }
`

export const SchedulerTitle = styled("div")`
  color: #1d1e1f;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const SchedulerContent = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  label: {
    margin-bottom: 0px;
  }

  [class^="ComboBoxstyles__SelectContainer"] {
    min-width: 252px;
    width: auto;
  }
`

export const DatesContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  opacity: 1;

  [class^="InputCheckBoxWrapper"] {
    label {
      margin-bottom: 0px;
    }
  }
`

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  width: ${(props) => (props.width ? props.width : "115px")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
`

export const InputRow = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

// DayPicker components styles here

export const DayPickerWrapper = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

export const DayPickerItem = styled("div")`
  display: flex;
  width: 26px;
  height: 26px;
  padding: 6px 2px 7px 2px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${(props) => (props.active ? "#039ba1" : "#AEB5BF")};
  color: #ffffff;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
`

// Execution time picker styles here

export const ExecutionTimePickerWrapper = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 0px;

  svg {
    height: 9px !important;
  }
`

export const Bold = styled.span`
  font-weight: 700;
`

export const OccurrenceSelectWrapper = styled.div`
  input {
    background-color: revert;
  }
`
