import styled from "styled-components"

const colours = {
  highlightGreen: "#0C7D84",
  active: "#f5f0e9",
  default: "#565D66",
}

const sizing = {
  menuItem: "52px",
  subMenuItem: "32px",
  secondaryItem: "44px",
  avatar: "32px",
}

const Sidebar = styled.aside`
  position: relative;
  bottom: 0;
  box-sizing: border-box;
  font-size: 100%;
  height: 100vh;
  overflow: visible;
  width: 269px;
  background: #fbf8f3;
  display: flex;
  flex-direction: column;

  > * {
    vertical-align: middle;
  }
`

const Nav = styled.nav`
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  height: 32px;
  margin: 24px auto 32px 21px;
  box-sizing: border-box;
`

const MainNavItem = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  border-left: ${(props) => (props.noBorder ? "0" : "3px solid transparent")};

  cursor: pointer;
  outline: none;

  &.selected {
    background: ${colours.active};
    a {
      font-weight: 700;
      color: ${colours.highlightGreen};
      outline: none;
    }
  }

  &.active {
    border-left: ${(props) =>
      props.noBorder ? "0" : `3px solid ${colours.highlightGreen}`};
    background: ${colours.active};
    font-weight: 700;

    a {
      color: ${colours.highlightGreen};
    }
  }

  &.locked > span,
  &.locked > a {
    cursor: not-allowed;
    color: ${colours.default};
  }

  &.locked > span > a {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover {
    background: ${colours.active};
  }

  span[aria-expanded="true"],
  span[aria-expanded="false"] {
    display: flex;
    width: 100%;
    background-color: transparent;
    z-index: 1;
  }
`

const MainNavLink = (C) => styled(C)`
  display: flex;
  align-items: center;
  height: ${sizing.menuItem};
  line-height: ${sizing.menuItem};
  margin-left: 18px;
  width: 100%;

  color: ${colours.default};
  font-size: 15px;
  text-decoration: none;

  &:hover {
    color: ${colours.highlightGreen};
  }

  &.active {
    a {
      color: ${colours.highlightGreen};
    }
  }
`

const NavLinkIcon = styled.img`
  margin-right: 16px;
  height: 32px;
  width: 32px;
`

const NavLinkSubIcon = (C) => styled(C)`
  margin-right: 26px;
`

const SubMenuToggleButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  line-height: ${sizing.menuItem};
  padding: 0;
  margin: 0;
  width: ${sizing.menuItem};
  text-align: center;
  transition: transform 250ms;
  position: absolute;
  left: auto;
  right: 0;

  &:active,
  &:focus {
    outline: none !important;
  }

  &.active {
    svg {
      stroke: ${colours.highlightGreen};
    }

    transform: rotate(90deg);
  }
`

const MenuButtonLock = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  left: auto;
  right: 0;
  padding-left: 10px;
  width: 52px;
  cursor: not-allowed;
`

const MainNavSubItems = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colours.active};
  padding-bottom: 4px;
`

const MainNavSubLink = (C) => styled(C)`
  box-sizing: border-box;
  color: ${colours.default};
  font-size: 15px;
  display: block;
  line-height: ${sizing.subMenuItem};
  padding: 0 0 0 66px;
  text-decoration: none;
  width: 100%;
  border-left: 3px solid transparent;
  outline: none;
  .disabled-link {
    pointer-events: none;
  }

  &.active,
  &:hover {
    background: #eee6dd;
    color: ${colours.highlightGreen};
  }

  &.active {
    border-left: 3px solid ${colours.highlightGreen};
    font-weight: 700;
    outline: none;
  }
`

const SecondaryNavLink = (C) => styled(MainNavSubLink(C))`
  height: ${sizing.secondaryItem};
  line-height: ${sizing.secondaryItem};
  margin-left: 18px;
  padding-left: 0;
  display: flex;
  align-items: center;

  &.active {
    border-left: 3px solid ${colours.highlightGreen};
    color: ${colours.highlightGreen};
    background: ${colours.active};
  }

  &:active {
    font-weight: 500;
    background: ${colours.active};
  }

  &.active,
  &:hover {
    background: ${colours.active};
    color: ${colours.highlightGreen};
  }

  &:hover {
    color: ${colours.highlightGreen};
  }
`

const LegalFooter = styled.div`
  display: flex;
  border-top: 1px solid #ede5db;
  padding: 16px 20px;
  margin: auto 10px 0;
  a {
    display: flex;
    flex: 1 50%;
    color: #565d66;
    line-height: 16px;
    justify-content: center;
    &:hover,
    &:focus {
      color: #848b93;
      text-decoration: underline;
    }
  }
`

export default {
  Logo,
  MainNavItem,
  MainNavLink,
  NavLinkIcon,
  NavLinkSubIcon,
  MainNavSubItems,
  MainNavSubLink,
  SecondaryNavLink,
  Sidebar,
  LegalFooter,
  SubMenuToggleButton,
  MenuButtonLock,
  Nav,
}
