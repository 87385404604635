import { THEME_PRIMARY } from "@src/theme"
import styled from "styled-components"

export const InputCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  && {
    -webkit-appearance: none;
    background-color: #ffffff;
    border: 1px solid ${(props) => (props.invalid ? "#c73434" : "#b1b8c1")};
    box-shadow: none;
    height: 16px;
    width: 16px;
    min-width: 16px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    outline: 0;

    &:active,
    &:checked:active {
      box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:checked {
      background-color: ${THEME_PRIMARY};
      border: 1px solid ${THEME_PRIMARY};
      box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: #ffffff;
      border-radius: 4px;

      &:after {
        content: "";
        font-size: 14px;
        position: absolute;
        color: #ffffff;
        ${(props) =>
          props.indeterminate
            ? `
          top: 6px;
          left: 2px;
          width: 10px;
          height: 0;
        `
            : `
          top: 1px;
          left: 5px;
          width: 5px;
          height: 10px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        `}
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
      }
    }
  }
`
export const InputCheckBoxWrapper = styled.div`
  ${({ hasDropdown }) =>
    hasDropdown
      ? `
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px;
    width: 50px;
    height: 28px;
    background: #F5F6F8;
    border: 1px solid #B1B8C1;
    border-radius: 4px;
    input[type="checkbox"] {
      margin-right: 4px;
    }
    svg {
      width: 8px;
    }
  `
      : ``}
  label {
    transform: translateY(-3px);
  }
`

export const Caret = styled.span`
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding-top: 1px;
  :hover {
    background: #dde1e7;
  }
`

export const Dropdown = styled.div`
  position: absolute;
  left: -1px;
  top: 28px;
  min-width: 200px;
  background: white;
  z-index: 1001;
  box-shadow: 0px 2px 8px rgba(177, 184, 193, 0.5);
  border-radius: 4px;
  border: 1px solid #c9cfd6;
  padding: 8px 0;
`

export const Option = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
  font-weight: normal;
  padding: 8px 16px;
  cursor: pointer;
  :hover {
    background-color: #eef0f3;
  }
`
