import React from "react"
import { SpacingY } from "@src/components/SpacingY"
import {
  ScoreBox,
  ScoreBoxWrapper,
  ScoreBoxDescription,
  Strong,
} from "./styles"
import IconMalware from "./icon-malware.svg"
import IconPhishing from "./icon-phishing.svg"
import { getAttackExplanation } from "@src/services/apis/reputation"
import { darklyGetFlag } from "@src/utils/darkly"
import { IdentityExplanation } from "./IdentityExplanation"
import pluralize from "pluralize"
import { useRequest } from "ahooks"
import { SkeletonLoader } from "@src/utils/skeleton"

export function AttackExplanationCards({
  profileId,
  startDate,
  timePeriodValue,
}) {
  const { loading, data } = useRequest(
    () =>
      getAttackExplanation(profileId, {
        start_date: startDate,
      }),
    {
      refreshDeps: [timePeriodValue],
      formatResult: (res) => {
        return res?.data || {}
      },
    },
  )

  const isItdrEnabled = darklyGetFlag("itdr-behaviors")

  return (
    <SpacingY>
      <div>
        In the {timePeriodValue?.toLocaleLowerCase() || ""}, this individual:
      </div>
      <ScoreBoxWrapper>
        <ScoreBox>
          {loading ? (
            <SkeletonLoader height={80} />
          ) : (
            <>
              <img src={IconPhishing} alt="logo" />
              <ScoreBoxDescription>
                <span> HAS RECEIVED </span>
                <Strong>
                  {" "}
                  {data?.phishing_received !== null
                    ? data?.phishing_received
                    : "-"}{" "}
                </Strong>
                Actual Phishing emails
              </ScoreBoxDescription>
            </>
          )}
        </ScoreBox>
        <ScoreBox>
          {loading ? (
            <SkeletonLoader height={80} />
          ) : (
            <>
              <img src={IconMalware} alt="logo" />
              <ScoreBoxDescription>
                <span> WAS INVOLVED IN </span>
                <Strong>
                  {" "}
                  {data?.malware_received !== null
                    ? data?.malware_received
                    : "-"}{" "}
                </Strong>
                Malware incidents
              </ScoreBoxDescription>
            </>
          )}
        </ScoreBox>
        {isItdrEnabled && (
          <>
            <IdentityExplanation
              attemptText="Impossible Login"
              successCount={data?.impossible_login_success_count}
              failureCount={data?.impossible_login_failure_count}
              loading={loading}
            />
            <IdentityExplanation
              attemptText="Password Brute Force"
              successCount={data?.password_brute_force_success_count}
              failureCount={data?.password_brute_force_failure_count}
              loading={loading}
            />
            <IdentityExplanation
              attemptText="Credential Stuffing"
              successCount={data?.credential_stuffing_success_count}
              failureCount={data?.credential_stuffing_failure_count}
              loading={loading}
            />
            <ScoreBox>
              {loading ? (
                <SkeletonLoader height={80} />
              ) : (
                <>
                  <img src={IconMalware} alt="logo" />
                  <ScoreBoxDescription>
                    <span> WAS DETECTED IN </span>
                    <Strong>{data?.session_count_anomaly_count ?? "-"} </Strong>
                    Session Count{" "}
                    {pluralize(
                      "Anomaly",
                      data?.session_count_anomaly_count || 0,
                    )}
                  </ScoreBoxDescription>
                </>
              )}
            </ScoreBox>
            <ScoreBox>
              {loading ? (
                <SkeletonLoader height={80} />
              ) : (
                <>
                  <img src={IconMalware} alt="logo" />
                  <ScoreBoxDescription>
                    <span> WAS DETECTED IN </span>
                    <Strong>{data?.login_anomaly_count ?? "-"} </Strong>
                    Login {pluralize("Anomaly", data?.login_anomaly_count || 0)}
                  </ScoreBoxDescription>
                </>
              )}
            </ScoreBox>
          </>
        )}
      </ScoreBoxWrapper>
    </SpacingY>
  )
}
