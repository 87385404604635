import { humanize } from "@src/utils/string"
import { FILTER_TYPE } from "../../../../../components/utils"
export const actions = [
  "training",
  "sensitive_data_handling",
  "secure_browsing",
  "password_manager",
  "real_world_phishing",
  "phishing",
  "malware",
  "password_security",
  "clean_desk",
  "optional_activities",
  "unauthorized_software",
  "physical_security",
]

export const options = (params) => [
  {
    type: FILTER_TYPE.MULTI_SELECT,
    showAllFilter: true,
    title: "action".toUpperCase(),
    key: "actions",
    items: params?.map((decision) => ({
      label: humanize(decision),
      value: humanize(decision),
    })),
  },
]

export const InsightDataFilterOptions = (params) => [
  {
    type: FILTER_TYPE.DATE_RANGE,
    title: "date".toUpperCase(),
    key: "date",
  },
  ...(params?.actions?.length > 1
    ? [
        {
          type: FILTER_TYPE.MULTI_SELECT,
          title: "action".toUpperCase(),
          key: "action",
          items:
            params?.actions?.map((decision) => ({
              label: humanize(decision),
              value: decision.toLowerCase().replaceAll(" ", "_"),
            })) || [],
          ...(params?.actions &&
            params?.actions?.length > 4 && {
              showMore: "Show all actions",
              showLess: "Show fewer actions",
            }),
        },
      ]
    : []),
  ...(params?.data_source?.length > 1
    ? [
        {
          type: FILTER_TYPE.MULTI_SELECT,
          title: "Data Source".toUpperCase(),
          key: "data_source",
          items:
            params?.data_source?.map((decision) => ({
              label: humanize(decision),
              value: humanize(decision),
            })) || [],
          ...(params?.actions &&
            params?.actions?.length > 4 && {
              showMore: "Show all Data Source",
              showLess: "Show fewer Data Source",
            }),
        },
      ]
    : []),
]

export const InsightListOfPeopleFilterOptions = (params) => [
  {
    type: FILTER_TYPE.SLIDER,
    title: "Human Risk Score".toUpperCase(),
    key: "overall",
    items: actions?.map((decision) => ({
      label: humanize(decision),
      value: humanize(decision),
    })),
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: "Access Factor".toUpperCase(),
    key: "access_factor",
    items: params?.access_factor?.map((decision) => ({
      label: humanize(decision.label),
      value: decision.value,
    })),
    ...(params?.access_factor &&
      params?.access_factor?.length > 4 && {
        showLess: "Show less Access Factor",
        showMore: "Show all Access Factor",
      }),
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: "Attack Factor".toUpperCase(),
    key: "attack_factor",
    items: params?.attack_factor?.map((decision) => ({
      label: humanize(decision.label),
      value: decision.value,
    })),
    ...(params?.attack_factor &&
      params?.attack_factor?.length > 4 && {
        showLess: "Show less Attack Factor",
        showMore: "Show all Attack Factor",
      }),
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: "department".toUpperCase(),
    key: "department",
    items: params?.department
      ?.sort((a, b) => (a > b ? 1 : -1))
      ?.map((decision) => ({
        label: humanize(decision),
        value: decision,
      })),
    ...(params?.department &&
      params?.department?.length > 4 && {
        showLess: "Show less departments",
        showMore: "Show all departments",
      }),
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: "location".toUpperCase(),
    key: "location",
    items: params?.location
      ?.sort((a, b) => (a > b ? 1 : -1))
      ?.map((decision) => ({
        label: humanize(decision),
        value: decision,
      })),
    ...(params?.location &&
      params?.location?.length > 4 && {
        showMore: "Show all departments",
        showLess: "Show less departments",
      }),
  },
]
