import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const RecommendationItemsWrapper = styled.div`
  ${({ isRiskInsights }) =>
    isRiskInsights &&
    `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  `}
  color: #000;
`

export const RecommendationItemCardWrapper = styled.div`
  position: relative;
  background: ${(props) =>
    props.activated ? theme.button.disabled.color.default : "#FFFFFF"};
  color: ${(props) =>
    props.activated ? theme.text.color.default : theme.text.color["700"]};
  font-weight: ${(props) => (props.activated ? "400" : "500")};
  border: 1px solid ${theme.colors.gray.opaque["300"]};
  border-radius: 4px;
  box-shadow: 0px 1px 16px rgba(149, 157, 168, 0.26);
  padding: 12px 15px 12px 15px;
  margin-bottom: 8px;
  overflow: visible;
  display: flex;
  align-items: center;
  align-content: center;
  ${({ isRiskInsights }) =>
    isRiskInsights &&
    `
  width: 100%;
  `}

  > span {
    flex: 1;
    color: ${theme.text.color.default};
  }
  > button {
    margin-left: 24px;
  }

  ${({ showHover }) =>
    showHover &&
    `
  &:hover{
    cursor: pointer;
    box-shadow: 0 1px 16px rgba(149, 157, 168, 0.26);
    a {
      color: ${theme.text.color.linkHover} !important;
    };
    a > svg path {
      fill: ${theme.text.color.linkHover};
    }
    
  }
  `}
`
export const RecommendationIconWRAPPER = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 25px;
`
export const RecommendationParagraphWRAPPER = styled.div`
  margin-left: 15px;
  color: ${(props) =>
    props.activated ? theme.text.color.default : theme.text.color["700"]};
  font-size: 14px;
  margin-right: auto;
`

export const RecommendationCompanyIconWRAPPER = styled.div`
  cursor: default;
  margin-right: 16.25px;
  min-width: 50px;
`

export const RecommendationImpact = styled.div`
  min-width: 106px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  min-height: 24px;
  margin-right: 32px;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: default;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
`
export const RecommendationActivateWRAPPER = styled.div`
  color: #039ba1;
  font-weight: ${(props) => (props.activated ? 400 : 700)};
  font-size: 14px;
  text-decoration-color: ${theme.text.color["500"]};
  margin-right: 22px;
  cursor: pointer;
  min-width: 70px;
`
export const RightArrow = styled.div`
  width: 0;
  height: 0;
  border-left: ${({ subSectionColor }) => `12px solid ${subSectionColor}`};
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  position: absolute;
  left: -30px;
  display: ${(props) => (props.activated ? "none" : "block")};
`
