import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const FieldWrapper = styled.div`
  border-radius: 8px;
  border: 2px solid ${theme.colors.gray.opaque[100]};
  background-color: white;
`

export const InsertRow = styled.div`
  border-bottom: 2px solid ${theme.colors.gray.opaque[100]};
  padding: 2px 9px;

  // Hacks for centering icon in ButtonIcon
  svg {
    padding-top: 7px;
  }
`

export const NotificationInput = styled.textarea`
  border: none;
  font-size: 14px;
  width: 100%;
  padding: 15px 20px;
  color: black;
`
