import React from "react"

import { getIconAndColorByScoreDiff } from "@src/utils/colors"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Button from "@elevate_security/elevate-component-library/dist/Button"
import {
  ScoreTooltip,
  FlexRow,
  FlexCol,
  ScoreSpan,
  BehaviorName,
  ScoreTrend,
  ScoreIcon,
} from "./styles"

export function parseScoreChange(behaviorScoreChange) {
  if (behaviorScoreChange > 0) return `+ ${behaviorScoreChange}`
  if (behaviorScoreChange < 0) return `- ${behaviorScoreChange * -1}`
  return behaviorScoreChange || 0
}

export const renderScoreTooltip = ({
  name,
  teamScore,
  teamScoreTrend,
  onImproveBehavior,
}) => {
  const { icon, color } = getIconAndColorByScoreDiff(teamScoreTrend)
  return (
    <ScoreTooltip>
      <FlexRow>
        <FlexCol>
          <ScoreSpan>{teamScore}</ScoreSpan>
          <FlexRow>
            <ScoreIcon>
              <Icon name={icon} fill={color} />
            </ScoreIcon>
            <ScoreTrend color={color} style={{ marginLeft: 4 }}>
              {parseScoreChange(teamScoreTrend)} pts
            </ScoreTrend>
          </FlexRow>
        </FlexCol>
        <FlexCol style={{ marginLeft: 20, flex: 1 }}>
          <BehaviorName>{name}</BehaviorName>
          {teamScore < 40 && (
            <>
              <br />
              <Button onClick={onImproveBehavior}>Improve Behavior</Button>
            </>
          )}
        </FlexCol>
      </FlexRow>
    </ScoreTooltip>
  )
}
