import React, { useEffect, useState } from "react"
import { useHumanRisksIndividuals } from "./HumanRisksIndividualsProvider"
import IndividualRiskDetails from "./IndividualsRiskDetail"
import IndividualRiskDetailsSkeleton from "@src/scenes/Dashboard/components/FirstQuestion/components/Skeleton/IndividualRiskDetailsSkeleton"
import { capitalize } from "@src/utils/string"

export default function HumanRisksIndividuals() {
  const { riskyIndividuals, riskIndividualLoading } = useHumanRisksIndividuals()

  const [selectedEntity, setSelectedEntity] = useState(null)

  useEffect(() => {
    if (!riskIndividualLoading) {
      setSelectedEntity(riskyIndividuals[0].heading)
    }
  }, [riskIndividualLoading, riskyIndividuals])

  if (riskIndividualLoading) {
    return <IndividualRiskDetailsSkeleton riskyIndividuals={riskyIndividuals} />
  }

  const item =
    riskyIndividuals.find(({ heading }) => heading === selectedEntity) ||
    riskyIndividuals[0]

  return (
    riskyIndividuals && (
      <IndividualRiskDetails
        analyticsId={(type, index) => {
          return `riskiest-${type}-${index}`
        }}
        riskData={item}
        entityOptions={riskyIndividuals.map(({ heading }) => ({
          label: capitalize(heading),
          value: heading,
        }))}
        onChange={({ value }) => {
          setSelectedEntity(value)
        }}
      />
    )
  )
}

export function AttackedIndividuals({
  riskyIndividuals,
  riskIndividualLoading,
}) {
  if (riskIndividualLoading) {
    return <IndividualRiskDetailsSkeleton riskyIndividuals={riskyIndividuals} />
  }

  return (
    riskyIndividuals && (
      <IndividualRiskDetails
        analyticsId={(type, index) => {
          return `most-attacked-individuals-${index}`
        }}
        riskData={riskyIndividuals}
        title="Most Attacked"
        headerText="ATTACK FACTOR SCORE"
        scoretype="text"
      />
    )
  )
}
