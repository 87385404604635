import styled from "styled-components"
import { PrettyLogicalOperator } from "@src/scenes/RiskDetectionRulesScreen/DropdownCard/PrettyPrintedR3Criteria/PrettyPrintedR3Criteria.styles"
import { theme } from "@elevate_security/elevate-component-library"

export const GroupWrapper = styled.div`
  padding: 20px 0px 0px;
`

export const ItemWrapper = styled.li`
  position: relative;
  input {
    background-color: revert;
  }

  input:disabled {
    // By defauly, disabled inputs have some transparency, so we set the
    // background color here manually without transparency, to avoid bleeding
    // from query builder group background color
    background-color: ${theme.colors.gray.opaque["050"]};
    cursor: not-allowed;
  }
`

// Very good article about styling selects with pure CSS https://moderncss.dev/custom-select-styles-with-pure-css/

// Use grid-template-areas to overlap the arrow with the select element, which
// gives the illusion that clicking on the arrow activates the select
export const ConditionWrapper = styled(PrettyLogicalOperator)`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 6px;
  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  z-index: 1; // Make sure select appears above any vertical connectors
`

// Need to remove some global `select` styles explicitly because they are tagged as important :(
// For now, remove all styles and then explicitly add back any that we need.
export const ConditionSelect = styled.select`
  all: unset !important;
  grid-area: select !important;
  z-index: 1 !important;
  text-align: center !important;
  padding-right: 13px !important;
`

export const IconWrapper = styled.div`
  svg {
    width: 9px;
    height: 10px;
  }
  position: relative;
  top: -1px;
  grid-area: select;
  justify-self: end;
`
