import styled from "styled-components"

const breakpoints = {
  xs: "5px",
  sm: "10px",
  md: "20px",
  lg: "40px",
  xl: "80px",
}

export type SpaceBetweenProps = {
  direction?: string
  justify?: string
  gap?: string
  size?: keyof typeof breakpoints
  alignItems?: string
  xs?: object
}

export const SpaceBetween = styled.div<SpaceBetweenProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  justify-content: ${(props) => props.justify || "space-between"};
  gap: ${(props) => props.gap || breakpoints[props.size || "md"]};
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems};` : "")}
  ${({ xs }) => ({ ...xs })}
`
