import styled from "styled-components"

export const CardsContainer = styled.div`
  padding: 20px 10px;
`

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 5px 0;
  border: solid 1px lightgray;
  border-radius: 5px;
`

export const PolicyTitle = styled.div`
  font-size: 15px;
  text-decoration: underline;
  font-weight: 600;
`

export const ItemStatus = styled.div`
  text-align: center;
  border: solid 1px lightgray;
  border-radius: 40px;
  margin: 0 10px;
  padding: 5px;
  width: 60px;
`

export const ButtonsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 18px;
`

export const EmailHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
`
export const ShowEmail = styled.div`
  width: 100%;
`
export const EmailContent = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-bottom: 4px;
`
export const DeleteHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-bottom: 6px;
`

export const DeleteContent = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin-bottom: 4px;
`
