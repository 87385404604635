import React, { useState } from "react"
import {
  OverlayWrapper,
  ChildrenWrapper,
  Overlay,
} from "./TransparentOverlay.styles"

export function TransparentOverlay({ children, when, activationElement }) {
  const [overlayOpacity, setOverlayOpacity] = useState("0.5")

  if (!when) {
    return children
  }

  return (
    <OverlayWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Overlay
        opacity={overlayOpacity}
        onMouseEnter={() => {
          setOverlayOpacity("1")
        }}
        onMouseLeave={() => {
          setOverlayOpacity("0.5")
        }}
      >
        {activationElement}
      </Overlay>
    </OverlayWrapper>
  )
}
