import { THEME_HOVER, THEME_PRIMARY } from "@src/theme"
import styled from "styled-components"

export const Container = styled.div`
  opacity: 1;
  width: 100%;
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__time-container {
    width: 80px !important;
  }
  .react-datepicker__time-box {
    width: 79px !important;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    line-height: 38px;
    padding: 0 16px;
    border: 1px solid rgba(189, 197, 209, 0.5);
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    outline: none;
    &:hover {
      border: 1px solid ${THEME_HOVER};
    }
    &:focus {
      border: 1px solid ${THEME_HOVER};
      box-shadow: rgb(12, 125, 132) 0px 0px 3px;
    }
  }

  .react-datepicker__close-icon {
    ::after {
      margin: 10px;
      background-color: ${THEME_PRIMARY};
      padding: 1px 2px 1px 3px;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
`
