import { v4 as uuidv4 } from "uuid"

export const eventOccurrences = [
  {
    occurrence_type: "count",
    value: ["", 0, 0, "weeks"],
  },
]

export const INITIAL_RULE = {
  category: "",
  field: "",
  operator: "",
  value: [""],
  uuid: uuidv4(),
}

export const symbolOperators = {
  eq: "=",
  gt: ">",
  ge: ">=",
  lt: "<",
  le: "<=",
  ne: "!=",
}

export const alphabeticOperators = {
  in: "IN",
  not_in: "NOT IN",
  contains: "CONTAINS",
  like: "LIKE",
  ilike: "ILIKE",
  between: "BETWEEN",
  rbetween: "BETWEEN",
  rge: "On or After",
  rle: "On or Before",
}

export const operatorLookUp = {
  ...symbolOperators,
  ...alphabeticOperators,
}

export const TIME_MODE_OPS = [
  { label: "Minutes", value: "minutes" },
  { label: "Hours", value: "hours" },
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
]

export const EVENT_OCCURRENCE_OPERATORS = [
  {
    label: "Equals",
    value: "eq",
  },
  {
    label: "Not Equal",
    value: "ne",
  },
  {
    label: "Greater than",
    value: "gt",
  },
  {
    label: "Greater than or Equal to",
    value: "ge",
  },
  {
    label: "Between",
    value: "between",
  },
  {
    label: "Less than",
    value: "lt",
  },
  {
    label: "Less than or Equal to",
    value: "le",
  },
]

export const VENDOR_DATA_IDENTIFIER = "vendor_schema"
