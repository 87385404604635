import React from "react"

import { ActionGroups } from "./ActionGroups"
import {
  ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION,
  REMOVE_USERS_FROM_AZUREAD_ENTERPRISE_APPLICATION,
} from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"

import { ActionConfig } from "./actionConfigTypes"

import {
  EditAzureEnterpriseApplications,
  BaseAzureEnterpriseAppPayload,
} from "../actionRenderComponents/EditAzureEnterpriseApplications"

type UserFacingActionPayload = {
  action_type: "COMPOUND_update_azuread_applications_users"
} & Omit<BaseAzureEnterpriseAppPayload, "groups">

type ServerActionPayload = {
  action_type: string
  matching_method: "delta" | "snapshot"
  update_info: {
    app_ids: string[]
    app_role_mapping: { [key: string]: string }
  }
  target_apps_meta: { appId: string; label: string; value: string }[]
}

/*
 * This is a "fake" action -- i.e. it doesn't get sent to the back-end as-is,
 * but is actually composed of up to one of two actions depending on what the
 * users want to do with the applications they select. We want to present these
 * actions as a single action in the UI, so the config specifies additional
 * methods in order to convert from the multiple-action version to the
 * single-action version, and vice-versa.
 */
const config: ActionConfig<UserFacingActionPayload, ServerActionPayload> = {
  label: "Azure Enterprise Applications - Add/remove users",
  group: ActionGroups.OUTBOUND,
  defaultPayload: {
    action_type: "COMPOUND_update_azuread_applications_users",
    matching_method: "delta",
    operation: "add",
    applications: [],
    app_role_mapping: {},
  },
  isEnabled: (isServiceIntegrated) =>
    isServiceIntegrated("azuread", "enterprise-applications"),
  render: ({ details, updateAction }) => {
    return (
      <EditAzureEnterpriseApplications
        type="users"
        action={details}
        updateAction={updateAction}
      />
    )
  },
  isRelatedServerAction: (action) => {
    return [
      ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION,
      REMOVE_USERS_FROM_AZUREAD_ENTERPRISE_APPLICATION,
    ].includes(action.action_type)
  },
  // For this compound action, there should be only one related server action,
  // either to add or remove users
  convertServerActionsToUserFacingAction: ([action]) => {
    return {
      action_type: "COMPOUND_update_azuread_applications_users",
      matching_method: action.matching_method,
      applications: action.target_apps_meta.map((app) => ({
        id: app.appId,
        label: app.label,
      })),
      app_role_mapping: action.update_info.app_role_mapping,
      operation:
        action.action_type === ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION
          ? "add"
          : "remove",
    }
  },
  convertUserFacingActionToServerActions: (payload) => {
    return [
      {
        action_type:
          payload.operation === "add"
            ? ADD_USERS_TO_AZUREAD_ENTERPRISE_APPLICATION
            : REMOVE_USERS_FROM_AZUREAD_ENTERPRISE_APPLICATION,
        matching_method: payload.matching_method,
        target_apps_meta: payload.applications.map((app) => ({
          appId: app.id,
          label: app.label,
          value: app.id,
        })),
        update_info: {
          app_ids: payload.applications.map((app) => app.id),
          app_role_mapping: payload.app_role_mapping,
        },
      },
    ]
  },
}

export default config
