export const GET_ALL_API_DATA = "GET_ALL_API_DATA"
export const GET_TREND_OVER_TIME_CARD_DATA = "GET_TREND_OVER_TIME_CARD_DATA"
export const GET_TREND_OVER_TIME_POLICY_STATS =
  "GET_TREND_OVER_TIME_POLICY_STATS"
export const SET_FETCHING_TREND_OVER_TIME_CARD_DATA =
  "SET_FETCHING_TREND_OVER_TIME_CARD_DATA"
export const SET_FETCHING_TREND_OVER_TIME_POLICY_STATS =
  "SET_FETCHING_TREND_OVER_TIME_POLICY_STATS"
export const GET_FACTOR_GRAPH_DATA = "GET_FACTOR_GRAPH_DATA"
export const SET_HIERACHICAL_DATA = "SET_HIERACHICAL_DATA"
export const GET_TRENDS_OVER_TIME = "GET_TRENDS_OVER_TIME"
export const TIME_PERIOD_VALUE = "TIME_PERIOD_VALUE"
export const TREND_LOADER = "TREND_LOADER"
export const LATEST_EVENTS = "LATEST_EVENTS"
export const SCORE_BREAKDOWN = "SCORE_BREAKDOWN"
export const SET_ACTIVE_ACTIONS = "SET_ACTIVE_ACTIONS"
export const SET_COMPANY_INFO = "SET_COMPANY_INFO"
export const SET_LATEST_SCORING_DATETIME = "SET_LATEST_SCORING_DATETIME"
