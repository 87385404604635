import React from "react"
import PropTypes from "prop-types"
import dompurify from "dompurify"

import {
  Modal,
  Header,
  Typography,
} from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"
import {
  EmailContent,
  EmailHeader,
  ShowEmail,
} from "@src/scenes/RiskDetectionRulesScreen/styles"
import { ModalWrapper } from "./styles"
import EmailPreviewContainer from "../EmailPreview/EmailPreviewContainer"
const { H2, Text } = Typography

export const EmailPreview = ({ plainSubject, htmlTemplate }) => {
  const sanitizer = dompurify.sanitize
  return (
    <ShowEmail>
      <Text>
        <EmailHeader>
          <p>Email Subject</p>
        </EmailHeader>
        <p>{plainSubject || ""}</p>
        <EmailContent>Email Content</EmailContent>

        <div
          dangerouslySetInnerHTML={{ __html: sanitizer(htmlTemplate) }}
          style={{ gap: "2rem" }}
        />
      </Text>
    </ShowEmail>
  )
}

const EmailReviewModal = ({
  item,
  showModal,
  onClose,
  queryRules,
  ruleName,
}) => {
  const renderButtons = [
    <Button key="yes" onClick={onClose}>
      Close
    </Button>,
  ]

  return (
    <ModalWrapper>
      <Modal
        onClose={onClose}
        header={
          <Header>
            <H2 color="900">Preview Email Message</H2>
          </Header>
        }
        rightButtons={renderButtons}
        isOpen={showModal}
      >
        <EmailPreviewContainer
          queryRules={queryRules}
          ruleName={ruleName}
          action={item}
        />
      </Modal>
    </ModalWrapper>
  )
}

EmailReviewModal.propTypes = {
  item: PropTypes.object,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  queryRules: PropTypes.object,
  ruleName: PropTypes.string,
}

export default EmailReviewModal
