import type { BackendClient } from "./BackendClient"
import fetcher from "@src/services/fetcher"
import { BASE_EP_API_URL } from "@src/constants"

const API_URL = BASE_EP_API_URL()

export class ElevateClient implements BackendClient {
  getEnabledBehaviors() {
    return fetcher
      .get<string[]>(`${API_URL}/scores/get-behaviours`)
      .then(({ data }) => data)
  }
}
