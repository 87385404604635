import PropTypes from "prop-types"
import React from "react"

import {
  ScoreTooltipWrapper,
  ScoreNumber,
  ScoreLabel,
  ScoreMonth,
  ScoreTrend,
  Span,
  PerformanceTooltipWrapper,
  PerformanceText,
  BarColorsWrapper,
} from "./styles"

// components
import { Icon, BarColors } from "@elevate_security/elevate-component-library"
import { getIconAndColorByScoreDiff } from "@src/utils/colors"
import { formatDate } from "@src/utils/dates"
import { dashToTitleCase } from "@src/utils/string"
import { SpacingX } from "@src/components/SpacingX"
import { SpacingY } from "@src/components/SpacingY"
import { WordScoreBadge } from "@src/components/ScoreBadges"

function treatTrend(trend) {
  if (trend === null) {
    return null
  }

  if (Number.isInteger(trend)) {
    return trend
  }

  return parseFloat(trend).toFixed(1)
}
export default function ScoreTooltip(props) {
  const {
    score,
    trend,
    behaviorName,
    month,
    maxValue,
    performanceFlag,
    performanceType,
    useWordLabel = false,
  } = props

  const treatedTrend = treatTrend(trend)

  const { color, icon, diff } = getIconAndColorByScoreDiff(treatedTrend)
  const barColorValue = maxValue === 100 ? score / 10 : score

  if (performanceFlag) {
    return (
      <PerformanceTooltipWrapper>
        <PerformanceText>
          <b>
            {Math.floor(score) === 1
              ? `${Math.floor(score)} individual`
              : `${Math.floor(score)} individuals`}
          </b>{" "}
          had <b>{performanceType}</b> events in{" "}
          {formatDate({ date: month, format: "MMM Y" })}
        </PerformanceText>
      </PerformanceTooltipWrapper>
    )
  }

  return (
    <ScoreTooltipWrapper>
      <SpacingX alignItems="center" justify="space-between">
        {score === null ? (
          <ScoreNumber>N/A</ScoreNumber>
        ) : useWordLabel ? (
          <WordScoreBadge score={score} />
        ) : (
          <ScoreNumber>{score}</ScoreNumber>
        )}
        <SpacingY gap="1px">
          <ScoreLabel>{dashToTitleCase(behaviorName)}</ScoreLabel>
          <ScoreMonth>
            {formatDate({ date: month, format: "MMM Y" })}
          </ScoreMonth>
        </SpacingY>
        <SpacingY gap="1px" alignItems="center">
          <ScoreTrend color={color}>
            {!diff ? (
              "N/A"
            ) : (
              <>
                <Icon name={icon} fill={color} />
                {diff}
              </>
            )}
          </ScoreTrend>
          <Span>score trend</Span>
        </SpacingY>
      </SpacingX>
      <BarColorsWrapper>
        <BarColors score={barColorValue} size="small" />
      </BarColorsWrapper>
    </ScoreTooltipWrapper>
  )
}

ScoreTooltip.defaultProps = {
  score: null,
  trend: null,
  behaviorName: "N/A",
  month: "N/A",
  maxValue: 100,
}
ScoreTooltip.propTypes = {
  score: PropTypes.number,
  trend: PropTypes.number,
  month: PropTypes.string,
  behaviorName: PropTypes.string,
  maxValue: PropTypes.number,
}
