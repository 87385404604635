export const POLICY_STATE_MAP = {
  DISABLED: "disabled",
  DELETED: "deleted",
  DRAFT: "draft",
  TEST: "test",
  ACTIVE: "active",
  PAUSED: "paused",
  ERROR: "error",
}

// To use on R3 listing page
export const POLICY_STATE_LABELS_MAP = {
  [POLICY_STATE_MAP.ACTIVE]: "Active",
  [POLICY_STATE_MAP.DRAFT]: "Draft",
  [POLICY_STATE_MAP.PAUSED]: "Paused",
  [POLICY_STATE_MAP.TEST]: "Test Mode",
  [POLICY_STATE_MAP.DISABLED]: "Disabled",
  [POLICY_STATE_MAP.DELETED]: "Deleted",
  [POLICY_STATE_MAP.ERROR]: "Error",
}

export const ACTIONS_LABELS_MAPPING = {
  azure_ad: "AzureAD",
  cisco_duo: "Cisco Duo",
  crowdstrike: "CrowdStrike",
  elevate_security: "Elevate Security",
  email: "Email",
  github: "GitHub",
  microsoft_teams: "Microsoft Teams",
  sailpoint: "SailPoint",
  service_now: "ServiceNow",
  slack: "Slack",
  splunk: "Splunk",
  webhook: "Webhook",
}
