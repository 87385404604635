import React from "react"

import { EditEmailNotification } from "../actionRenderComponents/EditEmailNotification"
import { ActionGroups } from "./ActionGroups"
import { SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS } from "../../RiskRuleOutcome/constants"
import { stringToBase64 } from "@src/utils/string"
import { IndividualsEmailTemplate } from "../../RiskRuleOutcome/EmailTemplates"

import { ActionConfig } from "./actionConfigTypes"
import { validateEmailActionPayload } from "./validators/validateEmailActionPayload"

type ActionPayload = {
  action_type: typeof SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS
  matching_method: "delta" | "snapshot"
  subject: string
  message: string
  run_options?: { schedule: any }
}

const config: ActionConfig<ActionPayload> = {
  label: "Individuals who meet the criteria",
  group: ActionGroups.EMAIL,
  defaultPayload: {
    action_type: SEND_EMAIL_NOTIFICATION_TO_INDIVIDUALS,
    matching_method: "delta",
    subject: stringToBase64(
      "{{people.first_name}}, your risk profile has changed",
    ),
    message: stringToBase64(IndividualsEmailTemplate),
  },
  isEnabled: () => true,
  validate: (payload) => {
    return validateEmailActionPayload(payload)
  },
  render: ({ details, updateAction, templateAttributes }) => {
    return (
      <EditEmailNotification
        templateAttributes={templateAttributes}
        action={details}
        onChange={updateAction}
      />
    )
  },
}

export default config
