import { theme } from "@elevate_security/elevate-component-library"

import styled from "styled-components"

export const ActionWrapper = styled.div`
  padding: ${(props) => (props.isEditing ? "20px" : "10px 15px")};
  border-radius: 4px;
  background-color: ${(props) =>
    props.isEditing
      ? theme.colors.primary["050"]
      : theme.colors.gray.opaque[100]};
`

export const ActionDescription = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`
