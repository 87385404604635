import React from "react"
import PropTypes from "prop-types"
import { CustomSelect } from "@src/components/MimecastReskin"
import { orderBy } from "./utils"
import { SpacingX } from "@src/components/SpacingX"

const SortSelect = ({ select }) => {
  return (
    <SpacingX alignItems="center">
      <div>Sort:</div>
      <CustomSelect
        options={["Newest to Oldest", "Oldest to Newest", "A-Z", "Z-A"]}
        defaultValue="Newest to Oldest"
        onChange={(e) => {
          select(orderBy[e.value])
        }}
      />
    </SpacingX>
  )
}

SortSelect.propTypes = {
  select: PropTypes.func.isRequired,
}

export default SortSelect
