import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const IconWrapper = styled.div`
  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 3px;
  }
`

export const CriteriaStringWrapper = styled.div`
  flex-grow: 1;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.gray.opaque["050"]};
  }
`
