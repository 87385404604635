import styled from "styled-components"

export const Wrapper = styled.span`
  button {
    display: flex;
    height: auto;
    padding: 0;
    border-radius: 50%;
    svg path {
      fill: ${({ color }) => color};
    }
    svg line {
      stroke: ${({ color }) => color};
    }
    &:hover {
      background-color: transparent;
      svg path {
        fill: ${({ hoverColor }) => hoverColor};
      }
      svg line {
        stroke: ${({ hoverColor }) => hoverColor};
      }
    }
    &:focus {
      background-color: transparent;
      box-shadow: none;
      outline: none;
      border: none;
    }
    &:disabled,
    .disabled {
      background-color: transparent !important;
    }
  }
`
