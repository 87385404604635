export const PAST_THREE_MONTHS = "Past 3 Months"
export const PAST_SIX_MONTHS = "Past 6 Months"
export const PAST_TWELVE_MONTHS = "Past 12 Months"
export const TIME_PERIOD_LIST = [
  PAST_THREE_MONTHS,
  PAST_SIX_MONTHS,
  PAST_TWELVE_MONTHS,
]
export const YEAR = 12
export const TIME_PERIOD_MONTHS = {
  [PAST_THREE_MONTHS]: 3,
  [PAST_SIX_MONTHS]: 6,
  [PAST_TWELVE_MONTHS]: 12,
}

export const GRAPH_GAP = {
  [PAST_THREE_MONTHS]: 115,
  [PAST_SIX_MONTHS]: 75,
  [PAST_TWELVE_MONTHS]: 55,
}

export const HUMAN_RISK_DESC = "human_risk_score desc"
export const INIT_MANAGER_DRAWER = { isOpen: false, es_person_id: "", name: "" }
export const EXPORT_PARAMS = {
  order_by: HUMAN_RISK_DESC,
  total: 0,
}
export const OVERALL = "overall"
export const CHIP_LABEL = { [OVERALL]: "human_risk_score" }
export const SCORE_DETAILS_TABS = ["Details"]
export const ACTION_FACTOR_SCORE = "Action factor score"
export const VIEW_PROFILE = {
  riskType: "managerDashboard",
  text: "View Manager Dashboard",
}

export const VIEW_MANGER_DASHBOARD = {
  riskType: "individual",
  text: "View Profile",
}
