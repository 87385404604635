import { THEME_HOVER } from "@src/theme"
import styled from "styled-components"

export const HumanRiskBehaviorsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  flex: 1 0 0;
  align-self: stretch;
`

export const HumanRiskBehaviorsItem = styled.span`
  display: inline-flex;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(209, 212, 218, 0.3);
`

export const HumanRiskBehaviorsLeftBar = styled.span`
  width: 8px;
  height: 100%;
  background: #8e0000;
  border-radius: 8px 0px 0px 8px;
`

export const HumanRiskBehaviorsItemTitleLeft = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
`

export const HumanRiskBehaviorsItemBody = styled.span`
  padding: 14px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`

export const HumanRiskBehaviorsItemTitleText = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 600;
  line-height: 160%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const HumanRiskBehaviorsItems = styled.div`
  display: grid;
  gap: 11px;
  width: 100%;
`

export const HumanRiskBehaviorsItemTitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  flex-shrink: 0;
  white-space: nowrap;
  gap: 10px;
`

export const HumanRiskBehaviorsItemTitle = styled.div`
  display: flex;
  ${(props) => props.useHandCursor && "cursor: pointer;"}
`

export const ToggleButton = styled.div`
  background: none;
  border: none;
  width: 12px;
  text-align: center;
  transition: transform 250ms;

  &.active {
    transform: rotate(90deg);
  }
`

export const HumanRiskBehaviorsItemDetails = styled.div`
  padding: 15px 0px 0px 0px;
  display: flex;
  gap: 30px;
  align-items: center;
`

export const HumanRiskBehaviorsItemDetailsGrid = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`

export const HumanRiskBehaviorsItemDetailsLeft = styled.div`
  --col-gap: 18px;
  flex-grow: 1;
  display: grid;
  // Ensure at most two columns for large widths, but for small widths shrink to
  // one column; set a min-width so there isn't too much text
  // wrapping/truncation before we switch to one column.
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(200px, calc(50% - var(--col-gap))), auto)
  );
  gap: 10px var(--col-gap);
`

export const HumanRiskBehaviorsItemDetailsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
`

export const HumanRiskBehaviorsItemDetailsGridItem = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
  line-height: 15px;
`

export const HumanRiskBehaviorsItemDetailsGridItemScore = styled.div`
  font-weight: 700;
  text-align: right;
  flex-basis: 50px;
  flex-shrink: 0;
`

export const HumanRiskBehaviorsItemDetailsGridItemText = styled.div`
  color: #1565c0;
  font-weight: 400;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HumanRiskBehaviorsDropdownButton = styled.button`
  font-size: 14px;
  background-color: transparent;
  border: none;
  line-height: revert;
  transition: transform 250ms;
  svg {
    width: 18px;
    height: 18px;
    path {
      fill: #959da8;
    }
  }
  &:hover {
    color: ${THEME_HOVER};
    svg {
      path {
        fill: ${THEME_HOVER};
      }
    }
  }
  &:focus {
    outline: none;
  }
`

export const HumanRiskBehaviorsItemTitleScoreIcon = styled.div`
  min-width: 20px;
`

export const HumanRiskBehaviorsItemDetailsGridItemIcon = styled.div`
  svg {
    // for some reason max-width is set to 100% in the CSS reset, which causes bizarre
    // shrinking behaviour, so we manually remove it here
    max-width: unset;
  }
`
