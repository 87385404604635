import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

export const EditLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: end;
  font-size: 14px;
`

export const IconWrapper = styled.div`
  padding: 0 0 5px 5px;
  margin-bottom: 3px;
  :hover {
    path {
      fill: #069ba1;
    }
  }
`

export const ConfirmWrapper = styled.div`
  border: 1px solid #039ba1;
  background-color: #0c7d84;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
    background-color: #039ba1;
  }
  svg {
    margin: auto;
  }
`
export const CancelWrapper = styled.div`
  border: 1px solid #0b7e84;
  background-color: white;
  border-radius: 3px;
  height: 40px;
  width: 40px;
  &:hover {
    cursor: pointer;
  }
  svg {
    margin: auto;
  }
`

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  input {
    &:hover {
      cursor: pointer;
    }
  }
`

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 40px;
  margin-right: 16px;
  margin-top: 57px;
  z-index: 10;
  div {
    height: 110px;
    width: 110px;
    font-size: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 6px;
    border: 4px solid #ffffff;
  }
`

export const NameEmailContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
  color: #000 87%;
`

export const FullNameContainer = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #34383c;
`

export const EmailContainer = styled.a`
  line-height: 26px;
  color: #1565c0;
  margin-right: 10px;
  margin-left: 2px;
`

export const PolicyGroup = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`

export const PolicyGroupContainer = styled.div`
  display: grid;
  row-gap: 13px;
`

export const PolicyGroupData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 80px;
`

export const PolicyDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: end;
  flex-wrap: wrap;
`

export const PolicyDetailsLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-right: 5px;
  color: #000 87%;
`

export const PolicyRecord = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`
export const ThirdPartyText = styled.div`
  color: #7b828a;
  font-size: 12px;
  font-weight: bold;
`

export const BackgroundContainer = styled.div`
  display: flex;
  background-color: #fbf8f3;
  width: 100%;
  height: 79px;
`

export const PolicyDetailsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0px;
  margin-bottom: 24px;
`

export const SegmentTeal = styled.div`
  margin-top: 20px;
  margin-left: 88px;
`

export const SegmentYellow = styled.div`
  position: absolute;
`

export const TabsWrapper = styled.div`
  ul:first-child {
    margin-left: 0;
    margin-bottom: 15px;
  }
`
