import styled from "styled-components"
import { THEME_PRIMARY, THEME_HOVER } from "@src/theme"

export const ContainerWrapper = styled.div`
  h4:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #34383c;
  }
`

export const RiskTableWrapper = styled.div`
  position: relative;
  margin: 3px;
  overflow: hidden;
  [class^="Tablestyles__TableContainer"] {
    padding: 0;
    /* Stick table header at top */
    > [class^="Tablestyles__TableHeader"] {
      width: 100%;
      top: 0;
      z-index: 990;
      background: white;
      overflow: visible;
      padding-right: 0;
      border-bottom: 1px solid #eef0f3;
      [class^="Tablestyles__TableRow"] {
        padding: 0;
        [class^="Tablestyles__TableData"] {
          padding-top: 12px;
          padding-bottom: 12px;
          background: white;
        }
      }
    }
    > [class^="Tablestyles__TableBody"] {
      overflow: hidden !important;
      [class^="Tablestyles__TableRow"]
        [class^="Tablestyles__TableData"][data-column-key="checkbox"]
        input {
        margin-left: 6px;
      }
    }
  }
  [class^="Tablestyles__TableRow"] {
    padding: 5px 0;
    margin-top: 0;
  }

  [class^="Tablestyles__TableRow"]:last-child {
    border-bottom: none;
  }

  [class^="Tablestyles__TableData"] {
    /* flex: 0 0 calc(100% / 7); */
    /* width: calc(100% / 7); */
    min-width: calc(100% / 6.2) !important;
    &:nth-child(2) {
      span {
        padding-left: 0;
      }
    }
    &:last-child {
      padding-right: 0;
    }
    > .span {
      display: block;
      width: 100%;
      padding-left: 5px;
    }
    .span-head {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #7b828a;
    }
  }
  [class^="Tablestyles__TableNavigationInfo-sc-15sgrue-11"] {
    input {
      cursor: pointer;
    }
  }
  [class^="Tablestyles__TablePagination-sc-15sgrue-6"] {
    display: none;
  }
`
export const ActionWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.color};
`

export const CurrentScoreLabel = styled.div`
  margin-left: 7px;
  font-style: normal;
  font-size: 14px;
  color: #34383c;
`

export const CurrentScoreNumber = styled.div`
  font-weight: bold;
`

export const ShowRiskTrend = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.color};
  svg {
    margin-right: 4px;
    width: 16px;
  }
`
export const SparkLineWrapper = styled.div`
  svg {
    height: 32px;
  }
`

export const ActionFactorReviewButton = styled.div`
  color: ${THEME_PRIMARY};
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
  min-width: 65px;
  svg {
    width: 9px;
    height: 9px;
    path {
      fill: ${THEME_PRIMARY};
    }
  }
  &:hover {
    color: ${THEME_HOVER};
    svg {
      path {
        fill: ${THEME_HOVER};
      }
    }
  }
`
