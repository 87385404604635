import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  InputCheckbox,
  InputCheckBoxWrapper,
  Caret,
  Dropdown,
  Option,
} from "./styles"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"

const Checkbox = ({
  checked,
  disabled,
  indeterminate,
  onChange,
  label,
  id,
  invalid,
  hasDropdown,
  dropdownOptions,
  onDropdownSelect,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const handleChange = (e) => {
    onChange(e.target ? e.target.checked : null, e)
  }
  const props = {
    ...(checked !== null ? { checked } : {}),
  }
  const onOptionSelect = (option) => {
    onDropdownSelect(option)
    setDropdownOpen(false)
  }

  return (
    <InputCheckBoxWrapper hasDropdown={hasDropdown}>
      <InputCheckbox
        indeterminate={indeterminate}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        id={id}
        invalid={invalid}
        {...props}
      />
      {label && !hasDropdown && <label htmlFor={id}>{label}</label>}
      {hasDropdown && (
        <Caret onClick={() => setDropdownOpen(!dropdownOpen)}>
          <Icon name="CaretDown" size="xxsm" fill="#959DA8" />
        </Caret>
      )}
      {hasDropdown &&
        dropdownOpen &&
        dropdownOptions &&
        !!dropdownOptions.length && (
          <Dropdown>
            {dropdownOptions.map((option) => (
              <Option key={option} onClick={() => onOptionSelect(option)}>
                {option}
              </Option>
            ))}
          </Dropdown>
        )}
    </InputCheckBoxWrapper>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  invalid: PropTypes.bool,
  hasDropdown: PropTypes.bool,
  dropdownOptions: PropTypes.arrayOf(PropTypes.string),
  onDropdownSelect: PropTypes.func,
}

Checkbox.defaultProps = {
  checked: null,
  disabled: false,
  indeterminate: false,
  onChange: () => null,
  label: "",
  id: "checkbox-0",
  invalid: false,
  hasDropdown: false,
  dropdownOptions: [],
  onDropdownSelect: () => null,
}

export default Checkbox
