import styled from "styled-components"

export const PerformanceOverTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const PerformanceHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
`

export const PerformanceLegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const PerformanceCirclesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  padding-top: 4%;
`

export const PerformanceCircleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  line-height: 16px;
  margin-right: 10px;
`

export const PerformanceCircleOne = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`

export const PerformanceLegendActionText = styled.div`
  padding-left: 8px;
`
