/* eslint-disable indent */
import { matchPath } from "react-router"
import Moment from "moment"
// import { scaleQuantize, scaleSqrt } from 'd3-scale'
import { scaleQuantize } from "d3-scale"
import { camelToTitleCase, dashToTitleCase } from "@src/utils/string"

// Green to Red
export const colors = [
  "#D23A54", // 0 - 19 // Flimsy
  "#F08526", // 20 - 39 // Tenuous
  "#FACC38", // 40 - 59 // Sturdy
  "#3CC1AE", // 60 - 79 // Fortified
  "#2C9097", // 80 - 100 // Indestructible
]

// Vanilla Helper functions
export { camelToTitleCase, dashToTitleCase }

export const getSortedKeys = (obj) => {
  return Object.keys(obj).sort((a, b) => {
    if (Moment(a).isBefore(Moment(b))) return -1
    if (Moment(a).isAfter(Moment(b))) return 1
    return 0
  })
}

export const getSortedData = (obj) => {
  const sortedKeys = getSortedKeys(obj)
  return sortedKeys.map((date) => obj[date])
}

export const riskLevels = ["critical", "high", "average", "low", "minimal"]
export const getOverallScore = (obj) => {
  let score = 0
  riskLevels.forEach((type) => {
    switch (type) {
      case "critical":
        score += obj[type] * 0.2
        break
      case "high":
        score += obj[type] * 0.4
        break
      case "average":
        score += obj[type] * 0.6
        break
      case "low":
        score += obj[type] * 0.8
        break
      case "minimal":
        score += obj[type]
        break
      default:
        score += 0
        break
    }
  })
  return score / 100
}

// D3 Scales
export const colorScale = scaleQuantize().domain([0, 100]).range(colors)

// const aScale = scaleSqrt()
//   .domain([0, 100])
//   .range([13, 3])

export const gaugeScale = (score = null) => {
  if (score !== null && score >= 0) {
    if (score < 20) return colors[0]
    if (score < 40) return colors[1]
    if (score < 60) return colors[2]
    if (score < 80) return colors[3]
    if (score <= 100) return colors[4]
  }
  return "#8995A4"
}

// D3 Helper Functions
export const getCircleDiameter = (score = null) => {
  if (score !== null && score >= 0) {
    if (score < 20) return "24px"
    if (score < 40) return "22px"
    if (score < 60) return "16px"
    if (score < 80) return "12px"
    if (score <= 100) return "8px"
  }
  return "5px"
}

export const getColor = (value = null) => {
  if (value !== null && value >= 0) {
    return colorScale(value)
  }
  return "#8995A4"
}

export const getLevelId = (history) => {
  // Path
  const matchTeam = matchPath(history.location.pathname, {
    path: "/engagement/vision2/insights/team/:teamId",
    isExact: true,
  })
  const matchDept = matchPath(history.location.pathname, {
    path: "/engagement/vision2/insights/department/:deptId",
    isExact: true,
  })

  const deptId = matchDept !== null ? matchDept.params.deptId : undefined
  const teamId = matchTeam !== null ? matchTeam.params.teamId : undefined

  if (teamId) return { level: "team", id: teamId }
  if (deptId) return { level: "department", id: deptId }
  return { level: "company", id: null }
}

export const getFirstInitial = (name) => {
  return name ? name.charAt(0) : null
}

export const getPersonDepartmentName = (
  department_name,
  first_name,
  last_name,
) => {
  if (department_name && last_name) {
    return `${department_name} - ${last_name}${
      (first_name && `, ${getFirstInitial(first_name)}`) || ""
    }`
  }
  return department_name || ""
}
