import styled from "styled-components"

export const ComboBoxWrapper = styled.div`
  [class^="ComboBoxstyles__SelectContainer-sc-2zrjff-0"] {
    .css-g1d714-ValueContainer {
      padding: 2px 13px;
    }
  }
  .css-26l3qy-menu {
    z-index: 9;
  }
  [class^="Checkboxstyles__InputCheckBoxWrapper-sc-1oawj0u-4"] {
    input:checked::after {
      top: 2px;
      left: 5.5px;
      width: 4px;
      height: 9px;
    }
  }
`
export const FixedLabelWrapper = styled.div`
  svg {
    width: 12px;
    height: 12px;
    margin-bottom: 2px;
  }
`
export const ChipLoadingWrapper = styled.div`
  display: flex;
  .default-activity {
    background: #b8e5e3;
    opacity: 1;
    position: static;
  }
`
