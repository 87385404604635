import { useEffect, useRef, useState } from "react"
import { useDrawSparkLineGraph } from "@src/components/SparkLine/useDrawSparkLineGraph"

export const useSparkLineGraph = ({ behaviorScores, minMaxValue }) => {
  const chartRef = useRef(null)
  const [hideActionsTaken, setHideActionsTaken] = useState(false)

  const [isReady, setIsReady] = useState(false)
  const [typeGraph, setTypeGraph] = useState("monthly")

  const drawChart = useDrawSparkLineGraph(
    chartRef,
    typeGraph,
    behaviorScores,
    hideActionsTaken,
    setIsReady,
    minMaxValue,
  )

  useEffect(() => drawChart(false), [chartRef, typeGraph, behaviorScores])

  useEffect(() => {
    if (!isReady) return
    drawChart(true)
  }, [hideActionsTaken])

  useEffect(() => {
    window.addEventListener("resize", () => drawChart(true))
    return () => window.removeEventListener("resize", drawChart)
  }, [chartRef, typeGraph, behaviorScores])

  return {
    chartRef,
    hideActionsTaken,
    setHideActionsTaken,
    typeGraph,
    setTypeGraph,
    isReady,
  }
}
