import { Icon } from "@elevate_security/elevate-component-library"
import React from "react"

import { AddButtonWrapper } from "./styles"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { ActionButton } from "../ActionButton"

export function AddButton(props) {
  const { onClick, children, ...rest } = props

  return (
    <AddButtonWrapper>
      <ActionButton onClick={onClick} {...rest}>
        <SpaceBetween size="xs" direction="row" alignItems="center">
          <Icon name="Plus" size="default" stroke="#FFFFFF" />
          <div>{children}</div>
        </SpaceBetween>
      </ActionButton>
    </AddButtonWrapper>
  )
}
