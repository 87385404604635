import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

const getColorByScoreDiff = (diff) => {
  if (diff > 0) return "#15785f"
  if (diff < 0) return "#D54242"
  return "#7E8691"
}

const barPadding = {
  default: "12px",
  medium: "6px",
  small: "4px",
}

const wideBarPadding = {
  medium: "6px 13px 6px 6px",
}

const barMargin = {
  default: "0 4px",
  medium: "0 2px",
  small: "0 2px",
}

const barRadius = {
  default: "4px",
  medium: "2px",
  small: "2px",
}

const bulletSize = {
  default: "40px",
  medium: "25px",
  small: "16px",
}

const bulletBackgroundCenter = {
  default: "15px",
  medium: "10px",
  small: "5px",
}

export const ScoreHorizontalBarWrapper = styled.div`
  padding: 8px 0px;
  h3 {
    line-height: 24px;
  }
`
export const BarColorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ padding }) => (padding ? "15px 10px" : "0 0")};
  width: 100%;
`

export const ScoreBar = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: ${({ size, showWideBar }) =>
    showWideBar ? wideBarPadding[size] : barPadding[size]};
  margin: ${({ size }) => barMargin[size]};
  width: calc(100% / 5 - 1px);
  border-radius: ${({ size, isActionFactorChart }) =>
    isActionFactorChart ? "2px" : barRadius[size]};
  height: ${({ isActionFactorChart }) =>
    isActionFactorChart ? "16px" : "none"};
  background-color: #dde1e7;
  ${({ isActionFactorChart }) =>
    isActionFactorChart &&
    `
    padding: 9.6px 0px 9.5px !important;
  `}
  &:before {
    display: block;
    content: "";
    position: absolute;
    border-radius: ${({ size, isActionFactorChart }) =>
      isActionFactorChart ? "2px" : barRadius[size]};
    left: 0;
    right: 0;
    width: ${({ barInfo: { size } }) => size + "%"};
    max-width: 100%;
    height: 100%;
    background-color: ${({ barInfo: { color } }) =>
      !color ? "#DDE1E7" : color};
  }
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
`

export const Bullet = styled.span`
  position: absolute;
  display: ${({ barInfo: { hasCircleIndicator } }) =>
    !hasCircleIndicator ? "none" : "block"};
  left: ${({ barInfo: { size } }) => size + "%"};
  transform: ${({ barInfo: { size } }) =>
    size >= 85 ? "translateX(-95%)" : "translateX(-20%)"};
  background-color: white;
  width: ${({ size }) => bulletSize[size]};
  height: ${({ size }) => bulletSize[size]};
  background-color: ${({ barInfo: { color } }) =>
    !color ? " #1d1e1f" : color};
  border-radius: 50%;
  margin: auto;
  z-index: 1;
  &:before {
    content: "";
    display: block;
    background-color: white;
    width: ${({ size }) => bulletBackgroundCenter[size]};
    height: ${({ size }) => bulletBackgroundCenter[size]};
    border-radius: 50%;
    left: 50%;
    top: 50%;
    position: relative;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: "";
    display: ${({ hasIndicatorBox }) => (!hasIndicatorBox ? "none" : "block")};
    border: 1px solid ${theme.colors.gray.opaque[300]};
    border-top-left-radius: 5px;
    width: 32px;
    height: 32px;
    background: white;
    transform: rotate(45deg);
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    position: absolute;
    top: 57px;
    left: 4px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${({ justifyContent }) =>
    !justifyContent ? "initial" : justifyContent};
  align-items: ${({ alignItems }) => (!alignItems ? "initial" : alignItems)};
  h1 {
    font-size: 44px;
    margin-bottom: 0 !important;
  }
  h3,
  h4 {
    margin: 0;
  }
`

export const Column = styled.div``

export const ScoreGained = styled.div`
  span {
    color: ${({ diff }) => getColorByScoreDiff(diff)};
  }

  svg g {
    path {
      stroke: ${({ diff }) => getColorByScoreDiff(diff)};
    }
    &[id="icon-trendneutral"] {
      path {
        fill: ${({ diff }) => getColorByScoreDiff(diff)};
      }
      rect {
        fill: ${({ diff }) => getColorByScoreDiff(diff)};
      }
    }
  }
`
