import React from "react"
import { InsightDescription } from "./style"
export const BREADCRUMB_INSIGHT_LINKS = [
  {
    label: "Vision",
    link: "/engagement/vision2/dashboard",
  },
  {
    label: "Actionable Insights",
    link: "/engagement/vision2/dashboard",
  },
  {
    label: "Insight",
  },
]

const factorValues = [
  {
    label: "Very High",
    value: "very_high",
  },
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Very Low",
    value: "very_low",
  },
]

const actionFactorValues = [
  {
    label: "Very High",
    value: "very_high",
  },
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Very Low",
    value: "very_low",
  },
]

export const getOptionsParams = (
  deptsLoaded,
  departments,
  locationLoaded,
  locations,
) => [
  {
    action_factor: actionFactorValues,
    access_factor: factorValues,
    attack_factor: factorValues,
    department: deptsLoaded ? departments : [],
    location: locationLoaded ? locations : [],
  },
]

export const insightsDescription = (description) => {
  return (
    <InsightDescription>
      <p
        dangerouslySetInnerHTML={{
          __html: description?.replace(/\d+(%?)/g, (a) => `<b>${a}</b>`),
        }}
      />
    </InsightDescription>
  )
}

export const insightEventLogDummyData = []

for (let i = 0; i < 15; i++) {
  insightEventLogDummyData.push({
    action: "Actual Phishing",
    action_event: "real-world-phishing-clicked",
    date: "2021-10-17T12:51:00",
    full_name: "Garner Mumford",
    title: "Actual phishing email clicked (phish)",
  })
}

export const individualInvolveDummyData = []
for (let j = 0; j < 15; j++) {
  individualInvolveDummyData.push({
    access_factor: "low",
    attack_factor: "very_high",
    department: "Strategy",
    first_name: "Elizet",
    last_name: "Pruiett",
    location: "AMER",
    risks: {
      account_compromise: 7.44,
      availability_impact: 7.44,
      cybersecurity_knowledge: 7.44,
      data_loss: 7.44,
      malware: 7.44,
      overall: 7.44,
      privacy_violation: 7.44,
    },
  })
}

export const managerDummyData = []
for (let j = 0; j < 25; j++) {
  managerDummyData.push({
    full_name: "low",
    team_human_risk_score: 10,
    department: "Strategy",
    manager_name: "Elizet",
  })
}

export const mocked_interventions = {
  secure_browsing_threats: [
    { date: "2022-02-20", rdr_name: "Browsing Block Rule" },
  ],
  sensitive_data_improperly_handling: [
    { date: "2022-03-21", rdr_name: "DLP Rule" },
  ],
  three_or_more_actions: [
    { date: "2022-01-18", rdr_name: "Malware Fail Rule" },
  ],
}

export const mocked_stats_blocks = {
  three_or_more_actions: [
    {
      position: 2,
      title: "Have high or very high access",
      type: "percentage",
      value: "34",
    },
    {
      position: 3,
      title: "Are executives",
      type: "percentage",
      value: "5",
    },
    {
      position: 1,
      title: "Most commonly violated action",
      type: "string",
      value: "Malware",
    },
  ],
  secure_browsing_threats: [
    {
      position: 1,
      title: "3+ Secure Browsing Violations",
      type: "percentage",
      value: "9.7",
    },
    {
      position: 2,
      title: "Blocked But Continued Anyway",
      type: "percentage",
      value: "8.3",
    },
    {
      position: 3,
      title: "Most Common URL Category",
      type: "string",
      value: "Malicious",
    },
    {
      position: 4,
      title: "Have high or very high access",
      type: "percentage",
      value: "24",
    },
    {
      position: 5,
      title: "Are executives",
      type: "percentage",
      value: "11",
    },
  ],
  sensitive_data_improperly_handling: [
    {
      position: 2,
      title: "Have high or very high access",
      type: "percentage",
      value: "44",
    },
    {
      position: 3,
      title: "Are executives",
      type: "percentage",
      value: "9",
    },
    {
      position: 1,
      title: "Most commonly violated policy",
      type: "string",
      value: "Credit Card",
    },
  ],
  default: [
    {
      position: 1,
      title: "Have high or very high access",
      type: "percentage",
      value: "34",
    },
    {
      position: 2,
      title: "Are executives",
      type: "percentage",
      value: "5",
    },
  ],
}
