import styled from "styled-components"

export const LinkWrapper = styled.div`
  font-size: 14px;
  svg {
    width: 8px;
    margin-left: 5px;
    path {
      fill: #0c7d84;
    }
  }
  &:hover svg path {
    fill: #039ba1;
  }
`
const SubSectionBorder = (color) => `border-left:  3px solid ${color}`

export const SubSectionWrapper = styled.div`
  ${({ color }) => SubSectionBorder(color)};
  color: ${({ color }) => color};
  ${({ actionableDetailsFlag }) =>
    !actionableDetailsFlag &&
    `
  padding-inline: 28px;
  `}
  padding-top: ${({ actionableDetailsFlag }) =>
    actionableDetailsFlag ? "0px" : "8px"};
  padding-bottom: ${({ actionableDetailsFlag }) =>
    actionableDetailsFlag ? "0px" : "8px"};
  b {
    font-size: 12px;
  }
  margin-top: ${({ actionableDetailsFlag }) =>
    actionableDetailsFlag ? "0px" : "8px"};
`
export const SubSectionHeader = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: ${({ actionableDetailsFlag }) =>
    actionableDetailsFlag ? "0px" : "16px"};
`

export const SectionHeading = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #34383c;
`
export const SubSectionCardSkeleton = styled.div`
  border: 1px solid #c9cfd6;
  border-radius: 4px;
  padding: 8px 15px;
  display: flex;
  margin-bottom: 10px;
`
export const SubSectionCard = styled.div`
  min-height: 80px;
  position: relative;
`
export const NoRecommendationItemsWrapper = styled.div`
  width: 100%;
  background: #f5f6f8;
  border-radius: 5px;
  padding: 45px;
  text-align: center;
`

export const NoRecommendationItemsMessageWrapper = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    margin-bottom: 0.3rem;
    color: #7b828a;
  }
`

export const RecommendationsBlurb = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #565d66;
  margin-top: 16px;
  margin-bottom: 24px;
`
