import fetcher from "@src/services/fetcher"
import { getErrorStatus } from "@src/services/fetcher/utils"

import {
  BASES_REFLEX_API_URL,
  BASE_EP_API_URL,
  BASE_PULSE_API_URL,
} from "@src/constants"

export async function createNewGroupWithFilters(filters, payload) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.post(
      `${API_URL}/scores/group-creation/${filters || ""}`,
      payload,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function createHackersMindCampaign(payload) {
  try {
    const API_URL = BASE_EP_API_URL()
    const { status, data } = await fetcher.post(
      `${API_URL}/hackersmindcampaigns/`,
      payload,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function createReflexCampaign(payload) {
  try {
    const API_URL = BASES_REFLEX_API_URL
    const { status, data } = await fetcher.post(`${API_URL}/campaigns`, payload)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function createPulseCampaign(payload) {
  try {
    const API_URL = BASE_PULSE_API_URL()
    const { status, data } = await fetcher.post(
      `${API_URL}/campaigns/`,
      payload,
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getPulseCampaignTemplates() {
  try {
    const API_URL = BASE_PULSE_API_URL()
    const { status, data } = await fetcher.get(`${API_URL}/templates/all/`)
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
