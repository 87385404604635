import React, { useMemo, useState, useEffect } from "react"
import { Table } from "@elevate_security/elevate-component-library"
import {
  ContainerWrapper,
  RiskTableWrapper,
} from "@src/scenes/HumanRiskDashboard/components/TrendingOverTime/HumanRiskScoreTrendOverTime/style"
import getRiskTrendColumns from "@src/scenes/HumanRiskDashboard/components/TrendingOverTime/HumanRiskScoreTrendOverTime/getRiskTrendColumns"
import { getActionName } from "@src/components/ActionChart/utils"
import { useSelector, useDispatch } from "react-redux"
import { setFlagActions } from "@src/services/redux/analysisIndividuals/actions"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { darklyGetFlag } from "@src/utils/darkly"
import { setReputationService } from "@src/scenes/HumanRiskDashboard/utils"

function HumanRiskTrendTable({ onOpenSideDrawer }) {
  const analysisIndividuals = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const { trends_over_time } = useSelector((state) =>
    state.get("reputationsReducer"),
  )
  const [parsedData, setParsedData] = useState([])
  const activeActions = useSelector(selectActiveActions)
  const dispatch = useDispatch()
  const actionFactorDisplayed =
    darklyGetFlag("rap-action-factor-trends-over-time-hidden-behaviours") || []

  const transformData = (array1) => {
    const array2 = []
    array1.forEach((element) => {
      const actionName = getActionName(element.action)
      // Check if the actionName is not included in the actionFactorDisplayed feature flag
      if (!actionFactorDisplayed.includes(actionName)) {
        array2.push({
          action: actionName,
          current_score: element.current_score,
          past_1_month: element.past_1_month,
          past_12_month: element.past_12_month,
          trendData: element.trendData,
          review: {
            name: element.review,
            value: element.current_score,
            trending: element.current_score - element.past_12_month,
          },
        })
      }
    })
    setParsedData(array2)
  }

  useEffect(() => {
    const data = setReputationService(trends_over_time, activeActions)
    transformData(data)
  }, [trends_over_time])

  useEffect(() => {
    if (!analysisIndividuals.flagActions) {
      const showActions =
        darklyGetFlag("actions-that-enable-the-action-factor-drawer")?.map(
          (item) => getActionName(item),
        ) || []
      dispatch(setFlagActions(showActions))
    }
  }, [])

  const columns = useMemo(
    () =>
      getRiskTrendColumns(
        false,
        analysisIndividuals.flagActions,
        onOpenSideDrawer,
      ),
    [analysisIndividuals.flagActions, onOpenSideDrawer],
  )

  const sortedData = parsedData.sort(
    (a, b) => b.current_score - a.current_score,
  )
  return (
    <ContainerWrapper>
      <RiskTableWrapper>
        <Table
          resizable
          data={sortedData || []}
          columns={columns}
          manual
          totalCount={0}
          excludeSortColumn={columns.map((column) => column.key)}
        />
      </RiskTableWrapper>
    </ContainerWrapper>
  )
}

export default HumanRiskTrendTable
