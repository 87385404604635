export const HR_DATA_FILTER_KEY = {
  JOB_TITLE: "job_title",
  BUSINESS_TITLE: "business_title",
  BUSINESS_UNIT: "business_unit",
  IS_ONLEAVE: "is_onleave",
  JOB_FAMILY_FUNCTION_GROUP: "job_family_function_group",
  JOB_FAMILY_FUNCTION_NAME: "job_family_function_name",
  PREFERRED_LANGUAGE: "preferred_language",
  PREFERRED_NAME: "preferred_name",
  PREFERRED_MIDDLE_NAME: "preferred_middle_name",
  PREFERRED_NAME_LOCAL_LANG: "preferred_name_local_lang",
  TIME_TYPE: "time_type",
  WORK_LOCATION_COUNTRY: "work_location_country",
  WORK_LOCATION_GEO: "work_location_geo",
  WORK_LOCATION_TIMEZONE: "work_location_timezone",
  WORKER_TYPE: "worker_type",
  WORK_LOCATION_BUILDING: "work_location_building",
  WORK_LOCATION_CITY: "work_location_city",
  WORK_LOCATION_NAME: "work_location_name",
  WORK_SPACE: "work_space",
  LENGTH_OF_SERVICE: "length_of_service",
  HIRE_DATE: "hire_date",
  IS_ACTIVE: "is_active",
  LEAVE_DATE: "leave_date",
  ALTERNATIVE_USER_ID_1: "alternative_user_id_1",
  ALTERNATIVE_USER_ID_2: "alternative_user_id_2",
}

const DETECTIONS_FILTER_KEYS = {
  APPLICATION: "application",
  STATUS: "status",
  EXPECTED_COUNTRY: "expected_country",
  SUSPICIOUS_COUNTRY: "suspicious_country",
  AUTHENTICATION_FACTOR_TYPE: "authentication_factor_type",
}

export const FILTER_KEY = {
  // multi value filters (comma separated)
  INDIVIDUAL: "individual",
  MANAGER: "manager_names",
  GROUP: "groups",
  DEPARTMENT: "department",
  LOCATION: "location",
  EMPLOYMENT_TYPE: "employment_type",
  ORGANIZATIONAL_ROLE: "organizational_role",
  TENURE_STATUS: "tenure_status",
  TENURE_DURATION: "tenure_duration",
  // single value filters
  SEARCH: "search",
  SCORE_OVERALL: "score_filter_overall",
  SCORE_PHISHING: "score_filter_phishing",
  // sort
  SORT: "ordering",
  SORT_SCORE: "score_ordering",
  ACCESS_FACTOR: "access_factor",
  ATTACK_FACTOR: "attack_factor",
  ACTION_FACTOR: "action_factor",
  BEHAVIOR_TYPE: "behavior_type",
  DETECTION_TYPE: "detection_type",
  ...HR_DATA_FILTER_KEY,
  ...DETECTIONS_FILTER_KEYS,
}
export const factorValues = [
  {
    label: "Very High",
    value: "very_high",
  },
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
  {
    label: "Very Low",
    value: "very_low",
  },
]

export const getFilterChipLabels = (behaviorTypes, actionPrefix) => ({
  [FILTER_KEY.INDIVIDUAL]: "Individual:",
  [FILTER_KEY.DEPARTMENT]: "Dept:",
  [FILTER_KEY.LOCATION]: "Location:",
  [FILTER_KEY.MANAGER]: "Managed by:",
  [FILTER_KEY.GROUP]: "Group:",
  [FILTER_KEY.SEARCH]: "",
  // sort key
  [FILTER_KEY.SORT]: null,
  [FILTER_KEY.SORT_SCORE]: null,
  // score labels
  ...behaviorTypes.reduce(
    (accumulator, key) => ({
      ...accumulator,
      [`${actionPrefix}${key}`]: formatKeyToChipLabel(key),
    }),
    {},
  ),
})

const formatKeyToChipLabel = (key) =>
  `${key.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())} Score:`

const isValidFilter = (key) =>
  Object.values(FILTER_KEY).includes(key) || key.startsWith("score_filter_")

const isGroupFilter = (key) => key === FILTER_KEY.GROUP

const isScoreFilter = (key) => key.startsWith("score_filter_")

// Filters that are shown in More dropdown
export const isMoreFilter = (key) =>
  isScoreFilter(key) ||
  [
    FILTER_KEY.DEPARTMENT,
    FILTER_KEY.LOCATION,
    FILTER_KEY.TENURE_STATUS,
    FILTER_KEY.ATTACK_FACTOR,
    FILTER_KEY.ACTION_FACTOR,
    FILTER_KEY.JOB_TITLE,
    FILTER_KEY.BUSINESS_TITLE,
    FILTER_KEY.BUSINESS_UNIT,
    FILTER_KEY.IS_ONLEAVE,
    FILTER_KEY.JOB_FAMILY_FUNCTION_GROUP,
    FILTER_KEY.JOB_FAMILY_FUNCTION_NAME,
    FILTER_KEY.PREFERRED_LANGUAGE,
    FILTER_KEY.PREFERRED_NAME,
    FILTER_KEY.PREFERRED_MIDDLE_NAME,
    FILTER_KEY.PREFERRED_NAME_LOCAL_LANG,
    FILTER_KEY.TIME_TYPE,
    FILTER_KEY.WORK_LOCATION_COUNTRY,
    FILTER_KEY.WORK_LOCATION_GEO,
    FILTER_KEY.WORK_LOCATION_TIMEZONE,
    FILTER_KEY.WORKER_TYPE,
    FILTER_KEY.WORK_LOCATION_BUILDING,
    FILTER_KEY.WORK_LOCATION_CITY,
    FILTER_KEY.WORK_LOCATION_NAME,
    FILTER_KEY.WORK_SPACE,
    FILTER_KEY.LENGTH_OF_SERVICE,
    FILTER_KEY.HIRE_DATE,
    FILTER_KEY.IS_ACTIVE,
    FILTER_KEY.LEAVE_DATE,
    FILTER_KEY.ALTERNATIVE_USER_ID_1,
    FILTER_KEY.ALTERNATIVE_USER_ID_2,
  ].some((el) => key === el)

/**
 * It is responsible to convert the score tag into meaningful label
 *
 * @param {string} tag // e.g. overall_score:under_40
 * @returns {string} // e.g. Overall Score: 0 - 40
 */
export const convertScoreTagToLabel = (tag) => {
  if (!tag.includes("_score:under_")) return tag
  return tag
    .replace(/_/g, " ")
    .replace(/:under/g, ": 0 -")
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.substr(1))
    .join(" ")
}

/**
 * It is resposible to convert the search param (single) to an array
 * of filter object
 *
 * @param {string} str
 * @returns {Object}
 */
const convertFilterParamToFilterArray = (param) => {
  const [key, value] = param.split("=")
  if (isValidFilter(key)) {
    return decodeURI(value)
      .split("|")
      .map((val) => {
        let label = val
        if (isScoreFilter(key)) label = val.replace("-", " - ")
        else if (isGroupFilter(key)) label = convertScoreTagToLabel(val)
        return {
          key,
          label,
          value: val,
        }
      })
  }
  return [null]
}

/**
 * It is resposible to convert the search params string
 * to an array of filter objects
 *
 * @param {string} params
 * @returns {Object}
 */
export const translateParamsToFilters = (params) => {
  if (params && params.length) {
    return params
      .split("&")
      .map((str) => convertFilterParamToFilterArray(str))
      .reduce((accumulator, arr) => [...accumulator, ...arr], [])
      .filter(
        (obj, index, arr) =>
          // filter null
          obj !== null &&
          // filter duplicates
          arr.findIndex(
            (obj2) => obj2.key === obj.key && obj2.value === obj.value,
          ) === index,
      )
  }
  return []
}

/**
 * It is resposible to convert an array of filter objects
 * to the search params string
 *
 * @param {Object} filters
 * @returns {string}
 */
export const translateFiltersToParams = (filters) => {
  const multiFilters = {} // filters with comma separated multiple values

  filters.forEach((filter) => {
    if (multiFilters[filter.key]) multiFilters[filter.key].push(filter.value)
    else multiFilters[filter.key] = [filter.value]
  })

  const params = Object.keys(multiFilters).reduce(
    (accumulator, key) =>
      `${accumulator}&${key}=${multiFilters[key].join("|")}`,
    "",
  )

  return params.slice(1)
}

/**
 * It is resposible to extract filters value from search params string
 *
 * @param {string} params
 * @returns {string}
 */

export const extractFiltersString = (params) => {
  if (params && params.length) {
    return (
      params
        ?.split("&")
        ?.find((item) => item.indexOf("filters=") > -1)
        ?.split("=")
        .pop() || ""
    )
  }
  return ""
}
