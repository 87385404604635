import React from "react"

import { getIRPPolicyStats } from "@src/services/apis/reputation"
import { useRequest } from "ahooks"
import moment from "moment"
import { TIME_PERIOD_MONTHS } from "@src/scenes/HumanRiskDashboard/constants"
import SummaryComponent from "@src/components/SummaryParts"
import { SkeletonLoader } from "@src/utils/skeleton"

export function IRPSummaryStatisticsSection({
  onSwitchToActionlogsTab,
  person_nid,
  timePeriodValue,
}) {
  const startDate = `${moment()
    .subtract(TIME_PERIOD_MONTHS[timePeriodValue], "month")
    .format("YYYY-MM")}-01`

  const { data, loading } = useRequest(
    () => {
      return getIRPPolicyStats(person_nid, startDate)
    },
    { refreshDeps: [startDate] },
  )

  const riskResponseData = [
    {
      name: "total_actions",
      events: data?.data?.summary?.total_actions,
    },
  ]

  const dataList = [
    {
      name: "notifications",
      events: data?.data?.summary?.notifications,
    },
    {
      name: "outbound_actions",
      events: data?.data?.summary?.outbound_actions,
    },
    {
      name: "training",
      events: data?.data?.summary?.trainings,
    },
  ].filter((item) => typeof item.events === "number")

  dataList.forEach((item) => {
    item.onClick = () => onSwitchToActionlogsTab()
  })

  return loading ? (
    <SkeletonLoader height={200} />
  ) : (
    <SummaryComponent
      riskResponseData={riskResponseData}
      tileData={dataList}
      direction="vertical"
    />
  )
}
