import React from "react"
import styled from "styled-components"
import { SpaceBetween, SpaceBetweenProps } from "../SpaceBetween"

const SpacingXWrapper = styled(SpaceBetween)<{ wrap: boolean }>`
  > * {
    flex-shrink: 0;
  }
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`

export const SpacingX = (
  props: React.PropsWithChildren<
    Omit<SpaceBetweenProps, "direction"> & { wrap?: boolean }
  >,
) => {
  return (
    <SpacingXWrapper
      justify="flex-start"
      size="sm"
      {...props}
      direction="row"
      wrap={props.wrap ?? true}
    />
  )
}
