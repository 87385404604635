import React from "react"
import { StyledNavLink } from "./styles"

const LinkTo = ({ value, path }) => {
  return (
    <StyledNavLink
      title={value}
      to={{
        pathname: path,
      }}
    >
      {value}
    </StyledNavLink>
  )
}

export default LinkTo
