import React, { useEffect, useState } from "react"
import { actionsTypesMapping } from "@src/scenes/RiskResponseRuleCreateScreen/components/utils"
import { SkeletonLoader } from "@src/utils/skeleton"

const empty = () => null

function ShowActionText({ data, loading, parseHTML = false }) {
  const [resData, setResData] = useState({ isLoading: true, text: "" })

  useEffect(() => {
    async function fetchData() {
      const action = data?.action || {}
      const actionFunction = actionsTypesMapping[action?.action_type] || empty
      const actionText = await actionFunction(action)
      setResData({
        isLoading: false,
        text: actionText || "No rule response found",
      })
    }
    if (!loading) {
      fetchData()
    }
  }, [loading])

  if (loading || resData.isLoading) {
    return <SkeletonLoader />
  }

  if (parseHTML) {
    return <div dangerouslySetInnerHTML={{ __html: resData.text }} />
  }
  return <div>{resData.text}</div>
}

export default ShowActionText
