import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import {
  PositionSpan,
  HeaderText,
  TotalText,
  EntityComboboxWrapper,
  NoActionItemsMessageWrapper,
  NoActionItemsWrapper,
  NoDataTextHeader,
  NoDataText,
  NoActionItemImage,
  Row,
} from "./styles"
import TextOverflow from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/TextOverflow"
import { BarColors, Badge } from "@elevate_security/elevate-component-library/"
import { SpacingX } from "@src/components/SpacingX"
import { SpacingY } from "@src/components/SpacingY"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"
import { arraySelector } from "@src/utils/dataFilter"
import { humanize } from "@src/utils/string"
import { truncateToOneDecimalPlace } from "@src/utils/numbers/utils"
import { VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH } from "@src/theme"
import { getGlobalConfig } from "@src/globalConfig"

/**
 * This component handles display for individuals AND departments and locations,
 * so should be renamed at some point to reflect that. Not doing now to minimize
 * diff for review purposes.
 */
export default function IndividualsRiskDetail(props) {
  const {
    riskData,
    onChange = () => {},
    entityOptions,
    headerText = "HUMAN RISK SCORE",
    scoretype = "bar",
    analyticsId = () => {},
  } = props

  const { heading = "Attribute", data } = riskData

  const color = [VERY_LOW, LOW, MEDIUM, HIGH, VERY_HIGH]

  const headingToUrl = {
    individuals: "individual",
    departments: "departments",
    locations: "locations",
  }

  const tableHeader = {
    individuals: "INDIVIDUAL",
    departments: "DEPARTMENT",
    locations: "LOCATION",
  }

  return (
    <SpacingY>
      {getGlobalConfig("ENABLE_RAP_TABLE_MODE") && entityOptions && (
        <EntityComboboxWrapper>
          <ComboBoxWithOverlap
            data={entityOptions}
            onChange={onChange}
            value={entityOptions.find((option) => option.value === heading)}
          />
        </EntityComboboxWrapper>
      )}
      <div>
        <Row>
          <HeaderText>{tableHeader[heading]}</HeaderText>
          <HeaderText>{headerText}</HeaderText>
        </Row>
        {!data?.length && (
          <HumanRiskIndividualEmpty
            riskiestName={heading}
            scoretype={scoretype}
          />
        )}
        {!!data?.length && (
          <>
            {data.map((item, index) => (
              <Row key={item.name}>
                <SpacingX size="xs">
                  <PositionSpan>#{index + 1}</PositionSpan>
                  <TextOverflow name={item.name}>
                    <NavLink
                      to={item.link}
                      data-id={`${heading}-${index}`}
                      data-analytics={analyticsId(heading, index)}
                    >
                      {item.name}
                    </NavLink>
                  </TextOverflow>
                </SpacingX>
                {scoretype === "bar" ? (
                  <BarColors
                    showScore
                    showBorder
                    score={item.hrs}
                    showCircle={false}
                    size="medium"
                    sameColor={true}
                    barColor={arraySelector(item.hrs, color)}
                    formatter={truncateToOneDecimalPlace}
                  />
                ) : (
                  <Badge
                    text={humanize(item.score)}
                    theme={item.score.replace("_", "")}
                    shape="smallboldround"
                  />
                )}
              </Row>
            ))}
            <NavLink
              to={`/engagement/vision2/insights/${headingToUrl[heading]}/risk-analysis?${scoretype === "bar" ? "filters=&order_by=human_risk_score desc" : "filters=&order_by=attack_factor%20desc"}`}
            >
              <TotalText>
                {scoretype === "bar"
                  ? `View all high-risk ${heading}`
                  : `View most attacked ${heading}`}
              </TotalText>
            </NavLink>
          </>
        )}
      </div>
    </SpacingY>
  )
}

const HumanRiskIndividualEmpty = ({ riskiestName, scoretype }) => {
  const showRankingLink = riskiestName === "individuals"
  return (
    <NoActionItemsWrapper>
      {getGlobalConfig("ELLIE") && (
        <NoActionItemImage
          src="https://hm-assets.elevatesecurity.com/master/pulse/Indestructible.png"
          alt=""
          width="20%"
        />
      )}
      <NoActionItemsMessageWrapper>
        <NoDataTextHeader>
          Your {riskiestName} are in the green!
        </NoDataTextHeader>
        <NoDataText>
          Based on our data, none of your {riskiestName}
          {scoretype === "bar"
            ? " seem too risky"
            : " are highly attacked"}.{" "}
          {showRankingLink && (
            <NavLink to="/engagement/vision2/insights/individual/risk-analysis?filters=&order_by=human_risk_score desc">
              <span>See rankings</span>
            </NavLink>
          )}
        </NoDataText>
      </NoActionItemsMessageWrapper>
    </NoActionItemsWrapper>
  )
}

IndividualsRiskDetail.propTypes = {
  riskData: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  entityOptions: PropTypes.array,
  headerText: PropTypes.string,
  scoretype: PropTypes.string,
  analyticsId: PropTypes.func,
}

HumanRiskIndividualEmpty.propTypes = {
  riskiestName: PropTypes.string.isRequired,
  scoretype: PropTypes.string.isRequired,
}
