import { useEffect, useState } from "react"
import type { Breadcrumb } from "./MimecastCgShellClient"
import mimecastCgShellClient from "./MimecastCgShellClient"

const AVAILABLE_BREADCRUMBS = {
  risk_response_engine: {
    label: "Risk Response Engine",
    url: "/admin/engagement/vision2/risk-response-engine/action-logs",
  },
  risk_response_rules: {
    label: "Risk Response Rules",
    url: "/admin/engagement/vision2/risk-response-engine/risk-response-rules",
  },
  action_logs: {
    label: "Action Logs",
    url: "/admin/engagement/vision2/risk-response-engine/action-logs",
  },
  audit_logs: {
    label: "Audit Logs",
    url: "/admin/engagement/vision2/risk-response-engine/audit-logs",
  },
  watchlists: {
    label: "Watchlists",
    url: "/admin/engagement/vision2/risk-response-engine/watchlists",
  },
} as const

export type BreadcrumbItem = keyof typeof AVAILABLE_BREADCRUMBS | Breadcrumb

export function useMimecastCgBreadcrumbs(
  breadcrumbs: BreadcrumbItem[],
): boolean {
  const [isShowingBreadcrumbs, setShowingBreadcrumbs] = useState(false)

  useEffect(() => {
    const finalBreadcrumbs: Breadcrumb[] = [
      { label: "Human Risk", url: "/admin/engagement/vision2/dashboard" },
      ...breadcrumbs.map(
        (breadcrumb) =>
          (typeof breadcrumb === "string"
            ? AVAILABLE_BREADCRUMBS[breadcrumb]
            : breadcrumb) || breadcrumb,
      ),
    ]
    const isShowing = mimecastCgShellClient.updateBreadcrumbs(finalBreadcrumbs)

    setShowingBreadcrumbs(isShowing)

    return () => {
      mimecastCgShellClient.updateBreadcrumbs([])
    }
  }, [breadcrumbs])

  return isShowingBreadcrumbs
}
