import styled from "styled-components"

export const MessageSpan = styled.span`
  margin-left: 5px;
  font-size: 0.8em;
`

export const MessageWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`
