import { groupBy } from "./groupBy"

export const typeLabelMapping = {
  relative: "Relative date range",
  absolute: "Specific date range",
}

export const groupOperators = (list, keyGetter) => {
  var map = groupBy(list, keyGetter)
  const arr = Object.keys(map)?.map((key) => ({
    label: typeLabelMapping[key],
    value: key,
    options: map[key],
  }))
  return arr
}
