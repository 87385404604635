import React, { useEffect, useState } from "react"
import {
  ActionInsightData,
  ActionInsightsBox,
  ActionInsightsWrapper,
  NoInsightData,
  IconExportWrapper,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import LightBulb from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/LightBulb"
import { useHistory } from "react-router-dom"
import { getActionAbleInsight as _getActionAbleInsight } from "@src/services/apis/reputation"
import ActionAbleInsightsSkeleton from "@src/scenes/Dashboard/components/FirstQuestion/components/Skeleton/ActionAbleInsightsSkeleton"
import sunglasses from "./sunglasses.svg"
import { insightsDescription } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/utils"
import FlagRibbon from "@src/components/FlagRibbon"
import { darklyGetFlag } from "@src/utils/darkly"
import ExportData from "@src/components/ExportData"
import { Button } from "@src/components/MimecastReskin"

function ActionableInsights({ getActionAbleInsight = _getActionAbleInsight }) {
  const actionableInsightsFlag = darklyGetFlag("dashboard-actionable-insights")
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const dataToRender = data

  useEffect(() => {
    async function fetchActionAbleInsightData() {
      const response = await getActionAbleInsight()
      setData(response?.data || [])
      setLoading(false)
    }
    fetchActionAbleInsightData()
  }, [])

  const loadUserProfile = (item) => {
    if (!actionableInsightsFlag) return
    if (item?.insight) {
      sessionStorage.setItem("data", JSON.stringify(item))
      history.push({
        pathname: `/engagement/vision2/insights/individualInsightInfo/${item.insight}`,
      })
    }
  }

  if (loading) {
    return <ActionAbleInsightsSkeleton />
  }

  if (!loading && dataToRender.length === 0) {
    return (
      <ActionInsightsWrapper>
        <hr />
        <h5>Insights</h5>
        <NoInsightData>
          <img src={sunglasses} alt="sunglasses" />
          <h6>We&apos;re fresh out of insights for you!</h6>
          <p>Whenever new ones are available, we&apos;ll show &apos;em here</p>
        </NoInsightData>
      </ActionInsightsWrapper>
    )
  }

  return (
    <ActionInsightsWrapper>
      <hr />
      <FlagRibbon flagName="dashboard-actionable-insights">
        <h5>Insights</h5>
      </FlagRibbon>
      {dataToRender.map((item, index) => {
        {
          return (
            <ActionInsightsBox
              key={index}
              pointer={item?.insight && actionableInsightsFlag}
              onClick={() => loadUserProfile(item)}
            >
              <LightBulb />
              <ActionInsightData>
                {insightsDescription(item?.description)}
              </ActionInsightData>
              {actionableInsightsFlag ? (
                <Button onClick={() => loadUserProfile(item)}>
                  Review Insight
                </Button>
              ) : (
                <IconExportWrapper>
                  <ExportData
                    type="IndividualInvolvedData"
                    order_by="human_risk_score desc"
                    actions={item.insight}
                    text="Download Data"
                  />
                </IconExportWrapper>
              )}
            </ActionInsightsBox>
          )
        }
      })}
    </ActionInsightsWrapper>
  )
}

export default ActionableInsights
