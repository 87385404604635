import * as INDIVIDUAL_PROFILE_TYPE from "@src/services/redux/IndividualProfile/types"
const {
  checkIfShouldHide,
  handleFilter,
} = require("@src/services/redux/IndividualProfile/actions")

const initialEventsLogFiltersValues = {
  filterBy: "",
  actions: [],
}

const initialAuditLogFiltersValues = {
  filterBy: "",
  when: null,
  old_value: [],
  new_value: [],
  integration_source: [],
}

const INITIAL_STATE = {
  overtimeRiskTrend: null,
  reputationScore: null,
  individualData: null,
  systems: [],
  decision_areas: [],
  applied_actions: [],
  eventCount: [],
  eventsLog: {
    toggle_auto_scroll: false,
    results: [],
    filters: initialEventsLogFiltersValues,
    hideClearFilter: true,
  },
  auditLog: {
    results: [],
    filters: initialAuditLogFiltersValues,
    hideClearFilter: true,
  },
  selectedKeyFactor: null,
}

const individualProfileReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case INDIVIDUAL_PROFILE_TYPE.REPUTATION_SCORE_HISTORICAL:
      return {
        ...state,
        overtimeRiskTrend: { ...action.reputationScoreHistorical },
      }
    case INDIVIDUAL_PROFILE_TYPE.INDIVIDUAL_DATA:
      return {
        ...state,
        individualData: {
          ...action.individualData,
          last_month_risks: {
            account_compromise: 2.21,
            availability_impact: 9.33,
            cybersecurity_knowledge: 4.03,
            data_loss: 8.39,
            malware: 8.14,
            overall: 5.63,
            physical: 1.83,
            privacy_violation: 2.46,
          },
        },
      }
    case INDIVIDUAL_PROFILE_TYPE.SET_SYSTEMS:
      return {
        ...state,
        systems: action.systems,
      }
    case INDIVIDUAL_PROFILE_TYPE.SET_DECISION_AREAS:
      return {
        ...state,
        decision_areas: action.decision_areas,
      }
    case INDIVIDUAL_PROFILE_TYPE.SET_EVENT_COUNT:
      return {
        ...state,
        eventCount: action.eventCount,
      }
    case INDIVIDUAL_PROFILE_TYPE.SET_SELECTED_KEY_FACTOR:
      return {
        ...state,
        selectedKeyFactor: action.keyFactor,
      }
    case INDIVIDUAL_PROFILE_TYPE.CLEAR:
      return INITIAL_STATE
    default:
      return reducerEventLogs(state, action)
  }
}

const reducerEventLogs = (state, action) => {
  switch (action.type) {
    case INDIVIDUAL_PROFILE_TYPE.EVENTS_LOG_SET_RESULTS:
      return {
        ...state,
        eventsLog: {
          ...state.eventsLog,
          results: action.results,
        },
      }
    case INDIVIDUAL_PROFILE_TYPE.TOGGLE_AUTO_SCROLL: {
      return {
        ...state,
        eventsLog: {
          ...state.eventsLog,
          toggle_auto_scroll: !state.eventsLog.toggle_auto_scroll,
        },
      }
    }
    case INDIVIDUAL_PROFILE_TYPE.EVENTS_LOG_SET_FILTER_BY: {
      const newFilters = {
        ...state.eventsLog.filters,
        filterBy: action.filterBy,
      }
      return {
        ...state,
        eventsLog: {
          ...state.eventsLog,
          filters: newFilters,
          hideClearFilter: checkIfShouldHide(
            newFilters,
            initialEventsLogFiltersValues,
          ),
        },
      }
    }
    case INDIVIDUAL_PROFILE_TYPE.EVENTS_LOG_SET_FILTERS: {
      const newFilters = {
        ...state.eventsLog.filters,
        ...action.filters,
        ...handleFilter("actions")(state, action, "eventsLog"),
      }
      return {
        ...state,
        eventsLog: {
          ...state.eventsLog,
          filters: newFilters,
          hideClearFilter: checkIfShouldHide(
            newFilters,
            initialEventsLogFiltersValues,
          ),
        },
      }
    }
    case INDIVIDUAL_PROFILE_TYPE.EVENTS_LOG_CLEAR_FILTERS:
      return {
        ...state,
        eventsLog: {
          ...state.eventsLog,
          filters: initialEventsLogFiltersValues,
          hideClearFilter: true,
        },
      }
    case INDIVIDUAL_PROFILE_TYPE.EVENTS_LOG_CLEAR_FILTER: {
      const { filter, value } = action

      const newfilter = { [filter]: null }
      const oldValue = state.eventsLog.filters[filter]

      if (filter === "filterBy") newfilter[filter] = ""
      if (filter === "actions") {
        newfilter[filter] = oldValue.filter((v) => v !== value)
      }

      return {
        ...state,
        eventsLog: {
          ...state.eventsLog,
          filters: {
            ...state.eventsLog.filters,
            ...newfilter,
          },
        },
      }
    }
    default:
      return reducerAuditLogs(state, action)
  }
}

const reducerAuditLogs = (state, action) => {
  switch (action.type) {
    case INDIVIDUAL_PROFILE_TYPE.AUDIT_LOG_SET_RESULTS:
      return {
        ...state,
        auditLog: {
          ...state.auditLog,
          results: action.results,
        },
      }
    case INDIVIDUAL_PROFILE_TYPE.AUDIT_LOG_SET_FILTER_BY: {
      const newFilters = {
        ...state.auditLog.filters,
        filterBy: action.filterBy,
      }
      return {
        ...state,
        auditLog: {
          ...state.auditLog,
          filters: newFilters,
          hideClearFilter: checkIfShouldHide(
            newFilters,
            initialAuditLogFiltersValues,
          ),
        },
      }
    }
    case INDIVIDUAL_PROFILE_TYPE.AUDIT_LOG_SET_FILTERS: {
      const newFilters = {
        ...state.auditLog.filters,
        ...action.filters,
        ...handleFilter("integration_source")(state, action, "auditLog"),
        ...handleFilter("old_value")(state, action, "auditLog"),
        ...handleFilter("new_value")(state, action, "auditLog"),
      }
      return {
        ...state,
        auditLog: {
          ...state.auditLog,
          filters: newFilters,
          hideClearFilter: checkIfShouldHide(
            newFilters,
            initialAuditLogFiltersValues,
          ),
        },
      }
    }
    case INDIVIDUAL_PROFILE_TYPE.AUDIT_LOG_CLEAR_FILTERS:
      return {
        ...state,
        auditLog: {
          ...state.auditLog,
          filters: initialAuditLogFiltersValues,
          hideClearFilter: true,
        },
      }
    case INDIVIDUAL_PROFILE_TYPE.AUDIT_LOG_CLEAR_FILTER: {
      const { filter, value } = action

      const newfilter = { [filter]: null }
      const oldValue = state.auditLog.filters[filter]

      if (filter === "filterBy") newfilter[filter] = ""
      if (filter === "when") newfilter[filter] = null
      if (filter === "old_value") {
        newfilter[filter] = oldValue.filter((v) => v !== value)
      }
      if (filter === "new_value") {
        newfilter[filter] = oldValue.filter((v) => v !== value)
      }
      if (filter === "integration_source") {
        newfilter[filter] = oldValue.filter((v) => v !== value)
      }

      return {
        ...state,
        auditLog: {
          ...state.auditLog,
          filters: {
            ...state.auditLog.filters,
            ...newfilter,
          },
        },
      }
    }
    default:
      return state
  }
}

export default individualProfileReducer
