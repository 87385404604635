import React, { useState, useEffect, useMemo } from "react"
import { createPortal } from "react-dom"
import {
  Modal,
  Header,
  Typography,
} from "@elevate_security/elevate-component-library"
import { ModalWrapper } from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/LatestEvents/styles"
import {
  setFilterBy,
  setSelectedKeyFactor,
} from "@src/services/redux/IndividualProfile/actions"
import { useDispatch, useSelector } from "react-redux"
import { EventLogs } from "@src/components/EventLogs"
import { getActionName } from "@src/components/ActionChart/utils"
import moment from "moment"

const { H2 } = Typography

function LatestEventModal() {
  const individualProfile = useSelector((state) =>
    state.get("individualProfileReducer"),
  )
  const keyFactor = individualProfile?.selectedKeyFactor
  const dispatch = useDispatch()

  const [totalEventLogs, setTotalEventLogs] = useState()

  useEffect(() => {
    dispatch(setFilterBy(""))
    dispatch(setSelectedKeyFactor(null))
  }, [])

  const defaultFilters = useMemo(() => {
    return [
      {
        type: "date",
        key: "date",
        value: [
          moment().subtract(3, "months").format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD"),
        ],
        humanizedName: "Date",
      },
    ]
  }, [])

  return createPortal(
    <ModalWrapper>
      <Modal
        onClose={() => {
          dispatch(setFilterBy(""))
          dispatch(setSelectedKeyFactor(null))
        }}
        header={
          <Header>
            <H2 color="900">{`Event Logs - ${getActionName(
              keyFactor,
            )} (${totalEventLogs?.toLocaleString()})`}</H2>
          </Header>
        }
        isOpen={!!keyFactor}
        style={{
          width: "100%",
          height: "100vh",
          maxHeight: "none",
          maxWidth: "none",
          padding: "0 40px",
        }}
      >
        <EventLogs
          type="actionEventsLog"
          exportConfig={{
            disable: false,
          }}
          setTotalEventLogs={setTotalEventLogs}
          defaultFilters={defaultFilters}
        />
      </Modal>
    </ModalWrapper>,
    document.body,
  )
}

export default LatestEventModal
