import React from "react"
import { useSelector, useDispatch } from "react-redux"

import Table from "@elevate_security/elevate-component-library/dist/Table"
import Typography from "@elevate_security/elevate-component-library/dist/Typography"
import ActivityIndicator from "@src/components/ActivityIndicator"
import Link from "@elevate_security/elevate-component-library/dist/Link"
import Badge from "@elevate_security/elevate-component-library/dist/Badge"

import { getTeamScoresByIndividual } from "../../../../services/redux/selectors"
import { setTeamScoresByIndividuals } from "@src/scenes/ManagerDashboard/services/redux/actions"
import {
  getParserForBreakdownLevel,
  getTableColumns,
  getOrdering,
} from "@src/scenes/Dashboard/components/SecondQuestion/BreakdownTable/utils"
import {
  BreakdownTableWrapper,
  BreakdownTableHeaderWrapper,
  BreakdownTableGridWrapper,
} from "@src/scenes/Dashboard/components/SecondQuestion/BreakdownTable/styles"
import { ManagerNameWrapper } from "./styles"

const { H3, Text } = Typography
const breakdownLevel = "Individual"

const TeamScoresByIndividual = () => {
  const dispatch = useDispatch()
  const { data, isLoaded } = useSelector(getTeamScoresByIndividual)

  const parseSubManagerName = (row) => {
    return row.is_manager
      ? {
          ...row,
          individual_name: (
            <ManagerNameWrapper>
              <Link
                onClick={() => {
                  window.open(
                    `/vision2/manager-dashboard/${row.person_nid}`,
                    "_blank",
                  )
                }}
              >
                {row.individual_name}
              </Link>
              <Badge text="Manager" theme="white" />
            </ManagerNameWrapper>
          ),
        }
      : row
  }

  const tableProps = {
    data:
      (data &&
        getParserForBreakdownLevel(breakdownLevel)(data.results, false).map(
          parseSubManagerName,
        )) ||
      [],
    columns: getTableColumns(breakdownLevel),
    totalCount: data ? data.count : 0,
    onChangeTable: ({ pagination, sort }) =>
      handleOnChangeTable(pagination, sort),
    defaultPageSize: 10,
    pageSizeOptions: [10, 25, 50],
  }

  // 2 cases supported: pagination and sorting
  const handleOnChangeTable = (pagination, sort) => {
    if (pagination && sort) {
      // Unpack pagination and sort attributes
      const { limit, offset } = pagination
      const { direction, property } = sort

      // Get the ordering
      const ordering = getOrdering(direction, property)
      dispatch(
        setTeamScoresByIndividuals({
          limit,
          offset,
          ordering,
        }),
      )
    } else {
      dispatch(
        setTeamScoresByIndividuals({
          ...pagination,
        }),
      )
    }
  }

  return (
    <BreakdownTableWrapper>
      <BreakdownTableHeaderWrapper>
        <H3 fontWeight="bold" color="900">
          Score Breakdown by Individual
        </H3>
      </BreakdownTableHeaderWrapper>
      {isLoaded && (!data || !data.count) ? (
        <Text
          style={{
            fontSize: 16,
            margin: "0 0 20px 20px",
            display: "block",
            textAlign: "center",
          }}
        >
          No individual scores found.
        </Text>
      ) : (
        <BreakdownTableGridWrapper>
          <Table {...tableProps} manual />
        </BreakdownTableGridWrapper>
      )}
      <ActivityIndicator active={!isLoaded} />
    </BreakdownTableWrapper>
  )
}

export default TeamScoresByIndividual
