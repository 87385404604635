import fetcher from "@src/services/fetcher"
import { useRequest } from "ahooks"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"
import { buildQueryString } from "@src/utils/buildQueryString"

import type { Watchlist } from "./useWatchlist"

export function useWatchlists({
  pagination,
  search,
}: {
  pagination?: { pageSize: number; current: number }
  search?: string
} = {}): {
  data?: { results: Watchlist[]; total_elements: number }
  loading: boolean
  error?: Error
} {
  const { loading, data, error } = useRequest(
    () => {
      if (!pagination) {
        return fetchAllWatchlists({ search })
      }
      return fetchWatchlists({
        search,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
      })
    },
    {
      refreshDeps: [pagination?.pageSize, pagination?.current, search],
    },
  )
  return { loading, data, error }
}

function fetchWatchlists({
  search,
  pageSize,
  pageNumber,
}: {
  search?: string
  pageSize: number
  pageNumber: number
}): Promise<{ results: Watchlist[]; total_elements: number }> {
  const params = buildQueryString({
    page_size: pageSize,
    page_number: pageNumber,
    name: search,
  })
  return fetcher
    .get(`${BASES_REPUTATIONS_API_URL}/watchlists?${params}`)
    .then((res) => res.data)
}

async function fetchAllWatchlists({ search }: { search?: string }): Promise<{
  results: Watchlist[]
  total_elements: number
}> {
  const pageSize = 50
  let pageNumber = 1
  const watchlists = []
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { results, total_elements } = await fetchWatchlists({
      search,
      pageSize,
      pageNumber,
    })
    watchlists.push(...results)
    if (watchlists.length >= total_elements) {
      break
    }
    pageNumber++
    if (pageNumber > 10) {
      // In case there is a bug on the back-end, break to make sure we don't
      // keep pulling infinitely. 500 watchlists is way more than we are likely
      // to want to pull without manual pagination.
      break
    }
  }
  return { results: watchlists, total_elements: watchlists.length }
}
