import React, { useRef, useState } from "react"
import { createPortal } from "react-dom"
import { Editor } from "@tinymce/tinymce-react"

// Using the bundling approach from https://www.tiny.cloud/docs/tinymce/6/react-pm-bundle/
// For some reason, uploading assets directly and using tinymceScriptSrc did not work when deployed
import "tinymce/tinymce"

// Necessary editor utils and styles
import "tinymce/models/dom/model"
import "tinymce/themes/silver"
import "tinymce/icons/default"
import "tinymce/skins/ui/oxide/skin.min.css"

// If you use a plugin that is not imported here the editor will fail to load
import "tinymce/plugins/advlist"
import "tinymce/plugins/autolink"
import "tinymce/plugins/image"
import "tinymce/plugins/link"
import "tinymce/plugins/lists"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/table"
import "tinymce/plugins/code"
import "tinymce/plugins/emoticons"
import "tinymce/plugins/media"
import "tinymce/plugins/pagebreak"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/preview"
import "tinymce/plugins/fullscreen"

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis"

// Content styles, including inline UI like fake cursors
// eslint-disable-next-line
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css"
// eslint-disable-next-line
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css"

import { InsertTemplateVariablesModal } from "../../InsertTemplateVariablesModal"

import { EditorWrapper } from "./styles"

export const RichTextR3NotificationEditor = ({
  initData,
  onChange,
  templateOptions,
}) => {
  const editorRef = useRef(null)
  const [showModal, setShowModal] = useState(false)

  // Use state without a setter to make sure we don't keep passing new initial
  // data to the editor if this prop changes -- otherwise the editor is
  // "unstable"; see https://www.tiny.cloud/docs/tinymce/6/react-ref/#initialvalue
  const [initialValue] = useState(initData)

  return (
    <>
      {showModal &&
        createPortal(
          <InsertTemplateVariablesModal
            onInsert={(content) => {
              const lines = content.split("\n")
              // Make sure multi-line templates start on their own line
              editorRef.current.insertContent(
                (lines.length > 1
                  ? lines.map((line) => `<div>${line}</div>`)
                  : lines
                ).join(""),
              )
            }}
            options={templateOptions}
            onClose={() => setShowModal(false)}
          />,
          document.body,
        )}
      <EditorWrapper>
        <Editor
          initialValue={initialValue}
          onEditorChange={(value) => {
            onChange(value)
          }}
          onInit={(event, editor) => {
            editorRef.current = editor
          }}
          init={{
            setup: (editor) => {
              editor.ui.registry.addButton("estemplating", {
                tooltip: "Insert criteria template variables",
                icon: "plus",
                onAction: () => {
                  setShowModal(true)
                },
              })
            },
            toolbar_mode: "sliding",
            height: 500,
            browser_spellcheck: true,
            menubar: false,
            plugins: [
              "fullscreen",
              "advlist",
              "autolink",
              "image",
              "link",
              "lists",
              "searchreplace",
              "table",
              "code",
              "emoticons",
              "media",
              "pagebreak",
              "visualblocks",
              "preview",
            ],
            toolbar: [
              "estemplating blocks fontfamily bold italic forecolor removeformat",
              "image media link pagebreak",
              "alignleft aligncenter alignright alignjustify",
              "bullist numlist outdent indent",
              "emoticons",
              "table tabledelete tableprops",
              "fullscreen preview visualblocks code",
            ].join(" | "),
            skin: false,
            content_css: false,
            content_style: [
              contentCss,
              contentUiCss,
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            ].join("\n"),
            convert_unsafe_embeds: true,
          }}
        />
      </EditorWrapper>
    </>
  )
}
